import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Popover from 'react-tiny-popover';
import { FaSortDown, FaCheck } from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import Card from '../Card/Card';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from './Menu.module.scss';

export default function Menu({
  label,
  labelClassName,
  options,
  value,
  setValue,
  className,
  title,
  multiple,
  largeMenu,
  placeholder,
  allowEmpty,
  fixedPlaceholder,
  rounded,
  larger,
  darker,
  disabled,
  onClear,
  optionClassName,
  hasError,
  menuProfile,
  ...props
}) {
  const [isOpen, setOpen] = useState(false);
  const selectedRef = useRef(null);
  const { t } = useTranslation();

  function handleOptionClick(option) {
    if (!multiple) {
      setOpen(false);
      const newValue = option === value && allowEmpty ? '' : option;
      setValue(newValue);
    } else {
      if (value.includes(option)) {
        setValue(value.filter(v => v !== option));
      } else {
        setValue([...value, option]);
      }
    }
  }

  if (isOpen) {
    const scrollInitial = window.scrollY;
    window.addEventListener('scroll', () => {
      const scrollFinal = window.scrollY;
      if (scrollFinal > scrollInitial + 100 || scrollFinal < scrollInitial - 100) {
        setOpen(false);
      }
    });
  }

  function mouseEnterHandler() {
    if (selectedRef && selectedRef.current) {
      selectedRef.current.classList.add(styles.normal);
    }
  }

  function mouseLeaveHandler() {
    if (selectedRef && selectedRef.current) {
      selectedRef.current.classList.remove(styles.normal);
    }
  }

  const menuClasses = [styles.menu];
  if (className) menuClasses.push(className);
  if (rounded) menuClasses.push(styles.rounded);
  if (larger) menuClasses.push(styles.larger);
  if (darker) menuClasses.push(styles.darker);
  if (disabled) menuClasses.push(styles.disabled);
  if (hasError) menuClasses.push(styles.error);

  const optionsList = options?.map((option, i) => {
    const optionClasses = [styles.option];
    if (optionClassName) optionClasses.push(optionClassName);
    if (allowEmpty) optionClasses.push(styles.allowEmpty);
    if (i === 0 && !title) optionClasses.push(styles.first);
    if (i === options.length - 1 && !onClear) optionClasses.push(styles.last);
    if (option.value === value) optionClasses.push(styles.selected);
    if (multiple && value.includes(option.value)) optionClasses.push(styles.selected);
    if (option.color) {
      optionClasses.push(styles.withColor);
      optionClasses.push(styles[option.color]);
    }

    const circleClasses = [styles.circle];
    circleClasses.push(styles[option.color]);

    const optionContent = (
      <>
        {option.color ? <div className={circleClasses.join(' ')} /> : ''}
        <span>{option.label ?? option.value}</span>
        {multiple && value && (value.includes(option.value) || option.value === value) ? <FaCheck /> : null}
      </>
    );

    return (
      <div
        key={`menu_${option.value}${i}`}
        onClick={() => (option.onClick ? option.onClick() : handleOptionClick(option.value))}
        // onClick={() => handleOptionClick(option.value)}
        className={optionClasses.join(' ')}
        onMouseEnter={() => mouseEnterHandler()}
        onMouseLeave={() => mouseLeaveHandler()}
        ref={option.value === value ? selectedRef : null}
      >
        {optionContent}
      </div>
    );
  });

  optionsList &&
    optionsList.unshift(
      <div className={styles.option} style={{ marginTop: '12px' }} key="menu-initial-option">
        {'-'}
      </div>
    );

  let optionLabel = placeholder;
  let optionColor = '';
  const placeholderIsString = typeof placeholder === 'string' || placeholder instanceof String;
  if (!fixedPlaceholder && placeholderIsString) {
    const selectedOptions =
      options?.filter(option => {
        const optionValue = option.value;
        if (Array.isArray(value)) {
          if (value.find(v => v === optionValue)) {
            return true;
          }
        } else {
          return optionValue === value;
        }
        return false;
      }) ?? [];
    const placeholderSplit = placeholder.split(':');
    if (selectedOptions.length > 0) {
      if (selectedOptions.length === 1) {
        const selectedOptionlabel = selectedOptions[0].label;
        if (
          placeholderSplit.length > 1 &&
          (typeof selectedOptionlabel === 'string' || selectedOptionlabel instanceof String)
        ) {
          optionLabel = `${placeholderSplit[0]}: ${selectedOptionlabel}`;
        } else {
          optionLabel = selectedOptionlabel;
        }
        optionColor = selectedOptions[0].color;
      } else {
        optionLabel = `${placeholderSplit[0]}: ${t('filters.multiple')}`;
      }
    }
  }

  const circleClasses = [styles.circle];
  if (optionColor) circleClasses.push(styles[optionColor]);

  const cardClasses = [styles.card];
  if (title) cardClasses.push(styles.hasTitle);
  if (largeMenu) cardClasses.push(styles.largeMenu);

  const titleClasses = [styles.menuTitle];
  if (largeMenu) titleClasses.push(styles.largeMenu);

  const menuContent = (
    <Card bordered softShadow className={cardClasses.join(' ')}>
      {title ? (
        <div className={titleClasses.join(' ')}>
          <span>{title}</span> <MdClear onClick={() => setOpen(false)} />
        </div>
      ) : null}
      <div className={styles.clearWrapper}>
        {(allowEmpty || onClear != null) && (
          <Button color="inherit" onClick={onClear}>
            {t('slider_menu.clean')}
          </Button>
        )}
      </div>
      {optionsList}
    </Card>
  );

  return (
    <>
      {label ? <label className={labelClassName}>{label}</label> : ''}
      <Popover
        isOpen={isOpen}
        position={['bottom', 'top']}
        onClickOutside={() => setOpen(false)}
        containerClassName={styles.popoverContainer}
        content={menuContent}
      >
        <div {...props} className={menuClasses.join(' ')} onClick={disabled ? null : () => setOpen(val => !val)}>
          {optionColor ? <div className={circleClasses.join(' ')} /> : ''}
          <span>{optionLabel}</span> <FaSortDown />
        </div>
      </Popover>
    </>
  );
}

Menu.defaultProps = {
  label: '',
  className: '',
  setValue: () => null,
  value: '',
  placeholder: 'Selecione',
  allowEmpty: false,
  rounded: false,
  larger: false,
  darker: false,
  fixedPlaceholder: false,
  multiple: false,
  largeMenu: false,
  title: '',
  disabled: false,
  onClear: null,
  optionClassName: '',
  labelClassName: '',
};

Menu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.any,
      color: PropTypes.oneOf(['warning', 'danger', 'info']),
    })
  ).isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Array)]),
  setValue: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  allowEmpty: PropTypes.bool,
  rounded: PropTypes.bool,
  fixedPlaceholder: PropTypes.bool,
  larger: PropTypes.bool,
  darker: PropTypes.bool,
  title: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  largeMenu: PropTypes.bool,
  disabled: PropTypes.bool,
  onClear: PropTypes.func.isRequired,
  optionClassName: PropTypes.string,
};
