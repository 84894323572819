import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from 'store/ducks/club';
import { withRouter } from 'react-router-dom';

import { Card, Spinner, Table, Checkbox, Avatar } from 'components/template';
import styles from './Amateur.module.scss';

import { useTranslation } from 'react-i18next';

import ActionBar from '../ActionBar/ActionBar';
import { getList } from 'store/ducks/monitoringV2';
import AthleteRow from 'components/AthleteRow/AthleteRow';
import NationalitiesRow from 'components/NationalitiesRow/NationalitiesRow';
import MonitoringButton from 'components/MonitoringButton/MonitoringButton';
import moment from 'moment';
import TeamRow from 'components/TeamRow/TeamRow';

function Amateur({ teamId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const amateur = useSelector(state => state.club.amateurPage);
  const list = useSelector(state => state.monitoringV2);

  const [checkedAthletes, setCheckedAthletes] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  const today = new Date();

  const [selectedFilters, setSelectedFilters] = useState({
    name: '',
    position: [],
    list: [],
    birthYear: {
      min: 1975,
      max: Number(today.getFullYear()),
    },
  });

  const [athletes, setAthletes] = useState([]);

  const [athletesAsRows, setAthletesAsRows] = useState([]);

  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    setAthletes(applyFilters(amateur.data));
  }, [amateur]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAthletesAsRows(parseAmateurs(athletes, handleChange, checkedAthletes));
  }, [athletes, checkedAthletes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getList());
  }, [dispatch]);

  useEffect(() => {
    setFilteredList(
      list.data.filter(item => item.name !== '_DEFAULT_').map(item => ({ label: item.name, value: String(item.id) }))
    );
  }, [list.data]);

  useEffect(() => {
    if (allChecked) {
      setCheckedAthletes(athletes.map(athlete => ({ id: athlete.id, name: athlete.fullname })));
    } else {
      setCheckedAthletes([]);
    }
  }, [allChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAthletes(applyFilters(amateur.data));
  }, [selectedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (amateur.data.length === 0 && !amateur.loading) {
      dispatch(Creators.getAmateur(teamId));
    } else {
      setAthletes(amateur.data);
    }
  }, [dispatch, teamId, amateur.data.length]); // eslint-disable-line react-hooks/exhaustive-deps

  function applyFilters(athletes) {
    let filteredAthletes = athletes.filter(athlete => {
      if (selectedFilters.name === '' || athlete.fullname?.toLowerCase().includes(selectedFilters.name)) {
        if (selectedFilters.position.length === 0 || selectedFilters.position.some(pos => athlete.position === pos)) {
          if (
            athlete.birthyear >= selectedFilters.birthYear.min &&
            athlete.birthyear <= selectedFilters.birthYear.max
          ) {
            if (
              selectedFilters.list.length === 0 ||
              athlete.lists.some(list => selectedFilters.list.some(item => Number(item) === Number(list)))
            ) {
              return true;
            }
          }
        }
      }

      return false;
    });

    return filteredAthletes;
  }

  const parseAmateurs = (list, handleChange, checkedAthletes) => {
    return list.map(row => {
      const checkbox = (
        <Checkbox
          dark
          checked={checkedAthletes.some(athlete => athlete.id === row.id)}
          onChange={() => {
            handleChange({ name: row.fullname, id: row.id });
          }}
        />
      );
      const athleteImg = <Avatar noBorder className={styles.athleteImg} img={row.img} name={row.fullname} />;
      // const athlete = <AthleteRow name={row.name} img={row.img} id={row.id} />;
      const athlete = <AthleteRow noAvatar name={row.fullname} id={row.id} nickname={row.nickname} />;
      const nationalities = (
        <NationalitiesRow className={styles.nationalityFlag} nationalities={row?.nationalities} athleteId={row.id} />
      );
      const age = (
        <span style={{ fontWeight: '500' }}>
          '{row.birthyear?.toString().substr(-2)} &#40;{row.age}&#41;
        </span>
      );
      const endOfContract = (
        <div className={styles.textCenter}>
          {row.contract_info.current_contract_end
            ? moment(row.contract_info.current_contract_end).format('DD/MM/YYYY')
            : '-'}
        </div>
      );

      const monitoringButton = (
        <div className={styles.buttonContainer}>
          <MonitoringButton athlete={row} />
        </div>
      );

      let team = null;

      if (row.contract_info.loaned) {
        team = (
          <div className={styles.teamContainer}>
            <span>{row.src_team?.nickname}</span>
            <br />
            <span className={styles.contractDate}>{moment(row.src_team?.src_contract_end).format('DD/MM/YYYY')}</span>
          </div>
        );
      }

      const assignorTeam = (
        <div className={styles.textCenter}>
          {row?.contract_info?.loaned ? <TeamRow name={team} id={row.src_team?.id} img={row.src_team?.emblem} /> : '-'}
        </div>
      );

      const position = <div className={styles.textCenter}>{row.position ?? '-'}</div>;

      return [
        checkbox,
        athleteImg,
        athlete,
        age,
        position,
        nationalities,
        endOfContract,
        assignorTeam,
        monitoringButton,
      ];
    });
  };

  const handleChange = athlete => {
    if (checkedAthletes.some(item => item.id === athlete.id)) {
      setCheckedAthletes(checkedAthletes.filter(item => item.id !== athlete.id));
    } else {
      setCheckedAthletes([...checkedAthletes, athlete]);
    }
  };

  let content = '';
  if (amateur.loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (amateur.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(amateur.error)}</p>
      </Card>
    );
  } else {
    content = (
      <Card softShadow className={styles.amateur}>
        <ActionBar
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          list={filteredList}
          checkedAthletes={checkedAthletes}
        />
        {athletesAsRows.length === 0 && (
          <div className={styles.nocontent}>
            <span>{t('club.noData')}</span>
          </div>
        )}
        <div className={styles.tableContainer}>
          <Table
            className={styles.amateursTable}
            flat
            theads={[
              <Checkbox dark checked={allChecked} onChange={() => setAllChecked(!allChecked)} />,
              '',
              t('athlete_list.box1'),
              t('athlete_list.box3'),
              t('athlete_list.box4'),
              t('filters.nation'),
              t('athlete_list.box6'),
              <div className={styles.loanedContract}>
                {t('text.box1')} /<br /> {t('text.box2')}
              </div>,
              '',
            ]}
            data={athletesAsRows}
          />
        </div>
      </Card>
    );
  }

  return <>{content}</>;
}

Amateur.propTypes = {
  teamId: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(Amateur);
