import Endpoints from 'endpoints';
import axios from 'api';
import logger from 'shared/Logger';
import ErrorMessages from 'shared/ErrorMessages';
import { stringifyFilters } from 'shared/utility';

export const actionTypes = {
  GET: 'transferWindows/GET',
  GET_SUCCESS: 'transferWindows/GET_SUCCESS',
  GET_FAILED: 'transferWindows/GET_FAILED',
  GET_HEADER: 'transferWindowsHeader/GET',
  GET_HEADER_SUCCESS: 'transferWindowsHeader/GET_SUCCESS',
  GET_HEADER_FAILED: 'transferWindowsHeader/GET_FAILED',
  RESET: 'transferWindows/RESET',
  GET_COUNTRY: 'country/GET',
  GET_SUCCESS_COUNTRY: 'country/GET_SUCCESS',
  GET_FAILED_COUNTRY: 'country/GET_FAILED',
  GET_CATEGORY: 'category/GET',
  GET_SUCCESS_CATEGORY: 'category/GET_SUCCESS',
  GET_FAILED_CATEGORY: 'category/GET_FAILED',
  GET_STATUS: 'status/GET',
  GET_SUCCESS_STATUS: 'status/GET_SUCCESS',
  GET_FAILED_STATUS: 'status/GET_FAILED',
};

const initialState = {
  transferWindowsPage: {
    loading: false,
    error: null,
    data: {
      count: 0,
      data: [],
      hasNext: true,
      hasPrevious: false,
    },
  },
  transferWindowsHeader: {
    loading: false,
    error: null,
    data: {
      count: 0,
      data: [],
      hasNext: true,
      hasPrevious: false,
    },
  },
  countries: {
    loading: false,
    error: null,
    data: {
      count: 0,
      data: [],
    },
  },
  categories: {
    loading: false,
    error: null,
    data: {
      count: 0,
      data: [],
    },
  },
  statuses: {
    loading: false,
    error: null,
    data: {
      count: 0,
      data: [],
    },
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, transferWindowsPage: { ...state.transferWindowsPage, loading: true } };
    case actionTypes.GET_SUCCESS:
      return {
        ...state,
        transferWindowsPage: {
          ...state.transferWindowsPage,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_FAILED:
      return {
        ...state,
        transferWindowsPage: {
          ...state.transferWindowsPage,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_HEADER:
      return { ...state, transferWindowsHeader: { ...state.transferWindowsHeader, loading: true } };
    case actionTypes.GET_HEADER_SUCCESS:
      return {
        ...state,
        transferWindowsHeader: {
          ...state.transferWindowsHeader,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_HEADER_FAILED:
      return {
        ...state,
        transferWindowsHeader: {
          ...state.transferWindowsHeader,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.RESET:
      return { ...state, transferWindowsPage: initialState.transferWindowsPage };
    default:
      return state;
  }
}

export const Creators = {
  getTransferWindows: (filters, action = null) => async dispatch => {
    try {
      dispatch({ type: actionTypes.GET });

      let url = `${Endpoints.transferWindows}`;
      if (action) url += `/${action}`;
      const queryString = stringifyFilters(filters);

      const { data } = await axios.get(`${url}${queryString}`);

      dispatch({
        type: actionTypes.GET_SUCCESS,
        payload: {
          data: {
            count: data.count,
            data: data.results,
            hasNext: data.next ? true : false,
            hasPrevious: data.previous ? true : false,
          },
        },
      });
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response) {
        dispatch({
          type: actionTypes.GET_FAILED,
          payload: { error: 'erros.text1' },
        });
      } else {
        dispatch({
          type: actionTypes.GET_FAILED,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  resetTransferWindows: () => ({ type: actionTypes.RESET }),
};

export const HeaderCreators = {
  getTransferWindows: filters => async dispatch => {
    try {
      dispatch({ type: actionTypes.GET_HEADER });

      const url = `${Endpoints.transferWindows}/recent`;
      const queryString = stringifyFilters(filters);

      const { data } = await axios.get(`${url}${queryString}`);

      dispatch({
        type: actionTypes.GET_HEADER_SUCCESS,
        payload: {
          data: {
            count: data.count,
            data: data.results,
            hasNext: data.next ? true : false,
            hasPrevious: data.previous ? true : false,
          },
        },
      });
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response) {
        dispatch({
          type: actionTypes.GET_HEADER_FAILED,
          payload: { error: 'erros.text1' },
        });
      } else {
        dispatch({
          type: actionTypes.GET_HEADER_FAILED,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  resetTransferWindows: () => ({ type: actionTypes.RESET }),
};
