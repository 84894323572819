import React, { useState } from 'react';
import styles from './StepTwo.module.scss';
import { Input, Checkbox, InfoCard } from 'components/template';
import { useTranslation } from 'react-i18next';

export default function StepTwo({ adsData, setAdsData, athletes }) {
  const { t } = useTranslation();
  const [checkedSale, setCheckedSale] = useState(true);
  const [checkedSalary, setCheckedSalary] = useState(false);

  const filteredAthlete = athletes?.filter(item => {
    return item.id === adsData.athlete;
  });

  const data = filteredAthlete && filteredAthlete[0];

  return (
    <div className={styles.stepTwoBody}>
      <div className={styles.stepTwoText}>
        <span>{t('transferMarket.step2title')}</span>
      </div>
      <InfoCard className={styles.stepTwoInfoCard} data={data} isDesired={false} />
      <div className={styles.stepTwoNegotiation}>
        <div className={styles.stepTwoNegotiationSpan}>
          <span>{t('transferMarket.negotiationType')}</span>
        </div>
        <div className={styles.stepTwoNegotiationSelect}>
          <Checkbox
            className={styles.stepTwoCheckbox}
            label={t('negotiationOptions.SALE')}
            checked={adsData.negotiation_type === 'SALE'}
            onChange={() => {
              if (checkedSalary) {
                setCheckedSalary(false);
              }
              setCheckedSale(true);
              setAdsData({
                ...adsData,
                negotiation_type: 'SALE',
              });
            }}
          />
          <Checkbox
            className={styles.stepTwoCheckbox}
            label={t('negotiationOptions.LOAN')}
            checked={adsData.negotiation_type === 'LOAN'}
            onChange={() => {
              if (checkedSale) {
                setCheckedSale(false);
              }
              setCheckedSalary(true);
              setAdsData({
                ...adsData,
                negotiation_type: 'LOAN',
              });
            }}
          />
        </div>
      </div>
      <div className={styles.stepTwoSale}>
        {checkedSale ? (
          <div className={styles.stepTwoSaleSpan}>
            <span>{t('transferMarket.sale_value')}</span>
          </div>
        ) : (
          <div className={styles.stepTwoSaleSpan}>
            <span>{t('transferMarket.loan_value')}</span>
          </div>
        )}
        <div>
          <Input
            prefix="€ "
            inputClassName={styles.stepTwoSaleInput}
            value={adsData.sale_value}
            type="currency"
            onValueChange={(value, name) => {
              setAdsData({
                ...adsData,
                sale_value: value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.stepTwoSalary}>
        <div className={styles.stepTwoSalarySpan}>
          <span>{t('transferMarket.annual_salary')}</span>
        </div>
        <div>
          <Input
            prefix="€ "
            inputClassName={styles.stepTwoSalaryInput}
            value={adsData.annual_salary}
            type="currency"
            onValueChange={(value, name) => {
              setAdsData({
                ...adsData,
                annual_salary: value,
              });
            }}
          />
        </div>
        <div className={styles.stepTwoSalarySpan2}>
          <span>{t('transferMarket.perYear')}</span>
        </div>
      </div>
      <div className={styles.stepTwoObservation}>
        <div className={styles.stepTwoObservationSpan}>
          <span>{t('rest.box32')}</span>
        </div>
        <div>
          <textarea
            className={styles.stepTwoObservationInput}
            value={adsData.notes}
            onChange={e =>
              setAdsData({
                ...adsData,
                notes: e.target.value,
              })
            }
          ></textarea>
        </div>
      </div>
    </div>
  );
}
