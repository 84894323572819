import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { Avatar } from 'components/template';

import styles from './AthleteRow.module.scss';

export default function AthleteRow(props) {
  const { img, name, nickname, birthyear, id, noAvatar, ...rest } = props;

  return (
    <div className={styles.athleteDiv} {...rest}>
      {noAvatar ? '' : <Avatar className={styles.avatar} noBorder img={img ?? ''} name={name} />}
      <Link className={styles.link} to={{ pathname: `/atletas/${id}`, state: { previews: window.location.pathname } }}>
        {nickname ? (
          <>
            <h5>
              {nickname}
              {birthyear && <b> '{birthyear.toString().substr(-2)}</b>}
            </h5>
            <span>{name}</span>
          </>
        ) : (
          <h5>{name}</h5>
        )}
      </Link>
    </div>
  );
}

AthleteRow.defaultProps = {
  img: null,
  nickname: null,
  noAvatar: false,
};

AthleteRow.propTypes = {
  img: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  nickname: PropTypes.string,
  birthyear: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  noAvatar: PropTypes.bool,
};
