import React from 'react';
import PropTypes from 'prop-types';

import styles from './TextArea.module.scss';

export default function TextArea({
  rows,
  textAreaClassName,
  labelClassName,
  fluid,
  label,
  ...props
}) {
  const textAreaClasses = [styles.textArea];
  if (textAreaClassName) textAreaClasses.push(textAreaClassName);
  if (fluid) textAreaClasses.push(styles.fluid);

  const labelClasses = [styles.label];
  if (labelClassName) labelClasses.push(labelClassName);

  return (
    <label className={labelClasses}>
      {label}
      <textarea {...props} className={textAreaClasses.join(' ')} rows={rows || 5} />
    </label>
  );
}

TextArea.defaultProps = {
  rows: null,
  textAreaClassName: null,
  fluid: false,
  label: null,
  labelClassName: null,
};

TextArea.propTypes = {
  rows: PropTypes.number,
  textAreaClassName: PropTypes.string,
  fluid: PropTypes.bool,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
};
