/*eslint-disable eqeqeq*/
import React from 'react'
import styles from './index.module.scss'
import { useTranslation } from "react-i18next";

export default function Tabs({ filter, setFilter }) {
    const { t } = useTranslation();
    const itemClasses = [styles.badge]

    const itens = [{
        text: t("pagina_inicial.homepage_7"),
        value: ""
    }, {
        text: t("pagina_inicial.homepage_20"),
        value: "negotiations"
    }, {
        text: t("pagina_inicial.homepage_8"),
        value: "bids"
    }, {
        text: t("pagina_inicial.homepage_9"),
        value: "actions"
    }]

    const linksList = itens.map((i) => {
        if (i.value == filter) itemClasses.push(styles.active)
        else
            if (itemClasses.indexOf(styles.active) > 0) itemClasses.splice(itemClasses.indexOf(styles.active), 1)

        return <span key={i.value} className={itemClasses.join(' ')} onClick={() => setFilter(i.value)}> {i.text}</span>
    })

    return (
        <div className={styles.container}>
            {linksList}
        </div>
    );
}