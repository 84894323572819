import React from 'react';
import PropTypes from 'prop-types';
import styles from './TopBar.module.scss';
import { Avatar } from 'components/template';
import { MdVerified } from 'react-icons/md';
import CategoryGlobal from 'assets/category-global.svg';
import CategoryBrasil from 'assets/category-brasil-default.svg';
import CategoryLatam from 'assets/category-latam.svg';
import AgencyFifa from 'assets/agency-FIFA.svg';

export default function TopBar({ agency }) {
  const addressArray = [];
  if (agency?.city_team) addressArray.push(agency.city_team);
  if (agency?.state) addressArray.push(agency.state);
  if (agency?.country?.name) addressArray.push(agency.country.name);

  return (
    <div className={styles.topBar}>
      <div className={styles.left}>
        <Avatar
          noBorder
          noRadius
          img={agency?.img ?? agency?.emblem ?? ''}
          name={agency.shortName ?? agency.nickname}
          className={styles.club_avatar}
        />
        <div className={styles.clubInfo}>
          <div className={styles.titleClub}>
            <h1>{agency.shortName ?? agency.nickname}</h1>
            {agency.is_verified_agency && (
              <div className={styles.agency_verified}>
                <MdVerified />
              </div>
            )}
          </div>
          <span>{agency.longname ?? ''}</span>
          <div className={styles.categorias}>
            {(() => {
              switch (agency.agency_subtype) {
                case 'brazil':
                  return <img src={CategoryBrasil} className={styles.category_types_img} alt="" />;
                case 'latam':
                  return <img src={CategoryLatam} className={styles.category_types_img} alt="" />;
                case 'global':
                  return <img src={CategoryGlobal} className={styles.category_types_img} alt="" />;
                default:
                  return <img src={CategoryBrasil} className={styles.category_types_img} alt="" />;
              }
            })()}
            {agency.is_fifa_agency ? <img src={AgencyFifa} alt="" /> : null}
          </div>
          {/* {agency?.country?.flag && (
            <div className={styles.address}>
              <img draggable="false" src={agency.country.flag} alt="país do clube" className={styles.country_flag} />
              <span>{addressArray.join(', ')}</span>
            </div>
          )} */}
        </div>
        <span className={styles.separator}></span>
      </div>
    </div>
  );
}

TopBar.propTypes = {
  agency: PropTypes.instanceOf(Object).isRequired,
};
