/*eslint-disable eqeqeq*/
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import phoneStyles from './phoneStyles.module.scss';
import Form from '../Form';
import { Input, Avatar, Button, Menu } from 'components/template';
import FormGroup from '../Form/FormGroup';
import Aside from '../Aside';
import { Formik } from 'formik';
import * as yup from 'yup';
import { getCountries } from 'store/ducks/countries';
import { updateOrganization, updateOrganizationImage } from 'store/ducks/organization';
import * as FComponent from '../Form/styles';
import * as S from './styles';
import styles from './styles.module.scss';
import CenteredLoader from 'components/template/CenteredLoader';
import { getUnique } from 'shared/utility';
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';

// import * as FGComponent from '../Form/FormGroup/styles';
// import Form from './Form/Form'

const Organization = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const orgSelector = useSelector(state => state.organization);
  const organization = orgSelector.data;
  const countries = useSelector(state => ({
    data: getUnique(state.countries.countries.data, 'ddi').filter(country => country.ddi !== ''),
    loading: state.countries.countries.loading,
    error: state.countries.countries.error,
  }));

  useEffect(() => {
    if (countries.data.length == 0) {
      dispatch(getCountries());
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  if (countries.loading || orgSelector.loading) {
    return <CenteredLoader />;
  }

  if (organization == null) {
    return '';
  }

  const organizationSchema = yup.object().shape({
    // emblem: yup.string(),
    fullname: yup.string(),
    nickname: yup.string(),
    website: yup.string().url(),
    twitter: yup.string().url(),
    youtube: yup.string().url(),
    linkedin: yup.string().url(),
    facebook: yup.string().url(),
    instagram: yup.string().url(),
    email: yup.string().email(),
    stadium: yup.string(),
    foundation_date: yup.date(),
    member_number: yup.number(),
    phone1ddi: yup.string(),
    phone1ddd: yup.number(),
    phone1number: yup.number(),
    phone2ddi: yup.string(),
    phone2ddd: yup.number(),
    phone2number: yup.number(),
    whatsappddi: yup.string(),
    whatsappddd: yup.number(),
    whatsappnumber: yup.number(),
    city: yup.string(),
    state: yup.string(),
    country: yup.string(),
    zip_code: yup.number(),
    full_address: yup.string(),
  });

  const onUploadFile = file => {
    dispatch(updateOrganizationImage(organization.id, file));
  };

  const parseDate = date => {
    const dateArr = date.split('-');

    return `${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`;
  };

  return <Formik
    initialValues={{
      fullname: organization.fullname ?? undefined,
      nickname: organization.nickname ?? undefined,
      website: organization.social.website ?? undefined,
      twitter: organization.social.twitter ?? undefined,
      youtube: organization.social.youtube ?? undefined,
      linkedin: organization.social.linkedin ?? undefined,
      facebook: organization.social.facebook ?? undefined,
      instagram: organization.social.instagram ?? undefined,
      email: organization.email ?? undefined,
      stadium: organization.team_info?.stadium ?? undefined,
      foundation_date: organization.team_info?.foundation_date
        ? parseDate(organization.team_info?.foundation_date)
        : undefined,
      member_number: organization.team_info?.member_number ?? undefined,
      phone1ddi: organization.phones.phone1.ddi ?? undefined,
      phone1ddd: organization.phones.phone1.ddd ?? undefined,
      phone1number: organization.phones.phone1.number ?? undefined,
      phone2ddi: organization.phones.phone2.ddi ?? undefined,
      phone2ddd: organization.phones.phone2.ddd ?? undefined,
      phone2number: organization.phones.phone2.number ?? undefined,
      whatsappddi: organization.phones.whatsapp.ddi ?? undefined,
      whatsappddd: organization.phones.whatsapp.ddd ?? undefined,
      whatsappnumber: organization.phones.whatsapp.number ?? undefined,
      city: organization.address.city ?? undefined,
      state: organization.address.state ?? undefined,
      country: organization.country?.code ?? undefined,
      zip_code: organization.address?.zip_code ?? undefined,
      full_address: organization.address?.full_address ?? undefined,
    }}
    validationSchema={organizationSchema}
    onSubmit={(values, { setSubmitting }) => {
      const foundDate = values.foundation_date ? values.foundation_date.split('/') : undefined;

      const unmaskedValues = {
        ...values,
        foundation_date: foundDate ? `${foundDate[2]}-${foundDate[1]}-${foundDate[0]}` : undefined,
      };

      dispatch(updateOrganization(organization.id, unmaskedValues, organization.type));
    }}
    validate={values => {
      let errors = {};
      const maxCharactersNumber = 8;
      const maxCharactersDDD = 3;
      if (
        (values.phone1ddi || values.phone1ddd || values.phone1number) &&
        !(
          values.phone1ddi &&
          values.phone1ddd &&
          values.phone1ddd.toString().length <= maxCharactersDDD &&
          values.phone1number &&
          values.phone1number.toString().length <= maxCharactersNumber
        )
      ) {
        errors.phone1 = t('organi.box12');
      }

      if (
        (values.phone2ddi || values.phone2ddd || values.phone2number) &&
        !(
          values.phone2ddi &&
          values.phone2ddd &&
          values.phone1ddd.toString().length <= maxCharactersDDD &&
          values.phone2number &&
          values.phone1number.toString().length <= maxCharactersNumber
        )
      ) {
        errors.phone2 = t('organi.box13');
      }

      if (
        (values.whatsappddi || values.whatsappddd || values.whatsappnumber) &&
        !(
          values.whatsappddi &&
          values.whatsappddd &&
          values.whatsappddd.toString().length <= maxCharactersDDD &&
          values.whatsappnumber &&
          values.whatsappnumber.toString().length <= maxCharactersNumber
        )
      ) {
        errors.whatsapp = t('organi.box14');
      }

      return errors;
    }}
  >
    {({ values, errors, touched, handleChange, handleSubmit, setValues, isSubmitting, dirty }) => (
      <S.FormElement>
        <Aside>
          <S.AvatarContainer>
            <Avatar noBorder img={organization.emblem} name={organization.name} className={styles.avatar} />
            <S.AvatarUploadContainer>
              <S.AvatarButtonTitle>{t('organi.box15')}</S.AvatarButtonTitle>
              <Input
                onChange={e => onUploadFile(e.target.files[0])}
                rounded
                // smaller
                type="file"
                inputClassName={styles.avatarInput}
                containerClassName={styles.avatarLabel}
                label={t('organi.box1')}
              />
            </S.AvatarUploadContainer>
          </S.AvatarContainer>
          <Input
            onChange={handleChange}
            type="text"
            profileInput
            name="fullname"
            placeholder=""
            label={t('organi.box3')}
            rounded
            value={values.fullname}
          />

          {organization.type == 'team' && (
            <Input
              onChange={handleChange}
              type="text"
              profileInput
              name="nickname"
              placeholder=""
              label={t('organi.box17')}
              rounded
              value={values.nickname}
            />
          )}

          <Input
            onChange={handleChange}
            type="text"
            profileInput
            name="website"
            placeholder=""
            label="Website"
            rounded
            value={values.website}
            hasError={!!(touched.website && errors.website)}
          />
          <Input
            onChange={handleChange}
            type="text"
            profileInput
            name="email"
            placeholder=""
            label={t('organi.box4')}
            rounded
            value={values.email}
            hasError={!!(touched.email && errors.email)}
          />

          {organization.type == 'team' && (
            <>
              <Input
                onChange={handleChange}
                type="text"
                name="stadium"
                profileInput
                placeholder=""
                label={t('organi.box16')}
                rounded
                value={values.stadium}
              />
              <InputMask
                mask="99/99/9999"
                profileInput
                value={values.foundation_date}
                onChange={handleChange}
                name="foundation_date"
              >
                {inputProps => (
                  <Input
                    type="text"
                    name={inputProps.name}
                    placeholder=""
                    label={t('organi.box18')}
                    rounded
                    value={inputProps.value}
                    {...inputProps}
                  />
                )}
              </InputMask>
              <Input
                onChange={handleChange}
                type="text"
                profileInput
                name="member_number"
                placeholder=""
                label={t('organi.box19')}
                rounded
                value={values.member_number}
                hasError={!!(touched.member_number && errors.member_number)}
              />
            </>
          )}
        </Aside>
        <Form>
          <FormGroup title={t('agencies.phones')}>
            <FComponent.UnifiedField>
              <div className={phoneStyles.telefone}>
                <div>
                  <Menu
                    rounded
                    className={phoneStyles.ddi}
                    labelClassName={phoneStyles.ddiLabel}
                    options={countries.data.map(country => ({
                      label: (
                        <>
                          <S.CountryFlag src={country.flag} />
                          <span className={styles.spanFlag}>{country.code}</span>
                        </>
                      ),
                      value: country.ddi,
                    }))}
                    placeholder={
                      <>
                        <S.CountryFlag
                          src={
                            countries.data.find(country => country.ddi === values.phone1ddi)?.flag ||
                            countries.data.find(country => country.code === 'BR')?.flag
                          }
                        />
                        <span className={styles.spanFlag}>
                          {countries.data.find(country => country.ddi === values.phone1ddi)?.code ||
                            countries.data.find(country => country.code === 'BR')?.code}
                        </span>
                      </>
                    }
                    setValue={value => setValues({ ...values, phone1ddi: value })}
                    name="phone1ddi"
                    label={t('organi.box5')}
                    value={
                      <>
                        <span>
                          {countries.data.find(country => country.ddi === values.phone1ddi)?.code ||
                            countries.data.find(country => country.code === 'BR')?.code}
                        </span>
                      </>
                    }
                    // hasError={errors.phone1}
                    hasError={!!(touched.phone1ddi && (errors.phone1ddi || errors.phone1))}
                  />
                </div>
                <div className={phoneStyles.number}>
                  <Input
                    onChange={handleChange}
                    type="number"
                    name="phone1ddd"
                    placeholder="11"
                    rounded
                    containerClassName={phoneStyles.ddd}
                    value={values.phone1ddd}
                    hasError={!!(touched.phone1ddd && (errors.phone1ddd || errors.phone1))}
                  />
                  <Input
                    onChange={handleChange}
                    type="number"
                    name="phone1number"
                    placeholder="000000000"
                    rounded
                    containerClassName={phoneStyles.phone}
                    value={values.phone1number}
                    hasError={!!(touched.phone1number && (errors.phone1number || errors.phone1))}
                  />
                </div>
              </div>
            </FComponent.UnifiedField>
            <FComponent.UnifiedField>
              <div className={phoneStyles.telefone}>
                <div>
                  <Menu
                    rounded
                    className={phoneStyles.ddi}
                    labelClassName={phoneStyles.ddiLabel}
                    options={countries.data.map(country => ({
                      label: (
                        <>
                          <S.CountryFlag src={country.flag} />
                          <span className={styles.spanFlag}>{country.code}</span>
                        </>
                      ),
                      value: country.ddi,
                    }))}
                    placeholder={
                      <>
                        <S.CountryFlag
                          src={
                            countries.data.find(country => country.ddi === values.phone1ddi)?.flag ||
                            countries.data.find(country => country.code === 'BR')?.flag
                          }
                        />
                        <span className={styles.spanFlag}>
                          {countries.data.find(country => country.ddi === values.phone1ddi)?.code ||
                            countries.data.find(country => country.code === 'BR')?.code}
                        </span>
                      </>
                    }
                    setValue={value => setValues({ ...values, phone2ddi: value })}
                    name="phone2ddi"
                    label={t('organi.box6')}
                    value={
                      <>
                        <span>
                          {countries.data.find(country => country.ddi === values.phone1ddi)?.code ||
                            countries.data.find(country => country.code === 'BR')?.code}
                        </span>
                      </>
                    }
                    hasError={!!(touched.phone2ddi && (errors.phone2ddi || errors.phone2))}
                  />
                </div>
                <div className={phoneStyles.number}>
                  <Input
                    onChange={handleChange}
                    type="number"
                    name="phone2ddd"
                    placeholder="11"
                    rounded
                    containerClassName={phoneStyles.ddd}
                    value={values.phone2ddd}
                    hasError={!!(touched.phone2ddd && (errors.phone2ddd || errors.phone2))}
                  />
                  <Input
                    onChange={handleChange}
                    type="number"
                    name="phone2number"
                    placeholder="000000000"
                    rounded
                    containerClassName={phoneStyles.phone}
                    value={values.phone2number}
                    hasError={!!(touched.phone2number && (errors.phone2number || errors.phone2))}
                  />
                </div>
              </div>
            </FComponent.UnifiedField>
            <FComponent.UnifiedField>
              <div className={phoneStyles.telefone}>
                <div>
                  <Menu
                    rounded
                    className={phoneStyles.ddi}
                    labelClassName={phoneStyles.ddiLabel}
                    options={countries.data.map(country => ({
                      label: (
                        <>
                          <S.CountryFlag src={country.flag} />
                          <span className={styles.spanFlag}>{country.code}</span>
                        </>
                      ),
                      value: country.ddi,
                    }))}
                    placeholder={
                      <>
                        <S.CountryFlag
                          src={
                            countries.data.find(country => country.ddi === values.phone1ddi)?.flag ||
                            countries.data.find(country => country.code === 'BR')?.flag
                          }
                        />
                        <span className={styles.spanFlag}>
                          {countries.data.find(country => country.ddi === values.phone1ddi)?.code ||
                            countries.data.find(country => country.code === 'BR')?.code}
                        </span>
                      </>
                    }
                    setValue={value => setValues({ ...values, whatsappddi: value })}
                    name="whatsappddi"
                    label="Whatsapp"
                    value={
                      <>
                        <span className={styles.spanFlag}>
                          {countries.data.find(country => country.ddi === values.phone1ddi)?.code ||
                            countries.data.find(country => country.code === 'BR')?.code}
                        </span>
                      </>
                    }
                    hasError={!!(touched.whatsappddi && (errors.whatsappddi || errors.whatsapp))}
                  />
                </div>
                <div className={phoneStyles.number}>
                  <Input
                    onChange={handleChange}
                    type="number"
                    name="whatsappddd"
                    placeholder="11"
                    rounded
                    containerClassName={phoneStyles.ddd}
                    // defaultValue={organization.phones.whatsapp.ddd}
                    value={values.whatsappddd}
                    // hasError={!!(touched.whatsappddd && errors.whatsappddd)}
                    hasError={!!(touched.whatsappddd && (errors.whatsappddd || errors.whatsapp))}
                  />
                  <Input
                    onChange={handleChange}
                    type="number"
                    name="whatsappnumber"
                    placeholder="000000000"
                    rounded
                    containerClassName={phoneStyles.phone}
                    value={values.whatsappnumber}
                    hasError={!!(touched.whatsappnumber && (errors.whatsappnumber || errors.whatsapp))}
                  // hasError={!!(touched.whatsappnumber && errors.whatsappnumber)}
                  />
                </div>
              </div>
            </FComponent.UnifiedField>
          </FormGroup>
          <FormGroup title={t('organi.box7')}>
            <FComponent.FieldWrapper width="400px">
              <Input
                onChange={handleChange}
                profileInput
                type="text"
                name="full_address"
                placeholder=""
                label={t('organi.box8')}
                rounded
                value={values.full_address}
              />
            </FComponent.FieldWrapper>
            <FComponent.FieldWrapper width="150px">
              <Input
                onChange={handleChange}
                profileInput
                type="text"
                name="zip_code"
                placeholder="00000-000"
                label={t('text.box16')}
                rounded
                value={values.zip_code}
                hasError={!!(touched.zip_code && errors.zip_code)}
              />
            </FComponent.FieldWrapper>
            <FComponent.FieldWrapper>
              <Menu
                rounded
                menuProfile
                className={styles.countrySelect}
                labelClassName={styles.countrySelectLabel}
                options={countries.data.map(country => ({ label: country.name, value: country.code }))}
                placeholder="Brasil"
                setValue={value => setValues({ ...values, country: value })}
                name="country"
                label={t('compe.text1')}
                value={values.country}
              />
            </FComponent.FieldWrapper>
            <FComponent.FieldWrapper>
              <Input
                onChange={handleChange}
                profileInput
                type="text"
                name="state"
                placeholder=""
                label={t('athlete_card.text5')}
                rounded
                value={values.state}
              />
            </FComponent.FieldWrapper>
            <FComponent.FieldWrapper>
              <Input
                onChange={handleChange}
                profileInput
                type="text"
                name="city"
                placeholder=""
                label={t('organi.box9')}
                rounded
                value={values.city}
              />
            </FComponent.FieldWrapper>
          </FormGroup>
          <FormGroup title={t('organi.box10')}>
            <FComponent.FieldWrapper>
              <Input
                onChange={handleChange}
                profileInput
                type="text"
                name="twitter"
                placeholder=""
                label="X"
                rounded
                value={values.twitter}
                hasError={!!(touched.twitter && errors.twitter)}
              />
            </FComponent.FieldWrapper>
            <FComponent.FieldWrapper>
              <Input
                onChange={handleChange}
                profileInput
                type="text"
                name="instagram"
                placeholder=""
                label="Instagram"
                rounded
                value={values.instagram}
                hasError={!!(touched.instagram && errors.instagram)}
              />
            </FComponent.FieldWrapper>
            <FComponent.FieldWrapper>
              <Input
                onChange={handleChange}
                profileInput
                type="text"
                name="facebook"
                placeholder=""
                label="Facebook"
                rounded
                value={values.facebook}
                hasError={!!(touched.facebook && errors.facebook)}
              />
            </FComponent.FieldWrapper>
            <FComponent.FieldWrapper>
              <Input
                onChange={handleChange}
                profileInput
                type="text"
                name="linkedin"
                placeholder=""
                label="Linkedin"
                rounded
                value={values.linkedin}
                hasError={!!(touched.linkedin && errors.linkedin)}
              />
            </FComponent.FieldWrapper>
            <FComponent.FieldWrapper>
              <Input
                onChange={handleChange}
                profileInput
                type="text"
                name="youtube"
                placeholder=""
                label="Youtube"
                rounded
                value={values.youtube}
                hasError={!!(touched.youtube && errors.youtube)}
              />
            </FComponent.FieldWrapper>
          </FormGroup>
          <Button className={styles.updateButton} rounded color="green" smaller disabled={!dirty} type="submit">
            {t('organi.box11')}
          </Button>
        </Form>
      </S.FormElement>
    )}
  </Formik>;
};

export default Organization;
