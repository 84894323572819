/*eslint-disable eqeqeq*/
import React from 'react';
import { Card } from 'components/template';
import LineUp from 'pages/Project/Workflow/ShadowTeam/ShadowTeamLineUp/components/lineup';
import styles from './index.module.scss';

export default function ShadowTeamReport({ reportData, id }) {
  return (
    <Card softShadow className={styles.card}>
      <div className={styles.lineup} {...(id == null ? {} : { id })}>
        <LineUp
          schema={reportData.shadow_team.schema}
          color={reportData.shadow_team.color_field}
          model={reportData.shadow_team.modelfield}
          positions={reportData.shadow_team_positions}
          athletes={reportData.shadow_team_athletes}
          setPosition={null}
          selectedPosition={null}
          handleSelectPosition={null}
          handleUnselectPosition={null}
          disablePositions={true}
        />
      </div>
    </Card>
  );
}
