import React, { useState } from 'react';
import styles from './CalendarMenu.module.scss';
import PropTypes from 'prop-types';
import { Calendar } from 'components/template';
import { Button } from '@material-ui/core';
import Card from '../Card/Card';
import Popover from 'react-tiny-popover';
import { useTranslation } from 'react-i18next';
import { MdClear } from 'react-icons/md';
import { FaSortDown } from 'react-icons/fa';

const CalendarMenu = ({
  label,
  onClear,
  className,
  minDatePlaceholder,
  maxDatePlaceholder,
  minDateValue,
  maxDateValue,
  setMinValue,
  setMaxValue,
}) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  const menu_custom_class = [styles.calender_menu_select_container];
  if (className) menu_custom_class.push(className);

  const popoverContent = (
    <Card bordered softShadow className={styles.calender_menu_card_container}>
      <div className={styles.calender_menu_card_title}>
        <span>{t('filters.select_period')}</span> <MdClear onClick={() => setOpen(false)} />
      </div>
      <div className={styles.calender_menu_card_body}>
        <div className={styles.calender_menu_card_body_calendar_container}>
          <div className={styles.calender_menu_card_body_first_calendar}>
            <span>{t('filters.de')}: </span>
            <div className={styles.calender_menu_calendar_class}>
              <Calendar
                placeholder={minDatePlaceholder}
                minDate={1975}
                value={minDateValue}
                setValue={setMinValue}
                maxDate={maxDateValue != null && maxDateValue !== '' ? maxDateValue : null}
              />
            </div>
          </div>
          <div className={styles.calender_menu_card_body_second_calendar}>
            <span>{t('filters.ate')}: </span>
            <div className={styles.calender_menu_calendar_class}>
              <Calendar
                placeholder={maxDatePlaceholder}
                value={maxDateValue}
                setValue={setMaxValue}
                minDate={minDateValue != null && minDateValue !== '' ? minDateValue : 1975}
              />
            </div>
          </div>
        </div>
        <Button color="inherit" onClick={onClear} className={styles.calender_menu_card_clear_button}>
          {t('slider_menu.clean')}
        </Button>
      </div>
    </Card>
  );

  return (
    <>
      <Popover
        isOpen={isOpen}
        position={['bottom', 'top']}
        onClickOutside={() => setOpen(false)}
        containerClassName={styles.calender_menu_popover_container}
        content={popoverContent}
      >
        <div className={menu_custom_class.join(' ')} onClick={() => setOpen(true)}>
          <span>{label}</span> <FaSortDown />
        </div>
      </Popover>
    </>
  );
};

export default CalendarMenu;

CalendarMenu.propTypes = {
  setValue: PropTypes.func.isRequired,
};
