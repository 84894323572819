import Endpoints from 'endpoints';
import axios from 'api';
import ErrorMessages from 'shared/ErrorMessages';
import { stringifyFilters } from 'shared/utility';

export const actionTypes = {
  GET_TEAMS: 'myClub/teams/GET',
  GET_TEAMS_SUCCESS: 'myClub/teams/GET_SUCCESS',
  GET_TEAMS_FAIL: 'myClub/teams/GET_FAIL',
  GET_CLUBUSERS: 'myClub/teams/users/GET',
  GET_CLUBUSERS_SUCCESS: 'myClub/teams/users/GET_SUCCESS',
  GET_CLUBUSERS_FAIL: 'myClub/teams/users/GET_FAIL',
  PATCH_TEAMS: 'myClub/teams/PATCH',
  PATCH_TEAMS_SUCCESS: 'myClub/teams/PATCH_SUCCESS',
  PATCH_TEAMS_FAIL: 'myClub/teams/PATCH_FAIL',
  GET_TEAM_ATHLETES: 'myClub/teams/athletes/GET',
  GET_TEAM_ATHLETES_SUCCESS: 'myClub/teams/athletes/GET_SUCCESS',
  GET_TEAM_ATHLETES_FAIL: 'myClub/teams/athletes/GET_FAIL',
  GET_TEAM_FRAMES: 'myClub/teams/frames/GET',
  GET_TEAM_FRAMES_SUCCESS: 'myClub/teams/frames/GET_SUCCESS',
  GET_TEAM_FRAMES_FAIL: 'myClub/teams/frames/GET_FAIL',
};

const initialState = {
  teams: {
    loading: false,
    error: null,
    data: [],
    hasNext: true,
    hasPrevious: false,
    count: 0,
  },
  users: {
    loading: false,
    error: null,
    data: [],
    hasNext: true,
    hasPrevious: false,
    count: 0,
  },
  athletes: {
    loading: false,
    error: null,
    data: [],
    hasNext: true,
    hasPrevious: false,
    count: 0,
  },
  frames: {
    loading: false,
    error: null,
    data: [],
    hasNext: true,
    hasPrevious: false,
    count: 0,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // teams
    case actionTypes.GET_TEAMS:
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: true,
        },
      };
    case actionTypes.GET_TEAMS_SUCCESS:
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: false,
          data: action.payload.data,
          hasNext: action.payload.hasNext,
          hasPrevious: action.payload.hasPrevious,
          count: action.payload.count,
        },
      };
    case actionTypes.GET_TEAMS_FAIL:
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_CLUBUSERS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        },
      };
    case actionTypes.GET_CLUBUSERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          data: action.payload.data,
          hasNext: action.payload.hasNext,
          hasPrevious: action.payload.hasPrevious,
          count: action.payload.count,
        },
      };
    case actionTypes.GET_CLUBUSERS_FAIL:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.PATCH_TEAMS:
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: true,
        },
      };
    case actionTypes.PATCH_TEAMS_SUCCESS:
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: false,
          data: action.payload.data,
          hasNext: action.payload.hasNext,
          hasPrevious: action.payload.hasPrevious,
          count: action.payload.count,
        },
      };
    case actionTypes.PATCH_TEAMS_FAIL:
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_TEAM_ATHLETES_FAIL:
      return {
        ...state,
        athletes: {
          ...state.athletes,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_TEAM_ATHLETES:
      return {
        ...state,
        athletes: {
          ...state.athletes,
          loading: true,
        },
      };
    case actionTypes.GET_TEAM_ATHLETES_SUCCESS:
      return {
        ...state,
        athletes: {
          ...state.athletes,
          loading: false,
          data: action.payload.data,
          hasNext: action.payload.hasNext,
          hasPrevious: action.payload.hasPrevious,
          count: action.payload.count,
        },
      };

    case actionTypes.GET_TEAM_FRAMES:
      return {
        ...state,
        frames: {
          ...state.frames,
          loading: true,
        },
      };
    case actionTypes.GET_TEAM_FRAMES_SUCCESS:
      return {
        ...state,
        frames: {
          ...state.frames,
          loading: false,
          data: action.payload.data,
          hasNext: action.payload.hasNext,
          hasPrevious: action.payload.hasPrevious,
          count: action.payload.count,
        },
      };
    case actionTypes.GET_TEAM_FRAMES_FAIL:
      return {
        ...state,
        frames: {
          ...state.frames,
          loading: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
}

export const getTeams = (id, filters) => async dispatch => {
  dispatch({ type: actionTypes.GET_TEAMS });
  const url = Endpoints.teams;

  try {
    const queryString = stringifyFilters(filters);

    const { data } = await axios.get(`${url}/${id}${queryString}`);

    dispatch({
      type: actionTypes.GET_TEAMS_SUCCESS,
      payload: {
        data: data.results ?? data,
        hasNext: data.next ? true : false,
        hasPrevious: data.previous ? true : false,
        count: data.count,
      },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_TEAMS_FAIL,
        payload: { error: 'erros.text39' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_TEAMS_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else {
      dispatch({
        type: actionTypes.GET_TEAMS_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};

export const getTeamAthletes = id => async dispatch => {
  dispatch({ type: actionTypes.GET_TEAM_ATHLETES });
  const url = Endpoints.teams;

  try {
    // const queryString = stringifyFilters(filters);
    const { data } = await axios.get(`${url}/${id}/athletes`);

    dispatch({
      type: actionTypes.GET_TEAM_ATHLETES_SUCCESS,
      payload: {
        data: data.results ?? data,
        hasNext: data.next ? true : false,
        hasPrevious: data.previous ? true : false,
        count: data.count,
      },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_TEAM_ATHLETES_FAIL,
        payload: { error: 'erros.text39' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_TEAM_ATHLETES_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else {
      dispatch({
        type: actionTypes.GET_TEAM_ATHLETES_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};

export const getTeamNegotiations = (id, type) => async dispatch => {
  dispatch({ type: actionTypes.GET_TEAM_FRAMES });
  const url = Endpoints.teams;

  try {
    // const queryString = stringifyFilters(filters);
    const { data } = await axios.get(`${url}/${id}/negotiations?type=${type}`);

    dispatch({
      type: actionTypes.GET_TEAM_FRAMES_SUCCESS,
      payload: {
        data: data.results ?? data,
        hasNext: data.next ? true : false,
        hasPrevious: data.previous ? true : false,
        count: data.count,
      },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_TEAM_FRAMES_FAIL,
        payload: { error: 'erros.text39' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_TEAM_FRAMES_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else {
      dispatch({
        type: actionTypes.GET_TEAM_FRAMES_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};

export const getUsers = (id, filters) => async dispatch => {
  dispatch({ type: actionTypes.GET_CLUBUSERS });
  const url = Endpoints.teams;

  try {
    const queryString = stringifyFilters(filters);

    const { data } = await axios.get(`${url}/${id}/users${queryString}`);

    dispatch({
      type: actionTypes.GET_CLUBUSERS_SUCCESS,
      payload: {
        data: data.results ?? data,
        hasNext: data.next ? true : false,
        hasPrevious: data.previous ? true : false,
        count: data.count,
      },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_CLUBUSERS_FAIL,
        payload: { error: 'erros.text39' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_CLUBUSERS_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else {
      dispatch({
        type: actionTypes.GET_CLUBUSERS_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};

export const removeUser = async (teamId, removeUserId) => {
  try {
    const { data } = await axios.delete(`${Endpoints.teams}/${teamId}/users/${removeUserId}`);
    return data;
  } catch (err) {
    return { error: true };
  }
};

export const updateTeamInfo = async (id, body) => {
  const url = Endpoints.teams;
  try {
    const { data } = await axios.patch(`${url}/${id}`, body);
    return data;
  } catch (err) {
    return err;
  }
};

export const updateTeamFrames = async (id, frames_field_name, frames) => {
  const url = Endpoints.teams;
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const formData = new FormData();
    formData.append(frames_field_name, JSON.stringify(frames));
    const { data } = await axios.patch(`${url}/${id}`, formData, config);
    return data;
  } catch (err) {
    return err;
  }
};

export const updateTeamNegotiations = async (teamId, negotiationId, frame, order, type) => {
  try {
    const { data } = await axios.patch(`${Endpoints.teams}/${teamId}/negotiations/${negotiationId}`, {
      frame: frame,
      order: order,
      type: type,
    });
    return { data };
  } catch (err) {
    return { error: 'Ocorreu um erro ao tentar atualizar o projeto. Por favor, tente novamente' };
  }
};

export const deleteTeamNegotiation = async teamId => {
  try {
    const { data } = await axios.delete(`${Endpoints.teams}/${teamId}/negotiations`);
    return { data };
  } catch (err) {
    return { error: 'Ocorreu um erro ao tentar remover o atleta. Por favor, tente novamente' };
  }
};

export const createNegotiation = async (teamId, frame, athlete, type) => {
  try {
    const { data } = await axios.post(`${Endpoints.teams}/${teamId}/negotiations`, {
      frame: frame,
      athlete: athlete,
      type: type,
    });
    return { data };
  } catch (err) {
    return { error: 'Ocorreu um erro ao tentar adicionar o atleta no Workflow. Por favor, tente novamente' };
  }
};

export const deleteNegotiation = async (teamId, negotiationId) => {
  try {
    const { data } = await axios.delete(`${Endpoints.teams}/${teamId}/negotiations/${negotiationId}`);
    return { data };
  } catch (err) {
    return { error: 'Ocorreu um erro ao tentar remover o atleta. Por favor, tente novamente' };
  }
};
