import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Toast.module.scss';

export default function Toast({ isVisible, onClose, duration, className, type, content, fixed, ...props }) {

  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    clearTimeout(timeoutId);
    if (isVisible && !fixed) {
      setTimeoutId(setTimeout(() => {
        onClose();
      }, duration));
    }
  }, [duration, onClose, isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const toastClasses = [styles.toast];
  if (isVisible) toastClasses.push(styles.visible);
  switch (type) {
    case 'success':
      toastClasses.push(styles.success);
      break;
    case 'warning':
      toastClasses.push(styles.warning);
      break;
    case 'danger':
      toastClasses.push(styles.danger);
      break;
    case 'info':
      toastClasses.push(styles.info);
      break;
    case 'default':
    default:
      toastClasses.push(styles.default);
  }
  if (className) toastClasses.push(className);
  let renderedContent;
  if (typeof content === 'string') {
    renderedContent = <p>{content}</p>;
  } else {
    renderedContent = content;
  }

  return (
    <div {...props} className={toastClasses.join(' ')}>
      {renderedContent}
    </div>
  );
}

Toast.defaultProps = {
  duration: 1500,
  className: null,
  type: 'default',
  content: ' ',
  fixed: false
};

Toast.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  duration: PropTypes.number,
  className: PropTypes.string,
  content: PropTypes.node,
  type: PropTypes.oneOf(['success', 'warning', 'danger', 'info', 'default']),
  fixed: PropTypes.bool.isRequired,
};
