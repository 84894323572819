/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-case-declarations */
import React from 'react';
import MonitoringButton from 'components/MonitoringButton/MonitoringButton';
import AthleteRow from 'components/AthleteRow/AthleteRow';
import NationalitiesRow from 'components/NationalitiesRow/NationalitiesRow';
import { Avatar, Checkbox } from 'components/template';
import moment from 'moment';
import TeamRow from 'components/TeamRow/TeamRow';

export default class Helpers {
  static stringWODiacritics(str) {
    return str.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
  }

  static normalizedString(str) {
    const val = String(str);
    return String(this.stringWODiacritics(val)).toLowerCase();
  }

  static athleteListToTable(
    list,
    type,
    styles,
    actionType = null,
    handleChange = null,
    checkedAthletes = [],
    loaned = false
  ) {
    return list.map(row => {
      const checkbox = handleChange ? (
        <Checkbox
          dark
          checked={checkedAthletes.some(athlete => athlete.id === row.id)}
          onChange={() => {
            handleChange({ name: row.fullname, id: row.id });
          }}
        />
      ) : null;
      const athleteImg = (
        <Avatar
          noBorder
          className={styles.athleteImg}
          img={row.img || row.photo}
          name={row.name || row.fullname || row.nickname || ''}
        />
      );
      // const athlete = <AthleteRow name={row.name} img={row.img} id={row.id} />;
      const athlete = (
        <AthleteRow
          noAvatar
          name={row.name || row.fullname || row.nickname || ''}
          id={row.id}
          nickname={row.nickname}
        />
      );
      const nationalities = (
        <NationalitiesRow className={styles.nationalityFlag} nationalities={row?.nationalities} athleteId={row.id} />
      );
      // const loanedTeamContract = row?.loanedTeam?.endOfContract
      //    ? moment(row.loanedTeam.endOfContract).format('DD/MM/YYYY')
      //    : '';

      const age = <span style={{ fontWeight: '500' }}>{row.age}</span>;
      let endOfContract;
      if (loaned) {
        endOfContract = row.contract_info?.src_contract_end;
      } else {
        endOfContract = row.team?.endOfContract || row.contract_info?.current_contract_end;
      }
      endOfContract = endOfContract ? moment(endOfContract).format('DD/MM/YYYY') : '';

      let actionComponent = null;
      if (actionType === 'monitoring')
        actionComponent = (
          <div className={styles.buttonContainer}>
            <MonitoringButton athlete={row} />
          </div>
        );

      let team = null;

      switch (type) {
        case 'elenco':
          const rowTeam = loaned ? row.current_team : row.src_team;
          if (row.contract_info.loaned) {
            const contractEnd = loaned ? row.contract_info?.current_contract_end : row.contract_info?.src_contract_end;
            team = (
              <div className={styles.teamContainer}>
                <span>{rowTeam?.nickname ?? rowTeam?.fullname ?? '-'}</span>
                <br />
                <span className={styles.contractDate}>
                  {contractEnd ? moment(contractEnd).format('DD/MM/YYYY') : '-'}
                </span>
              </div>
            );
          }

          const assignorTeam = rowTeam ? <TeamRow name={team} id={rowTeam?.id} img={rowTeam?.emblem} /> : null;

          return [
            ...(checkbox ? [checkbox] : []),
            athleteImg,
            athlete,
            age,
            row.position,
            nationalities,
            endOfContract,
            assignorTeam,
            ...(actionComponent ? [actionComponent] : []),
          ];
        case 'emprestados':
          if (row.contract_info.loaned) {
            team = (
              <div className={styles.teamContainer}>
                <span>{row.current_team?.nickname ?? row.current_team?.fullname ?? '-'}</span>
                <br />
                <span className={styles.contractDate}>
                  {row.contract_info?.current_contract_end
                    ? moment(row.contract_info.current_contract_end).format('DD/MM/YYYY')
                    : '-'}
                </span>
              </div>
            );
          }

          const loanedTeam = row?.current_team ? (
            <TeamRow name={team} id={row.current_team.id} img={row.current_team.emblem} />
          ) : null;

          return [
            ...(checkbox ? [checkbox] : []),
            athleteImg,
            athlete,
            age,
            row.position,
            nationalities,
            endOfContract,
            loanedTeam,
            ...(actionComponent ? [actionComponent] : []),
          ];
        default:
          break;
      }

      return undefined;
    });
  }

  static getAge(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age -= 1;
    }
    return age;
  }
}
