import React from 'react'
import styles from './index.module.scss'
import Banners from './Banners'
import TransferWindows from './TransferWindows'

export default function RightPanelHome() {
    return (
        <div className={styles.container}>
            <Banners />
            <TransferWindows />
        </div>
    );
}