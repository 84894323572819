import React, { useState, useEffect } from 'react';
import { Spinner, Card, Table, Checkbox, Pagination, StarRating, Dialog } from 'components/template';
import { useSelector, useDispatch } from 'react-redux';
import { getEvaluations, deleteEvaluations } from 'store/ducks/evaluations';
import { getEvaluationsStats } from 'store/ducks/evaluationsStats';
import styles from './styles.module.scss';
import { usePagination } from 'shared/hooks';
import AthleteRow from 'components/AthleteRow/AthleteRow';
import moment from 'moment';
import TeamRow from 'components/TeamRow/TeamRow';
import { useHistory } from 'react-router-dom';
import ActionBar from './ActionBar';
import { useTranslation } from 'react-i18next';
import Charts from './Charts/Charts';

const Evaluations = () => {
  const { t } = useTranslation();

  const initialFilters = {
    name: '',
    analyst: '',
    position: '',
    viewList: true,
    period: {
      before: null,
      after: null,
    },
  };

  const [filters, setFilters] = useState(initialFilters);

  const dispatch = useDispatch();
  const evaluations = useSelector(state => state.evaluations);
  const evaluationsStats = useSelector(state => state.evaluationsStats);
  const [pagination, setPagination, defaultPagination] = usePagination();
  const history = useHistory();

  const [checkedEvaluations, setCheckedEvaluations] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  useEffect(() => {
    if (filters.viewList) {
      dispatch(getEvaluations({ ...pagination, ...filters }));
    }
  }, [dispatch, pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filters.viewList) {
      dispatch(getEvaluations({ ...defaultPagination, ...filters }));
    } else {
      dispatch(getEvaluationsStats({ ...filters }));
    }
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (allChecked) {
      setCheckedEvaluations(
        evaluations.data.map(evaluation => ({ id: evaluation.id, athlete: evaluation.target_data?.id }))
      );
    } else {
      setCheckedEvaluations([]);
    }
  }, [allChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEvaluationCheck = evaluation => {
    if (checkedEvaluations.some(item => item.id === evaluation.id)) {
      setCheckedEvaluations(checkedEvaluations.filter(item => item.id !== evaluation.id));
    } else {
      setCheckedEvaluations([...checkedEvaluations, evaluation]);
    }
  };

  const listToTable = evaluations => {
    return evaluations.map(evaluation => {
      const today = moment();
      const checkbox = (
        <Checkbox
          dark
          checked={checkedEvaluations.some(ckdEval => ckdEval.id === evaluation.id)}
          onChange={() => {
            handleEvaluationCheck({ id: evaluation.id, athlete: evaluation.target_data?.id });
          }}
        />
      );
      const athlete = (
        <AthleteRow
          img={evaluation.target_data?.photo}
          name={evaluation.target_data?.fullname}
          nickname={evaluation.target_data?.nickname}
          id={evaluation.target_data?.id}
        />
      );
      let age_text = '-';
      if (evaluation.target_data?.birthdate) {
        const birthdate = moment(evaluation.target_data.birthdate);
        const age_math = today.diff(birthdate, 'years');
        age_text = `'${evaluation.target_data?.birthdate?.toString().slice(2, 4)} (${age_math})`;
      }
      const age = <span style={{ fontWeight: '500' }}>{age_text}</span>;

      const lassignorTeamInfo = (
        <div className={styles.teamContainer}>
          <span>{evaluation.target_data?.current_team?.nickname}</span>
          <br />
          <span className={styles.contractDate}>
            {evaluation.target_data?.contract_info?.current_contract_end
              ? moment(evaluation.target_data?.contract_info.current_contract_end).format('DD/MM/YYYY')
              : '-'}
          </span>
        </div>
      );

      const current_team = evaluation.target_data?.current_team?.nickname ? (
        <TeamRow
          name={lassignorTeamInfo}
          id={evaluation.target_data?.current_team.id}
          img={evaluation.target_data?.current_team.emblem}
        />
      ) : null;
      const position = evaluation.target_data?.position
        ? evaluation.target_data?.position
        : evaluation.target_data?.position1
        ? evaluation.target_data?.position1
        : evaluation.target_data?.position2
        ? evaluation.target_data?.position2
        : '-';
      const team = (
        <TeamRow
          name={evaluation.team_data?.nickname}
          id={evaluation.team_data?.id}
          img={evaluation.team_data?.emblem}
        />
      );
      const data = moment(evaluation.evaluation_date).format('DD/MM/YYYY');
      const author = (
        <span className={styles.darkPurple9}>
          {evaluation?.author?.name ? evaluation?.author?.name : evaluation?.author?.email}
        </span>
      );

      const generalEvaluation = (
        <div style={{ display: 'flex', gap: '5px' }}>
          <StarRating rate={evaluation.general_evaluation} />
          <p>({evaluation?.general_evaluation ? evaluation.general_evaluation.toFixed(2) : '0.00'})</p>
        </div>
      );

      const direc = evaluation?.recommendation && (t('config.' + evaluation?.recommendation) || '');
      const model = t('avaliationModel.' + evaluation?.mode) || '';

      const priority =
        (
          <div className={styles.priority}>
            {evaluation?.priority === 'ALTA' && <div className={styles.highPriority} />}
            {evaluation?.priority === 'MEDIA' && <div className={styles.mediumPriority} />}
            {evaluation?.priority === 'BAIXA' && <div className={styles.lowPriority} />}
            {evaluation?.priority && t('config.' + evaluation?.priority)}
          </div>
        ) || '';
      const evaluationButton = (
        <div
          className={styles.smallestButton}
          onClick={() => {
            history.push(`/atletas/${evaluation.target_data?.id}/avaliacoes/${evaluation.id}`);
          }}
        >
          {t('table.text2')}
        </div>
      );

      return [
        checkbox,
        athlete,
        current_team,
        age,
        position,
        team,
        data,
        author,
        generalEvaluation,
        model,
        direc,
        priority,
        evaluationButton,
      ];
    });
  };

  const handleEvaluationsDelete = () => {
    setIsOpenDialog(false);
    dispatch(
      deleteEvaluations(
        checkedEvaluations.map(evaluation => evaluation.id),
        { ...pagination }
      )
    );
    setCheckedEvaluations([]);
  };

  if (evaluations.error) {
    return (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(evaluations.error)}</p>
      </Card>
    );
  }

  return (
    <>
      <div className={styles.content}>
        <Card softShadow className={styles.evaluations}>
          {/* <ActionBar checkedEvaluations={checkedEvaluations} setIsOpenDialog={setIsOpenDialog} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} /> */}
          <ActionBar
            checkedEvaluations={checkedEvaluations}
            setIsOpenDialog={setIsOpenDialog}
            filters={filters}
            setFilters={setFilters}
          />
          {evaluations.loading || evaluationsStats.loading ? (
            <div className={styles.loader}>
              <Spinner />
            </div>
          ) : filters.viewList ? (
            <div className={styles.tableContainer}>
              <Table
                flat
                theads={[
                  <Checkbox dark checked={allChecked} onChange={() => setAllChecked(!allChecked)} />,
                  t('athlete_list.box1'),
                  <div className={styles.club_column}>
                    {t('agencies.text2')} /<br /> {t('filters.contract')}
                  </div>,
                  t('athlete_list.box3'),
                  t('athlete_list.box4'),
                  <div className={styles.club_at_the_moment}>
                    {t('agencies.club_at_moment1')} <br /> {t('agencies.club_at_moment2')}
                  </div>,
                  t('organi.text6'),
                  t('table.analist'),
                  t('table.general'),
                  t('myClub.negotiations.model'),
                  t('myClub.negotiations.direc'),
                  t('myClub.negotiations.priority'),
                  '',
                ]}
                className={styles.evaluationsTable}
                data={listToTable(evaluations.data)}
              />
            </div>
          ) : (
            <Charts data={evaluationsStats.data} />
          )}
        </Card>
      </div>
      {filters.viewList && (
        <Pagination
          length={evaluations.data.length}
          hasNext={evaluations.hasNext}
          hasPrevious={evaluations.hasPrevious}
          pagination={pagination}
          count={evaluations.count}
          setPagination={setPagination}
          resourceDisplayName={t('club.box26')}
        />
      )}
      <Dialog
        isOpen={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        mainTitle={t('organi.text8')}
        btnLeft={t('dialog_content.cancel')}
        btnRight={t('organi.text9')}
        btnLeftClick={() => setIsOpenDialog(false)}
        btnRightClick={() => handleEvaluationsDelete()}
      >
        {t('organi.text10')}
      </Dialog>
    </>
  );
};

export default Evaluations;
