import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dialog, Input, Menu, Spinner, Table, Button } from 'components/template';
import styles from './SelectTeamDialog.module.scss';
import { FaSearch } from 'react-icons/fa';
import { checkboxesAdvancedSearch } from 'shared/mocks';
import { getTeams } from 'store/ducks/teams';
import TeamRow from 'components/TeamRow/TeamRow';

const SelectTeamDialog = ({ isOpen, setIsOpen, setTeam }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { countries } = useSelector(state => state.countries);
  const teams = useSelector(state => state.teams.teams);

  const divisions = {
    '1': t('home.text6'),
    '2': t('home.text7'),
    '3': t('home.text8'),
    '4': t('home.text9'),
  };

  const initialFilters = {
    q: '',
    country: 'BR',
    division: '1',
    limit: 50,
  };
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    dispatch(getTeams(filters));
  }, [dispatch, filters]);

  const onClose = () => {
    setIsOpen(false);
  };

  const tableData = teams.data?.map(row => {
    const country = <img className={styles.selectTeamCountryFlag} src={row.country?.flag} alt="" />;
    const division = divisions[row.division];
    const club = <TeamRow img={row.emblem} name={row.nickname} />;
    const button = (
      <Button
        color="link"
        noShadow
        onClick={() => {
          setIsOpen(false);
          setTeam(row);
        }}
      >
        {t('athlete_card.selectTeam')}
      </Button>
    );

    return [country, division, club, '', button];
  });

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      mainTitle={t('athlete_card.selectTeam')}
      className={{ body: styles.dialogBody }}
      showBackdrop={false}
    >
      <div className={styles.selectTeamFilters}>
        <div className={styles.selectTeamInputContainer}>
          <Input
            className={styles.selectTeamInput}
            placeholder={t('navDrawer.name')}
            icon={<FaSearch />}
            iconPosition="right"
            onChange={e => {
              setFilters({
                ...filters,
                q: e.target.value,
              });
            }}
            value={filters.q}
          />
        </div>
        <Menu
          className={styles.selectTeamCountryMenu}
          rounded
          title={t('compe.text1')}
          placeholder={t('compe.text1')}
          options={countries?.data?.map(country => ({ label: country.name, value: country.code }))}
          setValue={value => {
            setFilters({
              ...filters,
              country: value,
            });
          }}
          onClear={() =>
            setFilters({
              ...filters,
              country: '',
            })
          }
          value={filters.country}
        />
        <Menu
          className={styles.selectTeamLeagueMenu}
          rounded
          title={t('organi.text12')}
          placeholder={t('organi.text12')}
          options={checkboxesAdvancedSearch.division.map(item => ({ label: item.label, value: item.value }))}
          setValue={value => {
            setFilters({
              ...filters,
              division: value,
            });
          }}
          onClear={() =>
            setFilters({
              ...filters,
              division: '',
            })
          }
          value={filters.division}
        />
      </div>
      <div className={styles.selectTeamTableContainer}>
        {teams.loading ? (
          <Spinner height="40" width="40" className={styles.spinner} />
        ) : (
          <Table
            theads={[t('compe.text1'), t('organi.text12'), t('athlete_list.box2'), '', '']}
            className={styles.selectTeamTable}
            data={tableData}
          />
        )}
      </div>
    </Dialog>
  );
};

export default SelectTeamDialog;
