import React, { useState, useEffect } from 'react';
import styles from './MinMaxInput.module.scss';
import { Input } from 'components/template';
import { Button } from 'components/template';
import { FaSearch } from 'react-icons/fa';

const MinMaxInput = ({ state,setMinMax, maxLabel, minLabel, setErrorState, hideSearchBtn = false }) => {
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const [error, setError] = useState(false);
  // const [firstMount, setFirstMount] = useState(true);

  useEffect(() => {      
    // setFirstMount(false);
    setMin(state.min);
    setMax(state.max);
  }, [state]);

  useEffect(() => {
    setErrorState(error);
  }, [error, setErrorState]);

  useEffect(() => {   
    // if(!firstMount) {
      if (min !== '' && max !== '' && Number(max) < Number(min)) {
        setError(true);
      } else {
        // setMinMax({ min, max });
        setError(false);
      }
    // }
    // eslint-disable-next-line
  },[min, max]);

  const handleInput = (type, value) => {
    if (type === 'min') {
      if (value === '') {
        setMin('');
      } else {
        if (max !== '' && Number(value) > Number(max)) {
          setMax('');
        }

        setMin(Number(value) >= 0 ? Number(value) : 0);
      }
    } else {
      if (value === '') {
        setMax('');
      } else {
        setMax(Number(value) >= 0 ? Number(value) : 0);
      }
    }
  };

  return (
    <div className={styles.minMaxContainer}>
      <div className={styles.inputMinMaxContainer}>
        <span>{minLabel}</span>
        <Input
          containerClassName={styles.inputContainer}
          type="number"
          value={min}
          onChange={event => handleInput('min', event.target.value)}
        />
      </div>
      <div className={styles.inputMinMaxContainer}>
        <span>{maxLabel}</span>
          <Input
            containerClassName={`${styles.inputContainer}${error ? ` ${styles.inputContainerError}` : ''}`}
            type="number"
            value={max}
            onChange={event => handleInput('max', event.target.value)}
          />      
      </div>  
      {!hideSearchBtn &&
        <div className={styles.inputMinMaxContainer}>
            <Button
              onClick={() => setMinMax({ min, max })}
              className={styles.searchBtn}
            >
              <FaSearch/>
            </Button>
        </div>
      }
    </div>
  );
};

MinMaxInput.defaultProps = {
  minLabel: 'Mín',
  maxLabel: 'Máx',
};

export default MinMaxInput;
