import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './LanguageCard.module.scss';
import BrFlag from 'assets/ico/brFlag.png';
//import PtFlag from 'assets/ico/ptFlag.png';
import UkFlag from 'assets/ico/ukFlag.png';
import EspFlag from 'assets/ico/espFlag.png';
import CheckCircle from 'assets/ico/checkcircle.png';
import { PopCard } from 'components/template';

function LanguageCard({ isVisible, hide }) {
  const { i18n } = useTranslation();
  const defaultLanguage = localStorage.getItem('language') ?? 'br';
  const [checkButton, setCheckButton] = useState(defaultLanguage);
  const check = <img draggable="false" className={styles.checkIcon} src={CheckCircle} alt="Check" />;
  const brFlag = <img draggable="false" className={styles.icon} src={BrFlag} alt="Bandeira do Brasil" />;
  //const ptFlag = <img draggable='false' className={styles.icon} src={PtFlag} alt='Bandeira de Portugal' />
  const ukFlag = <img draggable="false" className={styles.icon} src={UkFlag} alt="Bandeira do Reino Unido" />;
  const espFlag = <img draggable="false" className={styles.icon} src={EspFlag} alt="Bandeira da Espanha" />;

  const br = 'br';
  const en = 'en';
  const esp = 'esp';
  //const pt = "pt";
  //onChange={(e) => i18n.changeLanguage(e.target.value)}
  //selected={i18n.language === 'en'}
  const handleClick = language => {
    setCheckButton(language);
    if (window.location.pathname.includes('login')) {
      //remove possível parametro "language", para não dar conflito
      const languageUrl = {
        en: 'en-us',
        br: 'pt-br',
        esp: 'es-es',
      };
      window.location.href = `/login?language=${languageUrl[language]}`;
    } else {
      i18n.changeLanguage(language);
      localStorage.setItem('language', language);
      window.location.reload();
    }
  };

  return (
    <PopCard isVisible={isVisible} hide={hide} className={styles.container} style={{ left: '-100px', top: '40px' }}>
      <div onClick={() => handleClick(br)} className={styles.link}>
        <span className={styles.flag}>{brFlag}</span>
        <span className={styles.texto}>Português (Brasileiro)</span>
        <span className={styles.checkIcon}>{checkButton === 'br' && check}</span>
      </div>
      {/* <div onClick={() => handleClick(pt)} className={styles.link}>
                <span className={styles.flag}>{ptFlag}</span>
                <span className={styles.texto}>Português</span>
                <span className={styles.checkIcon}>{checkButton === "pt" && check}</span>
            </div> */}
      <div onClick={() => handleClick(en)} className={styles.link}>
        <span className={styles.flag}>{ukFlag}</span>
        <span className={styles.texto}>English</span>
        <span className={styles.checkIcon}>{checkButton === 'en' && check}</span>
      </div>
      <div onClick={() => handleClick(esp)} className={styles.link}>
        <span className={styles.flag}>{espFlag}</span>
        <span className={styles.texto}>Español</span>
        <span className={styles.checkIcon}>{checkButton === 'esp' && check}</span>
      </div>
    </PopCard>
  );
}

LanguageCard.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
};

export default withRouter(LanguageCard);
