import Endpoints from 'endpoints';
import axios from 'api';
import ErrorMessages from 'shared/ErrorMessages';
import { stringifyFilters } from 'shared/utility';

export const actionTypes = {
  GET_COUNTRIES: 'countries/GET',
  GET_COUNTRIES_SUCCESS: 'countries/GET_SUCCESS',
  GET_COUNTRIES_FAIL: 'countries/GET_FAIL',
};

const initialState = {
  countries: {
    loading: false,
    error: null,
    data: [],
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Countries
    case actionTypes.GET_COUNTRIES:
      return {
        ...state,
        countries: {
          ...state.countries,
          loading: true,
        },
      };
    case actionTypes.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: {
          ...state.countries,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_COUNTRIES_FAIL:
      return {
        ...state,
        countries: {
          ...state.countries,
          loading: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
}

let _loadingCountries = false;
let _loadedCountries = false;
let _getCountriesWithFilters = null;
export const getCountries = filters => async dispatch => {
  const hasFilters = filters != null;
  if (!_loadingCountries && (hasFilters !== _getCountriesWithFilters || !_loadedCountries)) {
    _getCountriesWithFilters = hasFilters;
    _loadingCountries = true;

    dispatch({ type: actionTypes.GET_COUNTRIES });
    const url = Endpoints.countries;

    try {
      let queryString = stringifyFilters(filters);

      const { data } = await axios.get(`${url}${queryString}`);

      const countries = [];
      let brCountry = {};
      for (const key in data) {
        const country = data[key].country;
        if (country) {
          if (country.code === 'BR') {
            brCountry = country;
          } else {
            countries.push(country);
          }
        }
      }
      countries.sort((a, b) => a.name.localeCompare(b.name));
      _loadedCountries = countries.length > 0;

      dispatch({ type: actionTypes.GET_COUNTRIES_SUCCESS, payload: { data: [brCountry, ...countries] } });
    } catch (err) {
      const { response } = err;

      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_COUNTRIES_FAIL,
          payload: { error: 'erros.text19' },
        });
      } else if (response?.status === 401) {
        dispatch({
          type: actionTypes.GET_COUNTRIES_FAIL,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_COUNTRIES_FAIL,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }

    //previne multiplas chamadas de "getCountries", já que muitos arquivo dependem dos países.
    setTimeout(() => {
      _loadingCountries = false;
    }, 2000);
  }
};

export const resetCountries = () => {
  _loadingCountries = false;
  _loadedCountries = false;
  _getCountriesWithFilters = null;
}
