import React from 'react';
import * as S from './styles';
import { Button } from 'components/template';
import { MdEdit } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

export default function ShadowTeamLeftHeader({ editButtonClick = () => {} }) {
  const { t } = useTranslation();
  return (
    <>
      <S.LeftHeader>
        <h3>{t('proj.montarTimeSombra')}</h3>
        {t('proj.montarTimeSombraDesc')}
      </S.LeftHeader>
      <Button
        onClick={editButtonClick}
        rounded
        color="green"
        smaller
        style={{
          fontWeight: '700',
          margin: '0 auto 10px 15px',
          padding: '10px 10px 10px 15px',
        }}
      >
        {t('proj.editarTimeSombra')}
        <MdEdit size="15px" style={{ marginLeft: '10px', marginBottom: '-1px' }} />
      </Button>
    </>
  );
}
