import React, { useState, useEffect } from 'react';
import { FaTrashAlt, FaSearch } from 'react-icons/fa';
import { IconButton, Input, Menu } from 'components/template';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { getUsers } from 'store/ducks/user';
import { debounce } from 'shared/utility';

export default function ActionBar({ reports, filters, setFilters, setIsOpenDialog, checkedReports }) {
  const [analysts, setAnalysts] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (analysts == null) {
      getUsers().then(usersApi => setAnalysts(usersApi?.data?.results));
    }
  });

  return (
    <div className={styles.actionBar}>
      <div className={styles.actionButtons}>
        <IconButton
          className={styles.deleteButton}
          onClick={() => {
            setIsOpenDialog(true);
          }}
          disabled={checkedReports.length === 0}
          icon={<FaTrashAlt />}
          text={t('buttons.excluir')}
        />
      </div>
      <Input
        rounded
        placeholder={t('professionals.filtersInputName')}
        containerClassName={styles.inputContainer}
        onChange={e => {
          debounce(setFilters)({
            ...filters,
            athlete: e.target.value,
          });
        }}
        icon={<FaSearch className={styles.icon} />}
        iconPosition="right"
      />
      <Menu
        title={t('table.analist')}
        rounded
        allowEmpty
        placeholder={t('table.analist')}
        className={styles.menu}
        options={
          analysts
            ? analysts.map(item => ({
                label: item.name,
                value: item.id,
              }))
            : [
                {
                  label: t('proj.text20'),
                  value: 0,
                },
              ]
        }
        value={filters['owner']}
        onClear={() => {
          setFilters({ ...filters, owner: null });
        }}
        setValue={val => {
          setFilters({ ...filters, owner: val ? val : null });
        }}
      />
    </div>
  );
}
