import React, { useEffect } from 'react';
import styles from './Users.module.scss';
import { Avatar } from 'components/template';
import { useTranslation } from 'react-i18next';
import { getAgencyUsers } from 'store/ducks/agencies';
import { useDispatch, useSelector } from 'react-redux';

export default function Users({ agency }) {
  const { t } = useTranslation();
  const agencyId = agency.id;

  const dispatch = useDispatch();
  const { data } = useSelector(state => state.agencies.users);

  useEffect(() => {
    dispatch(getAgencyUsers(agencyId));
  }, [agencyId, dispatch]);

  const concatName = (name, lastName) => {
    if (lastName) {
      return `${name} ${lastName}`;
    }
    return name;
  };

  function sliceDataArray(array) {
    return array.slice(0, 5);
  }

  function buildCard(user) {
    return (
      <div className={styles.athleteContainer} key={user.id}>
        <div className={styles.left}>
          <Avatar img={user.photo} name={concatName(user.first_name, user.last_name)} className={styles.avatar} />
        </div>
        <div className={styles.middle}>
          <span className={styles.name}>{concatName(user.first_name, user.last_name)}</span>
          <div className={styles.info}>
            {user.occupation && <span className={styles.completeName}>{user.occupation} |</span>}
            {/* <img src={'item.org?.emblem'} alt={'item.org.name' ?? ''} draggable="false" />
              <span className={styles.clubName}>{'item.org.name'}</span> */}
          </div>
          <div className={styles.infoCountry}>
            {/* <img src={'item.org.country?.flag'} alt={'item.org.country.name' ?? ''} draggable="false" /> */}
            {user.address && (
              <span>
                {user.city}, {user.address}, {user.country}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.usersContainer}>
      <h1 className={styles.title}>{t('user.text3')}</h1>
      {data.length > 5
        ? sliceDataArray(data).map(user => {
            return buildCard(user);
          })
        : data.map(user => {
            return buildCard(user);
          })}
      <div className={styles.users_container_footer} onClick={() => (window.location.href = '/minha-agencia/usuarios')}>
        <h1>{t('pagina_inicial.homepage_5')} +</h1>
      </div>
    </div>
  );
}
