import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MdAssignment } from 'react-icons/md';
import { Button } from 'components/template';
import * as S from './styles';
import { Creators as layoutActions } from 'store/ducks/layout';
import { getReportIdFromShadowTeam } from 'store/ducks/reports';
import { useParams } from 'react-router-dom';

export default function GenerateReportButton({ disabled, positionsName, getAthletesIds }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { project_id, id: shadow_team_id } = useParams();

  const project = useSelector(state => state.project);
  const shadowTeamReportId = project?.shadowTeamsData?.report_id;

  return (
    <Button
      rounded
      fluid
      outline={!shadowTeamReportId}
      smaller
      color={shadowTeamReportId ? 'orange' : 'green'}
      disabled={disabled}
      style={{
        width: '160px',
        fontSize: '14px',
        height: '35px',
        marginTop: '3px',
        marginLeft: 'auto',
        padding: '0',
        marginRight: '10px',
        fontWeight: 700,
        ...(disabled
          ? {
              backgroundColor: 'lightgrey',
              cursor: 'default',
              color: 'white',
              borderColor: 'white',
            }
          : {}),
      }}
      onClick={async () => {
        const athletesIds = await getAthletesIds();
        if (athletesIds.length > 0) {
          let reportId = shadowTeamReportId;
          if (!reportId) {
            reportId = (await getReportIdFromShadowTeam(project_id, shadow_team_id, athletesIds)) ?? 0;
          }
          if (reportId) {
            window.open(`/relatorios/${reportId}`);
          } else {
            if (reportId === -1) {
              dispatch(
                layoutActions.showToast({
                  content: <S.ToastMessage>{t('proj.text11')}</S.ToastMessage>,
                  duration: 3000,
                })
              );
            } else {
              let reportUrl = `/relatorios/criar?ids=${athletesIds.join(
                ','
              )}&project=${project_id}&shadow_team=${shadow_team_id}`;
              if (positionsName) {
                reportUrl = `${reportUrl}&positions=${positionsName}`;
              }
              window.open(reportUrl);
            }
          }
        }
      }}
    >
      {t(shadowTeamReportId ? 'proj.box2' : 'athlete_list.tip')}
      <MdAssignment size="16px" style={{ marginLeft: '10px', marginBottom: '-3px' }} />
    </Button>
  );
}
