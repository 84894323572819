import styled from 'styled-components';
import Variables from 'variables.js';
import { Card as DefaultCard, Button as DefaultButton } from 'components/template';

export const GlobalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #f5f5f5;
`;

export const FilterContainer = styled(DefaultCard)`
  box-shadow: none !important;
  padding: 8px;
  display: flex;
  justify-content: space-between;
`;

export const MenuFilter = styled.div`
  display: flex;
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
`;

export const LeftPanelConteinar = styled.div`
  width: 420px;
  display: flex;
  flex-direction: column;
  padding: 15px 15px 13px 30px;
`;

export const LeftPanel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 1px 1px 1px ${Variables.lighterGrey};
  border: solid 1px ${Variables.lightBorder};
  border-radius: 8px;
  overflow: auto;
`;

export const LineUp = styled.div`
  flex: 1;
  padding: 15px;
  overflow: auto;
`;

export const SpanTimeSombra = styled.span`
  font-weight: 700;
  margin-left: 10px;
  font-size: 22px;
  color: #000000;
  align-self: center;
`;

export const PageBackButton = styled(DefaultButton)`
  background-color: transparent;
  border: none;
  width: 22px;
  font-size: 22px;
  padding: 0;
  margin-left: 20px;
  padding-top: 2px;
`;

export const ToastMessage = styled.span`
  padding: 20px 0;
`;
