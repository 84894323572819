// import { useEffect } from 'react';
import axios from 'api';

const sendOnlineStatus = (id) => {  
  const url = '/user_session_log';    
  axios.post(`${url}`, { user: id, plataform: "web" });
};

const checkAndSendStatus = (id) => {
  const lastPingTime = localStorage.getItem('lastPingTime');
  const currentTime = new Date().getTime();

  if (!lastPingTime || currentTime - lastPingTime >= 180000) {        
    sendOnlineStatus(id);
    localStorage.setItem('lastPingTime', currentTime.toString());
  }
};

export default checkAndSendStatus;
