import React, { useState } from 'react';
import { Input, Menu, Checkbox } from 'components/template';
import { useTranslation } from 'react-i18next';
import { AiOutlineSearch } from 'react-icons/ai';

import * as S from './styles';

const categoryOptions = [
  {
    label: 'Global',
    value: 'global',
  },
  {
    label: 'Latam',
    value: 'latam',
  },
  {
    label: 'Brasil',
    value: 'brazil',
  },
];

const ActionBar = ({ filter, setFilter }) => {
  const [timeoutId, setTimeoutId] = useState(null);
  const [name, setName] = useState(filter.name ?? '');
  const { t } = useTranslation();

  const handleInputChange = value => {
    clearTimeout(timeoutId);
    setName(value);
    setTimeoutId(
      setTimeout(() => {
        setFilter({
          ...filter,
          name: value,
        });
      }, 800)
    );
  };

  return (
    <S.Container>
      <S.InputContainer>
        <Input
          rounded
          placeholder={t('agencies.searchName')}
          onChange={e => {
            handleInputChange(e.target.value);
          }}
          icon={<AiOutlineSearch />}
          iconPosition="right"
          value={name}
        />
      </S.InputContainer>
      <S.MenuContainer>
        <Menu
          title={t('agencies.text7')}
          largeMenu
          rounded
          placeholder={t('agencies.text9')}
          options={categoryOptions ?? []}
          value={filter.agency_subtype}
          onClear={() => setFilter({ ...filter, agency_subtype: undefined })}
          setValue={val => setFilter({ ...filter, agency_subtype: val })}
        />
      </S.MenuContainer>
      <S.Checkboxes>
        <Checkbox
          value={filter.is_verified_agency}
          onChange={e => setFilter({ ...filter, is_verified_agency: filter.is_verified_agency ? undefined : true })}
          label={t('agencies.text10')}
          checked={filter.is_verified_agency}
          dark
        />
        <Checkbox
          value={filter.is_fifa_agency}
          onChange={e => setFilter({ ...filter, is_fifa_agency: filter.is_fifa_agency ? undefined : true })}
          label={t('agencies.text8')}
          checked={filter.is_fifa_agency}
          dark
        />
      </S.Checkboxes>
    </S.Container>
  );
};

export default ActionBar;
