import React from 'react';
import PropTypes from 'prop-types';

import styles from './Divider.module.scss';

export default function Divider({ vertical, className, ...props }) {
  const dividerClasses = [styles.divider];
  if (vertical) dividerClasses.push(styles.vertical);
  if (className) dividerClasses.push(className);

  return (
    <div {...props} className={dividerClasses.join(' ')}>
      <span>_</span>
    </div>
  );
}

Divider.defaultProps = {
  vertical: false,
  className: null,
};

Divider.propTypes = {
  vertical: PropTypes.bool,
  className: PropTypes.string,
};
