import React from 'react';
import styles from './CountryGrid.module.scss';
import PropTypes from 'prop-types';

export default function CountryGridItem({ country, onSetFilter }) {
    return (
        <div title={country.name ?? ''} className={styles.gridItem} onClick={() => onSetFilter({ type: 'country', value: country.code })}>
            <img src={country.flag} className={styles.countryFlag} alt={`Bandeira ${country.name}`} />
            <div className={styles.countryName}>
                <p>{country.name}</p>
            </div>
        </div>
    );
}

CountryGridItem.propTypes = {
    country: PropTypes.object.isRequired,
    onSetFilter: PropTypes.func.isRequired,
}

