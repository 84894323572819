import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Spinner, MinPagination, Button, Menu } from 'components/template';
import TeamRow from 'components/TeamRow/TeamRow';
import moment from 'moment';
import { getAthleteMatches, getAthleteFutureMatches, getAthleteHistorical } from 'store/ducks/athleteV2';
import { usePagination } from 'shared/hooks';
import styles from './Matches.module.scss';
import IcoGols from 'assets/ico/ico-gols.png';
import IcoAmarelos from 'assets/ico/ico-amarelos.png';
import IcoAmareloVermelho from 'assets/ico/ico-amarelo-vermelho.png';
/*
import IcoTempo from 'assets/ico/ico-tempo.png';
import IcoTitular from 'assets/ico/ico-titular.png';
import IcoBanco from 'assets/ico/ico-banco.png';
import IcoEntrou from 'assets/ico/ico-entrou.png';
import IcoSaiu from 'assets/ico/ico-saiu.png';
*/
import IcoVermelhos from 'assets/ico/ico-vermelhos.png';
import Sumula from 'assets/notebook.png';
import video from 'assets/video-camera (1).png';
import { useTranslation } from 'react-i18next';

function Matches({ athleteId }) {
  const dispatch = useDispatch();
  const athlete = useSelector(state => state.athlete.athleteData);
  const athleteMatches = useSelector(state => state.athleteV2.athleteMatches);
  const athleteFutureMatches = useSelector(state => state.athleteV2.athleteFutureMatches);
  const athleteHistorical = useSelector(state => state.athleteV2.athleteHistorical);

  const [pagination, setPagination] = usePagination(5);
  const [futurePagination, setFuturePagination] = usePagination(5);
  const { t } = useTranslation();

  const [showFilterTabelMatches, setShowFilterTabelMatches] = useState(false);
  const [infoTabelMatches, setInfoTabelMatches] = useState(false);
  const [mapItem, setMapItem] = useState(null);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [filters, setFilters] = useState({
    season__name: '',
    q: '',
  });

  const initialFilterComp = {
    competition: [],
    season: [],
  };
  const [filterComp, setFilterComp] = useState(initialFilterComp);

  const [data, setData] = useState([]);
  const [futureData, setFutureData] = useState([]);
  const athleteTeamId = athlete.data?.current_team?.id;

  useEffect(() => {
    dispatch(getAthleteHistorical(athleteId));
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let queryFilters = {
      ...filters,
      ...pagination,
    };

    dispatch(getAthleteMatches(athleteId, queryFilters));
  }, [dispatch, pagination, filters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let futureFilters = {
      ...filters,
      ...futurePagination,
    };

    dispatch(getAthleteFutureMatches(athleteId, futureFilters));
  }, [dispatch, futurePagination, filters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (athleteHistorical.data.length > 0 && filterComp === initialFilterComp) {
      createCompFilters(athleteHistorical.data);
    }
  }, [athleteHistorical.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!athleteMatches.loading && athleteMatches.error == null) {
      setData(athleteMatches.data.data);
      createFilters(athleteMatches.data.data);
    }
  }, [athleteMatches.data.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (athleteFutureMatches.data.data.length > 0) {
      setFutureData(athleteFutureMatches.data.data);
      createFutureFilters(athleteFutureMatches.data.data);
    }
  }, [athleteFutureMatches.data.data]);

  async function createCompFilters(data) {
    let seasons = [];
    let competitions = [];

    await data.forEach(register => {
      seasons.push(register.season.toUpperCase());
      competitions.push(register.competition_name.toUpperCase());
    });

    seasons.sort((a, b) => (a > b ? -1 : 1));
    competitions.sort((a, b) => (a > b ? 1 : -1));

    setFilterComp({
      season: [...new Set(seasons)],
      competition: [...new Set(competitions)],
    });
  }

  async function createFilters(data) {
    let competitions = [];

    await data.forEach(register => {
      competitions.push(register.competition_name);
    });
  }

  async function createFutureFilters(data) {
    let competitions = [];

    await data.forEach(register => {
      competitions.push(register.competition_name);
    });
  }

  function filterData(data) {
    const filteredData = data.map(register => {
      const home_team = (
        <TeamRow
          className={styles.teamName}
          name={register.home_team?.nickname}
          id={register.home_team?.id}
          img={register.home_team?.emblem}
        />
      );
      const home_team_left = (
        <TeamRow name={register.home_team?.nickname} id={register.home_team?.id} img={register.home_team?.emblem} />
      );
      const visiting_team = (
        <TeamRow
          className={styles.teamNameVisiting}
          name={register.visiting_team?.nickname}
          id={register.visiting_team?.id}
          img={register.visiting_team?.emblem}
        />
      );
      const visiting_team_left = (
        <TeamRow
          name={register.visiting_team?.nickname}
          id={register.visiting_team?.id}
          img={register.visiting_team?.emblem}
        />
      );
      const match_date = register.match_date ? moment(register.match_date).format('DD/MM/YYYY') : '-';
      const scoreboard = register.scoreboard?.trim() ?? '';
      let scoreboard1 = '-';
      let scoreboard2 = '-';
      if (scoreboard.includes('(')) {
        let penaltySplit = scoreboard.split('(') ?? [];
        if (penaltySplit.length === 2) {
          const scoreboardSplit = penaltySplit[0].split('-');
          penaltySplit = penaltySplit[1].split('-');
          if (scoreboardSplit.length === 2 && penaltySplit.length === 2) {
            scoreboard1 = (
              <div className={styles.scoreNumbers}>
                {scoreboardSplit[0]} ({penaltySplit[0]})
              </div>
            );
            scoreboard2 = (
              <div className={styles.scoreNumbers}>
                {scoreboardSplit[1]} ({penaltySplit[1]}
              </div>
            );
          }
        }
      } else {
        const scoreboardSplit = scoreboard.split('-') ?? [];
        if (scoreboardSplit.length === 2) {
          scoreboard1 = <div className={styles.scoreNumbers}>{scoreboardSplit[0]}</div>;
          scoreboard2 = <div className={styles.scoreNumbers}>{scoreboardSplit[1]}</div>;
        }
      }
      const competition = register.competition_name;
      const goals = register.events.filter(event => event === 'G').length;
      const yellowCards = register.events.filter(event => event === 'YC').length;
      const secondYellow = register.events.filter(event => event === 'SYC').length;
      const redCards = register.events.filter(event => event === 'RC').length;
      let docket = '-';
      if (register.match_url) {
        if (register.match_url.includes('://')) docket = register.match_url;
      }
      const videos = register.match_video;
      // todo: endpoint missing videos

      return {
        id: register.id,
        match_date,
        competition,
        home_team,
        home_team_left,
        scoreboard1,
        scoreboard2,
        visiting_team,
        visiting_team_left,
        goals,
        yellowCards,
        secondYellow,
        redCards,
        docket,
        videos,
      };
    });

    return filteredData;
  }

  function filterFutureData(data) {
    const filteredData = data.map(register => {
      const home_team = (
        <TeamRow name={register.home_team?.nickname} id={register.home_team?.id} img={register.home_team?.emblem} />
      );
      const visiting_team = (
        <TeamRow
          name={register.visiting_team?.nickname}
          id={register.visiting_team?.id}
          img={register.visiting_team?.emblem}
        />
      );
      const match_date = register.match_date ? moment(register.match_date).format('DD/MM/YYYY') : '-';
      const competition = register.competition_name;

      return {
        match_date,
        competition,
        home_team,
        visiting_team,
      };
    });

    return filteredData;
  }

  function handleItem(item, index) {
    setMapItem(item);
    setInfoTabelMatches(true);
    setSelectedMatch(index);
  }

  const buttonClasses = [styles.buttons];
  if (showFilterTabelMatches) {
    buttonClasses.push(styles.falseBorder);
  } else {
    buttonClasses.push(styles.trueBorder);
  }

  let content;

  if (!athleteMatches.error) {
    content = (
      <div className={styles.container}>
        <div className={styles.header_container}>
          <span className={styles.header}>{t('match.text3')}</span>
          <span className={styles.sub_header}>{t('match.text7')}</span>
        </div>
        <div className={styles.panelContainer}>
          <div className={styles.leftContainer}>
            <div className={styles.buttons}>
              <div
                onClick={() => setShowFilterTabelMatches(false)}
                className={!showFilterTabelMatches ? buttonClasses.join(' ') : styles.buttons}
              >
                <span>{t('match.text4')}</span>
              </div>
              {/*
                <div
                  onClick={() => setShowFilterTabelMatches(true)}
                  className={showFilterTabelMatches ? buttonClasses.join(' ') : styles.buttons}
                >
                  <span>{t('match.text1')}</span>
                </div>
                */}
            </div>
            <div className={styles.filterContainer}>
              {/*
                <Menu
                  title={t('slider_menu.seas')}
                  rounded
                  allowEmpty
                  placeholder={t('slider_menu.seas')}
                  fixedPlaceholder
                  className={styles.menu}
                  options={filterComp.season.map(filter => ({
                    label: String(filter),
                    value: String(filter),
                    table: String(filter),
                  }))}
                  setValue={value => setFilters({ ...filters, season__name: value })}
                  value={filters.season__name}
                />
                */}
              <Menu
                title={t('match.comp')}
                rounded
                placeholder={t('match.comp')}
                className={styles.menu}
                style={{ width: '280px' }}
                options={filterComp.competition.map(filter => ({
                  label: String(filter),
                  value: String(filter),
                }))}
                setValue={value => {
                  setFilters({ q: value });
                }}
                onClear={() => setFilters({ q: '' })}
                value={filters.q}
              />
            </div>
            {athleteMatches.loading ? (
              <Spinner width="30" height="30" className={styles.matchesSpinner} />
            ) : data.length === 0 ? (
              <div className={styles.noResults}>
                {t('match.noResults1')}
                <br />
                {t('match.noResults2')}
              </div>
            ) : (
              <>
                {showFilterTabelMatches
                  ? filterFutureData(futureData).map(item => {
                      return (
                        <div className={styles.listItem}>
                          <div className={styles.listItemTitle}>
                            {item.match_date} - {item.competition}
                          </div>
                          <div className={styles.listItemContent}>
                            <div className={styles.divleft}>
                              <span>{item.home_team}</span>
                              <span>{item.visiting_team}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : filterData(data).map((item, index) => {
                      const visitingTeamId = data[index]?.visiting_team?.id;
                      const raw_score = data[index].scoreboard.split('(')[0].trim();
                      const [score1, score2] = raw_score.split('-').map(Number);

                      const raw_parenthesis_score = data[index].scoreboard.match(/\((.*?)\)/);
                      const [score3, score4] = raw_parenthesis_score
                        ? raw_parenthesis_score[1].split('-').map(Number)
                        : [0, 0];

                      if (index === 0 && selectedMatch == null) {
                        handleItem(item, index);
                      }

                      const spanIcons = (
                        <>
                          <span className={styles.div_right_row_goals}>
                            {(item.goals ?? 0) === 0 ? (
                              ''
                            ) : (
                              <>
                                <img src={IcoGols} alt="goals" />
                                {item.goals !== 1 && (
                                  <>
                                    <span className={styles.div_right_row_goals_x}>x</span>
                                    <span className={styles.div_right_row_goals_score}>{item.goals}</span>
                                  </>
                                )}
                              </>
                            )}
                          </span>
                          <span className={styles.div_right_row_yellow_card}>
                            {(item.secondYellow ?? 0) > 0 ? (
                              <img src={IcoAmareloVermelho} alt="double yellow card" />
                            ) : (
                              (item.yellowCards ?? 0) > 0 && <img src={IcoAmarelos} alt="yellow card" />
                            )}
                            {(item.redCards ?? 0) > 0 && <img src={IcoVermelhos} alt="red card" />}
                          </span>
                        </>
                      );

                      return (
                        <div
                          onClick={() => handleItem(item, index)}
                          className={selectedMatch === index ? styles.selected_match : styles.other_matches}
                        >
                          <div className={styles.listItemTitle}>
                            {item.match_date} - {item.competition}
                          </div>
                          <div className={styles.listItemContent}>
                            <div className={styles.divLeft}>
                              <span className={styles.itemsTeam}>{item.home_team_left}</span>
                              <span className={styles.itemsTeam}>{item.visiting_team_left}</span>
                            </div>
                            <div className={styles.div_right}>
                              <div className={styles.div_right_row}>
                                {visitingTeamId !== athleteTeamId && spanIcons}
                                <span className={styles.div_right_score}>
                                  {score3 ? `${score1} (${score3})` : score1}
                                </span>
                              </div>
                              <div className={styles.div_right_row}>
                                {visitingTeamId === athleteTeamId && spanIcons}
                                <span className={styles.div_right_score}>
                                  {score4 ? `${score2} (${score4})` : score2}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                {showFilterTabelMatches ? (
                  <div className={styles.pagination}>
                    <MinPagination
                      className={styles.pagination}
                      length={futureData.length}
                      hasNext={athleteFutureMatches.data.hasNext}
                      hasPrevious={athleteFutureMatches.data.hasPrevious}
                      pagination={futurePagination}
                      setPagination={setFuturePagination}
                      count={athleteFutureMatches.data.count}
                      resourceDisplayName={t('match.text3')}
                    />
                  </div>
                ) : (
                  <div className={styles.pagination}>
                    <MinPagination
                      className={styles.pagination}
                      length={data.length}
                      hasNext={athleteMatches.data.hasNext}
                      hasPrevious={athleteMatches.data.hasPrevious}
                      pagination={pagination}
                      setPagination={setPagination}
                      count={athleteMatches.data.count}
                      resourceDisplayName={t('match.text3')}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          {infoTabelMatches && (
            <div className={styles.historyContainer}>
              <div className={styles.scoreboard}>
                <div className={styles.listItemTitleRight}>
                  {mapItem.match_date} - {mapItem.competition}
                </div>
                <div className={styles.leftTitle}>
                  {mapItem.home_team}
                  <p className={styles.scoreText}>
                    {mapItem.scoreboard1}:{mapItem.scoreboard2}
                  </p>
                  {mapItem.visiting_team}
                </div>
              </div>
              <div className={styles.containerStatistic}>
                <div className={styles.infoContainer}>
                  <h1>{t('match.text8')}</h1>
                  <div className={styles.statistAthlete}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <div className={styles.table_image_container}>
                              <img src={IcoGols} alt="gols" />
                            </div>
                            <p>{t('match.text9')}</p>
                          </td>
                          <td>{mapItem.goals ? <span>{mapItem.goals}</span> : '-'}</td>
                        </tr>
                        <tr>
                          <td>
                            <div className={styles.table_image_container}>
                              <img src={IcoAmarelos} alt="amarelos" />
                            </div>
                            <p>{t('match.text10')}</p>
                          </td>
                          <td>{mapItem.yellowCards ? <span>{mapItem.yellowCards}</span> : '-'}</td>
                        </tr>
                        <tr>
                          <td>
                            <div className={styles.table_image_container}>
                              <img src={IcoAmareloVermelho} alt="amarelo vermelho" />
                            </div>
                            <p>{t('match.text11')}</p>
                          </td>
                          <td>{mapItem.secondYellow ? <span>{mapItem.secondYellow}</span> : '-'}</td>
                        </tr>
                        <tr>
                          <td>
                            <div className={styles.table_image_container}>
                              <img src={IcoVermelhos} alt="vermelho" />
                            </div>
                            <p>{t('match.text12')}</p>
                          </td>
                          <td>{mapItem.redCards ? <span>{mapItem.redCards}</span> : '-'}</td>
                        </tr>
                        {/*
                          <tr>
                            <td>
                              <div className={styles.table_image_container}>
                                <img src={IcoTempo} alt="Tempo" />
                              </div>
                              <p>{t('match.text13')}</p>
                            </td>
                            <td>???</td>
                          </tr>
                          <tr>
                            <td>
                              <div className={styles.table_image_container}>
                                <img src={IcoTitular} alt="Titular" />
                              </div>
                              <p>{t('match.text14')}</p>
                            </td>
                            <td>???</td>
                          </tr>
                          <tr>
                            <td>
                              <div className={styles.table_image_container}>
                                <img src={IcoBanco} alt="Banco" />
                              </div>
                              <p>{t('match.text15')}</p>
                            </td>
                            <td>???</td>
                          </tr>
                          <tr>
                            <td>
                              <div className={styles.table_image_container}>
                                <img src={IcoEntrou} alt="Entrou" />
                              </div>
                              <p>{t('match.text16')}</p>
                            </td>
                            <td>???</td>
                          </tr>
                          <tr>
                            <td>
                              <div className={styles.table_image_container}>
                                <img src={IcoSaiu} alt="Saiu" />
                              </div>
                              <p>{t('match.text17')}</p>
                            </td>
                            <td>???</td>
                          </tr>
                          */}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className={styles.buttonContainer}>
                  {mapItem.docket !== '-' && (
                    <a href={mapItem.docket} target="_blank" rel="noopener noreferrer">
                      <Button className={styles.button}>
                        <h1>{t('match.sum')}</h1>
                        <img src={Sumula} alt="notebook" />
                      </Button>
                    </a>
                  )}
                  {mapItem.videos != null && mapItem.videos !== '' && (
                    <a href={mapItem.videos} target="_blank" rel="noopener noreferrer">
                      <Button className={styles.button}>
                        <h1>{t('match.videos')}</h1>
                        <img src={video} alt="video" />
                      </Button>
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    content = (
      <Card bordered className={styles.errorContainer}>
        <h2>{t(athleteMatches.error)}</h2>
      </Card>
    );
  }

  return (
    <>
      {(athleteMatches.loading || athleteFutureMatches.loading) && filterComp === initialFilterComp ? (
        <div className={styles.loaderContainer}>
          <Spinner />
        </div>
      ) : (
        content
      )}
    </>
  );
}

export default Matches;
