import React from 'react';
import * as S from './styles';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { Button, Input } from 'components/template';
import { MdGroup, MdArrowBack, MdDelete } from 'react-icons/md';
import AthleteCard from 'components/AthleteCard';
import Variables from 'variables';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ShadowTeamLeftHeader from './ShadowTeamLeftHeader';

export default function ShadowTeamPositionInfo({
  position,
  athletes,
  handleBack = () => {},
  handleDeletePosition = () => {},
  editButtonClick = () => {},
  handleUpdatePosition = () => {},
  handleNewAthleteClick = () => {},
  handleUnselectAthlete = () => {},
  handleAtheleteReorder = () => {},
  handleAtheleteUpdate = () => {},
}) {
  const { t } = useTranslation();

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onChangeOrder = result => {
    let sourceAthletesArray = athletes;
    if (result.destination && result.destination.droppableId === result.source.droppableId) {
      const athleteId = parseInt(result.draggableId);
      const athleteItem = sourceAthletesArray.find(athlete => athlete?.athlete?.id === athleteId);
      const index = sourceAthletesArray.indexOf(athleteItem);
      if (index > -1) {
        sourceAthletesArray = reorder(sourceAthletesArray, result.source.index, result.destination.index);
      }
    }
    handleAtheleteReorder(sourceAthletesArray);
  };

  const handleColorChange = (athlete, color) => {
    handleAtheleteUpdate(athlete, {
      color,
    });
  };

  return (
    <>
      <ShadowTeamLeftHeader editButtonClick={editButtonClick}></ShadowTeamLeftHeader>

      <S.PositionTitle>
        <MdArrowBack
          size="18px"
          style={{ paddingLeft: '10px', paddingRight: '10px' }}
          onClick={handleBack}
          cursor={'pointer'}
        />
        <S.PositionTitleText>{t('proj.posicaoPanelTitle')}</S.PositionTitleText>
        <S.PositionTitleName>
          <span>{position.position_name}</span>
        </S.PositionTitleName>
        <MdDelete
          size="18px"
          style={{ marginLeft: 'auto' }}
          onClick={() => handleDeletePosition(position)}
          cursor={'pointer'}
        />
      </S.PositionTitle>

      <S.LeftInput>
        <label>{t('proj.posicaoInputNome')}</label>
        <Input
          rounded
          inputClassName={styles.leftInput}
          value={position.position_name}
          onChange={e =>
            handleUpdatePosition(position.id, {
              position_name: e.target.value,
            })
          }
          style={{ backgroundColor: '#fff' }}
        />
      </S.LeftInput>

      <DragDropContext onDragEnd={onChangeOrder}>
        <Droppable droppableId={'athletesDroppable'}>
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {athletes &&
                athletes.map((athlete, index) => (
                  <Draggable
                    key={athlete?.athlete?.id ?? athlete}
                    draggableId={athlete?.athlete?.id?.toString()}
                    index={index}
                  >
                    {provided => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <AthleteCard
                          item={athlete.athlete}
                          iconStyle={{ backgroundColor: 'transparent', color: Variables.lightPurple1 }}
                          // iconColor={}
                          iconClick={() => handleUnselectAthlete(athlete)}
                          style={{ margin: '0 15px 15px' }}
                          hiddenColorPicker={false}
                          cardColor={athlete.color}
                          handleColorChange={value => handleColorChange(athlete, value)}
                        ></AthleteCard>
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
              <div style={{ height: '15px' }}></div>
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Button
        onClick={handleNewAthleteClick}
        rounded
        color="green"
        style={{
          fontWeight: '700',
          margin: '14px 15px 15px 15px',
        }}
      >
        {t('proj.adicionarAtleta')}
        <MdGroup size="18px" style={{ marginLeft: '10px', marginBottom: '-3px' }} />
      </Button>
    </>
  );
}
