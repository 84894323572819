/*eslint-disable eqeqeq*/
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from 'store/ducks/user';
import { useParams, useHistory } from 'react-router-dom';
import { Card, Button, Input, Toast, Spinner } from 'components/template';
import { FaEye, FaEyeSlash, FaInfoCircle } from 'react-icons/fa';
import styles from './ResetPassword.module.scss';
import LanguageButton from 'App/Layout/AppBar/LanguageButton/LanguageButton';
import Logo from 'assets/logo.svg';
import FlashState from 'shared/FlashState';
import { useTranslation } from 'react-i18next';

export default function ResetPassword() {
  const { t } = useTranslation();
  const { uid, token } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [form, setForm] = useState({
    uid: uid,
    token: token,
    new_password: '',
    re_new_password: '',
  });
  const [isPwdVisible, setPwdVisibility] = useState(false);
  const [isRePwdVisible, setRePwdVisibility] = useState(false);
  const [toast, setToast] = useState({ isVisible: false, content: null, duration: 4000 });
  const history = useHistory();

  if (user.data) {
    localStorage.removeItem('footlink_udata');
    localStorage.removeItem('report');
    sessionStorage.removeItem('advanced_search');
    window.location.reload();
  }

  // toast erro no reset
  useEffect(() => {
    if (user.reset.error) {
      setToast({
        isVisible: true,
        content: (
          <p className={styles.toastContent}>
            <FaInfoCircle />
            {t(user.reset.error)}
          </p>
        ),
        type: 'danger',
        fixed: true,
      });
    }
  }, [user.reset.error, t]);

  // redirect when successful
  useEffect(() => {
    if (user.reset.redirect) {
      FlashState.set('success', 'Sua senha foi alterada. Faça o login utilizando a nova senha.');
      history.push('/login');
    }
  }, [user.reset.redirect]); // eslint-disable-line react-hooks/exhaustive-deps

  function resetToast() {
    setToast({ ...toast, isVisible: false, fixed: false });
    if (user.reset.error) dispatch(Creators.handleResetError());
  }

  function resetClickHandler() {
    dispatch(Creators.reset(form.uid, form.token, form.new_password, form.re_new_password));
  }

  function inputChangeHandler(e, field) {
    e.persist();
    setForm(f => ({ ...f, [field]: e.target.value, forceActive: false }));
  }

  function onKeyUp(e) {
    if (user.reset.error) dispatch(Creators.handleResetError());
    if (e.keyCode === 13 && form.new_password && form.re_new_password) {
      resetClickHandler();
    }
  }

  const passwordIcon = isPwdVisible ? (
    <FaEye className={styles.icon} onClick={() => setPwdVisibility(false)} />
  ) : (
    <FaEyeSlash className={styles.icon} onClick={() => setPwdVisibility(true)} />
  );
  const rePasswordIcon = isRePwdVisible ? (
    <FaEye className={styles.icon} onClick={() => setRePwdVisibility(false)} />
  ) : (
    <FaEyeSlash className={styles.icon} onClick={() => setRePwdVisibility(true)} />
  );

  let resetContent = null;
  if (user.reset.loading) {
    resetContent = <Spinner className={styles.loading} />;
  } else {
    resetContent = (
      <>
        <div className={styles.passwordContainer}>
          <Input
            label={t('user.text14')}
            type={isPwdVisible ? 'text' : 'password'}
            containerClassName={styles.inputContainer}
            value={form.new_password}
            onKeyUp={onKeyUp}
            icon={passwordIcon}
            iconPosition="right"
            onChange={e => inputChangeHandler(e, 'new_password')}
            hasError={Boolean(user.reset.error)}
            inputClassName={styles.passwordInput}
          />
        </div>
        <div className={styles.passwordContainer}>
          <Input
            label={t('user.text15')}
            type={isRePwdVisible ? 'text' : 'password'}
            containerClassName={styles.inputContainer}
            value={form.re_new_password}
            onKeyUp={onKeyUp}
            icon={rePasswordIcon}
            iconPosition="right"
            onChange={e => inputChangeHandler(e, 're_new_password')}
            inputClassName={styles.passwordInput}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            disabled={
              !form.forceActive &&
              (!form.new_password || !form.re_new_password || form.re_new_password != form.new_password)
            }
            onClick={resetClickHandler}
            color="purple"
            fluid
            rounded
          >
            {user.reset.loading ? t('home.box13') : t('home.text19')}
          </Button>
        </div>
      </>
    );
  }

  const resetContainerClasses = [styles.resetContainer];

  return (
    <div className={styles.container}>
      <div className={styles.languageButton}>
        <LanguageButton />
      </div>
      <Toast
        type={toast.type}
        isVisible={toast.isVisible}
        onClose={resetToast}
        content={toast.content}
        duration={toast.duration}
        fixed={toast.fixed}
      />
      <div className={styles.innerContainer}>
        <img src={Logo} alt="Logotipo Footlink" />
        <div className={styles.textl}>
          <p className={styles.text}>{t('trello.box4')}</p>
        </div>
        <Card className={styles.cardContainer}>
          <div className={resetContainerClasses.join(' ')}>{resetContent}</div>
        </Card>
        <p className={styles.spacer}></p>
      </div>
    </div>
  );
}
