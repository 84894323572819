import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from 'components/template';
import styles from './TopBar.module.scss';
import { BiArrowBack } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import DesiredAthleteIcon from 'assets/ico/desired_athlete_icon.svg';
import TeamRow from 'components/TeamRow/TeamRow';

export default function TopBar({ transferMarket, border }) {
  const { t } = useTranslation();
  const history = useHistory();

  const nationalities = transferMarket?.nationalities?.length
    ? <> 
      { transferMarket.nationalities.slice(0,3).map(item => (
        <img src={item.flag} alt={item.name} className={styles.country} draggable="false" />
      ))}
      {transferMarket.nationalities && transferMarket.nationalities.length > 3 && <span>+{transferMarket.nationalities.length -3}</span>}
    </>
    : '';

  return (
    <div className={styles.topBar} style={{ borderBottom: border && '1px solid #BDBDBD' }}>
      <div className={styles.left}>
        <div className={styles.pageBack}>
          <Button onClick={() => history.goBack()} noShadow>
            <BiArrowBack />
          </Button>
        </div>
        <img className={styles.avatar} src={DesiredAthleteIcon} draggable="false" alt='Desired' />
        <div className={styles.athleteInfo}>
          <div className={styles.athleteName}>
            <h1>{t(`positionOptionsRaw.${transferMarket?.position}`)}</h1>
          </div>
          <div className={styles.athleteDetails}>
            {nationalities}
            <span className={styles.span}>
              {t(transferMarket?.female ? 'radioAdvancedSearch.female' : 'radioAdvancedSearch.male')} | 
              {' '}{t(transferMarket?.amateur ? 'listTranslation.Amateur' : 'compe.text26')} | 
              {' '}{t('slider_menu.in')} {transferMarket?.min_age} {t('slider_menu.e')} {transferMarket?.max_age} {t('athlete_card.idade')}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.teamsContainer}>
          <div className={styles.teamContainer}>
            <div className={styles.teamContent}>
              <div>
                <h5>{t('negotiations.headerClubTitle').toUpperCase()}</h5>
              </div>
              <TeamRow 
                name={transferMarket?.owner_team?.team_name} 
                id={transferMarket?.owner_team?.id} 
                img={transferMarket?.owner_team?.emblem} 
              />
            </div>
          </div>
        </div>
        <div className={styles.teamsContainer}>
          <div className={styles.teamContainer}>
            <div className={styles.teamContent}>
              <div>
                <h5>{t('transferMarket.maxValue').toUpperCase()}</h5>
                <h2>
                  {parseFloat(transferMarket?.purchase_value)?.toLocaleString('pt-BR')} M €
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.teamsContainer}>
          <div className={styles.teamContainer}>
            <div className={styles.teamContent}>
              <div>
                <h5>{t('transferMarket.maxSalary').toUpperCase()}</h5>
                <h2>
                  {parseFloat(transferMarket?.annual_salary ?? transferMarket?.monthly_salary)?.toLocaleString('pt-BR')} M €
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TopBar.propTypes = {
  athlete: PropTypes.instanceOf(Object).isRequired,
};
