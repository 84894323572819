import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// scss
import styles from './Menu.module.scss';

// components
// import { MyMessages } from './Items/Items';
import { OrganizationMessages } from './Items/Items';
import { useSelector } from 'react-redux';

export default function MessagesMenu({ selectedRepository, handleSelectChat }) {
  const { t } = useTranslation();
  const notifications = useSelector(state => state.notifications);
  const history = useHistory();

  const [activeItem, setActiveItem] = useState(selectedRepository);

  // const myMessages = MyMessages(activeItem);
  const organizationMessages = OrganizationMessages(activeItem, notifications);

  return (
    <div className={styles.container}>
      {/* first section */}
      {/* <div className={styles.first_section}>
        <h2>{t('chat.my_messages')}</h2>
        <div className={styles.menu_list_container}>
          {myMessages.map((item, index) => (
            <div
              key={index}
              className={styles.menu_item_container}
              //  onClick={() => repositorySelector(item.repository)}
            >
              {item.content}
            </div>
          ))}
        </div>
      </div> */}

      {/* second section */}
      <div className={styles.second_section}>
        <h2>{t('chat.organization_messages')}</h2>
        <div className={styles.menu_list_container}>
          {organizationMessages.map((item, index) => {
            return (
              <div
                key={index}
                className={styles.menu_item_container}
                onClick={() => {
                  handleSelectChat(null);
                  setActiveItem(item.repository);
                  history.push(`/mensagens/${item.repository}`);
                }}
              >
                {item.content}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
