import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { Avatar } from 'components/template';

import styles from './CompetitionRow.module.scss';

export default function CompetitionRow(props) {
  const { img, name, id, level, ...rest } = props;

  return (
    <div className={styles.clubDiv} {...rest}>
      <Avatar className={styles.avatar} noBorder noRadius img={img ?? ''} name={name} />
      <Link className={styles.link} to={`/${level}/competicoes/${id}`}>
        {name}
      </Link>
    </div>
  );
}

CompetitionRow.defaultProps = {
  img: null,
};

CompetitionRow.propTypes = {
  img: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  level: PropTypes.string.isRequired
};
