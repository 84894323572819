import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Avatar } from 'components/template';
import { useLocation } from 'react-router-dom';
import styles from './TeamRow.module.scss';
import { MdShield } from "react-icons/md";

export default function TeamRow({ contractDate, img, name, id, link, linkExact, textBreak, className, type, ...rest }) {
  const location = useLocation();
  const isOnClubs =
    location.pathname.includes('/clubes-brasileiros') || location.pathname.includes('/clubes-estrangeiros') || location.pathname.includes('/clubes-footlink');

  const teamRowClasses = [];
  if (className) {
    teamRowClasses.push(styles.teamRow);
    teamRowClasses.push(className);
  } else {
    teamRowClasses.push(styles.clubDiv);
  }
  if (name === 'Sigiloso') teamRowClasses.push(styles.sigiloso);

  const mountTeamName = () => {
    if (linkExact != null) {
      return (
        <Link className={styles.link} data-text-break={textBreak} to={linkExact}>
          {name}
        </Link>
      );
    }

    if (isOnClubs) {
      return (
        <Link className={styles.link} data-text-break={textBreak} to={link ? link : (type === 'agency' ? `/agencias/${id}/atletas` : `/clube/${id}/elenco`)}>
          {name}
        </Link>
      );
    }
    
    if (contractDate) {
      return (
        <div className={styles.contractDate}>
          <span>
            {id != null ? (
              <Link className={styles.link} data-text-break={textBreak} to={link ? link : (type === 'agency' ? `/agencias/${id}/atletas` : `/clube/${id}/elenco`)}>
                {name}
              </Link>
            ) : name}
          </span>
          <span>{contractDate}</span>
        </div>
      );
    }

    if (id != null) {
      return (
        <span>
          <Link className={styles.link} data-text-break={textBreak} to={link ? link : (type === 'agency' ? `/agencias/${id}/atletas` : `/clube/${id}/elenco`)}>
            {name}
          </Link>
        </span>
      );      
    }
    
    return (
      <span className={styles.no_link}>{name}</span>
    );
  }

  return (
    <div className={teamRowClasses.join(' ')} {...rest}>
      {name === 'Sigiloso' ? (
        <MdShield />
      ) : (
        <div className={styles.club_image}>
          <Avatar className={styles.avatar} noBorder noRadius img={img ?? ''} name={name} />
        </div>
      )}
      {mountTeamName()}
    </div>
  );
}

TeamRow.defaultProps = {
  img: null,
  textBreak: false,
};

TeamRow.propTypes = {
  img: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  textBreak: PropTypes.bool,
};
