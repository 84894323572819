import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Input.module.scss';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import CurrencyInput from 'react-currency-input-field';

export default function Input({
  inputClassName,
  containerClassName,
  type,
  label,
  icon,
  iconPosition,
  containerStyle,
  inputStyle,
  hasError,
  rounded,
  roundedBorderWhite,
  smaller,
  profileInput,
  darkMode,
  ...props
}) {
  const [hidePassword, setHidePassword] = useState(true);

  const [state, setState] = useState({ icon, iconPosition });

  useEffect(() => {
    setState({ icon, iconPosition });
  }, [icon, iconPosition]);

  useEffect(() => {
    if (type === 'password') {
      // setState({ ...state, iconPosition: 'right' })

      if (hidePassword) {
        setState({
          ...state,
          icon: (
            <FaEye
              onClick={() => {
                setHidePassword(false);
              }}
            />
          ),
        });
      } else {
        setState({
          ...state,
          icon: (
            <FaEyeSlash
              onClick={() => {
                setHidePassword(true);
              }}
            />
          ),
        });
      }
    }
  }, [hidePassword]); // eslint-disable-line react-hooks/exhaustive-deps

  const inputClasses = [styles.input];
  const inputContainerClasses = [styles.inputContainer];

  if (inputClassName) inputClasses.push(inputClassName);
  if (smaller) inputClasses.push(styles.smaller);

  if (state.icon) {
    inputClasses.push(state.iconPosition === 'left' ? styles.hasIconLeft : styles.hasIconRight);
    inputContainerClasses.push(state.iconPosition === 'left' ? styles.hasIconLeft : styles.hasIconRight);
  }
  if (hasError) inputClasses.push(styles.hasError);
  if (rounded) inputClasses.push(styles.rounded);
  if (roundedBorderWhite) inputClasses.push(styles.roundedBorderWhite);
  if (profileInput) inputContainerClasses.push(styles.profileInput);
  if (darkMode === true) inputClasses.push(styles.darkMode);

  return (
    <label style={containerStyle} className={[styles.container, containerClassName].join(' ')}>
      {label}
      <div className={inputContainerClasses.join(' ')}>
        {type === 'currency' ? (
          <CurrencyInput
            style={inputStyle}
            className={inputClasses.join(' ')}
            decimalScale={2}
            decimalSeparator=","
            groupSeparator="."
            onFocus={e => e.target.select()}
            {...props}
          />
        ) : (
          <input
            style={inputStyle}
            className={inputClasses.join(' ')}
            type={type === 'password' && !hidePassword ? 'text' : type}
            {...props}
          />
        )}
        {state.icon}
      </div>
    </label>
  );
}

Input.defaultProps = {
  inputClassName: '',
  containerClassName: '',
  type: '',
  label: null,
  containerStyle: {},
  inputStyle: {},
  icon: null,
  iconPosition: 'left',
  hasError: false,
  rounded: false,
};

Input.propTypes = {
  inputClassName: PropTypes.string,
  label: PropTypes.any,
  containerClassName: PropTypes.string,
  type: PropTypes.string,
  containerStyle: PropTypes.instanceOf(Object),
  inputStyle: PropTypes.instanceOf(Object),
  iconPosition: PropTypes.oneOf(['left', 'right']),
  icon: PropTypes.node,
  hasError: PropTypes.bool,
  rounded: PropTypes.bool,
};
