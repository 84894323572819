/*eslint-disable eqeqeq*/
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Page from 'components/template/Page';
import { getAgencies } from 'store/ducks/agencies';
import { usePagination } from 'shared/hooks';
import { Table } from 'components/template';
import ActionBar from './ActionBar';
import AgencyName from './AgencyName';
import CountryRow from 'components/CountryRow/CountryRow';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import AgentsLogo from 'assets/ico/menu/agents.svg';
import { MdVerified } from 'react-icons/md';
import CategoryGlobal from 'assets/category-global.svg';
import CategoryBrasil from 'assets/category-brasil-default.svg';
import CategoryLatam from 'assets/category-latam.svg';
import AgencyFifa from 'assets/agency-FIFA.svg';

const Agencies = () => {
  const dispatch = useDispatch();
  const agencies = useSelector(state => state.agencies.list);
  const [pagination, setPagination, defaultPagination] = usePagination();

  const { t } = useTranslation();

  const [filter, setFilter] = useState({ o: 'name' });

  useEffect(() => {
    dispatch(getAgencies({ ...pagination, ...filter }));
  }, [dispatch, pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getAgencies({ ...defaultPagination, ...filter }));
  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  const parseAgencies = data => {
    return data.map(agency => {
      const isVerified = agency.is_verified_agency && (
        <div className={styles.agency_verified}>
          <MdVerified />
        </div>
      );
      const name = (
        <div className={styles.agency_name_container}>
          <AgencyName id={agency.id} name={agency.name} emblem={agency.emblem} />
        </div>
      );
      const athletesCount =
        agency.counts?.athletes == 1
          ? `${agency.counts?.athletes} ${t('rest.box21')}`
          : `${agency.counts?.athletes} ${t('proj.box20')}`;
      const fifaLicense = agency.is_fifa_agency ? (
        <img src={AgencyFifa} className={styles.category_types_img} alt="" />
      ) : (
        <></>
      );
      let category;
      switch (agency.agency_subtype) {
        case 'brazil':
          category = <img src={CategoryBrasil} className={styles.category_types_img} alt="" />;
          break;
        case 'latam':
          category = <img src={CategoryLatam} className={styles.category_types_img} alt="" />;
          break;
        case 'global':
          category = <img src={CategoryGlobal} className={styles.category_types_img} alt="" />;
          break;
        default:
          category = <img src={CategoryBrasil} className={styles.category_types_img} alt="" />;
          break;
      }
      const country = <CountryRow name={agency.country?.name} id={agency.country?.code} img={agency.country?.flag} />;

      // let phones = '';

      // phones = agency.phones.phone1.number
      //   ? `${agency.phones.phone1.ddi} ${agency.phones.phone1.ddd} ${agency.phones.phone1.number}`
      //   : '';

      // if (phones !== '') {
      //   phones += ' / ';
      // }

      // phones = agency.phones.phone2.number
      //   ? `${agency.phones.phone2.ddi} ${agency.phones.phone2.ddd} ${agency.phones.phone2.number}`
      //   : '';

      // if (phones === '') {
      //   phones = '-';
      // }

      return [isVerified, name, athletesCount, category, fifaLicense, country];
    });
  };

  return (
    <Page
      title={t('agencies.agencies')}
      icon={<img draggable="false" src={AgentsLogo} alt={t('nav_drawer.agencias')} />}
      iconBgColor="#62747C"
      loading={agencies.loading}
      error={t(agencies.error)}
      subtitle={t('agencies.subti')}
      paginationObj={{
        pagination,
        setPagination,
        length: agencies.data.length,
        count: agencies.count,
        resourceDisplayName: t('agencies.agencies'),
        hasNext: agencies.hasNext,
        hasPrevious: agencies.hasPrevious,
      }}
    >
      <ActionBar setFilter={setFilter} filter={filter} />
      <Table
        flat
        theads={[
          '',
          t('agencies.agency'),
          t('filters.ath'),
          t('agencies.text7'),
          t('agencies.text8'),
          t('agencies.origin_countr'),
        ]}
        data={parseAgencies(agencies.data)}
        className={styles.table}
      />
    </Page>
  );
};

export default Agencies;
