import React from 'react'

import * as S from './styles'

import { useTranslation } from "react-i18next";

export default function New({ onAddButtonClick }) {
    const { t } = useTranslation();
    return (
        <>
            <S.Card bordered >
                <S.Content>
                    <S.Icon>
                        <S.ImportIcon />
                    </S.Icon>
                    <S.ImportSubTitle>
                        {t("proj.text3")}
                    </S.ImportSubTitle>
                    <S.Button
                        onClick={onAddButtonClick}
                        rounded
                        color='green'
                    >{t("proj.text4")}</S.Button>
                </S.Content>
            </S.Card>
        </>
    );
}
