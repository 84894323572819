import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ReportCreators } from 'store/ducks/athleteReport';
import { Spinner, Card, Input, Button, Checkbox } from 'components/template';
import CenteredLoader from 'components/template/CenteredLoader';
import { FaChevronLeft } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import styles from './index.module.scss';
import SideInfo from './SideInfo/SideInfo';
import { Creators as ProjectCreators } from 'store/ducks/projects';
import Notes from 'pages/Athlete/Notes';
import { createNote } from 'store/ducks/athleteNotes';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

let _sendingLastNote = false;
export default function WorkflowAthlete({ projectId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const project = useSelector(state => state.project);
  const athlete = useSelector(state => state.athleteReport);
  const user = useSelector(state => state.user.data);
  const notes = useSelector(state => state.athleteNotes);
  const [importComments, setImportComments] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [indicatedBy, setIndicatedBy] = useState('');
  const [editingIndicatedBy, setEditingIndicatedBy] = useState(false);
  const [noteListChecked] = useState([]);
  const [allNoteList] = useState([]);
  const { id } = useParams();
  const athleteId = id;

  useEffect(() => {
    dispatch(ReportCreators.getAthlete([athleteId], true, projectId));
  }, [dispatch, athleteId, projectId, user.organization]);

  useEffect(() => {
    if (project.workflow.data?.indicated_by && indicatedBy === '') {
      setIndicatedBy(project.workflow.data?.indicated_by);
    }
  }, [project.workflow.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (project.data?.workflow) {
      const workflow = project.data?.workflow?.find(w => w.athlete.id.toString() === athleteId);
      if (workflow) {
        dispatch(ProjectCreators.getWorkflow(projectId, workflow.id));
      }
    }
  }, [dispatch, athleteId, projectId, project.data]);

  useEffect(() => {
    if (notes.sendingLastNote) {
      _sendingLastNote = true;
    } else if (_sendingLastNote) {
      _sendingLastNote = false;
      setImportComments(false);
    }
  }, [notes.sendingLastNote]);

  let content = '';
  if (athlete.loading) {
    content = (
      <Card className={styles.loaderContainer}>
        <Spinner />
      </Card>
    );
  } else if (athlete.error) {
    content = (
      <Card className={styles.errorContainer}>
        <h2>{t(athlete.error)}</h2>
      </Card>
    );
  } else if (athlete.data.length > 0) {
    content = (
      <>
        <Card softShadow className={styles.container}>
          <SideInfo data={athlete.data[0]} projectId={projectId} hideBg={true} />
          <div className={styles.comments}>
            {importComments ? (
              <>
                <div className={styles.importCommentsHeader}>
                  <FaChevronLeft onClick={() => setImportComments(false)} />
                  <h1>{t('proj.box3')}</h1>
                </div>
                <div className={styles.importCommentsButton}>
                  <span>
                    <Checkbox
                      checked={allChecked}
                      onChange={() => setAllChecked(!allChecked)}
                      className={styles.checkbox}
                    />
                    {t('proj.box4')}
                  </span>
                  <Button
                    tip={t('proj.box5')}
                    onClick={() => {
                      const noteList = allChecked ? allNoteList : noteListChecked;
                      if (noteList.length > 0) {
                        noteList.forEach(note => {
                          dispatch(
                            createNote(
                              athleteId,
                              note.text,
                              note.file,
                              note.file_type,
                              projectId,
                              note.original_file_name,
                              note.file_size,
                              note.thumb,
                              note.date
                            )
                          );
                        });
                      }
                      allNoteList.splice(0, allNoteList.length);
                      noteListChecked.splice(0, noteListChecked.length);
                    }}
                    color="green"
                    rounded
                    smaller
                  >
                    {t('proj.box5')}
                  </Button>
                </div>
                {notes.sendingLastNote ? (
                  <CenteredLoader />
                ) : (
                  <Notes
                    athleteId={athleteId}
                    calcHeight={false}
                    hideNoteGroups={true}
                    checkAllNotes={allChecked}
                    noteListChecked={noteListChecked}
                    allNoteList={allNoteList}
                    inputPosition={'none'}
                    showDeleted={false}
                  />
                )}
              </>
            ) : (
              <>
                <div className={styles.indicate}>
                  <span>{t('proj.box6')}</span>
                  <div className={`${styles.indicatedByDiv} ${editingIndicatedBy ? styles.indicatedByDivEditing : ''}`}>
                    <div>{t('proj.box7')}</div>
                    {editingIndicatedBy && (
                      <div
                        className={styles.saveIndicatedBy}
                        onClick={async () => {
                          const workflow = project.data?.workflow?.find(w => w.athlete.id.toString() === athleteId);
                          if (workflow) {
                            await ProjectCreators.updateIndicateWorkflow(projectId, workflow.id, indicatedBy);
                            setIndicatedBy('');
                            setEditingIndicatedBy(false);
                            dispatch(ProjectCreators.getWorkflow(projectId, workflow.id));
                          }
                        }}
                      >
                        {t('buttons.save')}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.indicate}>
                  {project.workflow.loading ? (
                    '...'
                  ) : (
                    <>
                      <span className={styles.addedName}>{project.workflow.data?.created_by?.name}</span>
                      <span>{t('overview.text30')}</span>
                      <span>{moment(project.workflow.data?.created_at).format('DD/MM/YYYY')}</span>
                    </>
                  )}
                  {editingIndicatedBy ? (
                    <Input
                      rounded
                      disabled={project.workflow.loading}
                      value={indicatedBy}
                      onChange={e => setIndicatedBy(e.target.value)}
                      containerClassName={styles.indicated_by_input}
                    />
                  ) : (
                    <div onClick={() => setEditingIndicatedBy(true)} className={styles.indicated_by_button}>
                      {indicatedBy}
                      {(project.workflow.data?.indicated_by ?? '') === '' ? t('proj.indicatedByEditing') : <MdEdit />}
                    </div>
                  )}
                </div>
                <h3>{t('proj.box11')}</h3>
                <div className={styles.notes}>
                  <Notes
                    athleteId={athleteId}
                    calcHeight={false}
                    hideNoteGroups={true}
                    inputPosition={'top'}
                    projectId={projectId}
                    inputPostElement={
                      (notes.countOutOfProject ?? 0) > 0 && (
                        <div className={styles.commentsCountPanel}>
                          {t('proj.box12')} {notes.countOutOfProject} {t('proj.box13')}
                          <span onClick={() => setImportComments(true)}>{t('proj.box14')}</span>
                        </div>
                      )
                    }
                  />
                </div>
              </>
            )}
          </div>
        </Card>
      </>
    );
  }

  return content;
}

WorkflowAthlete.propTypes = {
  projectId: PropTypes.instanceOf(Object).isRequired,
};
