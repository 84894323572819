import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// scss
import styles from './AgentChatHeader.module.scss';

// components
import { Avatar, IconButton, Spinner } from 'components/template';

// assets
import AgencyLogo from 'assets/ico/menu/agents.svg';

export default function AgentChatHeader({ selectedChat, user }) {
  const { t } = useTranslation();
  const history = useHistory();

  let chatAgency = selectedChat.agency ?? selectedChat.org;
  if (user?.organization?.id?.id === chatAgency?.id) {
    chatAgency = selectedChat.org;
  }

  return (
    <div className={styles.container}>
      <div className={styles.container_row}>
        {chatAgency != null ? (
          <>
            <div className={styles.left}>
              <div className={styles.agency_container}>
                <div className={styles.agency_avatar_container}>
                  <Avatar
                    className={styles.agency}
                    img={chatAgency?.photo}
                    name={chatAgency?.name}
                    noBorder
                  />
                </div>
                <div className={styles.agency_info}>
                  <span className={styles.name}>{chatAgency?.name}</span>
                  <div className={styles.info}>
                    {chatAgency?.country?.flag && (
                      <img
                        src={chatAgency?.country?.flag}
                        alt={chatAgency?.country?.name ?? ''}
                        draggable="false"
                      />
                    )}
                    <span className={styles.address}>{chatAgency?.country?.name}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <IconButton
                className={styles.view_club_button}
                text={t('chat.view_agent_page')}
                icon={<img src={AgencyLogo} alt="agency" />}
                onClick={() => history.push(`/agencias/${chatAgency?.id}/atletas`)}
              />
            </div>
          </>
        ) : (
          <Spinner width="40" height="40" className={styles.spinner} />
        )}
      </div>
    </div>
  );
}
