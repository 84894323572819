import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMount } from 'shared/hooks';

import styles from './Table.module.scss';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

export default function Table({
  fullhead,
  theads,
  data,
  footer,
  footer1,
  className,
  flat,
  paginated,
  page,
  nextPage,
  previousPage,
  sortHandlers,
  onClickRow,
  left,
  evaluation,
  ...props
}) {
  const [sortState, setSortState] = useState([]);

  useMount(() => {
    const sortStateAux = [];
    theads.forEach((val, i) => {
      if (sortHandlers && sortHandlers[i]) {
        sortStateAux[i] = 'unsorted';
      } else {
        sortStateAux[i] = null;
      }
    });
    setSortState(sortStateAux);
  });

  const tableClasses = [styles.table];
  if (className) tableClasses.push(className);
  if (flat) tableClasses.push(styles.flat);
  if (left) tableClasses.push(styles.left);

  function sortCLickHandler(i, order) {
    const newSortState = sortState.map((state, j) => {
      if (!state) {
        return null;
      }
      if (j !== i) {
        return 'unsorted';
      } else {
        return order;
      }
    });
    setSortState(newSortState);
    sortHandlers[i](order);
  }

  return (
    <div className={styles.container}>
      <table {...props} className={tableClasses.join(' ')}>
        <thead>
          {fullhead && (
            <tr>
              <th className={styles.fullhead} colSpan={theads.length}>
                {fullhead}
              </th>
            </tr>
          )}
          {theads.length > 0 && (
            <tr>
              {theads.map((th, i) => {
                let sortIcon = <FaSort />;
                let onClickHandler = null;

                if (sortState[i] === 'asc') {
                  sortIcon = <FaSortUp />;
                  onClickHandler = () => sortCLickHandler(i, 'desc');
                } else if (sortState[i] === 'desc') {
                  sortIcon = <FaSortDown />;
                  onClickHandler = () => sortCLickHandler(i, 'unsorted');
                } else if (sortState[i] === 'unsorted') {
                  onClickHandler = () => sortCLickHandler(i, 'asc');
                  sortIcon = <FaSort />;
                } else {
                  sortIcon = null;
                }

                const sortWrapper = (
                  <div onClick={onClickHandler} className={styles.sortWrapper}>
                    {sortIcon}
                  </div>
                );
                return (
                  <th key={i}>
                    <div className={styles.thWrapper}>
                      {th} {sortWrapper}
                    </div>
                  </th>
                );
              })}
            </tr>
          )}
        </thead>
        <tbody>
          {data.length > 0 && (
            <>
              {data.map((tr, i) => {
                let rowStyle;
                let keepStyle;
                return (
                  <tr key={i}>
                    {tr[tr.length - 1] && typeof tr[tr.length - 1].rowId !== 'undefined'
                      ? tr.slice(0, tr.length - 1).map((td, j) => {
                          return (
                            <td onClick={onClickRow ? () => onClickRow(tr[tr.length - 1].rowId) : () => {}} key={j}>
                              <div>{td}</div>
                            </td>
                          );
                        })
                      : tr.map((td, j) => {
                          if (typeof td === 'string' && td.startsWith('Total Temporada')) {
                            rowStyle = { fontWeight: 600 };
                            keepStyle = true;
                          }
                          if (!keepStyle) {
                            rowStyle = {};
                          }
                          return (
                            <td key={j} style={rowStyle}>
                              <div>{td}</div>
                            </td>
                          );
                        })}
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
        {footer && (
          <tfoot>
            <tr>
              <th colSpan={theads.length}>{footer}</th>
            </tr>
          </tfoot>
        )}
        {footer1 && footer1.length > 0 && (
          <tfoot>
            <tr>
              {footer1.map((th, i) => (
                <th key={i}>
                  <div className={styles.thWrapper}>{th}</div>
                </th>
              ))}
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
}

Table.defaultProps = {
  fullhead: null,
  theads: [],
  data: [],
  footer: null,
  footer1: [],
  className: null,
  flat: false,
};

Table.propTypes = {
  fullhead: PropTypes.node,
  theads: PropTypes.arrayOf(PropTypes.node),
  // data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)),
  footer: PropTypes.node,
  footer1: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  flat: PropTypes.bool,
  sortHandlers: PropTypes.arrayOf(PropTypes.func),
};
