/*eslint-disable eqeqeq*/
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog, Input, Menu, Button } from 'components/template';
import styles from './AddUserDialog.module.scss';
import CenteredLoader from 'components/template/CenteredLoader';
import { FaSearch, FaPaperPlane } from 'react-icons/fa';
import ProfileInfo from '../ProfileInfo';
import Helpers from 'shared/helpers';
import { getUsers } from 'store/ducks/user';
import { Creators } from 'store/ducks/projects';
import { Creators as layoutActions } from 'store/ducks/layout';
import { useTranslation } from 'react-i18next';

const AddUserDialog = ({ isOpen, setIsOpen, projectId, projectUsers, ownerId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClose = () => {
    setIsOpen(!isOpen);
  };

  const defaultPermission = t('user.box19');
  const permissionMenuOption = [
    { label: t('user.box20'), value: t('user.box20') },
    { label: t('user.text1'), value: t('user.text1') },
    { label: t('user.box19'), value: t('user.box19') },
  ];
  const [users, setUsers] = useState(undefined);
  const [saving, setSaving] = useState(false);
  const [sendingMail, setSendingMail] = useState(false);
  const [userSearch, setUserSearch] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [usersPermissions, setUsersPermissions] = useState(initialusersPermissions());
  const [invitePermission, setInvitePermission] = useState(defaultPermission);

  const handleInputChange = e => {
    setUserSearch(Helpers.normalizedString(e.target.value));
    e.persist();
  };

  const handleEmailInput = e => {
    const value = e.target.value;
    setEmailInput(value);
    e.persist();
  };

  const buttonSendMailClick = async () => {
    if (!sendingMail) {
      if (emailInput.includes('@')) {
        setSendingMail(true);
        try {
          const response = await Creators.sendUserInvite(projectId, emailInput, invitePermission);
          if (response?.data != 'ok') {
            dispatch(
              layoutActions.showToast({
                content: <span style={{ padding: '20px 0' }}>{response?.error ?? ''}</span>,
                duration: 4000,
              })
            );
          } else {
            dispatch(
              layoutActions.showToast({
                content: <span style={{ padding: '20px 0' }}>{t('user.box1')}</span>,
                duration: 3000,
                type: 'success',
              })
            );
          }
        } finally {
          setSendingMail(false);
        }
      } else {
        dispatch(
          layoutActions.showToast({
            content: <span style={{ padding: '20px 0' }}>{t('user.box2')}</span>,
            duration: 2000,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (isOpen && users === undefined) {
      getUsers().then(usersApi => setUsers(usersApi?.data?.results ?? undefined));
    }
  });

  function initialusersPermissions() {
    const np = {};
    projectUsers.forEach(pu => {
      np[pu.user.id] = pu.permission;
    });
    return np;
  }

  let content =
    users === undefined || saving ? (
      <CenteredLoader />
    ) : (
      <>
        <div className={styles.inputContainer}>
          <Input
            rounded
            name="search"
            placeholder={t('user.box3')}
            onChange={handleInputChange}
            inputStyle={{ width: '100%' }}
            icon={<FaSearch />}
            iconPosition="right"
          />
        </div>
        <div className={styles.usersList}>
          {[
            //junta todos os usuários que já estão no projeto + os usuários da organização
            ...projectUsers.map(pu => pu.user),
            ...users.filter(user => projectUsers.find(pu => pu.user.id === user.id) === undefined),
          ].map(user => {
            const isOwner = user.id == ownerId;
            return (
              (userSearch === '' || Helpers.normalizedString(user.name).includes(userSearch)) && (
                <div className={styles.userItem}>
                  <ProfileInfo
                    user={{
                      name: user.name,
                      photo: user.profile?.photo ?? user.photo,
                      email: user.email,
                    }}
                  />
                  <div className={styles.userOptions}>
                    <Menu
                      className={styles.menu}
                      title={t('user.box4')}
                      largeMenu
                      rounded
                      value={usersPermissions[user.id] ?? defaultPermission}
                      setValue={permission => {
                        if (!isOwner) {
                          usersPermissions[user.id] = permission;
                          setUsersPermissions({ ...usersPermissions });
                        }
                      }}
                      placeholder={isOwner ? t('proj.box15') : undefined}
                      fixedPlaceholder={isOwner}
                      options={permissionMenuOption}
                      onClear={() =>
                        setUsersPermissions({
                          ...usersPermissions,
                          [user.id]: defaultPermission,
                        })
                      }
                    />
                    <Button
                      onClick={() => {
                        if (!isOwner) {
                          if (usersPermissions[user.id]) {
                            //Remover
                            delete usersPermissions[user.id];
                          } else {
                            //Adicionar
                            usersPermissions[user.id] = defaultPermission;
                          }
                          setUsersPermissions({ ...usersPermissions });
                        }
                      }}
                      className={styles.button}
                      rounded
                      fluid
                      outline
                      smaller
                      color={usersPermissions[user.id] ? 'danger' : 'green'}
                    >
                      {usersPermissions[user.id] ? t('home.box20') : t('user.box5')}
                    </Button>
                  </div>
                </div>
              )
            );
          })}
        </div>
        <div className={styles.inviteContainer}>
          <h4>{t('user.box6')}</h4>
          <div className={styles.inviteOptions}>
            <Menu
              className={styles.menu}
              title={t('user.box4')}
              largeMenu
              rounded
              value={invitePermission}
              setValue={setInvitePermission}
              options={permissionMenuOption}
            />
            <div className={styles.inputContainer}>
              <Input
                rounded
                name="search"
                placeholder={t('user.box7')}
                onChange={handleEmailInput}
                inputStyle={{ width: '100%' }}
                iconPosition="right"
              />
              <Button
                className={styles.buttonSendMail}
                style={sendingMail ? { color: '#aaaaaa', cursor: 'default' } : {}}
                onClick={buttonSendMailClick}
              >
                <FaPaperPlane />
              </Button>
            </div>
          </div>
        </div>
      </>
    );

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      mainTitle={t('user.box8')}
      btnLeft={t('dialog_content.cancel')}
      btnLeftClick={onClose}
      btnRight={t('dialog_content.save')}
      btnRightClick={async () => {
        setSaving(true);
        try {
          const projectUserExists = {};
          const changedUsers = { ...usersPermissions };
          const removedProjectUsers = [];
          projectUsers.forEach(pu => {
            const userId = pu.user.id;
            if (userId in changedUsers) {
              if (changedUsers[userId] === pu.permission) {
                //significa que nada foi alterado, entao não precisa chamar o endpoint
                delete changedUsers[userId];
              } else {
                //usado ali em baixo para fazer um update em vez de create
                projectUserExists[pu.id] = userId;
              }
            } else {
              //se o usuário já existente não contém na lista, significa que foi removido pelo usuário
              removedProjectUsers.push(pu.id);
            }
          });

          for (const projectUserId of removedProjectUsers) {
            await Creators.removeUser(projectId, projectUserId);
          }

          for (const [projectUserId, userId] of Object.entries(projectUserExists)) {
            await Creators.updateUser(projectId, projectUserId, userId, changedUsers[userId]);
            delete changedUsers[userId];
          }

          for (const [userId, permission] of Object.entries(changedUsers)) {
            await Creators.createUser(projectId, userId, permission);
          }
        } finally {
          window.location.reload();
        }
      }}
      className={{
        container: styles.dialogContainer,
      }}
    >
      {content}
    </Dialog>
  );
};

export default AddUserDialog;

AddUserDialog.defaultProps = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};
