import React from 'react';
import PropTypes from 'prop-types';
import styles from './FooterMessage.module.scss';
import { IoCheckmarkCircle, IoWarning } from "react-icons/io5";

const FooterBar = ({ backgroundColor, type, infoText, buttonLabel, onButtonClick }) => {
  return (
    <>
      <div className={styles.footerStyle} style={{backgroundColor: backgroundColor}}>
        <div className={styles.contentStyle}>
          <div className={styles.iconStyle}>
            {type === 'warning' && <IoWarning />}
            {type === 'success' && <IoCheckmarkCircle />}
          </div>
          <span>{infoText}</span>
          {buttonLabel && onButtonClick && (
            <button className={styles.buttonStyle} onClick={onButtonClick}>
              {buttonLabel}
            </button>
          )}
        </div>
      </div>
      <span className={styles.footerSpan}></span>
    </>
  );
};

FooterBar.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  type: 'warning' || 'success',
  infoText: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
};

FooterBar.defaultProps = {
  icon: null,
  buttonLabel: null,
  onButtonClick: null,
};

export default FooterBar;
