import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
//import "../../../translations/i18n"

import Popover from 'react-tiny-popover';
import { FaSortDown } from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import { Slider, Button, Tooltip } from '@material-ui/core';
import Card from '../Card/Card';
import styles from './SliderMenu.module.scss';

export default function SliderMenu({
  title,
  setFilter,
  max,
  min,
  className,
  rounded,
  larger,
  darker,
  disabled,
  onClear,
  ...props
}) {
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState([min, max]);
  const { t } = useTranslation();

  const menuClasses = [styles.menu];
  if (className) menuClasses.push(className);
  if (rounded) menuClasses.push(styles.rounded);
  if (larger) menuClasses.push(styles.larger);
  if (darker) menuClasses.push(styles.darker);
  if (disabled) menuClasses.push(styles.disabled);

  const cardClasses = [styles.card];
  const titleClasses = [styles.menuTitle];

  function handleChange(_, newValue) {
    setValue(newValue);
  }

  function handleChangeCommitted(_, newValue) {
    setFilter(newValue);
  }

  function handleClear() {
    setValue([min, max]);
    setFilter([min, max]);
  }

  const menuContent = (
    <Card softShadow className={cardClasses.join(' ')}>
      <div className={titleClasses.join(' ')}>
        <span>{title}</span> <MdClear onClick={() => setOpen(false)} />
      </div>
      <div className={styles.sliderContainer}>
        <div className={styles.sliderHeader}>
          <span>
            {t('slider_menu.in')}
            <span className={styles.sliderValue}>{value[0]}</span> e{' '}
            <span className={styles.sliderValue}>{value[1]}</span>
          </span>
        </div>
        <div className={styles.slider_mid_container}>
          <Slider
            classes={{ root: styles.sliderRoot }}
            ValueLabelComponent={props => {
              const { children, value } = props;
              return (
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                    open: true,
                  }}
                  enterDelay={0}
                  leaveDelay={0}
                  leaveTouchDelay={0}
                  enterTouchDelay={0}
                  placement="bottom"
                  title={value}
                  open
                  arrow
                >
                  {children}
                </Tooltip>
              );
            }}
            valueLabelDisplay="off"
            value={value || [min, max]}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
            max={max}
            min={min}
          />
        </div>
        <div className={styles.sliderFooter}>
          <span>
            <Button onClick={handleClear}>{t('slider_menu.clean')}</Button>
          </span>
        </div>
      </div>
    </Card>
  );

  return (
    <>
      <Popover
        isOpen={isOpen}
        position={['bottom', 'top']}
        onClickOutside={() => setOpen(false)}
        containerClassName={styles.popoverContainer}
        content={menuContent}
      >
        <div {...props} className={menuClasses.join(' ')} onClick={disabled ? null : () => setOpen(val => !val)}>
          <span>{title}</span> <FaSortDown />
        </div>
      </Popover>
    </>
  );
}

SliderMenu.defaultProps = {
  rounded: false,
  larger: false,
  darker: false,
  disabled: false,
  className: '',
  min: 1975,
  max: new Date().getFullYear(),
};

SliderMenu.propTypes = {
  title: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  rounded: PropTypes.bool,
  larger: PropTypes.bool,
  darker: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
