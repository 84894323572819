import React from 'react';
import PropTypes from 'prop-types';

import styles from './Card.module.scss';

export default function Card({ children, rounded, bordered, borderedGrey, className, softShadow, ...rest }) {
  const cardClasses = [styles.card];
  if (rounded) cardClasses.push(styles.rounded);
  if (bordered) cardClasses.push(styles.bordered);
  if (softShadow) cardClasses.push(styles.softShadow);
  if (borderedGrey) cardClasses.push(styles.borderedGrey);
  if (className) cardClasses.push(className);

  return (
    <div {...rest} className={cardClasses.join(' ')}>
      {children}
    </div>
  );
}

Card.defaultProps = {
  rounded: false,
  bordered: false,
  borderedGrey: false,
  className: null,
  softShadow: false,
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  rounded: PropTypes.bool,
  bordered: PropTypes.bool,
  borderedGrey: PropTypes.bool,
  className: PropTypes.string,
  softShadow: PropTypes.bool,
};
