import React from 'react';
import { useDispatch } from 'react-redux';

import { setAgency } from 'store/ducks/agencies';

import * as S from './styles';

const AgencyName = ({ id, name, emblem, agency }) => {

    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(setAgency(agency))
    };

    return (<S.Container>
        <S.Avatar noBorder noRadius img={emblem ?? ''} name={name} />
        <S.Link onClick={() => { handleClick() }} to={`/agencias/${id}/atletas`}>
            {name}
        </S.Link>
    </S.Container>)
}

export default AgencyName;