import ErrorMessages from 'shared/ErrorMessages';

export const parseSuccess = data => {
  return { data };
};
export const parseError = (err, customMessage) => {
  const { response } = err;
  if (customMessage)
    return {
      status: response?.status,
      debug: response?.data,
      error: customMessage,
    };
  if (response?.status === 401)
    return {
      status: response?.status,
      debug: response?.data,
      error: ErrorMessages.defaultCredentialsError,
    };
  return {
    status: response?.status,
    debug: response?.data ?? err?.message,
    error: ErrorMessages.serviceUnavailable,
  };
};
