import { createStore, compose, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
// import { createLogger } from 'redux-logger';

import appReducer from './ducks';
import { Types } from './ducks/user';

// const logger = createLogger();
const middlewares = [reduxThunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composer =
    process.env.NODE_ENV === 'development'
        ? composeEnhancers(applyMiddleware(...middlewares))
        : compose(applyMiddleware(...middlewares));

function rootReducer(state, action) {
    return action.type === Types.LOGOUT ? appReducer(undefined, action) : appReducer(state, action);
}

// const store = createStore(rootReducer, composer);

// let initState = {};
// const persistedState = localStorage.getItem('reduxState');
// if (persistedState) {
//   initState = JSON.parse(persistedState);
// }

const store = createStore(rootReducer, {}, composer);

export default store;
