/*eslint-disable eqeqeq*/
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Table, Button, Checkbox, Input } from 'components/template';
import { FaTrashAlt, FaSearch } from 'react-icons/fa';
import { MdAddCircle } from 'react-icons/md';
import New from './New';
import * as S from './styles';
import { Creators } from 'store/ducks/projects';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import BarIdentification from '../../../MyClub/Negotiations/BarIdentification';
import { formatDate } from 'shared/utility';

function ShadowTeam({ history }) {
  const { t } = useTranslation();
  const project_id = history?.location?.state?.id;
  const project = useSelector(state => state.project);
  const loggedUser = useSelector(state => state.user.data);
  const loggedUserPermission =
    (project.data?.users ?? []).find(projectUser => projectUser?.user?.id == loggedUser.id)?.permission ??
    'Visualizador';
  const [shadowTeamsChecked, setShadowTeamsChecked] = useState([]);
  const [deleteButtonDisable, setDeleteButtonDisable] = useState(false);

  const initialFilters = {
    name: '',
    schema: '',
  };
  const [filter, setFilter] = useState(initialFilters);
  const [allChecked, setAllChecked] = useState(false);

  const allCheckedArr = [];

  const shadowTeamData = project?.data?.shadow_teams;

  const filteredShadowTeams = shadowTeamData?.filter(item => {
    return item.name?.toLowerCase().includes(filter.name) && item.schema.toLowerCase().includes(filter.schema);
  });

  useEffect(() => {
    if (allChecked) {
      setShadowTeamsChecked(allCheckedArr);
    } else {
      setShadowTeamsChecked([]);
    }
    // eslint-disable-next-line
  }, [allChecked]);

  const data = filteredShadowTeams?.map(shadowTeam => {
    allCheckedArr.push(shadowTeam.id);
    const checkbox = (
      <Checkbox
        dark
        checked={shadowTeamsChecked.includes(shadowTeam.id)}
        onChange={() => {
          const index = shadowTeamsChecked.indexOf(shadowTeam.id);
          if (index > -1) {
            shadowTeamsChecked.splice(index, 1);
          } else {
            shadowTeamsChecked.push(shadowTeam.id);
          }
          setShadowTeamsChecked([...shadowTeamsChecked]);
        }}
      />
    );
    const nome = shadowTeam.name;
    const campo = shadowTeam.modelfield?.length ? t(`proj.modelo${shadowTeam.modelfield}`) : shadowTeam.modelfield;
    const esquema = shadowTeam.schema;
    const atletas = shadowTeam.athletes_count;
    const criado = formatDate(shadowTeam.created_at);
    const atualizado = formatDate(shadowTeam.lastmodified_at);
    const botao = (
      <Button
        color="link"
        noShadow
        onClick={() => {
          history.push(`/projetos/${project_id}/time-sombra/${shadowTeam.id}`);
        }}
        style={{
          float: 'right',
          fontSize: '1em',
          padding: '7px 22px',
          lineHeight: 'unset',
          fontWeight: '700',
        }}
      >
        {t('proj.text5')}
      </Button>
    );

    return [checkbox, nome, campo, esquema, atletas, criado, atualizado, botao];
  });

  const checkboxHeader = (
    <Checkbox
      dark
      checked={allChecked}
      onChange={() => {
        allChecked ? setAllChecked(false) : setAllChecked(true);
      }}
    />
  );

  const onAddShadowTeamButtonClick =
    loggedUserPermission != 'Visualizador'
      ? () => {
          history.push(`/projetos/${project_id}/time-sombra/0`);
        }
      : undefined;

  if (shadowTeamData?.length === 0) {
    return <New onAddButtonClick={onAddShadowTeamButtonClick} />;
  }

  return (
    <S.ShadowTeamPage>
      <BarIdentification title={t('proj.pageTitleTimeSombra')} subtitle={t('proj.pageDescTimeSombra')} />
      <S.FilterContainer softShadow bordered>
        <S.MenuFilter>
          <Button
            outline
            rounded
            color="green"
            disabled={deleteButtonDisable || loggedUserPermission == 'Visualizador'}
            style={{
              marginRight: '15px',
              fontSize: '14px',
              fontWeight: 700,
            }}
            onClick={
              loggedUserPermission == 'Visualizador'
                ? undefined
                : () => {
                    if (allChecked) {
                      Promise.all(
                        allCheckedArr.map(item => Creators.deleteShadowTeam(project.data.id, item))
                      ).then(() => window.location.reload());
                    }

                    if (!deleteButtonDisable && shadowTeamsChecked.length > 0 && !allChecked) {
                      setDeleteButtonDisable(true);
                      Promise.all(
                        shadowTeamsChecked.map(id => Creators.deleteShadowTeam(project.data.id, id))
                      ).then(() => window.location.reload());
                    }
                  }
            }
          >
            <FaTrashAlt style={{ marginRight: '10px', marginBottom: '-2px' }} />
            {t('buttons.excluir')}
          </Button>
          <S.FilterInputContainer>
            <S.FilterInputText>{t('proj.filtrarPor')}</S.FilterInputText>
            <Input
              rounded
              name="name"
              onChange={e =>
                setFilter({
                  ...filter,
                  name: e.target.value.toLowerCase(),
                })
              }
              inputStyle={{ backgroundColor: '#fff', width: '173px', margin: '0 15px 0 10px' }}
              placeholder={t('proj.filtarPorPlaceholder')}
              icon={<FaSearch className={styles.searchIcon} />}
              iconPosition="right"
            />
          </S.FilterInputContainer>
          <S.Menu
            title={t('proj.text7')}
            rounded
            allowEmpty
            placeholder={t('proj.text7')}
            options={shadowTeamData?.map(option => ({
              label: option.schema,
              value: option.schema,
            }))}
            value={filter?.schema}
            onClear={() => setFilter(initialFilters)}
            setValue={val => {
              setFilter({ ...filter, schema: val });
            }}
          />
        </S.MenuFilter>
        <S.FilterButton
          rounded
          fluid
          color="green"
          smaller
          onClick={onAddShadowTeamButtonClick}
          disabled={loggedUserPermission === 'Visualizador'}
        >
          {t('proj.text4')}
          <MdAddCircle className={styles.addButton} />
        </S.FilterButton>
      </S.FilterContainer>
      <S.ContentContainer softShadow>
        <Table
          flat
          theads={[
            checkboxHeader,
            t('proj.text6'),
            t('proj.campo'),
            t('proj.text23'),
            t('club.box7'),
            t('table.criadoEm'),
            t('table.atualizadoEm'),
            '',
          ]}
          data={data}
          className={styles.shadowTeamTable}
        />
      </S.ContentContainer>
    </S.ShadowTeamPage>
  );
}

export default withRouter(ShadowTeam);
