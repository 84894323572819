import Endpoints from 'endpoints';
import axios from 'api';
import { stringifyFilters } from 'shared/utility';

export const actionTypes = {
  GET: 'dashboardChat/GET',
  GET_SUCCESS: 'dashboardChat/GET_SUCCESS',
  GET_ERROR: 'dashboardChat/GET_ERROR',
};

const initialState = {
  loading: false,
  error: null,
  data: [],
};

export default function reducer(state = initialState, action) {    
  switch (action.type) {
    case actionTypes.GET:
      return {
        ...initialState,
        loading: true,
      };
    case actionTypes.GET_SUCCESS:
      return {
        ...initialState,
        loading: false,
        data: action.payload.data,
      };
    case actionTypes.GET_ERROR:
      return {
        ...initialState,
        error: action.payload.error,
        loading: false,
      };
    default:
      return state;
  }
}

function mapPayloadToChat(payload) {
    const chatCounts = {
      total: payload.from_origin.total_count_chats,
      tabs: [],
      bars: []
    };
  
    // Verifica e adiciona a tab 0: from_origin
    if (
      payload.from_origin.total_count_agency_to_club > 0 ||
      payload.from_origin.total_count_club_to_club > 0 ||
      payload.from_origin.total_count_athlete_club_to_club > 0 ||
      payload.from_origin.total_count_athlete_agency_to_club > 0 ||
      payload.from_origin.total_count_athlete_agency_to_agency > 0 ||
      payload.from_origin.total_count_team_agency_to_club > 0 ||
      payload.from_origin.total_count_team_club_to_agency > 0 ||
      payload.from_origin.total_count_team_agency_to_agency > 0 ||
      payload.from_origin.total_count_team_club_to_club > 0
    ) {
      chatCounts.tabs.push('from_origin');
      Object.entries(payload.from_origin).forEach(([label, value]) => {
        if (label !== 'total_count_chats' && value > 0) {
          chatCounts.bars.push({ tab: chatCounts.tabs.length - 1, label, value });
        }
      });
    }
  
    // Verifica e adiciona a tab 1: from_organization
    if (Object.keys(payload.from_organization.total_count_per_organization).length > 0) {
      chatCounts.tabs.push('from_organization');
      Object.entries(payload.from_organization.total_count_per_organization).forEach(([label, value]) => {
        chatCounts.bars.push({ tab: chatCounts.tabs.length - 1, label, value });
      });
    }
  
    // Verifica e adiciona a tab 2: from_users
    if (Object.keys(payload.from_users.total_count_per_user).length > 0) {
      chatCounts.tabs.push('from_users');
      Object.entries(payload.from_users.total_count_per_user).forEach(([label, value]) => {
        chatCounts.bars.push({ tab: chatCounts.tabs.length - 1, label, value });
      });
    }
  
    return chatCounts;
  }

let axisosGetDashboardController;
export const getDashboardChat = filters => async dispatch => {
  dispatch({ type: actionTypes.GET });
  const url = Endpoints.dashboardChat;

  try { 
    const queryString = stringifyFilters(filters);
    
    if (axisosGetDashboardController) {
        axisosGetDashboardController.abort();
    }
    axisosGetDashboardController = new AbortController();
    const { data } = await axios.get(`${url}`+queryString, { signal: axisosGetDashboardController.signal });
    axisosGetDashboardController = null;

    const chatData = mapPayloadToChat(data);    

    dispatch({
      type: actionTypes.GET_SUCCESS,
      payload: {
        data: chatData,
      },
    });
  } catch (err) {
    if (err?.name === 'CanceledError') {
      //requisição cancelada
    } else {
      dispatch({
        type: actionTypes.GET_ERROR,
        payload: { error: 'erros.text20' },
      });
    }
  }
};
