import styles from './LanguageButton.module.scss';
import { BiChevronDown } from 'react-icons/bi';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BRFLAG from 'assets/ico/br-flag.png';
import USFLAG from 'assets/ico/uk-flag.png';
//import PTFLAG from 'assets/ico/pt-flag.png';
import SPFLAG from 'assets/ico/sp-flag.png';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/template';
import LanguageCard from './LanguageCard';
import { Creators as layoutCreators } from 'store/ducks/layout';

export default function LanguageButton({ toggleNavDrawer, className }) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const layout = useSelector(state => state.layout);
  const brflag = <img draggable="false" className={styles.brflag} src={BRFLAG} alt="BR" />;
  const usflag = <img draggable="false" className={styles.usflag} src={USFLAG} alt="BR" />;
  //const ptflag = <img draggable='false' className={styles.ptflag} src={PTFLAG} alt='BR' />;
  const spflag = <img draggable="false" className={styles.spflag} src={SPFLAG} alt="BR" />;
  const [isLanguageCardVisible, setLanguageCardVisibility] = useState(false);

  const languageDescription = {
    br: 'PT-BR',
    //"pt": "PT-PT",
    en: 'EN-US',
    esp: 'ES-ES',
  };

  const languageImg = {
    br: brflag,
    //"pt": ptflag,
    en: usflag,
    esp: spflag,
  };

  const navDrawerToggler = () => {
    dispatch(layoutCreators.hideNavDrawer());
  };

  return (
    <div className={[styles.languageButton, className].join(' ')}>
      {languageImg[i18n.language]}
      <Button
        onClick={() => {
          setLanguageCardVisibility(!isLanguageCardVisible);
          navDrawerToggler(false);
        }}
      >
        {languageDescription[i18n.language]}
        <LanguageCard
          isVisible={isLanguageCardVisible && layout.isNavDrawerVisible === false}
          hide={() => {
            setLanguageCardVisibility(false);
          }}
        />
      </Button>
      <BiChevronDown className={styles.arrowDown} />
    </div>
  );
}
