import styled from 'styled-components';
import Variables from 'variables';
import { Card as DefaultCard, Avatar as DefaultAvatar } from 'components/template'

const darkPurple9 = [`
  color: ${Variables.lightPurple1};
  font-weight: 700;
`];

export const Card = styled(DefaultCard)`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 10px 20px; 
  margin-bottom: 10px;
  width: 67%;
  border: 1px solid #BDBDBD;
`;

export const Hour = styled.span`
  margin-right: 20px;
`;

export const Avatar = styled(DefaultAvatar)`
  height: 35px;
  width: 35px;
`;

export const Message = styled.span`
  margin-left: 10px;
`;

export const UserName = styled.span(darkPurple9);
export const AthleteName = styled.span(darkPurple9);