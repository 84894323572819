import React, { useEffect, useState } from 'react';
import axios from 'api';
import LatestMoves from '../LatestMoves';
import styles from './General.module.scss';
import { Spinner, Avatar, Dialog, Input, Button, IconButton, Menu, Checkbox } from 'components/template';
import { AgencyAthletesRepository } from 'data/agencyAthletes';
import ContractsResume from './ContractsResume/ContractsResume';
import AgencyInfo from './AgencyInfo/AgencyInfo';
import Users from './Users/Users';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import { HiTrash } from 'react-icons/hi';
import { updateAgencyInfo } from 'store/ducks/agencies';
import { getCountries } from 'store/ducks/countries';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as layoutActions } from 'store/ducks/layout';

export default function General({ agency, agencyId }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { countries } = useSelector(state => state.countries);

  const initialAgencyInfo = {
    emblem: agency.emblem ?? '',
    photos: agency.photos ?? [],
    email: agency.email ?? '',
    is_fifa_agency: agency.is_fifa_agency ?? false,
    main_market: {},
    address: {
      city: agency.address.city ?? '',
      full_address: agency.address.full_address ?? '',
      state: agency.address.state ?? '',
    },
    social: {
      website: agency.social?.website ?? '',
      instagram: agency.social?.instagram ?? '',
      twitter: agency.social?.twitter ?? '',
      youtube: agency.social?.youtube ?? '',
      linkedin: agency.social?.linkedin ?? '',
      facebook: agency.social?.facebook ?? '',
    },
  };

  const [loading, setLoading] = useState(false);
  const [uploadingImg, setUploadingImg] = useState(false);
  const [error, setError] = useState(null);
  const [athletes, setAthletes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [agencyInfo, setAgencyInfo] = useState(initialAgencyInfo);
  const [step, setStep] = useState(0);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const data = await AgencyAthletesRepository.list(agencyId, { limit: '9999' });
      setError(data.error);
      setAthletes(data.results);
      setLoading(false);
    }
    if (agencyId) fetchData();
  }, [agencyId]);

  useEffect(() => {
    dispatch(getCountries());
    // eslint-disable-next-line
  }, [dispatch]);

  function onClose() {
    setIsOpen(false);
    setAgencyInfo(initialAgencyInfo);
  }

  function handleImageUpload() {
    setUploadingImg(true);
    const imageInput = document.createElement('input');
    imageInput.type = 'file';
    imageInput.multiple = true;

    imageInput.addEventListener('change', event => {
      const files = event.target.files;

      const formData = new FormData();
      formData.append('photo', files[0]);

      axios
        .post(`/agencies/${agencyId}/photos`, formData)
        .then(response => {
          setAgencyInfo(prevAgencyInfo => ({
            ...prevAgencyInfo,
            photos: [...prevAgencyInfo.photos, response.data],
          }));
        })
        .finally(() => {
          setUploadingImg(false);
        });
    });
    imageInput.click();
  }

  const hasPhotos = (agencyInfo.photos?.length ?? 0) > 0;
  function handleImageDelete() {
    if (hasPhotos) {
      setUploadingImg(true);
      const img = agencyInfo.photos[step];
      axios.delete(`/agencies/${agencyId}/photos/${img.id}`).finally(() => {
        setUploadingImg(false);
      });
      setAgencyInfo(prevAgencyInfo => ({
        ...prevAgencyInfo,
        photos: prevAgencyInfo.photos.filter(photo => photo !== img),
      }));
    }
  }

  function handleLogoUpdate() {
    setUploadingImg(true);
    const imageInput = document.createElement('input');
    imageInput.type = 'file';
    imageInput.multiple = false;

    imageInput.addEventListener('change', event => {
      const files = event.target.files;

      const formData = new FormData();
      formData.append('emblem', files[0]);

      axios
        .patch(`/agencies/${agencyId}`, formData)
        .then(response => {
          setAgencyInfo({
            ...agencyInfo,
            emblem: response.data.emblem,
          });
        })
        .finally(() => {
          setUploadingImg(false);
        });
    });
    imageInput.click();
  }

  const dotClasses = [styles.image_dot];
  if (step || 1) dotClasses.push(styles.selected);

  const socialOnBlur = socialKey => {
    const socialValue = agencyInfo.social[socialKey];
    if (socialValue && socialValue.includes('.') && !socialValue.includes('http')) {
      agencyInfo.social[socialKey] = `https://${socialValue}`;
      setAgencyInfo({
        ...agencyInfo,
        social: { ...agencyInfo.social }
      })
    }
  }

  return (
    <div className={styles.containerWrapper}>
      {loading && (
        <div className={styles.loading}>
          <Spinner />
        </div>
      )}
      {error && (
        <div className={styles.error}>
          <span>{error}</span>
        </div>
      )}
      {!error && !loading && (
        <div className={styles.container}>
          <div className={styles.leftPanel}>
            <AgencyInfo agency={agency} setIsOpen={setIsOpen} />
            <Users agency={agency} />
          </div>
          <div className={styles.middlePanel}>
            <LatestMoves styleClass={styles.latestNews} />
          </div>
          <div className={styles.rightPanel}>
            <ContractsResume agencyId={agencyId} athletes={athletes.slice(0, 50)} />
          </div>
        </div>
      )}
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        className={{
          container: styles.dialog,
          header: styles.dialogHeader,
          body: styles.dialogBody,
          footer: styles.dialogFooter,
        }}
        mainTitle={t('myAgency.edit_agency')}
      >
        <div className={styles.body_container}>
          <div className={styles.left_container}>
            <h1 className={styles.left_title}>{t('rest.box6')}</h1>
            <div className={styles.avatar_container}>
              <Avatar noBorder noRadius img={agencyInfo.emblem} name={agency.nickname} className={styles.club_avatar} />
              <div className={styles.avatar_side}>
                <span>Logo</span>
                <Button
                  style={{
                    cursor: uploadingImg ? 'wait' : 'pointer',
                    opacity: uploadingImg ? '0.6' : '1',
                  }}
                  className={styles.avatar_button}
                  onClick={() => {
                    handleLogoUpdate();
                  }}
                >
                  {t('organi.box1')}
                </Button>
              </div>
            </div>
            <div className={styles.recomendedDimension}>{t('myAgency.recomendedDimensionLogo')}</div>
            <div className={styles.images_container}>
              <h1>{t('rest.box6')}</h1>
              <div className={styles.images_display}>
                <div className={styles.carousel_container}>
                  <Carousel
                    showThumbs={false}
                    dynamicHeight={true}
                    showStatus={false}
                    emulateTouch={true}
                    showArrows={true}
                    showIndicators={false}
                    onChange={index => setStep(index)}
                    className={styles.carousel}
                    selectedItem={step}
                  >
                    {hasPhotos ? (
                      agencyInfo.photos?.map((photo, index) => {
                        return <img key={index} className={styles.image} alt={`club${index}`} src={photo.photo} />;
                      })
                    ) : (
                      <div className={styles.agency_general_no_image} />
                    )}
                  </Carousel>
                </div>
                {hasPhotos && (
                  <IconButton
                    className={styles.delete_image_icon}
                    style={{
                      cursor: uploadingImg ? 'wait' : 'pointer',
                      opacity: uploadingImg ? '0.6' : '1',
                    }}
                    icon={<HiTrash />}
                    onClick={() => {
                      handleImageDelete();
                    }}
                  />
                )}
              </div>
              <div className={styles.images_dots}>
                {agencyInfo.photos?.map((item, index) => {
                  return (
                    <div
                      className={index === step ? dotClasses.join(' ') : styles.image_dot}
                      onClick={() => setStep(index)}
                      key={item}
                    ></div>
                  );
                })}
              </div>
              <Button
                className={styles.add_image_button}
                style={{
                  cursor: uploadingImg ? 'wait' : 'pointer',
                  opacity: uploadingImg ? '0.6' : '1',
                }}
                onClick={() => {
                  handleImageUpload();
                }}
              >
                {t('myClub.general.addImage')}
              </Button>
              <div className={styles.verificationContainer}>
                <h1>{t('agencies.text8')}</h1>
                <div className={styles.verificationCheckboxes}>
                  <Checkbox
                    value={agencyInfo.is_fifa_agency}
                    className={styles.checkbox}
                    onChange={e =>
                      setAgencyInfo({
                        ...agencyInfo,
                        is_fifa_agency: true,
                      })
                    }
                    label={t('club.text3')}
                    checked={agencyInfo.is_fifa_agency}
                    dark
                  />
                  <Checkbox
                    value={!agencyInfo.is_fifa_agency}
                    onChange={e =>
                      setAgencyInfo({
                        ...agencyInfo,
                        is_fifa_agency: false,
                      })
                    }
                    label={t('club.text4')}
                    checked={!agencyInfo.is_fifa_agency}
                    dark
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.right_container}>
            <h1 className={styles.right_title}>{t('myAgency.dialog_title')}</h1>
            <div className={styles.input_city}>
              <span>{t('organi.box9')}</span>
              <Input
                value={agencyInfo.address.city}
                onChange={e =>
                  setAgencyInfo({
                    ...agencyInfo,
                    address: {
                      ...agencyInfo.address,
                      city: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className={styles.input_email}>
              <span>Email</span>
              <Input
                value={agencyInfo.email}
                onChange={e =>
                  setAgencyInfo({
                    ...agencyInfo,
                    email: e.target.value,
                  })
                }
              />
            </div>
            <div className={styles.input_main_market}>
              <span>{t('myAgency.principal_market')}</span>
              <Menu
                className={styles.country_menu}
                rounded
                title="País"
                placeholder={agencyInfo.main_market.name}
                options={countries?.data?.map(country => ({ label: country.name, value: country.code }))}
                setValue={value => {
                  setAgencyInfo({
                    ...agencyInfo,
                    main_market: countries.data.find(
                      country =>
                        country.code === value && {
                          name: country.name,
                          code: country.code,
                          flag: country.flag,
                        }
                    ),
                  });
                }}
                onClear={() => ''}
                value={agencyInfo.main_market.name}
              />
            </div>
            <div className={styles.input_site}>
              <span>Site</span>
              <Input
                value={agencyInfo.social.website}
                onChange={e =>
                  setAgencyInfo({
                    ...agencyInfo,
                    social: {
                      ...agencyInfo.social,
                      website: e.target.value,
                    },
                  })
                }
                onBlur={e => socialOnBlur('website')}
              />
            </div>
            <div className={styles.input_instagram}>
              <span>Instagram</span>
              <Input
                value={agencyInfo.social.instagram}
                onChange={e =>
                  setAgencyInfo({
                    ...agencyInfo,
                    social: {
                      ...agencyInfo.social,
                      instagram: e.target.value,
                    },
                  })
                }
                onBlur={e => socialOnBlur('instagram')}
              />
            </div>
            <div className={styles.input_twitter}>
              <span>X</span>
              <Input
                value={agencyInfo.social.twitter}
                onChange={e =>
                  setAgencyInfo({
                    ...agencyInfo,
                    social: {
                      ...agencyInfo.social,
                      twitter: e.target.value,
                    },
                  })
                }
                onBlur={e => socialOnBlur('twitter')}
              />
            </div>
            <div className={styles.input_youtube}>
              <span>YouTube</span>
              <Input
                value={agencyInfo.social.youtube}
                onChange={e =>
                  setAgencyInfo({
                    ...agencyInfo,
                    social: {
                      ...agencyInfo.social,
                      youtube: e.target.value,
                    },
                  })
                }
                onBlur={e => socialOnBlur('youtube')}
              />
            </div>
            <div className={styles.input_linkedin}>
              <span>Linkedin</span>
              <Input
                value={agencyInfo.social.linkedin}
                onChange={e =>
                  setAgencyInfo({
                    ...agencyInfo,
                    social: {
                      ...agencyInfo.social,
                      linkedin: e.target.value,
                    },
                  })
                }
                onBlur={e => socialOnBlur('linkedin')}
              />
            </div>
            <div className={styles.input_facebook}>
              <span>Facebook</span>
              <Input
                value={agencyInfo.social.facebook}
                onChange={e =>
                  setAgencyInfo({
                    ...agencyInfo,
                    social: {
                      ...agencyInfo.social,
                      facebook: e.target.value,
                    },
                  })
                }
                onBlur={e => socialOnBlur('facebook')}
              />
            </div>
          </div>
        </div>
        <div className={styles.dialog_footer}>
          <Button
            className={styles.dialog_footer_button_cancel}
            onClick={() => {
              onClose();
            }}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            className={styles.dialog_footer_button_save}
            onClick={async () => {
              for (const socialKey of Object.keys(agencyInfo.social)) {
                const socialValue = (agencyInfo.social[socialKey] ?? '').toLowerCase();
                if (socialValue.length > 0 && (!socialValue.includes('.') || !socialValue.includes('http'))) {
                  dispatch(
                    layoutActions.showToast({
                      content: t('erros.link'),
                      duration: 3000,
                    })
                  );
                  return;
                }
              }
              const updateBody = { ...agencyInfo };
              delete updateBody.emblem;
              updateAgencyInfo(agencyId, updateBody)
                .then(res => {
                  if (res?.id) {
                    window.location.reload();
                  }
                })
                .catch(err => { });
            }}
          >
            {t('buttons.save')}
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
