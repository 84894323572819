import React from 'react';
import styles from './Header.module.scss';
import { useTranslation } from 'react-i18next';
import { Input, Menu } from 'components/template';
import { FaArrowLeft, FaSearch } from 'react-icons/fa';
import { debounce } from 'shared/utility';

export default function Header({
  selectedRepository,
  sidebarLoading,
  chat,
  chats,
  filters,
  handleFilterChange,
  otherChatActive,
  otherChatClose,
}) {
  const { t } = useTranslation();

  function handleSearch(value) {
    handleFilterChange('messages', value);
  }

  const delayedHandleSearch = debounce(handleSearch);

  const defaultComponent = (
    <div className={styles.container}>
      <div className={styles.FilterInputContainer}>
        <Input
          rounded
          name="messages"
          onChange={e => delayedHandleSearch(e.target.value)}
          inputStyle={{ backgroundColor: '#fff' }}
          placeholder={t('transferMarket.filterMessagesPlaceholder')}
          icon={<FaSearch className={styles.searchIcon} />}
          iconPosition="right"
        />
      </div>
      <div className={styles.FilterMenuContainer}>
        {selectedRepository === 'negociacoes' && (
          <Menu
            rounded
            title={t('transferMarket.headerTransferTypeTitle')}
            placeholder={t(`transferMarket.filterTransferTypePlaceholder`)}
            options={[
              {
                label: t(`transferMarket.filterTransferTypePlaceholder`),
                value: '',
              },
              {
                label: t(`transferMarket.filterTransferTypeSALE`),
                value: 'SALE',
              },
              {
                label: t(`transferMarket.filterTransferTypeLOAN`),
                value: 'LOAN',
              },
            ]}
            value={filters.transferType ?? ''}
            setValue={value => handleFilterChange('transferType', value)}
            onClear={() => handleFilterChange('transferType', '')}
            className={styles.FilterMenu}
          />
        )}
        {selectedRepository === 'clubes' && (
          <Menu
            rounded
            title={t('chat.clubs')}
            placeholder={t('chat.clubs')}
            options={chats.reduce((uniqueOptions, chat) => {
              const label = chat.team?.name;
              const value = String(chat.team?.id);

              const isLabelUnique = !uniqueOptions.some(option => option.label === label);

              if (isLabelUnique) {
                uniqueOptions.push({ label, value });
              }

              return uniqueOptions;
            }, [])}
            value={filters.team ?? ''}
            setValue={value => handleFilterChange('team', value)}
            onClear={() => handleFilterChange('team', '')}
            className={styles.FilterMenu}
          />
        )}
      </div>
    </div>
  );

  const otherChatComponent = (
    <div className={styles.container}>
      <span className={styles.otherChatClose} onClick={otherChatClose}>
        <FaArrowLeft />
      </span>

      <span className={styles.otherChatText}>
        {t('negotiations.headerOtherChatTitle')}
        <b>{chat?.athlete?.shortName}</b>
      </span>
    </div>
  );

  return otherChatActive ? otherChatComponent : defaultComponent;
}
