const orderShadowTeamPositions = positions => {
  return positions.sort((a, b) => {
    const aInt = parseInt(a.position_name);
    const bInt = parseInt(b.position_name);
    const aIsNan = isNaN(aInt);
    const bIsNan = isNaN(bInt);
    if (aIsNan && bIsNan) {
      //comparação entre textos
      return ('' + a.attr).localeCompare(b.attr);
    } else if (aIsNan || bIsNan) {
      //comparação entre um texto e um numero
      if (bIsNan) {
        //"a" é numero, então deve ficar na frente
        return -1;
      } else {
        return 1;
      }
    } else {
      //comparação entre numeros
      return aInt - bInt;
    }
  });
};

export default orderShadowTeamPositions;
