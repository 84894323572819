import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Creators } from 'store/ducks/chat';
import { Creators as notificationsCreators } from 'store/ducks/notifications';

import styles from './ChatIcon.module.scss';

import { IconButton } from 'components/template';
import { BsFillChatSquareDotsFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
// import ChatCard from './ChatCard/ChatCard';

export default function ChatIcon({ className, notifications, darkMode }) {
  const dispatch = useDispatch();
  // const chat = useSelector(state => state.chat);

  useEffect(() => {
    dispatch(Creators.getChat());
  }, [dispatch]);

  
  useEffect(() => {
    if (!notifications.update) {
      return;
    }
    const pathname = window.location.pathname;
    if (pathname.includes('/transfer-market/my-negotiations') || pathname.includes('/mensagens')) {
      //ignora pois já está nas páginas de mensagens, que tem um tratamento interno para as notificações
      return;
    }

    dispatch(notificationsCreators.getNotifications());
  }, [notifications]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={[styles.container, className].join(' ')}>
      <Link to="/mensagens" className={styles.link}>
        <IconButton color="purple" className={`${darkMode ? styles.dark : ''}`} icon={<BsFillChatSquareDotsFill />} />
      </Link>
      {!!notifications.data.newMessagesCount && (
        <div className={styles.counter}>{notifications.data.newMessagesCount}</div>
      )}

      {/* <ChatCard
        isVisible={isChatCardVisible}
        hide={() => setChatCardVisibility(false)}
        items={chat.data}
        loading={chat.loading}
      /> */}
    </div>
  );
}

ChatIcon.defaultProps = {
  className: '',
};

ChatIcon.propTypes = {
  className: PropTypes.string,
};
