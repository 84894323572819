import React, { useState, cloneElement, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as layoutCreators } from 'store/ducks/layout';
import styles from './ReportLayout.module.scss';
import AppBar from './AppBar/AppBar';
import ReportDialog from 'components/ReportDialog/ReportDialog';
import { updateReport } from 'store/ducks/reports';
import _ from 'lodash';
import { Toast } from 'components/template';
import { useTranslation } from 'react-i18next';

export default function ReportLayout({ children }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const layout = useSelector(state => state.layout);
  const user = useSelector(state => state.user.data);
  const createdReport = useSelector(state => state.reports.report);

  if (user == null) {
    window.location.href = '/login';
  }

  if (!(user.plan.busca_de_atletas || user.plan.mercado_de_transferencias || user.plan.analise_de_mercado)) {
    localStorage.setItem('rdf_excededPlanLimit', 'true');
    window.location.href = '/home';
  }

  const [previousReport, setPreviousReport] = useState(null);
  const [report, setReport] = useState(null);

  const [timeoutId, setTimeoutId] = useState(null);
  const [toastConfigs, setToastConfigs] = useState({
    isVisible: false,
    type: 'warning',
    msg: t('app.text3'),
    duration: 10000,
  });

  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      if (
        previousReport &&
        (previousReport.athletes?.length > 0 || previousReport.shadow_team_athletes?.length > 0) &&
        !_.isEqual(previousReport, report)
      ) {
        clearTimeout(timeoutId);
        setTimeoutId(
          setTimeout(() => {
            dispatch(updateReport(createdReport.data, report));
          }, 3000)
        );
      }

      setPreviousReport(report);
    } else {
      mounted.current = true;
    }
  }, [report]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (createdReport.updating) {
      setToastConfigs({
        isVisible: true,
        msg: t('app.text3'),
        type: 'warning',
        duration: 20000,
      });
    } else if (!createdReport.updating && toastConfigs.isVisible) {
      const newToast = {
        isVisible: true,
        msg: t(createdReport.error ?? 'app.text4'),
        type: 'success',
        duration: 3000,
      };
      setToastConfigs(newToast);
    }
  }, [createdReport.updating]); // eslint-disable-line react-hooks/exhaustive-deps

  const showDialog = () => {
    dispatch(layoutCreators.showDialog());
  };

  const hideToast = () => {
    setToastConfigs({
      ...toastConfigs,
      isVisible: false,
      duration: 0,
    });
  };

  return (
    <div className={styles.container}>
      <AppBar showDialog={showDialog} />
      <ReportDialog isOpen={layout.isDialogVisible} />
      {cloneElement(children, { setReport, report })}
      <Toast
        isVisible={toastConfigs.isVisible}
        content={toastConfigs.msg}
        type={toastConfigs.type}
        onClose={hideToast}
        duration={toastConfigs.duration}
      />
      <Toast
        isVisible={layout.toast.isVisible}
        content={layout.toast.content}
        type={layout.toast.type}
        onClose={() => {
          if (typeof layout.toast.onCloseHandler === 'function') layout.toast.onCloseHandler();
          dispatch(layoutCreators.hideToast());
        }}
        duration={layout.toast.duration}
      />
    </div>
  );
}

ReportLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
