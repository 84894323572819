import React, { useState, useEffect } from 'react';
import styles from './InputApply.module.scss';
import { AiOutlineSearch } from 'react-icons/ai';
import { Input } from 'components/template';

const InputApply = ({ onClick, icon, value, type }) => {
  const [inputValue, setInputvalue] = useState(value);

  useEffect(() => {
    setInputvalue(value);
  }, [value]);

  return (
    <div className={styles.inputButtonContainer}>
      <Input
        type={type}
        rounded
        icon={icon ?? <AiOutlineSearch className={styles.iconSearch} onClick={() => onClick(inputValue)} />}
        iconPosition="right"
        onChange={event => setInputvalue(event.target.value)}
        onKeyUp={e => {
          if (e.keyCode === 13) {
            onClick(inputValue);
          }
        }}
        value={inputValue}
      />
    </div>
  );
};

export default InputApply;
