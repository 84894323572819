import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Table, Spinner, Menu, Button, ToolTip } from 'components/template';
import TeamRow from 'components/TeamRow/TeamRow';
import { getAthleteHistorical } from 'store/ducks/athleteV2';
import styles from './Performance.module.scss';
import IcoTempo from 'assets/ico/ico-tempo.png';
import IcoJogos from 'assets/ico/ico-jogos.png';
import IcoTitular from 'assets/ico/ico-titular.png';
import IcoGols from 'assets/ico/ico-gols.png';
import IcoBanco from 'assets/ico/ico-banco.png';
import IcoAmarelos from 'assets/ico/ico-amarelos.png';
import IcoAmareloVermelho from 'assets/ico/ico-amarelo-vermelho.png';
import IcoVermelhos from 'assets/ico/ico-vermelhos.png';
import { useTranslation } from 'react-i18next';
import { criteriaOptions } from 'shared/mocks';

function Performance({ athleteId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const athleteHistorical = useSelector(state => state.athleteV2.athleteHistorical);

  const [filters, setFilters] = useState({
    season: [],
    team: [],
    competition: [],
  });

  const [groupBy, setGroupBy] = useState('');

  const initialSelectedFilters = {
    season: [],
    team: [],
    competition: [],
  };

  const [selectedFilters, setSelectedFilters] = useState(initialSelectedFilters);

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getAthleteHistorical(athleteId));
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (athleteHistorical.data.length > 0) {
      setData(athleteHistorical.data);
      createFilters(athleteHistorical.data);
    }
  }, [athleteHistorical.data]);

  useEffect(() => {
    if (athleteHistorical.data.length > 0) {
      applyFilters();
    }
  }, [selectedFilters.team.length, selectedFilters.season.length, selectedFilters.competition.length]); // eslint-disable-line react-hooks/exhaustive-deps

  function applyFilters() {
    if (
      selectedFilters.team.length > 0 ||
      selectedFilters.season.length > 0 ||
      selectedFilters.competition.length > 0
    ) {
      const filteredData = athleteHistorical.data.filter(register => {
        return (
          (selectedFilters.team.length === 0 || selectedFilters.team.some(team => team === register.team?.nickname)) &&
          (selectedFilters.season.length === 0 ||
            selectedFilters.season.some(season => String(season) === String(register.season))) &&
          (selectedFilters.competition.length === 0 ||
            selectedFilters.competition.some(competition => competition === register.competition_name))
        );
      });

      setData(filteredData);
    } else {
      setData(athleteHistorical.data);
    }
  }

  async function createFilters(data) {
    let seasons = [];
    let teams = [];
    let competitions = [];

    await data.forEach(register => {
      seasons.push(register.season);
      teams.push(register.team?.nickname);
      competitions.push(register.competition_name);
    });

    setFilters({
      season: [...new Set(seasons)],
      team: [...new Set(teams)],
      competition: [...new Set(competitions)],
    });
  }

  function filterData(data) {
    //se possuir groupBy cria uma cópia de data para podermos trabalhar sem dar conflito
    const hasGroupBy = groupBy?.length > 0;
    let fData = hasGroupBy
      ? data.map(d => {
          return { ...d };
        })
      : data;

    //agrupamento dos itens
    if (hasGroupBy) {
      const groupedData = new Map(); //é utilizado um Map para garantir a mesma ordenação do backend
      fData.forEach((register, registerIndex) => {
        //verifica por qual valor vai agrupar
        let registerKey = null;
        if (groupBy === 'season') {
          registerKey = register.season;
        } else if (groupBy === 'competition') {
          registerKey = register.competition_name;
        } else if (groupBy === 'team' && register.team) {
          registerKey = register.team.id;
        }

        //agrupa os registros desse valor
        if (registerKey) {
          const teamId = register.team?.id;
          const groupedRegister = groupedData.get(registerKey);
          if (groupedRegister) {
            if (groupBy !== 'season' && !groupedRegister.season.includes(register.season)) {
              groupedRegister.season.push(register.season);
            }
            if (groupBy !== 'competition' && !groupedRegister.competition_name.includes(register.competition_name)) {
              groupedRegister.competition_name.push(register.competition_name);
            }
            if (groupBy !== 'team' && teamId && !groupedRegister.team_component.has(teamId)) {
              const key = `${registerIndex}.${teamId}`;
              groupedRegister.team_component.set(teamId, <TeamRow id={teamId} img={register.team?.emblem} key={key} />);
            }
            groupedRegister.minutes_played += register.minutes_played;
            groupedRegister.appearances += register.appearances;
            groupedRegister.lineups += register.lineups;
            groupedRegister.on_bench += register.on_bench;
            groupedRegister.goals += register.goals;
            groupedRegister.yellow_cards += register.yellow_cards;
            groupedRegister.second_yellow_cards += register.second_yellow_cards;
            groupedRegister.red_cards += register.red_cards;
          } else {
            if (groupBy !== 'season') {
              register.season = [register.season];
            }
            if (groupBy !== 'competition') {
              register.competition_name = [register.competition_name];
            }
            if (groupBy !== 'team') {
              register.team_component = new Map();
              if (teamId) {
                const key = `${registerIndex}.${teamId}`;
                register.team_component.set(teamId, <TeamRow id={teamId} img={register.team?.emblem} key={key} />);
              }
            }
            groupedData.set(registerKey, register);
          }
        }
      });

      //atribui os registros agrupados para o fData
      fData = Array.from(groupedData.values()).map(register => {
        if (groupBy !== 'season') {
          register.season = register.season.join(', ');
        }
        if (groupBy !== 'competition') {
          register.competition_name = register.competition_name.join(', ');
        }
        if (groupBy !== 'team') {
          register.team_component = Array.from(register.team_component.values());
        }
        return register;
      });
    }

    //transforma os itens em registros para a tabela
    const filteredData = fData.map(register => {
      const season = <div title={register.season}>{register.season}</div>;
      const competition = <div title={register.competition_name}>{register.competition_name}</div>;
      const team = register.team_component ?? (
        <TeamRow name={register.team?.nickname} id={register.team?.id} img={register.team?.emblem} />
      );
      //const availability = '???';
      const minutesPlayed = parseInt(register.minutes_played);
      const appearances = register.appearances;
      const lineups = register.lineups;
      const onBench = register.on_bench;
      const goals = register.goals;
      const yellowCards = register.yellow_cards;
      const secondYellow = register.second_yellow_cards;
      const redCards = register.red_cards;

      return [
        season,
        competition,
        team,
        //availability,
        isNaN(minutesPlayed) ? '-' : `${minutesPlayed.toLocaleString('pt-BR')}'`,
        appearances,
        lineups,
        onBench,
        goals,
        yellowCards,
        secondYellow,
        redCards,
      ];
    });

    return filteredData;
  }

  let content;

  if (!athleteHistorical.error) {
    content = (
      <div className={styles.container}>
        <div className={styles.header_container}>
          <span className={styles.header}>{t('club.text16')}</span>
          <span className={styles.sub_header}>{t('club.text18')}</span>
        </div>
        <div className={styles.filter_container}>
          <span>{t('proj.filtrarPor')}</span>
          <Menu
            title={t('slider_menu.seas')}
            rounded
            allowEmpty
            placeholder={t('slider_menu.season_all')}
            className={styles.menu}
            options={filters.season.map(filter => ({ label: String(filter), value: String(filter) }))}
            setValue={value => setSelectedFilters({ ...selectedFilters, season: value })}
            value={selectedFilters.season}
            onClear={() => setSelectedFilters({ ...selectedFilters, season: initialSelectedFilters.season })}
            multiple
          />
          <Menu
            title={t('club.text17')}
            rounded
            allowEmpty
            placeholder={t('slider_menu.team_all')}
            className={styles.menu}
            options={filters.team.map(filter => ({ label: filter, value: filter }))}
            setValue={value => setSelectedFilters({ ...selectedFilters, team: value })}
            value={selectedFilters.team}
            onClear={() => setSelectedFilters({ ...selectedFilters, team: initialSelectedFilters.team })}
            multiple
          />
          <Menu
            title={t('match.comp')}
            rounded
            allowEmpty
            placeholder={t('slider_menu.competition_all')}
            className={styles.menu}
            options={filters.competition.map(filter => ({ label: filter, value: filter }))}
            setValue={value => setSelectedFilters({ ...selectedFilters, competition: value })}
            onClear={() => setSelectedFilters({ ...selectedFilters, competition: initialSelectedFilters.competition })}
            value={selectedFilters.competition}
            multiple
          />
          <Button
            className={styles.clearButton}
            onClick={() => setSelectedFilters(initialSelectedFilters)}
            rounded
            color="green"
          >
            {t('filters.clean_filters')}
          </Button>
          <div className={styles.group_by}>
            <span>{t('slider_menu.group_by')}</span>
            <Menu
              title={t('slider_menu.criterion')}
              rounded
              allowEmpty
              placeholder={t('slider_menu.criterion')}
              className={styles.menu}
              options={criteriaOptions.map(item => ({
                label: t(`criteriaOptions.${item.value}`),
                value: item.value,
              }))}
              value={groupBy}
              onClear={() => setGroupBy(null)}
              setValue={value => setGroupBy(value)}
            />
          </div>
        </div>
        <div className={styles.overview}>
          <span className={styles.overview_header}>{t('club.box24')}</span>
          <Table
            className={styles.performance_table}
            flat
            theads={[
              t('slider_menu.seas'),
              t('match.comp'),
              t('athlete_list.box2'),
              //t('match.availability'),
              <ToolTip message={t('match.text13')}>
                <img src={IcoTempo} alt="tempo" />
              </ToolTip>,
              <ToolTip message={t('match.text18')}>
                <img src={IcoJogos} alt="jogos" />
              </ToolTip>,
              <ToolTip message={t('match.text14')}>
                <img src={IcoTitular} alt="titular" />
              </ToolTip>,
              <ToolTip message={t('match.text15')}>
                <img src={IcoBanco} alt="banco" />
              </ToolTip>,
              <ToolTip message={t('match.text9')}>
                <img src={IcoGols} alt="gols" />
              </ToolTip>,
              <ToolTip message={t('match.text10')}>
                <img src={IcoAmarelos} alt="amarelos" />
              </ToolTip>,
              <ToolTip message={t('match.text19')}>
                <img src={IcoAmareloVermelho} alt="amarelo vermelho" />
              </ToolTip>,
              <ToolTip message={t('match.text12')}>
                <img src={IcoVermelhos} alt="vermelho" />
              </ToolTip>,
            ]}
            data={filterData(data)}
          />
        </div>
      </div>
    );
  } else {
    content = (
      <Card bordered className={styles.errorContainer}>
        <h2>{t(athleteHistorical.error)}</h2>
      </Card>
    );
  }

  return (
    <>
      {athleteHistorical.loading ? (
        <div className={styles.loaderContainer}>
          <Spinner />
        </div>
      ) : (
        content
      )}
    </>
  );
}

export default Performance;
