import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from 'store/ducks/user';
import styles from './ProfileIcon.module.scss';
import { Avatar } from 'components/template';
import { FaCaretDown } from 'react-icons/fa';
import ProfileCard from './ProfileCard/ProfileCard';
import { Creators as layoutCreators } from 'store/ducks/layout';

export default function ProfileIcon() {
  const dispatch = useDispatch();
  const layout = useSelector(state => state.layout);

  const user = useSelector(state => state.user.data);
  const [isProfileCardVisible, setProfileCardVisibility] = useState(false);

  function logout() {
    dispatch(Creators.logout());
  }

  const navDrawerToggler = () => {
    dispatch(layoutCreators.hideNavDrawer());
  };

  return (
    <div className={styles.profileIcon}>
      <Avatar
        onClick={() => setProfileCardVisibility(true)}
        className={styles.avatar}
        img={user.img}
        name={user.name}
      />
      <FaCaretDown
        onClick={() => {
          setProfileCardVisibility(true);
          navDrawerToggler(false);
        }}
      />
      <ProfileCard
        user={user}
        isVisible={isProfileCardVisible && layout.isNavDrawerVisible === false}
        hide={() => setProfileCardVisibility(false)}
        logout={logout}
      />
    </div>
  );
}
