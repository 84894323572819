import React from 'react';
import PropTypes from 'prop-types';
import styles from './InfoCard.module.scss';
import { AthleteAvatar } from 'components/template';
import { useTranslation } from 'react-i18next';
import DesiredAthleteIcon from 'assets/ico/desired_athlete_icon.svg';
import { Link } from 'react-router-dom';

export default function InfoCard({ className, data, isDesired }) {
  const { t } = useTranslation();

  const infoCardClasses = [styles.container];
  if (className) infoCardClasses.push(className);

  if (isDesired) {
    return (
      <Link to={`/desired-athletes/${data.id}`} className={styles.linkDesired}>
        <div className={infoCardClasses.join(' ')}>
          <aside className={styles.left}>
            <img className={styles.avatar} src={DesiredAthleteIcon} draggable="false" alt='Desired' />
          </aside>
          <div className={styles.right}>
            <h4 className={styles.name}>{t(`positionOptionsRaw.${data?.position}`)}</h4>
            <div className={styles.info}>
              {data.nationalities?.slice(0,3).map(n => (
                <img
                  key={`n-${n.code}`}
                  src={n.flag}
                  alt={n.name}
                  draggable="false"
                />
              ))}
              {data.nationalities && data.nationalities.length > 3 && <span>+{data.nationalities.length -3}</span>}
              <span> 
                {t(data.female ? 'radioAdvancedSearch.female' : 'radioAdvancedSearch.male')} | 
                {' '}{t(data.amateur ? 'listTranslation.Amateur' : 'compe.text26')} | 
                {' '}{t('slider_menu.in')} {data.min_age} {t('slider_menu.e')} {data.max_age} {t('athlete_card.idade')}
              </span>
            </div>
          </div>
        </div>
      </Link>
    );
  } 
  
  if (data.id) {
    return (
      <div className={infoCardClasses.join(' ')}>
        <div className={styles.left}>
          <AthleteAvatar className={styles.avatar} img={data.photo} name={data.fullname} />
        </div>
        <div className={styles.right}>
          <span className={styles.name}>{data.nickname}</span>
          <span className={styles.completeName}>{data.fullname}</span>
          <div className={styles.info}>
            {data.athlete?.nationalities && data.athlete?.nationalities.length > 0 && data.athlete.nationalities[0] && (
              <>
                <img
                  src={data.athlete?.nationalities[0].flag}
                  alt={data.athlete?.nationalities[0]?.name ?? ''}
                  draggable="false"
                />
              </>
            )}
            <span className={styles.age}>
              {data.age} {t('athlete_card.idade')}
            </span>
            <span className={styles.position}>| {t(`positionOptionsRaw.${data.position}`)}</span>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className={styles.noAthleteSelected}>Nenhum atleta selecionado</div>
  );
}

InfoCard.defaultProps = {
  className: '',
  data: {},
};

InfoCard.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
};
