import axios from 'api';
import Endpoints from 'endpoints';

export const actionTypes = {
  GET_LAST_SEEN_ATHLETES: 'last_seen_athletes/GET',
  GET_LAST_SEEN_ATHLETES_SUCCESS: 'last_seen_athletes/GET_SUCCESS',
  GET_LAST_SEEN_ATHLETES_ERROR: 'last_seen_athletes/GET_ERROR',
  GET_LATEST_MOVES: 'latest_moves/GET',
  GET_LATEST_MOVES_SUCCESS: 'latest_moves/GET_SUCCESS',
  GET_LATEST_MOVES_ERROR: 'latest_moves/GET_ERROR',
  GET_SYSTEM_DETAILS: 'system_details/GET',
  GET_SYSTEM_DETAILS_SUCCESS: 'system_details/GET_SUCCESS',
  GET_SYSTEM_DETAILS_ERROR: 'system_details/GET_ERROR',
  GET_BANNERS: 'banners/GET',
  GET_BANNERS_SUCCESS: 'banners/GET_SUCCESS',
  GET_BANNERS_ERROR: 'banners/GET_ERROR',
  GET_TRANSFER_WINDOWS: 'transfer_windows/GET',
  GET_TRANSFER_WINDOWS_SUCCESS: 'transfer_windows/GET_SUCCESS',
  GET_TRANSFER_WINDOWS_ERROR: 'transfer_windows/GET_ERROR',
};

const initialState = {
  lastSeenAthletes: {
    loading: false,
    error: null,
    data: null,
  },
  latestMoves: {
    loading: false,
    error: null,
    data: null,
    bannerData: null,
  },
  systemDetails: {
    loading: false,
    error: null,
    data: null,
  },
  banners: {
    loading: false,
    error: null,
    data: null,
  },
  transferWindows: {
    loading: false,
    error: null,
    data: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_LAST_SEEN_ATHLETES:
      return {
        ...state,
        lastSeenAthletes: {
          ...initialState.lastSeenAthletes,
          loading: true,
        },
      };
    case actionTypes.GET_LAST_SEEN_ATHLETES_SUCCESS:
      return {
        ...state,
        lastSeenAthletes: {
          ...initialState.lastSeenAthletes,
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case actionTypes.GET_LAST_SEEN_ATHLETES_ERROR:
      return {
        ...state,
        lastSeenAthletes: {
          ...initialState.lastSeenAthletes,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_LATEST_MOVES:
      return {
        ...state,
        latestMoves: {
          ...initialState.latestMoves,
          loading: true,
        },
      };
    case actionTypes.GET_LATEST_MOVES_SUCCESS:
      return {
        ...state,
        latestMoves: {
          ...initialState.latestMoves,
          loading: false,
          data: action.payload.data,
          bannerData: action.payload.bannerData,
          error: null,
        },
      };
    case actionTypes.GET_LATEST_MOVES_ERROR:
      return {
        ...state,
        latestMoves: {
          ...initialState.latestMoves,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_SYSTEM_DETAILS:
      return {
        ...state,
        systemDetails: {
          ...initialState.systemDetails,
          loading: true,
        },
      };
    case actionTypes.GET_SYSTEM_DETAILS_SUCCESS:
      return {
        ...state,
        systemDetails: {
          ...initialState.systemDetails,
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case actionTypes.GET_SYSTEM_DETAILS_ERROR:
      return {
        ...state,
        systemDetails: {
          ...initialState.systemDetails,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_BANNERS:
      return {
        ...state,
        banners: {
          ...initialState.systemDetails,
          loading: true,
        },
      };
    case actionTypes.GET_BANNERS_SUCCESS:
      return {
        ...state,
        banners: {
          ...initialState.systemDetails,
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case actionTypes.GET_BANNERS_ERROR:
      return {
        ...state,
        banners: {
          ...initialState.systemDetails,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_TRANSFER_WINDOWS:
      return {
        ...state,
        transferWindows: {
          ...initialState.transferWindows,
          loading: true,
        },
      };
    case actionTypes.GET_TRANSFER_WINDOWS_SUCCESS:
      return {
        ...state,
        transferWindows: {
          ...initialState.transferWindows,
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case actionTypes.GET_TRANSFER_WINDOWS_ERROR:
      return {
        ...state,
        transferWindows: {
          ...initialState.transferWindows,
          error: action.payload.error,
        },
      };

    default:
      return state;
  }
}

export const getLastSeenAthletes = () => async dispatch => {
  dispatch({ type: actionTypes.GET_LAST_SEEN_ATHLETES });

  const url = Endpoints.athleteVisits;

  try {
    const { data } = await axios.get(`${url}?limit=10`);

    dispatch({
      type: actionTypes.GET_LAST_SEEN_ATHLETES_SUCCESS,
      payload: { data: data.results },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_LAST_SEEN_ATHLETES_ERROR,
      payload: { error: 'erros.text22' },
    });
  }
};

export const getLatestMoves = (filter, hasCompetitionPermission) => async dispatch => {
  dispatch({ type: actionTypes.GET_LATEST_MOVES });

  const url = Endpoints.activities;

  try {
    const { data } = await axios.get(`${url}?type=${filter}`);
    let bannerData;
    if (hasCompetitionPermission) {
      bannerData = await axios.get(`${Endpoints.competitions}?highlight=true&o=-highlight_date&limit=1`);
    }

    dispatch({
      type: actionTypes.GET_LATEST_MOVES_SUCCESS,
      payload: {
        data: data.results,
        bannerData: bannerData?.data?.results?.length ? bannerData.data.results[0] : null,
      },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_LATEST_MOVES_ERROR,
      payload: { error: 'erros.text23' },
    });
  }
};

export const getSystemDetails = () => async dispatch => {
  dispatch({ type: actionTypes.GET_SYSTEM_DETAILS });

  const url = Endpoints.scripts;

  try {
    const { data } = await axios.get(`${url}`);

    dispatch({
      type: actionTypes.GET_SYSTEM_DETAILS_SUCCESS,
      payload: { data: data.results },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_SYSTEM_DETAILS_ERROR,
      payload: { error: 'erros.text24' },
    });
  }
};

export const getBanners = () => async dispatch => {
  dispatch({ type: actionTypes.GET_BANNERS });

  try {
    const { data } = await axios.get(Endpoints.banners);

    dispatch({
      type: actionTypes.GET_BANNERS_SUCCESS,
      payload: { data: data.results },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_BANNERS_ERROR,
      payload: { error: 'erros.text1' },
    });
  }
};

export const getTransferWindows = (monitoring, type) => async dispatch => {
  dispatch({ type: actionTypes.GET_TRANSFER_WINDOWS });

  const url = Endpoints.transferWindows;
  let filter = '';

  if(monitoring){
    filter = '&monitoring=true';
  } else {
    filter = '&is_open=true';

  }

  try {
    const { data } = await axios.get(`${url}/recent?limit=12`+filter);
    
    dispatch({
      type: actionTypes.GET_TRANSFER_WINDOWS_SUCCESS,
      payload: { data: data.results },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_TRANSFER_WINDOWS_ERROR,
      payload: { error: 'erros.text25' },
    });
  }
};
