import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from 'store/ducks/athleteReport';
import { getDesiredTransferMarket, resetViewedTransfermarket } from 'store/ducks/transferAthletes';
import { Card, Spinner } from 'components/template';
import styles from './MoreInfo.module.scss';
import SideInfo from './SideInfo/SideInfo';
import { useTranslation } from 'react-i18next';
import Annoucements from './Announcements/Annoucements';
import { withRouter, useParams } from 'react-router-dom';
import TopBar from './TopBar/TopBar';
import { IoWarning } from "react-icons/io5";
import AthleteList from './AthleteList/AthleteList';
import { MdInfo } from 'react-icons/md';
import Variables from 'variables';

function DesiredAthletesMoreInfo({ history }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const params = useParams();
  const transferMarketId = params.id;
  const transferAthletes = useSelector(state => state.transferAthletes);
  const transferMarket = transferAthletes.viewedTransferMarket;
  const transferMarketAthleteId = transferMarket?.athlete?.id;

  useEffect(() => {
    dispatch(getDesiredTransferMarket(transferMarketId));
  }, [dispatch, transferMarketId, getDesiredTransferMarket]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(Creators.reset());
      dispatch(resetViewedTransfermarket());
    };
  }, [transferMarketAthleteId]); // eslint-disable-line react-hooks/exhaustive-deps

  let content = '';
  if (transferAthletes.loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (transferAthletes.error) {
    content = (
      <Card softShadow bordered className={styles.errorContainer}>
        <span>{t(transferAthletes.error)}</span>
      </Card>
    );
  } else if (transferMarket) {
    const allAthletes = [];
    (transferMarket?.athletes ?? []).forEach(athlete => {
      (athlete?.offers ?? []).forEach(offer => {
        allAthletes.push({ 
          ...athlete, 
          offers: [{...offer}]}
        );
      });
    });
    content = (
      <>
        <TopBar transferMarket={transferMarket} setReportAthletes={() => { }} border />
        <Card softShadow className={styles.container} height="46rem">
          <SideInfo className={styles.content} data={transferMarket} setIsOpen={() => {}} isOpen={false} />
          <div className={styles.content}>
            <Annoucements data={transferMarket} athleteId={transferMarket.id} history={history} />
            { 
              allAthletes.length > 0 ? <AthleteList transferMarket={transferMarket} allAthletes={allAthletes} /> : 
              (transferMarket.is_ad_owner ? (
                <div className={styles.noAthletesResult} style={{ backgroundColor: Variables.lightPurple2 }}>
                  <MdInfo />
                  {t('transferMarket.textError1')}
                </div> 
              ) : (
                <div className={styles.noAthletesResult}>
                  <IoWarning />
                  {t('transferMarket.textError2')}
                </div> 
              ))
            }            
          </div>
        </Card>
      </>
    );
  }

  return <>{content}</>;
}

export default withRouter(DesiredAthletesMoreInfo);
