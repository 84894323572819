import styled from 'styled-components';
import { Card as DefaultCard } from 'components/template';
import Variables from 'variables'

export const Card = styled(DefaultCard)`
  display: flex;
  align-items: center;
  flex-direction: column;  
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;  
  background-color: ${Variables.white};
  width: 100%;
  margin-left:30px;
`;
