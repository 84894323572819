import React, { useEffect, useState, useRef } from 'react';
import styles from './Chat.module.scss';
import Header from './Header/Header';
import ChatMessage from './ChatMessage/ChatMessage';
import Footer from './Footer/Footer';
import { Spinner } from 'components/template';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { HiTrash } from 'react-icons/hi';
import { Creators as layoutActions } from 'store/ducks/layout';
import { useDispatch } from 'react-redux';

let _currentScrollHeight = 0;
let _currentPageLength = 0;
let _isLoadedNextPage = false;
export default function Chat({
  repository,
  messages,
  messageGroups,
  submitMessage,
  deleteMessages,
  nextMessagesPage,
  selectedChat,
  handleFilterChange,
  user,
  chatLoading,
  error = '',
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedIds, setSelectedIds] = useState([]);
  const [deleting, setDeleting] = useState(false);

  const scrollRef = useRef();

  useEffect(() => {
    if (scrollRef.current && messages) {
      if (messages.length > 20 && _isLoadedNextPage) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight - _currentScrollHeight;
        _isLoadedNextPage = false;
      } else {
        _currentPageLength = 0;
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight + 100;
      }
      _currentScrollHeight = scrollRef.current.scrollHeight;
    }
  }, [messageGroups]);  // eslint-disable-line react-hooks/exhaustive-deps

  const transferMarketIsActive = selectedChat?.transfer_market?.is_active ?? false;

  const deleteClick = async () => {
    setDeleting(true);
    const data = await deleteMessages(selectedIds);
    if (data?.error) {
      dispatch(
        layoutActions.showToast({
          content: t('erros.text8'),
          duration: 3000,
        })
      );
    } else {
      setSelectedIds([]);
    }
    setDeleting(false);
  }

  const onCheckClick = (checked, id) => {
    if (checked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      const index = selectedIds.indexOf(id);
      if (index > -1) {
        selectedIds.splice(index, 1);
        setSelectedIds([...selectedIds]);
      }
    }
  }

  const renderMessages = (group, index) => {
    const filteredMessages = group.messages.filter(message => message.orgchat === selectedChat.id);

    return (
      <div key={index} className={styles.dateWrapper}>
        <div className={styles.date}>{group.date}</div>
        <div className={styles.messages}>
          {filteredMessages.map(filteredMessage => {
            const hour = moment(filteredMessage.created_at).format('HH:mm');
            return (
              <ChatMessage
                key={filteredMessage.id}
                id={filteredMessage.id}
                user={user}
                message={filteredMessage}
                hour={hour}
                checked={selectedIds.includes(filteredMessage.id)}
                onCheckClick={onCheckClick}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const deleteButtonClasses = [styles.deleteButton];
  if (deleting) {
    deleteButtonClasses.push(styles.deleting);
  }

  return (
    <div className={styles.container}>
      <Header
        repository={repository}
        selectedChat={selectedChat}
        handleFilterChange={handleFilterChange}
        user={user}
      />
      <div className={styles.messageWrapper}>
        {chatLoading && (<Spinner width="40" height="40" className={styles.spinner} />)}
        {selectedIds.length > 0 && <HiTrash className={deleteButtonClasses.join(' ')} onClick={deleteClick} />}
        <div className={styles.content} ref={scrollRef} onScroll={() => {
          const scrollTop = scrollRef?.current?.scrollTop ?? 100;
          if (scrollTop < 100) {
            if (_currentPageLength !== messages.length && messages.length >= 20) {
              _currentPageLength = messages.length;
              _isLoadedNextPage = true;
              nextMessagesPage();
            }
          }
        }}>
          {!chatLoading && error != null && (
            <div className={styles.noMessages}>
              <span>{error}</span>
            </div>
          )}
          {!chatLoading && (messages == null || messages.length === 0) && (
            <div className={styles.noMessages}>
              <span>{t('negotiations.noMessages')}</span>
            </div>
          )}
          {repository === 'clubes'
            ? messageGroups
              ?.filter(group => group.messages.some(message => message.orgchat === selectedChat.id))
              .map(renderMessages)
            : messageGroups.map((group, index) => {
              return (
                <div key={index} className={styles.dateWrapper}>
                  <div className={styles.date}>{group.date}</div>
                  <div className={styles.messages}>
                    {group.messages.map(message => {
                      const hour = moment(message.created_at).format('HH:mm');
                      return (
                        <ChatMessage
                          key={message.id}
                          id={message.id}
                          user={user}
                          message={message}
                          hour={hour}
                          checked={selectedIds.includes(message.id)}
                          onCheckClick={onCheckClick}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {(transferMarketIsActive || repository !== 'negociacoes') && <Footer submitMessage={submitMessage} />}
    </div>
  );
}
