import React, { useState, useEffect } from 'react';

import { FaCheckCircle, FaListUl, FaEye } from 'react-icons/fa';
import styles from './MonitoringButton.module.scss';
import MonitoringDialog from './MonitoringDialog';
import { getList, createList, deleteList, addMonitor, deleteMonitor, editList } from 'store/ducks/monitoringV2';
import { Creators as athleteActions} from 'store/ducks/athlete';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import ErrorMessages from 'shared/ErrorMessages';
import { Creators as layoutActions } from 'store/ducks/layout';
import { Creators } from 'store/ducks/projects';

function MonitoringButton({ filled, athlete, className }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isMonitoring = (athlete?.lists ?? []).length > 0;
  const athleteId = athlete?.id;
  const loggedUser = useSelector(state => state.user.data);
  const list = useSelector(state => state.monitoringV2);
  const projects = useSelector(state => state.athlete.projects);
  const edit = useSelector(state => state.monitoringV2.edit);
  const workflows = useSelector(state => state.project);
  const [internalIsMonitoring, setInternalIsMonitoring] = useState(isMonitoring);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setloading] = useState(false);

  if (list.error === ErrorMessages.excededPlanLimit) {
    dispatch(layoutActions.showExcededPlanLimit());
  }

  useEffect(() => {
    if ((list?.data ?? []).length === 0) {
      dispatch(getList());
    }    
  }, [dispatch, athleteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (edit) {
      dispatch(getList());
    }
  }, [edit])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (list.loadingIds.some(id => id === athleteId)) {
      setloading(true);
    } else if (loading) {
      setloading(false);
      const isNewMonitoring =
        list.data.find(listData => {
          const isNewMonitoring = listData.athletes.find(a => a.athlete.id === athleteId) != null;
          return isNewMonitoring;
        }) != null;
      setInternalIsMonitoring(isNewMonitoring);
    }
  }, [list.loadingIds, athleteId]); // eslint-disable-line react-hooks/exhaustive-deps

  const filledClassName = filled ? styles.filled : '';
  const monitoringClasses = [styles.monitoring, filledClassName];
  if (className) {
    monitoringClasses.push(className);
  } else {
    monitoringClasses.push(styles.monitoringButtonDiv);
  }

  function handleClick() {
    setIsOpen(!isOpen);
  }

  const handleMonitor = async () => {
    if (!loading && !list.loading) {
      setloading(true);

      if (internalIsMonitoring) {
        const tasks = [];
        for (const listId of athlete.lists) {
          tasks.push(deleteMonitor(athlete.id, listId));
        }        
        const results = await Promise.all(tasks);
        const deleted = !results.find(changed => !changed);
        if (deleted) {
          setInternalIsMonitoring(!internalIsMonitoring);
          athlete.lists = [];
        }
      } else {
        const defaultList = list.data.find(l => l.name === '_DEFAULT_');
        const added = defaultList && await addMonitor(athleteId, defaultList.id);
        if (added) {
          setInternalIsMonitoring(!internalIsMonitoring);
          athlete.lists.push(defaultList.id);
        }
      }

      setloading(false);
    }
  }

  function dispatchGetProjects() {
    dispatch(athleteActions.getProjects(athleteId, {o: 'name'}));
  }

  function dispatchGetList() {
    dispatch(getList({'load_athlete_count': 'true'}));
  }

  function dispatchGetWorkflows(id){
    dispatch(Creators.getProject(id));
  }

  async function dispatchCreateWorkflows(projectId, frame, athlete) {    
    try {
      const result = await Creators.createWorkflow(projectId, frame, athlete);
        
      if (result.error) {
        console.error(result.error);
      } else {
        console.log(t('dialog_content.createSuccess'), result.data);
      }
    } catch (error) {
      console.error(t('dialog_content.unexpectedError'), error);
    }
  }

  async function dispatchUpdateWorkflows(projectId, workflowId, frame, order = 0) {
    try {
      const result = await Creators.updateFrameWorkflow(projectId, workflowId, frame, order);
        
      if (result.error) {
        console.error(result.error);
      } else {
        console.log(t('dialog_content.createSuccess'), result.data);
      }
    } catch (error) {
      console.error(t('dialog_content.unexpectedError'), error);
    }
  }

  async function dispatchDeleteWorkflow(projectId, workflowId) {
    try {
      const result = await Creators.deleteWorkflow(projectId, workflowId);
        
      if (result.error) {
        console.error(result.error);
      } else {
        console.log(t('dialog_content.createSuccess'), result.data);
      }
    } catch (error) {
      console.error(t('dialog_content.unexpectedError'), error);
    }
  }

  function dispatchCreateList(newList) {
    dispatch(createList(loggedUser.id, athlete, newList));
  }

  function dispatchDeleteList(listId) {
    dispatch(deleteList(listId));
    athlete.lists = athlete.lists.filter(list => list !== listId);
  }

  function dispatchEditList(listId, newListName) {
    dispatch(editList(listId, newListName));
  }

  const onAthleteListChange = () => {
    setInternalIsMonitoring(athlete.lists.length > 0);
  };

  const monitoring = (
    <>
      <div onClick={() => handleMonitor()} className={`${styles.monitoringStatus} ${filledClassName}`}>
        {loading ? (
          <Loader type="TailSpin" color="#3D6B00" className={`${styles.smallLoader} ${styles.monitoringSpinner}`} />
        ) : (
          <FaCheckCircle />
        )}
        <span>{t('proj.box18')}</span>
      </div>
      <button onClick={() => handleClick()} className={filledClassName}>
        <FaListUl />
      </button>
    </>
  );

  const monitor = (
    <>
      <div
        onClick={() => handleMonitor()}
        className={`${styles.monitoringStatus} ${styles.notMonitoring} ${filledClassName}`}
      >
        {loading ? <Loader type="TailSpin" color="#00000099" className={`${styles.smallLoader}`} /> : <FaEye />}
        <span>{t('config.mon')}</span>
      </div>
      <button onClick={() => handleClick()} className={`${styles.notMonitoring} ${filledClassName}`}>
        <FaListUl />
      </button>
    </>
  );

  return (
    <MonitoringDialog
      athlete={athlete}
      list={list}
      addMonitor={addMonitor}
      deleteMonitor={deleteMonitor}
      onAthleteListChange={onAthleteListChange}
      getList={dispatchGetList}
      createList={dispatchCreateList}
      editList={dispatchEditList}
      deleteList={dispatchDeleteList}
      isOpen={isOpen && !loading}
      setIsOpen={setIsOpen}
      projects={projects}
      getProjects={dispatchGetProjects}
      workflows={workflows}
      getWorkflows={dispatchGetWorkflows}
      createWorkflows={dispatchCreateWorkflows}
      updateFrameWorkflows={dispatchUpdateWorkflows}
      deleteWorkflow={dispatchDeleteWorkflow} 
    >
      <div
        className={`${loading ? styles.disabled : ''} ${
          internalIsMonitoring
            ? monitoringClasses.join(' ')
            : `${styles.monitoringButtonDiv} ${styles.notMonitoringButtonDiv} ${filledClassName}`
        }`}
      >
        {internalIsMonitoring ? monitoring : monitor}
      </div>
    </MonitoringDialog>
  );
}

export default MonitoringButton;
