import React from 'react';
import PropTypes from 'prop-types';
import styles from './MinPagination.module.scss';
import { Button } from 'components/template';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

export default function MinPagination({
    options,
    length,
    count,
    hasNext,
    hasPrevious,
    pagination,
    setPagination,
    resourceDisplayName,
    className,
}) {

    const paginationClasses = [styles.container];
    if (className) paginationClasses.push(className)

    const navigate = (to) => {

        let newOffset;

        switch (to) {
            case 'next':
                newOffset = pagination.offset + pagination.limit;
                break;
            case 'previous':
                newOffset = pagination.offset - pagination.limit;
                break;
            default:
                return;
        }

        if (newOffset < 0) {
            newOffset = 0;
        } else if (newOffset > count) {
            newOffset = count - pagination.limit;
        }

        setPagination({ ...pagination, offset: newOffset });

    };

    //const integerFormat = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return (
        <>
            <div className={styles.spacer} />
            <div className={[...paginationClasses, styles.container].join(' ')}>
                <div className={styles.content}>
                    <div className={[...paginationClasses, styles.pagination].join(' ')}>
                        {//<span><strong>{length === 0 ? 0 : pagination.offset + 1}</strong> - <strong>{pagination.offset + length}</strong> de <strong>{integerFormat(count)}</strong> {resourceDisplayName.toLowerCase()}</span>
                        }
                        <div className={styles.divButtons}>
                            <Button disabled={!hasPrevious} onClick={() => navigate('previous')} noShadow ><FiChevronLeft /></Button>
                            <Button disabled={!hasNext} onClick={() => navigate('next')} noShadow ><FiChevronRight /></Button>
                        </div>
                    </div>
                </div>
            </div>
        </>);
}

MinPagination.defaultProps = {
    hasNext: true,
    hasPrevious: true,
    resourceDisplayName: 'Clubes'
};

MinPagination.propTypes = {
    length: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    hasPrevious: PropTypes.bool,
    hasNext: PropTypes.bool,
    pagination: PropTypes.object.isRequired,
    setPagination: PropTypes.func.isRequired,
    resourceDisplayName: PropTypes.string
};