import React from 'react';
import styles from './Annoucements.module.scss';
import { useTranslation } from 'react-i18next';

export default function Annoucements({ data }) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h1>{t('transferMarket.clubAnnounce')}</h1>
      <div className={styles.card}>
        <div className={styles.rowInfo}>
          <div className={styles.info}>
            <div>
              <span>{t('transferMarket.maxValue')}</span>
              <span>
                {parseFloat(data?.purchase_value)?.toLocaleString('pt-BR')} €
              </span>
            </div>
            <div>
              <span>{t('transferMarket.headerTransferTypeTitle')}</span>
              <span>{t(`desiredNegotiationOptions.${data?.negotiation_type}`)}</span>
            </div>
            <div>
              <span>{t('transferMarket.maxSalary')}</span>
              <span>
                {parseFloat(data?.annual_salary ?? data?.monthly_salary)?.toLocaleString('pt-BR')} €
                {' '}{t(`transferMarket.${data?.annual_salary ? 'perYear' : 'perMonth'}`)}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.lowCard}>
          <div>
            <span className={styles.wordBreak}>
              {t('transferMarket.clubObservations')}
            </span>
          </div>
          <div>
            <span className={styles.wordBreak}>
              {data?.comments}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
