import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import styles from './NavigationItem.module.scss';

function NavigationItem({ notImplemented, className, label, link, onClick, params, amount, location, history, filled, isActive }) {
  const active = isActive ?? (location.pathname === link);
  const navigationItemClasses = [styles.navigationItem];
  if (active) navigationItemClasses.push(styles.active);
  if (className) navigationItemClasses.push(className)

  const isNotImplemented = notImplemented || false;
  if (isNotImplemented) navigationItemClasses.push(styles.notimplemented);

  const filledClassName = filled ? styles.filledItems : '';
  const historyData = {
    pathname: link,
    state: params,
  }

  return (
    <div onClick={() => {
      if (onClick != null) {
        onClick();
      } else if (link != null) {
        history.push(historyData);
      }
    }} className={[...navigationItemClasses, filledClassName].join(' ')}>
      <p>
        {label} {amount ? <span>({amount})</span> : null}
      </p>
    </div>
  );
}

NavigationItem.defaultProps = {
  notImplemented: false,
  amount: null,
};

NavigationItem.propTypes = {
  notImplemented: PropTypes.bool,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  location: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(NavigationItem);
