import styled from 'styled-components';
import Variables from 'variables.js';

export const Container = styled.div`
    background-color: ${Variables.white};
`;

export const Content = styled.div`
    width: 100%;
    height: auto;
`;

export const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-height: calc(100vh - 60px);
`;
