import styled from 'styled-components';
import { Card as DefaultCard } from 'components/template'
import Variables from 'variables';

const usersDiv = [`
  margin-bottom: 15px;
  width: 100%;
`];

export const Card = styled(DefaultCard)`
  display: flex;
  align-items: center;
  padding-right: 30px;
  padding-left: 30px;
  flex-direction: column;  
`;

export const Owner = styled.div(usersDiv);

export const OwnerContent = styled.div` 
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    text-align: center;
    width: 172px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid ${Variables.lightPurple1};
    color: ${Variables.white};
    background-color: ${Variables.lightPurple1};
    font-size: 14px;
    font-weight: 700;
    padding: 5px;
    cursor: pointer;

    svg {
      position: relative;
      left: 10px;
      top: 1px;
    }
  }
`;

export const Members = styled.div(usersDiv);

export const Table = styled.table`
  border-spacing: 0;
  width: 100%;
`;

export const Th = styled.th` 
  text-align: left;
  font-size: 0.9em;
  font-weight: 600;
  padding: 20px;
  border-top: 1px solid ${Variables.lightBorder};
  border-bottom: 1px solid ${Variables.lightBorder};

  &:first-child {
    border-left: 1px solid ${Variables.lightBorder};
    border-radius: 8px 0 0 0;
  }

  &:last-child {
    border-right: 1px solid ${Variables.lightBorder};
    border-left: none;
    border-radius: 0 8px 0 0;
  }

  &:first-of-type {
    text-align: left;
  }
`;

export const Td = styled.td` 
  font-size: 0.9em;
  padding: 5px 20px;
  text-align: left;
  border-bottom: 1px solid ${Variables.lightBorder};

  &:first-child {
    border-left: 1px solid ${Variables.lightBorder};
  }

  &:last-child {
    border-right: 1px solid ${Variables.lightBorder};
  }

  &:first-of-type {
    text-align: left;
  }
`;

export const Tr = styled.tr` 

  }
`;

export const TitleU = styled.div`
  display: flex;
  flex-direction: column;  
  background-color: ${Variables.white};
  width: 100%;
  margin-bottom:15px;
`;