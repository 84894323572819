import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// scss
import styles from './TeamChatHeader.module.scss';

// components
import { Avatar, IconButton, Spinner } from 'components/template';

// assets
import ClubsLogo from 'assets/ico/menu/clubs.svg';

export default function TeamChatHeader({ selectedChat }) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.container_row}>
        {selectedChat.team != null ? (
          <>
            <div className={styles.athleteCard}>
              <div className={styles.left}>
                <div className={styles.clubContainer}>
                  <div className={styles.athleteImg}>
                    <Avatar img={selectedChat.team?.emblem} name={selectedChat.team?.name} noBorder />
                  </div>
                </div>
              </div>
              <div className={styles.center}>
                <span className={styles.name}>{selectedChat.team?.name}</span>
                <span className={styles.completeName}>{selectedChat.team?.fullname}</span>
                <div className={styles.info}>
                  {selectedChat.team?.country?.flag && (
                    <img
                      src={selectedChat.team?.country?.flag}
                      alt={selectedChat.team?.country?.name ?? ''}
                      draggable="false"
                    />
                  )}
                  <span className={styles.address}>
                    {`${selectedChat.team?.country?.name} ${selectedChat.team?.country?.name &&
                      selectedChat.team?.state &&
                      '| '} ${selectedChat.team?.state}`}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <IconButton
                className={styles.view_club_button}
                text={t('chat.view_club_page')}
                icon={<img src={ClubsLogo} alt="club" />}
                onClick={() => history.push(`/clube/${selectedChat.team?.id}`)}
              />
            </div>
          </>
        ) : (
          <Spinner width="40" height="40" className={styles.spinner} />
        )}
      </div>
    </div>
  );
}
