import styled from 'styled-components';
import Variables from 'variables';
import React from 'react';

const _monthsDescriptions = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export default function BadgeDate({ fontSize, date }) {

  const BADGE_DATE = styled.span` 
    background-color: ${Variables.lightPurple3};
    border-radius: 8px;
    color: ${Variables.lightPurple1};
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    width: 350px;
    text-align: center;
    font-weight: 600;
    font-size: ${props => props.fontSize ? props.fontSize : 'normal'};
  `;

  if (typeof date === "string") {
    date = new Date(`${date}T00:00:00`);
  }
  if (date instanceof Date) {
    let day = `0${date.getDate()}`.slice(-2);
    let month = _monthsDescriptions[date.getMonth()];
    let year = date.getFullYear();
    date = `${day} de ${month} de ${year}`;
  }
  return <BADGE_DATE fontSize={fontSize}>
    {date}
  </BADGE_DATE>
}