import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Switch, Route, Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from 'store/ducks/athlete';
import { Spinner, Card, Dialog, Input } from 'components/template';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import styles from './Athlete.module.scss';
import { useTranslation } from 'react-i18next';
import TopBar from './TopBar/TopBar';
import Overview from './Overview/Overview';
import Evaluations from './Evaluations/Evaluations';
import Performance from './Performance/Performance';
import Career from './Career/Career';
import Matches from './Matches/Matches';
import DeletableNotes from './DeletableNotes';
import ReportsPRO from './ReportsPRO/ReportsPRO';
import { IoWarning, IoClose } from 'react-icons/io5';
import FooterBar from 'components/FooterMessage/FooterMessage';
import { FaChevronRight, FaSearch } from 'react-icons/fa';
import Variables from 'variables';
import AthleteCardView from 'components/AthleteCardView';
import { debounce } from 'shared/utility';
import axios from 'api';
import Endpoints from 'endpoints';
import { LuReply } from 'react-icons/lu';
import { useHistory } from 'react-router-dom';

function Athlete({ history }) {
  const history2 = useHistory();
  const { t } = useTranslation();

  const initialModalState = {
    isOpenMigration: false,
  };

  const dispatch = useDispatch();
  const athlete = useSelector(state => state.athlete.athleteData);
  const user = useSelector(state => state.user.data);
  const migration = useSelector(state => state.athlete.athleteMigration);
  const migrationSuccess = useSelector(state => state.athlete.migrationSuccess);
  const showMigrationSuccess = localStorage.getItem('migrationSuccess') === 'true';
  const { id } = useParams();
  const athleteId = id;
  const baseURL = `/atletas/${athleteId}`;
  const [isAthleteUpdated, setIsAthleteUpdated] = useState(false);
  const [showIsRerited, setShowIsRerited] = useState(true);
  const [modalState, setModalState] = useState(initialModalState);
  const [errorMessage, setErrorMessage] = useState('');
  const [footlinkAtlhete, setFootlinkAthlete] = useState(null);
  const [loadingFootlinkAthlete, setLoadingFootlinkAthlete] = useState(false);
  const [etapa, setEtapa] = useState(1);
  const [noteCount, setNoteCount] = useState(0);
  const [evaluationCount, setEvaluationCount] = useState(0);

  useEffect(() => {
    let timeoutId;

    if (athlete?.data?.id) {
      timeoutId = setTimeout(() => {
        localStorage.removeItem('migrationSuccess');
      }, 5000);

      if (athlete?.data.details_count?.athlete_notes) {
        setNoteCount(athlete?.data.details_count?.athlete_notes);
      }

      if (athlete?.data.details_count?.athlete_evaluations) {
        setEvaluationCount(athlete?.data.details_count?.athlete_evaluations);
      }
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [athlete]);

  function mapAthleteToItem(athlete) {
    return {
      id: athlete.id,
      fullname: athlete.fullname,
      nickname: athlete.nickname,
      photo: athlete.photo,
      position: athlete.position1 || athlete.position2,
      age: athlete.age,
      nationalities: athlete.nationalities,
      current_team: athlete.current_team,
      custom_athlete: athlete.custom_athlete,
    };
  }

  function extractAthleteId(url) {
    const regex = /\/atletas\/(\d+)$/;
    const match = url.match(regex);

    if (!match) {
      throw new Error(t('athlete_migration.message2'));
    } else if (match[1] === athlete.data.id.toString()) {
      throw new Error(t('athlete_migration.message3'));
    }

    return match[1];
  }

  async function handleSearch(value) {
    try {
      setErrorMessage('');
      setLoadingFootlinkAthlete(true);
      const { data } = await axios.get(`${Endpoints.athletes}/${extractAthleteId(value)}`);
      if (data) {
        if (data.custom_athlete) {
          throw new Error(t('athlete_migration.message4'));
        }
      }
      setFootlinkAthlete(mapAthleteToItem(data));
      setLoadingFootlinkAthlete(false);
    } catch (error) {
      setFootlinkAthlete(null);
      setLoadingFootlinkAthlete(false);
      setErrorMessage(error.message);
    }
  }

  const delayedHandleSearch = debounce(handleSearch);

  if (!(user.plan.busca_de_atletas || user.plan.mercado_de_transferencias || user.plan.analise_de_mercado)) {
    localStorage.setItem('rdf_excededPlanLimit', 'true');
    window.location.href = '/home';
  }

  function onCloseMigration() {
    setModalState({ ...modalState, isOpenMigration: false });
  }

  function onOpenMigration() {
    setEtapa(1);
    setFootlinkAthlete(null);
    setModalState({ ...modalState, isOpenMigration: true });
  }

  function onConfirmMigration() {
    dispatch(Creators.migrateAthleteData({ athleteId: footlinkAtlhete.id, customAthleteId: athlete.data.id }));
  }

  useEffect(() => {
    dispatch(Creators.getAthleteData(athleteId));
    setIsAthleteUpdated(false);
    return () => {
      dispatch(Creators.reset());
    };
  }, [dispatch, athleteId, isAthleteUpdated]);

  useEffect(() => {
    if (migration.loading === false && migrationSuccess) {
      localStorage.setItem('migrationSuccess', true);
      setModalState({ ...modalState, isOpenMigration: false });
      history2.push('/atletas/' + footlinkAtlhete.id);
    }
  }, [migration, migrationSuccess]); //eslint-disable-line

  const items = [
    {
      label: t('club.box24'),
      link: `${baseURL}`,
      component: <Overview athleteId={athleteId} baseURL={baseURL} setIsAthleteUpdated={setIsAthleteUpdated} />,
    },
    {
      label: t('club.box25'),
      link: `${baseURL}/anotacoes`,
      amount: noteCount,
      // amount: athlete?.data?.evaluationsAmount ?? '',
      component: (
        <DeletableNotes
          athleteId={athleteId}
          calcHeight={true}
          inputPosition={'bottom'}
          updateNoteCount={() => setNoteCount(noteCount + 1)}
        />
      ),
    },
    {
      notImplemented: false,
      label: t('overview.text2'),
      link: `${baseURL}/carreira`,
      amount: athlete?.data?.careerAmount ?? '',
      component: <Career athleteId={athleteId} />,
    },
    {
      notImplemented: false,
      label: t('overview.text20'),
      link: `${baseURL}/desempenho`,
      amount: athlete.data.performanceAmount,
      component: <Performance athleteId={athleteId} baseURL={baseURL} />,
    },

    {
      label: t('match.text3'),
      link: `${baseURL}/jogos`,
      amount: athlete?.data?.matchesAmount ?? '',
      component: <Matches athleteId={athleteId} baseURL={baseURL} />,
    },
  ];

  if (user.plan?.evaluations_limit !== 0) {
    items.push({
      label: t('club.box26'),
      link: `${baseURL}/avaliacoes`,
      amount: evaluationCount,
      component: (
        <Evaluations
          athleteId={athleteId}
          baseURL={baseURL}
          setEvaluationCount={val => setEvaluationCount(evaluationCount - val)}
        />
      ),
    });
  }

  items.push({
    label: t('report_pro.title'),
    link: `${baseURL}/relatorios-pro`,
    amount: athlete?.data?.details_count?.athlete_reportspro,
    component: <ReportsPRO athleteId={athleteId} baseURL={baseURL} athlete={athlete} user={user} />,
  });

  const sortedItems = [
    ...items.filter(item => item.label === t('club.box24')), // Mantém o overview primeiro
    ...items.filter(item => item.label !== t('club.box24')).sort((a, b) => a.label.localeCompare(b.label)), // Ordena os outros
  ];

  const routes = sortedItems.map(item => (
    <Route key={item.label} exact path={item.link} render={() => item.component} />
  ));

  let content = null;
  if (athlete.loading) {
    content = (
      <div className={styles.loaderContainer}>
        <Spinner />
      </div>
    );
  } else if (athlete.error) {
    content = (
      <Card className={styles.errorContainer}>
        <h2>{t(athlete.error)}</h2>
      </Card>
    );
  } else {
    content = (
      <>
        <TopBar athlete={athlete.data} setReportAthletes={() => {}} />
        <div className={styles.content}>
          <div className={styles.navigationDiv}>
            <div className={styles.navigationBar}>{!athlete.loading && <NavigationBar items={sortedItems} />}</div>
          </div>
          <Switch>
            {routes}
            <Redirect to={baseURL} />
          </Switch>
          {athlete.data.is_retired && showIsRerited && (
            <div className={styles.is_retired}>
              <IoWarning />
              {t('athlete_card.error.is_retired')}
              <IoClose className={styles.close_is_retired} onClick={() => setShowIsRerited(false)} />
            </div>
          )}
        </div>
        {athlete?.data?.custom_athlete ? (
          <FooterBar
            type={'warning'}
            backgroundColor={'#F2994A'}
            infoText={t('athlete_migration.warning')}
            buttonLabel={t('athlete_migration.warningButton')}
            onButtonClick={onOpenMigration}
          />
        ) : (
          showMigrationSuccess && (
            <>
              <FooterBar type={'success'} backgroundColor={'#27AE60'} infoText={t('athlete_migration.success')} />
            </>
          )
        )}
        <Dialog
          mainTitle={t('athlete_migration.title')}
          isOpen={modalState.isOpenMigration}
          className={{
            body: styles.customDialogBody,
          }}
          btnRight={
            migration.loading ? (
              <Spinner />
            ) : etapa === 1 ? (
              <>
                Continuar <FaChevronRight />
              </>
            ) : (
              'Migrar Dados'
            )
          }
          btnRightDisabled={!(errorMessage === '' && footlinkAtlhete) || migration.loading}
          btnRightClick={() => (etapa === 1 ? setEtapa(2) : onConfirmMigration())}
          onClose={onCloseMigration}
        >
          {etapa === 1 && (
            <>
              <div className={styles.migrationDialog}>
                <p>{t('athlete_migration.step1Text')}</p>
              </div>
              <div className={styles.searchContainer}>
                <Input
                  rounded
                  name="athleteUrl"
                  onChange={e => delayedHandleSearch(e.target.value.toLowerCase())}
                  placeholder={t('athlete_migration.insertUrl')}
                  icon={
                    <FaSearch
                      style={{
                        color: Variables.lightPurple1,
                        transform: 'rotate(90deg)',
                      }}
                    />
                  }
                  iconPosition="right"
                />
                {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
              </div>
              {loadingFootlinkAthlete ? (
                <div className={styles.loadingFootlinkAthlete}>
                  <Spinner />
                </div>
              ) : (
                footlinkAtlhete && (
                  <>
                    <h3 className={styles.formatH3}>{t('athlete_migration.message1')}</h3>
                    <div className={styles.athleteContainer}>
                      <AthleteCardView item={footlinkAtlhete} />
                    </div>
                  </>
                )
              )}
            </>
          )}
          {etapa === 2 && (
            <>
              <div className={styles.migrationDialog}>
                <p>
                  <b>{t('athlete_migration.step2TextBold')}</b>
                  {t('athlete_migration.step2Text')}
                </p>
              </div>
              <div className={styles.athleteContainer}>
                <div>
                  <h3>{t('athlete_migration.original')}</h3>
                  <AthleteCardView item={mapAthleteToItem(athlete?.data)} />
                </div>
                <LuReply
                  style={{
                    color: Variables.lightPurple1,
                    transform: 'scaleX(-1)',
                    alignSelf: 'center',
                    fontSize: '30px',
                    marginTop: '24px',
                  }}
                />
                <div>
                  <h3>
                    {footlinkAtlhete.custom_athlete ? t('athlete_migration.original') : t('athlete_migration.footlink')}
                  </h3>
                  <AthleteCardView item={footlinkAtlhete} />
                </div>
              </div>
            </>
          )}
        </Dialog>
      </>
    );
  }

  return <div className={styles.container}>{content}</div>;
}

Athlete.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(Athlete);
