import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Variables from 'variables';
import styles from './IconButton.module.scss';

// TODO: refatorar: tirar os 'if' e utilizar o 'className'

export default function IconButton({ icon, text, className, color, disabled, tip, ...props }) {
    const iconClasses = [styles.button];
    if (className) iconClasses.push(className);
    if (color === 'green') iconClasses.push(styles.green);
    if (color === 'purple') iconClasses.push(styles.purple);
    if (color === 'white') iconClasses.push(styles.white);
    if (color === 'vividGreen') iconClasses.push(styles.vividGreen);
    if (color === 'lightBlue') iconClasses.push(styles.lightBlue);
    if (color === 'orange') iconClasses.push(styles.orange);
    if (color === 'dark') iconClasses.push(styles.dark);

    // rede de profissionais
    if (color === 'invitePurple') iconClasses.push(styles.invitePurple);
    if (color === 'connectPurple') iconClasses.push(styles.connectPurple);
    if (color === 'connectedPurple') iconClasses.push(styles.connectedPurple);

    // detalhes do profissional
    if (color === 'connect') iconClasses.push(styles.connect);
    if (color === 'connectWhite') iconClasses.push(styles.connectWhite);
    if (color === 'sendMessage') iconClasses.push(styles.sendMessage);
    if (color === 'follow') iconClasses.push(styles.follow);
    if (color === 'following') iconClasses.push(styles.following);
    if (color === 'headerArrow') iconClasses.push(styles.headerArrow);
    if (color === 'instagram') iconClasses.push(styles.instagram);
    if (color === 'twitter') iconClasses.push(styles.twitter);
    if (color === 'tiktok') iconClasses.push(styles.tiktok);
    if (color === 'facebook') iconClasses.push(styles.facebook);
    if (color === 'linkedin') iconClasses.push(styles.linkedin);
    if (color === 'showCertification') iconClasses.push(styles.showCertification);

    if (disabled) iconClasses.push(styles.disabled);

    const tooltipColor = Variables.lightPurple1;

    return (
        <>
            <button data-tip={tip} disabled={disabled} {...props} type="button" className={iconClasses.join(' ')}>
                {text && <span>{text}</span>}
                {icon}
            </button>
            {tip ? (
                <ReactTooltip className={styles.tooltip} effect="solid" place="bottom" backgroundColor={tooltipColor} />
            ) : null}
        </>
    );
}

IconButton.defaultProps = {
    className: '',
    color: '',
};

IconButton.propTypes = {
    icon: PropTypes.node.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    tip: PropTypes.string,
};
