import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { Input } from 'components/template';
import { MdArrowBack } from 'react-icons/md';
import AthleteCard from 'components/AthleteCard';
import { FaCheck, FaPlus, FaSearch } from 'react-icons/fa';
import Variables from 'variables';
import ShadowTeamLeftHeader from './ShadowTeamLeftHeader';
import Helpers from 'shared/helpers';
import { positionOptions } from 'shared/mocks';

export default function ShadowTeamAddAthletes({
  position,
  athletes,
  handleBack = () => {},
  editButtonClick = () => {},
  athletesSelected = () => {},
  handleSelectAthlete = () => {},
}) {
  const { t } = useTranslation();
  const [filter, setFilter] = useState({ name: '', position: '', frame: '' });
  const [athletesForShow, setAthletesForShow] = useState(athletes);

  const isAthleteSelected = athlete => {
    return !!athletesSelected.find(i => i.athlete.id === athlete?.athlete?.id);
  };

  const handleInputChange = e => {
    e.persist();
    setFilter({ ...filter, [e.target.name]: Helpers.normalizedString(e.target.value) });
  };

  useEffect(() => {
    setAthletesForShow(
      athletes.filter(athlete => {
        if (
          filter.name &&
          !Helpers.normalizedString(athlete.athlete.fullname).includes(filter['name']) &&
          !Helpers.normalizedString(athlete.athlete.nickname).includes(filter['name'])
        )
          return false;
        if (filter.position && athlete.athlete.position !== filter.position) return false;
        if (filter.frame && athlete.frame !== filter.frame) return false;
        return true;
      })
    );
  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ShadowTeamLeftHeader editButtonClick={editButtonClick}></ShadowTeamLeftHeader>

      <S.PositionTitle>
        <MdArrowBack
          size="18px"
          style={{ paddingLeft: '10px', paddingRight: '10px' }}
          onClick={handleBack}
          cursor={'pointer'}
        />
        <S.PositionTitleText>{t('proj.atletaPanelTitle')}</S.PositionTitleText>
      </S.PositionTitle>

      <S.LeftInput>
        <S.FilterInputContainer>
          <Input
            rounded
            name="name"
            onChange={e => handleInputChange(e)}
            inputStyle={{ backgroundColor: '#fff' }}
            placeholder={t('proj.atletaFiltroNome')}
            icon={<FaSearch className={styles.searchIcon} />}
            iconPosition="right"
          />
        </S.FilterInputContainer>

        {/* <label>{t('proj.atletaFiltroNome')}</label>
        <Input
          rounded
          name="name"
          placeholder={t('proj.atletaFiltroNome')}
          inputClassName={styles.leftInput}
          value={filter.name}
          onChange={e => handleInputChange(e)}
          style={{ backgroundColor: '#fff' }}
        /> */}
      </S.LeftInput>

      <S.LeftInput>
        <S.Menu
          rounded
          allowEmpty
          placeholder={t(`proj.atletaFiltroEtapaTodos`)}
          options={[
            {
              label: t(`proj.atletaFiltroEtapaTodos`),
              value: '',
            },
            ...athletes
              .reduce((acc, i) => {
                if (!acc.includes(i.frame)) acc.push(i.frame);
                return acc;
              }, [])
              .map(i => ({
                label: i,
                value: i,
              })),
          ]}
          value={filter.frame}
          setValue={value => setFilter({ ...filter, frame: value })}
        />

        <S.Menu
          rounded
          allowEmpty
          placeholder={t(`proj.atletaFiltroPosicaoTodos`)}
          options={[
            {
              label: t(`proj.atletaFiltroPosicaoTodos`),
              value: '',
            },
            ...positionOptions.map(i => ({
              label: i.value,
              value: i.value,
            })),
          ]}
          value={filter.position}
          setValue={value => setFilter({ ...filter, position: value })}
        />
      </S.LeftInput>

      <div style={{ marginTop: '20px' }}>
        {athletesForShow &&
          athletesForShow.map(athlete => (
            <AthleteCard
              key={athlete?.athlete?.id ?? athlete}
              item={athlete.athlete}
              // bordered={}
              hiddenColorPicker={true}
              iconImg={isAthleteSelected(athlete) ? <FaCheck /> : <FaPlus />}
              iconStyle={
                isAthleteSelected(athlete)
                  ? { backgroundColor: Variables.green1, color: Variables.light }
                  : { backgroundColor: Variables.lightPurple1, color: Variables.light }
              }
              iconClick={() => {
                if (!isAthleteSelected(athlete)) handleSelectAthlete(athlete);
              }}
              style={{ margin: '0 15px 15px' }}
            ></AthleteCard>
          ))}
      </div>
    </>
  );
}
