import React from 'react';

// scss
import styles from './NegotiationChatHeader.module.scss';

// components
import { Avatar, IconButton } from 'components/template';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { BsExclamationTriangleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

//import "../../translations/i18n"
import { AiFillWarning } from 'react-icons/ai';
import { MdError } from 'react-icons/md';

// other
import Variables from 'variables';
import ProfileIcon from 'assets/ico/profile_box.svg';
import { useTranslation } from 'react-i18next';

export default function NegotiationChatHeader({
  selectedChat,
  handleFilterChange,
  headerOpen,
  handleAccordionClick,
  openNegotiations,
  otherChatClick,
  showOtherChatsAlert,
  user,
  ...rest
}) {
  const { t } = useTranslation();

  const transferType = selectedChat?.transfer_market?.negotiation_type;
  const transferMarketIsActive = selectedChat?.transfer_market?.is_active ?? false;

  const containerClasses = [styles.container];
  const isDesiredAthlete = selectedChat?.transfer_market?.is_desired_athlete ? true : false;
  let negotiationHeaderClubTitle = t('negotiations.headerClubTitle');
  let negotiationHeaderClubName = '';
  let negotiationHeaderClubEmblem = '';
  if (isDesiredAthlete) {
    if (user.organization.teamId === selectedChat.buyer_club.id) {
      negotiationHeaderClubTitle = t('transferMarket.offeredBy');
      if (selectedChat.ceding_club == null) {
        negotiationHeaderClubName = selectedChat.ceding_agency.name;
        negotiationHeaderClubEmblem = selectedChat.ceding_agency.emblem;
      } else {
        negotiationHeaderClubName = selectedChat.ceding_club.nikename;
        negotiationHeaderClubEmblem = selectedChat.ceding_club.emblem;
      }
    } else {
      negotiationHeaderClubTitle = t('transferMarket.interestedClub');
      negotiationHeaderClubName = selectedChat.buyer_club.nickname;
      negotiationHeaderClubEmblem = selectedChat.buyer_club.emblem;
    }
  }

  return (
    <div className={containerClasses.join(' ')} {...rest}>
      <div className={styles.containerRow}>
        <div className={styles.athleteCard}>
          <div className={styles.left}>
            <div className={styles.clubContainer}>
              <div className={styles.athleteImg}>
                <Avatar img={selectedChat.athlete?.photo} name={selectedChat.athlete?.fullname} noBorder />
              </div>
            </div>
          </div>
          <div className={styles.center}>
            <span className={styles.name}>{selectedChat.athlete?.nickname}</span>
            <span className={styles.completeName}>{selectedChat.athlete?.fullname}</span>
            <div className={styles.info}>
              {selectedChat.athlete?.nationalities?.length && (
                <img
                  src={selectedChat.athlete?.nationalities?.[0]?.flag}
                  alt={selectedChat.athlete?.nationalities?.[0]?.name ?? ''}
                  draggable="false"
                />
              )}
              <span className={styles.age}>
                {selectedChat.athlete?.year} {selectedChat.athlete?.age} {t('athlete_card.idade')}
              </span>
              <span className={styles.position}>{t(`positionOptionsRaw.${selectedChat.athlete?.position}`)}</span>
            </div>
          </div>
        </div>
        <div className={[styles.headerPanel, styles.athleteClub].join(' ')}>
          <div className={styles.headerTitle}>{negotiationHeaderClubTitle}</div>
          {selectedChat.ceding_club && (
            isDesiredAthlete ? (
              <div className={styles.athleteClubAvatar}>
                <Avatar
                  img={negotiationHeaderClubEmblem}
                  name={negotiationHeaderClubName}
                  className={styles.club}
                  noBorder
                />
                <div style={{ alignContent: 'center' }}>
                  <p className={styles.clubName}>{negotiationHeaderClubName}</p>
                </div>
              </div>
            ) : (
              <div className={styles.athleteClubAvatar}>
                <Avatar
                  img={selectedChat.ceding_club.emblem}
                  name={selectedChat.ceding_club.nikename}
                  className={styles.club}
                  noBorder
                />
                <div>
                  <p className={styles.clubName}>{selectedChat.ceding_club.nikename}</p>
                  {selectedChat.athlete?.current_team?.id === selectedChat.ceding_club.id ? (
                    <p>Até {selectedChat.athlete?.clubContractExpire ?? 'N/D'}</p>
                  ) : (
                    <p>Até N/D</p>
                  )}
                </div>
              </div>
            )
          )}
          {selectedChat.ceding_agency && (<>
            {user.organization.teamId === selectedChat.buyer_club.id ? <div className={styles.athleteClubAvatar}>
              <Avatar
                img={selectedChat.ceding_agency.emblem}
                name={selectedChat.ceding_agency.name}
                className={styles.club}
                noBorder
              />
              <div>
                <p className={styles.clubName}>{selectedChat.ceding_agency.name}</p>
                <p>{t('agencies.agency')}</p>
              </div>
            </div> :
            <div className={styles.athleteClubAvatar}>
              <Avatar
                img={selectedChat.buyer_club.emblem}
                name={selectedChat.buyer_club.name}
                className={styles.club}
                noBorder
              />
              <div>
                <p className={styles.clubName}>{selectedChat.buyer_club.nickname}</p>
                <p>{t('agencies.club')}</p>
              </div>
            </div>
            }
            </>) 
          }
        </div>
        <div className={[styles.headerPanel, styles.athletePrice].join(' ')}>
          {isDesiredAthlete ? (
            <>
              <div className={styles.headerTitle}>Valor anunciado</div>
              <p className={styles.priceText}>
                {selectedChat.transfer_market?.purchase_value?.toLocaleString('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                }) ?? 'N/D'}
              </p>
            </>
          ) : (
            <>
              <div className={styles.headerTitle}>{t('negotiations.headerPriceTitle')}</div>
              <p className={styles.priceText}>
                {selectedChat.transfer_market?.sale_value?.toLocaleString('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                }) ?? 'N/D'}
              </p>
            </>
          )}
        </div>
        <div className={[styles.headerPanel, styles.transferType].join(' ')}>
          <div className={styles.headerTitle}>{t('negotiations.headerTransferTypeTitle')}</div>
          {transferType && (
            <p>
              {t(
                `negotiations.header${transferType.charAt(0).toUpperCase() +
                  transferType.slice(1).toLowerCase()}TransferTypeText`
              )}
            </p>
          )}
        </div>
        <div className={[styles.headerPanel, styles.salary].join(' ')}>
          <div className={styles.headerTitle}>{t('negotiations.headerSalaryTitle')}</div>
          <p>
            {isDesiredAthlete ? (
              (selectedChat.transfer_market.annual_salary ?? selectedChat.transfer_market.monthly_salary) + 
              ' € ' + 
              t(`transferMarket.${selectedChat.transfer_market.annual_salary ? 'perYear' : 'perMonth'}`)
            ) : (
              selectedChat.transfer_market?.annual_salary ? selectedChat.transfer_market?.annual_salary + ' M €' : 'N/D'
            )}
          </p>
        </div>
        <div className={styles.accordionTrigger}>
          {headerOpen ? (
            <BiChevronUp size={26} onClick={handleAccordionClick} />
          ) : (
            <BiChevronDown size={26} onClick={handleAccordionClick} />
          )}
        </div>
      </div>
      <div className={styles.accordionContent} hidden={!headerOpen}>
        <div>
          <Link
            to={{ pathname: `/atletas/${selectedChat.athlete?.id}`, state: { previews: window.location.pathname } }}
            target="_self"
          >
            <IconButton
              className={styles.profile_button}
              text="Ver perfil completo"
              icon={<img src={ProfileIcon} alt="profile icon" />}
              type="button"
            />
          </Link>
        </div>
        <div>
          <p>
            <b>{t('negotiations.headerClubObs')}</b>
          </p>
          <p>{selectedChat.transfer_market?.notes}</p>
        </div>
      </div>
      {!!showOtherChatsAlert && (
        <div className={styles.otherChatAlert}>
          <BsExclamationTriangleFill />
          <span className={styles.otherChatAlertText}>
            {/* <Trans i18nKey="userMessagesUnread" count={count}>
            Hello <strong title={t('nameTitle')}>{{name}}</strong>, you have {{count}} unread message. <Link to="/msgs">Go to messages</Link>.
            </Trans> */}
            {t('negotiations.headerOtherChatAlertTitle')}
          </span>
          <span className={styles.otherChatAlertLink} onClick={otherChatClick}>
            {t('negotiations.headerOtherChatAlertLink')}
          </span>
        </div>
      )}
      {openNegotiations >= 3 ? (
        <div className={[styles.topBarBanner, styles.topBarBannerMessages].join(' ')}>
          <AiFillWarning />
          <span>{t('transferMarket.headerOtherChatAlertTitle', { length: openNegotiations })}</span>
          <span className={styles.spanButton} onClick={() => handleFilterChange('athlete', selectedChat.athlete?.id)}>
            {t('transferMarket.headerOtherChatAlertLink')}
          </span>
        </div>
      ) : (
        <></>
      )}
      {!transferMarketIsActive && (
        <div className={[styles.topBarBanner, styles.topBarBannerInactive].join(' ')}>
          <MdError style={{ color: Variables.red }} />
          <span>{t('transferMarket.headerChatInactive')}</span>
        </div>
      )}
    </div>
  );
}
