import React from 'react';
import PropTypes from 'prop-types';

import styles from './CountryRow.module.scss';

export default function CountryRow(props) {
  const { img, name, id, ...rest } = props;

  return (
    <div className={styles.withImg} {...rest}>
      <img
        key={id}
        src={img ?? ''}
        draggable="false"
        alt={name ?? ''}
        title={name ?? ''}
      />
      {name}
    </div>
  );
}

CountryRow.defaultProps = {
  countries: [],
};

CountryRow.propTypes = {
  id: PropTypes.number,
  img: PropTypes.string,
  name: PropTypes.string
};
