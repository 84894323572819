import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Table, Spinner, Menu, SliderMenu } from 'components/template';
import { getAthleteTransfers, getAthleteContracts } from 'store/ducks/athleteV2';
import styles from './Career.module.scss';
import TeamRow from 'components/TeamRow/TeamRow';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export default function Career({ athleteId }) {
  const dispatch = useDispatch();
  const athleteTransfers = useSelector(state => state.athleteV2.athleteTransfers);
  const athleteContracts = useSelector(state => state.athleteV2.athleteContracts);

  const { t } = useTranslation();

  const [transferList, setTransferList] = useState([]);
  const [contractList, setContractList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);

  const [filters, setFilters] = useState({
    season: [],
    age: {
      max: null,
      min: null,
    },
    value: {
      max: null,
      min: null,
    },
    registerType: [],
    period: [],
    clubs: [],
  });

  const [transfersFilters, setTransfersFilters] = useState({
    season: [],
    age: {
      max: null,
      min: null,
    },
    value: {
      max: null,
      min: null,
    },
  });

  const [contractsFilters, setContractsFilters] = useState({
    registerType: [],
    period: [],
    clubs: [],
  });

  useEffect(() => {
    fetchData();
  }, [athleteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTransferList(filterTransferData(athleteTransfers.data));
    setContractList(filterContractsData(athleteContracts.data));
    createFilters(athleteTransfers.data, athleteContracts.data);
  }, [athleteTransfers.data, athleteContracts.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    applyTransfersFilters();
  }, [transfersFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    applyContractsFilters();
  }, [contractsFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  /* Filters */

  function createFilters(transfers, contracts) {
    const contract = createContractsFilters(contracts);
    const transfer = createTransfersFilters(transfers);

    setFilters({ ...transfer, ...contract });
  }

  function createTransfersFilters(data) {
    let seasons = [];
    let minAge = 0;
    let maxAge = 0;
    let minValue = 0;
    let maxValue = 0;

    data.forEach(register => {
      seasons.push(register.season);

      const age = register.age_at_date?.years;

      if (!isNaN(age)) {
        minAge = minAge === 0 ? age : minAge > age ? age : minAge;
        maxAge = maxAge === 0 ? age : maxAge < age ? age : maxAge;
      }

      const value = Number(register.monetary_value);

      minValue = minValue === 0 ? value : minValue > value ? value : minValue;

      maxValue = maxValue === 0 ? value : maxValue < value ? value : maxValue;
    });

    return {
      // ...filters,
      season: [...new Set(seasons)],
      age: {
        min: minAge,
        max: maxAge,
      },
      value: {
        min: minValue,
        max: maxValue,
      },
    };
  }

  function applyTransfersFilters() {
    if (
      transfersFilters.season.length > 0 ||
      transfersFilters.age.min !== null ||
      transfersFilters.age.max !== null ||
      transfersFilters.value.max !== null ||
      transfersFilters.value.max !== null
    ) {
      const filteredData = athleteTransfers.data.filter(register => {
        let age = register.age_at_date?.years;
        age = isNaN(age) ? 0 : age;

        return (
          (transfersFilters.season.length === 0 ||
            transfersFilters.season.some(season => season === register.season)) &&
          (transfersFilters.age.min === null || transfersFilters.age.min <= age) &&
          (transfersFilters.age.max === null || transfersFilters.age.max >= age) &&
          (transfersFilters.value.min === null || transfersFilters.value.min <= Number(register.monetary_value)) &&
          (transfersFilters.value.max === null || transfersFilters.value.max >= Number(register.monetary_value))
        );
      });

      setTransferList(filterTransferData(filteredData));
    } else {
      setTransferList(filterTransferData(athleteTransfers.data));
    }
  }

  function createContractsFilters(data) {
    let types = [];
    let period = [];
    let clubs = [];

    data.forEach(register => {
      types.push(register.contract_info.type);
      clubs.push(register.team?.nickname);
      const startDateStr = register.contract_info.start;
      const endDateStr = register.contract_info.end;

      if (startDateStr && endDateStr) {
        period.push(parsePeriod(startDateStr, endDateStr));
      }
    });

    return {
      registerType: [...new Set(types)],
      period: [...new Set(period)],
      clubs: [...new Set(clubs)],
    };
  }

  function applyContractsFilters() {
    if (contractsFilters.clubs && contractsFilters.clubs.length > 0) {
      const filteredData = athleteContracts.data.filter(register => {
        return (
          (contractsFilters.clubs && contractsFilters.clubs.length === 0) ||
          contractsFilters.clubs.some(club => club === register.team?.nickname)
        );
      });
      setContractList(filterContractsData(filteredData));
    } else {
      setContractList(filterContractsData(athleteContracts.data));
    }
  }

  function fetchData() {
    Promise.all([dispatch(getAthleteTransfers(athleteId)), dispatch(getAthleteContracts(athleteId))]).then(() => {
      setIsLoading(false);
    });
  }

  function parsePeriod(start, end) {
    if (start && end) {
      const startFilter = `${start.split('-')[1]}/${start.split('-')[0]}`;
      const endFilter = `${end.split('-')[1]}/${end.split('-')[0]}`;

      return `${startFilter}-${endFilter}`;
    } else {
      return null;
    }
  }

  function filterTransferData(data) {
    let sum = 0;

    const filteredData = data
      .filter(dt => dt.age_at_date !== null)
      .map(register => {
        const season = register.season;
        const date = register.transfer_date && moment(register.transfer_date).format('DD/MM/YYYY');
        const srcTeam = register.src_team && (
          <div className={styles.flagsDiv}>
            {register.src_team?.country?.flag && (
              <img
                className={styles.countryFlag}
                src={register.src_team?.country?.flag}
                alt={register.src_team?.country?.name ?? ''}
              />
            )}
            <TeamRow name={register.src_team?.nickname} id={register.src_team?.id} img={register.src_team?.emblem} />
          </div>
        );
        const arrows = <AiOutlineArrowRight />;
        const dstTeam = register.dst_team && (
          <div className={styles.flagsDiv}>
            {register.dst_team?.country?.flag && (
              <img
                className={styles.countryFlag}
                src={register.dst_team?.country?.flag}
                alt={register.dst_team?.country?.name ?? ''}
              />
            )}
            <TeamRow name={register.dst_team?.nickname} id={register.dst_team?.id} img={register.dst_team?.emblem} />
          </div>
        );
        const age = `${register.age_at_date?.years} ${t('athlete_card.idade')} ${register.age_at_date?.months} ${t(
          'athlete_card.meses'
        )} ${register.age_at_date?.days} ${t('athlete_card.days')}`;
        const value =
          register.monetary_value === 0
            ? t('athlete_card.zero_cost')
            : register.monetary_value === null
            ? '-'
            : register.monetary_value;

        sum += Number(register.monetary_value);

        return [season, date, srcTeam, arrows, dstTeam, age, value];
      });

    setTotalAmount(sum);

    return filteredData;
  }

  function filterContractsData(data) {
    return data.map(register => {
      const bidDate = register.contract_info?.bid_date && moment(register.contract_info?.bid_date).format('DD/MM/YYYY');
      const team = <TeamRow name={register.team?.nickname} id={register.team?.id} img={register.team?.emblem} />;
      const state = register.contract_info?.state;
      const type = register.contract_info?.type;

      const contractActive = moment(register.contract_info.end, 'YYYY-MM-DD')._d >= moment();
      const contract = register.contract_info ? (
        <span className={styles.contractStatus}>{register.contract_info.contract}</span>
      ) : (
        ''
      );
      const start = register.contract_info?.start && (
        <span className={contractActive ? styles.contractActive : styles.contractInactive}>
          {moment(register.contract_info?.start).format('DD/MM/YYYY')}
        </span>
      );
      // const end = register.contract_info?.end && (
      //   <span className={contractActive ? styles.contractActive : styles.contractInactive}>
      //     {moment(register.contract_info?.end).format('DD/MM/YYYY')}
      //   </span>
      // );

      return [bidDate, team, state, type, contract, start /*, end*/];
    });
  }

  let content;

  if (athleteTransfers.error || athleteContracts.error) {
    const error = athleteTransfers.error || athleteContracts.error;

    content = (
      <Card bordered softShadow className={styles.errorContainer}>
        <h2>{t(error)}</h2>
      </Card>
    );
  } else {
    content = (
      <div className={styles.carrer_container}>
        {/* contracts */}
        {!!contractList.length && (
          <div className={styles.contracts}>
            <span className={styles.header}>{t('athlete_card.text1')}</span>
            <div className={styles.filters_container}>
              <span>{t('proj.filtrarPor')}</span>
              <Menu
                title={t('athlete_card.filter1')}
                rounded
                allowEmpty
                placeholder={t('athlete_card.filter1')}
                className={styles.menu}
                options={filters.clubs.map(item => ({ label: item, value: item }))}
                setValue={value => setContractsFilters({ ...contractsFilters, clubs: [value] })}
                value={contractsFilters.clubs}
                onClear={() => setContractsFilters({ ...contractsFilters, clubs: [] })}
              />
              {/* <Menu
                title="Período"
                rounded
                allowEmpty
                placeholder={'Período'}
                fixedPlaceholder
                className={styles.menu}
                options={filters.period.map(filter => ({ label: filter, value: filter }))}
                // setValue={value => setContractsFilters({ ...contractsFilters, period: value })}
                value={contractsFilters.period}
                multiple
              /> */}
            </div>
            <Table
              className={styles.contracts_table}
              flat
              theads={[
                t('athlete_card.text2'),
                t('athlete_list.box2'),
                t('athlete_card.text5'),
                t('contact_card.type'),
                t('filters.contract'),
                t('athlete_card.text3'),
                //t('athlete_card.text4'),
              ]}
              data={contractList}
            />
          </div>
        )}

        {/* transfers */}
        {!!transferList.length && (
          <div className={styles.transfers}>
            <span className={styles.header}>{t('club.box20')}</span>
            <div className={styles.filters_container}>
              <span>{t('proj.filtrarPor')}</span>
              <Menu
                title={t('slider_menu.season_all')}
                rounded
                allowEmpty
                placeholder={t('slider_menu.season_all')}
                fixedPlaceholder
                className={styles.menu}
                options={filters.season.map(item => ({ label: item, value: item }))}
                setValue={value => setTransfersFilters({ ...transfersFilters, season: value })}
                value={transfersFilters.season}
                multiple
                onClear={() => setTransfersFilters({ ...transfersFilters, season: [] })}
              />
              <SliderMenu
                rounded
                title={t('slider_menu.age_all')}
                className={styles.menu}
                max={filters.age.max || 0}
                min={filters.age.min || 0}
                setFilter={value => {
                  const [min, max] = value;
                  setTransfersFilters({ ...transfersFilters, age: { min, max } });
                }}
              />
              <SliderMenu
                rounded
                title={t('slider_menu.value_all')}
                className={styles.menu}
                max={filters.value.max || 0}
                min={filters.value.min || 0}
                setFilter={value => {
                  const [min, max] = value;
                  setTransfersFilters({ ...transfersFilters, value: { min, max } });
                }}
              />
              <span className={styles.total_amount}>
                {t('athlete_card.text7')} <strong>{totalAmount} M €</strong>
              </span>
            </div>
            <Table
              className={styles.transfers_table}
              flat
              theads={[
                t('slider_menu.seas'),
                t('table.data'),
                t('table.origin'),
                '',
                t('table.destiny'),
                t('table.age_moment'),
                t('table.price'),
              ]}
              data={transferList}
              // footer={`${t('athlete_card.text7')} ${totalAmount} M €`}
            />
          </div>
        )}
      </div>
    );
  }

  return isLoading ? (
    <div className={styles.loaderContainer}>
      <Spinner />
    </div>
  ) : (
    content
  );
}
