import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
//import Icons from 'assets/ico/icons.png';
import Out from 'assets/ico/logout.png';
import styles from './ProfileCard.module.scss';
import { IoMdLock } from 'react-icons/io';
import { MdAccountCircle } from 'react-icons/md';
import { IoSettingsSharp } from 'react-icons/io5';

import { PopCard, Button } from 'components/template';
import { useTranslation } from 'react-i18next';

function ProfileCard({ user, isVisible, hide, logout, history }) {
  const { t } = useTranslation();
  //const icon = <img draggable='false' className={styles.icon} src={Icons} alt='Icone do Meu Perfil' />
  const out = <img draggable="false" className={styles.iconOut} src={Out} alt="Icone de fazer Logout" />;

  function myProfileClickHandler() {
    hide();
    history.push('/perfil');
  }

  function changePassword() {
    hide();
    history.push('/perfil/alterar-senha');
  }

  // function clubClickHandler() {
  //     hide();
  //     history.push(`/clube/${user.organization.teamId}/elenco`);
  // }

  function configClickHandler() {
    hide();
    const showOrgTab = user.plan.has_any_permission && !user.is_only_for_projects && user.organization != null;
    if (showOrgTab) {
      history.push('/configuracoes');
    } else {
      history.push('/configuracoes/informacoes_sistema');
    }
  }

  return (
    <PopCard isVisible={isVisible} hide={hide} className={styles.container} style={{ left: '-175px' }}>
      {user.plan.has_any_permission && (
        <div>
          <span onClick={myProfileClickHandler} className={styles.link}>
            {<MdAccountCircle className={styles.icon} />}
            {t('home.text18')}
          </span>
        </div>
      )}
      <div>
        <span onClick={changePassword} className={styles.link}>
          {<IoMdLock className={styles.icon} />}
          {t('home.text19')}
        </span>
      </div>
      <div>
        <span onClick={configClickHandler} className={styles.link}>
          {<IoSettingsSharp className={styles.icon} />}
          {t('organi.text1')}
        </span>
      </div>
      <Button onClick={logout} className={styles.buttons}>
        {t('app.box5')}
        {out}
      </Button>
    </PopCard>
  );
}

ProfileCard.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  isVisible: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(ProfileCard);
