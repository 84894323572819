import React from 'react';
import Popover from 'react-tiny-popover';

import styles from './MonitoringButton.module.scss';
import DialogContent from './DialogContent/DialogContent';
import { useSelector } from 'react-redux';

function MonitoringDialog({
  children,
  isOpen,
  setIsOpen,
  createList,
  editList,
  deleteList,
  getList,
  list,
  athlete,
  addMonitor,
  deleteMonitor,
  onAthleteListChange,
  projects,
  getProjects,
  workflows,
  getWorkflows,
  createWorkflows,
  updateFrameWorkflows,
  deleteWorkflow,
}) {
  const loggedUser = useSelector(state => state.user.data);
  return (
    <Popover
      isOpen={isOpen}
      position={['bottom', 'top']}
      onClickOutside={() => setIsOpen(false)}
      containerClassName={styles.popoverContainer}
      // content={menuContent}
      content={
        <DialogContent
          loggedUser={loggedUser}
          athlete={athlete}
          getList={getList}
          createList={createList}
          editList={editList}
          deleteList={deleteList}        
          addMonitor={addMonitor}
          onAthleteListChange={onAthleteListChange}
          deleteMonitor={deleteMonitor}
          list={list}
          setIsOpen={setIsOpen}
          projects={projects}
          getProjects={getProjects}
          workflows={workflows}
          getWorkflows={getWorkflows}
          createWorkflows={createWorkflows}
          updateFrameWorkflows={updateFrameWorkflows}
          deleteWorkflow={deleteWorkflow}
        />
      }
    >
      {children}
    </Popover>
  );
}

export default MonitoringDialog;
