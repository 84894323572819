import React, { useState } from 'react';

// components
import NegotiationChatItem from './Negotiations/NegotiationChatItem';
import AthleteChatItem from './Athletes/AthleteChatItem';
import TeamChatItem from './Teams/TeamChatItem';
import AgentChatItem from './Agents/AgentChatItem';

export default function ChatItem({ chat, user, selectedChat, messages, repository, handleSelectChat }) {
  const [isAlertVisible, setAlertVisibility] = useState(true);

  const isSelected = selectedChat?.id === chat.id;
  const alertCount = chat.new_messages_count ?? '?';

  return (
    <div key={chat.id}>
      {repository === 'negociacoes' && (
        <NegotiationChatItem
          chat={chat}
          user={user}
          date={chat.last_message ?? chat.created_at}
          isSelected={isSelected}
          alertCount={alertCount}
          setAlertVisibility={setAlertVisibility}
          isAlertVisible={isAlertVisible}
          handleSelectChat={handleSelectChat}
        />
      )}
      {repository === 'atletas' && (
        <AthleteChatItem
          chat={chat}
          user={user}
          date={chat.last_message ?? chat.created_at}
          last_message={chat.last_message ?? { created_at: chat.created_at }}
          isSelected={isSelected}
          alertCount={alertCount}
          setAlertVisibility={setAlertVisibility}
          isAlertVisible={isAlertVisible}
          handleSelectChat={handleSelectChat}
        />
      )}
      {repository === 'clubes' && chat.team && (
        <TeamChatItem
          chat={chat}
          user={user}
          last_message={chat.last_message ?? { created_at: chat.created_at }}
          isSelected={isSelected}
          alertCount={alertCount}
          setAlertVisibility={setAlertVisibility}
          isAlertVisible={isAlertVisible}
          handleSelectChat={handleSelectChat}
        />
      )}
      {repository === 'agentes' && (chat.agency || chat.org) && (
        <AgentChatItem
          chat={chat}
          user={user}
          last_message={chat.last_message ?? { created_at: chat.created_at }}
          isSelected={isSelected}
          alertCount={alertCount}
          setAlertVisibility={setAlertVisibility}
          isAlertVisible={isAlertVisible}
          handleSelectChat={handleSelectChat}
        />
      )}
    </div>
  );
}
