/*eslint-disable eqeqeq*/
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FaSearch, FaEllipsisV } from 'react-icons/fa';
import { Input, Button, IconButton, Menu, Dialog } from 'components/template';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { debounce } from 'shared/utility';
import { updateAgencyNegotiations } from 'store/ducks/agencies';
import { useTranslation } from 'react-i18next';
import { getPreferentialFootWithLabel, positionOptions } from 'shared/mocks';
import * as S from './styles';
import styles from './index.module.scss';
import CenteredLoader from 'components/template/CenteredLoader';
import AthleteCard from 'components/AthleteCard';
import Helpers from 'shared/helpers';
import Variables from 'variables';
import BarIdentification from '../BarIdentification';
import { getAgencyNegotiations, deleteNegotiation } from 'store/ducks/agencies';

let updateWorkflowPosition = 0;
function Kanban({
  openFilter,
  queryFilters,
  setQueryFilters,
  openEditFrame,
  confirmDelete,
  deleteFrame,
  setDeleteFrame,
  enableAddAthlete,
  updateAthletesWorkflow,
  agencyId,
  agencies,
  history,
  loading,
  agencyFrameObject,
}) {
  const { t } = useTranslation();
  const [athletesFrames, setAthletesFrames] = useState([]);
  const [currentFrame, setCurrentFrame] = useState('');

  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const [removeAthleteData, setRemoveAthleteData] = useState(null);

  useEffect(() => {
    const newAthletesFrames = [];
    (updateAthletesWorkflow ?? []).forEach(athleteWorkflow => {
      const athleteTeamId = athleteWorkflow?.athlete?.current_team?.id ?? 0;
      if (
        (queryFilters['name'] === '' ||
          Helpers.normalizedString(athleteWorkflow.athlete.fullname).includes(queryFilters['name'])) &&
        (queryFilters['position'].length === 0 ||
          queryFilters['position'].includes(athleteWorkflow.athlete.position)) &&
        (queryFilters['foot'] === '' || athleteWorkflow.athlete.foot === queryFilters['foot']) &&
        (queryFilters['membership'] === 'transfer' ||
          queryFilters['membership'] === 'represent' ||
          athleteTeamId === agencyId)
      ) {
        let newAthletesFrame = newAthletesFrames[athleteWorkflow.frame];
        if (newAthletesFrame === undefined) {
          newAthletesFrames[athleteWorkflow.frame] = [];
          newAthletesFrame = newAthletesFrames[athleteWorkflow.frame];
        }
        newAthletesFrame.push({
          ...athleteWorkflow.athlete,
          transfer_market_negotiation_id: athleteWorkflow.transfer_market_negotiation_id,
        });
      }
    });
    setAthletesFrames(newAthletesFrames);
  }, [queryFilters, updateAthletesWorkflow]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (confirmDelete) {
      deleteFrame(currentFrame);
      toogleUpdateWorkflowPanel(-1, '');
    }
  }, [confirmDelete]); // eslint-disable-line react-hooks/exhaustive-deps

  const delayedHandleSearch = debounce(handleSearch);

  function handleSearch(searchText) {
    setQueryFilters({ ...queryFilters, name: searchText });
  }

  const toogleUpdateWorkflowPanel = (index, frameName) => {
    for (let i = 0; i < Object?.keys(agencyFrameObject).length; i++) {
      if (i !== index) {
        document.getElementById(`updateWorkflow${i}`).style.backgroundColor = 'unset';
      }
    }
    setCurrentFrame(frameName);
    const currentBC = index >= 0 ? document.getElementById(`updateWorkflow${index}`).style.backgroundColor : undefined;
    const dragDropContext = document.querySelector('#DragDropContext').firstChild;
    if (currentBC === 'unset' || currentBC === '') {
      updateWorkflowPosition = index * 376 + 260;
      dragDropContext.onscroll = () => {
        const scrollLeft = document.querySelector('#DragDropContext').firstChild.scrollLeft;
        document.getElementById('updateWorkflow').style.marginLeft = `${updateWorkflowPosition - scrollLeft}px`;
      };
      document.getElementById('updateWorkflow').style.display = 'block';
      document.getElementById('updateWorkflow').style.marginLeft = `${updateWorkflowPosition -
        dragDropContext.scrollLeft}px`;
      document.getElementById(`updateWorkflow${index}`).style.backgroundColor = Variables.gray5;
    } else {
      dragDropContext.onscroll = undefined;
      document.getElementById('updateWorkflow').style.display = 'none';
      if (index >= 0) {
        document.getElementById(`updateWorkflow${index}`).style.backgroundColor = 'unset';
      }
    }
  };

  return (
    <S.Card softShadow bordered>
      <BarIdentification title={t('myClub.negotiations.negotiations')} subtitle={t('myClub.negotiations.subtitle')} />
      <div className={styles.filters}>
        <div className={styles.button}>
          <div
            onClick={() => {
              if (queryFilters.membership !== 'transfer') {
                setQueryFilters({
                  ...queryFilters,
                  membership: 'transfer',
                });
              }
            }}
            className={`${styles.leftDefault} ${queryFilters.membership === 'transfer' ? styles.selected : ''}`}
          >
            <span>{t('myAgency.transfer')}</span>
          </div>
          <div
            onClick={() => {
              if (queryFilters.membership !== 'represent') {
                setQueryFilters({
                  ...queryFilters,
                  membership: 'represent',
                });
              }
            }}
            className={`${styles.rightDefault} ${queryFilters.membership === 'represent' ? styles.selected : ''}`}
          >
            <span>{t('myAgency.represent')}</span>
          </div>
        </div>
        <Input
          containerClassName={styles.inputContainer}
          icon={<FaSearch className={styles.icon} />}
          iconPosition="right"
          placeholder={t('proj.atletaFiltroNome')}
          onChange={e => delayedHandleSearch(e.target.value.toLowerCase())}
        />
        <Menu
          title={t('myClub.negotiations.allPositions')}
          className={styles.menu}
          placeholder={t('myClub.negotiations.allPositions')}
          allowEmpty
          value={queryFilters['position']}
          setValue={position => setQueryFilters({ ...queryFilters, position: position })}
          options={positionOptions.map(option => ({
            label: t(`positionOptions.${option.value}`),
            value: option.value,
          }))}
          multiple
          onClear={() => setQueryFilters({ ...queryFilters, position: [] })}
        />
        <Menu
          title={t('athlete_card.foot')}
          className={styles.menu}
          allowEmpty
          value={queryFilters['foot']}
          setValue={foot => setQueryFilters({ ...queryFilters, foot: foot })}
          onClear={() => setQueryFilters({ ...queryFilters, foot: '' })}
          options={getPreferentialFootWithLabel(t)}
          placeholder={t('athlete_card.foot')}
        />
      </div>
      {loading.agencies || loading.frames ? (
        <CenteredLoader size="calc(100vh - 250px)" />
      ) : (
        <>
          <S.UpdateWorkflow id="updateWorkflow">
            <button
              onClick={() => {
                openEditFrame({
                  isOpen: true,
                  frame: currentFrame,
                  description: agencyFrameObject[currentFrame],
                  saving: false,
                });
                toogleUpdateWorkflowPanel(-1, '');
              }}
            >
              {t('buttons.edit')}
            </button>
            <button
              onClick={() => {
                setDeleteFrame(true);
              }}
            >
              {t('buttons.excluir')}
            </button>
          </S.UpdateWorkflow>
          <div id={'DragDropContext'}>
            <DragDropContext
              onDragEnd={result => {
                const sourceFrame = result.source.droppableId;
                const destinationFrame = result.destination?.droppableId;
                const athleteId = parseInt(result.draggableId);
                if (destinationFrame) {
                  if (sourceFrame === destinationFrame) {
                    const sourceAthletesArray = [...athletesFrames[sourceFrame]];
                    const [removedAthlete] = sourceAthletesArray.splice(result.source.index, 1);
                    sourceAthletesArray.splice(result.destination.index, 0, removedAthlete);

                    const newAthletesFrame = { ...athletesFrames };
                    newAthletesFrame[sourceFrame] = sourceAthletesArray;
                    setAthletesFrames(newAthletesFrame);

                    const aw = updateAthletesWorkflow.find(aw => aw.athlete.id === athleteId);
                    aw.frame = destinationFrame;
                    updateAgencyNegotiations(agencyId, aw.id, destinationFrame, result.destination.index, aw.type).then(
                      data => {
                        if (data.error) {
                          console.error(data.error);
                        }
                      }
                    );
                  } else {
                    const sourceAthletesArray = [...athletesFrames[sourceFrame]];
                    const [removedAthlete] = sourceAthletesArray.splice(result.source.index, 1);

                    const destAthletesArray = [...(athletesFrames[destinationFrame] ?? [])];
                    destAthletesArray.splice(result.destination.index, 0, removedAthlete);

                    const newAthletesFrame = { ...athletesFrames };
                    newAthletesFrame[sourceFrame] = sourceAthletesArray;
                    newAthletesFrame[destinationFrame] = destAthletesArray;
                    setAthletesFrames(newAthletesFrame);

                    const aw = updateAthletesWorkflow.find(aw => aw.athlete.id === athleteId);
                    aw.frame = destinationFrame;
                    updateAgencyNegotiations(agencyId, aw.id, destinationFrame, result.destination.index, aw.type).then(
                      data => {
                        if (data.error) {
                          console.error(data.error);
                        }
                      }
                    );
                  }
                }
              }}
            >
              <S.ColumnsContainer>
                {Object.keys(agencyFrameObject)
                  .map((frameName, index) => {
                    const frameDescription = agencyFrameObject[frameName];
                    const atletas = athletesFrames[frameName] ?? [];
                    return (
                      <div key={`kanban${frameName}`}>
                        <S.ColumnHeader>
                          <S.Title>
                            {frameDescription}
                            {atletas.length == 1 ? (
                              <small style={{ paddingLeft: '20px', fontWeight: '500' }}>
                                ({atletas.length} {t('rest.box21')})
                              </small>
                            ) : (
                              <small style={{ paddingLeft: '20px', fontWeight: '500' }}>
                                ({atletas.length.toLocaleString('pt-BR')} {t('proj.box20')})
                              </small>
                            )}
                          </S.Title>
                          <IconButton
                            color="white"
                            onClick={() => toogleUpdateWorkflowPanel(index, frameName)}
                            icon={<FaEllipsisV />}
                            id={`updateWorkflow${index}`}
                            style={{ boxShadow: 'none' }}
                          />
                        </S.ColumnHeader>
                        <S.ColumnBody>
                          <Droppable droppableId={frameName}>
                            {provided => (
                              <div {...provided.droppableProps} ref={provided.innerRef}>
                                {atletas.map((athlete, index) => (
                                  <Draggable
                                    key={athlete.id}
                                    draggableId={athlete.id.toString()}
                                    index={index}
                                    isDragDisabled={deleting}
                                  >
                                    {provided => (
                                      <div
                                        style={
                                          deleting ? { opacity: '0.7', cursor: 'wait', pointerEvents: 'none' } : {}
                                        }
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <AthleteCard
                                          item={athlete}
                                          deleteAthlete={
                                            deleting
                                              ? undefined
                                              : () => setRemoveAthleteData({ id: athlete.id, atletas, frameName })
                                          }
                                          style={{ marginTop: '10px', marginBottom: '0' }}
                                          title={
                                            athlete.transfer_market_negotiation_id == null
                                              ? undefined
                                              : t('myClub.negotiations.originTransferMarket')
                                          }
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                                <div style={{ height: '15px' }}></div>
                              </div>
                            )}
                          </Droppable>
                        </S.ColumnBody>
                        <S.ColumnLink>
                          <Button
                            color="link"
                            noShadow
                            onClick={enableAddAthlete ? () => openFilter(frameName) : undefined}
                            style={enableAddAthlete ? {} : { color: '#b6b6b6', cursor: 'default' }}
                          >
                            {t('proj.text1')}
                          </Button>
                        </S.ColumnLink>
                      </div>
                    );
                  })}
                <div
                  style={{
                    minWidth: '200px',
                    height: '53px',
                    paddingTop: '30px',
                  }}
                  align="center"
                >
                  <Button
                    color="link"
                    noShadow
                    onClick={() => {
                      openEditFrame({ isOpen: true, frame: '', description: '', saving: false });
                    }}
                    style={enableAddAthlete ? {} : { color: '#b6b6b6', cursor: 'default' }}
                  >
                    {t('proj.text2')}
                  </Button>
                </div>
              </S.ColumnsContainer>
            </DragDropContext>
          </div>
        </>
      )}
      <Dialog
        isOpen={removeAthleteData != null}
        onClose={() => {
          setRemoveAthleteData(null);
        }}
        mainTitle={t('proj.removeAthlete')}
        btnLeft={t('dialog_content.cancel')}
        btnLeftClick={() => {
          setRemoveAthleteData(null);
        }}
        btnRight={t('buttons.excluir')}
        btnRightClick={async () => {
          const negotiation = updateAthletesWorkflow.find(aw => aw.athlete.id === removeAthleteData.id);
          setRemoveAthleteData(null);
          setDeleting(true);
          try {
            const result = await deleteNegotiation(agencyId, negotiation.id);
            if (result?.error === undefined) {
              dispatch(getAgencyNegotiations(agencyId, queryFilters));
            }
          } finally {
            setDeleting(false);
          }
        }}
      >
        <span style={{ marginBottom: '10px', marginLeft: '5px' }}>{t('proj.removeAthleteMessage')}</span>
      </Dialog>
    </S.Card>
  );
}

export default withRouter(Kanban);
