/*eslint-disable eqeqeq*/
import React, { useState } from 'react';
import axios from 'api';
import styles from './List.module.scss';
import Switch from 'react-switch';

export default function MonitoringTransferWindowsSwitch({ row }) {
    const [checked, setChecked] = useState(row.monitoring);

    return (
        <div style={{ textAlign: 'center' }}>
            <Switch
                checked={checked}
                onChange={newChecked => {
                    setChecked(newChecked);
                    axios.patch(`/transfer_windows/${row.id}`, { monitoring: newChecked }).catch(e => {
                        setChecked(!checked);
                    });
                }}
                className={styles.switch}
                height={20}
                width={40}
                onColor="#5A00FF"
                offColor="#ffffff"
                offHandleColor="#5A00FF"
                onHandleColor="#ffffff"
                checkedIcon={false}
                uncheckedIcon={false}
            />
        </div>
    )
}
