import React, { useEffect, useState, useRef } from 'react';
import styles from './MyNegotiations.module.scss';
import { withRouter, useLocation } from 'react-router-dom';
import SideBar from './SideBar/SideBar';
import Chat from './Chat/Chat';
import { useSelector, useDispatch } from 'react-redux';
import { NegotiationRepository } from 'data/negotiations';
import { useTranslation } from 'react-i18next';
import { Creators as notificationsCreators } from 'store/ducks/notifications';

function MyNegotiations() {
  const dispatch = useDispatch();
  const [chats, setChats] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedOtherChat, setSelectedOtherChat] = useState(null);
  const [otherChatActive, setOtherChatActive] = useState(null);
  const loggedUser = useSelector(state => state.user.data);
  const notifications = useSelector(state => state.notifications);
  const updateNotificationsRef = useRef(false);
  const lastFilterRef = useRef({});
  const { t } = useTranslation();
  const location = useLocation();

  async function fetchData(filter = {}) {
    lastFilterRef.current = filter;
    const data = await NegotiationRepository.list(filter);
    setChats(data?.results ?? []);
  }
  
  useEffect(() => {
    if (!notifications.update) {
      return;
    }

    if (!updateNotificationsRef.current) {
      //ignora o primeiro update, afinal já está na tela de mensagens
      updateNotificationsRef.current = true;
      return;
    }

    NegotiationRepository.list({ ...lastFilterRef.current, load_notifications: 'true' }).then(response => {
      if (response?.results) {
        dispatch(notificationsCreators.setNotification({
          newAthletechatCount: response.newAthletechatCount,
          newMessagesCount: response.newMessagesCount,
          newOrgchatCount: response.newOrgchatCount,
          newTeamchatCount: response.newTeamchatCount,
          newTransferMarketCount: response.newTransferMarketCount,
          newTransferMarketMessages: response.newTransferMarketMessages,
        }));
        setChats(response.results);
      }
    });
  }, [notifications]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData();
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    const count = selectedChat?.new_messages_count ?? 0;
    if (count > 0) {
      dispatch(notificationsCreators.updateTransferMarketCount(-count));
      setSelectedChat({ ...selectedChat, new_messages_count: 0 });
    }
  }, [selectedChat]); // eslint-disable-line react-hooks/exhaustive-deps  

  const negotiation_id = location?.state?.negotiation_id;
  useEffect(() => {
    if (negotiation_id && chats && chats.length > 0) {
      setSelectedChat(chats.find(i => i.id === negotiation_id));
    }
  }, [chats, negotiation_id]);

  const otherChatClick = () => {
    setOtherChatActive(true);
  };
  const otherChatClose = () => {
    setOtherChatActive(false);
    setSelectedOtherChat(null);
  };
  const onFilterChange = filterData => {
    fetchData({
      ...(filterData.query ? { messages: filterData.query } : {}),
      ...(filterData.transferType ? { negotiation_type: filterData.transferType } : {}),
      ...(filterData.athlete ? { athlete: filterData.athlete } : {}),
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.panel}>
        <SideBar
          chats={chats}
          selectedChat={selectedChat}
          setSelectedChat={setSelectedChat}
          selectedOtherChat={selectedOtherChat}
          setSelectedOtherChat={setSelectedOtherChat}
          otherChatActive={otherChatActive}
          otherChatClose={otherChatClose}
          onFilterChange={onFilterChange}
          user={loggedUser}
        ></SideBar>
        {(selectedOtherChat ?? selectedChat) == null ? (
          <div className={styles.selectChat}>
            <span>{t('negotiations.selectChat')}</span>
          </div>
        ) : (
          <Chat
            selectedChat={selectedOtherChat ?? selectedChat}
            user={loggedUser}
            otherChatClick={otherChatClick}
            onAthleteFilterChange={onFilterChange}
          ></Chat>
        )}
      </div>
    </div>
  );
}

export default withRouter(MyNegotiations);
