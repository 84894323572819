import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAgency } from 'store/ducks/agencies';
import { Card, Spinner } from 'components/template';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import { useTranslation } from 'react-i18next';
import styles from './MyAgency.module.scss';
import TopBar from './TopBar/TopBar';
import General from './General/General';
import Contracts from './Contracts/Contracts';
import MyAthletes from './MyAthletes/MyAthletes';
import AthletesProfile from './AthletesProfile/AthletesProfile';
import Negotiations from './Negotiations/Negotiations';
import Users from './Users/Users';

function MyAgency({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loggedUser = useSelector(state => state.user.data);
  const agency = useSelector(state => state.agencies.instance);
  const { id } = loggedUser?.organization?.id;

  useEffect(() => {
    dispatch(getAgency(id));
    const body = document.querySelector('body');
    body.classList.add('lightBg');
  }, [dispatch, match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const baseURL = `/minha-agencia`;

  const items = [
    {
      label: t('myClub.general.title'),
      link: `${baseURL}`,
      component: <General agency={agency?.data} agencyId={id} />,
    },
  ];

  const isGlobalAgency = agency?.data?.agency_subtype === 'global';
  if (isGlobalAgency) {
    items.push(
      {
        label: t('myAgency.my_athletes'),
        link: `${baseURL}/meus-atletas`,
        component: <MyAthletes agencyId={id} />,
      },
      {
        label: t('myAgency.athletes_profile'),
        link: `${baseURL}/perfil-atletas`,
        component: <AthletesProfile agencyId={id} />,
      },
      {
        label: t('myClub.contracts.pageTitle'),
        link: `${baseURL}/contratos`,
        component: <Contracts agencyId={id} />,
      },
      {
        label: t('myClub.negotiations.negotiations'),
        link: `${baseURL}/negociacoes`,
        component: <Negotiations agencyId={id} />,
      },
      {
        label: t('myClub.users.title'),
        link: `${baseURL}/usuarios`,
        component: <Users agencyId={id} />,
      },
    );
  }

  const routes = items.map(item => <Route key={item.label} exact path={item.link} render={() => item.component} />);

  let content = null;
  if (agency.loading) {
    content = (
      <div className={styles.loaderContainer}>
        <Spinner />
      </div>
    );
  } else if (agency.error) {
    content = (
      <Card className={styles.errorContainer}>
        <h2>{t(agency?.error)}</h2>
      </Card>
    );
  } else {
    content = (
      <>
        <TopBar agency={agency?.data} />
        <div className={styles.content}>
          {isGlobalAgency ? <NavigationBar items={items} /> : <div className={styles.notGlobalAgency} />}
          <Switch>
            {routes}
            {/* elenco redirect until overview is completed */} <Redirect to={items[0].link} />
            <Redirect to={baseURL} />
          </Switch>
        </div>
      </>
    );
  }

  return <div className={styles.container}>{content}</div>;
}

MyAgency.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(MyAgency);
