/*eslint-disable eqeqeq*/
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import phoneStyles from './phoneStyles.module.scss';
import Form from '../Form';
import { Input, Avatar, Button, Menu } from 'components/template';
import FormGroup from '../Form/FormGroup';
import Aside from '../Aside';
import { Formik } from 'formik';
import { getCountries } from 'store/ducks/countries';
import { updateUser, updateUserImage } from 'store/ducks/user';
import * as FComponent from '../Form/styles';
import * as S from './styles';
import styles from './styles.module.scss';
import CenteredLoader from 'components/template/CenteredLoader';
import { getUnique } from 'shared/utility';
import { useTranslation } from 'react-i18next';

// import * as FGComponent from '../Form/FormGroup/styles';
// import Form from './Form/Form'

const UserData = () => {
  const userSelector = useSelector(state => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = userSelector.profile.data;

  const countries = useSelector(state => ({
    data: getUnique(state.countries.countries.data, 'ddi').filter(country => country.ddi !== ''),
    loading: state.countries.countries.loading,
    error: state.countries.countries.error,
  }));

  useEffect(() => {
    if (countries.data.length == 0) {
      dispatch(getCountries());
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  if (user == null) {
    return '';
  }

  const onUploadFile = file => {
    dispatch(updateUserImage(user.id, file));
  };

  // const organizationSchema = yup.object().shape({
  //     // emblem: yup.string(),
  //     fullname: yup.string(),
  //     nickname: yup.string(),
  //     website: yup.string().url(),
  //     twitter: yup.string().url(),
  //     youtube: yup.string().url(),
  //     linkedin: yup.string().url(),
  //     facebook: yup.string().url(),
  //     instagram: yup.string().url(),
  //     email: yup.string().email(),
  //     stadium: yup.string(),
  //     foundation_date: yup.string(),
  //     member_number: yup.number(),
  //     phone1ddi: yup.string(),
  //     phone1ddd: yup.number(),
  //     phone1number: yup.number(),
  //     phone2ddi: yup.string(),
  //     phone2ddd: yup.number(),
  //     phone2number: yup.number(),
  //     whatsappddi: yup.string(),
  //     whatsappddd: yup.number(),
  //     whatsappnumber: yup.number(),
  //     city: yup.string(),
  //     state: yup.string(),
  //     country: yup.string(),
  //     zip_code: yup.number(),
  //     full_address: yup.string()
  // })

  const validar = values => {
    const phone1 = values?.profile?.phones?.phone1;
    const whatsapp = values?.profile?.phones?.whatsapp;
    const maxCharactersNumber = 9;
    const maxCharactersDDD = 3;
    if (
      (phone1.ddd ?? '').toString().length <= maxCharactersDDD &&
      (phone1.number ?? '').toString().length <= maxCharactersNumber &&
      (whatsapp.ddd ?? '').toString().length <= maxCharactersDDD &&
      (whatsapp.number ?? '').toString().length <= maxCharactersNumber
    ) {
      return values;
    } else {
      return null;
    }
  };

  return countries.loading ? (
    <CenteredLoader />
  ) : (
    <Formik
      initialValues={{
        name: user.name,
        email: user.email,
        profile: {
          occupation: user.profile.occupation,
          phones: {
            phone1: user.profile.phones.phone1,
            whatsapp: user.profile.phones.whatsapp,
          },
        },
      }}
      // validationSchema={organizationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const newValues = validar({ ...values });
        const phone1 = values?.profile?.phones?.phone1;
        if (phone1 != null && phone1.ddd != null && phone1.number != null && phone1.ddi == null) {
          newValues.profile.phones.phone1.ddi = countries.data.find(country => country.code === 'BR')?.code;
        }
        const whatsapp = values?.profile?.phones?.whatsapp;
        if (whatsapp != null && whatsapp.ddd != null && whatsapp.number != null && whatsapp.ddi == null) {
          newValues.profile.phones.whatsapp.ddi = countries.data.find(country => country.code === 'BR')?.code;
        }
        if (values?.profile != null && values.profile.occupation == null) {
          newValues.profile.occupation = '';
        }
        dispatch(updateUser(user.id, newValues));
      }}
    >
      {({ values, handleChange, setValues, dirty }) => (
        <S.FormElement>
          <Aside>
            <S.AvatarContainer>
              <Avatar noBorder img={user.profile.photo} name={user.name} className={styles.avatar} />
              <S.AvatarUploadContainer>
                <S.AvatarButtonTitle>{t('organi.box2')}</S.AvatarButtonTitle>
                <Input
                  onChange={e => onUploadFile(e.target.files[0])}
                  rounded
                  smaller
                  type="file"
                  inputClassName={styles.avatarInput}
                  containerClassName={styles.avatarLabel}
                  label={t('organi.box1')}
                />
              </S.AvatarUploadContainer>
            </S.AvatarContainer>
            <Input
              onChange={handleChange}
              type="text"
              profileInput
              name="name"
              placeholder=""
              label={t('athlete_card.fullname')}
              rounded
              value={values.name}
            />
            <Input
              onChange={handleChange}
              type="text"
              profileInput
              name="email"
              placeholder=""
              label="E-mail"
              rounded
              value={values.email}
            />
            <Input
              onChange={handleChange}
              type="text"
              profileInput
              name="profile.occupation"
              placeholder=""
              label={t('contact_card.role')}
              rounded
              value={values.profile.occupation}
            />
          </Aside>
          <Form>
            <S.ContentContainer>
              <FormGroup title={t('agencies.phones')}>
                <FComponent.UnifiedField>
                  <div className={phoneStyles.telephone}>
                    <div>
                      <Menu
                        rounded
                        className={phoneStyles.ddi}
                        labelClassName={phoneStyles.ddiLabel}
                        options={countries.data.map(country => ({
                          label: (
                            <>
                              <S.CountryFlag src={country.flag} />
                              <span className={styles.spanFlag}>{country.code}</span>
                            </>
                          ),
                          value: country.ddi,
                        }))}
                        placeholder={
                          <>
                            <S.CountryFlag
                              src={
                                countries.data.find(country => country.ddi === values.profile.phones.phone1.ddi)
                                  ?.flag || countries.data.find(country => country.code === 'BR')?.flag
                              }
                            />
                            <span className={styles.spanFlag}>
                              {countries.data.find(country => country.ddi === values.profile.phones.phone1.ddi)?.code ||
                                countries.data.find(country => country.code === 'BR')?.code}
                            </span>
                          </>
                        }
                        setValue={value =>
                          setValues({
                            ...values,
                            profile: {
                              ...values.profile,
                              phones: {
                                ...values.profile.phones,
                                phone1: {
                                  ...values.profile.phones.phone1,
                                  ddi: value,
                                },
                              },
                            },
                          })
                        }
                        name="profile.phones.phone1.ddi"
                        label={t('organi.box5')}
                        value={
                          <>
                            <span className={styles.spanFlag}>
                              {countries.data.find(country => country.ddi === values.profile.phones.phone1.ddi)?.code ||
                                countries.data.find(country => country.code === 'BR')?.code}
                            </span>
                          </>
                        }
                      />
                    </div>
                    <div className={phoneStyles.number}>
                      <Input
                        onChange={handleChange}
                        type="number"
                        name="profile.phones.phone1.ddd"
                        placeholder="00"
                        required={values.profile.phones.phone1.number != null}
                        rounded
                        containerClassName={phoneStyles.ddd}
                        value={values.profile.phones.phone1.ddd}
                      />
                      <Input
                        onChange={handleChange}
                        type="number"
                        name="profile.phones.phone1.number"
                        placeholder="000000000"
                        rounded
                        containerClassName={phoneStyles.phone}
                        value={values.profile.phones.phone1.number}
                      />
                    </div>
                  </div>
                </FComponent.UnifiedField>
                <FComponent.UnifiedField>
                  <div className={phoneStyles.telephone}>
                    <div>
                      <Menu
                        rounded
                        className={phoneStyles.ddi}
                        labelClassName={phoneStyles.ddiLabel}
                        options={countries.data.map(country => ({
                          label: (
                            <>
                              <S.CountryFlag src={country.flag} />
                              <span className={styles.spanFlag}>{country.code}</span>
                            </>
                          ),
                          value: country.ddi,
                        }))}
                        placeholder={
                          <>
                            <S.CountryFlag
                              src={
                                countries.data.find(country => country.ddi === values.profile.phones.whatsapp.ddi)
                                  ?.flag || countries.data.find(country => country.code === 'BR')?.flag
                              }
                            />
                            <span className={styles.spanFlag}>
                              {countries.data.find(country => country.ddi === values.profile.phones.whatsapp.ddi)
                                ?.code || countries.data.find(country => country.code === 'BR')?.code}
                            </span>
                          </>
                        }
                        setValue={value =>
                          setValues({
                            ...values,
                            profile: {
                              ...values.profile,
                              phones: {
                                ...values.profile.phones,
                                whatsapp: {
                                  ...values.profile.phones.whatsapp,
                                  ddi: value,
                                },
                              },
                            },
                          })
                        }
                        name="profile.phones.whatsapp.ddi"
                        label="Whatsapp"
                        //value={values.profile.phones.whatsapp.ddi}
                        value={
                          <>
                            <span className={styles.spanFlag}>
                              {countries.data.find(country => country.ddi === values.profile.phones.whatsapp.ddi)
                                ?.code || countries.data.find(country => country.code === 'BR')?.code}
                            </span>
                          </>
                        }
                      />
                    </div>
                    <div className={phoneStyles.number}>
                      <Input
                        onChange={handleChange}
                        type="number"
                        name="profile.phones.whatsapp.ddd"
                        placeholder="00"
                        required={values.profile.phones.whatsapp.number != null}
                        rounded
                        containerClassName={phoneStyles.ddd}
                        // defaultValue={organization.phones.whatsapp.ddd}
                        value={values.profile.phones.whatsapp.ddd}
                      />
                      <Input
                        onChange={handleChange}
                        type="number"
                        name="profile.phones.whatsapp.number"
                        placeholder="000000000"
                        rounded
                        containerClassName={phoneStyles.phone}
                        value={values.profile.phones.whatsapp.number}
                      />
                    </div>
                  </div>
                </FComponent.UnifiedField>
              </FormGroup>
            </S.ContentContainer>
            {userSelector.profile.error && <span className={styles.labelError}>{t('contact_card.error')}</span>}
            <Button className={styles.updateButton} rounded color="green" smaller type="submit">
              {t('organi.box11')}
            </Button>
          </Form>
        </S.FormElement>
      )}
    </Formik>
  );
};

export default UserData;
