import React from 'react';
import styles from './SideBar.module.scss';
import Header from './Header/Header';
import ChatItem from './ChatItem/ChatItem';
import { Spinner } from 'components/template';

export default function SideBar({
  chats,
  setSelectedChat,
  selectedChat,
  setSelectedOtherChat,
  selectedOtherChat,
  otherChatActive,
  otherChatClose,
  user,
  onFilterChange = () => {},
}) {
  const handleSelectChat = chat_id => {
    setSelectedChat(chats.find(i => i.id === chat_id));
  };

  const handleSelectOtherChat = chat_id => {
    setSelectedOtherChat({
      ...selectedChat,
      ...chats.find(i => i.id === selectedChat.id).oldChats.find(i => i.id === chat_id),
    });
  };

  return (
    <div className={styles.container}>
      <Header
        otherChatActive={otherChatActive}
        otherChatClose={otherChatClose}
        chat={selectedChat}
        onFilterChange={onFilterChange}
      ></Header>
      <div className={styles.content}>
        {chats == null ? (
          <Spinner width="40" height="40" className={styles.spinner} />
        ) : (
          <>
            {!otherChatActive &&
              chats?.map(chat => (
                <ChatItem
                  key={chat.id}
                  id={chat.id}
                  user={user}
                  chat={chat}
                  date={chat.last_message ?? chat.created_at}
                  alertCount={chat.new_messages_count ?? '?'}
                  isSelected={chat.id === selectedChat?.id}
                  onClick={handleSelectChat}
                ></ChatItem>
              ))}
            {!!otherChatActive &&
              !!selectedChat &&
              selectedChat.oldChats.map(chat => (
                <ChatItem
                  key={chat.id}
                  id={chat.id}
                  user={user}
                  chat={chat}
                  date={chat.lastUpdate}
                  alertCount={chat.unreadMessages ?? '?'}
                  isSelected={chat.id === selectedOtherChat?.id}
                  onClick={handleSelectOtherChat}
                ></ChatItem>
              ))}
          </>
        )}
      </div>
    </div>
  );
}
