import React, { useState } from 'react';

export default function ToolTip({ message, children }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovered && (
        <div
          style={{
            position: 'absolute',
            top: '55px',
            left: '50%',
            height: '10px',
            minWidth: '150px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transform: 'translateX(-50%)',
            backgroundColor: '#120033',
            color: 'white',
            fontSize: '14px',
            fontWeight: '600',
            padding: '8px',
            borderRadius: '8px',
            zIndex: '9999',
          }}
        >
          <div
            style={{
              position: 'absolute',
              height: '10px',
              width: '10px',
              left: '44%',
              bottom: '80%',
              backgroundColor: '#120033',
              transform: 'rotate(45deg)',
            }}
          />
          {message}
        </div>
      )}
      {children}
    </div>
  );
}
