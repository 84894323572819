import { contacts, videos, news } from 'shared/mocks';
import Endpoints from 'endpoints';
import axios from 'api';
import logger from 'shared/Logger';
import ErrorMessages from 'shared/ErrorMessages';
import { stringifyFilters } from 'shared/utility';

export const actionTypes = {
  // club
  RESET: 'club/RESET',
  GET: 'club/GET',
  GET_SUCCESS: 'club/GET_SUCCESS',
  GET_FAILED: 'club/GET_FAILED',
  GET_ERROR_HANDLED: 'club/GET_ERROR_HANDLED',

  // professional cast
  GET_PROFESSIONAL_CAST: 'club/GET_PROFESSIONAL_CAST',
  GET_PROFESSIONAL_CAST_SUCCESS: 'club/GET_PROFESSIONAL_CAST_SUCCESS',
  GET_PROFESSIONAL_CAST_FAILED: 'club/GET_PROFESSIONAL_CAST_FAILED',
  GET_PROFESSIONAL_CAST_ERROR_HANDLED: 'club/GET_PROFESSIONAL_CAST_ERROR_HANDLED',
  RESET_PROFESSIONAL_CAST: 'club/RESET_PROFESSIONAL_CAST',
  UPDATE_PROFESSIONAL_CAST_LISTS: 'club/UPDATE_PROFESSIONAL_CAST_LISTS',

  // loaned
  GET_LOANED: 'club/GET_LOANED',
  GET_LOANED_SUCCESS: 'club/GET_LOANED_SUCCESS',
  GET_LOANED_FAILED: 'club/GET_LOANED_FAILED',
  GET_LOANED_ERROR_HANDLED: 'club/GET_LOANED_ERROR_HANDLED',
  RESET_LOANED: 'club/RESET_LOANED',
  UPDATE_LOANED_LISTS: 'club/UPDATE_LOANED_LISTS',

  // amateur
  GET_AMATEUR: 'club/GET_AMATEUR',
  GET_AMATEUR_SUCCESS: 'club/GET_AMATEUR_SUCCESS',
  GET_AMATEUR_FAILED: 'club/GET_AMATEUR_FAILED',

  // female
  GET_FEMALE: 'club/GET_FEMALE',
  GET_FEMALE_SUCCESS: 'club/GET_FEMALE_SUCCESS',
  GET_FEMALE_FAILED: 'club/GET_FEMALE_FAILED',

  // evaluations
  GET_EVALUATIONS: 'club/GET_EVALUATIONS',
  GET_EVALUATIONS_SUCCESS: 'club/GET_EVALUATIONS_SUCCESS',
  GET_EVALUATIONS_FAILED: 'club/GET_EVALUATIONS_FAILED',
  GET_EVALUATIONS_ERROR_HANDLED: 'club/GET_EVALUATIONS_ERROR_HANDLED',
  RESET_EVALUATIONS: 'club/RESET_EVALUATIONS',

  // contacts
  GET_CONTACTS: 'club/GET_CONTACTS',
  GET_CONTACTS_SUCCESS: 'club/GET_CONTACTS_SUCCESS',
  GET_CONTACTS_FAILED: 'club/GET_CONTACTS_FAILED',
  GET_CONTACTS_ERROR_HANDLED: 'club/GET_CONTACTS_ERROR_HANDLED',
  RESET_CONTACTS: 'club/RESET_CONTACTS',

  // videos
  GET_VIDEOS: 'club/GET_VIDEOS',
  GET_VIDEOS_SUCCESS: 'club/GET_VIDEOS_SUCCESS',
  GET_VIDEOS_FAILED: 'club/GET_VIDEOS_FAILED',
  GET_VIDEOS_ERROR_HANDLED: 'club/GET_VIDEOS_ERROR_HANDLED',
  RESET_VIDEOS: 'club/RESET_VIDEOS',

  // news
  GET_NEWS: 'club/GET_NEWS',
  GET_NEWS_SUCCESS: 'club/GET_NEWS_SUCCESS',
  GET_NEWS_FAILED: 'club/GET_NEWS_FAILED',
  GET_NEWS_ERROR_HANDLED: 'club/GET_NEWS_ERROR_HANDLED',
  RESET_NEWS: 'club/RESET_NEWS',

  // evaluation fields
  GET_EVALUATION_FIELDS: 'club/GET_EVALUATION_FIELDS',
  GET_EVALUATION_FIELDS_SUCCESS: 'club/GET_EVALUATION_FIELDS_SUCCESS',
  GET_EVALUATION_FIELDS_FAILED: 'club/GET_EVALUATION_FIELDS_FAILED',
  GET_EVALUATION_FIELDS_ERROR_HANDLED: 'club/GET_EVALUATION_FIELDS_ERROR_HANDLED',
  RESET_EVALUATION_FIELDS_DATA: 'club/RESET_EVALUATION_FIELDS_DATA',
};

const initialState = {
  clubPage: {
    loading: false,
    error: null,
    hasNext: false,
    hasPrev: false,
    data: {
      shortName: '',
      fullName: '',
      img: '',
      city: '',
      state: '',
      country: {
        name: '',
        flag: '',
      },
      marketValue: '',
      marketValueSource: '',
    },
  },
  professionalCastPage: {
    loading: false,
    error: null,
    hasNext: false,
    hasPrevious: false,
    data: {
      count: 0,
      limit: null,
      offset: 0,
      athletes: [],
    },
  },
  loanedPage: {
    loading: false,
    error: null,
    hasNext: false,
    hasPrevious: false,
    data: {
      count: 0,
      limit: null,
      offset: 0,
      athletes: [],
    },
  },
  amateurPage: {
    loading: false,
    error: null,
    // hasNext: false,
    // hasPrevious: false,
    data: [],
  },
  femalePage: {
    loading: false,
    error: null,
    // hasNext: false,
    // hasPrevious: false,
    data: [],
  },
  evaluationsList: {
    loading: false,
    error: null,
    data: {
      performed: [],
    },
  },
  contactsPage: {
    loading: false,
    error: null,
    data: {
      teamId: null,
      data: [],
    },
  },
  videosPage: {
    loading: false,
    error: null,
    data: {
      teamId: null,
      data: [],
    },
  },
  newsPage: {
    loading: false,
    error: null,
    data: {
      teamId: null,
      data: [],
    },
  },
  evaluationFields: {
    loading: false,
    error: null,
    data: [],
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.RESET:
      return initialState;
    // CLUB
    case actionTypes.GET:
      return { ...state, clubPage: { ...state.clubPage, loading: true } };
    case actionTypes.GET_SUCCESS:
      return {
        ...state,
        clubPage: { ...state.clubPage, loading: false, data: action.payload.data },
      };
    case actionTypes.GET_FAILED:
      return {
        ...state,
        clubPage: { ...state.clubPage, loading: false, error: action.payload.error },
      };
    case actionTypes.GET_ERROR_HANDLED:
      return { ...state, clubPage: { ...state.clubPage, error: null } };
    // PROFESSIONAL CAST
    case actionTypes.GET_PROFESSIONAL_CAST:
      return { ...state, professionalCastPage: { ...state.professionalCastPage, loading: true } };
    case actionTypes.GET_PROFESSIONAL_CAST_SUCCESS:
      return {
        ...state,
        professionalCastPage: {
          ...state.professionalCastPage,
          hasNext: action.payload.data.hasNext,
          hasPrevious: action.payload.data.hasPrevious,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_PROFESSIONAL_CAST_FAILED:
      return {
        ...state,
        professionalCastPage: {
          ...state.professionalCastPage,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_PROFESSIONAL_CAST_ERROR_HANDLED:
      return { ...state, professionalCastPage: { ...state.professionalCastPage, error: null } };
    case actionTypes.RESET_PROFESSIONAL_CAST:
      return { ...state, professionalCastPage: initialState.professionalCastPage };
    case actionTypes.UPDATE_PROFESSIONAL_CAST_LISTS:
      return {
        ...state,
        professionalCastPage: {
          ...state.professionalCastPage,
          data: {
            ...state.data,
            athletes: state.professionalCastPage.data.athletes.map(athlete => {
              if (athlete.id === action.payload.athleteId) {
                return {
                  ...athlete,
                  lists: [...action.payload.lists],
                  counts: { ...athlete.counts, lists: action.payload.lists.length },
                };
              } else {
                return athlete;
              }
            }),
          },
        },
      };
    // LOANED
    case actionTypes.GET_LOANED:
      return { ...state, loanedPage: { ...state.loanedPage, loading: true } };
    case actionTypes.GET_LOANED_SUCCESS:
      return {
        ...state,
        loanedPage: {
          ...state.loanedPage,
          hasNext: action.payload.data.hasNext,
          hasPrevious: action.payload.data.hasPrevious,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_LOANED_FAILED:
      return {
        ...state,
        loanedPage: {
          ...state.loanedPage,
          hasNext: action.payload.data.hasNext,
          hasPrevious: action.payload.data.hasPrevious,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_LOANED_ERROR_HANDLED:
      return { ...state, loanedPage: { ...state.loanedPage, error: null } };
    case actionTypes.RESET_LOANED:
      return { ...state, loanedPage: initialState.loanedPage };
    case actionTypes.UPDATE_LOANED_LISTS:
      return {
        ...state,
        loanedPage: {
          ...state.loanedPage,
          data: {
            ...state.data,
            athletes: state.loanedPage.data.athletes.map(athlete => {
              if (athlete.id === action.payload.athleteId) {
                return {
                  ...athlete,
                  lists: [...action.payload.lists],
                  counts: { ...athlete.counts, lists: action.payload.lists.length },
                };
              } else {
                return athlete;
              }
            }),
          },
        },
      };
    // AMATEUR
    case actionTypes.GET_AMATEUR:
      return {
        ...state,
        amateurPage: {
          error: null,
          loading: true,
          data: [],
        },
      };
    case actionTypes.GET_AMATEUR_SUCCESS:
      return {
        ...state,
        amateurPage: {
          error: null,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_AMATEUR_FAILED:
      return {
        ...state,
        amateurPage: {
          error: actionTypes.payload.error,
          loading: false,
          data: [],
        },
      };
    // FEMALE
    case actionTypes.GET_FEMALE:
      return {
        ...state,
        femalePage: {
          error: null,
          loading: true,
          data: [],
        },
      };
    case actionTypes.GET_FEMALE_SUCCESS:
      return {
        ...state,
        femalePage: {
          error: null,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_FEMALE_FAILED:
      return {
        ...state,
        femalePage: {
          error: actionTypes.payload.error,
          loading: false,
          data: [],
        },
      };
    // EVALUATIONS
    case actionTypes.GET_EVALUATIONS:
      return { ...state, evaluationsList: { ...state.evaluationsList, loading: true } };
    case actionTypes.GET_EVALUATIONS_SUCCESS:
      return {
        ...state,
        clubPage: {
          ...state.clubPage,
          observationsAmount: action.payload.observationsAmount,
        },
        evaluationsList: {
          ...state.evaluationsList,
          loading: false,
          data: {
            performed: action.payload.performed,
          },
        },
      };
    case actionTypes.GET_EVALUATIONS_FAILED:
      return {
        ...state,
        evaluationsList: {
          ...state.evaluationsList,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_EVALUATIONS_ERROR_HANDLED:
      return { ...state, evaluationsList: { ...state.evaluationsList, error: null } };
    case actionTypes.RESET_EVALUATIONS:
      return { ...state, evaluationsList: initialState.evaluationsList };
    // CONTACTS
    case actionTypes.GET_CONTACTS:
      return { ...state, contactsPage: { ...state.contactsPage, loading: true } };
    case actionTypes.GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contactsPage: {
          ...state.contactsPage,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_CONTACTS_FAILED:
      return {
        ...state,
        contactsPage: {
          ...state.contactsPage,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_CONTACTS_ERROR_HANDLED:
      return { ...state, contactsPage: { ...state.contactsPage, error: null } };
    case actionTypes.RESET_CONTACTS:
      return { ...state, contactsPage: initialState.contactsPage };
    // VIDEOS
    case actionTypes.GET_VIDEOS:
      return { ...state, videosPage: { ...state.videosPage, loading: true } };
    case actionTypes.GET_VIDEOS_SUCCESS:
      return {
        ...state,
        videosPage: {
          ...state.videosPage,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_VIDEOS_FAILED:
      return {
        ...state,
        videosPage: {
          ...state.videosPage,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_VIDEOS_ERROR_HANDLED:
      return { ...state, videosPage: { ...state.videosPage, error: null } };
    case actionTypes.RESET_VIDEOS:
      return { ...state, videosPage: initialState.videosPage };
    // news
    case actionTypes.GET_NEWS:
      return { ...state, newsPage: { ...state.newsPage, loading: true } };
    case actionTypes.GET_NEWS_SUCCESS:
      return {
        ...state,
        newsPage: {
          ...state.newsPage,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_NEWS_FAILED:
      return {
        ...state,
        newsPage: {
          ...state.newsPage,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_NEWS_ERROR_HANDLED:
      return { ...state, newsPage: { ...state.newsPage, error: null } };
    case actionTypes.RESET_NEWS:
      return { ...state, newsPage: initialState.newsPage };
    // EvaluationFields
    case actionTypes.GET_EVALUATION_FIELDS:
      return {
        ...state,
        evaluationFields: { ...state.evaluationFields, loading: true },
      };
    case actionTypes.GET_EVALUATION_FIELDS_SUCCESS:
      return {
        ...state,
        evaluationFields: {
          ...state.evaluationFields,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_EVALUATION_FIELDS_FAILED:
      return {
        ...state,
        evaluationFields: {
          ...state.evaluationFields,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_EVALUATION_FIELDS_ERROR_HANDLED:
      return {
        ...state,
        evaluationFields: { ...state.evaluationFields, error: null },
      };
    case actionTypes.RESET_EVALUATION_FIELDS_DATA:
      return {
        ...state,
        evaluationFields: { ...initialState.evaluationFields, data: [] },
      };

    // End EvaluationFields
    default:
      return state;
  }
}

export const Creators = {
  // club
  reset: () => ({ type: actionTypes.RESET }),
  getClub: id => async dispatch => {
    dispatch({ type: actionTypes.GET });
    try {
      const url = Endpoints.teams;
      const { data } = await axios.get(`${url}/${id}/`);
      dispatch({ type: actionTypes.GET_SUCCESS, payload: { data: data } });
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_FAILED,
          payload: { error: ErrorMessages.resourceNotFound('athlete_list.box2') },
        });
      } else if (response?.status === 401) {
        dispatch({
          type: actionTypes.GET_FAILED,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_FAILED,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getClubErrorHandled: () => ({ type: actionTypes.GET_ERROR_HANDLED }),
  // professional cast
  getProfessionalCast: (id, filters = {}) => async dispatch => {
    try {
      const queryString = stringifyFilters(filters);
      dispatch({ type: actionTypes.GET_PROFESSIONAL_CAST });
      let url = `${Endpoints.teams}/${id}`;
      if (filters.loaned) {
        url += '/on_loan_athletes';
      } else {
        url += '/athletes';
      }

      const { data } = await axios.get(`${url}${queryString}`);
      const athletes = data.results.map(athlete => ({
        id: athlete?.id,
        name: athlete?.fullname,
        nickname: athlete?.nickname,
        img: athlete?.photo,
        age: athlete?.age,
        position: athlete?.position ?? '-',
        nationalities: athlete?.nationalities,
        birthYear: athlete.birthyear,
        contract_info: athlete.contract_info,
        current_team: athlete.current_team,
        src_team: athlete.src_team,
        lists: athlete.lists,
        team: {
          id: athlete?.contract_info?.current_team?.id,
          emblem: athlete?.contract_info?.current_team?.emblem,
          name: athlete?.contract_info?.current_team?.nickname,
          endOfContract: athlete?.contract_info.current_contract_end,
        },
        srcTeam: {
          id: athlete?.contract_info?.src_team?.id,
          emblem: athlete?.contract_info?.src_team?.emblem,
          name: athlete?.contract_info?.src_team?.nickname,
          endOfContract: athlete?.contract_info?.src_contract_end,
        },
      }));
      dispatch({
        type: actionTypes.GET_PROFESSIONAL_CAST_SUCCESS,
        payload: {
          data: {
            count: data.count,
            hasNext: data.next ? true : false,
            hasPrevious: data.previous ? true : false,
            athletes,
          },
        },
      });
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response) {
        dispatch({
          type: actionTypes.GET_PROFESSIONAL_CAST_FAILED,
          payload: { error: 'erros.text1' },
        });
      } else {
        dispatch({
          type: actionTypes.GET_PROFESSIONAL_CAST_FAILED,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  resetProfessionalCast: () => ({ type: actionTypes.RESET_PROFESSIONAL_CAST }),
  // loaned
  getLoaned: (id, filters) => async dispatch => {
    try {
      let queryString = stringifyFilters(filters);

      dispatch({ type: actionTypes.GET_LOANED });
      const url = `${Endpoints.teams}/${id}/on_loan_athletes${queryString}`;

      const { data } = await axios.get(url);
      const athletes = data.results.map(athlete => ({
        id: athlete?.id,
        name: athlete?.fullname,
        nickname: athlete?.nickname ?? '',
        img: athlete?.photo,
        nationalities: athlete?.nationalities,
        age: athlete?.age,
        position: athlete?.position ?? '-',
        birthYear: athlete.birthyear,
        contract_info: athlete.contract_info,
        current_team: athlete.current_team,
        lists: athlete.lists,
        team: {
          emblem: athlete?.contract_info?.src_team?.emblem,
          name: athlete?.contract_info?.src_team?.nickname,
          endOfContract: athlete?.contract_info.src_contract_end,
        },
        loanedTeam: {
          id: athlete?.contract_info?.current_team?.id,
          emblem: athlete?.contract_info?.current_team?.emblem,
          name: athlete?.contract_info?.current_team?.nickname,
          endOfContract: athlete?.contract_info?.current_contract_end,
        },
      }));
      dispatch({
        type: actionTypes.GET_LOANED_SUCCESS,
        payload: {
          data: {
            count: data.count,
            hasNext: data.next ? true : false,
            hasPrevious: data.previous ? true : false,
            athletes,
          },
        },
      });
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response) {
        dispatch({
          type: actionTypes.GET_LOANED_FAILED,
          payload: { error: 'erros.text1' },
        });
      } else {
        dispatch({
          type: actionTypes.GET_LOANED_FAILED,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  resetLoaned: () => ({ type: actionTypes.RESET_LOANED }),
  // evaluations
  getEvaluations: (id, teamId) => async dispatch => {
    try {
      dispatch({ type: actionTypes.GET_EVALUATIONS });
      const url = Endpoints.organizations;
      const {
        data: { count, results },
      } = await axios.get(`${url}/${id}/evaluations/`);

      const filteredResults = teamId
        ? results.filter(result => result.team_data && Number(result.team_data.id) === Number(teamId))
        : results;
      const promises = [];

      filteredResults.forEach(result => {
        if (result?.team_data?.id && result?.target_data) {
          promises.push(
            new Promise(async (resolve, reject) => {
              try {
                resolve({
                  date: result?.evaluation_date ?? result?.created_at,
                  author: result?.author?.name,
                  generalEvaluation: result?.general_evaluation,
                  mode: result?.mode,
                  id: result?.id,
                  team: {
                    id: result?.team_data?.id,
                    emblem: result?.team_data?.emblem ?? '',
                    name: result?.team_data?.nickname,
                  },
                  athlete: {
                    id: result?.target_data?.id ?? '',
                    name: result?.target_data?.fullname ?? '',
                    nickname: result?.target_data?.nickname ?? '',
                    img: result?.target_data?.photo ?? '',
                  },
                });
              } catch (err) {
                reject(err);
              }
            })
          );
        }
      });

      const output = await Promise.all(promises);

      dispatch({
        type: actionTypes.GET_EVALUATIONS_SUCCESS,
        payload: {
          observationsAmount: count,
          performed: output,
        },
      });
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch({
          type: actionTypes.GET_EVALUATIONS_FAILED,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else if (err?.response?.status === 404) {
        dispatch({
          type: actionTypes.GET_EVALUATIONS_FAILED,
          payload: { error: 'erros.text1' },
        });
      } else {
        dispatch({
          type: actionTypes.GET_EVALUATIONS_FAILED,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  // amateur
  getAmateur: id => async dispatch => {
    const url = Endpoints.teams;

    try {
      dispatch({ type: actionTypes.GET_AMATEUR });

      const { data } = await axios.get(`${url}/${id}/athletes?professional=false&female=false&o=position_order`);

      dispatch({
        type: actionTypes.GET_AMATEUR_SUCCESS,
        payload: { data: data.results },
      });
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch({
          type: actionTypes.GET_AMATEUR_FAILED,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else if (err?.response?.status === 404) {
        dispatch({
          type: actionTypes.GET_AMATEUR_FAILED,
          payload: { error: 'erros.text1' },
        });
      } else {
        dispatch({
          type: actionTypes.GET_AMATEUR_FAILED,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  // female
  getFemale: id => async dispatch => {
    const url = Endpoints.teams;

    try {
      dispatch({ type: actionTypes.GET_FEMALE });

      const { data } = await axios.get(`${url}/${id}/athletes?female=true&o=position_order`);

      dispatch({
        type: actionTypes.GET_FEMALE_SUCCESS,
        payload: { data: data.results },
      });
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch({
          type: actionTypes.GET_FEMALE_FAILED,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else if (err?.response?.status === 404) {
        dispatch({
          type: actionTypes.GET_FEMALE_FAILED,
          payload: { error: 'erros.text1' },
        });
      } else {
        dispatch({
          type: actionTypes.GET_FEMALE_FAILED,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  resetEvaluations: () => ({ type: actionTypes.RESET_EVALUATIONS }),
  // contacts
  getContacts: id => dispatch => {
    dispatch({ type: actionTypes.GET_CONTACTS });
    setTimeout(() => {
      const data = contacts.find(item => String(item.teamId) === String(id));
      if (data) {
        dispatch({ type: actionTypes.GET_CONTACTS_SUCCESS, payload: { data } });
      } else {
        dispatch({
          type: actionTypes.GET_CONTACTS_FAILED,
          payload: { error: 'erros.text1' },
        });
      }
    }, 550);
  },
  resetContacts: () => ({ type: actionTypes.RESET_CONTACTS }),
  // videos
  getVideos: id => dispatch => {
    dispatch({ type: actionTypes.GET_VIDEOS });
    setTimeout(() => {
      const data = videos.find(item => String(item.teamId) === String(id));
      if (data) {
        dispatch({ type: actionTypes.GET_VIDEOS_SUCCESS, payload: { data } });
      } else {
        dispatch({
          type: actionTypes.GET_VIDEOS_FAILED,
          payload: { error: 'erros.text1' },
        });
      }
    }, 550);
  },
  resetVideos: () => ({ type: actionTypes.RESET_VIDEOS }),
  // news
  getNews: id => dispatch => {
    dispatch({ type: actionTypes.GET_NEWS });
    setTimeout(() => {
      const data = news.find(item => String(item.teamId) === String(id));
      if (data) {
        dispatch({ type: actionTypes.GET_NEWS_SUCCESS, payload: { data } });
      } else {
        dispatch({
          type: actionTypes.GET_NEWS_FAILED,
          payload: { error: 'erros.text1' },
        });
      }
    }, 550);
  },
  resetNews: () => ({ type: actionTypes.RESET_NEWS }),
  // fields
  getEvaluationFields: () => async dispatch => {
    dispatch({ type: actionTypes.GET_EVALUATION_FIELDS });
    const { data } = await axios.get(Endpoints.evaluationsFields);
    if (data.results != null) {
      const calcEvaluationsFields = [];
      // eslint-disable-next-line     
      data?.results.forEach((df, index) => { 
        calcEvaluationsFields.push({ 
          ...df, 
          id: index, 
          mode_name: df.data.mode_name,
          mode: df.data.mode, 
          values: df.data.values
        })
      });
      dispatch({ type: actionTypes.GET_EVALUATION_FIELDS_SUCCESS, payload: { data: calcEvaluationsFields } });
    } else {
      dispatch({
        type: actionTypes.GET_EVALUATION_FIELDS_FAILED,
        payload: { error: 'erros.text47' },
      });
    }
  },
  resetEvaluationFields: () => ({ type: actionTypes.RESET_EVALUATION_FIELDS_DATA }),
  updateContractRisks: async (teamId, contract_high_risk, contract_warning_risk) => {
    const body = { contract_high_risk, contract_warning_risk };
    const { data } = await axios.patch(`${Endpoints.teams}/${teamId}`, body);
    return data;
  },
  // end fields
};

export const updateLoanedLists = (lists, athleteId) => async dispatch => {
  dispatch({
    type: actionTypes.UPDATE_LOANED_LISTS,
    payload: {
      lists,
      athleteId,
    },
  });
};

export const updateCastLists = (lists, athleteId) => async dispatch => {
  dispatch({
    type: actionTypes.UPDATE_PROFESSIONAL_CAST_LISTS,
    payload: {
      lists,
      athleteId,
    },
  });
};
