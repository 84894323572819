import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Input, Menu } from '../../../components/template';
import { FaSearch } from 'react-icons/fa';
import { divisionOptions } from '../../../shared/mocks';

import styles from '../ForeignClubs.module.scss';
import { useTranslation } from 'react-i18next';

export default function ActionBar({ countries, onSetFilter, selectedFilters }) {
  const { t } = useTranslation();

  const [timeoutId, setTimeoutId] = useState(null);
  const [clubNameFilter, setClubNameFilter] = useState('');

  useEffect(() => {
    setClubNameFilter(selectedFilters.name);
  }, [selectedFilters.name]);

  const handleInputChange = value => {
    setClubNameFilter(value);
    clearTimeout(timeoutId);

    setTimeoutId(
      setTimeout(() => {
        if ((clubNameFilter !== '' && value === '') || value !== '') {
          onSetFilter({ type: 'q', value });
        }
      }, 800)
    );
  };

  return (
    <div className={styles.filterContainer}>
      <Input
        rounded
        containerClassName={styles.filterInputContainer}
        inputClassName={styles.filterInput}
        icon={<FaSearch className={styles.searchIcon} />}
        iconPosition="right"
        placeholder={t('athlete_list.box2')}
        value={clubNameFilter}
        onChange={event => handleInputChange(event.target.value)}
      />
      {/* <Menu
                title="Confederação"
                largeMenu
                multiple
                rounded
                allowEmpty
                placeholder="Confederação"
                fixedPlaceholder
                className={styles.menu}
                options={[]}
            /> */}
      <Menu
        title={t('compe.text1')}
        rounded
        allowEmpty
        placeholder={countries.find(country => country.code === selectedFilters.country)?.name || t('compe.text1')}
        fixedPlaceholder
        className={styles.menu}
        options={countries.map(country => ({ value: country.code, label: country.name })) ?? []}
        setValue={value => onSetFilter({ type: 'country', value })}
        onClear={() => onSetFilter({ type: 'country', value: '' })}
        value={selectedFilters.country}
      />
      <Menu
        title={t('organi.text11')}
        rounded
        allowEmpty
        placeholder={t(
          `clubDivisionOptions.${divisionOptions.find(division => division.value === selectedFilters.division)?.value}`
        )}
        fixedPlaceholder
        className={styles.menu}
        // options={[...divisionOptions]}
        options={divisionOptions.map(item => ({
          label: t(`clubDivisionOptions.${item.value}`),
          value: item.value,
        }))}
        setValue={value => onSetFilter({ type: 'division', value })}
        onClear={() => onSetFilter({ type: 'division', value: '' })}
        value={selectedFilters.division}
      />
    </div>
  );
}

ActionBar.propTypes = {
  countries: PropTypes.array.isRequired,
  onSetFilter: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
};
