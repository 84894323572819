import React from 'react';
import PropTypes from 'prop-types';

import styles from './CountryGrid.module.scss'
import CountryGridItem from './CountryGridItem';

export default function CountryGrid({ countries, onSetFilter }) {
    return (
        <div className={styles.grid}>
            {countries && countries.map((country, index) => <CountryGridItem onSetFilter={onSetFilter} key={`${country.code}_${country.name}_${index}`} country={country} />)}
        </div>
    );
}
CountryGrid.propTypes = {
    countries: PropTypes.array.isRequired,
    onSetFilter: PropTypes.func.isRequired,
}