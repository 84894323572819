import React from 'react';
import PropTypes from 'prop-types';

import styles from './NationalitiesRow.module.scss';

export default function NationalitiesRow(props) {
  const { nationalities, athleteId, ...rest } = props;

  return (
    <div className={styles.withImg} {...rest}>
      {nationalities && nationalities.slice(0,3).map((nationality, i) => (
        <img
          key={`${nationality?.name}${athleteId}`}
          src={nationality?.flag ?? ''}
          className="softShadow"
          draggable="false"
          alt={nationality?.name ?? ''}
          title={nationality?.name ?? ''}
        />
      ))}
      {nationalities && nationalities.length > 3 && <span>+{nationalities.length -3}</span>}
    </div>
  );
}

NationalitiesRow.defaultProps = {
  nationalities: [],
};

NationalitiesRow.propTypes = {
  nationalities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      flag: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  athleteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
