import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Matches.module.scss';
import { Creators } from 'store/ducks/competition';
import { Card, Spinner, Button } from 'components/template';
import moment from 'moment';
import 'moment/locale/pt-br';
import Match from '../../Subscribed/Match';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export default function Matches({ id, baseURL }) {
  moment.locale('pt-br');

  const dispatch = useDispatch();
  const competition = useSelector(state => state.competition);
  const competitionData = competition.competitionsPage.data;
  const startDate = competitionData.start_date;
  const endDate = competitionData.end_date;
  const { t } = useTranslation();

  const [allGames, setAllGames] = useState([]);
  const matches = competition.matches;
  const rawData = matches.data.matches;
  const rawDataIsNotEmpty = (rawData?.length ?? 0) > 0;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(competitionData).length) {
      setAllGames([]);
      dispatch(Creators.getMatches(id, { limit: 20, offset: 0, o: '-match_date,-id' }));
    }
    return () => {
      dispatch(Creators.resetMatches());
    };
    //eslint-disable-next-line
  }, [id, competitionData]);

  const loadMore = async () => {
    setLoading(true);
    await dispatch(Creators.getMatches(id, { limit: 20, offset: allGames.length, o: '-match_date,-id' }));
    setLoading(false);
  };

  useEffect(() => {
    if (rawDataIsNotEmpty) {
      setAllGames([...allGames, ...rawData])
    }
    //eslint-disable-next-line
  }, [rawData]);
  
  const processedData = allGames.map(match => {
    const date = moment(match.match_date).locale('en');
    return {
        ...match,
        match_day: t('weekDays.'+moment(date).day()),
        match_date: date.format('DD/MM/YYYY')
    };
  });
  const groupByDay = _.groupBy(processedData, 'match_date');

  let content = '';
  if ((matches.loading || competition.competitionPage.loading) && !loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (matches.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(matches.error)}</p>
      </Card>
    );
  } else if (startDate && endDate) {   
    content = (
      <div className={styles.competition}>
        {Object.keys(groupByDay).length > 0 ? Object.keys(groupByDay).map(date => (
          <div key={date}>
            <h2 className={styles.matchDay}>{`${date}, ${groupByDay[date][0].match_day}`}</h2>
            <div className={styles.matchesContainer}>
              {groupByDay[date].map(match => (
                
                  <div className={styles.itemContainer}>
                    <Match match={{ ...match, match_date: null }} teamLink={baseURL + '/clubes/$id/inscritos'} />
                  </div>
                
              ))}
            </div>
          </div>
        )) : (<div className={styles.matchesContainer}>
            <p style={{ textAlign: 'center', margin: '40px' }}>
              <i>
                {t('compe.text6')}
              </i>
            </p>
          </div>
        )}
         <div style={{ textAlign: 'center' }}>           
          <Button className={styles.loadMore} 
            onClick={() => loadMore()} 
            disabled={loading || !competition.matches.data.hasNext} 
            outline 
            color="purple" 
            rounded
          >
            { loading ? <Spinner height='20' /> : t('compe.loadMore') }
          </Button>
         </div>
      </div>
    );
  }

  return content;
}

Matches.propTypes = {
  id: PropTypes.string.isRequired,
};
