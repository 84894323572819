import React from 'react';

// scss
import styles from './AthleteChatItem.module.scss';

// components
import { Avatar } from 'components/template';

// assets
// import WithoutClub from 'assets/ico/without-club.svg';

// other
import moment from 'moment';

export default function AthleteChatItem({
  chat,
  // user,
  date,
  last_message,
  isSelected,
  alertCount,
  isAlertVisible,
  setAlertVisibility,
  handleSelectChat,
}) {
  const containerClasses = [styles.athleteContainer];
  if (isSelected) containerClasses.push(styles.athleteSelected);
  if (chat?.athlete == null) {
    return '';
  }

  const athleteOrg = chat.receiver_org?.id === chat.athlete.current_team?.id ? chat.athlete.current_team : chat.athlete.agency;
  return (
    <div key={chat.id} className={containerClasses.join(' ')} onClick={() => setAlertVisibility(false)}>
      <div className={styles.link} onClick={() => handleSelectChat(chat.id)}>
        <div className={styles.left}>
          <div className={styles.avatar_container}>
            <div className={styles.athlete_image_container}>
              <Avatar className={styles.athlete} img={chat.athlete?.photo} name={chat.athlete?.fullname} noBorder />
            </div>
            <div className={styles.athlete_club_container}>
              <Avatar
                className={styles.athlete_club}
                img={athleteOrg?.emblem}
                name={athleteOrg?.name}
                noBorder
              />
            </div>
          </div>
        </div>
        <div className={styles.center}>
          <span className={styles.name}>{chat.athlete?.nickname}</span>
          {last_message?.message != null && <span>{`${last_message.sender_name}: ${last_message.message}`}</span>}
        </div>
        <div className={styles.right}>
          <span className={styles.date}>{moment(date ?? '2000-01-01T15:05:18.347034Z').format('DD/MM/YYYY')}</span>
          {!!alertCount && isAlertVisible && (
            <div className={styles.alert}>
              <span>{alertCount}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
