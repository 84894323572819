import axios from 'api';
import { stringifyFilters } from 'shared/utility';

export const actionTypes = {
    GET_NOTES: 'notes/GET',
    GET_NOTES_SUCCESS: 'notes/GET_SUCCESS',
    GET_NOTES_ERROR: 'notes/GET_ERROR',
    CREATE_NOTE: 'notes/POST',
    CREATE_NOTE_SUCCESS: 'notes/POST_SUCCESS',
    CREATE_NOTE_ERROR: 'notes/POST_ERROR',
    RESET_REQUEST_REG: 'notes/GET_RESET'
};

const initialState = {
    loadingNotes: false,
    sendingLastNote: false,
    createdNote: null,
    messages: null,
    error: null,
    hasNext: false,
    firstGet: true
};

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actionTypes.GET_NOTES:
            return {
                ...state,
                firstGet: false,
                loadingNotes: true
            }
        case actionTypes.GET_NOTES_ERROR:
            return {
                ...state,
                error: action.payload.error
            }
        case actionTypes.GET_NOTES_SUCCESS:
            return {
                ...state,
                loadingNotes: false,
                projectId: action.payload.projectId,
                athleteId: action.payload.athleteId,
                countOutOfProject: action.payload.countOutOfProject,
                messages: action.payload.data,
                hasNext: action.payload.hasNext,
            }
        case actionTypes.CREATE_NOTE:
            return {
                ...state,
                sendingLastNote: true,
                createdNote: null
            }
        case actionTypes.CREATE_NOTE_ERROR:
            return {
                ...state,
                sendingLastNote: false,
                createdNote: null
            }
        case actionTypes.CREATE_NOTE_SUCCESS:
            return {
                ...state,
                sendingLastNote: false,
                createdNote: action.payload.data
            }

        case actionTypes.RESET_REQUEST_REG:
            return {
                ...initialState,
            }
        default:
            return state;
    }
};

export const getNotes = (userId, pagination, projectId) => async dispatch => {
    dispatch({ type: actionTypes.GET_NOTES });
    const url = '/athletes';
    let filter = { ...pagination };
    if (projectId !== undefined) {
        filter['project'] = projectId;
    }

    const queryString = stringifyFilters(filter);
    try {
        const { data } = await axios.get(`${url}/${userId}/notes/${queryString}`);
        dispatch({
            type: actionTypes.GET_NOTES_SUCCESS,
            payload: {
                data: data.results,
                hasNext: !!data.next,
                projectId: projectId,
                athleteId: userId,
                countOutOfProject: data.countOutOfProject,
            }
        })
    } catch (err) {
        dispatch({
            type: actionTypes.GET_NOTES_ERROR,
            payload: { error: "erros.text11" }
        })
    }
};

export const deleteNotes = async (athleteId, notes_ids) => {
    try {
        await axios.delete(`/athletes/${athleteId}/notes`, { data: { notes_ids } });
    } catch (err) {
        return { error: "erros.text11" }
    }
};

export const createNote = (userId, text, file, file_type, projectId, original_file_name, file_size, thumb_url, date) => async dispatch => {
    dispatch({ type: actionTypes.CREATE_NOTE });
    const url = '/athletes';
    try {


        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }


        const formData = new FormData();

        if (text) {
            formData.append('text', text);
        }

        if (file) {
            if (typeof file === 'string' || file instanceof String) {
                formData.append('file_url', file);
            } else {
                formData.append('file', file);
            }
            formData.append('file_type', file_type);
        }

        if (projectId) {
            formData.append('project', projectId);
        }

        if (original_file_name) {
            formData.append('original_file_name', original_file_name);
        }

        if (file_size) {
            formData.append('file_size', file_size);
        }

        if (thumb_url) {
            formData.append('thumb_url', thumb_url);
        }

        if (date) {
            formData.append('date', date);
        }

        const { data } = await axios.post(`${url}/${userId}/notes/`, formData, config);
        dispatch({ type: actionTypes.CREATE_NOTE_SUCCESS, payload: { data } })
    } catch (err) {
        dispatch({
            type: actionTypes.CREATE_NOTE_ERROR,
            payload: { error: "erros.text12" }
        })
    }
};

export const resetGet = () => async dispatch => {
    dispatch({ type: actionTypes.RESET_REQUEST_REG });
};