import React from 'react';
import * as S from './styles';

export default function HistoryItem({ data }) {
  return (
    <S.Card softShadow bordered>
      <S.Hour>
        {`${data.created_at.getHours()}`.padStart(2, '0')}:{`${data.created_at.getMinutes()}`.padStart(2, '0')}
      </S.Hour>
      <S.Avatar noBorder name={data.user.name} img={data.user.photo} />
      <S.Message>
        <S.UserName>{data.user.name}</S.UserName> {data.text}
      </S.Message>
    </S.Card>
  );
}
