import React, { useEffect, useState } from 'react';
import styles from './Negotiations.module.scss';
import { useSelector } from 'react-redux';
import { getTeams, getTeamAthletes, getTeamNegotiations, updateTeamFrames } from 'store/ducks/myClub';
import Helpers from 'shared/helpers';
import { useDispatch } from 'react-redux';
import { Dialog, Input } from 'components/template';
import { Creators as layoutActions } from 'store/ducks/layout';
import { useTranslation } from 'react-i18next';
import Kanban from './Kanban';
import AthleteFilter from './Kanban/Filter';
import AthleteFilterResult from './Kanban/FilterResult';
import NavDrawer from 'components/NavDrawer/NavDrawer';
import CenteredLoader from 'components/template/CenteredLoader';

export default function Negotiations({ teamId }) {
  const { t } = useTranslation();

  const [queryFilters, setQueryFilters] = useState({ name: '', foot: '', position: [], type: 'arrival' });
  const [frameFilter, setFrameFilter] = useState('');
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenFilterResult, setIsOpenFilterResult] = useState(false);
  const [deleteFrame, setDeleteFrame] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [updateAthletesWorkflow, setUpdateAthletesWorkflow] = useState(undefined);
  const [optionsEditFrame, setOptionsEditFrame] = useState({
    isOpen: false,
    frame: '',
    description: '',
    saving: false,
  });

  const dispatch = useDispatch();
  const teams = useSelector(state => state.myClub.teams);
  const frames = useSelector(state => state.myClub.frames);

  useEffect(() => {
    dispatch(getTeams(teamId));
    dispatch(getTeamAthletes(teamId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getTeamNegotiations(teamId, queryFilters.type));
  }, [queryFilters.type]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUpdateAthletesWorkflow(frames.data);
  }, [frames]);

  let teamFrameObject;
  let teamFrameFieldName;
  if (queryFilters.type === 'arrival') {
    teamFrameFieldName = 'arrivals_frames';
    teamFrameObject = teams?.data?.arrivals_frames ?? {};
  } else {
    teamFrameFieldName = 'frames';
    teamFrameObject = teams?.data?.frames ?? {};
  }

  return (
    <div className={styles.containerNegotiations}>
      <NavDrawer isOpen={isOpenFilter} hide={() => setIsOpenFilter(!isOpenFilter)}>
        <AthleteFilter
          visible={isOpenFilter}
          onApply={() => {
            setIsOpenFilter(false);
            setIsOpenFilterResult(true);
          }}
        />
      </NavDrawer>
      <NavDrawer isOpen={isOpenFilterResult} hide={() => setIsOpenFilterResult(!isOpenFilterResult)}>
        <AthleteFilterResult
          back={() => {
            setIsOpenFilterResult(false);
            setIsOpenFilter(true);
          }}
          openFilter={setIsOpenFilterResult}
          athletesWorkflow={frames.data}
          frame={frameFilter}
          teamId={teamId}
          setUpdateAthletesWorkflow={setUpdateAthletesWorkflow}
          queryFilters={queryFilters}
        />
      </NavDrawer>
      {teams.loading || frames.loading ? (
        <CenteredLoader size="calc(100vh - 250px)" />
      ) : (
        <div className={styles.cards}>
          <Kanban
            filter={queryFilters}
            setFilter={setQueryFilters}
            openFilter={frame => {
              setFrameFilter(frame);
              setIsOpenFilter(true);
            }}
            openEditFrame={setOptionsEditFrame}
            setDeleteFrame={setDeleteFrame}
            confirmDelete={confirmDelete}
            deleteFrame={frame => {
              const oldValue = teamFrameObject[frame];
              delete teamFrameObject[frame];
              setOptionsEditFrame({ ...optionsEditFrame }); // update screen
              updateTeamFrames(teamId, teamFrameFieldName, teamFrameObject).then(result => {
                if (result?.error) {
                  dispatch(
                    layoutActions.showToast({
                      content: <span style={{ padding: '20px 0' }}>{t(result.error)}</span>,
                      duration: 3000,
                    })
                  );
                  teamFrameObject[frame] = oldValue;
                  setOptionsEditFrame({ ...optionsEditFrame }); // update screen
                } else {
                  window.location.reload();
                }
                setConfirmDelete(false);
                setDeleteFrame(false);
              });
            }}
            enableAddAthlete={true}
            updateAthletesWorkflow={updateAthletesWorkflow}
            teamFrameObject={teamFrameObject}
            teamId={teamId}
          />
        </div>
      )}
      <Dialog
        isOpen={optionsEditFrame.isOpen}
        onClose={() => setOptionsEditFrame({ isOpen: false, frame: '', description: '', saving: false })}
        mainTitle={`${optionsEditFrame.frame.length === 0 ? t('field.insert') : t('field.edit')} ${t(
          'myClub.negotiations.list'
        )}`}
        btnLeft={t('buttons.cancel')}
        btnLeftClick={() => setOptionsEditFrame({ isOpen: false, frame: '', description: '', saving: false })}
        btnRight={t('buttons.save')}
        btnRightDisabled={optionsEditFrame.saving}
        btnRightClick={() => {
          setOptionsEditFrame({ ...optionsEditFrame, saving: true });
          let frame = optionsEditFrame.frame;
          if (frame.length === 0) {
            frame = Helpers.normalizedString(optionsEditFrame.description);
          }
          const oldValue = teamFrameObject[frame];
          teamFrameObject[frame] = optionsEditFrame.description;
          updateTeamFrames(teamId, teamFrameFieldName, teamFrameObject).then(result => {
            if (result?.error === undefined) {
              teams.data.arrivals_frames = result.arrivals_frames;
              teams.data.frames = result.frames;
              setOptionsEditFrame({ isOpen: false, frame: '', description: '', saving: false });
            } else {
              dispatch(
                layoutActions.showToast({
                  content: <span style={{ padding: '20px 0' }}>{t(result.error)}</span>,
                  duration: 3000,
                })
              );
              teamFrameObject[frame] = oldValue;
              setOptionsEditFrame({ ...optionsEditFrame, saving: false });
            }
          });
        }}
      >
        <span style={{ marginBottom: '10px', marginLeft: '5px' }}>{t('agencies.name')}:</span>
        <Input
          value={optionsEditFrame.description}
          placeholder={t('agencies.name')}
          onChange={e => {
            e.persist();
            setOptionsEditFrame({ ...optionsEditFrame, description: e.target.value });
          }}
          rounded
          style={{ marginTop: '10px' }}
        />
      </Dialog>
      <Dialog
        isOpen={deleteFrame}
        onClose={() => {
          setDeleteFrame(false);
        }}
        mainTitle={t('proj.deleteTitle')}
        btnLeft={t('buttons.cancel')}
        btnLeftClick={() => {
          setDeleteFrame(false);
        }}
        btnRight={t('buttons.excluir')}
        btnRightClick={() => {
          setConfirmDelete(true);
        }}
        btnRightDisabled={confirmDelete}
      >
        <span className={styles.deleteMessage}>{t('proj.deleteMessage')}</span>
      </Dialog>
    </div>
  );
}
