import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const UnifiedField = styled.div`
  margin-right: 100px;
  margin-bottom: 20px;
`;

export const FieldWrapper = styled.div`
    margin-right: 20px;
    margin-top: 20px;
    ${props => (props.width ? `width: ${props.width};` : '')}

    /* flex-grow: ${props => props.flexGrow} */
`;
