import React, { useState } from 'react';
import * as S from './styles';
import ShadowTeamConsts from '../../consts';
import Variables from 'variables.js';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { Button, Input } from 'components/template';
import { FiChevronRight } from 'react-icons/fi';

export default function ShadowTeamInitialInfo({
  newShadowTeamData,
  setNewShadowTeamData,
  buttonSaveClick,
  handleChangeSchema,
}) {
  const { t } = useTranslation();
  const [saveButtonDisable, setSaveButtonDisable] = useState(false);

  return (
    <>
      <S.LeftHeader>
        <h3>{t('proj.montarTimeSombra')}</h3>
        {t('proj.montarTimeSombraDesc')}
      </S.LeftHeader>
      <S.LeftInput>
        <label>{t('proj.nomeTimeSombra')}</label>
        <Input
          rounded
          inputClassName={styles.leftInput}
          value={newShadowTeamData.name ?? ''}
          onChange={e =>
            setNewShadowTeamData({
              ...newShadowTeamData,
              name: e.target.value,
            })
          }
          placeholder={t('proj.text9')}
          style={{ backgroundColor: '#fff' }}
        />
      </S.LeftInput>
      <S.LeftInput>
        <label>{t('proj.modeloDeCampo')}</label>
        <S.FlexDiv>
          <S.Menu
            rounded
            placeholder={t('organi.box20')}
            options={Object.values(ShadowTeamConsts.EModels).map(e => {
              return {
                label: t(`proj.modelo${e.description}`),
                value: e.description,
              };
            })}
            value={newShadowTeamData.modelfield ?? ShadowTeamConsts.initialModelField}
            setValue={val =>
              setNewShadowTeamData({
                ...newShadowTeamData,
                modelfield: val,
              })
            }
          />
        </S.FlexDiv>
      </S.LeftInput>
      <S.LeftInput>
        <label>{t('proj.esquema')}</label>
        <S.FlexDiv>
          <S.Menu
            rounded
            placeholder={t('organi.box20')}
            options={ShadowTeamConsts.schemas.map(e => {
              return { label: e, value: e };
            })}
            value={newShadowTeamData.schema ?? ShadowTeamConsts.initialSchema}
            setValue={handleChangeSchema}
          />
        </S.FlexDiv>
      </S.LeftInput>
      <S.LeftInput>
        <label>{t('proj.corDoCampo')}</label>
        <S.FlexDiv>
          <S.Menu
            rounded
            placeholder={t('organi.box20')}
            className={styles.menuCor}
            options={[
              {
                label: (
                  <S.MenuCor>
                    {t('proj.corDoCampoVerde')}
                    <S.MenuCorOption className={styles.menuCorOption} color={ShadowTeamConsts.initialColorField} />
                  </S.MenuCor>
                ),
                value: ShadowTeamConsts.initialColorField,
              },
              {
                label: (
                  <S.MenuCor>
                    {t('proj.corDoCampoEscuro')}
                    <S.MenuCorOption className={styles.menuCorOption} color={Variables.darkPurple2} />
                  </S.MenuCor>
                ),
                value: Variables.darkPurple2,
              },
              {
                label: (
                  <S.MenuCor>
                    {t('proj.corDoCampoBranco')}
                    <S.MenuCorOption className={styles.menuCorOption} color={'#ffffff'} />
                  </S.MenuCor>
                ),
                value: '#ffffff',
              },
            ]}
            value={newShadowTeamData.color_field ?? ShadowTeamConsts.initialColorField}
            setValue={val =>
              setNewShadowTeamData({
                ...newShadowTeamData,
                color_field: val,
              })
            }
          />
        </S.FlexDiv>
      </S.LeftInput>
      <Button
        onClick={async () => {
          setSaveButtonDisable(true);
          try {
            await buttonSaveClick();
          } finally {
            setSaveButtonDisable(false);
          }
        }}
        disabled={saveButtonDisable}
        rounded
        color="green"
        style={{
          fontWeight: '700',
          margin: '30px 15px 0 15px',
        }}
      >
        {t('proj.salvarContinuar')}
        <FiChevronRight size="18px" style={{ marginLeft: '10px', marginBottom: '-4px' }} />
      </Button>
    </>
  );
}
