import React from 'react';
import styles from './Home.module.scss';
import LastSeenAthletes from './LastSeenAthletes';
import LatestMoves from '../shared/LatestMoves';
import RightPanelHome from './RightPanelHome';
import { TopBar } from 'components/template';
import { FaRss } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import SupportButton from './SupportButton';

export default function Home() {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <TopBar
          title={t('pagina_inicial.homepage_16')}
          icon={<FaRss />}
          subtitle={t('pagina_inicial.homepage_17')}
          className={styles.TopBar}
        />
        <LastSeenAthletes />
        <LatestMoves />
        <RightPanelHome />
      </div>
      <SupportButton />
    </>
  );
}
