import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './ContractsResume.module.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'components/template';

export default function ContractsResume({ athletes, linkURL = null }) {
  const { t } = useTranslation();
  const [groups, setGroups] = useState({ finished: [], danger: [], warning: [] });
  const agency = useSelector(state => state.agencies.instance);
  const contractHighRisk = agency.data?.contract_high_risk ?? 9;
  const contractWarningRisk = agency.data?.contract_warning_risk ?? 18;
  const url = linkURL || `/minha-agencia/contratos`;

  const dangerRangeDivider = new Date(new Date().setMonth(new Date().getMonth() + contractHighRisk))
    .toISOString()
    .split('T')[0];
  const warningRangeDivider = new Date(new Date().setMonth(new Date().getMonth() + contractWarningRisk))
    .toISOString()
    .split('T')[0];

  function getGroupsByAthletes(athletes) {
    const today = new Date();
    return athletes.reduce(
      (acc, at) => {
        if (at?.contract_info?.current_contract_end == null || at?.contract_info?.current_contract_end < today) acc['finished'].push(at)
        else if (at?.contract_info?.current_contract_end < dangerRangeDivider) acc['danger'].push(at)
        else if (at?.contract_info?.current_contract_end < warningRangeDivider) acc['warning'].push(at);
        return acc;
      },
      { finished: [], danger: [], warning: [] }
    );
  }
  useEffect(() => {
    if (athletes?.length) setGroups(getGroupsByAthletes(athletes));
  }, [athletes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <h3 className={styles.panelTitle}>{t('myClub.general.contractTitle')}</h3>
      {Object.keys(groups).map((group, index) => {
        return (
          <>
            <div key={index} className={styles.group}>
              <div className={[styles.groupColor, styles[`groupColor_${group}`]].join(' ')} />
              <span>
                {t('athlete_list.existplural')}
                <span className={styles.athlete_length}>
                  {t(`myClub.contracts.contractResumeCount_${group}`, { length: groups[group].length })}
                </span>
                {' '}{t(`myClub.contracts.contractResumeDesc_${group}`)}
              </span>
            </div>
          </>
        );
      })}
      <Link draggable="false" to={url} className={styles.link}>
        <Button className={styles.button} rounded fluid outline color="green">
          {t('myClub.general.contractSeeMore')}
        </Button>
      </Link>
    </div>
  );
}
