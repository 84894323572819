import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import styles from './NavigationBar.module.scss';

import NavigationItem from './NavigationItem/NavigationItem';

function NavigationBar({ items, filled, className }) {
    const itemList = items.map(
        item =>
            !item.shouldHide && (
                <NavigationItem
                    key={item.label}
                    label={item.label}
                    link={item.link}
                    onClick={item.onClick}
                    amount={item.amount}
                    params={item.params}
                    isActive={item.isActive}
                    className={item.className}
                    notImplemented={item.notImplemented}
                />
            )
    );
    const filledClassName = filled ? styles.filled : '';
    const classes = [styles.navigationBar, filledClassName];
    if (className) {
        classes.push(className);
    }
    return <div className={classes.join(' ')}>{itemList}</div>;
}

NavigationBar.propTypes = {
    items: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

export default withRouter(NavigationBar);
