import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, Checkbox } from 'components/template';
import styles from './ActionBar.module.scss';
import { useTranslation } from 'react-i18next';

export default function ActionBar({
  categoriesFilter,
  setCategoriesFilter,
  setCountriesFilter,
  countriesFilter,
  countries,
  checkboxFilter,
  setCheckboxFilter,
}) {
  const { t } = useTranslation();

  const CATEGORY_OPTIONS = [
    { label: t('listTranslation.Male'), value: 'M' },
    { label: t('listTranslation.Female'), value: 'F' },
    { label: t('listTranslation.Amateur'), value: 'A' },
  ];

  const [onlyOpen, setOnlyOpen] = useState(false);
  const [onlyMonitoring, setOnlyMonitoring] = useState(false);

  return (
    <div className={styles.actionBar}>
      <Menu
        title={t('compe.text1')}
        largeMenu
        rounded
        allowEmpty
        placeholder={t('user.text20')}
        className={`${styles.menu} ${countriesFilter.currentState.length > 0 ? styles.menuGreen : ''}`}
        options={countries?.map(country => ({ label: country.name, value: country.code })) ?? []}
        value={countriesFilter.currentState}
        onClear={() => setCountriesFilter(f => ({ ...f, currentState: [] }))}
        setValue={val => setCountriesFilter(f => ({ ...f, currentState: val }))}
      />
      <Menu
        title={t('user.text21')}
        largeMenu
        rounded
        allowEmpty
        placeholder={t('user.text22')}
        className={`${styles.menu} ${categoriesFilter.currentState ? styles.menuGreen : ''}`}
        options={CATEGORY_OPTIONS ?? []}
        value={categoriesFilter.currentState}
        onClear={() => setCategoriesFilter(f => ({ ...f, currentState: null }))}
        setValue={val => setCategoriesFilter(f => ({ ...f, currentState: val }))}
      />
      <div className={styles.checkbox_container}>
        <Checkbox
          label={t('transferWindows.onlyMonitored')}
          checked={onlyMonitoring}
          onChange={() => {
            if (onlyMonitoring) {
              setOnlyMonitoring(false);
              setCheckboxFilter({
                ...checkboxFilter,
                monitoring: '',
              });
            } else {
              setOnlyMonitoring(true);
              setCheckboxFilter({
                ...checkboxFilter,
                monitoring: true,
              });
            }
          }}
        />
        <Checkbox
          label={t('transferWindows.onlyOpen')}
          checked={onlyOpen}
          onChange={() => {
            if (onlyOpen) {
              setOnlyOpen(false);
              setCheckboxFilter({
                ...checkboxFilter,
                is_open: '',
              });
            } else {
              setOnlyOpen(true);
              setCheckboxFilter({
                ...checkboxFilter,
                is_open: true,
              });
            }
          }}
        />
      </div>
    </div>
  );
}

ActionBar.propTypes = {
  stateOptions: PropTypes.instanceOf(Array).isRequired,
  nameInputValue: PropTypes.string.isRequired,
  nameInputChangeHandler: PropTypes.func.isRequired,
  filterMenu: PropTypes.instanceOf(Object).isRequired,
  setFilterMenu: PropTypes.func.isRequired,
};
