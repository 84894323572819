import React from 'react';
import PropTypes from 'prop-types';

import styles from './Edition.module.scss';
import Aside from './Aside/Aside';
import Main from './Main/Main';

export default function Edition({
  athletes,
  orderedAthletesId,
  configuration,
  setConfiguration,
  setAthletesInfoData,
  athletesInfoData,
  reportData,
  setReportData,
  addAthlete,
  removeAthlete,
  updateAthlete,
}) {
  return (
    <div className={styles.container}>
      <Aside
        addAthlete={addAthlete}
        removeAthlete={removeAthlete}
        updateAthlete={updateAthlete}
        reportData={reportData}
        setReportData={setReportData}
        data={athletes}
        orderedAthletesId={orderedAthletesId}
        configuration={configuration}
        setConfiguration={setConfiguration}
      />
      <Main
        data={athletes}
        orderedAthletesId={orderedAthletesId}
        configuration={configuration}
        setConfiguration={setConfiguration}
        setAthletesInfoData={setAthletesInfoData}
        athletesInfoData={athletesInfoData}
        reportData={reportData}
      />
    </div>
  );
}

Edition.propTypes = {
  athletes: PropTypes.array.isRequired,
  orderedAthletesId: PropTypes.array.isRequired,
  configuration: PropTypes.instanceOf(Object).isRequired,
  setConfiguration: PropTypes.func.isRequired,
  setAthletesInfoData: PropTypes.func.isRequired,
  athletesInfoData: PropTypes.array.isRequired,
};
