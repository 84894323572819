import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './GameFields.module.scss';
import { Calendar, Input, Radio, StarRating, Menu } from 'components/template';
import InputNumber from 'components/template/InputNumber/InputNumber';
import PropTypes from 'prop-types';
import { MdCalendarMonth, MdInfo } from 'react-icons/md';
import { gamePositionOptions, statureOptions, categoryOptions, getPreferentialFootWithLabel } from '../../../../shared/mocks';

const GameFields = ({setFields, fields}) => {
  const { t } = useTranslation();
  const [matchFields, setMatchFields] = useState(fields);    
  
  useEffect(() => {
    setFields(matchFields);
    console.log('matchFields', matchFields.minutesPlayed);
  }, [matchFields, setFields]);

  return (
    <div className={styles.gameFields}>
      <div className={styles.formGroup}>
        <label className={styles.formLabel}>
          {t('gameFields.competition')+':'}
        </label>
        <div>
        <Input
          className={styles.largeSelect}
          value={matchFields.competition} 
          type="text" 
          placeholder={t('gameFields.competition')} 
          name="competition" 
          onChange={e => setMatchFields({...matchFields, competition: e.target.value})}
        />
        <p className={styles.info}><MdInfo className={styles.infoIcon}/> Ex: Copa Nike</p>
        </div>
      </div>
      <div className={styles.formGroup}>
        <label className={styles.formLabel}>
          {t('gameFields.category')+':'}
        </label>
        <Menu
          className={styles.largeSelect}
          placeholder={t('gameFields.category')}
          options={categoryOptions.map(item => ({
            label: t(`categoryOptions.${item.value}`),
            value: item.value,
          }))}
          value={matchFields.category}
          setValue={value => setMatchFields({ ...matchFields, category: value, isApplyButton: true })}
          onClear={() => setMatchFields({ ...matchFields, category: '' })}
          allowEmpty
          
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.formLabel}>
          {t('gameFields.level')+':'}
        </label>
        <StarRating 
          rate={matchFields.competitionLevel} 
          size={25} 
          editable 
          setValue={(val) => setMatchFields({...matchFields, competitionLevel: val})}
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.formLabel}>
          {t('gameFields.location')+':'}
        </label>
        <Input 
          className={styles.largeSelect}
          value={matchFields.location} 
          type="text" 
          placeholder={t('gameFields.location')} 
          name="game-location" 
          onChange={e => setMatchFields({...matchFields, location: e.target.value})}
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.formLabel}>
          {t('gameFields.observed')+':'}
        </label>
        <div>
          <Input 
            className={styles.largeSelect}
            value={matchFields.observedGame} 
            type="text" 
            placeholder={t('gameFields.observed')} 
            name="observed-game" 
            onChange={e => setMatchFields({...matchFields, observedGame: e.target.value})}
          />
          <p className={styles.info}><MdInfo className={styles.infoIcon}/> Ex: Footure FC 1 x 0 Footlink FC</p>
        </div>
      </div>
      <div className={styles.formGroup}>
        <label className={styles.formLabel}>
          {t('gameFields.date')+':'}
        </label>
        <div className={styles.calendar}>
          <Calendar
            minDate="1975-01-01"
            value={matchFields.gameDate ? new Date(matchFields.gameDate + ' 00:00') : null}
            setValue={value => setMatchFields({...matchFields, gameDate: value})}
          />
          <div className={styles.calendarIcon}>
            <MdCalendarMonth />
          </div>
        </div>
      </div>
      <div className={styles.formGroup}>
        <label className={styles.formLabel}>
          {t('gameFields.gameLevel')+':'}
        </label>
        <StarRating rate={matchFields.gameLevel} size={25} editable setValue={(val) => setMatchFields({...matchFields, gameLevel: val})}/>
      </div>
      <div className={styles.formGroup}>
        <label className={styles.formLabel}>
          {t('gameFields.minutes')+':'}
        </label>
        <InputNumber 
          className={styles.largeSelect}
          value={matchFields.minutesPlayed}
          placeholder={t('gameFields.minutes')} 
          name="minutes-played"           
          maxLength={3}
          onChange={e => setMatchFields({...matchFields, minutesPlayed: e})}
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.formLabel}>
          {t('gameFields.startingPlayer')+':'}
        </label>
        <div className={styles.radioContainer}>
          <Radio
              label={t('yesNo.sim')}
              checked={matchFields?.startingPlayer}
              onChange={() => {
                setMatchFields({...matchFields, startingPlayer: true});
              }}
          />
          <Radio
              label={t('yesNo.nao')}
              checked={matchFields?.startingPlayer === undefined ? false : !matchFields.startingPlayer}
              onChange={() => {
                setMatchFields({...matchFields, startingPlayer: false});
              }}
          />
        </div>
      </div>
      <div className={styles.formGroup}>
        <label className={styles.formLabel}>
          {t('gameFields.gols')+':'}
        </label>
        <InputNumber 
          value={matchFields?.gols}
          name="goals"           
          maxLength={2}
          showButton={true}
          onChange={e => setMatchFields({...matchFields, gols: e})}
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.formLabel}>
          {t('gameFields.assistances')+':'}
        </label>
        <InputNumber 
          value={matchFields?.assistances} 
          name="assistances"           
          maxLength={2}
          showButton={true}
          onChange={e => setMatchFields({...matchFields, assistances: e})}
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.formLabel}>
          {t('gameFields.yellowCards')+':'}
        </label>
        <InputNumber 
          value={matchFields?.yellowCard} 
          name="yellowCard"           
          maxLength={2}
          showButton={true}
          onChange={e => setMatchFields({...matchFields, yellowCard: e})}
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.formLabel}>
          {t('gameFields.redCards')+':'}
        </label>
        <InputNumber 
          value={matchFields?.redCards}           
          name="minutes-played"           
          maxLength={2}
          showButton={true}
          onChange={e => setMatchFields({...matchFields, redCards: e})}
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.formLabel}>
          {t('gameFields.position')+':'}
        </label>
        <Menu
          className={styles.largeSelect}
          placeholder={t('gameFields.position')}
          options={gamePositionOptions.map(item => ({
            label: t(`positionOptions.${item.value}`),
            value: item.label,
          }))}
          value={matchFields.position}
          setValue={value => setMatchFields({ ...matchFields, position: value, isApplyButton: true })}
          onClear={() => setMatchFields({ ...matchFields, position: '' })}
          allowEmpty
          
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.formLabel}>
          {t('gameFields.stature')+':'}
        </label>
        <Menu
          className={styles.largeSelect}
          placeholder={t('gameFields.stature')}
          options={statureOptions.map(item => ({
            label: t(`statureOptions.${item.value}`),
            value: item.value,
          }))}
          value={matchFields.stature}
          setValue={value => setMatchFields({ ...matchFields, stature: value, isApplyButton: true })}
          onClear={() => setMatchFields({ ...matchFields, stature: '' })}
          allowEmpty
          
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.formLabel}>
          {t('gameFields.foot')+':'}
        </label>
        <Menu
          className={styles.largeSelect}
          placeholder={t('gameFields.foot')}
          options={getPreferentialFootWithLabel(t)}
          value={matchFields.foot}
          setValue={value => setMatchFields({ ...matchFields, foot: value, isApplyButton: true })}
          onClear={() => setMatchFields({ ...matchFields, foot: '' })}
          allowEmpty
          
        />
      </div>
      {/* <div className={styles.formGroup}>
        <label className={styles.formLabel}>
          {t('gameFields.performance')+':'}
        </label>
        <Menu
          className={styles.largeSelect}
          placeholder={t('gameFields.performance')}
          options={getPerformanceWithLabel(t)}
          value={matchFields.performance}
          setValue={value => setMatchFields({ ...matchFields, performance: value, isApplyButton: true })}
          onClear={() => setMatchFields({ ...matchFields, performance: '' })}
          allowEmpty
          
        />
      </div> */}
    </div>
  );
};

GameFields.propTypes = {  
  fields: PropTypes.instanceOf(Object).isRequired,
  setFields: PropTypes.func.isRequired,
};


export default GameFields;
