import React from "react";
import { 
    Slider, 
    withStyles, 
    // Button,  
    // Tooltip,  
    // Popover,
    // Grid
} from "@material-ui/core";
import {
    FastForward,
    FastRewind,
    Pause,
    PlayCircleFilledRounded,
    PlayArrow,
    VolumeUp,
    VolumeOff,
    Fullscreen,
    ThumbUpAlt,
    ThumbUpAltOutlined,
    SkipPrevious
} from "@material-ui/icons";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from 'react-router-dom';
import styles from './Controls.module.scss';
import { useSelector } from "react-redux";
   
const PrettoSlider = withStyles({
    root: {
      height: "20px",
      color: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    thumb: {
      height: 10,
      width: 10,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      marginTop: 0,
      marginLeft: "-1px",
    "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 4px)",
    },
    track: {
      height: 5,
      borderRadius: 4,
      width: "100%",
    },
    rail: {
      height: 5,
      borderRadius: 4,
    },
   })(Slider);

const HeadContainer = ({name}) => {
    return(
    <div className={styles.topContainer}>
        <span className={styles.pageBack}>
          <Link className={styles.link} to={'/originals'}>
            <IoMdArrowRoundBack size={24}/>
          </Link>
        </span>
        <h2>{name || 'Video PLayer' }</h2>
    </div> 
    )
}

const MiddleContainer = ({onRewind, onPlayPause, playing, onForward}) => {
    return(
    <div className={styles.midContainer}>
        <div className={styles.iconBtn} onDoubleClick={onRewind}>
         <FastRewind fontSize="large" />
        </div>
    
       <div className={styles.iconBtn} onClick={onPlayPause}>
            {playing ? (
                <Pause fontSize="large" />
            ) : (
                <PlayArrow fontSize="large" />
            )}{" "}
       </div>
    
       <div className={styles.iconBtn} onDoubleClick={onForward}>
        <FastForward fontSize="large" />
       </div>
     </div>
    )
}

const BottomContainer = ({
    onPlayPause,
    playing,    
    played,
    onSeek,
    onSeekMouseUp,
    onVolumeChangeHandler,
    onVolumeSeekUp,
    volume,
    mute,
    onMute,
    duration,
    currentTime,
    onMouseSeekDown,
    liked,
    backToStart,
    fullScreen,
    onLike
}) => {
    const originals = useSelector(state => state.originals.originals);

    return(
    <div className={styles.bottomContainer}>        
        <div className={styles.controlBox}>
          <div className={styles.innerControls}>          
            <div className={styles.iconBtn} onClick={onPlayPause}>
                {playing ? (
                    <Pause fontSize="large" />
                ) : (
                    <PlayCircleFilledRounded fontSize="large" />
                )}{" "}
            </div>
            <span className={styles.currentTime}>{currentTime}</span>
            <div className={styles.sliderContainer}>
                <PrettoSlider 
                min={0}
                max={100}
                value={played * 100}
                onChange={onSeek}
                onChangeCommitted={onSeekMouseUp}
                onMouseDown={onMouseSeekDown}
                />
            </div>
            <span>{duration}</span>            
            <div className={styles.iconBtn} onClick={backToStart}>
                <SkipPrevious fontSize="large"/>              
            </div>
            {/* <div className={styles.iconBtn}>
              <SkipNext fontSize="large" />
            </div> */}
            <div className={styles.iconBtn}>
                {originals?.like === true ? (
                    <ThumbUpAlt onClick={onLike} fontSize="large"/>    
                ) : (
                    <ThumbUpAltOutlined onClick={onLike} fontSize="large"/>
                )}                
            </div>            
            <div className={styles.iconBtn} onClick={onMute}>
                {mute ? (
                  <VolumeOff fontSize="large" />
                ) : (
                  <VolumeUp fontSize="large" />
                )}
            </div>
            <div className={styles.iconBtn}>
                <Fullscreen fontSize="large" onClick={fullScreen}/>
            </div>
            {/* <Slider
                className={styles.volumeSlider} 
                onChange={onVolumeChangeHandler}
                value={volume * 100}
                onChangeCommitted={onVolumeSeekUp}
            /> */}          
            </div>
          </div>
    </div>
    )
}


const Controls = ({
    name,
    onPlayPause,
    playing,
    onRewind,
    onForward,
    played,
    onSeek,
    onSeekMouseUp,
    onVolumeChangeHandler,
    onVolumeSeekUp,
    volume,
    mute,
    onMute,
    duration,
    currentTime,
    onMouseSeekDown,
    controlRef,
    liked,
    backToStart,
    fullScreen,
    onLike
  }) => {
    return(
    <div className={styles.controlContainer} ref ={controlRef}>
        <HeadContainer name={name}/>
        <MiddleContainer onRewind={onRewind} onPlayPause={onPlayPause} playing={playing} onForward={onForward}/>
        <BottomContainer 
            onPlayPause={onPlayPause} 
            playing={playing}
            played={played}
            onSeek={onSeek}
            onSeekMouseUp={onSeekMouseUp}
            onVolumeChangeHandler={onVolumeChangeHandler}
            onVolumeSeekUp={onVolumeSeekUp}
            volume={volume}
            mute={mute}
            onMute={onMute}
            duration={duration}
            currentTime={currentTime}
            onMouseSeekDown={onMouseSeekDown}
            liked={liked}
            backToStart={backToStart}
            fullScreen={fullScreen}
            onLike={onLike}
        />
    </div>)
}

export default Controls;