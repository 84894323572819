import Endpoints from 'endpoints';
import axios from 'api';
import ErrorMessages from 'shared/ErrorMessages';
import { stringifyFilters } from 'shared/utility';

export const actionTypes = {
  GET_TEAMS: 'teamsHistorical/GET',
  GET_TEAMS_SUCCESS: 'teamsHistorical/GET_SUCCESS',
  GET_TEAMS_FAIL: 'teamsHistorical/GET_FAIL',
};

const initialState = {
  teams: {
    loading: false,
    error: null,
    data: [],
    hasNext: true,
    hasPrevious: false,
    count: 0,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // teams
    case actionTypes.GET_TEAMS:
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: true,
        },
      };
    case actionTypes.GET_TEAMS_SUCCESS:
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: false,
          data: action.payload.data,
          hasNext: action.payload.hasNext,
          hasPrevious: action.payload.hasPrevious,
          count: action.payload.count,
        },
      };
    case actionTypes.GET_TEAMS_FAIL:
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
}

export const getTeamsHistoricalFilterOptions = async teamId => {
  const url = Endpoints.teams;
  try {
    const { data } = await axios.get(`${url}/${teamId}/historical_filteroptions`);
    data.seasons = data.seasons.filter(s => {
      const years = s.split('/');
      let gte2023 = true;
      for (let index in years) {
        const year = years[index];
        const nYear = Number(year);
        gte2023 = gte2023 && (isNaN(nYear) || nYear >= 2023);
      }
      return gte2023;
    });
    return data;
  } catch (err) {
    return {};
  }
};

export const getTeamsHistorical = (filters, teamId) => async dispatch => {
  dispatch({ type: actionTypes.GET_TEAMS });
  const url = Endpoints.teams;

  try {
    const queryString = stringifyFilters(filters);

    const { data } = await axios.get(`${url}/${teamId}/historical${queryString}`);
    dispatch({
      type: actionTypes.GET_TEAMS_SUCCESS,
      payload: {
        data: data.results ?? data,
        hasNext: data.next ? true : false,
        hasPrevious: data.previous ? true : false,
        count: data.count,
      },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_TEAMS_FAIL,
        payload: { error: 'erros.text39' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_TEAMS_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else {
      dispatch({
        type: actionTypes.GET_TEAMS_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};
