import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from 'store/ducks/competition';
import CompetitionLogo from 'assets/ico/menu/competition.svg';
import styles from './YouthLeague.module.scss';
import moment from 'moment';
import { Card, Spinner, Table, Pagination } from 'components/template';
import CompetitionRow from 'components/CompetitionRow/CompetitionRow';
import CountryRow from 'components/CountryRow/CountryRow';
import OrganizerRow from 'components/OrganizerRow/OrganizerRow';
import TopBar from 'components/template/TopBar/TopBar';
import ActionBar from './ActionBar/ActionBar';
import { usePagination } from 'shared/hooks';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavigationBar from 'components/NavigationBar/NavigationBar';

const YouthLeague = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const competitions = useSelector(state => state.competition.competitionsPage);

  const [pagination, setPagination] = usePagination();

  const initialFilters = {
    seasons: [],
    categories: [],
    context: [],
    countries: [],
    loading: true,
  };
  const currentSeasonName = new Date().getFullYear().toString();
  const [filters, setFilters] = useState(initialFilters);
  const [gender, setGender] = useState('M');
  const [selectedCategory, setSelectedCategory] = useState('currentSeason');
  const [selectedFilters, setSelectedFilters] = useState({
    season__name: currentSeasonName,
    category__gender: 'M',
    category: null,
    context: '',
    country: '',
    name: '',
  });

  const categoriesOptions = ['Profissional', 'Sub-23', 'Sub-20', 'Sub-19', 'Sub-17', 'Sub-15', 'Sub-14', 'Sub-13'];
  const loadedCategoriesFromBackend = filters.categories.length > 0;
  filters.categories.forEach(c => {
    if (!categoriesOptions.includes(c.name)) {
      categoriesOptions.push(c.name);
    }
  });

  useEffect(() => {
    dispatch(
      Creators.getCompetitions({
        ...pagination,
        ...selectedFilters,
      })
    );

    return () => {
      dispatch(Creators.resetCompetitions());
    };
  }, [dispatch, pagination, selectedFilters]);
  
  useEffect(() => {
    if (loadedCategoriesFromBackend) {
      if (selectedCategory === 'currentSeason') {
        setSelectedFilters({ 
          ...selectedFilters, 
          category: null, 
          season__name: currentSeasonName,
          category__gender: gender,
        });

      } else {
        const newCategory = filters.categories.find(c => c.gender === gender && c.name === selectedCategory);
        const newCategoryId = newCategory ? newCategory.id : 0;
        setSelectedFilters({ 
          ...selectedFilters, 
          category: newCategoryId, 
          season__name: '',
          category__gender: '',
        });
      }
    }
  }, [gender, selectedCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  const rawData = competitions.data.competitions;
  const filterOptions = competitions.data.options;
  if (filterOptions && filters.loading === true) {
    setFilters(filterOptions);
  }

  const data =
    rawData &&
    rawData.map(row => {
      const competition = (
        <div className={styles.competitionRowContainer}>
          <CompetitionRow name={row.name} id={row.id} level="base" img={row.emblem} />
        </div>
      );
      const organizer = row.organizer ? (
        <div className={styles.organizerRowContainer}>
          <OrganizerRow name={row.organizer.name} id={row.organizer.id} img={row.organizer.emblem} />
        </div>
      ) : (
        ''
      );
      const country = row.country ? (
        <div className={styles.countryRowContainer}>
          <CountryRow name={row.country.name} id={1} img={row.country.flag} />
        </div>
      ) : (
        ''
      );
      const region = row.context;
      const category = row.category;
      const clubs = row?.counts?.teams ?? '-';
      const start = moment(row.start_date).format('DD/MM/YYYY');
      const end = moment(row.end_date).format('DD/MM/YYYY');

      return [competition, organizer, country, region, category, clubs, start, end];
    });

  let content = '';
  if (competitions.loading) {
    content = (
      <div className={styles.loaderContainer}>
        <Spinner />
      </div>
    );
  } else if (competitions.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(competitions.error)}</p>
      </Card>
    );
  } else {
    content = (
      <>
        <Table
          className={styles.competition}
          flat
          theads={[
            t('match.comp'),
            t('compe.text21'),
            t('compe.text1'),
            t('compe.text22'),
            t('contact_card.category'),
            t('compe.text12'),
            t('athlete_card.text3'),
            t('compe.text23'),
          ]}
          data={data}
        />
      </>
    );
  }

  return (
    <>
      <TopBar
        icon={<img draggable="false" src={CompetitionLogo} alt={t('nav_drawer.competicoesBase')} />}
        iconBgColor="#56CCF2"
        title={t('compe.text24')}
        subtitle={t('compe.text25')}
      />
      <NavigationBar items={
        [
          {
            label: t('compe.currentSeason'),
            isActive: 'currentSeason' === selectedCategory,
            component: <></>,
            onClick: () => setSelectedCategory('currentSeason'),
          },
          ...categoriesOptions.map(category => ({
            label: category === 'Profissional' ? t('compe.text26') : category,
            isActive: category === selectedCategory,
            component: <></>,
            onClick: () => {
              if (loadedCategoriesFromBackend) {
                setSelectedCategory(category);
              }
            },
          }))
        ]        
      } />
      <div className={styles.container}>
        <ActionBar 
          filters={filters} 
          selectedFilters={selectedFilters} 
          setSelectedFilters={setSelectedFilters} 
          gender={gender}
          setGender={loadedCategoriesFromBackend ? setGender : null}
          isCurrentSeasonTab={selectedCategory === 'currentSeason'}
        />
        {content}
      </div>
      {rawData && competitions.data && (
        <Pagination
          length={rawData.length}
          count={competitions.data.count}
          pagination={pagination}
          setPagination={setPagination}
          hasNext={competitions.data.hasNext}
          hasPrevious={competitions.data.hasPrevious}
        />
      )}  
    </>
  );
};

export default withRouter(YouthLeague);
