/*eslint-disable eqeqeq*/
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, Switch, Route, Redirect, useParams, useLocation } from 'react-router-dom';
import Page from 'components/template/Page';
import { Dialog, Input } from 'components/template';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import NavDrawer from 'components/NavDrawer/NavDrawer';
import { Creators } from 'store/ducks/projects';
import WorkflowAthlete from 'pages/Project/Workflow/Athlete';
import AthleteFilter from './Kanban/Filter';
import AthleteFilterResult from './Kanban/FilterResult';
import Kanban from './Kanban';
import ShadowTeam from './ShadowTeam';
import History from './History';
import Users from './Users';
import TopBar from './TopBar';
import { Creators as layoutActions } from 'store/ducks/layout';
import Helpers from 'shared/helpers';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import ModalErroPermission from 'components/ModalErroPermission/ModalErroPermision';

function Workflow() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const baseURL = `/projetos/${id}/workflow`;
  const project = useSelector(state => state.project);
  const loggedUser = useSelector(state => state.user.data);
  const loggedUserPermission =
    (project.data?.users ?? []).find(projectUser => projectUser?.user?.id == loggedUser.id)?.permission ??
    'Visualizador';
  const [updateAthletesWorkflow, setUpdateAthletesWorkflow] = useState(undefined);

  useEffect(() => {
    dispatch(Creators.getProject(id));
  }, [dispatch, id]);

  const [frameFilter, setFrameFilter] = useState('');
  const [deleteFrame, setDeleteFrame] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [optionsEditFrame, setOptionsEditFrame] = useState({
    isOpen: false,
    frame: '',
    description: '',
    saving: false,
  });
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenFilterResult, setIsOpenFilterResult] = useState(false);
  const items = [
    {
      label: 'Workflow',
      link: `${baseURL}`,
      params: { id },
      component: (
        <Kanban
          openFilter={frame => {
            setFrameFilter(frame);
            setIsOpenFilter(true);
          }}
          openEditFrame={setOptionsEditFrame}
          setDeleteFrame={setDeleteFrame}
          confirmDelete={confirmDelete}
          deleteFrame={frame => {
            const oldValue = project.data.frames[frame];
            delete project.data.frames[frame];
            setOptionsEditFrame({ ...optionsEditFrame }); //update screen
            Creators.updateProjectFrames(project.data.id, project.data.frames).then(result => {
              if (result?.error) {
                dispatch(
                  layoutActions.showToast({
                    content: <span style={{ padding: '20px 0' }}>{t(result.error)}</span>,
                    duration: 3000,
                  })
                );
                project.data.frames[frame] = oldValue;
                setOptionsEditFrame({ ...optionsEditFrame }); //update screen
              } else {
                window.location.reload();
              }
            });
          }}
          enableAddAthlete={loggedUserPermission != t('user.box19')}
          updateAthletesWorkflow={updateAthletesWorkflow}
        />
      ),
    },
    {
      label: t('proj.text6'),
      link: `${baseURL}/time-sombra`,
      params: { id },
      component: <ShadowTeam />,
    },
    {
      label: t('user.text2'),
      link: `${baseURL}/historico`,
      params: { id },
      component: <History />,
    },
    {
      label: t('user.text3'),
      link: `${baseURL}/usuarios`,
      params: { id },
      component: <Users />,
    },
  ];

  const isNotAthleteOpened = isNaN(
    Number(
      useLocation()
        .pathname.split('/')
        .pop()
    )
  );
  return (
    <div>
      {project.error === null ? (
        <Page
          //icon={targetIcon}
          customTopBar={<TopBar project={project.data} />}
          title={t('home.text10')}
          loading={project.loading}
        >
          <div>
            {isNotAthleteOpened ? (
              <>
                <NavDrawer isOpen={isOpenFilter} hide={() => setIsOpenFilter(!isOpenFilter)}>
                  <AthleteFilter
                    visible={isOpenFilter}
                    onApply={() => {
                      setIsOpenFilter(false);
                      setIsOpenFilterResult(true);
                    }}
                  />
                </NavDrawer>
                <NavDrawer isOpen={isOpenFilterResult} hide={() => setIsOpenFilterResult(!isOpenFilterResult)}>
                  <AthleteFilterResult
                    back={() => {
                      setIsOpenFilterResult(false);
                      setIsOpenFilter(true);
                    }}
                    openFilter={setIsOpenFilterResult}
                    athletesWorkflow={project.data.workflow}
                    frame={frameFilter}
                    projectId={id}
                    setUpdateAthletesWorkflow={setUpdateAthletesWorkflow}
                  />
                </NavDrawer>
                <NavigationBar items={items} />
              </>
            ) : (
              <></>
            )}
            <Dialog
              isOpen={optionsEditFrame.isOpen}
              onClose={() => setOptionsEditFrame({ isOpen: false, frame: '', description: '', saving: false })}
              mainTitle="Editar Workflow"
              btnLeft={t('buttons.cancel')}
              btnLeftClick={() => setOptionsEditFrame({ isOpen: false, frame: '', description: '', saving: false })}
              btnRight={t('buttons.save')}
              btnRightDisabled={optionsEditFrame.saving}
              btnRightClick={() => {
                setOptionsEditFrame({ ...optionsEditFrame, saving: true });
                let frame = optionsEditFrame.frame;
                if (frame.length === 0) {
                  frame = Helpers.normalizedString(optionsEditFrame.description);
                }
                const oldValue = project.data.frames[frame];
                project.data.frames[frame] = optionsEditFrame.description;
                Creators.updateProjectFrames(project.data.id, project.data.frames).then(result => {
                  if (result?.error === undefined) {
                    project.data.frames = result.data.frames;
                    setOptionsEditFrame({ isOpen: false, frame: '', description: '', saving: false });
                  } else {
                    dispatch(
                      layoutActions.showToast({
                        content: <span style={{ padding: '20px 0' }}>{t(result.error)}</span>,
                        duration: 3000,
                      })
                    );
                    project.data.frames[frame] = oldValue;
                    setOptionsEditFrame({ ...optionsEditFrame, saving: false });
                  }
                });
              }}
            >
              <span style={{ marginBottom: '10px', marginLeft: '5px' }}>{t('agencies.name')}:</span>
              <Input
                value={optionsEditFrame.description}
                placeholder={t('agencies.name')}
                onChange={e => {
                  e.persist();
                  setOptionsEditFrame({ ...optionsEditFrame, description: e.target.value });
                }}
                rounded
                style={{ marginTop: '10px' }}
              />
            </Dialog>
            <Dialog
              isOpen={deleteFrame}
              onClose={() => {
                setDeleteFrame(false);
              }}
              mainTitle={t('proj.deleteTitle')}
              btnLeft={t('buttons.cancel')}
              btnLeftClick={() => {
                setDeleteFrame(false);
              }}
              btnRight={t('buttons.excluir')}
              btnRightClick={() => {
                setConfirmDelete(true);
              }}
            >
              <span className={styles.deleteMessage}>{t('proj.deleteMessage')}</span>
            </Dialog>
            <Switch>
              {items.map(item => (
                <Route key={item.label} exact path={item.link} render={() => item.component} />
              ))}
              <Route path={`${baseURL}/:id`} render={() => <WorkflowAthlete projectId={id} />} />
              <Redirect to={baseURL} />
            </Switch>
          </div>
        </Page>
      ) : (
        <ModalErroPermission isOpen={true} link="/projetos" />
      )}
    </div>
  );
}

export default withRouter(Workflow);
