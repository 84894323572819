import styled from 'styled-components';
import Variables from 'variables';
import { Card as DefaultCard, Avatar as DefaultAvatar } from 'components/template'

export const Card = styled(DefaultCard)`
  display: flex;
  align-items: center;
  padding: 0;
`;

export const Avatar = styled(DefaultAvatar)`
  height: 40px;
  width: 40px;
`;

export const ProfileData = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const UserName = styled.span`
  color: ${Variables.lightPurple1};
  font-weight: 600;
`;

export const UserEmail = styled.span`
  font-size: small;
`;