import React from 'react';
import styles from './Users.module.scss';
import { Avatar } from 'components/template';
import { useTranslation } from 'react-i18next';

export default function Users({ club }) {
  const { t } = useTranslation();

  const concatName = (name, lastName) => {
    if (lastName) {
      return `${name} ${lastName}`;
    }
    return name;
  };

  return (
    <div className={styles.usersContainer}>
      <h1 className={styles.title}>{t('user.text3')}</h1>
      {club?.users?.map((user, index) => {
        return (
          <div className={styles.athleteContainer} key={user.id}>
            <div className={styles.left}>
              <Avatar img={user.photo} name={concatName(user.first_name, user.last_name)} className={styles.avatar} />
            </div>
            <div className={styles.middle}>
              <span className={styles.name}>{concatName(user.first_name, user.last_name)}</span>
              <div className={styles.info}>
                {user.occupation && <span className={styles.completeName}>{user.occupation} |</span>}
                {/* <img src={'item.org?.emblem'} alt={'item.org.name' ?? ''} draggable="false" />
                    <span className={styles.clubName}>{'item.org.name'}</span> */}
              </div>
              <div className={styles.infoCountry}>
                {/* <img src={'item.org.country?.flag'} alt={'item.org.country.name' ?? ''} draggable="false" /> */}
                {user.address && (
                  <span>
                    {user.city}, {user.address}, {user.country}
                  </span>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
