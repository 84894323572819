export default class ShadowTeamSchemas {
  static goalkeeperPosition = {
    x: 50,
    y: 95,
  };

  static ESchemasPosition = Object.freeze({
    '4-3-2-1': [
      // OK
      this.goalkeeperPosition,
      { x: 10, y: 75 },
      { x: 35, y: 75 },
      { x: 65, y: 75 },
      { x: 90, y: 75 },
      { x: 20, y: 55 },
      { x: 50, y: 60 },
      { x: 80, y: 55 },
      { x: 35, y: 40 },
      { x: 65, y: 40 },
      { x: 50, y: 25 },
    ],
    '4-2-3-1': [
      // OK
      this.goalkeeperPosition,
      { x: 10, y: 75 },
      { x: 35, y: 75 },
      { x: 65, y: 75 },
      { x: 90, y: 75 },
      { x: 35, y: 60 },
      { x: 65, y: 60 },
      { x: 10, y: 40 },
      { x: 50, y: 40 },
      { x: 90, y: 40 },
      { x: 50, y: 20 },
    ],
    '4-2-2-2': [
      // OK
      this.goalkeeperPosition,
      { x: 10, y: 75 },
      { x: 35, y: 75 },
      { x: 65, y: 75 },
      { x: 90, y: 75 },
      { x: 30, y: 55 },
      { x: 70, y: 55 },
      { x: 15, y: 40 },
      { x: 85, y: 40 },
      { x: 40, y: 22 },
      { x: 60, y: 22 },
    ],
    '4-1-4-1': [
      // OK
      this.goalkeeperPosition,
      { x: 10, y: 70 },
      { x: 35, y: 75 },
      { x: 65, y: 75 },
      { x: 90, y: 70 },
      { x: 50, y: 60 },
      { x: 10, y: 40 },
      { x: 35, y: 45 },
      { x: 65, y: 45 },
      { x: 90, y: 40 },
      { x: 50, y: 25 },
    ],
    '4-1-3-2': [
      // OK
      this.goalkeeperPosition,
      { x: 10, y: 70 },
      { x: 35, y: 75 },
      { x: 65, y: 75 },
      { x: 90, y: 70 },
      { x: 50, y: 60 },
      { x: 15, y: 35 },
      { x: 50, y: 40 },
      { x: 85, y: 35 },
      { x: 30, y: 20 },
      { x: 70, y: 20 },
    ],
    '5-4-1': [
      // OK
      this.goalkeeperPosition,
      { x: 10, y: 65 },
      { x: 30, y: 70 },
      { x: 50, y: 75 },
      { x: 70, y: 70 },
      { x: 90, y: 65 },
      { x: 10, y: 30 },
      { x: 35, y: 45 },
      { x: 65, y: 45 },
      { x: 90, y: 30 },
      { x: 50, y: 20 },
    ],
    '5-3-2': [
      // OK
      this.goalkeeperPosition,
      { x: 15, y: 60 },
      { x: 30, y: 75 },
      { x: 50, y: 75 },
      { x: 70, y: 75 },
      { x: 85, y: 60 },
      { x: 25, y: 40 },
      { x: 50, y: 40 },
      { x: 75, y: 40 },
      { x: 35, y: 20 },
      { x: 65, y: 20 },
    ],
    '4-5-1': [
      // OK
      this.goalkeeperPosition,
      { x: 15, y: 75 },
      { x: 38, y: 75 },
      { x: 62, y: 75 },
      { x: 85, y: 75 },
      { x: 10, y: 43 },
      { x: 30, y: 50 },
      { x: 50, y: 55 },
      { x: 70, y: 50 },
      { x: 90, y: 43 },
      { x: 50, y: 25 },
    ],
    '4-4-2': [
      // OK
      this.goalkeeperPosition,
      { x: 10, y: 65 },
      { x: 35, y: 75 },
      { x: 65, y: 75 },
      { x: 90, y: 65 },
      { x: 20, y: 40 },
      { x: 40, y: 55 },
      { x: 60, y: 55 },
      { x: 80, y: 40 },
      { x: 30, y: 20 },
      { x: 70, y: 20 },
    ],
    '4-3-3': [
      // OK
      this.goalkeeperPosition,
      { x: 10, y: 75 },
      { x: 35, y: 75 },
      { x: 65, y: 75 },
      { x: 90, y: 75 },
      { x: 30, y: 45 },
      { x: 50, y: 60 },
      { x: 70, y: 45 },
      { x: 10, y: 30 },
      { x: 50, y: 20 },
      { x: 90, y: 30 },
    ],
    '4-2-4': [
      // OK
      this.goalkeeperPosition,
      { x: 15, y: 75 },
      { x: 40, y: 75 },
      { x: 60, y: 75 },
      { x: 85, y: 75 },
      { x: 30, y: 55 },
      { x: 70, y: 55 },
      { x: 20, y: 35 },
      { x: 40, y: 20 },
      { x: 60, y: 20 },
      { x: 80, y: 35 },
    ],
    '3-6-1': [
      // OK
      this.goalkeeperPosition,
      { x: 25, y: 75 },
      { x: 50, y: 75 },
      { x: 75, y: 75 },
      { x: 10, y: 40 },
      { x: 25, y: 50 },
      { x: 40, y: 60 },
      { x: 60, y: 60 },
      { x: 75, y: 50 },
      { x: 90, y: 40 },
      { x: 50, y: 25 },
    ],
    '3-5-2': [
      // OK
      this.goalkeeperPosition,
      { x: 25, y: 75 },
      { x: 50, y: 75 },
      { x: 75, y: 75 },
      { x: 10, y: 40 },
      { x: 30, y: 50 },
      { x: 50, y: 55 },
      { x: 70, y: 50 },
      { x: 90, y: 40 },
      { x: 35, y: 20 },
      { x: 65, y: 20 },
    ],
    '3-4-3': [
      // OK
      this.goalkeeperPosition,
      { x: 25, y: 75 },
      { x: 50, y: 75 },
      { x: 75, y: 75 },
      { x: 15, y: 45 },
      { x: 35, y: 50 },
      { x: 65, y: 50 },
      { x: 85, y: 45 },
      { x: 20, y: 25 },
      { x: 50, y: 15 },
      { x: 80, y: 25 },
    ],
  });

  static setPositions = (schema, positions) => {
    if (!this.ESchemasPosition[schema]) {
      console.error('schema not exists');
      return positions;
    }
    return this.ESchemasPosition[schema].map((item, i) => {
      return {
        position_name: i + 1,
        ...(positions.find(i => i.position_name === item.position_name) ?? {}),
        ...{
          x: item.x,
          y: item.y,
          // x: item.x + '%',
          // y: item.y + '%',
        },
      };
    });
  };
}
