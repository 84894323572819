import Variables from 'variables';
import ShadowTeamSchemas from './schemas';

export default class ShadowTeamConsts {
  static EModels = Object.freeze({
    STANDARD: Symbol('Standard'),
    AREA: Symbol('Area'),
    RUNNER: Symbol('Runner'),
  });

  static schemas = Object.keys(ShadowTeamSchemas.ESchemasPosition);

  static schemasConfig = {};

  static models = Object.values(this.EModels).map(i => i.description);

  static initialModelField = this.models[0];

  static initialColorField = Variables.green3;

  static initialSchema = '';
}
