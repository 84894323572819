import styled from 'styled-components';
import Variables from 'variables.js';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar as TemplateAvatar } from 'components/template';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled(TemplateAvatar)`
  height: 30px;
  width: 30px;
  margin-right: 0.5rem;
`;

export const Link = styled(RouterLink)`
  display: inline;
  text-decoration: none;
  color: #5a00ff;
  font-size: 10pt;
  font-weight: 600;
  white-space: nowrap;
  @media print {
    color: ${Variables.dark};
    font-size: 9pt;
  }
`;
