import React, { useState } from 'react'

import * as S from './styles'
import Page from 'components/template/Page'
import { FaStar } from 'react-icons/fa'
import ProjectDialog from 'components/ProjectDialog';
import { useTranslation } from "react-i18next";

export default function NewProject() {
  const { t } = useTranslation();
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const showProjectDialog = () => {
    setIsOpenDialog(!isOpenDialog)
  }

  return (
    <Page
      title={t("home.text10")}
      subtitle={t("home.text11")}
      icon={<FaStar />}
    // loading={user.profile.loading}
    // error={user.profile.error}
    >
      <S.Card bordered >
        <S.Content>
          <S.Icon>
            <S.ImportIcon />
          </S.Icon>
          <S.ImportSubTitle>
            {t("home.text12")}
          </S.ImportSubTitle>
          <S.Button
            onClick={showProjectDialog}
            rounded
            color='green'
          >{t("home.text13")}</S.Button>
        </S.Content>
      </S.Card>
      <ProjectDialog
        isOpen={isOpenDialog}
        setIsOpen={setIsOpenDialog}
      />
    </Page>
  );
}
