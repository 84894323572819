import React from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Avatar, Divider } from 'components/template';

export default function Bids({ data }) {
  const { t } = useTranslation();
  const item = data.data;

  // const inList = ["Liberar", "Registro para iniciação desportiva", "Rescisão", "Prorrogacao", "Prorrogação", "Prorrogação e Alteração Salarial", "Reversão"] solicitada a remocao dos itens "prorrogacao"
  const inList = [t('home.box6'), t('home.box7'), t('home.box8'), t('home.box9')];
  const isContractDate = inList.indexOf(item?.contract_type) > -1;
  const prepName = isContractDate ? t('home.box4') : t('home.box5');
  const contractDate = isContractDate ? item?.contract_publish_date : item?.contract_expire;

  const card = (
    <div className={styles.card} key={data.id}>
      <div className={styles.header}>
        <div className={styles.left}>
          <Avatar noBorder img={item?.team?.emblem} name={item?.team?.nickname} />
        </div>
        <div className={styles.right}>
          <div className={styles.texto}>
            <span className={styles.monitor}>{item?.team?.nickname}</span> {t('home.box1')}{' '}
            <strong>{item?.contract_type}</strong> {t('home.box2')}{' '}
            <Link
              className={styles.athleteNickname}
              to={{ pathname: `/atletas/${item?.athlete?.id}`, state: { previews: window.location.pathname } }}
            >
              {item?.athlete?.nickname}
            </Link>{' '}
            {t('home.box3')}
          </div>
          <div className={styles.date}>
            <span>
              {item?.contract_publish_date &&
                t('organi.text20') + moment(item?.contract_publish_date).format('DD/MM/YYYY')}
            </span>
          </div>
        </div>
      </div>
      <Divider />
      <div className={styles.athlete}>
        <div className={styles.photo}>
          <img src={item?.athlete?.photo && (item?.athlete?.photo).split('?')[0]} alt={''} />
        </div>
        <div className={styles.info}>
          <span className={styles.nickname}>{item?.athlete?.nickname}</span>
          <span className={styles.name}>{item?.athlete?.fullname}</span>
          <span className={styles.age}>
            <b>
              {item?.athlete?.age} {t('athlete_card.idade')}
            </b>
          </span>
          <span className={styles.position}>{t(`positionOptionsRaw.${item?.athlete?.position}`)}</span>
          <span className={styles.flag}>
            {item?.athlete?.nationalities?.length > 0 && item.athlete.nationalities[0] && (
              <img src={item?.athlete?.nationalities[0]?.flag} alt={item?.athlete?.nationalities[0]?.name ?? ''} />
            )}
          </span>
        </div>
        <div className={styles.team}>
          <span className={styles.name}>
            {item?.team?.nickname}
            <Avatar noBorder noRadius img={item?.team?.emblem} name={item?.team?.nickname} />
          </span>
          <span className={styles.contractType}>{item?.contract_type}</span>
          {isContractDate && (
            <span className={styles.ends}>
              {prepName} <b>{contractDate ? moment(contractDate).format('DD/MM/YYYY') : ' - '}</b>
            </span>
          )}
          <span className={styles.registrationNumber}>Inscrição: {item?.athlete?.cbf_code || ' - '}</span>
          <span className={styles.contractNumber}>Nº Contrato: {item?.contract_number || ' - '}</span>
        </div>
      </div>
    </div>
  );

  return <div className={styles.container}>{card}</div>;
}
