import React, { useState, useEffect } from 'react';
import { Dialog, Card, Checkbox, Input, Spinner, IconButton, Button, Radio } from 'components/template';
import { MdClear, MdEdit } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import styles from './DialogContent.module.scss';
import { FaSearch } from 'react-icons/fa';
import { AiFillPlusCircle, AiFillDelete } from 'react-icons/ai';
import Variables from 'variables';

function DialogContent({ 
  loggedUser, 
  setIsOpen, 
  list, 
  getList, 
  createList, 
  editList, 
  deleteList, 
  athlete, 
  addMonitor, 
  deleteMonitor, 
  onAthleteListChange, 
  projects, 
  getProjects, 
  workflows, 
  getWorkflows, 
  createWorkflows,
  updateFrameWorkflows,
  deleteWorkflow,
}) {
  const { t } = useTranslation();  

  const [monitoringLists, setMonitoringLists] = useState(list.data.filter(item => item.name !== '_DEFAULT_'));
  const [displaingList, setDisplainglist] = useState(monitoringLists);
  const [projectLists, setProjectLists] = useState(projects?.data?.results ?? []);
  const [displaingProjectList, setDisplaingProjectList] = useState(projectLists);
  const [checkedLists, setCheckedLists] = useState([]);
  const [newListName, setNewListName] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const [searchValProject, setSearchValProject] = useState('');
  const [listCreation, setListCreation] = useState(false);
  const [savingId, setSavingId] = useState(null);
  const [editListName, setEditListName] = useState('');
  const [listDeletion, setListDeletion] = useState({
    isOpen: false,
    item: null,
  });
  const [listEdit, setListEdit] = useState({
    isOpen: false,
    item: null,
  });
  const [activeTab, setActiveTab] = useState(1);
  const [projectStep, setProjectStep] = useState(1);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedFrame, setSelectedFrame] = useState(null);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [toDelete, setToDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(selectedProject && selectedProject?.id){        
      getWorkflows(selectedProject.id);
    }
  }, [selectedProject]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(selectedProject && selectedProject?.workflow_id){        
      const selectedWorkflowFrame = workflows?.data?.workflow?.find(w => w.id === selectedProject?.workflow_id)?.frame;
      setSelectedFrame(selectedWorkflowFrame);
    }
  }, [workflows, selectedProject])

  useEffect(() => {
    if (!listCreation) {
      setNewListName('');
      setTimeout(function () {
        setIsOpen(true);
      }, 200);
    }
  }, [listCreation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!listDeletion.isOpen) {
      setTimeout(function () {
        setIsOpen(true);
      }, 200);
    }
  }, [listDeletion]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const isLoading = list?.loading ?? true;
    const displaingListIsEmpty = (displaingList ?? []).length === 0;
    const notHaveAthleteCount = displaingListIsEmpty || displaingList[0].athlete_count == null;
    if (!isLoading && notHaveAthleteCount) {
      getList();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps  

  useEffect(() => {
    if (list.data.length !== 0) {
      const defaultList = list.data.find(item => item.name === '_DEFAULT_');
      const withoutDefault = list.data.filter(item => item.name !== '_DEFAULT_');
      setMonitoringLists(withoutDefault);
      setDisplainglist(withoutDefault);

      const athleteLists = athlete?.lists ?? [];
      setCheckedLists(athleteLists.filter(id => id !== defaultList?.id));
      onAthleteListChange();
    }
  }, [list.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(true);
    if (projects?.data) {
      setProjectLists(projects.data.results);
      handleInput(searchVal);
      handleInputProject(searchValProject);
      setLoading(false);
    }
  }, [projects]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCheck = async id => {
    setSavingId(id);
    console.log('listas', list);
    if (checkedLists.some(list => list === id)) {      
      //delete
      console.log(athlete.id, id);
      const deleted = await deleteMonitor(athlete.id, id);
      console.log('delete', deleted);
      if (deleted) {
        athlete.lists = athlete.lists.filter(list => list !== id);
        onAthleteListChange();
        setCheckedLists(checkedLists.filter(list => list !== id));
      }
    } else {      
      //add
      const added = await addMonitor(athlete.id, id);
      if (added) {
        athlete.lists.push(id);
        onAthleteListChange();
        setCheckedLists([...checkedLists, id]);
      }      
    }
    await getList();
    setSavingId(null);
  }

  async function handleSaveWorkflow() {    
    try {
      if (toDelete){
        await deleteWorkflow(selectedProject.id, selectedProject.workflow_id);
      }else {
        if (selectedProject.workflow_id == null) {              
          await createWorkflows(selectedProject.id, selectedFrame, athlete.id);
        } else if (selectedFrame !== null && selectedProject.workflow_id !== null) {      
          await updateFrameWorkflows(selectedProject.id, selectedProject.workflow_id, selectedFrame, 0);
        }
      }
    } catch (error) {
      console.error(t('dialog_content.unexpectedError'), error);
    }
    handleStep(1);
    getProjects();
  }

  function handleInput(value) {
    setSearchVal(value);

    if (value !== '') {
      setDisplainglist(
        monitoringLists.filter(item => {
          return item?.name && item.name.toLowerCase().search(value.toLowerCase()) !== -1;
        })
      );
    } else {
      setDisplainglist(monitoringLists);
    }
  }

  function handleInputProject(value) {
    setSearchValProject(value);

    if (value !== '') {
      setDisplaingProjectList(
        projectLists.filter(item => {
          return item?.name && item.name.toLowerCase().search(value.toLowerCase()) !== -1;
        })
      );
    } else {
      setDisplaingProjectList(projects.data.results);
    }
  }

  function selectProject(item) {
    setSelectedProject(item);
  }

  function mountDisplay(list) {        
    return list.map(item => {
      const isMyItem = loggedUser.id === item.owner || loggedUser.plan.mensageria;      
      return (
        <div className={styles.listCheckboxs}>                                    
          <div
            onClick={() =>{
              if (isMyItem) {
                setListDeletion({
                  isOpen: true,
                  item,
                })
              }
            }}
            className={isMyItem ? styles.divDelete : styles.divDisable }
          >
            <AiFillDelete color={isMyItem ? Variables.lightPurple2 : Variables.gray5} />
          </div>                  
          <div
            onClick={() =>{
              if(isMyItem) {
                setEditListName(item.name);          
                setListEdit({
                  isOpen: true,
                  item,
                })
              }
            }}
            className={isMyItem ? styles.divDelete : styles.divDisable }
          >
            <MdEdit color={isMyItem ? Variables.lightPurple2 : Variables.gray5}/>
          </div>
          <Spinner 
            width="16" 
            height="16" 
            className={styles.spinnerSaving} 
            style={{ display: savingId === item.id ? 'block' : 'none' }} 
          />
          <Checkbox
            onChange={() => {
              if (savingId == null) {
                console.log('Teste desmarcar', item);
                handleCheck(item.id);
              }
            }}
            checked={checkedLists.some(id => id === item.id)}
            className={savingId === item.id ? styles.checkboxItemSaving : styles.checkboxItem}
            key={item.id}
          />
          <small style={{display: 'flex', flexDirection: 'row'}}>{item.name} 
            { item?.athlete_count >= 0
                ? ` (${item?.athlete_count})` 
                : '(1)'
            }
          </small>
        </div>
      );
    })
  }  

  function handleStep(step) {
    setTimeout(() => setProjectStep(step), 100);
  }


  const onCreateList = async () => {
    if (monitoringLists.some(list => list.name === newListName)) {
      setShowValidationMessage(true);
    } else {
      setShowValidationMessage(false);
      await createList({ name: newListName });      
      setListCreation(false);      
    }
  }

  let content = null;

  if (list.loading) {
    content = (
      <div className={styles.loaderContainer}>
        <Spinner width="50" height="50" />
      </div>
    );
  } else if (list.error) {
    content = (
      <Card className={styles.errorContainer}>
        <h2>{t(list.error)}</h2>
      </Card>
    );
  } else {
    content = (
      <>
        {/* Lists */}
        <div className={projectStep === 2 
          ? `${styles.monitoringTitle} ${styles.borderBottom}`:
          styles.monitoringTitle}>
          <span>
            {projectStep === 2 ? t('dialog_content.selectStep') : t('dialog_content.include')}
          </span>
          <MdClear onClick={() => setIsOpen(false)} />
        </div>
        {projectStep === 1 &&
        <div className={styles.tabs}>  
            <div className={activeTab === 1 
              ? `${styles.tab} ${styles.active}` 
              : styles.tab}
              onClick={() => setActiveTab(1)}
            >
              {t('agencies.lists')}
            </div>
            <div className={activeTab === 2 
              ? `${styles.tab} ${styles.active}` 
              : styles.tab}
              onClick={() => {
                setActiveTab(2);
                getProjects();
              }}
            >
              {t('proj.linkProjetos')}
            </div>            
        </div >}
        {activeTab === 1 && <>
          <div className={styles.contentContainer}>
            <Input
              value={searchVal}
              placeholder={t('rest.box34')}
              onChange={event => handleInput(event.target.value)}
              containerClassName={styles.inputContainer}
              rounded
              icon={<FaSearch />}
              iconPosition="right"
            />

            {/* Checkboxes */}
            {displaingList && <>
                <h3>{t('dialog_content.myLists')}</h3>
                {mountDisplay(displaingList.filter(item => loggedUser.id === item.owner))}
                <h3>{t('dialog_content.orgLists')}</h3>
                {mountDisplay(displaingList.filter(item => loggedUser.id !== item.owner))}
              </>
            }
          </div>
        </>}
        {activeTab === 2 && <>          
          <div className={projectStep === 1 ? styles.contentContainer : `${styles.contentContainer} ${styles.contentContainerLarge}`}>          
            {projects?.loading || loading ? 
              <div style={{
                textAlign: 'center', 
                alignContent: 'center', 
                height: 'inherit'
              }}>
                <Spinner width="50" height="50" />
              </div>
            : <>
              {projectStep === 1 && <>
                <Input
                  value={searchValProject}
                  placeholder={t('rest.box34')}
                  onChange={event => handleInputProject(event.target.value)}
                  containerClassName={styles.inputContainer}
                  rounded
                  icon={<FaSearch />}
                  iconPosition="right"
                />            
                {displaingProjectList && <>
                  <h3>{t('dialog_content.myProjects')}</h3>              
                  {displaingProjectList && displaingProjectList.map(item => {
                    return (<div className={`${styles.listCheckboxs} ${styles.cursorPointer}`} 
                        onClick={() => {                
                          handleStep(2)
                          selectProject(item);
                        }}
                      >
                      <Checkbox
                        checked={item.workflow_id !== null}
                        className={savingId === item.id ? styles.checkboxItemSaving : styles.checkboxItem}
                        key={item.id}
                      />
                      <small>{item.name}{item?.athlete_count ? ` (${item.athlete_count})` : ' (0)'}</small>
                    </div>);
                  })}
                </>
                }
              </>}
              {projectStep === 2 && <>
                <p>
                  <b>{t('report.project')}</b><br/>
                  {selectedProject.name}
                </p>              
                <h3>{t('dialog_content.includeStep')}</h3>
                { workflows && workflows.loading ? 
                  <Spinner width="50" height="50" /> 
                : workflows?.error !== null ? 
                  <p>{t(workflows?.error)}</p> 
                : <div className={styles.radioContainer} >
                    {workflows?.data?.frames && Object.entries(workflows?.data?.frames).map(([key, value]) => {
                      return <Radio label={value} 
                        checked={selectedFrame === key}
                        onChange={() => {
                          setSelectedFrame(key);
                          setToDelete(false);
                        }}
                      />                    
                    })}
                    { selectedProject.workflow_id !== null && selectedProject?.users__permission !== 'Editor' && 
                      <Radio label={t('dialog_content.removeFromProject')} 
                      checked={selectedFrame === '-deleteProject-opt'}
                      className={styles.radioDelete}
                      onChange={() => {
                        setSelectedFrame('-deleteProject-opt');
                        setToDelete(true);
                      }}
                    />   
                    }                  
                  </div>
                }
              </>}
            </>}
          </div>
        </>}
        
          <div className={styles.buttonsContainer}>
            {activeTab === 1 &&
              <IconButton
                className={styles.add_button}
                text={t('rest.box35')}
                icon={<AiFillPlusCircle />}
                onClick={() => setListCreation(true)}
              >
                {t('dialog_content.new_create')}
              </IconButton>          
            }
            {activeTab === 2 && projectStep === 2 &&
              <div className={styles.btnContainer}>
                <Button
                  className={styles.projectsBtn}               
                  icon={<AiFillPlusCircle />}
                  onClick={() => { handleStep(1) }}
                  color={'white'}                
                >
                  {t('dialog_content.cancel')}
                </Button>
                <Button
                  className={styles.projectsBtn}           
                  icon={<AiFillPlusCircle />}                  
                  onClick={() => { handleSaveWorkflow() }}
                  color={'green'}                
                >
                  {t('dialog_content.save')}
                </Button>
              </div>
            }
          </div>
        
      </>
    );
  }

  return (
    <>
      <Card className={`${styles.card} ${styles.largeMenu}`} softShadow>
        {content}
      </Card>
      <Dialog
        isOpen={listCreation}
        onClose={() => setListCreation(false)}
        mainTitle={t('dialog_content.list')}
        btnLeft={t('dialog_content.cancel')}
        btnLeftClick={() => setListCreation(false)}
        btnRight={t('dialog_content.create')}
        btnRightClick={onCreateList}
        className={{
          body: styles.bodyDialog,
        }}
      >
        <span>{t('agencies.name')}:</span>
        <Input
          value={newListName}
          onChange={e => setNewListName(e.target.value)}
          placeholder={t('dialog_content.text12')}
          inputClassName={styles.inputCreate}
        />
        {showValidationMessage && <h2 className={styles.validationMessage}>{t('dialog_content.warn')}</h2>}
      </Dialog>
      <Dialog
        isOpen={listDeletion.isOpen}
        onClose={() => {
          setListDeletion({ isOpen: false, item: null });
        }}
        mainTitle={t('proj.deleteTitle')}
        btnLeft={t('dialog_content.cancel')}
        btnLeftClick={() => {
          setListDeletion({ isOpen: false, item: null });
        }}
        btnRight={t('buttons.excluir')}
        btnRightClick={() => {
          deleteList(listDeletion.item.id);
          setListDeletion({ isOpen: false, item: null });
          setTimeout(function () {
            setIsOpen(true);
          }, 200);
        }}
        className={{container: styles.customContainerWidth}}
      >
        <div style={{textAlign: 'center', padding: '0 15px'}}>
          <div style={{fontWeight: '600', fontSize: '16px', lineHeight: '19.2px', marginBottom: '10px' }}>
            {t('dialog_content.deleteMessage')} 
            <span style={{color: '#5A00FF'}}>{listDeletion?.item?.name ?? ''}</span>?
          </div>
          <span style={{ marginBottom: '10px', fontSize: '16px', lineHeight: '19.2px' }}>
            {t('dialog_content.the')}
            {listDeletion?.item?.athlete_count}
            {listDeletion?.item?.athlete_count === 1 
              ? t('dialog_content.toBeDeletedSingular') 
              : t('dialog_content.toBeDeleted')
            }
          </span>
        </div>
      </Dialog>
      <Dialog
        isOpen={listEdit.isOpen}
        onClose={() => {
          setListEdit({ isOpen: false, item: null });
        }}
        mainTitle={t('dialog_content.editList')}
        btnLeft={'Cancelar'}
        btnLeftClick={() => {
          setListEdit({ isOpen: false, item: null });
        }}
        btnRight={'Salvar'}
        btnRightClick={() => {
          editList(listEdit.item.id, editListName);
          setListEdit({ isOpen: false, item: null });
          setTimeout(function () {
            setIsOpen(true);
          }, 200);
        }}
        className={{container: styles.customContainerWidth}}
      >
        <Input value={editListName} onChange={e => setEditListName(e.target.value)} />
      </Dialog>
    </>
  );
}

export default DialogContent;
