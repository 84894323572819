import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DashboardLogo from 'assets/ico/menu/dashboard.svg';
import styles from "./Dashboard.module.scss"
import TopBar from 'components/template/TopBar/TopBar';
import Card from './Card/Card';
import ClubLogo from 'assets/ico/menu/clubs.svg';
import AgentsLogo from 'assets/ico/menu/agents.svg';
import TransferMarketLogo from 'assets/ico/menu/transfermarket.svg';
import MarketAnalysisLogo from 'assets/ico/menu/marketanalysis.svg';
import ChatLogo from 'assets/ico/menu/chat.svg';
// import LanguageLogo from 'assets/ico/menu/language.svg';
import { getDashboardTeams } from "store/ducks/dashboardTeams";
import { getDashboardTransferMarket } from "store/ducks/dashboardTransferMarket";
import { getDashboardMarketAnalysis } from "store/ducks/dashboardMarketAnalysis";
import { getDashboardChat } from "store/ducks/dashboardChat";
const Dashboard = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const dashboardTeams = useSelector(state => state.dashboardTeams);
    const [transferMarketFilter, setTransferMarketFilter] = useState(false);
    const dashboardTransferMarket = useSelector(state => state.dashboardTransferMarket);
    const dashboardMarketAnalysis = useSelector(state => state.dashboardMarketAnalysis);
    const dashboardChat = useSelector(state => state.dashboardChat);

    useEffect(() => {
        dispatch(getDashboardTeams());
        dispatch(getDashboardTransferMarket({is_active: transferMarketFilter }));
        dispatch(getDashboardMarketAnalysis({days_ago: 30}));
        dispatch(getDashboardChat({days_ago: 30}));
    }, [])//eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{        
        dispatch(getDashboardTransferMarket({is_active: transferMarketFilter }));
    }, [transferMarketFilter])//eslint-disable-line react-hooks/exhaustive-deps

    const days_ago_filter = {
        days_ago: [{
            label: t('dashboard.last_30'),
            value: '30'
        },{
            label: t('dashboard.last_60'),
            value: '60'
        },{
            label: t('dashboard.last_90'),
            value: '90'
        }]
    }

    const handleAnalysisFiltersChange = (newFilters) => {        
        dispatch(getDashboardMarketAnalysis(newFilters));
    };

    const handleChatFiltersChange = (newFilters) => {        
        dispatch(getDashboardChat(newFilters));
    };

    return (<div className={styles.container}>
        <TopBar
            title={t('nav_drawer.dashboard')}
            subtitle={'Indicadores gerais do Footlink'}
            icon={<img src={DashboardLogo} alt={t('nav_drawer.dashboard')}/>}  
            className={styles.TopBar}
        />
            <div className={styles.content}>
                <Card 
                    title={t('dashboard.clubs')}
                    subtitle={t('dashboard.numer_of_clubs')}
                    icon={ClubLogo}
                    bgColor="bgGreen"
                    total={dashboardTeams?.data?.teamsCount?.total}
                    tabs={dashboardTeams?.data?.teamsCount?.tabs}
                    bars={dashboardTeams?.data?.teamsCount?.bars}
                    loading={dashboardTeams.loading}
                />                                
                <Card
                    title={t('dashboard.agents')}
                    subtitle={t('dashboard.numer_of_agents')}
                    icon={AgentsLogo}
                    total={dashboardTeams?.data?.agencyCount?.total}
                    tabs={dashboardTeams?.data?.agencyCount?.tabs}
                    bars={dashboardTeams?.data?.agencyCount?.bars}
                    loading={dashboardTeams.loading}
                />                
                <Card 
                    title={t('dashboard.transfer_market')}
                    subtitle={t('dashboard.number_of_ads')}
                    icon={TransferMarketLogo}
                    bgColor="bgOrange"
                    total={dashboardTransferMarket?.data?.total}
                    tabs={dashboardTransferMarket?.data?.tabs}
                    bars={dashboardTransferMarket?.data?.bars}
                    filters={{
                        boolean: {
                            label: t('dashboard.transfer_market_filter'),
                            fieldName: 'is_active'
                        },
                    }}
                    onFiltersChange={() => setTransferMarketFilter(prevState => !prevState)}
                    loading={dashboardTransferMarket?.loading}
                />
                <Card 
                    title={t('dashboard.chat')}
                    subtitle={t('dashboard.number_of_chats')}
                    icon={ChatLogo}
                    bgColor="bgPurple"
                    total={dashboardChat?.data?.total}
                    tabs={dashboardChat?.data?.tabs}
                    bars={dashboardChat?.data?.bars}
                    filters={days_ago_filter}
                    onFiltersChange={handleChatFiltersChange}
                    loading={dashboardChat?.loading}
                />
                <Card 
                    title={t('dashboard.market_analysis')}
                    subtitle={t('dashboard.number_of_monitored_players')}
                    icon={MarketAnalysisLogo}
                    bgColor="bgBrown"
                    filters={days_ago_filter}
                    onFiltersChange={handleAnalysisFiltersChange}
                    total={dashboardMarketAnalysis?.data?.total}
                    tabs={dashboardMarketAnalysis?.data?.tabs}
                    bars={dashboardMarketAnalysis?.data?.bars}
                    loading={dashboardMarketAnalysis?.loading}
                />
                {/*
                <Card 
                    title={'Acessos'}
                    subtitle={'Nº de Anúncios'}
                    icon={LanguageLogo}
                    bgColor="bgPurple"
                    filters={{
                        days_ago: [{
                            label: 'Últimos 30 dias',
                            value: '30'
                        },{
                            label: 'Últimos 60 dias',
                            value: '60'
                        },{
                            label: 'Últimos 90 dias',
                            value: '90'
                        }]
                    }}
                    onFiltersChange={handleCardFiltersChange}
                    total={0}
                    tabs={tabs}
                    bars={bars}
                    loading={false}
                /> */}
            </div>
        </div>
    )
}

export default Dashboard;