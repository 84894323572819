import React from 'react';
import PropTypes from 'prop-types';

import styles from './Pagination.module.scss';
import { Button, Select } from 'components/template';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { FaAngleDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export default function Pagination({
  options,
  left,
  count,
  hasNext,
  hasPrevious,
  pagination,
  setPagination,
  resourceDisplayName,
}) {
  const { t } = useTranslation();
  const navigate = to => {
    let newOffset;

    switch (to) {
      case 'next':
        newOffset = pagination.offset + pagination.limit;
        break;
      case 'previous':
        newOffset = pagination.offset - pagination.limit;
        break;
      default:
        return;
    }

    if (newOffset < 0) {
      newOffset = 0;
    } else if (newOffset > count) {
      newOffset = count - pagination.limit;
    }

    setPagination({ ...pagination, offset: newOffset });
  };

  const integerFormat = x => x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const initPage = pagination.offset + 1;
  let finalPage = pagination.offset + pagination.limit;
  if (finalPage > count) {
    finalPage = count;
  }

  let internalHasNext = hasNext;
  if (internalHasNext == null) {
    internalHasNext = finalPage < count;
  }

  let internalHasPrevious = hasPrevious;
  if (internalHasPrevious == null) {
    internalHasPrevious = pagination.offset > 0;
  }

  return (
    <>
      <div className={styles.spacer} />
      <div
        className={styles.container}
        style={{
          width: `calc(100% - ${left ?? 0}px)`,
          left: `${left ?? 0}px`,
        }}
      >
        <div className={styles.content}>
          <div className={styles.pagination}>
            {count > 0 ? (
              <>
                <span>
                  <strong>{integerFormat(initPage)}</strong> - <strong>{integerFormat(finalPage)}</strong>{' '}
                  {t('app.text5')} <strong>{integerFormat(count)}</strong> {resourceDisplayName.toLowerCase()}
                </span>
              </>
            ) : (
              <span>
                .. - .. {t('app.text5')} .. {resourceDisplayName.toLowerCase()}
              </span>
            )}
            <div className={styles.divButtons}>
              <Button disabled={!internalHasPrevious} onClick={() => navigate('previous')} noShadow>
                <FiChevronLeft />
              </Button>
              <Button disabled={!internalHasNext} onClick={() => navigate('next')} noShadow>
                <FiChevronRight />
              </Button>
            </div>
          </div>
          <div className={styles.itensPerPage}>
            <span>
              {resourceDisplayName} {t('app.text6')}
            </span>
            <Select
              value={pagination.limit.toString()}
              onChange={event => setPagination({ ...pagination, limit: Number(event.target.value) })}
              options={options}
            />
            <FaAngleDown />
          </div>
        </div>
      </div>
    </>
  );
}

Pagination.defaultProps = {
  options: [
    {
      label: 20,
      value: 20,
    },
    {
      label: 30,
      value: 30,
    },
    {
      label: 50,
      value: 50,
    },
    {
      label: 150,
      value: 150,
    },
  ],
  hasNext: true,
  hasPrevious: true,
  resourceDisplayName: 'Itens',
};

Pagination.propTypes = {
  options: PropTypes.array,
  left: PropTypes.number,
  count: PropTypes.number.isRequired,
  hasPrevious: PropTypes.bool,
  hasNext: PropTypes.bool,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  resourceDisplayName: PropTypes.string,
};
