import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './SideInfo.module.scss';
import { useHistory } from 'react-router-dom';
import { Avatar, SocialButton, Button } from 'components/template';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SideInfoAgency from 'pages/Athlete/Overview/SideInfo/SideInfoAgency.js';

export default function SideInfo({ data, projectId, isOpen, setIsOpen }) {
  const history = useHistory();
  const { t } = useTranslation();
  const user = useSelector(state => state.user.data);

  const athleteData = data.athlete;
  let avgEvaluation = null;
  if (data.evaluations.length > 0) {
    avgEvaluation = 0;
    data.evaluations.forEach(_eval => (avgEvaluation += _eval.general_evaluation));
    avgEvaluation /= data.evaluations.length;
  }

  const country = athleteData.nationalities;

  const emblem_current_team = athleteData?.current_team?.emblem ? (
    <img src={athleteData?.current_team?.emblem} alt={''} />
  ) : (
    ''
  );
  const emblem_src_team = athleteData?.src_team?.emblem ? <img src={athleteData?.src_team?.emblem} alt={''} /> : '';

  return (
    <div className={styles.container}>
      <h1>{t('overview.text31')}</h1>
      <div className={styles.info}>
        <div className={styles.detailedInfo}>
          <Avatar noBorder img={athleteData?.photo ?? ''} name={athleteData?.fullname} className={styles.avatar} />
          <div className={styles.buttons}>
            {!user.is_only_for_projects && (
              <Button
                tip={t('proj.box1')}
                rounded
                onClick={() =>
                  history.push({
                    pathname: `/atletas/${athleteData.id}`,
                    state: { previews: window.location.pathname },
                  })
                }
              >
                Ver Perfil
              </Button>
            )}
            <Button
              tip={data.reportId ? t('proj.box2') : t('athlete_list.tip')}
              color={data.reportId ? 'orange' : 'green'}
              rounded
              onClick={() => {
                if (data.reportId) {
                  window.open(`/relatorios/${data.reportId}`);
                } else {
                  window.open(`/relatorios/criar?ids=${athleteData.id}&project=${projectId}`);
                }
              }}
            >
              {data.reportId ? t('proj.box2') : t('athlete_list.tip')}
            </Button>
          </div>
          <table>
            <tbody>
              <tr cellSpacing="10">
                <td>{t('athlete_card.fullname')}: </td>
                <td>{athleteData?.fullname}</td>
              </tr>
              <tr>
                <td>{t('athlete_card.birth')}: </td>
                <td>{athleteData.birthdate ? moment(athleteData.birthdate).format('DD/MM/YYYY') : t('club.text12')}</td>
              </tr>
              <tr>
                <td>{t('athlete_list.box3')}: </td>
                <td>{athleteData.age ? athleteData.age + t('athlete_card.idade') : t('club.text12')}</td>
              </tr>
              <tr>
                <td>{t('club.text13')}: </td>
                <td>{athleteData.height ? `${athleteData.height} cm` : `${t('club.text12')}`}</td>
              </tr>
              <tr>
                <td>{t('club.text14')}:</td>
                <td>{athleteData.weight ? `${athleteData.weight} kg` : `${t('club.text12')}`}</td>
              </tr>
              <tr>
                <td>{t('athlete_card.pos')}: </td>
                <td>{t(`positionOptionsRaw.${athleteData.position}`) ?? t('club.text12')}</td>
              </tr>
              <tr>
                <td>{t('athlete_card.foot')}: </td>
                <td>{t(`preferentialFoot.${athleteData.foot}`) ?? t('club.text12')}</td>
              </tr>
              {/*
              <tr>
                <td>{t('overview.text27')}:</td>
                <td>{athleteData.career_stats?.total_goals ?? t('club.text12')}</td>
              </tr>
              <tr>
                <td>{t('overview.text28')}:</td>
                <td>{athleteData.career_stats?.total_matches ?? t('club.text12')}</td>
              </tr>
              */}
              <tr>
                <td>{t('athlete_card.nation')}: </td>
                <td>
                  {country[0]?.flag ? (
                    <>
                      <img className={styles.flagImg} alt="flag" src={country[0]?.flag} />
                      <span>{country[0]?.name}</span>
                    </>
                  ) : (
                    <span>{country[0]?.name}</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>{t('agencies.text2')}: </td>
                <td>
                  {emblem_current_team} {athleteData?.current_team?.nickname ?? t('club.text12')}
                </td>
              </tr>
              <tr>
                <td>{t('table.contrato')}: </td>
                <td>
                  {moment(athleteData?.contract_info?.current_contract_end).format('DD/MM/YYYY') ?? t('club.text12')}
                </td>
              </tr>
              {athleteData?.src_team != null && (
                <tr>
                  <td>{t('agencies.text4')}: </td>
                  <td>
                    {emblem_src_team} {athleteData?.src_team?.nickname ?? t('club.text12')}
                  </td>
                </tr>
              )}
              {athleteData?.contract_info?.src_contract_end != null && (
                <tr>
                  <td>{t('table.contrato')}: </td>
                  <td>{moment(athleteData.contract_info.src_contract_end).format('DD/MM/YYYY')}</td>
                </tr>
              )}
              {/* <tr>
                <td>{t('GBE')}:</td>
                <td>?????</td>
              </tr> 
              <tr>
                <td>{t('organi.text14')}:</td>
                <td>{athleteData.market_value.current?.value} M €</td>
              </tr>
              {athleteData?.market_value?.greatest && (
                <tr>
                  <td>{t('overview.text29')}: </td>
                  <td>
                    {athleteData?.market_value?.greatest.value} M € {t('overview.text30')}{' '}
                    {moment(athleteData?.market_value?.greatest.date).format('DD/MM/YYYY')}
                  </td>
                </tr>
              )}
              <tr>
                <td>Valuation Footure: </td>
                <td>
                  <a
                    draggable="false"
                    href={`https://api.whatsapp.com/send?phone=${t('whatsapp.phone')}&text=${t(
                      'whatsapp.valuation1'
                    )}${athleteData?.fullname ?? ''}${t('whatsapp.valuation2')}${athleteData?.current_team?.nickname ??
                    athleteData?.current_team?.longname ??
                    ''}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className={styles.marketValueSpan}>{t('athlete_card.buyValuation')}</div>
                  </a>
                </td>
              </tr>
              */}
            </tbody>
          </table>
          {setIsOpen != null && (
            <Button
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className={styles.editAthleteButton}
              rounded
              smaller
              color="green"
            >
              {t('club.text10')}
            </Button>
          )}
          <div className={styles.socialButtons}>
            {athleteData.social?.wikipedia && (
              <SocialButton large type="wikipedia" href={athleteData.social?.wikipedia} />
            )}
            {athleteData.social?.instagram && (
              <SocialButton large type="instagram" href={athleteData.social?.instagram} />
            )}
            {athleteData.social?.twitter && <SocialButton large type="twitter" href={athleteData.social?.twitter} />}
            {athleteData.social?.youtube && <SocialButton large type="youtube" href={athleteData.social?.youtube} />}
          </div>
          <SideInfoAgency data={athleteData} user={user} />
        </div>
      </div>
    </div>
  );
}

SideInfo.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};
