import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import styles from './ResultCard.module.scss';

import { PopCard, Spinner } from 'components/template';
import ResultItem from './ResultItem/ResultItem';

import { useTranslation } from 'react-i18next';

function ResultCard({ data, isVisible, loading, hide, hasHide, onItemClick, redirectAthletes }) {
  const { t } = useTranslation();
  const hasData =
    data &&
    (data.athletes?.results?.length > 0 ||
      data.teams?.results?.length > 0 ||
      data.custom_athletes?.results?.length > 0);
  const content = loading ? (
    <Spinner width="50" height="50" className={styles.spinner} />
  ) : hasData ? (
    Object.keys(data)
      .filter(k => data[k].total > 0)
      .map(k => {
        let title = '';
        switch (k) {
          case 'athletes':
            title = t('filters.ath');
            break;
          case 'custom_athletes':
            title = t('filters.custom_ath');
            break;
          case 'teams':
            title = t('compe.text12');
            break;
          default:
            break;
        }
        return (
          <ResultItem
            key={k}
            type={k}
            title={title}
            amount={data[k].total}
            items={data[k].results}
            clubs={data.teams.total}
            // hideCard={hide}
            hideCard={onItemClick}
            redirectAthletes={redirectAthletes}
          />
        );
      })
  ) : (
    <div className={styles.nothingFound}>{t('club.box1')}</div>
  );

  return (
    <>
      <PopCard isVisible={isVisible || loading} hasHide={hasHide} hide={hide} className={styles.card}>
        {content}
      </PopCard>
    </>
  );
}

ResultCard.defaultProps = {
  data: [],
  hide: () => {},
  hasHide: true,
  onItemClick: () => {},
};

ResultCard.propTypes = {
  data: PropTypes.instanceOf(Object),
  isVisible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  hide: PropTypes.func,
  hasHide: PropTypes.bool,
  onItemClick: PropTypes.func,
  redirectAthletes: PropTypes.func.isRequired,
};

export default withRouter(ResultCard);
