import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as S from './styles';
import styles from './Projects.module.scss';
import Page from 'components/template/Page';
import CenteredLoader from 'components/template/CenteredLoader';
import { FaSearch } from 'react-icons/fa';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { Input, Menu, Pagination, Checkbox } from 'components/template';
import { usePagination } from 'shared/hooks';
import { debounce } from 'shared/utility';
import ProjectDialog from 'components/ProjectDialog';
import ProjectItem from 'components/ProjectItem';
import { Creators } from 'store/ducks/projects';
import { useTranslation } from 'react-i18next';
import IconButtonText from 'components/template/IconButtonText/IconButtonText';
import Variables from 'variables';

export default function Projects() {
  const { t } = useTranslation();
  const [projectDialog, setProjectDialog] = useState({ isOpen: false, project: undefined, updatePage: false });
  const [pagination, setPagination] = usePagination();
  const [filter, setFilter] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [order, setOrder] = useState('name');
  const [loadedProjects, setLoadedProjects] = useState(null);
  const [showArchived, setShowArchived] = useState(false);
  const projects = useSelector(state => state.project);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.data);

  useEffect(() => {
    dispatch(Creators.getProjects({ ...pagination, name: searchValue, o: order, showArchived: showArchived }));
  }, [dispatch, pagination, searchValue, order, showArchived]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (projectDialog.updatePage) {
      window.location.reload();
    }
  }, [projectDialog.updatePage]);

  useEffect(() => {
    if (projects?.data?.results) {
      setFilter(projects.data.results);
      setLoadedProjects(projects.data);
    }
  }, [projects]);

  const delayedHandleSearch = debounce(handleSearch);

  const showProjectDialog = project => {
    setProjectDialog({ isOpen: true, project: project, updatePage: false });
  };

  const handlePagination = newPagination => {
    setPagination(newPagination);
    dispatch(Creators.getProjects({ ...newPagination }));
  };

  function handleSearch(searchText) {
    const filtered = projects.data.results.filter(item => {
      return item.name.toLowerCase().includes(searchText);
    });
    setSearchValue(searchText);
    setFilter(filtered);
  }

  const hasProjects = (projects.data?.results?.length ?? 0) > 0;
  return (
    <div>
      <S.FilterDiv>
        <S.FilterInputContainer>
          <Input
            rounded
            name="name"
            onChange={e => delayedHandleSearch(e.target.value.toLowerCase())}
            inputStyle={{ width: 308 }}
            containerStyle={{ width: 'auto' }}
            placeholder={t('user.text5')}
            icon={
              <FaSearch
                style={{
                  color: Variables.lightPurple1,
                  transform: 'rotate(90deg)',
                }}
              />
            }
            iconPosition="right"
          />
          <S.FilterLabelOrder>{t('filters.order_by')}:</S.FilterLabelOrder>
          <Menu
            title={t('filters.order_by')}
            className={styles.menu}
            options={[
              // {
              //   label: t(`proj.orderCreatedAtDesc`),
              //   value: '-created_at',
              // },
              // {
              //   label: t(`proj.orderCreatedAtAsc`),
              //   value: 'created_at',
              // },
              {
                label: t(`proj.orderUpdatedAtDesc`),
                value: '-lastmodified_at',
              },
              {
                label: t(`proj.orderUpdatedAtAsc`),
                value: 'lastmodified_at',
              },
              {
                label: t(`proj.orderNameAsc`),
                value: 'name',
              },
              {
                label: t(`proj.orderNameDesc`),
                value: '-name',
              },
            ]}
            value={order}
            setValue={setOrder}
            onClear={() => setOrder('name')}
          />
          <Checkbox
              key={'showArchivedCheckbox'}              
              onChange={() => setShowArchived(!showArchived)}
              label={t('proj.showArchived')}
              checked={showArchived}              
              dark
          />
        </S.FilterInputContainer>
        {!user.is_only_for_projects && (
          <IconButtonText
            onClick={() => showProjectDialog(undefined)}
            color="lightPurple1"
            text={t('user.text6')}
            icon={<BsFillPlusCircleFill />}
          />
        )}
      </S.FilterDiv>
      {projects.loading ? (
        <CenteredLoader size="calc(100vh - 400px)" />
      ) : (
        <Page loading={projects.loading} error={t(projects.error)} customTopBar>
          {hasProjects ? (
            <>
              <S.ProjectsContainer>
                {filter?.map(project => (
                  <ProjectItem project={project} onEdit={() => showProjectDialog(project)} />
                ))}
              </S.ProjectsContainer>
              <Pagination
                length={loadedProjects?.results?.length}
                hasNext={loadedProjects?.next}
                hasPrevious={loadedProjects?.previous}
                pagination={pagination}
                count={loadedProjects?.count}
                setPagination={handlePagination}
                resourceDisplayName={t('app.text7')}
              />
            </>
          ) : (
            <S.NoResults>
              <span>{t('user.text4')}</span>
            </S.NoResults>
          )}
          <ProjectDialog projectDialog={projectDialog} setProjectDialog={setProjectDialog} />
        </Page>
      )}
    </div>
  );
}
