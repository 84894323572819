import axios from 'api';

export const validateCode = async (token, code) => {
  try {
    const { data } = await axios.post('/user-code/', {
      token,
      code,
    });
    return data === 'Successful';
  } catch (err) {
    return false;
  }
};

export const sendCode = async (token, password) => {
  try {
    const { data } = await axios.post(`/first-access/${token}`, {
      password,
    });

    if (data === 'Wrong password.') {
      return null;
    }
    return data.email;
  } catch (e) {
    return null;
  }
};
