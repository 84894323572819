import Endpoints from 'endpoints';
import axios from 'api';
import Helpers from 'shared/helpers';
import logger from 'shared/Logger';
import ErrorMessages from 'shared/ErrorMessages';

export const Types = {
  SEARCH: 'searchBar/SEARCH',
  SEARCH_SUCCESS: 'searchBar/SEARCH_SUCCESS',
  SEARCH_FAILED: 'searchBar/SEARCH_FAILED',
  SEARCH_ERROR_HANDLED: 'searchBar/SEARCH_ERROR_HANDLED',
  RESET: 'searchBar/RESET',
  HIDE: 'searchBar/HIDE',
  SHOW: 'searchBar/SHOW',
};

const initialState = {
  loading: false,
  error: null,
  data: [],
  isVisible: false,
};

export default function searchBarReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SEARCH:
      return { ...state, loading: true };
    case Types.SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        isVisible: action.payload.isVisible,
        data: action.payload.data,
      };
    case Types.SEARCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case Types.SEARCH_ERROR_HANDLED:
      return { ...state, error: null };
    case Types.HIDE:
      return { ...state, isVisible: false };
    case Types.SHOW:
      return { ...state, isVisible: true };
    case Types.RESET:
      return initialState;
    default:
      return state;
  }
}

export const Creators = {
  search: (query, setIsVisibleTrue = true) => async dispatch => {
    try {
      dispatch({ type: Types.SEARCH });

      const splitedQuery = query.split(' ').map(item => {
        return String(Helpers.stringWODiacritics(item)).toLowerCase();
      });

      let getFirstTree = [];
      if (splitedQuery.length > 3) for (let i = 0; i < 3; i++) getFirstTree.push(splitedQuery[i]);
      else getFirstTree = splitedQuery;

      const normalizedQuery = getFirstTree.join('+');
      const url = `${Endpoints.search}?q=${normalizedQuery}`;
      const { data } = await axios.get(url);

      dispatch({
        type: Types.SEARCH_SUCCESS,
        payload: { data, isVisible: setIsVisibleTrue },
      });
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response) {
        dispatch({
          type: Types.SEARCH_FAILED,
          payload: { error: 'erros.text1' },
        });
      } else {
        dispatch({
          type: Types.SEARCH_FAILED,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  reset: () => ({ type: Types.RESET }),
  hide: () => ({ type: Types.HIDE }),
  show: () => ({ type: Types.SHOW }),
};
