import React from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';

export default function Modal({ isOpen, setModalOpen, className, children }) {
  const modalStyles = [styles.container];
  if (className) modalStyles.push(className);

  if (isOpen) {
    return (
      <div className={styles.background}>
        <div className={modalStyles.join(' ') || styles.container}>
          <div className={styles.body}>{children}</div>
        </div>
      </div>
    );
  }

  return null;
}

Modal.defaultProps = {
  isOpen: false,
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.object,
};
