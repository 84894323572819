import styled from 'styled-components';

export const Container = styled.div`
    padding: 10px 20px;
    border-bottom: 0.5px solid lightgray;
`;

export const UnifiedField = styled.div`
    margin-right: 100px;
    margin-bottom: 20px;
`;

export const FieldWrapper = styled.div`
    margin-right: 20px;
    margin-bottom: 20px;
    ${props => props.width ? `width: ${props.width};` : ''}

    /* flex-grow: ${props => props.flexGrow} */
`;