// color pallete
export default class Variables {
  static black = '#000000';
  static lighterBorder = 'rgba(0, 0, 0, 0.2)';
  static darker = '#363636';
  static dark = '#4a4a4a';
  static gray2 = '#4f4f4f';
  static gray3 = '#828282';
  static gray4 = '#bdbdbd';
  static gray5 = '#e0e0e0';
  static lightGrey = '#a9a9a9';
  static lightBorder = '#BDBDBD';
  static lighterGrey = '#d7d6d2';
  static lightGrey6 = '#f2f2f2';
  static spanishWhite = '#f9f6e7';
  static dangerBg = '#ffdfdf';
  static light = '#f5f5f5';
  static white = '#ffffff';

  static warning = '#fb3';
  static danger = '#ff5252';
  static info = '#29b6f6';
  static lightBlue = '#03a9f4';
  static lightPurple1 = '#5A00FF';
  static lightPurple2 = '#6B1AFF';
  static lightPurple3 = '#EEE5FF';
  static lightPurple8 = '#CEB3FF'
  static lightPurple10 = '#EEE5FF';
  static darkPurple2 = '#120033';
  static darkPurple7 = '#3F00B3';
  static darkPurple9 = '#5100E5';
  static brown = '#755632';
  static purple9 = '#deccff';

  static orange = '#F5A623';
  static orange2 = '#F2994A';
  static orangeText = '#ce9232';

  static red = '#EB5757';

  static green1 = '#219653';
  static green2 = '#27AE60';
  static green3 = '#6FCF97';

  static pink1 = '#CC00FF';

  // media queries
  static maxSmall = '499px';
  static maxMedium = '769px';
  static minVeryLarge = '1920px';
}
