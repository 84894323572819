import React from 'react';
import PropTypes from 'prop-types';

import styles from './AthleteAvatar.module.scss';
import Avatar from 'components/template/Avatar/Avatar';

export default function AthleteAvatar({ img, name, clubImg, clubName, className, ...props }) {
  return (
    <div {...props} className={[styles.container, className].join(' ')}>
      <Avatar img={img} name={name} noBorder />
      {(clubImg ?? clubName) && <Avatar img={clubImg} name={clubName} className={styles.club} noBorder />}
    </div>
  );
}

AthleteAvatar.defaultProps = {
  img: null,
  name: '',
  clubImg: null,
  clubName: '',
  className: '',
};

AthleteAvatar.propTypes = {
  img: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  name: PropTypes.string,
  clubImg: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  clubName: PropTypes.string,
  className: PropTypes.string,
};
