import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from 'store/ducks/athleteReport';
import { getTransferMarket, resetViewedTransfermarket } from 'store/ducks/transferAthletes';
import AthleteDialog from 'components/AthleteDialog/AthleteDialog';
import { Card, Spinner } from 'components/template';
import styles from './MoreInfo.module.scss';
import SideInfo from './SideInfo/SideInfo';
import Performance from './Performance/Performance';
import Career from './Career/Career';
import { useTranslation } from 'react-i18next';
import Annoucements from './Announcements/Annoucements';
import { withRouter, useParams } from 'react-router-dom';
import TopBar from './TopBar/TopBar';

function MoreInfo({ history }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const params = useParams();
  const transferMarketId = params.id;
  const transferAthletes = useSelector(state => state.transferAthletes);
  const transferMarket = transferAthletes.viewedTransferMarket;
  const transferMarketAthleteId = transferMarket?.athlete?.id;
  const athlete = useSelector(state => state.athleteReport);

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  useEffect(() => {
    dispatch(getTransferMarket(transferMarketId));
  }, [dispatch, transferMarketId, getTransferMarket]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (transferMarketAthleteId) {
      dispatch(Creators.getAthlete([transferMarketAthleteId], true));
    }
    return () => {
      dispatch(Creators.reset());
      dispatch(resetViewedTransfermarket());
    };
  }, [transferMarketAthleteId]); // eslint-disable-line react-hooks/exhaustive-deps

  let content = '';
  if (transferAthletes.loading || athlete.loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (transferAthletes.error || athlete.error) {
    content = (
      <Card softShadow bordered className={styles.errorContainer}>
        <span>{t(transferAthletes.error || athlete.error)}</span>
      </Card>
    );
  } else if (athlete.data?.length > 0) {
    content = (
      <>
        <TopBar athlete={athlete?.data[0]?.athlete} setReportAthletes={() => { }} border />
        <Card softShadow className={styles.container} height="46rem">
          <SideInfo data={athlete.data[0]} setIsOpen={setIsOpenDialog} isOpen={isOpenDialog} />
          <div className={styles.content}>
            <Annoucements data={athlete.data[0]} athleteId={athlete.data[0].id} history={history} />
            <Performance data={athlete.data[0].historical} athleteId={athlete.data[0]?.id} redirect={history} />
            <Career
              data={athlete.data[0].transfers}
              total_amount={athlete.data[0].athlete.market_value.total_transfers_amount}
              athleteId={athlete.data[0].id}
              redirect={history}
            />
          </div>
        </Card>
        <AthleteDialog isOpen={isOpenDialog} setIsOpen={setIsOpenDialog} data={athlete.data[0]} />
      </>
    );
  }

  return <>{content}</>;
}

export default withRouter(MoreInfo);
