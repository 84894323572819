import React from 'react';
import PropTypes from 'prop-types';

import styles from './Checkbox.module.scss';

export default function Checkbox({
  label, checked, className, dark, small, ...props 
}) {
  const checkboxClasses = [styles.checkbox];
  if (dark) checkboxClasses.push(styles.dark);
  if (small) checkboxClasses.push(styles.small);
  if (checked) checkboxClasses.push(styles.checked);

  return (
    <label className={[styles.container, className].join(' ')}>
      <div className={checkboxClasses.join(' ')} />
      <input checked={checked} type='checkbox' {...props} /> {label}
    </label>
  );
}

Checkbox.defaultProps = {
  label: null,
  className: '',
  dark: false,
  small: false,
};

Checkbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  dark: PropTypes.bool,
  small: PropTypes.bool,
};
