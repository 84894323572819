import React from 'react';

// hooks
import { useTranslation } from 'react-i18next';

// scss
import styles from './NegotiationChatItem.module.scss';

// components
import { Avatar } from 'components/template';

// assets
import WithoutClub from 'assets/ico/without-club.svg';
import RightToLeft from 'assets/ico/ico-right_to_left.svg';
import LeftToRight from 'assets/ico/ico-left_to_right.svg';

// other
import moment from 'moment';

export default function NegotiationChatItem({
  chat,
  user,
  date,
  isSelected,
  alertCount,
  isAlertVisible,
  setAlertVisibility,
  handleSelectChat,
}) {
  const { t } = useTranslation();

  const containerClasses = [styles.athleteContainer];
  if (isSelected) containerClasses.push(styles.athleteSelected);

  let transferTypeComponent;
  if (user?.organization?.teamId === chat.buyer_club?.id || chat.ceding_club == null)
    transferTypeComponent = (
      <div>
        {chat.buyer_club && (
          <Avatar img={chat.buyer_club.emblem} name={chat.buyer_club.nickname} className={styles.clubFrom} noBorder />
        )}
        <img draggable="false" className={styles['buy']} src={LeftToRight} alt="buy" />
        {chat.ceding_club ? (
          <Avatar img={chat.ceding_club.emblem} name={chat.ceding_club.nickname} className={styles.clubTo} noBorder />
        ) : 
        chat.ceding_agency ? 
        <Avatar img={chat.ceding_agency.emblem} name={chat.ceding_agency.nickname} className={styles.clubTo} noBorder />
        : (
          <img draggable="false" src={WithoutClub} className={styles.clubTo} alt="ceding agency" />
        )}
      </div>
    );
  if (user?.organization?.teamId === chat.ceding_club?.id && chat.ceding_club != null)
    transferTypeComponent = (
      <div>
        {chat.ceding_club ? (
          <Avatar img={chat.ceding_club.emblem} name={chat.ceding_club.nickname} className={styles.clubFrom} noBorder />
        ) : (
          <img draggable="false" src={WithoutClub} alt="ceding agency" className={styles.clubFrom} />
        )}
        <img draggable="false" className={styles['sell']} src={RightToLeft} alt="sell" />
        {chat.buyer_club && (
          <Avatar img={chat.buyer_club.emblem} name={chat.buyer_club.nickname} className={styles.clubTo} noBorder />
        )}
      </div>
    );

  return (
    <div key={chat.id} className={containerClasses.join(' ')} onClick={() => setAlertVisibility(false)}>
      <div className={styles.link} onClick={() => handleSelectChat(chat.id)}>
        <div className={styles.left}>
          <div className={styles.clubContainer}>
            <div className={styles.athleteImg}>
              <Avatar img={chat.athlete?.photo} name={chat.athlete?.fullname} noBorder />
            </div>
            {transferTypeComponent}
          </div>
        </div>
        <div className={styles.center}>
          <span className={styles.name}>{chat.athlete?.nickname}</span>
          <span className={styles.completeName}>{chat.athlete?.fullname}</span>
          <div className={styles.info}>
            {chat.athlete?.nationalities?.length && (
              <img
                src={chat.athlete?.nationalities?.[0]?.flag}
                alt={chat.athlete?.nationalities?.[0]?.name ?? ''}
                draggable="false"
              />
            )}
            <span className={styles.age}>
              {chat.athlete?.year} {chat.athlete?.age} {t('athlete_card.idade')}
            </span>
            <span className={styles.position}>{t(`positionOptionsRaw.${chat.athlete?.position}`)}</span>
          </div>
        </div>
        <div className={styles.right}>
          <span className={styles.date}>{moment(date ?? '2000-01-01T15:05:18.347034Z').format('DD/MM/YYYY')}</span>
          {!!alertCount && isAlertVisible && (
            <div className={styles.alert}>
              <span>{alertCount}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
