class Logger {
    isDev = process.env.NODE_ENV === 'development';

    info(str) {
        if (this.isDev) console.info(str);
    }

    warn(str) {
        if (this.isDev) console.warn(str);
    }

    error(str) {
        if (this.isDev) console.error(str);
    }

    log(str) {
        if (this.isDev) console.log(str);
    }
}

export default new Logger();
