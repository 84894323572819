import Endpoints from 'endpoints';
import axios from 'api';
import ErrorMessages from 'shared/ErrorMessages';
import _ from 'lodash';

export const Types = {
    UPDATE_ATHLETE_LISTS: 'athleteReport/UPDATE_ATHLETE_LISTS',
    GET: 'athleteReport/GET',
    GET_SUCCESS: 'athleteReport/GET_SUCCESS',
    GET_FAILED: 'athleteReport/GET_FAILED',
    GET_ERROR_HANDLED: 'athleteReport/GET_ERROR_HANDLED',
    RESET: 'athleteReport/RESET',
    HIDE: 'athleteReport/HIDE',
    SHOW: 'athleteReport/SHOW',
};

const initialState = {
    loading: false,
    error: null,
    data: [],
    isVisible: false,
};

export default function athleteReportReducer(state = initialState, action) {
    switch (action.type) {
        case Types.GET:
            return { ...state, loading: true };
        case Types.GET_SUCCESS:

            // const data = [...state.data, ...action.payload.data]

            return {
                ...state,
                loading: false,
                isVisible: true,
                data: (action.payload.append ? _.uniq([...state.data, ...action.payload.data]) : action.payload.data),
            };
        case Types.GET_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case Types.GET_ERROR_HANDLED:
            return { ...state, error: null };
        case Types.HIDE:
            return { ...state, isVisible: false };
        case Types.SHOW:
            return { ...state, isVisible: true };
        case Types.RESET:
            return { ...initialState, loading: state.loading };
        case Types.UPDATE_ATHLETE_LISTS:
            return {
                ...state,
                data: state.data.map(athlete => {
                    if (athlete.id === action.payload.athleteId) {
                        return {
                            ...athlete,
                            lists: action.payload.lists,
                            counts: { ...athlete.counts, lists: action.payload.lists.length }
                        }
                    } else {
                        return athlete;
                    }
                })

            }
        default:
            return state;
    }
}

export const Creators = {
    getAthlete: (ids, hasEvaluations, loadReportIdFromProjectId = undefined) => async dispatch => {
        let output = [];
        try {
            dispatch({ type: Types.GET });


            const limitFilter = '?limit=5';

            if (hasEvaluations) {
                for (const id of ids) {
                    let urls = [
                        axios.get(`${Endpoints.athletes}/${id}/`),
                        axios.get(`${Endpoints.athletes}/${id}/historical${limitFilter}`),
                        axios.get(`${Endpoints.athletes}/${id}/evaluations${limitFilter}`),
                        axios.get(`${Endpoints.athletes}/${id}/transfers${limitFilter}`),
                        axios.get(`${Endpoints.athletes}/${id}/contracts${limitFilter}`),
                    ];
                    if (loadReportIdFromProjectId) {
                        urls.push(axios.get(`${Endpoints.reports}/?athletes=${id}&project=${loadReportIdFromProjectId}`));
                    }

                    let res1 = {};
                    let res2 = {};
                    let res3 = {};
                    let res4 = {};
                    let res5 = {};
                    let reportId = undefined;

                    await Promise.all(urls).then(function (results) {
                        res1 = results[0].data;
                        res2 = results[1].data.results;
                        res3 = results[2].data.results;
                        res4 = results[3].data.results;
                        res5 = results[4].data.results;
                        if ((results[5]?.data?.results ?? []).length > 0) {
                            reportId = results[5].data.results[0].id;
                        }
                    });

                    output.push({
                        id,
                        athlete: res1,
                        historical: res2,
                        evaluations: res3,
                        transfers: res4,
                        contracts: res5,
                        reportId: reportId,
                    })
                }

            } else {
                for (const id of ids) {
                    let urls = [
                        axios.get(`${Endpoints.athletes}/${id}/`),
                        axios.get(`${Endpoints.athletes}/${id}/historical${limitFilter}`),
                        axios.get(`${Endpoints.athletes}/${id}/transfers${limitFilter}`),
                        axios.get(`${Endpoints.athletes}/${id}/contracts${limitFilter}`),
                    ];
                    if (loadReportIdFromProjectId) {
                        urls.push(axios.get(`${Endpoints.reports}/?athletes=${id}&project=${loadReportIdFromProjectId}`));
                    }

                    let res1 = {};
                    let res2 = {};
                    let res3 = {};
                    let res4 = {};
                    let res5 = {};
                    let reportId = undefined;
                    await Promise.all(urls).then(function (results) {
                        res1 = results[0].data;
                        res2 = results[1].data.results;
                        res3 = [];
                        res4 = results[2].data.results;
                        res5 = results[3].data.results;
                        if ((results[4]?.data?.results ?? []).length > 0) {
                            reportId = results[4].data.results[0].id;
                        }
                    });

                    output.push({
                        id,
                        athlete: res1,
                        historical: res2,
                        evaluations: res3,
                        transfers: res4,
                        contracts: res5,
                        reportId: reportId,
                    })
                }
            }

            dispatch({ type: Types.GET_SUCCESS, payload: { data: output, append: false } });
        } catch (err) {
            const { response } = err;
            if (response) {
                dispatch({
                    type: Types.GET_FAILED,
                    payload: { error: "erros.text1" },
                });
            } else {
                dispatch({
                    type: Types.GET_FAILED,
                    payload: { error: ErrorMessages.serviceUnavailable },
                });
            }
        }
    },
    reset: () => ({ type: Types.RESET }),
    hide: () => ({ type: Types.HIDE }),
    show: () => ({ type: Types.SHOW }),
};