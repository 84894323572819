import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { StarRating, TextArea } from 'components/template';
import styles from './FieldItem.module.scss';

const FieldItem = ({ field, index, setField, showFieldsFeedback, enableFieldsFeedbackUpdate, onEditFinish }) => {
  const [editFeedback, setEditFeedback] = useState(false);
  const { t } = useTranslation();

  const setSubfield = newSubfield => {
    const prevField = { ...field };
    prevField.subfields.forEach((subfield, i) => {
      if (subfield.title === newSubfield.title) {
        prevField.subfields[i] = newSubfield;
      }
    });

    const subfieldsTotalRate = prevField.subfields.map(s => s.rate ?? 0).reduce((acc, rate) => acc + rate);
    prevField.rate = subfieldsTotalRate / field.subfields.length || 0;
    setField(prevField);
  };

  let editFeedbackField = '';
  if (showFieldsFeedback ?? true) {
    if (editFeedback) {
      editFeedbackField = (
        <>
          <TextArea value={field.feedback} onChange={e => setField({ ...field, feedback: e.target.value })} />
          <span
            onClick={() => {
              setEditFeedback(false);
              if (onEditFinish) {
                onEditFinish();
              }
            }}
            className={styles.feedbackTrigger}
          >
            {t('field.con')}
          </span>
        </>
      );
    } else if (field.feedback) {
      editFeedbackField = (
        <div className={styles.withFeedback}>
          <p>{field.feedback}</p>
          {enableFieldsFeedbackUpdate && (
            <span onClick={() => setEditFeedback(true)} className={styles.feedbackTrigger}>
              {t('field.edit')}
            </span>
          )}
        </div>
      );
    } else {
      editFeedbackField = enableFieldsFeedbackUpdate ? (
        <span onClick={() => setEditFeedback(true)} className={styles.feedbackTrigger}>
          {t('field.click')}
        </span>
      ) : (
        ''
      );
    }
  }

  let subfields = null;
  if (field.subfields) {
    subfields = field.subfields.map(subfield => (
      <div key={subfield.title} className={styles.subfield}>
        <h5>{subfield.title}</h5>
        <StarRating editable setValue={val => setSubfield({ ...subfield, rate: val })} rate={subfield.rate} size={20} />
      </div>
    ));
  }

  return (
    <div className={styles.fieldItem}>
      <h3>
        {index}. {field.title}
      </h3>
      <StarRating
        editable={!subfields}
        setValue={val => setField({ ...field, rate: val })}
        rate={field.rate}
        size={25}
      />
      {editFeedbackField}
      {subfields ? <div className={styles.subfields}>{subfields}</div> : null}
    </div>
  );
};

FieldItem.propTypes = {
  field: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  setField: PropTypes.func.isRequired,
};

export default FieldItem;
