import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './AthleteList.module.scss';
import { Card, Spinner, Table, Checkbox, Button } from 'components/template';
import AthleteRow from 'components/AthleteRow/AthleteRow';
import TeamRow from 'components/TeamRow/TeamRow';
import NationalitiesRow from 'components/NationalitiesRow/NationalitiesRow';
import moment from 'moment';
import { FaClipboardList } from 'react-icons/fa';
import { TbPlayFootball } from 'react-icons/tb';
import MonitoringButton from 'components/MonitoringButton/MonitoringButton';
import { useTranslation } from 'react-i18next';
import ActiveFilters from '../ActiveFilters/ActiveFilters';
import AthleteDialog from 'components/AthleteDialog/AthleteDialog';
import { FaExchangeAlt, FaSearch } from 'react-icons/fa';
import Variables from 'variables';
import { useHistory } from 'react-router-dom';

const athleteInsert = {
  athlete: {},
};
export default function AthleteList({
  activeFilters,
  countries,
  setActiveFilters,
  athletes,
  user,
  countInTransferMarket,
  queryFilters,
}) {
  const [checkedAthletes, setCheckedAthletes] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [isOpenInsertAthleteDialog, setIsOpenInsertAthleteDialog] = useState(false);
  const rawData = athletes.data;
  const { t } = useTranslation();
  const history = useHistory();

  const handleChange = athlete => {
    if (checkedAthletes.some(item => item.id === athlete.id)) {
      setCheckedAthletes(checkedAthletes.filter(item => item.id !== athlete.id));
    } else {
      setCheckedAthletes([...checkedAthletes, athlete]);
    }
  };

  useEffect(() => {
    setCheckedAthletes([]);
    setAllChecked(false);
  }, [rawData]);

  useEffect(() => {
    if (allChecked) {
      setCheckedAthletes(rawData.map(athlete => ({ id: athlete.id, name: athlete.fullname })));
    } else {
      setCheckedAthletes([]);
    }
  }, [allChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  let data = rawData
    ? rawData.map(row => {
        const checkbox = (
          <Checkbox
            dark
            checked={checkedAthletes.some(athlete => athlete.id === row.id)}
            onChange={() => {
              handleChange({ name: row.fullname, id: row.id });
            }}
          />
        );
        const athlete = <AthleteRow name={row.fullname} nickname={row.nickname} img={row.photo} id={row.id} />;
        const team = row.current_team?.nickname ? (
          <TeamRow name={row.current_team?.nickname} id={row.current_team?.id} img={row.current_team?.emblem} />
        ) : null;
        const nationalities = (
          <span className={styles.flag}>
            <NationalitiesRow nationalities={row.nationalities} athleteId={row.id} />
          </span>
        );
        const endOfContract = (
          <div style={{ textAlign: 'center', width: '80px' }}>
            {row.contract_info?.current_contract_end
              ? moment(row.contract_info?.current_contract_end).format('DD/MM/YYYY')
              : '-'}
          </div>
        );
        const age = <div style={{ textAlign: 'center', width: '36px' }}>{row.age}</div>;
        const monitoringButton = (
          <div className={styles.buttonContainer}>
            <MonitoringButton athlete={row} />
          </div>
        );

        return [
          checkbox,
          athlete,
          team,
          age,
          row.position ? t(`positionOptionsRaw.${row.position}`) : '-',
          nationalities,
          endOfContract,
          monitoringButton,
        ]; //, { rowId: row.id }
      })
    : [];

  // const handlePagination = newPagination => {
  //     setPagination(newPagination);
  //     dispatch(getAthletes({ ...filters, ...newPagination }));
  // }

  let content = '';
  if (athletes.loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (athletes.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(athletes.error)}</p>
      </Card>
    );
  } else if (athletes.data) {
    content = (
      <>
        <div className={styles.container}>
          <div className={styles.topBar}>
            <Button
              className={styles.iconButton}
              onClick={() =>
                window.open(`/relatorios/criar?ids=${checkedAthletes.map(athlete => athlete.id).join(',')}`)
              }
              color="vividGreen"
              disabled={checkedAthletes.length === 0}
            >
              <div className={styles.text}>
                {t('athlete_list.tip')}
                <FaClipboardList className={styles.icon} />
              </div>
            </Button>
            <ActiveFilters
              setActiveFilters={setActiveFilters}
              activeFilters={activeFilters}
              countries={countries}
              overlay={athletes.loading}
            />
          </div>
          {countInTransferMarket > 0 && (
            <div className={styles.actionBar}>
              <span style={{ backgroundColor: '#F5A623' }} className={styles.icon}>
                <FaExchangeAlt />
              </span>
              <div className={styles.textTransfer}>
                {t('athlete_list.existplural')}
                <b>{t('myClub.contracts.contractResumeCount_finished', { length: countInTransferMarket })}</b>{' '}
                {t('transferMarket.search_page_desc')}
              </div>
              <div
                className={styles.actionBarButton}
                onClick={() => {
                  history.push({
                    pathname: '/transfer-market',
                    state: { queryFilters },
                  });
                }}
              >
                <div className={styles.text}>
                  <FaExchangeAlt style={{ fontSize: '12px', marginTop: '3px' }} />
                  {t('transferMarket.search_page_button')}
                </div>
              </div>
            </div>
          )}
          <div className={styles.actionBar}>
            <span style={{ backgroundColor: Variables.lightPurple1 }} className={styles.icon}>
              <FaSearch />
            </span>
            <div>
              {Object.keys(activeFilters).length === 0 ? (
                <>
                  {athletes.count < 1 ? (
                    <span className={styles.registersCounter}>{t('athlete_list.noAthletes')}</span>
                  ) : (
                    <span className={styles.registersCounter}>
                      {athletes.count === 1 ? t('athlete_list.exist') : t('athlete_list.existplural')}
                      <strong>{athletes.count.toLocaleString('pt-BR')}</strong>
                      {athletes.count === 1 ? t('athlete_list.text1') : t('athlete_list.text1plural')}
                    </span>
                  )}
                </>
              ) : (
                <>
                  {athletes.count < 1 ? (
                    <span className={styles.registersCounter}>{t('athlete_list.noAthletes')}</span>
                  ) : (
                    <span className={styles.registersCounter}>
                      {t('athlete_list.founded')}
                      <strong>{athletes.count.toLocaleString('pt-BR')}</strong>
                      {athletes.count === 1 ? t('athlete_list.text2') : t('athlete_list.text2plural')}
                    </span>
                  )}
                </>
              )}
            </div>
            {user.data?.organization != null && (
              <div className={styles.actionBarButton} onClick={() => setIsOpenInsertAthleteDialog(true)}>
                <div className={styles.text}>
                  <TbPlayFootball />
                  {t('filters.add_athlete')}
                </div>
              </div>
            )}
          </div>
          <Table
            flat
            theads={[
              <Checkbox dark checked={allChecked} onChange={() => setAllChecked(!allChecked)} />,
              t('athlete_list.box1'),
              t('athlete_list.box2'),
              t('athlete_list.box3'),
              t('athlete_list.box4'),
              t('athlete_list.box5'),
              t('filters.contract_until'),
              '',
            ]}
            data={data}
            className={styles.athleteTable}
            // onClickRow={id => history.push(`/atletas/${id}`)}
          />
        </div>
        <AthleteDialog
          isOpen={isOpenInsertAthleteDialog}
          setIsOpen={setIsOpenInsertAthleteDialog}
          data={athleteInsert}
          setIsAthleteUpdated={updated => {
            if (updated) {
              if (activeFilters.custom_athletes === 'custom') {
                window.location.reload();
              } else {
                setActiveFilters({ ...activeFilters, custom_athletes: 'custom' });
              }
            }
          }}
        />
      </>
    );
  }

  return <>{content}</>;
}

AthleteList.defaultProps = {
  hasFilter: false,
};

AthleteList.propTypes = {
  hasFilter: PropTypes.bool,
};
