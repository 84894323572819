import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash/lang';

import { getCountries } from 'store/ducks/countries';
import { getTeams } from 'store/ducks/teams';

import CountryGrid from './CountryGrid/CountryGrid';
import ActionBar from './ActionBar/ActionBar';
import ClubList from './ClubList/ClubList';
import { Card, Pagination } from '../../components/template';

import { Spinner } from 'components/template';

import styles from './ForeignClubs.module.scss';
import { usePagination } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import TopBarPrincipal from '../NationalClubs/TopBar/TopBar';

function ForeignClubs() {
  const { t } = useTranslation();

  const page = {
    title: t('organi.text15'),
    subTitle: t('trello.box1'),
  };

  const initialFilters = {
    country: '',
    division: '',
    q: '',
  };

  const [filters, setFilters] = useState(initialFilters);
  const [loadingState, setLoadingState] = useState(false);

  const [pagination, setPagination, defaultPagination] = usePagination();

  const dispatch = useDispatch();

  const countries = useSelector(state => state.countries.countries);
  const teams = useSelector(state => state.teams.teams);

  const countriesData = (countries?.data ?? []).filter(c => c.code !== 'BR');

  const mounted = useRef();

  useEffect(() => {
    /* Countries */
    dispatch(getCountries({ limit: ['teams'] }));
  }, [dispatch]);

  useEffect(() => {
    /* Filters */
    if (!mounted.current) {
      mounted.current = true;
    } else {
      setPagination(defaultPagination);
    }
  }, [filters, setPagination]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    /* Pagination */
    if (!isEqual(filters, initialFilters)) {
      dispatch(getTeams({ foreign: true, ...filters, ...pagination }));
    } else {
      setLoadingState(true);
      setTimeout(() => {
        setLoadingState(false);
      }, 300);
    }
  }, [dispatch, pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSetFilter = filter => {
    let newFilters;

    switch (filter.type) {
      case 'country':
        newFilters = { ...filters, country: filter.value };
        break;
      case 'division':
        newFilters = { ...filters, division: filter.value };
        break;
      case 'q':
        newFilters = { ...filters, q: filter.value };
        break;
      default:
        newFilters = { ...filters };
        break;
    }

    setFilters(newFilters);
  };

  let content = '';

  if (countries.error || teams.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(countries.error) || t(teams.error)}</p>
      </Card>
    );
  } else if (countries.loading || teams.loading || loadingState) {
    content = (
      <div className={styles.loaderContainer}>
        <Spinner />
      </div>
    );
  } else {
    content = (
      <>
        {filters.country || filters.division || filters.q ? (
          <ClubList teams={teams} pagination={pagination} setPagination={setPagination} />
        ) : (
          <CountryGrid onSetFilter={onSetFilter} countries={countriesData} />
        )}
      </>
    );
  }

  return (
    <>
      <TopBarPrincipal />
      <div className={styles.content}>
        <div style={{ paddingLeft: '5px' }}>
          <h1 className={styles.title}>{page.title}</h1>
          <span className={styles.subTitle}>{page.subTitle}</span>
        </div>
        <ActionBar countries={countriesData} onSetFilter={onSetFilter} selectedFilters={filters} />
        <div className={styles.contentContainer}>{content}</div>
      </div>
      {filters.country && (
        <Pagination
          length={teams.data.length}
          count={teams.count}
          pagination={pagination}
          setPagination={setPagination}
          hasNext={teams.hasNext}
          hasPrevious={teams.hasPrevious}
        />
      )}
    </>
  );
}

export default ForeignClubs;
