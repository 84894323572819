import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from 'store/ducks/searchBar';
import styles from './SearchBar.module.scss';
import { useTranslation } from 'react-i18next';
import SearchIcon from 'assets/ico/appbar/search.svg';
import AdvancedSearchIcon from 'assets/ico/appbar/advanced_search.svg';
import { Input } from 'components/template';
import ResultCard from './ResultCard/ResultCard';

function SearchBar({ history, darkMode }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const searchBar = useSelector(state => state.searchBar);
  const [searchValue, setSearchValue] = useState('');
  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  });

  const searchIconClickHandler = useCallback(() => {
    if (searchValue.trim().length >= 3) {
      dispatch(Creators.search(searchValue));
    }
  }, [dispatch, searchValue]);

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setSearchValue('');
      dispatch(Creators.hide());
    }
  };

  const handleClickItem = () => {
    setSearchValue('');
    dispatch(Creators.hide());
  };

  const redirectAthletes = type => {
    sessionStorage.setItem(
      'advanced_search',
      JSON.stringify({
        keyword: searchValue,
        custom_athletes: type === 'custom_athletes' ? 'custom' : 'normal',
      })
    );
    sessionStorage.setItem('redirect', JSON.stringify(true));
    dispatch(Creators.hide());
    setSearchValue('');
    history.push('/busca-avancada');
  };

  return (
    <div className={styles.container} ref={wrapperRef}>
      <Input
        icon={
          <img 
            src={SearchIcon} 
            alt="search" 
            className={styles.searchIconTop} 
            onClick={searchIconClickHandler} 
          />
        }
        iconPosition="right"
        inputClassName={styles.input}
        value={searchValue}
        onKeyUp={e => {
          if (e.keyCode === 13) searchIconClickHandler();
        }}
        onChange={e => setSearchValue(e.target.value)}
        placeholder={t('app.box7')}
        roundedBorderWhite
        darkMode={darkMode}
      />
      <span
        onClick={() => {
          setSearchValue('');
          dispatch(Creators.hide());
          history.push('/busca-avancada');
        }}
        className={[styles.link, 'hideMobile'].join(' ')}
      >
        <img src={AdvancedSearchIcon} className={styles.advancedSearchIcon} alt="advanced search" />
      </span>
      <ResultCard
        isVisible={searchBar.isVisible}
        loading={searchBar.loading}
        data={searchBar.data}
        hasHide={false}
        onItemClick={handleClickItem}
        redirectAthletes={redirectAthletes}
      />
    </div>
  );
}

SearchBar.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(SearchBar);
