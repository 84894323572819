import Endpoints from 'endpoints';
import axios from 'api';
import { stringifyFilters } from 'shared/utility';
import { t } from 'i18next';
import { parseError } from './base';

//firebase
import { firestore } from 'App/App';
import { doc, setDoc, deleteDoc } from "firebase/firestore";
import { encryptJson } from 'shared/crypto';

export const TeamChatMessagesRepository = {
  baseUrl: Endpoints.teamChatMessages,

  list: async function (filters) {
    try {
      const queryString = stringifyFilters(filters);

      const { data } = await axios.get(`${this.baseUrl}${queryString}`);

      return data;
    } catch (err) {
      const { response } = err;
      return parseError(response);
    }
  },

  create: async function (otherOrgId, secret, fields) {
    try {
      const formData = new FormData();
      for (const [key, value] of Object.entries(fields)) {
        if (value) {
          formData.append(key, value);
        }
      }
      const { data } = await axios.post(`${this.baseUrl}`, formData);
      if (data.orgchat) {
        const firebaseData = { ...data };
        for (const key of Object.keys(firebaseData.sender)) {
          firebaseData[`sender_${key}`] = firebaseData.sender[key];
        }
        delete firebaseData.sender;
        const orgChatDocRef = doc(firestore, `orgchat-${process.env.REACT_APP_FIREBASE_SUFFIX}`, data.orgchat.toString());
        const orgChatMessageDocRef = doc(orgChatDocRef, 'data', data.id.toString());
        await setDoc(orgChatMessageDocRef, { 
          sender_id: firebaseData.sender_id,
          created_at: firebaseData.created_at,
          data: encryptJson(firebaseData, secret), //encrypt non necessary data
        });
        const orgNotifyDocRef = doc(firestore, `org-notify-${process.env.REACT_APP_FIREBASE_SUFFIX}`, otherOrgId.toString());
        const now = new Date();
        setDoc(orgNotifyDocRef, { updated_at: now.toISOString() });
      }

      return data;
    } catch (err) {
      if (err?.response?.status === 400) return parseError(err, t('negotiations.errorChatCreate'));
      return parseError(err);
    }
  },

  deleteMessages: async function (orgChatId, ids) {
    try {
      const orgChatDocRef = doc(firestore, `orgchat-${process.env.REACT_APP_FIREBASE_SUFFIX}`, orgChatId.toString());
      for (const id of ids) {
        await axios.delete(`${this.baseUrl}/${id}`);
        const orgChatMessageDocRef = doc(orgChatDocRef, 'data', id.toString());
        await deleteDoc(orgChatMessageDocRef);
      }
    } catch (err) {
      return parseError(err, t('erros.text8'));
    }
  },
};
