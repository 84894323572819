import crypto from 'crypto-js';

export const encrypt = (msg, secret) => {
    const keyutf = crypto.enc.Utf8.parse(secret);
    const iv = crypto.enc.Utf8.parse(secret);
    const config = {
        mode: crypto.mode.CBC,
        padding: crypto.pad.Pkcs7,
        iv: iv,
    };
    return crypto.AES.encrypt(msg, keyutf, config).toString();
}

export const encryptJson = (json, secret) => {
    return encrypt(JSON.stringify(json), secret);
}

export const decrypt = (msgEncrypt, secret) => {
    const keyutf = crypto.enc.Utf8.parse(secret);
    const iv = crypto.enc.Utf8.parse(secret);
    const config = {
        mode: crypto.mode.CBC,
        padding: crypto.pad.Pkcs7,
        iv: iv,
    };
    const bytes = crypto.AES.decrypt(msgEncrypt, keyutf, config);
    return bytes.toString(crypto.enc.Utf8);
}

export const decryptJson = (msgEncrypt, secret) => {
    return JSON.parse(decrypt(msgEncrypt, secret));
}