import React from 'react';
import PropTypes from 'prop-types';

import styles from './PopCard.module.scss';

import Card from '../Card/Card';
import ClickAwayListener from '../ClickAwayListener/ClickAwayListener';

export default function PopCard({
  isVisible,
  hasHide,
  hide,
  children,
  className,
  style,
  right,
  left,
  ...props
}) {
  const cardClasses = [styles.container];
  if (isVisible) cardClasses.push(styles.show);
  if (className) cardClasses.push(className);
  if (right) {
    cardClasses.push(styles.right);
  } else {
    cardClasses.push(styles.left);
  }
  return (
    <>
      {isVisible && hasHide && <ClickAwayListener onClick={hide} />}
      <Card {...props} style={style} className={cardClasses.join(' ')}>
        {children}
      </Card>
    </>
  );
}

PopCard.defaultProps = {
  className: null,
  style: null,
  right: false,
  left: true,
  hasHide: true,
  hide: () => { }
};

PopCard.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  right: PropTypes.bool,
  left: PropTypes.bool,
  hide: PropTypes.func,
  hasHide: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.instanceOf(Object),
};
