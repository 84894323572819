import React from 'react';
import styles from './SupportButton.module.scss';
import { IoHelp } from "react-icons/io5";
import { useTranslation } from 'react-i18next';

export default function SupportButton() {
    const { t } = useTranslation();

    return (
        <div
            className={styles.support}
            footlink-title={t('home.support')}
            onClick={() => window.open('https://api.whatsapp.com/send?phone=5551997823228', '_blank')}
        >
            <IoHelp />
        </div>
    );
}
