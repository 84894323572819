/*eslint-disable eqeqeq*/
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOrganization } from 'store/ducks/organization'
import Page from 'components/template/Page';
import Organization from './Organization';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import { FaCog } from 'react-icons/fa'
import * as S from './styles';
import { useTranslation } from "react-i18next";
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import SystemInfo from './SystemInfo';

function Configuration() {
    const { t } = useTranslation();
    const user = useSelector(state => state.user.data);
    const organization = useSelector(state => state.organization);
    const dispatch = useDispatch();
    const showOrgTab = user.plan.has_any_permission && !user.is_only_for_projects && user.organization != null;

    useEffect(() => {
        if (showOrgTab) {
            dispatch(getOrganization(user.organization?.id.id));
        }
    }, [dispatch, user.organization, showOrgTab])

    const navigationItems = [];
    if (showOrgTab) {
        navigationItems.push({
            label: t("organi.text4"),
            link: '/configuracoes',
            component: <Organization />
        });
    }
    navigationItems.push({
        label: t("pagina_inicial.homepage_2"),
        link: '/configuracoes/informacoes_sistema',
        component: <SystemInfo />
    });

    const routes = navigationItems.map(item => <Route key={item.label} exact path={item.link} render={() => item.component} />);

    return (
        <>
            <Page
                title={t("organi.text1")}
                subtitle={t("organi.text2")}
                icon={<FaCog />}
                error={t(organization.error)}
            >
                <NavigationBar
                    items={navigationItems}
                />
                <S.Card >
                    <Switch>
                        {routes}
                        <Redirect to="/configuracoes" />
                    </Switch>
                </S.Card>
            </Page>
        </>
    );
};

export default withRouter(Configuration);
