import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import styles from './VideoPlayer.module.scss';
import Controls from "./Controls/Controls";
import {formatTime} from "./format";
import { useSelector, useDispatch } from "react-redux";
import { likeVideo, getOriginals } from "store/ducks/originals";
import { useParams } from 'react-router-dom';

let count = 0;
const VideoPlayer = () => {
    const dispatch = useDispatch();
    const videoPlayerRef = useRef(null);
    const containerRef = useRef(null);
    const controlRef = useRef(null);
    const originals = useSelector(state => state.originals.originals);
    const { id } = useParams();
    const [playedTime, setPlayedTime] = useState(0);
    const [isProgressSet, setIsProgressSet] = useState(false);

    const [videoState, setVideoState] = useState({
        playing: true,
        muted: false,
        volume: 1.0,
        playbackRate: 1.0,
        played: 0,
        seeking: false,
        buffer: true,
        liked: false
    });

    useEffect(() => {
        // Carrega a posição salva no localStorage
        const savedProgress = localStorage.getItem('videoProgress');
        console.log('progresso anteriror', savedProgress);
        if (savedProgress) {
            setVideoState({...videoState, played: parseFloat(savedProgress)});
            setPlayedTime(parseFloat(savedProgress));
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Configura o intervalo para salvar o progresso a cada 5 segundos
        const interval = setInterval(() => {
            if (videoPlayerRef.current) {
                const currentTime = videoPlayerRef.current.getCurrentTime();
                localStorage.setItem('videoProgress', currentTime);
            }
        }, 5000);

        // Limpa o intervalo quando o componente é desmontado
        return () => clearInterval(interval);
    }, []);

    const handleEnded = () => {
        // Limpa o progresso salvo quando o vídeo termina
        localStorage.removeItem('videoProgress');
    };

    useEffect(() => {
        dispatch(getOriginals());
      }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const handleLike = () => {
        if(originals && id !== null){
            dispatch(likeVideo(id, originals.like));
        }
    };

    useEffect(() => {
        console.log('ao abrir',originals)
        if (originals && originals.id !== null) {
            setVideoState({ ...videoState, liked: originals.like });
        }
    }, [originals]);// eslint-disable-line react-hooks/exhaustive-deps

    const { playing, muted, volume, playbackRate, played, seeking, liked } =
        videoState;

    const currentTime = videoPlayerRef.current
        ? videoPlayerRef.current.getCurrentTime()
        : "00:00";
    const duration = videoPlayerRef.current
        ? videoPlayerRef.current.getDuration()
        : "00:00";

    const formatCurrentTime = formatTime(currentTime);
    const formatDuration = formatTime(duration);

    const playPauseHandler = () => {
        setVideoState({ ...videoState, playing: !videoState.playing });
    };

    const rewindHandler = () => {
        videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() - 5);
    };

    const handleFastFoward = () => {
        videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() + 10);
    };

    const handleFullScreen = () => {
        const containerElement = containerRef.current;
        if (!document.fullscreenElement && !document.webkitFullscreenElement && 
            !document.mozFullScreenElement && !document.msFullscreenElement) {
          if (containerElement.requestFullscreen) {
            containerElement.requestFullscreen();
          } else if (containerElement.mozRequestFullScreen) { // Firefox
            containerElement.mozRequestFullScreen();
          } else if (containerElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
            containerElement.webkitRequestFullscreen();
          } else if (containerElement.msRequestFullscreen) { // IE/Edge
            containerElement.msRequestFullscreen();
          }
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) { // Firefox
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) { // IE/Edge
            document.msExitFullscreen();
          }
        }
    };

    const progressHandler = (state) => {
        if (count > 3) {
        console.log("close");
        controlRef.current.style.visibility = "hidden";
        } else if (controlRef.current.style.visibility === "visible") {
        count += 1;
        }

        if (!seeking) {
        setVideoState({ ...videoState, ...state });
        }
    };

    const seekHandler = (e, value) => {
        setVideoState({ ...videoState, played: parseFloat(value / 100) });
        videoPlayerRef.current.seekTo(parseFloat(value / 100));
    };

    const backToStartHandler = () => {
        setVideoState({ ...videoState, played: 0 });
        videoPlayerRef.current.seekTo(0);
    };

    const seekMouseUpHandler = (e, value) => {
        console.log(value);

        setVideoState({ ...videoState, seeking: false });
        videoPlayerRef.current.seekTo(value / 100);
    };

    const volumeChangeHandler = (e, value) => {
        const newVolume = parseFloat(value) / 100;

        setVideoState({
        ...videoState,
        volume: newVolume,
        muted: Number(newVolume) === 0 ? true : false,
        });
    };

    const volumeSeekUpHandler = (e, value) => {
        const newVolume = parseFloat(value) / 100;

        setVideoState({
        ...videoState,
        volume: newVolume,
        muted: newVolume === 0 ? true : false,
        });
    };

    const muteHandler = () => {
        setVideoState({ ...videoState, muted: !videoState.muted });
    };

    const onSeekMouseDownHandler = (e) => {
        setVideoState({ ...videoState, seeking: true });
    };

    const mouseMoveHandler = () => {
        controlRef.current.style.visibility = "visible";
        count = 0;
    };

    const bufferStartHandler = () => {
        console.log("Bufering.......");
        setVideoState({ ...videoState, buffer: true });
    };

    const bufferEndHandler = () => {
        console.log("buffering stoped ,,,,,,play");
        setVideoState({ ...videoState, buffer: false });
    };
    return (<div ref={containerRef} className={styles.videoContainer}>
          <div className={styles.playerWrapper} onMouseMove={mouseMoveHandler} onMouseDown = {mouseMoveHandler}>
            <ReactPlayer
                    className={styles.player}
                    ref={videoPlayerRef}
                    url="https://storage.googleapis.com/rdf/uploads/videos/originals_franca.mp4"              
                    width="100vw"
                    height="100vh"
                    playing={playing}
                    volume={volume}
                    muted={muted}
                    onEnded={handleEnded}
                    onProgress={progressHandler}
                    onBuffer={bufferStartHandler}
                    onBufferEnd={bufferEndHandler}
                    onReady={() => {
                        if (videoPlayerRef.current && !isProgressSet) {
                          videoPlayerRef.current.seekTo(playedTime, 'seconds');
                          setIsProgressSet(true); // Define que o progresso foi ajustado
                        }
                    }}
            />

            {/* {buffer && <p>Loading</p>} */}

            <Controls
                name={'O Mercado Francês de Futebol'}
                controlRef={controlRef}
                onPlayPause={playPauseHandler}
                playing={playing}
                onRewind={rewindHandler}
                onForward={handleFastFoward}
                played={played}
                onSeek={seekHandler}
                onSeekMouseUp={seekMouseUpHandler}
                volume={volume}
                onVolumeChangeHandler={volumeChangeHandler}
                onVolumeSeekUp={volumeSeekUpHandler}
                mute={muted}
                onMute={muteHandler}
                playRate={playbackRate}
                duration={formatDuration}
                currentTime={formatCurrentTime}
                liked={liked}
                onLike={handleLike}
                onMouseSeekDown={onSeekMouseDownHandler}
                backToStart={backToStartHandler}
                fullScreen={handleFullScreen}
            />           
        </div>      
    </div>);
}

export default VideoPlayer;