import styled from 'styled-components';
import Variables from 'variables';

export const Background = styled.div`
  height: 1350px;
  min-height: 1350px;
  max-height: 1350px;
  width: 900px;
  min-width: 900px;
  max-width: 900px;
  margin: auto;
  background-color: ${props => props.color};
  border-radius: 8px;
  position: relative;
`;

export const External = styled.div`
  left: 27px;
  right: 27px;
  top: 28px;
  bottom: 28px;
  border: 2px solid ${props => props.borderColor};
  position: absolute;
`;

export const Area = styled.div`
  background-color: ${props => props.color};
  height: 17.5%;
  width: 54.8%;
  border: 2px solid ${props => props.borderColor};
  border-top: ${props => (props.top ? 'none' : null)};
  border-bottom: ${props => (props.top ? null : 'none')};
  top: ${props => (props.top ? '0' : null)};
  bottom: ${props => (props.top ? null : '0')};
  position: absolute;
  left: 22.2%;
`;

export const InsideArea = styled.div`
  height: 39%;
  width: 47%;
  border: 2px solid ${props => props.borderColor};
  border-top: ${props => (props.top ? 'none' : null)};
  border-bottom: ${props => (props.top ? null : 'none')};
  top: ${props => (props.top ? '0' : null)};
  bottom: ${props => (props.top ? null : '0')};
  position: absolute;
  left: 26%;
`;

export const DotArea = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${props => props.borderColor};
  top: ${props => (props.top ? '64.5%' : null)};
  bottom: ${props => (props.top ? null : '64.5%')};
  left: 49%;
  position: absolute;
`;

export const AreaCircle = styled.div`
  height: 16.5%;
  width: 25%;
  border-radius: 50%;
  border: 2px solid ${props => props.borderColor};
  top: ${props => (props.top ? '5%' : null)};
  bottom: ${props => (props.top ? null : '5%')};
  left: 37%;
  position: absolute;
`;

export const MiddleLine = styled.div`
  height: 2px;
  background-color: ${props => props.borderColor};
  top: calc(50% - 1px);
  left: 0;
  right: 0;
  position: absolute;
`;

export const MiddleCircle = styled.div`
  height: 216px;
  width: 216px;
  border-radius: 50%;
  border: 2px solid ${props => props.borderColor};
  top: calc(50% - 112px);
  left: calc(50% - 112px);
  position: absolute;
`;

export const MiddleDot = styled.div`
  height: 21px;
  width: 21px;
  border-radius: 50%;
  background-color: ${props => props.borderColor};
  top: calc(50% - 10.5px);
  left: calc(50% - 10.5px);
  position: absolute;
`;

export const LineHModelArea = styled.div`
  width: 1px;
  background-color: ${props => props.color};
  top: 0;
  bottom: 0;
  left: ${props => props.left};
  position: absolute;
`;

export const LineWModelArea = styled.div`
  height: 1px;
  background-color: ${props => props.color};
  left: 0;
  right: 0;
  top: ${props => props.top};
  position: absolute;
`;

export const NumberModelArea = styled.div`
  top: calc(${props => props.top} - 16px);
  left: calc(${props => props.left} - 25px);
  text-align: center;
  position: absolute;
  width: 50px;
  height: 32px;
  font-size: 32px;
  font-weight: 900;
  color: ${props => props.color};
`;

export const LineHModelCorredor = styled.div`
  border-left: 5px dashed ${props => props.color};
  opacity: 0.5;
  top: 0;
  bottom: 0;
  left: ${props => props.left};
  position: absolute;
`;

export const AthletePosition = styled.div`
  cursor: pointer;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: ${props => (props.selected ? Variables.lightPurple1 : '#ffffff')};
  color: ${props => (props.selected ? '#ffffff' : '#000000')};
  position: absolute;
  overflow: visible;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &:hover,
  &:focus,
  &:hover > div,
  &:focus > div {
    background-color: ${Variables.lightPurple1};
    color: ${'#ffffff'};
  }
`;

export const AthletePositionName = styled.div`
  position: relative;
  font-size: 14px;
  text-align: center;
  max-width: 130px;
  white-space: nowrap;
  background-color: ${props => (props.selected ? Variables.lightPurple1 : '#ffffff')};
  flex: 0;
  text-overflow: ellipsis;
  height: 22px;
  border-radius: 4px;
  padding: 0 4px;
`;

export const AthletePositionNameText = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AthletePositionList = styled.div`
  position: absolute;
  width: 175px;
  border-width: 0;
  border-style: solid;
  border-color: ${Variables.gray4};
  ${props => (props.positionIverted ? 'bottom: 50px;' : 'top: 50px;')}
  ${props => (props.positionIverted ? 'border-bottom-width: 5px;' : 'border-top-width: 5px;')}
`;
