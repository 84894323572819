import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import { RadarChart, TextArea } from 'components/template';
import styles from './SideSection.module.scss';

export default function SideSection({ fields, feedback, setFeedback, teamContext, setTeamContext }) {  
  const [editFeedback, setEditFeedback] = useState(false);
  const [editTeamContext, setEditTeamContext] = useState(false);
  const { t } = useTranslation();

  const radarChartLabels = fields ? fields.map(item => String(item.title)) : [];
  const radarChartSeries = fields ? fields.map(item => item.rate || 0) : [];
  const plotOptions = {
    plotOptions: {
      radar: {
        size: 90,        
      },
    },    
  };

  const radarChartData = {
    labels: radarChartLabels,
    series: [
      {
        data: radarChartSeries,
      },
    ],
  };

  let editFeedbackField = null;
  if (editFeedback) {
    editFeedbackField = (
      <>
        <TextArea value={feedback} onChange={e => setFeedback(e.target.value)} />
        <span onClick={() => setEditFeedback(false)} className={styles.feedbackTrigger}>
          {t("field.con")}
        </span>
      </>
    );
  } else if (feedback) {
    editFeedbackField = (
      <div className={styles.withFeedback}>
        <p>{feedback}</p>
        <span onClick={() => setEditFeedback(true)} className={styles.feedbackTrigger}>
          {t("field.edit")}
        </span>
      </div>
    );
  } else {
    editFeedbackField = (
      <span onClick={() => setEditFeedback(true)} className={styles.feedbackTrigger}>
        {t("field.click1")}
      </span>
    );
  }

  let editTeamContextField = null;
  if (editTeamContext) {
    editTeamContextField = (
      <>
        <TextArea value={teamContext} onChange={e => setTeamContext(e.target.value)} />
        <span onClick={() => setEditTeamContext(false)} className={styles.feedbackTrigger}>
          {t("field.con")}
        </span>
      </>
    );
  } else if (teamContext) {
    editTeamContextField = (
      <div className={styles.withFeedback}>
        <p>{teamContext}</p>
        <span onClick={() => setEditTeamContext(true)} className={styles.feedbackTrigger}>
          {t("field.edit")}
        </span>
      </div>
    );
  } else {
    editTeamContextField = (
      <span onClick={() => setEditTeamContext(true)} className={styles.feedbackTrigger}>
        {t("field.click1")}
      </span>
    );
  }

  return (
    <div className={styles.sideSection}>
      <h3>{t("field.graf")}</h3>
      <RadarChart height={240} data={radarChartData} options={plotOptions}/>
      <div className={styles.textArea}>
        <h3>{t("field.pare")}</h3>
        {editFeedbackField}
      </div>
      <div className={styles.textArea}>
        <h3>{t("field.conte")}</h3>
        {editTeamContextField}
      </div>
    </div>
  );
}

SideSection.propTypes = {
  fields: PropTypes.instanceOf(Array).isRequired,
  feedback: PropTypes.string.isRequired,
  setFeedback: PropTypes.func.isRequired,
  teamContext: PropTypes.string.isRequired,
  setTeamContext: PropTypes.func.isRequired,
};
