/*eslint-disable eqeqeq*/
import React, { useState } from 'react';
import * as S from './styles';
import { useSelector } from 'react-redux';
import ProfileInfo from './ProfileInfo';
import AddUserDialog from './AddUserDialog';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FaUsers } from 'react-icons/fa';

export default function Users() {
  const { t } = useTranslation();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const project = useSelector(state => state.project);
  const loggedUser = useSelector(state => state.user.data);
  const loggedUserPermission =
    (project.data?.users ?? []).find(projectUser => projectUser?.user?.id == loggedUser.id)?.permission ??
    t('user.box19');

  const showAddUserDialog = () => {
    setIsOpenDialog(!isOpenDialog);
  };

  if (project?.data?.users === undefined) {
    return '';
  }

  const owner = project.data.users.find(pu => pu.permission === 'Proprietário');
  return owner ? (
    <>
      <S.Card softShadow bordered>
        <S.TitleU>
          <h1>{t('proj.userTitle')}</h1>
          <span>{t('proj.userSubTitle')}</span>
        </S.TitleU>
        <S.Owner>
          <h2>{t('proj.box15')}</h2>
          <S.OwnerContent>
            <ProfileInfo user={owner.user} email={owner.user.email} />
            {(loggedUserPermission === 'Proprietário' || loggedUserPermission === 'Administrador') && (
              <button onClick={showAddUserDialog} type="button">
                {t('user.box15')}
                <FaUsers />
              </button>
            )}
          </S.OwnerContent>
        </S.Owner>
        {project.data.users.length > 1 && (
          <S.Members>
            <h2>{t('user.box14')}</h2>
            <S.Table>
              <thead>
                <tr>
                  <S.Th>{t('agencies.name')}</S.Th>
                  <S.Th>{t('agencies.email')}</S.Th>
                  <S.Th>{t('user.box4')}</S.Th>
                  <S.Th>{t('user.box12')}</S.Th>
                  <S.Th>{t('user.box13')}</S.Th>
                </tr>
              </thead>
              <tbody>
                {project.data.users.map(pu => {
                  if (pu !== owner) {
                    let ultimaAtividade;
                    try {
                      const hoursBetween =
                        (new Date(Date.now()).getTime() - new Date(pu.lastmodified_at).getTime()) / 3600000;
                      if (hoursBetween < 1) {
                        ultimaAtividade = t('user.box9');
                      } else if (hoursBetween < 24) {
                        ultimaAtividade = `${Math.trunc(hoursBetween)} ${t('user.box10')}`;
                      } else {
                        ultimaAtividade = `${Math.trunc(hoursBetween / 24)} ${t('user.box11')}`;
                      }
                    } catch (e) {
                      ultimaAtividade = '-';
                    }
                    return (
                      <S.Tr>
                        <S.Td>
                          <ProfileInfo user={pu.user} />
                        </S.Td>
                        <S.Td>{pu.user.email}</S.Td>
                        <S.Td>{pu.permission}</S.Td>
                        <S.Td>{moment(pu.created_at).format('DD/MM/YYYY')}</S.Td>
                        <S.Td>{ultimaAtividade}</S.Td>
                      </S.Tr>
                    );
                  }
                  return <></>;
                })}
              </tbody>
            </S.Table>
          </S.Members>
        )}
      </S.Card>
      <AddUserDialog
        isOpen={isOpenDialog}
        setIsOpen={setIsOpenDialog}
        projectId={project.data.id}
        projectUsers={project.data.users}
        ownerId={owner.user.id}
      />
    </>
  ) : (
    ''
  );
}
