/**
 * Mocks related to the athlete pages
 * are located here
 */
import ImgBrazil from 'assets/escudos/brasil.png';
import ImgSaoPaulo from 'assets/escudos/sao_paulo.png';
import ImgGremio from 'assets/escudos/gremio.svg';
import ImgPalmeiras from 'assets/escudos/palmeiras.png';
import AthleteAvatar from 'assets/avatar-atleta.png';
import AthleteAvatar2 from 'assets/avatar-atleta (copy).png';
import PersonAvatar from 'assets/Romildo-Bolzan.jpg';
import Person2Avatar from 'assets/duda-kroeff.jpg';

export const athletesData = [
  {
    id: 119210,
    teamId: 1098,
    clubImg: ImgGremio,
    clubShortName: 'Grêmio',
    img: AthleteAvatar,
    shortName: 'Lucas Barrios',
    fullName: 'Lucas Ramón Barrios Cáceres',
    role: 'Centroavante',
    age: "'84 (34 anos)",
    country: ImgBrazil,
    marketValue: '1,75 M €',
    highestMarketValue: {
      value: '17M €',
      date: '29/06/2011',
    },
    rate: 4.56,
    performanceAmount: 0,
    careerAmount: 0,
    evaluationsAmount: 3,
    contactsAmount: 3,
    negotiationsAmount: 2,
    videosAmount: 15,
    newsAmount: 0,
  },
];

export const evaluationsList = [
  {
    teamId: 1098,
    athleteId: 119210,
    data: [
      {
        id: 1,
        date: '01/04/2018',
        author: 'Adriano Martins',
        client: {
          img: ImgGremio,
          name: 'Grêmio FBPA',
        },
        scoreboard: '1:0',
        visitor: {
          img: ImgSaoPaulo,
          name: 'São Paulo',
        },
        duration: "63'",
        firstString: 'S',
        in: '-',
        out: 'S',
        goals: 0,
        assists: 0,
        generalEvaluation: 3.5,
      },
    ],
  },
];

export const athletesChatData = [
  {
    athlete: {
      id: 389486,
      fullname: 'Joao Victor Ossame',
      nickname: 'Joãozera',
      age: 19,
      birthdate: '2003-10-30',
      position: null,
      nationalities: [
        {
          code: 'BR',
          name: 'Brasil',
          flag: 'https://storage.googleapis.com/rdf_static/flags/4x3/br.svg',
        },
        {
          code: 'BR',
          name: 'Brasil',
          flag: 'https://storage.googleapis.com/rdf_static/flags/4x3/br.svg',
        },
      ],
      photo: null,
      current_team: {
        id: 2794,
        name: '1 Bpm',
        emblem: 'https://storage.googleapis.com/rdf_dev/uploads/teams/emblems/2794_1_Bpm.png',
      },
    },
    buyer_club: {
      emblem: 'https://storage.googleapis.com/rdf_dev/uploads/teams/emblems/1098_Gr%C3%AAmio.png',
      id: 1098,
      nickname: 'Grêmio',
    },
    ceding_club: {
      emblem: 'https://storage.googleapis.com/rdf_dev/uploads/teams/emblems/2794_1_Bpm.png',
      id: 2794,
      nikename: '1 Bpm',
    },
    created_at: '2023-05-12T20:40:11.215282-03:00',
    id: 1,
    transfer_market: {
      id: 2,
      is_active: true,
      negotiation_type: 'SALE',
      notes: 'teste',
      sale_value: 500,
    },
  },
];

export const athletesChatDataOld = [
  {
    id: 10,
    athlete: {
      id: 119210,
      teamId: 1098,
      clubImg: ImgSaoPaulo,
      clubShortName: 'São Paulo',
      clubContractExpire: '31/05/2020',
      img: AthleteAvatar,
      shortName: 'Lucas Barrios',
      fullName: 'Lucas Ramón Barrios Cáceres',
      role: 'Centroavante',
      age: "'84 (34 anos)",
      country: ImgBrazil,
      marketValue: '1,75 M €',
      salary: '17 M €/ano',
      advertisement:
        'Aenean semper eleifend ipsum et interdum. Sed quis imperdiet ex. Integer dignissim enim augue, sit amet dapibus elit tincidunt id. Integer sagittis mauris at lacus pulvinar, non ullamcorper augue accumsan. Maecenas a metus vulputate, congue nunc eget, ultrices sapien. Nunc sagittis orci porttitor eros efficitur, non venenatis urna sollicitudin. Fusce volutpat velit non consectetur accumsan. Curabitur eu faucibus quam.',
    },
    lastUpdate: '24/01/2023',
    transferType: 'buy',
    transferClub: {
      clubImg: ImgGremio,
      clubShortName: 'Grêmio',
    },
    unreadMessages: 2,
    participants: [
      {
        id: 56789,
        name: 'Jorge da Silva',
        role: 'Vice-Presidente de Futebol',
        clubImg: ImgSaoPaulo,
        clubShortName: 'São Paulo',
        img: Person2Avatar,
      },
      {
        id: 21,
        name: 'João da Silva',
        role: 'Diretor Executivo',
        clubImg: ImgGremio,
        clubShortName: 'Grêmio',
        img: PersonAvatar,
      },
    ],
    messages: [
      {
        id: 102030,
        from: 56789,
        message: 'Claro, estamos trabalhando com visa, master e pix.',
      },
      {
        id: 124214,
        from: 21,
        message: 'Tenho uma proposta interessante, gostaria de conhecer mais sobre?',
      },
    ],
    oldChats: [
      {
        id: 908070,
        lastUpdate: '24/01/2023',
        transferType: 'buy',
        transferClub: {
          clubImg: ImgPalmeiras,
          clubShortName: 'Palmeiras',
        },
        unreadMessages: 2,
        participants: [
          {
            id: 21,
            name: 'João da Silva',
            role: 'Diretor Executivo',
            clubImg: ImgGremio,
            clubShortName: 'Grêmio',
            img: PersonAvatar,
          },
          {
            id: 56789,
            name: 'Jorge da Silva',
            role: 'Vice-Presidente de Futebol',
            clubImg: ImgPalmeiras,
            clubShortName: 'Palmeiras',
            img: Person2Avatar,
          },
        ],
        messages: [
          {
            id: 102030,
            from: 56789,
            message: 'OK, fique a vontade',
          },
          {
            id: 123214,
            from: 21,
            message: 'Testando chat antigo',
          },
        ],
      },
    ],
  },
  {
    id: 20,
    athlete: {
      id: 119210,
      teamId: 1098,
      clubImg: ImgGremio,
      clubShortName: 'Grêmio',
      clubContractExpire: '20/09/2022',
      img: AthleteAvatar2,
      shortName: 'João da Silva',
      fullName: 'João da Silva Castro Neves',
      role: 'Atacante',
      age: "'81 (22 anos)",
      country: ImgBrazil,
      marketValue: '1,20 M €',
      salary: '13 M €/ano',
      advertisement:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at luctus ligula. Etiam gravida augue id est elementum, ac blandit nunc pulvinar. Nunc viverra vestibulum felis, nec posuere eros vulputate vitae. Praesent mauris nulla, egestas in pharetra non, porttitor sed eros. Suspendisse suscipit, turpis eget hendrerit pretium, risus turpis auctor tellus, a mattis enim tellus a lorem. Sed id elit ac lorem pretium vestibulum quis non lacus. Nam a euismod mi, et rutrum enim. Aliquam pharetra, odio tincidunt porttitor tristique, orci odio ornare nunc, eu pellentesque libero metus eget nulla. Pellentesque posuere sem leo, eget congue mi tristique eget.',
    },
    lastUpdate: '02/01/2023',
    transferType: 'sell',
    transferClub: {
      clubImg: ImgSaoPaulo,
      clubShortName: 'São Paulo',
    },
    unreadMessages: 0,
    participants: [
      {
        id: 21,
        name: 'João da Silva',
        role: 'Diretor Executivo',
        clubImg: ImgGremio,
        clubShortName: 'Grêmio',
        img: PersonAvatar,
      },
      {
        id: 56789,
        name: 'Jorge da Silva',
        role: 'Vice-Presidente de Futebol',
        clubImg: ImgSaoPaulo,
        clubShortName: 'São Paulo',
        img: Person2Avatar,
      },
    ],
    messages: [
      {
        id: 102030,
        from: 21,
        message: 'Sim, é por 100% dos direitos econômicos do atleta',
      },
      {
        id: 124636,
        from: 56789,
        message: 'O valor é por 100% dos direitos econômicos do atleta?',
      },
    ],
    oldChats: [],
  },
];
