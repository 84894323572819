import Endpoints from 'endpoints';
import axios from 'api';
import { stringifyFilters } from 'shared/utility';
import { parseError } from './base';

export const ClubAthletesRepository = {
  baseUrl: Endpoints.clubAthletes,

  list: async function(teamId, filters) {
    try {
      const queryString = stringifyFilters(filters);
      const { data } = await axios.get(`${this.baseUrl(teamId)}${queryString}`);

      return data;
    } catch (err) {
      return parseError(err);
    }
  },
};
