import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from 'store/ducks/competition';
import { withRouter } from 'react-router-dom';
import { Card, Spinner, Table, Pagination, ToolTip } from 'components/template';
import TeamRow from 'components/TeamRow/TeamRow';
import AthleteRow from 'components/AthleteRow/AthleteRow';
import CountryRow from 'components/CountryRow/CountryRow';
import styles from './AthletesList.module.scss';
import IcoTempo from 'assets/ico/ico-tempo.png';
import IcoJogos from 'assets/ico/ico-jogos.png';
import IcoTitular from 'assets/ico/ico-titular.png';
import IcoBanco from 'assets/ico/ico-banco.png';
import IcoEntrou from 'assets/ico/ico-entrou.png';
import IcoSaiu from 'assets/ico/ico-saiu.png';
import IcoGols from 'assets/ico/ico-gols.png';
import IcoAssistencias from 'assets/ico/ico-assistencias.png';
import IcoAmarelos from 'assets/ico/ico-amarelos.png';
import IcoAmareloVermelho from 'assets/ico/ico-amarelo-vermelho.png';
import IcoVermelhos from 'assets/ico/ico-vermelhos.png';
import { usePagination } from 'shared/hooks';
import MonitoringButton from 'components/MonitoringButton/MonitoringButton';
import { useTranslation } from 'react-i18next';

function AthletesList({ id }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const athletes = useSelector(state => state.competition.athletesPage);

  const [pagination, setPagination] = usePagination();

  useEffect(() => {
    dispatch(Creators.getAthletes(id, pagination));
  }, [dispatch, pagination, id]);

  const rawData = athletes.data.athletes;

  const data =
    rawData &&
    rawData.map(row => {
      const player = (
        <AthleteRow
          id={row.athlete?.id}
          img={row.athlete?.photo}
          name={row.athlete?.fullname}
          nickname={row.athlete?.nickname}
        />
      );
      const competitionTeam = <TeamRow name={row.team?.nickname} id={row.team?.id} img={row.team?.emblem} textBreak />;
      const currentTeam = row.athlete?.current_team && (
        <TeamRow
          name={row.athlete?.current_team?.nickname}
          id={row.athlete?.current_team?.id}
          img={row.athlete?.current_team?.emblem}
          textBreak
        />
      );
      const age = (
        <span style={{ fontWeight: '500' }}>
          '{row.athlete?.birthyear?.toString().substr(-2)} &#40;{row.athlete?.age}&#41;
        </span>
      );
      const position = row.athlete?.position ? t(`positionOptionsRaw.${row.athlete?.position}`) : '-';
      const nationality =
        row.athlete &&
        row.athlete.nationalities &&
        row.athlete.nationalities.length > 0 &&
        row.athlete.nationalities.map((item, key) => <CountryRow key={key} id={key} img={item.flag} />);
      const minutesPlayed = row.minutes_played;
      const appearances = row.appearances;
      const lineups = row.lineups;
      const onBench = row.on_bench;
      const subsIn = row.subs_in;
      const subsOut = row.subs_out;
      const goals = row.goals;
      const assists = row.assists;
      const yellowCards = row.yellow_cards;
      const secondYellow = row.second_yellow_cards;
      const redCards = row.red_cards;
      const monitoringButton = (
        <div className={styles.buttonContainer}>
          <MonitoringButton athlete={row.athlete} />
        </div>
      );
      return [
        player,
        competitionTeam,
        currentTeam,
        age,
        position,
        nationality,
        goals,
        minutesPlayed,
        appearances,
        lineups,
        onBench,
        subsIn,
        subsOut,
        assists,
        yellowCards,
        secondYellow,
        redCards,
        monitoringButton,
      ];
    });

  let content = '';
  if (athletes.loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (athletes.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(athletes.error)}</p>
      </Card>
    );
  } else {
    content = (
      <>
        <Card softShadow className={styles.athleteList}>
          <div className={styles.tableContainer}>
            <Table
              flat
              theads={[
                t('athlete_list.box1'),
                t('compe.text4'),
                t('compe.text3'),
                t('athlete_list.box3'),
                t('athlete_list.box4'),
                t('athlete_list.box5'),
                <ToolTip message="Gols">
                  <img src={IcoGols} alt="gols" />
                </ToolTip>,
                <ToolTip message="Minutos jogados">
                  <img src={IcoTempo} alt="tempo" />
                </ToolTip>,
                <ToolTip message="Partidas jogadas">
                  <img src={IcoJogos} alt="jogos" />
                </ToolTip>,
                <ToolTip message="Iniciou como titular">
                  <img src={IcoTitular} alt="titular" />
                </ToolTip>,
                <ToolTip message="Iniciou no banco">
                  <img src={IcoBanco} alt="banco" />
                </ToolTip>,
                <ToolTip message="Entrou no jogo">
                  <img src={IcoEntrou} alt="entrou" />
                </ToolTip>,
                <ToolTip message="Saiu do jogo">
                  <img src={IcoSaiu} alt="saiu" />
                </ToolTip>,
                <ToolTip message="Assistências">
                  <img src={IcoAssistencias} alt="assistências" />
                </ToolTip>,
                <ToolTip message="Cartão Amarelo">
                  <img src={IcoAmarelos} alt="amarelos" />
                </ToolTip>,
                <ToolTip message="Dois cartões amarelos">
                  <img src={IcoAmareloVermelho} alt="amarelo vermelho" />
                </ToolTip>,
                <ToolTip message="Cartão vermelho">
                  <img src={IcoVermelhos} alt="vermelho" />
                </ToolTip>,
                '',
              ]}
              data={data}
            />
          </div>
        </Card>
        {rawData && athletes.data && (
          <Pagination
            length={rawData.length}
            count={athletes.data.count}
            pagination={pagination}
            setPagination={setPagination}
            hasNext={athletes.data.hasNext}
            hasPrevious={athletes.data.hasPrevious}
          />
        )}
      </>
    );
  }

  return <>{content}</>;
}

AthletesList.propTypes = {
  id: PropTypes.string.isRequired,
};

export default withRouter(AthletesList);
