import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import Draggable from 'react-draggable';
import AthleteMinCard from 'components/AthleteMinCard';

export default function AthletePosition({ position, setPosition, athletes, background, selected, onClick, disabled }) {
  const [convertedPosition, setConvertedPosition] = useState(null);

  const convertPosition = useCallback(
    position => {
      return {
        x: (position.x * background.width) / 100,
        y: (position.y * background.height) / 100,
      };
    },
    [background.width, background.height]
  );

  // const handleStart = (event, data) => {
  // const { node } = data;
  // setPosition(position.position_name, {
  //   x: (parseFloat(node.style.left) * background.width) / 100,
  //   y: (parseFloat(node.style.top) * background.height) / 100,
  // });
  // node.style.left = '0';
  // node.style.top = '0';
  // };

  const handleStop = (event, data) => {
    const { x, y } = data;

    // convert to percentage
    const newX = Math.round((x / background.width) * 100);
    const newY = Math.round((y / background.height) * 100);

    setPosition(position.position_name, {
      x: newX,
      y: newY,
    });
  };

  useEffect(() => {
    if (background && position) setConvertedPosition(convertPosition(position));
  }, [position, background, convertPosition]);

  return (
    convertedPosition && (
      <Draggable
        disabled={disabled}
        axis="both"
        // defaultPosition={convertedPosition}
        position={convertedPosition}
        positionOffset={{ x: '-25px', y: '-25px' }}
        grid={[background.width / 100, background.height / 100]}
        scale={1}
        bounds={{ left: 0, top: 0, right: background.width, bottom: background.height }}
        // onStart={handleStart}
        // onDrag={handleDrag}
        onStop={handleStop}
      >
        <S.AthletePosition
          onClick={disabled ? () => {} : onClick}
          selected={selected ?? false}
          //left={position.x} top={position.y}
        >
          <S.AthletePositionName selected={selected ?? false}>
            <S.AthletePositionNameText>{position.position_name}</S.AthletePositionNameText>
          </S.AthletePositionName>
          {athletes?.length ? (
            <S.AthletePositionList positionIverted={position.y > 80}>
              {athletes.map((athlete, index) => {
                return (
                  <AthleteMinCard
                    key={index}
                    item={athlete.athlete_data ?? athlete.athlete}
                    cardColor={athlete.color}
                    // iconColor={}
                    // iconClick={() => handleUnselectAthlete(athlete)}
                  ></AthleteMinCard>
                );
              })}
            </S.AthletePositionList>
          ) : null}
        </S.AthletePosition>
      </Draggable>
    )
  );
}
