import React, { useEffect, useState } from 'react';
import styles from './CountDownTimer.module.scss';
import { useTranslation } from 'react-i18next';

export default function CountDownTimer({ min, sec, onRequest }) {
  const { t } = useTranslation();
  const [minutes, setMinutes] = useState(min);
  const [seconds, setSeconds] = useState(sec);

  const updateCountdown = () => {
    if (minutes === 0 && seconds === 0) {
      return;
    }

    if (seconds === 0) {
      setMinutes(minutes - 1);
      setSeconds(59);
    } else {
      setSeconds(seconds - 1);
    }
  };

  useEffect(() => {
    const countdownTimer = setTimeout(updateCountdown, 1000);

    return () => clearTimeout(countdownTimer);
  }, [minutes, seconds]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {minutes || seconds !== 0 ? (
        <p>
          {t('firstAccess.text19')}
          <span className={styles.timer}>
            {minutes}:{seconds < 10 ? '0' : ''}
            {seconds}
          </span>
        </p>
      ) : (
        <p>
          {t('firstAccess.text20')}
          <span
            className={styles.btnSolicite}
            onClick={() => {
              setMinutes(2);
              onRequest();
            }}
          >
            {' '}
            {t('firstAccess.box1')}
          </span>
        </p>
      )}
    </>
  );
}
