import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { Creators } from 'store/ducks/athleteReport';

import AthleteDialog from 'components/AthleteDialog/AthleteDialog';
import { Card, Spinner } from 'components/template';
import styles from './Overview.module.scss';
import SideInfo from './SideInfo/SideInfo';
import Performance from './Performance/Performance';
import Career from './Career/Career';
import { useTranslation } from 'react-i18next';

export default function Overview({ athleteId, baseURL, setIsAthleteUpdated }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const athlete = useSelector(state => state.athleteReport);
  const user = useSelector(state => state.user.data);

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  useEffect(() => {
    dispatch(Creators.getAthlete([athleteId], true));

    return () => {
      dispatch(Creators.reset());
    };
  }, [dispatch, athleteId, user.organization]);

  let content = '';
  if (athlete.loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (athlete.error) {
    content = (
      <Card softShadow bordered className={styles.errorContainer}>
        <span>{t(athlete.error)}</span>
      </Card>
    );
  } else if (athlete.data.length > 0) {
    content = (
      <>
        <div softShadow className={styles.container}>
          <SideInfo data={athlete.data[0]} setIsOpen={setIsOpenDialog} isOpen={isOpenDialog} />
          <div className={styles.content}>
            <Performance data={athlete.data[0].historical} baseURL={baseURL} />
            <Career
              data={athlete.data[0].transfers}
              total_amount={athlete.data[0].athlete.market_value?.total_transfers_amount}
              baseURL={baseURL}
            />
          </div>
        </div>
        <AthleteDialog
          isOpen={isOpenDialog}
          setIsOpen={setIsOpenDialog}
          data={athlete.data[0]}
          setIsAthleteUpdated={setIsAthleteUpdated}
        />
      </>
    );
  }

  return <>{content}</>;
}

Overview.propTypes = {
  athleteId: PropTypes.string.isRequired,
  baseURL: PropTypes.string.isRequired,
};
