import React from 'react';
import styles from './ExcededPlanLimit.module.scss';
import { Button } from 'components/template';
import { MdClose, MdWhatsapp } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

export default function ExcededPlanLimit({ closeClick, showBackground }) {
  const classes = [styles.container];
  const { t } = useTranslation();
  if (showBackground) {
    classes.push(styles.containerBackground);
  }
  return (
    <div className={classes.join(' ')}>
      {closeClick != null && <MdClose className={styles.closeButton} onClick={closeClick} />}
      <h4>
        {t('excededPlanLimit.h4_1')}
        <br />
        {t('excededPlanLimit.h4_2')}
      </h4>
      <h5>{t('excededPlanLimit.h5_1')}</h5>
      <h6>{t('excededPlanLimit.h6_1')}</h6>
      <h6>{t('excededPlanLimit.h6_2')}</h6>
      <h6>{t('excededPlanLimit.h6_3')}</h6>
      <h6>{t('excededPlanLimit.h6_4')}</h6>
      <h5>{t('excededPlanLimit.h5_2')}</h5>
      <a
        draggable="false"
        href={`https://api.whatsapp.com/send?phone=${t('whatsapp.phone')}&text=${t('whatsapp.upgrade')}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button rounded color="green">
          <MdWhatsapp className={styles.whatsIcon} />
          {t('excededPlanLimit.button')}
        </Button>
      </a>
    </div>
  );
}
