export const types = {
  HIDE_APP_BAR: 'layout/HIDE_APP_BAR',
  SHOW_APP_BAR: 'layout/SHOW_APP_BAR',
  HIDE_EXCEDED_PLAN_LIMIT: 'layout/HIDE_EXCEDED_PLAN_LIMIT',
  SHOW_EXCEDED_PLAN_LIMIT: 'layout/SHOW_EXCEDED_PLAN_LIMIT',
  TOGGLE_APP_BAR: 'layout/TOGGLE_APP_BAR',
  HIDE_NAV_DRAWER: 'layout/HIDE_NAV_DRAWER',
  SHOW_NAV_DRAWER: 'layout/SHOW_NAV_DRAWER',
  TOGGLE_NAV_DRAWER: 'layout/TOGGLE_NAV_DRAWER',
  SHOW_TOAST: 'layout/SHOW_TOAST',
  HIDE_TOAST: 'layout/HIDE_TOAST',
  SHOW_DIALOG: 'layout/SHOW_DIALOG',
  HIDE_DIALOG: 'layout/HIDE_DIALOG',
};

const excededPlanLimit = localStorage.getItem('rdf_excededPlanLimit') === 'true';
const initialState = {
  isAppBarVisible: true,
  isNavDrawerVisible: false,
  toast: {
    isVisible: false,
    onCloseHandler: () => null,
    content: 'Conteúdo do toast de teste',
    type: 'danger',
    duration: 1000,
  },
  isDialogVisible: false,
  showExcededPlanLimit: excededPlanLimit,
};
if (excededPlanLimit) {
  localStorage.removeItem('rdf_excededPlanLimit');
}

export default function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case types.HIDE_APP_BAR:
      return { ...state, isAppBarVisible: false };
    case types.SHOW_APP_BAR:
      return { ...state, isAppBarVisible: true };
    case types.HIDE_EXCEDED_PLAN_LIMIT:
      return { ...state, showExcededPlanLimit: false };
    case types.SHOW_EXCEDED_PLAN_LIMIT:
      return { ...state, showExcededPlanLimit: true };
    case types.TOGGLE_APP_BAR:
      return { ...state, isAppBarVisible: !state.isAppBarVisible };
    case types.HIDE_NAV_DRAWER:
      return { ...state, isNavDrawerVisible: false };
    case types.SHOW_NAV_DRAWER:
      return { ...state, isNavDrawerVisible: true };
    case types.TOGGLE_NAV_DRAWER:
      return { ...state, isNavDrawerVisible: !state.isNavDrawerVisible };
    case types.SHOW_TOAST:
      return { ...state, toast: { ...state.toast, isVisible: true, ...action.payload } };
    case types.HIDE_TOAST:
      return { ...state, toast: { ...state.toast, isVisible: false } };
    case types.SHOW_DIALOG:
      return { ...state, isDialogVisible: true };
    case types.HIDE_DIALOG:
      return { ...state, isDialogVisible: false };
    default:
      return state;
  }
}

export class Creators {
  static hideAppBar = () => ({ type: types.HIDE_APP_BAR });
  static showAppBar = () => ({ type: types.SHOW_APP_BAR });
  static hideExcededPlanLimit = () => ({ type: types.HIDE_EXCEDED_PLAN_LIMIT });
  static showExcededPlanLimit = () => ({ type: types.SHOW_EXCEDED_PLAN_LIMIT });
  static toggleAppBar = () => ({ type: types.TOGGLE_APP_BAR });
  static hideNavDrawer = () => ({ type: types.HIDE_NAV_DRAWER });
  static showNavDrawer = () => ({ type: types.SHOW_NAV_DRAWER });
  static toggleNavDrawer = () => ({ type: types.TOGGLE_NAV_DRAWER });

  static showToast({ onCloseHandler, content, type = 'danger', duration = 1000 }) {
    return dispatch => {
      dispatch(this.hideToast());
      dispatch({ type: types.SHOW_TOAST, payload: { onCloseHandler, content, type, duration } });
    };
  }

  static hideToast() {
    return { type: types.HIDE_TOAST };
  }

  static showDialog = () => ({ type: types.SHOW_DIALOG });
  static hideDialog = () => ({ type: types.HIDE_DIALOG });
}
