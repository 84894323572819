import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import * as S from './styles';
import BadgeDate from 'components/BadgeDate/BadgeDate';
import HistoryItem from './HistoryItem';
import { Creators } from 'store/ducks/projects';
import CenteredLoader from 'components/template/CenteredLoader';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

function History() {
    const { t } = useTranslation();
    const project = useSelector(state => state.project);
    const [projectHistory, setProjectHistory] = useState(undefined);

    useEffect(() => {
        if (project?.data) {
            Creators.getHistory(project.data.id).then((history) => {
                if (history?.data?.results) {
                    setProjectHistory(history.data.results);
                }
            });
        }
    }, [project]);

    const mountItems = () => {
        const items = [];
        if (projectHistory) {
            let date = new Date('2022-01-01').toDateString();
            projectHistory.forEach((history) => {
                if (!(history.created_at instanceof Date)) {
                    history.created_at = new Date(history.created_at)
                }
                let created_at = history.created_at.toDateString();
                if (created_at !== date) {
                    date = created_at;
                    items.push(<BadgeDate date={history.created_at} key={history.created_at} />);
                }
                items.push(<HistoryItem data={history} key={history.id} />);
            })
        }
        if (items.length === 0) {
            const owner = project.data.users.find((user) => user.permission === t("proj.box15"))
            if (owner?.user) {
                const fakeHistory = {
                    created_at: new Date(project.data.created_at),
                    user: owner.user,
                    text: t("proj.box16"),
                }
                items.push(<BadgeDate date={fakeHistory.created_at} />);
                items.push(<HistoryItem data={fakeHistory} />)
            } else {
                items.push(t("proj.box17"));
            }
        }
        return items;
    }

    return (
    projectHistory === undefined ? <CenteredLoader /> :
    <>
        
        <S.Card softShadow bordered>
            <S.Title>
                <h1>{t("proj.historyTitle")}</h1>
                <span>{t("proj.historySubTitle")}</span>
            </S.Title>
            {mountItems()}
        </S.Card>
    </> 
    );
}

export default withRouter(History);
