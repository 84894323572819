import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { getAthletes } from 'store/ducks/athleteV2';
import Filter from 'pages/MyClub/Negotiations/Filter/Filter';
import styles from './index.module.scss';

export default function AthleteFilter({ visible, onApply }) {
  const dispatch = useDispatch();

  const mounted = useRef(false);
  const [queryFilters, setQueryFilters] = useState(null);
  const [activeFilters, setActiveFilters] = useState({});

  useEffect(() => {
    if (mounted.current && visible) {
      dispatch(getAthletes(queryFilters));
      onApply();
    }
    mounted.current = true;
  }, [dispatch, queryFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSetQueryFilters = filters => {
    let emptyObj;

    for (let filter in filters) {
      if (Array.isArray(filters[filter]) && filters[filter].length === 0) {
        delete filters[filter];
      } else if (typeof filters[filter] === 'object') {
        emptyObj = true;

        for (let key in filters[filter]) {
          if (filters[filter][key] !== '') {
            emptyObj = false;
          }
        }

        if (emptyObj) {
          delete filters[filter];
        }
      } else if (filters[filter] === '') {
        delete filters[filter];
      } else if (filter === 'watched' && !filters[filter]) {
        delete filters[filter];
      }
    }

    setActiveFilters(filters);
  };

  return (
    <Filter
      setQueryFilters={setQueryFilters}
      onSetQueryFilters={onSetQueryFilters}
      activeFilters={activeFilters}
      overlay={false}
      addScrollAndMaxHeight={true}
      autoSearch={false}
      className={styles.filter}
    />
  );
}
