import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Contracts.module.scss';
import { ClubAthletesRepository } from 'data/clubAthletes';
import { useTranslation } from 'react-i18next';
import { Creators } from 'store/ducks/club';
import CenteredLoader from 'components/template/CenteredLoader';
import ContractsGroup from './ContractsGroup/ContractsGroup';
import ContractDialog from './ContractDialog/ContractDialog';
import ActionBar from './ActionBar/ActionBar';

export default function Contracts({ teamId }) {
  const { t } = useTranslation();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [athletes, setAthletes] = useState([]);
  const [groups, setGroups] = useState({ danger: [], warning: [], ok: [] });
  const dispatch = useDispatch();
  const club = useSelector(state => state.club.clubPage);
  const professionalCast = useSelector(state => state.club.professionalCastPage);
  const contractHighRisk = club.data?.contract_high_risk ?? 9;
  const contractWarningRisk = club.data?.contract_warning_risk ?? 18;

  const [selectedFilters, setSelectedFilters] = useState({
    female: false,
    professional: true,
    loaned: false,
  });

  const dangerRangeDivider = new Date(new Date().setMonth(new Date().getMonth() + contractHighRisk))
    .toISOString()
    .split('T')[0];
  const warningRangeDivider = new Date(new Date().setMonth(new Date().getMonth() + contractWarningRisk))
    .toISOString()
    .split('T')[0];

  useEffect(() => {
    dispatch(Creators.getProfessionalCast(teamId, selectedFilters));
  }, [dispatch, teamId, selectedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAthletes(professionalCast.data.athletes);
  }, [professionalCast]);

  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      const data = await ClubAthletesRepository.list(teamId, { has_contract: 'true' });
      setError(data.error);
      setAthletes(data.results);
      setLoading(false);
    }

    if (teamId) fetchData();
  }, [teamId]);

  function getGroupsByAthletes(athletes) {
    return athletes.reduce(
      (acc, at) => {
        if (!at?.contract_info?.current_contract_end) return acc;
        if (at.contract_info.current_contract_end < dangerRangeDivider) acc['danger'].push(at);
        else if (at.contract_info.current_contract_end < warningRangeDivider) acc['warning'].push(at);
        else acc['ok'].push(at);
        return acc;
      },
      { danger: [], warning: [], ok: [] }
    );
  }

  useEffect(() => {
    if (athletes) setGroups(getGroupsByAthletes(athletes));
  }, [athletes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.containerWrapper}>
      {loading && <CenteredLoader size="calc(100vh - 250px)" />}
      {error && (
        <div className={styles.error}>
          <span>{error}</span>
        </div>
      )}
      {!error && !loading && (
        <div className={styles.container}>
          <div className={styles.panelTitle}>
            <h2 className={styles.title}>{t('myClub.contracts.pageTitle')}</h2>
            <p className={styles.subTitle}>{t('myClub.contracts.pageSubTitle')}</p>
          </div>
          <ActionBar setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} />
          {professionalCast.loading ? (
            <CenteredLoader size="calc(100vh - 250px)" />
          ) : (
            Object.keys(groups).map((group, index) => {
              return (
                <div key={index} className={styles.groupContainer}>
                  <ContractsGroup
                    selectedFilters={selectedFilters}
                    type={group}
                    athletes={groups[group]}
                    onClick={() => {
                      setIsOpenDialog(true);
                    }}
                  />
                </div>
              );
            })
          )}
          <ContractDialog teamId={teamId} isOpen={isOpenDialog} setIsOpen={setIsOpenDialog} />
        </div>
      )}
    </div>
  );
}
