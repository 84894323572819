import Endpoints from 'endpoints';
import axios from 'api';
import { stringifyFilters } from 'shared/utility';
import { parseError } from './base';

export const AthleteChatRepository = {
  baseUrl: Endpoints.athleteChat,

  list: async function(filters) {
    try {
      const queryString = stringifyFilters(filters);
      const { data } = await axios.get(`${this.baseUrl}${queryString}`);

      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  post: async function(payload) {
    try {
      const { data } = await axios.post(`${this.baseUrl}`, payload);

      return data;
    } catch (err) {
      return parseError(err);
    }
  },
};
