import React, { useEffect, useState, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IconButton, Input, Avatar } from 'components/template';
import * as S from './styles';
import styles from './styles.module.scss';
import { FaPaperclip, FaPaperPlane } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { getNotes, createNote } from 'store/ducks/athleteNotes';
import CenteredLoader from 'components/template/CenteredLoader';
import NoteList from './NoteList';
import NotesGroup from './NotesGroup';
import { usePagination } from 'shared/hooks';
import { useTranslation } from 'react-i18next';

const Notes = ({
  athleteId,
  calcHeight,
  hideNoteGroups,
  inputPosition,
  inputPostElement,
  projectId,
  checkAllNotes,
  noteListChecked,
  allNoteList,
  showDeleted,
  updateNoteCount,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notes = useSelector(state => state.athleteNotes);
  const user = useSelector(state => state.user.data);

  const [viewOnlyDay, setViewOnlyDay] = useState(null);
  const [loadedNotes, setLoadedNotes] = useState([]);
  const [text, setText] = useState('');

  const [pagination, setPagination] = usePagination(60);

  const fileInput = useRef(null);

  useEffect(() => {
    if (
      !notes.loadingNotes &&
      !notes.sendingLastNote &&
      (!notes.messages || projectId !== notes.projectId || athleteId !== notes.athleteId)
    ) {
      dispatch(getNotes(athleteId, pagination, projectId));
    }

    if (notes.messages) {
      if(showDeleted === false){
        setLoadedNotes(notes.messages.filter(note => !note.deleted_at));
      }else {
        setLoadedNotes(notes.messages);
      }
    } else if (projectId !== undefined) {
      setLoadedNotes([]);
    }
  }, [notes.messages]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (notes.createdNote) {
      const oldNotes = loadedNotes.filter(note => !note.provisory);

      setLoadedNotes([
        {
          ...notes.createdNote,
          author: { name: user.name, id: user.id },
        },
        ...oldNotes,
      ]);
    }
  }, [notes.createdNote]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUploadImage = () => {
    fileInput.current.click();
  };

  const onUploadFile = file => {
    let file_type = file.type.split('/')[0];
    if (file_type === 'application') {
      file_type = 'other';
    }
    sendNote(file, file_type);    
  };

  const sendNote = (file = null, file_type = null) => {
    setLoadedNotes([
      {
        provisory: true,
        file,
        file_type,
        text,
        author: {
          name: user.name,
          id: user.id,
        },
      },
      ...loadedNotes,
    ]);
    setText('');

    dispatch(createNote(athleteId, text, file, file_type, projectId));
    updateNoteCount();
  };

  const handleScroll = () => {
    const newPagination = {
      limit: pagination.limit,
      offset: pagination.offset + pagination.limit,
    };

    dispatch(getNotes(athleteId, newPagination, projectId));
    setPagination(newPagination);
  };

  const input = (
    <>
      <S.InputsContainer>
        <Avatar
          noBorder
          img={user.img}
          name={user.name}
          style={{ width: '40px', height: '40px', marginRight: '10px' }}
        />
        <Input
          containerClassName={styles.textInput}
          rounded
          placeholder={t('rest.box3')}
          type="text"
          icon={<FaPaperclip onClick={() => handleUploadImage()} />}
          iconPosition="right"
          value={text}
          onChange={e => setText(e.target.value)}
          onKeyUp={e => {
            if (e.keyCode === 13) sendNote();
          }}
        />
        <input
          accept="audio/*,video/*,image/*,application/*"
          onChange={e => onUploadFile(e.target.files[0])}
          type="file"
          ref={fileInput}
          style={{ display: 'none' }}
        />
        <IconButton
          icon={<FaPaperPlane />}
          color="green"
          disabled={!!!text.trim() || notes.sendingLastNote}
          onClick={() => sendNote()}
          className={styles.sendButton}
        />
      </S.InputsContainer>
      {inputPostElement}
    </>
  );

  if (allNoteList) {
    allNoteList.splice(0, allNoteList.length);
    allNoteList.push.apply(allNoteList, loadedNotes);
  }
  const noteList = notes.loadingNotes ? (
    <CenteredLoader />
  ) : (
    <NoteList
      notes={viewOnlyDay == null ? loadedNotes : loadedNotes.filter(note => note.date === viewOnlyDay)}
      reverseBadge={inputPosition === 'bottom'}
      checkAllNotes={checkAllNotes}
      noteListChecked={noteListChecked}
    />
  );
  const content = (
    <>
      {inputPosition === 'top' && input}
      {loadedNotes.length > 0 ? (
        calcHeight ? (
          <S.TextContainer id="infinite-scroll-div">
            <InfiniteScroll
              next={() => {
                handleScroll();
              }}
              inverse={inputPosition === 'bottom'}
              style={{ display: 'flex', flexDirection: 'column-reverse' }}
              hasMore={notes.hasNext}
              dataLength={loadedNotes.length}
              scrollableTarget="infinite-scroll-div"
            >
              {noteList}
            </InfiniteScroll>
          </S.TextContainer>
        ) : (
          noteList
        )
      ) : (
        <S.TextContainerEmpty>
          <S.EmptyContainer>
            <S.BackgroundIconContainer>
              <S.EmptyIcon />
            </S.BackgroundIconContainer>
            <S.EmptyText>
              {t('rest.box2')} {projectId === undefined ? '' : t('rest.box4')}
            </S.EmptyText>
          </S.EmptyContainer>
        </S.TextContainerEmpty>
      )}
      {inputPosition === 'bottom' && input}
    </>
  );

  return notes.loadingNotes ? (
    <CenteredLoader />
  ) : (
    <S.Container>
      {hideNoteGroups === true ? (
        <></>
      ) : (
        <S.Aside>
          <S.AsideContent>
            <NotesGroup notes={loadedNotes} viewOnlyDay={viewOnlyDay} setViewOnlyDay={setViewOnlyDay} />
          </S.AsideContent>
        </S.Aside>
      )}
      <S.Content height={calcHeight ? 'calc(100vh - 280px)' : 'auto'}>{content}</S.Content>
    </S.Container>
  );
};

export default Notes;
