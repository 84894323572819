import React, { useEffect, useState, useRef } from 'react';
import styles from './Chat.module.scss';
import Header from './Header/Header';
import ChatMessage from 'pages/Messages/Content/Chat/ChatMessage/ChatMessage';
import Footer from './Footer/Footer';
import { NegotiationMessageRepository } from 'data/negotiationMessages';
import { Spinner } from 'components/template';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'shared/utility';
import moment from 'moment';
import { HiTrash } from 'react-icons/hi';
import { Creators as layoutActions } from 'store/ducks/layout';
import { useDispatch } from 'react-redux';

//firebase
import { firebase, firestore } from 'App/App';
import { collection, query, where, orderBy, limit, doc, onSnapshot } from "firebase/firestore";
import { decryptJson } from 'shared/crypto';

let _currentOffset = 0;
let _currentScrollHeight = 0;
let _currentPageLength = 0;
let _isLoadedNextPage = false;
let _unsubscribe;
let _updateVisualizedTimeout;
let _lastLoadedChatId = 0;
export default function Chat({ user, selectedChat, otherChatClick, onAthleteFilterChange = () => { } }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [messages, setMessages] = useState(selectedChat?.messages ?? []);
  const messagesRef = useRef([]);
  const otherOrgIdRef = useRef(null);
  const [messageGroups, setMessageGroups] = useState(selectedChat?.messages ?? []);
  const [openNegotiations, setOpenNegotiations] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [deleting, setDeleting] = useState(false);

  const scrollRef = useRef();

  useEffect(() => {
    return () => {
      if (_unsubscribe != null) {
        _unsubscribe();
      }
    };
  }, [firebase]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (scrollRef.current && messages) {
      if (messages.length > 20 && _isLoadedNextPage) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight - _currentScrollHeight;
        _isLoadedNextPage = false;
      } else {
        _currentPageLength = 0;
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight + 100;
      }
      _currentScrollHeight = scrollRef.current.scrollHeight;
    }
  }, [messageGroups]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const data = await NegotiationMessageRepository.list({
        negotiations: selectedChat.id,
        offset: 0,
        limit: 20,
      });
      _currentOffset = 0;
      setError(data.error);
      setMessages(data.results);
      otherOrgIdRef.current = data.other_org_id;     
      setOpenNegotiations(data?.open_negotiations);
      setLoading(false);
      loadFirebase();
    }
    if (selectedChat && selectedChat.id !== _lastLoadedChatId) {
      setMessages(selectedChat?.messages ?? []);
      _lastLoadedChatId = selectedChat.id;
      fetchData();
    };
  }, [selectedChat]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadFirebase = () => {
    if (_unsubscribe != null) {
      _unsubscribe();
    }
    const repositoryDataRef = collection(doc(firestore, `negotiations-${process.env.REACT_APP_FIREBASE_SUFFIX}`, selectedChat.id.toString()), "data");
    const firebaseBatchLimit = 10;
    const q = query(repositoryDataRef, where("sender_id", "!=", user.id), orderBy("created_at", "desc"), limit(firebaseBatchLimit));
    _unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const messagesLength = messagesRef.current.length;
        if (change.type === "added") {
          const newMessage = decryptJson(change.doc.data()['data'], selectedChat.secret);
          let addMessage = messagesLength === 0;
          if (messagesLength > 0) {
            const lastLoadedMessageDate = moment(messagesRef.current[messagesLength-1].created_at ?? '2024-01-01');
            const newMessageDate = moment(newMessage.created_at ?? '2024-01-01');
            addMessage = !newMessageDate.isBefore(lastLoadedMessageDate) && !messagesRef.current.find(m => m.id === newMessage.id);
          }
          if (addMessage) {
            if (_updateVisualizedTimeout == null) {
              _updateVisualizedTimeout = setTimeout(() => {
                //serve para que o backend atualize a data da ultima mensagem visualizada.
                NegotiationMessageRepository.list({ limit: 1, negotiations: selectedChat.id, });
                _updateVisualizedTimeout = null;
              }, 2000);
            }

            newMessage.sender = {};
            for (const key of Object.keys(newMessage)) {
              if (key.includes('sender_')) {
                newMessage.sender[key.substring(7)] = newMessage[key];
                delete newMessage[key];
              }
            }
            setMessages(prevMessages => [ newMessage, ...prevMessages ]);
            _currentOffset++;
          }
        }
        //se for o ultimo da lista, é só o firebase reajustando a lista.
        //  aqui há uma limitação pois se foi apagado justamente esse registro, 
        //  então o user conseguirá ler uma mensagem que ja foi apagada até dar um F5 ou navegar pelo site
        if (change.type === "removed" && messagesLength > 0 && change.oldIndex < firebaseBatchLimit-1) { 
          const removedMessage = decryptJson(change.doc.data()['data'], selectedChat.secret);
          const newMessages = [ ...messagesRef.current ];
          const messageDeleted = newMessages.find(m => m.id === removedMessage.id);
          messageDeleted.deleted_at = moment().toISOString();
          messageDeleted.message = 'Esta mensagem foi excluída';
          setMessages(newMessages);
        }
      });
    });   
  }

  const nextMessagesPage = async () => {
    setLoading(true);
    const data = await NegotiationMessageRepository.list({
      negotiations: selectedChat.id,
      offset: _currentOffset + 20,
      limit: 20,
    });
    setLoading(false);
    if (data.results) {
      _currentOffset = _currentOffset + 20;
      setMessages([...messages, ...data.results]);
    }
  };

  useEffect(() => {
    messagesRef.current = messages;
    setMessageGroups(
      messages
        ?.reduce((groups, message) => {
          const date = formatDate(message.created_at);

          const existingGroup = groups.find(group => group.date === date);
          if (existingGroup) {
            existingGroup.messages.push(message);
          } else {
            groups.push({
              date: date,
              messages: [message],
            });
          }

          return groups;
        }, [])
        .reverse()
    );
  }, [messages]);

  const submitMessage = async message => {
    if (message == null || (message.text === '' && message.file_type === '')) return;
    const newMessage = await NegotiationMessageRepository.create(otherOrgIdRef.current, selectedChat.secret, {
      ...message,
      sender: user.id,
      negotiations: selectedChat.id,
    });

    if (newMessage) setMessages([newMessage, ...messages]);
  };

  if (selectedChat == null) {
    return '';
  }

  const deleteClick = async () => {
    setDeleting(true);
    const data = await NegotiationMessageRepository.deleteMessages(selectedChat.id, selectedIds);
    if (data?.error) {
      dispatch(
        layoutActions.showToast({
          content: t('erros.text8'),
          duration: 3000,
        })
      );
    } else {
      messages.filter(m => selectedIds.includes(m.id)).forEach(message => {
        message.deleted_at = (new Date()).toString();
        message.message = 'Esta mensagem foi excluída';
      });
      setMessages([...messages]);
      setSelectedIds([]);
    }
    setDeleting(false);
  }

  const onCheckClick = (checked, id) => {
    if (checked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      const index = selectedIds.indexOf(id);
      if (index > -1) {
        selectedIds.splice(index, 1);
        setSelectedIds([...selectedIds]);
      }
    }
  }

  const deleteButtonClasses = [styles.deleteButton];
  if (deleting) {
    deleteButtonClasses.push(styles.deleting);
  }


  const transferMarketIsActive = selectedChat.transfer_market?.is_active ?? false;
  return (
    <div className={styles.container}>
      <Header
        chat={selectedChat}
        user={user}
        otherChatClick={otherChatClick}
        showOtherChatsAlert={!!selectedChat.oldChats?.length}
        openNegotiations={openNegotiations}
        onAthleteFilterChange={onAthleteFilterChange}
      ></Header>
      <>
        <div className={styles.messageWrapper}>
          {selectedIds.length > 0 && <HiTrash className={deleteButtonClasses.join(' ')} onClick={deleteClick} />}
          <div className={styles.content} ref={scrollRef} onScroll={() => {
            const scrollTop = scrollRef?.current?.scrollTop ?? 100;
            if (scrollTop < 100) {
              if (_currentPageLength !== messages.length && messages.length >= 20) {
                _currentPageLength = messages.length;
                _isLoadedNextPage = true;
                nextMessagesPage();
              }
            }
          }}>
            {!loading && error != null && (
              <div className={styles.noMessages}>
                <span>{error}</span>
              </div>
            )}
            {!loading && (messages == null || messages.length === 0) && (
              <div className={styles.noMessages}>
                <span>{t('negotiations.noMessages')}</span>
              </div>
            )}
            {loading && (
              <div className={styles.loading}>
                <Spinner width="40" height="40" />
              </div>
            )}
            {messageGroups?.map(group => {
              return (
                <div className={styles.dateWrapper}>
                  <div className={styles.date}>{group.date}</div>
                  <div className={styles.messages}>
                    {group.messages.map(message => {
                      const hour = moment(message.created_at).format('HH:mm');
                      return (
                        <ChatMessage
                          key={message.id}
                          id={message.id}
                          user={user}
                          message={message}
                          hour={hour}
                          checked={selectedIds.includes(message.id)}
                          onCheckClick={onCheckClick}
                        ></ChatMessage>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {
          transferMarketIsActive && (
            <Footer submitMessage={submitMessage}></Footer>
          )
        }
      </>
    </div>
  );
}
