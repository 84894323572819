import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as athleteActions } from 'store/ducks/athlete';
import { Creators as clubActions } from 'store/ducks/club';
import { Creators as layoutCreators } from 'store/ducks/layout';
import { useTranslation } from 'react-i18next';

import { Card, Spinner } from 'components/template';
import { FaEye, FaRegCalendarAlt, FaFileAlt } from 'react-icons/fa';
import { IoIosFootball } from 'react-icons/io';
import isEmpty from 'lodash/isEmpty';
import styles from './Evaluation.module.scss';
import TopBar from '../TopBar/TopBar';
import ConfigBar from './ConfigBar/ConfigBar';
import SideSection from './SideSection/SideSection';
import FieldList from './FieldList/FieldList';
import { useMount } from 'shared/hooks';
import moment from 'moment';
import GameFields from './GameFields/GameFields';
import { defaultClubEvaluationFields } from 'shared/mocks';

const Evaluation = ({ match, isNew, history }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.data);
  const athleteData = useSelector(state => state.athlete.athleteData);
  const evaluationData = useSelector(state => state.athlete.evaluationData);
  const evaluationFields = useSelector(state => state.club.evaluationFields);
  const { t } = useTranslation();

  const [generalRate, setGeneralRate] = useState(0);
  const [targeting, setTargeting] = useState('');
  const [priority, setPriority] = useState('');
  const [mode, setMode] = useState('');
  const [feedback, setFeedback] = useState('');
  const [teamContext, setTeamContext] = useState('');
  const [fields, setFields] = useState([]);
  const [matchFields, setMatchFields] = useState({});

  useEffect(() => {
    if (evaluationFields && (user.organization.id.id === 152)) {      
      if(evaluationFields.data.filter(item => item?.mode === 'RELATORIO-DE-JOGO').length === 0){
        evaluationFields.data.push(defaultClubEvaluationFields[0]);
      } 
    }
  }, [evaluationFields]);//eslint-disable-line

  const cleanEvaluation = useCallback(() => {
    dispatch(athleteActions.resetEvaluation());
    dispatch(clubActions.resetEvaluationFields());
  }, [dispatch]);

  useMount(() => {
    dispatch(clubActions.getEvaluationFields());
    dispatch(athleteActions.getAthleteData(match.params.athleteId));

    if (!isNew) {
      dispatch(
        athleteActions.getEvaluation({
          athleteId: match.params.athleteId,
          id: match.params.evaluationId,
        })
      );
    }

    return () => cleanEvaluation();
  });

  const updateFields = useCallback(
    data => {
      if (data?.mode) setMode(data.mode);
      if (data?.feedback) setFeedback(data.feedback);
      if (data?.teamContext) setTeamContext(data.teamContext);
      if (data?.values) {
        setFields(data.values.fields);
      }
      if (data?.match_details) {
        setMatchFields(data.match_details);
      }
      if (data?.recommendation) setTargeting(data.recommendation.toLowerCase());
      if (data?.priority) setPriority(data.priority.toLowerCase());
    },
    [setMode]
  );

  // redirect to the created evaluation
  useEffect(() => {
    if (evaluationData.saved) {
      dispatch(athleteActions.resetEvaluation());
      dispatch(
        layoutCreators.showToast({
          onCloseHandler: () => dispatch(athleteActions.saveEvaluationErrorHandled()),
          content: t('organi.evaluationSaved'),
          duration: 1500,
          type: 'success',
        })
      );
      if (isNew) {
        const id = evaluationData.data.id;
        const athleteId = match.params.athleteId;
        const url = `/atletas/${athleteId}/avaliacoes/${id}`;
        history.push(url);
      }
    }
  }, [evaluationData.saved, evaluationData.data, history, match.url, dispatch, isNew, match.params.athleteId]); // eslint-disable-line react-hooks/exhaustive-deps

  // effect used to calculate the general evaluation
  useEffect(() => {
    if (fields?.length) {
      const sumRates = fields.map(f => f?.rate ?? 0).reduce((acc, rate) => acc + rate);
      setGeneralRate(sumRates / fields.length);
    }
  }, [fields]);

  // error handler
  useEffect(() => {
    if (evaluationData.error) {
      dispatch(
        layoutCreators.showToast({
          onCloseHandler: () => dispatch(athleteActions.saveEvaluationErrorHandled()),
          content: evaluationData.error,
          duration: 1500,
        })
      );
    }
  }, [evaluationData.error, dispatch]);

  // empty evaluation data
  useEffect(() => {
    if (isNew && evaluationFields.data?.length) {
      updateFields(evaluationFields.data.find(s => s.mode === mode));
    }
  }, [evaluationData.data, evaluationFields.data, updateFields, mode, isNew]);

  // existing evaluation data fillup
  useEffect(() => {
    if (!isNew) {
      updateFields(evaluationData.data);
    }
  }, [evaluationData.data, updateFields, isNew]);

  function saveEvaluationHandler() {
    const requestData = {
      org: user.organization?.id,
      target: match.params.athleteId,
      method: 'GERAL',
      mode: mode.toUpperCase(),
      recommendation: targeting.toUpperCase(),
      priority: priority.toUpperCase(),
      author_id: user.id,
      evaluation_date: moment().format('YYYY-MM-DD'),
      data: {
        teamId: user.organization?.teamId,
        values: {
          fields,
        },
        match_details: matchFields,
        feedback,
        teamContext,      
      },
      match_position: matchFields?.position || null,
    };
    dispatch(
      athleteActions.saveEvaluation({
        athleteId: match.params.athleteId,
        evaluationData: requestData,
        id: isNew ? null : match.params.evaluationId,
      })
    );
  }

  const setFieldsCb = useCallback(setFields, []);

  let evaluationHeader = null;

  if (!isEmpty(evaluationData.data) && !isNew) {
    evaluationHeader = (
      <div className={styles.evaluationHeaderInfo}>
        <h1>{t('evaluationData')}</h1>
        <div>
          <FaEye /> <span>{evaluationData?.data?.author?.name ?? evaluationData?.data?.author?.email}</span>
        </div>
        <div>
          <FaRegCalendarAlt />{' '}
          <span>{new Date(evaluationData.data.created_at).toLocaleDateString('pt-BR') || ' '}</span>
        </div>
        <div>
          <IoIosFootball /> <span>{evaluationData.data.method}</span>
        </div>
        {evaluationData.data.mode_name && (
          <div>
            <FaFileAlt /> <span>{evaluationData.data.mode_name}</span>
          </div>
        )}
      </div>
    );
  }

  let content = null;

  if (athleteData.loading || evaluationFields.loading || evaluationData.loading) {
    content = (
      <div className={styles.loaderContainer}>
        <Spinner />
      </div>
    );
  } else if (athleteData.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        <h2>{t(athleteData.error)}</h2>
      </Card>
    );
  } else {
    content = (
      <>
        <TopBar athlete={athleteData.data} />
        <div className={styles.innerContainer}>
          <ConfigBar
            configBar={{
              generalRate,
              targeting,
              priority,
              mode,
              evaluationFields,
              setTargeting,
              setPriority,
              setMode,
            }}
            updateFields={updateFields}
            evaluationData={evaluationData.data}
            saveEvaluation={saveEvaluationHandler}
          />          
          <div className={styles.evaluationContainer}>            
            <Card softShadow className={styles.contentCard}>                         
              {/* body */}
              <div className={styles.body}>
                {!mode ? (
                  <p className={styles.noModeSelected}>{t('field.selec')}</p>
                ) : (
                  <>
                    <div>                      
                      <SideSection
                        fields={fields}
                        feedback={feedback || ''}
                        setFeedback={v => setFeedback(v)}
                        teamContext={teamContext || ''}
                        setTeamContext={v => setTeamContext(v)}
                      />
                      <div className={styles.header}>
                        {evaluationHeader}
                      </div>
                    </div>                    
                    <div className={mode === 'RELATORIO-DE-JOGO' ? styles.fieldList2 : styles.fieldList}>
                      <h3>{t('field.asp')}</h3>
                      <FieldList saveEvaluation={saveEvaluationHandler} fields={fields} setFields={setFieldsCb} />                      
                    </div>
                  </>
                )}
              </div>
            </Card>
            { (user.organization.id.id === 152) && mode === 'RELATORIO-DE-JOGO' && (
              <div className={styles.matchInfo}>
                <h3>{t('field.gameData')}</h3>
                <GameFields fields={matchFields} setFields={setMatchFields}/>
              </div>  
            )}
          </div>
        </div>
      </>
    );
  }

  return <div className={styles.container}>{content}</div>;
};

Evaluation.defaultProps = {
  isNew: false,
};

Evaluation.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  isNew: PropTypes.bool,
};

export default withRouter(Evaluation);
