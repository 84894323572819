// hooks
import React from 'react';
import { useTranslation } from 'react-i18next';

// scss
import styles from './SideBar.module.scss';

// components
import Header from './Header/Header';
import ChatItem from './ChatItem/ChatItem';
import { Spinner } from 'components/template';

export default function SideBar({
  repository,
  chats,
  messages,
  handleFilterChange,
  filters,
  selectedChat,
  user,
  sidebarLoading,
  handleSelectChat,
}) {
  const { t } = useTranslation();
  const renderSpinner = () => <Spinner width="40" height="40" className={styles.spinner} />;
  const renderChatItems = () => (
    <>
      {chats?.map(chat => (
        <ChatItem
          key={chat.id}
          chat={chat}
          user={user}
          messages={messages}
          selectedChat={selectedChat}
          repository={repository}
          handleSelectChat={handleSelectChat}
        />
      ))}
      {selectedChat?.oldChats?.map(chat => (
        <ChatItem
          key={chat.id}
          chat={chat}
          user={user}
          selectedChat={selectedChat}
          repository={repository}
          handleSelectChat={handleSelectChat}
        />
      ))}
    </>
  );

  return (
    <div className={styles.container}>
      {repository != null && (
        <Header
          selectedRepository={repository}
          sidebarLoading={sidebarLoading}
          chats={chats}
          chat={selectedChat}
          filters={filters}
          handleFilterChange={handleFilterChange}
        />
      )}
      {!!chats.length && (
        <div className={styles.content}>
          {sidebarLoading && renderSpinner()}
          {!sidebarLoading && renderChatItems()}
        </div>
      )}
      {!sidebarLoading && !chats.length && (
        <div className={styles.noChatSelected}>
          {repository != null ? <span>{t('chat.no_chat')}</span> : <span>{t('chat.select_category')}</span>}
        </div>
      )}
    </div>
  );
}
