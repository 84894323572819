import { monitoringMock } from 'shared/mocks';

export const Types = {
  GET: 'monitoring/GET',
  GET_SUCCESS: 'monitoring/GET_SUCCESS',
  GET_FAILED: 'monitoring/GET_FAILED',
  ERROR_HANDLED: 'monitoring/ERROR_HANDLED',
};

const initialState = {
  loading: false,
  error: null,
  data: [],
};

export default function monitoringReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET:
      return { ...state, loading: true };
    case Types.GET_FAILED:
      return { ...state, loading: false, error: action.payload.error };
    case Types.GET_SUCCESS:
      return { ...state, loading: false, data: action.payload.data };
    case Types.ERROR_HANDLED:
      return { ...state, error: null };
    default:
      return state;
  }
}

export const Creators = {
  getMonitoring: () => dispatch => {
    dispatch({ type: Types.GET });
    setTimeout(() => {
      dispatch({ type: Types.GET_SUCCESS, payload: { data: monitoringMock } });
    }, 550);
  },
};
