import Endpoints from 'endpoints';
import axios from 'api';
import ErrorMessages from 'shared/ErrorMessages';
import { stringifyFilters } from 'shared/utility';

export const actionTypes = {
  GET_PROJECTS: 'project/GET_ALL',
  GET_PROJECTS_SUCCESS: 'project/GET_ALL_SUCCESS',
  GET_PROJECTS_ERROR: 'project/GET_ALL_ERROR',
  GET_PROJECT: 'project/GET',
  GET_PROJECT_SUCCESS: 'project/GET_SUCCESS',
  GET_PROJECT_ERROR: 'project/GET_ERROR',
  GET_PROJECT_SHADOWTEAM: 'project/GET_PROJECT_SHADOWTEAM',
  GET_PROJECT_SHADOWTEAM_SUCCESS: 'project/GET_PROJECT_SHADOWTEAM_SUCCESS',
  GET_PROJECT_SHADOWTEAM_ERROR: 'project/GET_PROJECT_SHADOWTEAM_ERROR',
  GET_ATHLETES: 'athletes/GET_ATHLETES',
  GET_ATHLETES_SUCCESS: 'athletes/GET_ATHLETES_SUCCESS',
  GET_ATHLETES_ERROR: 'athletes/GET_ATHLETES_ERROR',
  GET_WORKFLOW: 'athletes/GET_WORKFLOW',
  GET_WORKFLOW_SUCCESS: 'athletes/GET_WORKFLOW_SUCCESS',
  GET_WORKFLOW_ERROR: 'athletes/GET_WORKFLOW_ERROR',
  SHADOWTEAM_RESET: 'project/SHADOWTEAM_RESET',
  RESET: 'project/RESET',
};

const initialState = {
  loading: false,
  error: null,
  data: {},
  shadowTeamsData: {},
  workflow: {
    loading: false,
    error: null,
    data: null,
  },
  athletes: {
    loading: false,
    error: null,
    data: [],
    hasNext: true,
    hasPrevious: false,
    count: 0,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PROJECTS:
      return { ...state, loading: true };
    case actionTypes.GET_PROJECTS_SUCCESS:
      return { ...state, loading: false, data: action.payload.data };
    case actionTypes.GET_PROJECTS_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.GET_PROJECT:
      return { ...state, loading: true };
    case actionTypes.GET_PROJECT_SUCCESS:
      return { ...state, loading: false, data: action.payload.data, error: null };
    case actionTypes.GET_PROJECT_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.GET_PROJECT_SHADOWTEAM:
      return { ...state, loading: true };
    case actionTypes.GET_PROJECT_SHADOWTEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        shadowTeamsData: action.payload.shadowTeamsData,
      };
    case actionTypes.GET_PROJECT_SHADOWTEAM_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.GET_ATHLETES:
      return {
        ...state,
        athletes: {
          ...state.athletes,
          loading: true,
        },
      };
    case actionTypes.GET_ATHLETES_SUCCESS:
      return {
        ...state,
        athletes: {
          ...state.athletes,
          loading: false,
          data: action.payload.data,
          hasNext: action.payload.hasNext,
          hasPrevious: action.payload.hasPrevious,
          count: action.payload.count,
          error: null,
        },
      };
    case actionTypes.GET_ATHLETES_ERROR:
      return {
        ...state,
        athletes: {
          ...state.athletes,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_WORKFLOW:
      return {
        ...state,
        workflow: {
          ...state.workflow,
          loading: true,
        },
      };
    case actionTypes.GET_WORKFLOW_SUCCESS:
      return {
        ...state,
        workflow: {
          ...state.workflow,
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case actionTypes.GET_WORKFLOW_ERROR:
      return {
        ...state,
        workflow: {
          ...state.workflow,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.SHADOWTEAM_RESET:
      return {
        ...state,
        loading: false,
        shadowTeamsData: {},
      };
    case actionTypes.RESET:
      return { ...state, initialState };
    default:
      return state;
  }
}

const _getProject = async id => {
  const { data } = await axios.get(`${Endpoints.projects}/${id}`);
  (data.workflow ?? []).sort((a, b) => {
    return a.order - b.order;
  });
  return data;
};

export const Creators = {
  getProjects: filters => async dispatch => {
    dispatch({ type: actionTypes.GET_PROJECTS });
    try {
      const queryString = stringifyFilters(filters);
      const { data } = await axios.get(`${Endpoints.projects}${queryString}`);      
      dispatch({
        type: actionTypes.GET_PROJECTS_SUCCESS,
        payload: { data },
      });
    } catch (err) {
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_PROJECTS_ERROR,
          payload: { error: 'erros.text31' },
        });
      } else if (response?.status === 401) {
        dispatch({
          type: actionTypes.GET_PROJECTS_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_PROJECTS_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getProject: id => async dispatch => {
    dispatch({ type: actionTypes.GET_PROJECT });
    try {
      const data = await _getProject(id);
      dispatch({
        type: actionTypes.GET_PROJECT_SUCCESS,
        payload: { data },
      });
    } catch (err) {
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_PROJECT_ERROR,
          payload: { error: 'erros.text32' },
        });
      } else if (response?.status === 401) {
        dispatch({
          type: actionTypes.GET_PROJECT_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_PROJECT_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  createProject: async project => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('name', project.name);
      if (project.photo) {
        formData.append('photo', project.photo);
      }
      const { data } = await axios.post(`${Endpoints.projects}`, formData, config);
      return { data };
    } catch (err) {
      return {
        error: 'Ocorreu um erro ao tentar criar o projeto. Por favor, tente novamente',
        status: err?.response?.status,
      };
    }
  },
  updateProject: async project => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('name', project.name);
      if (project.photo && !(typeof project.photo === 'string' || project.photo instanceof String)) {
        // se for string nao deve adicionar pois tratasse do link
        formData.append('photo', project.photo);
      }
      if (project.is_archived !== undefined) {
        formData.append('is_archived', project.is_archived);
      }
      const { data } = await axios.put(`${Endpoints.projects}/${project.id}`, formData, config);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar atualizar o projeto. Por favor, tente novamente' };
    }
  },
  deleteProject: async project => {
    try {
      const { data } = await axios.delete(`${Endpoints.projects}/${project.id}`);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar remover o projeto. Por favor, tente novamente' };
    }
  },
  updateProjectFrames: async (projectId, frames) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('frames', JSON.stringify(frames));
      const { data } = await axios.put(`${Endpoints.projects}/${projectId}`, formData, config);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar atualizar o projeto. Por favor, tente novamente' };
    }
  },
  getWorkflow: (projectId, workflowId) => async dispatch => {
    dispatch({ type: actionTypes.GET_WORKFLOW });
    try {
      const { data } = await axios.get(`${Endpoints.projects}/${projectId}/workflow/${workflowId}`);
      dispatch({
        type: actionTypes.GET_WORKFLOW_SUCCESS,
        payload: { data },
      });
    } catch (err) {
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_WORKFLOW_ERROR,
          payload: { error: 'erros.text32' },
        });
      } else if (response?.status === 401) {
        dispatch({
          type: actionTypes.GET_WORKFLOW_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_WORKFLOW_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  createWorkflow: async (projectId, frame, athlete) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('frame', frame);
      formData.append('athlete', athlete);
      const { data } = await axios.post(`${Endpoints.projects}/${projectId}/workflow`, formData, config);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar adicionar o atleta no Workflow. Por favor, tente novamente' };
    }
  },
  updateIndicateWorkflow: async (projectId, workflowId, indicate) => {
    try {
      const body = {
        indicated_by: indicate,
      };
      const { data } = await axios.patch(`${Endpoints.projects}/${projectId}/workflow/${workflowId}`, body);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar atualizar o atleta no Workflow. Por favor, tente novamente' };
    }
  },
  updateFrameWorkflow: async (projectId, workflowId, frame, order) => {
    try {
      const body = {
        frame: frame,
        order: order,
      };
      const { data } = await axios.patch(`${Endpoints.projects}/${projectId}/workflow/${workflowId}`, body);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar atualizar o atleta no Workflow. Por favor, tente novamente' };
    }
  },
  deleteWorkflow: async (projectId, workflowId) => {
    try {
      const { data } = await axios.delete(`${Endpoints.projects}/${projectId}/workflow/${workflowId}`);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar remover o atleta. Por favor, tente novamente' };
    }
  },
  createUser: async (projectId, userId, permission) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('user', userId);
      formData.append('permission', permission);
      const { data } = await axios.post(`${Endpoints.projects}/${projectId}/users`, formData, config);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar adicionar o usuário no projeto. Por favor, tente novamente' };
    }
  },
  updateUser: async (projectId, projectUserId, userId, permission) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('user', userId);
      formData.append('permission', permission);
      const { data } = await axios.put(`${Endpoints.projects}/${projectId}/users/${projectUserId}`, formData, config);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar atualizar o usuário do projeto. Por favor, tente novamente' };
    }
  },
  removeUser: async (projectId, projectUserId) => {
    try {
      const { data } = await axios.delete(`${Endpoints.projects}/${projectId}/users/${projectUserId}`);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar remover o usuário do projeto. Por favor, tente novamente' };
    }
  },
  sendUserInvite: async (projectId, email, invitePermission) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('email', email);
      formData.append('invitePermission', invitePermission);
      const { data } = await axios.post(`${Endpoints.projects}/${projectId}/user_invite`, formData, config);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar enviar o email. Por favor, tente novamente' };
    }
  },
  getHistory: async projectId => {
    try {
      const { data } = await axios.get(`${Endpoints.projects}/${projectId}/history?limit=100`);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar obter o histórico. Por favor, tente novamente' };
    }
  },
  createShadowTeam: async (projectId, colorfield, modelfield, name, schema) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('color_field', colorfield);
      formData.append('modelfield', modelfield);
      formData.append('name', name);
      formData.append('schema', schema);
      const { data } = await axios.post(`${Endpoints.projects}/${projectId}/shadow_teams`, formData, config);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar criar o time-sombra. Por favor, tente novamente' };
    }
  },
  updateShadowTeam: async (projectId, shadowTeamId, colorfield, modelfield, name, schema) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('color_field', colorfield);
      formData.append('modelfield', modelfield);
      formData.append('name', name);
      formData.append('schema', schema);
      const { data } = await axios.put(
        `${Endpoints.projects}/${projectId}/shadow_teams/${shadowTeamId}`,
        formData,
        config
      );
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar atualizar o time-sombra. Por favor, tente novamente' };
    }
  },
  deleteShadowTeam: async (projectId, shadowTeamId) => {
    try {
      const { data } = await axios.delete(`${Endpoints.projects}/${projectId}/shadow_teams/${shadowTeamId}`);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar remover o atleta. Por favor, tente novamente' };
    }
  },
  getShadowTeam: (projectId, shadowTeamId) => async dispatch => {
    dispatch({ type: actionTypes.GET_PROJECT_SHADOWTEAM });
    try {
      const data = await _getProject(projectId);
      let shadowTeamsData;
      if (shadowTeamId > 0) {
        const { data: aShadowTeamsData } = await axios.get(
          `${Endpoints.projects}/${projectId}/shadow_teams/${shadowTeamId}`
        );
        shadowTeamsData = aShadowTeamsData;
      }
      dispatch({
        type: actionTypes.GET_PROJECT_SHADOWTEAM_SUCCESS,
        payload: { data, shadowTeamsData },
      });
    } catch (err) {
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_PROJECT_SHADOWTEAM_ERROR,
          payload: { error: 'erros.text31' },
        });
      } else if (response?.status === 401) {
        dispatch({
          type: actionTypes.GET_PROJECT_SHADOWTEAM_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_PROJECT_SHADOWTEAM_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  createShadowTeamPosition: async (projectId, shadowTeamId, position) => {
    try {
      const config = {
        headers: {
          'content-type': 'application/json',
        },
      };
      const response = await axios.post(
        `${Endpoints.projects}/${projectId}/shadow_teams/${shadowTeamId}/positions`,
        position,
        config
      );

      return { data: response.data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar criar a posição. Por favor, tente novamente' };
    }
  },
  syncShadowTeamPositions: async (projectId, shadowTeamId, positions, oldPositions) => {
    try {
      const config = {
        headers: {
          'content-type': 'application/json',
        },
      };
      const promises = positions.map(p =>
        p.id
          ? axios.put(
            `${Endpoints.projects}/${projectId}/shadow_teams/${shadowTeamId}/positions/${p.id}`,
            {
              shadow_team: shadowTeamId,
              ...p,
            },
            config
          )
          : axios.post(`${Endpoints.projects}/${projectId}/shadow_teams/${shadowTeamId}/positions`, p, config)
      );
      const responses = await Promise.all(promises);

      if (oldPositions) {
        const newPositions = positions.map(item => item.id);
        oldPositions
          .filter(p => !newPositions.includes(p.id))
          .map(p =>
            axios.delete(`${Endpoints.projects}/${projectId}/shadow_teams/${shadowTeamId}/positions/${p.id}`, config)
          );
      }

      return { data: responses.map(i => i.data) };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar atualizar as posições. Por favor, tente novamente' };
    }
  },
  syncShadowTeamAthletes: async (projectId, shadowTeamId, athletes, oldAthletes) => {
    try {
      const config = {
        headers: {
          'content-type': 'application/json',
        },
      };
      const responses = [];
      const athletesSplit = [ ...athletes ];
      while (athletesSplit.length) {
        //divide as chamadas ao backend de 10 em 10
        const aSplit = athletesSplit.splice(0, 10);
        const promises = aSplit.map(a =>
          a.id
            ? axios.put(
              `${Endpoints.projects}/${projectId}/shadow_teams/${shadowTeamId}/athletes/${a.id}`,
              {
                shadow_team: shadowTeamId,
                ...a,
                athlete: a.athlete.id,
              },
              config
            )
            : axios.post(
              `${Endpoints.projects}/${projectId}/shadow_teams/${shadowTeamId}/athletes`,
              {
                ...a,
                athlete: a.athlete.id,
              },
              config
            )
        );
        const res = await Promise.all(promises);
        responses.push(...res);
        if (athletesSplit.length) {
          //faz uma pequena pausa de 400 ms
          await new Promise( res => setTimeout(res, 400));
        }
      }

      if (oldAthletes) {
        const newAthletes = athletes.map(item => item.id);
        oldAthletes
          .filter(a => !newAthletes.includes(a.id))
          .map(a =>
            axios.delete(`${Endpoints.projects}/${projectId}/shadow_teams/${shadowTeamId}/athletes/${a.id}`, config)
          );
      }

      return {
        data: responses.map(i => ({
          ...i.data,
          athlete: i.data.athlete_data,
        })),
      };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar atualizar as posições. Por favor, tente novamente' };
    }
  },
  createAthleteShadowTeam: async (projectId, shadowTeamId, athleteId, position, positionCode) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('athlete', athleteId);
      formData.append('position', position);
      formData.append('positionCode', positionCode);
      const { data } = await axios.post(
        `${Endpoints.projects}/${projectId}/shadow_teams/${shadowTeamId}/athletes`,
        formData,
        config
      );
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar adicionar o atleta no time-sombra. Por favor, tente novamente' };
    }
  },
  updateAthleteShadowTeam: async (projectId, shadowTeamId, shadowTeamAthleteId, athleteId, position, positionCode) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('athlete', athleteId);
      formData.append('position', position);
      formData.append('positionCode', positionCode);
      const { data } = await axios.put(
        `${Endpoints.projects}/${projectId}/shadow_teams/${shadowTeamId}/athletes/${shadowTeamAthleteId}`,
        formData,
        config
      );
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar atualizar o atleta do time-sombra. Por favor, tente novamente' };
    }
  },
  removeAthleteShadowTeam: async (projectId, shadowTeamId, shadowTeamAthleteId) => {
    try {
      const { data } = await axios.delete(
        `${Endpoints.projects}/${projectId}/shadow_teams/${shadowTeamId}/athletes/${shadowTeamAthleteId}`
      );
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar remover o atleta do time-sombra. Por favor, tente novamente' };
    }
  },
  getAthletes: (projectId, shadowTeamId, filters = {}) => async dispatch => {
    dispatch({ type: actionTypes.GET_ATHLETES });
    const url = `${Endpoints.projects}/${projectId}/shadow_teams/${shadowTeamId}/workflow`;

    try {
      if (!filters.limit) filters.limit = 20;
      const queryString = stringifyFilters(filters);

      const { data } = await axios.get(`${url}${queryString}`);

      dispatch({
        type: actionTypes.GET_ATHLETES_SUCCESS,
        payload: {
          data: data.results,
          hasNext: data.next ? true : false,
          hasPrevious: data.previous ? true : false,
          count: data.count,
        },
      });
    } catch (err) {
      const { response } = err;

      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_ATHLETES_ERROR,
          payload: { error: 'erros.text13' },
        });
      } else if (response?.status === 401) {
        dispatch({
          type: actionTypes.GET_ATHLETES_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_ATHLETES_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  resetProject: () => ({ type: actionTypes.RESET }),
  resetShadowTeam: () => ({ type: actionTypes.SHADOWTEAM_RESET }),
};
