import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Button } from 'components/template';
import { useTranslation } from 'react-i18next';
import { createAgencyRequest, resetAgencyRequest } from 'store/ducks/agencies';
import styles from './styles.module.scss';
import CenteredLoader from 'components/template/CenteredLoader';
import { Creators as layoutCreators } from 'store/ducks/layout';

let agencyDialogExcededPlanLimitShowed = false;
const AgencyDialog = ({ isOpen, setIsOpen, userAgencyId, athleteAgency, athleteId }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const agencyRequest = useSelector(state => state.agencies.request);

  useEffect(() => {
    return () => {
      dispatch(resetAgencyRequest());
    };
  }, [dispatch]);

  let contentTitle = '';
  let contentText = '';
  let contentButtonText = '';
  let contentButtonOnClick;

  if (!athleteAgency) {
    contentTitle = t('dialog_content.text1');
    contentText = t('dialog_content.text2');
    contentButtonText = t('dialog_content.text3');
    contentButtonOnClick = () => dispatch(createAgencyRequest(athleteId));
  } else if (userAgencyId !== athleteAgency.id) {
    contentTitle = t('dialog_content.text4');
    contentText = t('dialog_content.text5');
    contentButtonText = t('dialog_content.text6');
    contentButtonOnClick = () => dispatch(createAgencyRequest(athleteId));
  } else if (userAgencyId === athleteAgency.id) {
    contentTitle = t('dialog_content.text7');
    contentText = (
      <div>
        {t('dialog_content.text9')}
        <span className={styles.boldText}>{t('dialog_content.text11')}</span>
        {t('dialog_content.text10')}
      </div>
    );
    contentButtonText = t('dialog_content.text8');
    contentButtonOnClick = () => dispatch(createAgencyRequest(athleteId));
  }

  if (agencyRequest.loading) {
    contentText = <CenteredLoader size="50px" />;
  } else if (agencyRequest.error) {
    contentText = t(agencyRequest.error);
    if (!agencyDialogExcededPlanLimitShowed && agencyRequest.error.includes('maximumAthleteReached')) {
      agencyDialogExcededPlanLimitShowed = true;
      dispatch(layoutCreators.showExcededPlanLimit());
    }
  } else if (agencyRequest.data != null) {
    contentText = t(agencyRequest.data);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      mainTitle={contentTitle}
      className={{
        container: styles.dialogContainer,
        header: styles.dialogHeader,
        body: styles.dialogBody,
      }}
    >
      <div>{contentText}</div>
      {agencyRequest.data ? (
        <Button className={styles.button} rounded onClick={() => setIsOpen(false)}>
          {t('overview.text21')}
        </Button>
      ) : (
        <Button className={styles.button} rounded onClick={contentButtonOnClick}>
          {agencyRequest.error ? t('overview.text22') : contentButtonText}
        </Button>
      )}
    </Dialog>
  );
};

export default AgencyDialog;
