import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Card, StarRating, Menu, Button } from 'components/template';
import styles from './ConfigBar.module.scss';

const ConfigBar = props => {
  const { t } = useTranslation();
  const { configBar, updateFields, evaluationData, saveEvaluation } = props;
  const { generalRate, targeting, priority, mode, evaluationFields, setTargeting, setPriority, setMode } = configBar;

  function updateMode(val) {
    setMode(val);
    if (evaluationData && evaluationData?.mode === val) {
      updateFields(evaluationData);
    } else {
      updateFields(evaluationFields.data.find(s => s.mode === val));
    }
  }

  return (
    <Card className={styles.container} softShadow>
      <div className={styles.generalRate}>
        <b>{t('table.general')}: </b>
        <StarRating rate={generalRate} size={25} />
      </div>      
      <div className={styles.targetingContainer}>
        <Menu
          className={styles.largeSelect}
          allowEmpty
          label={<b>{t('config.dire')}:</b>}
          title={t('config.dire')}
          options={[
            { label: t('config.cont'), value: t('config.contr') },
            { label: t('config.mon'), value: t('config.moni') },
            { label: t('config.DESCARTAR'), value: t('config.discard') },
            { label: t('config.toEvaluate'), value: t('config.evaluate') },
          ]}
          value={targeting}
          setValue={val => setTargeting(val)}
          onClear={() => setTargeting(null)}
        />
      </div>
      <div className={styles.targetingContainer}>
        <Menu
          allowEmpty
          className={styles.largeSelect}
          label={<b>{t('config.pri')}:</b>}
          title={t('config.pri')}
          options={[
            {
              label: t('config.hi'),
              value: t('config.hig'),
              color: t('config.dang'),
            },
            {
              label: t('config.mid'),
              value: t('config.mi'),
              color: t('config.warn'),
            },
            {
              label: t('config.lo'),
              value: t('config.low'),
              color: t('config.inf'),
            },
          ]}
          value={priority}
          setValue={val => setPriority(val)}
          onClear={() => setPriority(null)}
        />
      </div>
      <div className={styles.targetingContainer}>
        <Menu
          className={styles.largeSelect}
          label={<b>{t('config.model')}:</b>}
          title={t('config.model')}
          options={evaluationFields.data.map(item => {
            return { label: item.mode_name, value: item.mode };
          })}
          value={mode}
          setValue={val => updateMode(val)}
          onClear={() => updateMode(null)}
        />
      </div>
      {saveEvaluation && (
        <div className={styles.targetingContainer}>
          <Button onClick={saveEvaluation} className={styles.saveButton} color="green" disabled={!mode} rounded smaller>
            {t('dialog_content.save')}
          </Button>
        </div>
      )}
    </Card>
  );
};

ConfigBar.propTypes = {
  configBar: PropTypes.shape({
    generalRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    targeting: PropTypes.string,
    priority: PropTypes.string,
    mode: PropTypes.string,
    evaluationFields: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string,
      data: PropTypes.instanceOf(Array),
    }),
    setTargeting: PropTypes.func.isRequired,
    setPriority: PropTypes.func.isRequired,
    setMode: PropTypes.func,
  }).isRequired,
  updateFields: PropTypes.func.isRequired,
  evaluationData: PropTypes.object.isRequired,
  saveEvaluation: PropTypes.func,
};

export default ConfigBar;
