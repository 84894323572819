/*eslint-disable eqeqeq*/
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { removeUser } from 'store/ducks/myClub';
import { Dialog } from 'components/template';
import styles from './UserDialog.module.scss';
import { useTranslation } from "react-i18next";

const UserDialog = ({ userDialog, setUserDialog, initialUserDialog, teamId }) => {
    const { t } = useTranslation();
    const [removeDisabled, setRemoveDisabled] = useState(false);
    
    const onClose = (updatePage) => {
        setUserDialog({
            ...initialUserDialog,
            updatePage,
        });
    }

    const onDelete = () => {
        setRemoveDisabled(true);
        removeUser(teamId, userDialog.removeUserId).then((result) => {
            setRemoveDisabled(false);
            if (result.error !== undefined) {
                alert(t('myClub.users.errorMessage'));
            } else {
                onClose(true);
            }
        });
    }

    return <Dialog
        isOpen={userDialog?.isOpen}
        onClose={() => onClose(false)}
        mainTitle={t('myClub.users.removeUser')}
        btnLeft={t('myClub.users.cancel')}
        btnLeftClick={() => onClose(false)}
        btnRight={t('myClub.users.remove')}
        btnRightColor="danger"
        btnRightClick={onDelete}
        btnRightDisabled={removeDisabled}
        className={{
            container: styles.dialogContainer,
        }}>
        {`${t('myClub.users.warningMessage')} ${userDialog.removeUserName}?`}
    </Dialog>
};

export default UserDialog;

UserDialog.defaultProps = {
    userDialog: PropTypes.instanceOf(Object).isRequired,
    setUserDialog: PropTypes.func.isRequired,
    initialUserDialog: PropTypes.instanceOf(Object).isRequired,
    teamId: PropTypes.number.isRequired,
};
