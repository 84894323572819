import React from 'react';
import PropTypes from 'prop-types';
import styles from './SideInfo.module.scss';
import { useTranslation } from 'react-i18next';
import SideBarReportsPro from './ReportsPro';
import moment from 'moment';

export default function SideInfo({ data }) {
  const { t } = useTranslation();
  const athleteId = data.id;

  // prettier-ignore
  const positionMap = {
    'Atacante': t('positionOptions.Atacante'),
    'Ataque': t('positionOptions.Ataque'),
    'Centroavante': t('positionOptions.Centroavante'),
    'Zagueiro': t('positionOptions.Zagueiro'),
    'Defesa': t('positionOptions.Defesa'),
    'Goleiro': t('positionOptions.Goleiro'),
    'Meia': t('positionOptions.Meia'),
    'Volante': t('positionOptions.Volante'),
    'Lateral Direito': t('positionOptions.LateralDireito'),
    'Lateral Esquerdo': t('positionOptions.LateralEsquerdo'),
    'Meia-atacante': t('positionOptions.MeiaAtacante'),
    'Meio-campo': t('positionOptions.MeioCampo'),
  };

  let avgEvaluation = null;
  if (data.length > 0) {
    avgEvaluation = 0;
    data.forEach(_eval => (avgEvaluation += _eval.general_evaluation));
    avgEvaluation /= data.length;
  }

  const nationalities = data?.nationalities?.length
    ? data.nationalities.map(item => (
      <div>
        <img src={item.flag} alt={item.name} className={styles.country} draggable="false" /> 
        { item.name ?? t('club.text9')}
      </div>
    ))
    : '';

  return (
    <div>
      <div className={styles.container}>
        <h1>{t('transferMarket.desiredProfile')}</h1>
        <div className={styles.info}>
          <div className={styles.images_display}>
            <SideBarReportsPro athleteId={athleteId} />
          </div>
          <div className={styles.detailedInfo}>
            <tr cellspacing="10">
              <td>{t('filters.profile')}</td>
              <td>{t(data?.amateur ? 'listTranslation.Amateur' : 'compe.text26')}</td>
            </tr>
            <tr>
              <td>{t('filters.gender')}</td>
              <td>{t(data?.female ? 'radioAdvancedSearch.female' : 'radioAdvancedSearch.male')}</td>
            </tr>
            <tr>
              <td>{t('table.idade')}</td>
              <td>{t('slider_menu.in')} {data?.min_age} {t('slider_menu.e')} {data?.max_age} {t('athlete_card.idade')}</td>
            </tr>
            <tr>
              <td>{t('athlete_card.pos')}</td>
              <td>{positionMap[data.position] ?? t('club.text12')}</td>
            </tr>
            <tr>
              <td>{t('athlete_card.foot')}</td>
              <td>{t(`preferentialFoot.${data.foot}`) ?? t('club.text12')}</td>
            </tr>
            <tr>
              <td>{t('athlete_card.nation')}:</td>
              <td>
                {nationalities}
              </td>
            </tr>
            <tr>
              <td>{t('filters.contract')}</td>
              <td>
                {t(`contractOptions.${data?.has_contract === true ? 'com-contrato' : data?.has_contract === false ? 'sem-contrato' : 'indiferente'}`)}
                {data?.has_contract && ` ${t('filters.ate')} ${moment(data?.contract_end).format('DD/MM/YYYY')}`}
              </td>
            </tr>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <h1>{t('transferMarket.otherFeatures')}</h1>
        <div className={styles.info} style={{padding: '20px'}}>
          {data?.characteristics}
        </div>
      </div>
    </div>
  );
}

SideInfo.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};
