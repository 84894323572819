import React from 'react';
import PropTypes from 'prop-types';
import styles from './Career.module.scss';
import { useTranslation } from 'react-i18next';
import { BiRightArrowAlt } from 'react-icons/bi';
import { Table } from 'components/template';
import moment from 'moment';

export default function Career({ data, total_amount, athleteId, redirect }) {
  const { t } = useTranslation();
  const tfooter = (
    <div onClick={() => redirect.push(`/atletas/${athleteId}/carreira`)} className={styles.link}>
      {t('overview.text1')}
    </div>
  );

  const theads = [
    t('slider_menu.seas'),
    t('table.data'),
    t('table.origin'),
    t(' '),
    t('table.destiny'),
    t('table.age_moment'),
    t('table.price'),
  ];

  const tdata = data
    ? data
        .filter(dt => dt.age_at_date !== null)
        .slice(0, 3)
        .map(d => {
          const origin = (
            <div key={d.id} className={styles.withImage}>
              <img src={d.src_team?.country?.flag} draggable="false" alt="" />
              <img src={d.src_team?.emblem} draggable="false" alt="" />
              <span>{d.src_team?.nickname}</span>
            </div>
          );

          const arrow = (
            <div className={styles.withImage}>
              <BiRightArrowAlt className={styles.arrow} />{' '}
            </div>
          );
          const destiny = (
            <div className={styles.withImage}>
              <img src={d.dst_team?.country?.flag} draggable="false" alt="" />
              <img src={d.dst_team?.emblem} draggable="false" alt="" />
              <span>{d.dst_team?.nickname}</span>
            </div>
          );
          const age = `${d.age_at_date?.years} ${t('athlete_card.idade')}, ${d.age_at_date?.months} ${t(
            'athlete_card.meses'
          )} ${d.age_at_date?.days} ${t('athlete_card.days')}`;

          return [
            d.season,
            moment(d.transfer_date).format('DD/MM/YYYY'),
            origin,
            arrow,
            destiny,
            age,
            d.monetary_value,
          ];
        })
    : [];

  return (
    <div className={styles.career}>
      <div className={styles.header}>
        <h3>{t('overview.text25')}</h3>
        <p>
          {t('overview.text3')}
          <strong>{total_amount}</strong>
        </p>
      </div>
      <Table className={styles.tableCarrer} theads={theads} data={tdata} footer={tfooter} />
    </div>
  );
}

Career.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  baseURL: PropTypes.string.isRequired,
};
