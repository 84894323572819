import React, { useState, useEffect } from 'react';
import axios from 'api';
import styles from './ReportsPRO.module.scss';
import ActionBar from './ActionBar/ActionBar';
import ReportsProCard from 'components/ReportsProCard/ReportsProCard';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from 'store/ducks/athlete';
import { Spinner, Input, IconButton, Button, Dialog, Checkbox } from 'components/template';
import { HiTrash } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { Creators as layoutActions } from 'store/ducks/layout';

const initialFilters = {
  type: null,
  period: {
    before: null,
    after: null,
  },
  origin: null,
};

export default function ReportsPRO({ athleteId, athlete, user }) {
  const { t } = useTranslation();

  const isCustomAthlete = athlete?.data?.custom_athlete ?? false;
  const initialReportInfo = {
    id: null,
    is_edit: false,
    is_public: !isCustomAthlete,
    thumbnail: null,
    title: '',
    file_type: 'pdf',
    video_link: '',
  };

  // states
  const [filters, setFilters] = useState(initialFilters);
  const [saving, setSaving] = useState(false);
  const [reportProDialog, setReportProDialog] = useState({
    isOpen: false,
    loading: false,
    data: initialReportInfo,
  });
  const [deleteReportId, setDeleteReportId] = useState(null);

  // api handler
  const dispatch = useDispatch();
  const reports = useSelector(state => state.athlete.reports_pro);
  const all_reports = reports.results;

  useEffect(() => {
    dispatch(Creators.getReportsPro(athleteId, filters));
  }, [dispatch, athleteId, filters]);

  function onClose() {
    setReportProDialog({
      isOpen: false,
      loading: false,
      data: initialReportInfo,
    });
  }

  const readFile = file => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.addEventListener('load', function(e) {
        resolve(e.target.result);
      });

      reader.readAsDataURL(file);
    });
  };

  function handleThumbUpdate() {
    const imageInput = document.createElement('input');
    imageInput.type = 'file';
    imageInput.multiple = false;
    imageInput.addEventListener('change', async e => {
      const photo = e.target.files[0];
      const photo64 = await readFile(photo);
      setReportProDialog({
        ...reportProDialog,
        data: {
          ...reportProDialog.data,
          thumbnail: photo,
          thumbnail64: photo64,
        },
      });
    });
    imageInput.click();
  }

  function handleImageDelete() {
    setReportProDialog({
      ...reportProDialog,
      data: {
        ...reportProDialog.data,
        thumbnail: null,
        thumbnail64: null,
      },
    });
  }

  function handleFileUpload() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = false;
    fileInput.accept = 'application/pdf';

    fileInput.addEventListener('change', e => {
      setReportProDialog({
        ...reportProDialog,
        data: {
          ...reportProDialog.data,
          file: e.target.files[0],
          file_name: e.target.files[0].name,
        },
      });
    });
    fileInput.click();
  }

  const orgsArray = all_reports?.filter((obj, index, self) => {
    return self.findIndex(o => o.org?.fullname === obj.org?.fullname) === index;
  });

  return (
    <div className={styles.container}>
      <div className={styles.header_container}>
        <span className={styles.header}>{t('report_pro.title')}</span>
        <span className={styles.sub_header}>{t('report_pro.description')}</span>
      </div>
      <ActionBar
        filters={filters}
        setFilters={setFilters}
        orgs={orgsArray ?? []}
        createReportPro={() => {
          setReportProDialog({
            isOpen: true,
            loading: false,
            data: initialReportInfo,
          });
        }}
        athlete={athlete}
        user={user}
      />
      {reports.loading ? (
        <div className={styles.reports_pro_loading}>
          <Spinner />
        </div>
      ) : (
        <div className={styles.reports_pro_cards_grid}>
          {(all_reports?.length ?? 0) > 0 ? (
            all_reports.map(report => (
              <ReportsProCard
                key={report.id}
                report={report}
                editReportPro={async () => {
                  setReportProDialog({
                    isOpen: true,
                    loading: true,
                    data: initialReportInfo,
                  });
                  const data = await Creators.getReportPro(athleteId, report.id);
                  setReportProDialog({
                    isOpen: true,
                    loading: false,
                    data: {
                      ...data,
                      is_edit: true,
                    },
                  });
                }}
                athlete={athlete}
                user={user}
              />
            ))
          ) : (
            <div className={styles.reports_pro_no_reports}>
              <span>{t('report_pro.no_reports')}</span>
            </div>
          )}
        </div>
      )}
      <Dialog
        isOpen={reportProDialog.isOpen}
        onClose={onClose}
        className={{
          container: styles.dialog,
          header: styles.dialogHeader,
          body: styles.dialogBody,
          footer: styles.dialogFooter,
        }}
        mainTitle={
          reportProDialog.loading || reportProDialog.data.is_edit ? t('proj.box2') : t('report_pro.add_report')
        }
      >
        {reportProDialog.loading ? (
          <div className={styles.reports_pro_loading}>
            <Spinner height="40" width="40" />
          </div>
        ) : (
          <>
            <div className={styles.body_container}>
              <div className={styles.left_container}>
                <div className={styles.images_container}>
                  <h1>{t('report_pro.image')}</h1>
                  <div className={styles.images_display}>
                    <div className={styles.reports_pro_modal_thumbnail_image_container}>
                      {reportProDialog.data.thumbnail === null ? (
                        <div className={styles.reports_pro_modal_thumbnail_image_replacement} />
                      ) : (
                        <img
                          className={styles.reports_pro_modal_thumbnail_image}
                          alt="thumbnail"
                          src={reportProDialog.data.thumbnail64 ?? reportProDialog.data.thumbnail}
                        />
                      )}
                    </div>
                    {reportProDialog.data.thumbnail !== null && (
                      <IconButton
                        className={styles.reports_pro_modal_thumbnail_delete_image_button}
                        icon={<HiTrash />}
                        onClick={() => {
                          handleImageDelete();
                        }}
                      />
                    )}
                  </div>
                  {reportProDialog.data.thumbnail == null && (
                    <Button
                      className={styles.reports_pro_modal_thumbnail_add_image_button}
                      onClick={() => {
                        handleThumbUpdate();
                      }}
                    >
                      {t('myClub.general.addImage')}
                    </Button>
                  )}
                  {reportProDialog.data.is_edit && reportProDialog.data.thumbnail !== null && (
                    <Button
                      className={styles.reports_pro_modal_thumbnail_update_image_button}
                      onClick={() => {
                        handleThumbUpdate();
                      }}
                    >
                      {t('report_pro.edit')}
                    </Button>
                  )}
                </div>
              </div>
              <div className={styles.right_container}>
                <h1 className={styles.right_title}>{t('report_pro.dialog_h1')}</h1>
                <div className={styles.reports_pro_modal_title}>
                  <span>{t('report_pro.dialog_title')}</span>
                  <Input
                    value={reportProDialog.data.title}
                    onChange={e =>
                      setReportProDialog({
                        ...reportProDialog,
                        data: {
                          ...reportProDialog.data,
                          title: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className={styles.reports_pro_modal_file_type}>
                  <span>{t('report_pro.dialog_type')}</span>
                  <Checkbox
                    label={t('report_pro.dialog_type_pdf')}
                    checked={reportProDialog.data.file_type === 'pdf'}
                    onChange={() => {
                      setReportProDialog({
                        ...reportProDialog,
                        data: {
                          ...reportProDialog.data,
                          file_type: 'pdf',
                        },
                      });
                    }}
                  />
                  <Checkbox
                    label={t('report_pro.dialog_type_video')}
                    checked={reportProDialog.data.file_type === 'video_link'}
                    onChange={() => {
                      setReportProDialog({
                        ...reportProDialog,
                        data: {
                          ...reportProDialog.data,
                          file_type: 'video_link',
                        },
                      });
                    }}
                  />
                </div>
                <div className={styles.reports_pro_modal_file}>
                  <span>{reportProDialog.data.file_type === 'pdf' ? t('report_pro.file') : t('report_pro.video')}</span>
                  {reportProDialog.data.file_type === 'pdf' ? (
                    <div className={styles.reports_pro_modal_file_inner_container}>
                      {reportProDialog.data.file ? (
                        <>
                          <span>{reportProDialog.data.file_name}</span>
                          <Button
                            className={styles.reports_pro_modal_edit_file_button}
                            onClick={() => handleFileUpload()}
                          >
                            {t('buttons.edit')}
                          </Button>
                        </>
                      ) : (
                        <Button className={styles.reports_pro_modal_add_file_button} onClick={() => handleFileUpload()}>
                          {t('report_pro.select_file')}
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div className={styles.reports_pro_modal_video_link_inner_container}>
                      <Input
                        value={reportProDialog.data.video_link}
                        onChange={e =>
                          setReportProDialog({
                            ...reportProDialog,
                            data: {
                              ...reportProDialog.data,
                              video_link: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  )}
                </div>
                {isCustomAthlete ? (
                  <div></div>
                ) : (
                  <div className={styles.reports_pro_modal_view}>
                    <span>{t('report_pro.when_see')}</span>
                    <Checkbox
                      label={t('home.text5')}
                      checked={reportProDialog.data.is_public}
                      onChange={() =>
                        setReportProDialog({
                          ...reportProDialog,
                          data: {
                            ...reportProDialog.data,
                            is_public: true,
                          },
                        })
                      }
                    />
                    <Checkbox
                      label={t('report_pro.when_see_agency')}
                      checked={!reportProDialog.data.is_public}
                      onChange={() =>
                        setReportProDialog({
                          ...reportProDialog,
                          data: {
                            ...reportProDialog.data,
                            is_public: false,
                          },
                        })
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.dialog_footer}>
              {reportProDialog.data.is_edit && (
                <Button
                  className={styles.dialog_footer_button_delete}
                  onClick={() => {
                    setDeleteReportId(reportProDialog.data.id);
                  }}
                >
                  {t('buttons.excluir')}
                </Button>
              )}
              <Button className={styles.dialog_footer_button_cancel} onClick={onClose}>
                {t('buttons.cancel')}
              </Button>
              <Button
                className={styles.dialog_footer_button_save}
                disabled={saving}
                style={{ opacity: saving ? 0.5 : 1 }}
                onClick={async () => {
                  setSaving(true);
                  try {
                    const formData = new FormData();
                    formData.append('title', reportProDialog.data.title);
                    formData.append('file_type', reportProDialog.data.file_type);
                    formData.append('is_public', reportProDialog.data.is_public);
                    if (reportProDialog.data.file_type === 'pdf') {
                      const file = reportProDialog.data.file;
                      if (!(typeof file === 'string' || file instanceof String)) {
                        formData.append('file', file);
                        formData.append('file_name', reportProDialog.data.file_name);
                      }
                    } else {
                      formData.append('video_link', reportProDialog.data.video_link);
                    }
                    const thumbnail = reportProDialog.data.thumbnail;
                    const removeThumbnail = thumbnail == null;
                    if (removeThumbnail) {
                      formData.append('removeThumbnail', 'true');
                    } else if (!(typeof thumbnail === 'string' || thumbnail instanceof String)) {
                      formData.append('thumbnail', thumbnail);
                    }
                    const config = {
                      headers: {
                        'content-type': 'multipart/form-data',
                      },
                    };
                    try {
                      if (reportProDialog.data.is_edit) {
                        await axios.patch(
                          `athletes/${athleteId}/reports-pro/${reportProDialog.data.id}`,
                          formData,
                          config
                        );
                      } else {
                        await axios.post(`athletes/${athleteId}/reports-pro`, formData, config);
                      }
                      window.location.reload();
                    } catch (e) {
                      if (e.response?.status >= 300) {
                        dispatch(
                          layoutActions.showToast({
                            content: t('erros.text8'),
                            duration: 3000,
                          })
                        );
                      }
                    }
                  } finally {
                    setSaving(false);
                  }
                }}
              >
                {t('buttons.save')}
              </Button>
            </div>
          </>
        )}
      </Dialog>
      <Dialog
        isOpen={!reportProDialog.loading && deleteReportId != null}
        onClose={() => {
          setDeleteReportId(null);
        }}
        className={{
          container: styles.dialog_delete,
          body: styles.dialog_delete_body,
        }}
        mainTitle={t('report_pro.delete_report')}
      >
        {t('report_pro.confirm_delete_report')}
        <div className={styles.dialog_delete_footer}>
          <Button color="green" noShadow rounded onClick={onClose}>
            {t('buttons.cancel')}
          </Button>
          <Button
            color="danger"
            rounded
            noShadow
            onClick={async () => {
              setReportProDialog({
                isOpen: true,
                loading: true,
                data: initialReportInfo,
              });
              try {
                await axios.delete(`/athletes/${athleteId}/reports-pro/${deleteReportId}`);
                window.location.reload();
              } catch (e) {
                setDeleteReportId(null);
                setReportProDialog({
                  isOpen: false,
                  loading: false,
                  data: initialReportInfo,
                });
                dispatch(
                  layoutActions.showToast({
                    content: t('erros.text8'),
                    duration: 3000,
                  })
                );
              }
            }}
          >
            {t('buttons.excluir')}
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
