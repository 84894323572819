import React from 'react';
import PropTypes from 'prop-types';

import styles from './SocialButton.module.scss';

import IconButton from '../IconButon/IconButton';
import {
  FaDesktop,
  FaWikipediaW,
  FaInstagram,
  FaYoutube,
  FaFacebook,
  FaLinkedin,
  FaTiktok,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

export default function SocialButton({ href, type, large }) {
  let icon = null;
  switch (type) {
    case 'website':
      icon = <FaDesktop />;
      break;
    case 'wikipedia':
      icon = <FaWikipediaW />;
      break;
    case 'instagram':
      icon = <FaInstagram />;
      break;
    case 'twitter':
      icon = <FaXTwitter style={{ color: '#000000' }} />;
      break;
    case 'youtube':
      icon = <FaYoutube style={{ color: '#e62117' }} />;
      break;
    case 'facebook':
      icon = <FaFacebook style={{ color: '#1873EB' }} />;
      break;
    case 'linkedin':
      icon = <FaLinkedin style={{ color: '#0077B5' }} />;
      break;
    case 'tiktok':
      icon = <FaTiktok />;
      break;
    default:
      console.error(`${type} isn't a valid type`);
  }

  const iconButtonClasses = [styles.socialButton];
  if (large) iconButtonClasses.push(styles.large);

  return (
    <a draggable="false" href={href} target="_blank" rel="noopener noreferrer">
      <IconButton className={iconButtonClasses.join(' ')} icon={icon} />
    </a>
  );
}

SocialButton.defaultProps = {
  href: '#',
  large: false,
};

SocialButton.propTypes = {
  href: PropTypes.string,
  type: PropTypes.oneOf(['website', 'wikipedia', 'instagram', 'twitter', 'youtube', 'facebook', 'linkedin']).isRequired,
  large: PropTypes.bool,
};
