import Endpoints from 'endpoints';
import axios from 'api';
// import { parseError } from './base';
// import { stringifyFilters } from 'shared/utility';
import ErrorMessages from 'shared/ErrorMessages';

export const actionTypes = {
  GET_TEAMS: 'myClubNegotiations/teams/GET',
  GET_TEAMS_SUCCESS: 'myClubNegotiations/teams/GET_SUCCESS',
  GET_TEAMS_FAIL: 'myClubNegotiations/teams/GET_FAIL',
};

const initialState = {
  teams: {
    loading: false,
    error: null,
    data: [],
    hasNext: true,
    hasPrevious: false,
    count: 0,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // teams
    case actionTypes.GET_TEAMS:
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: true,
        },
      };
    case actionTypes.GET_TEAMS_SUCCESS:
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: false,
          data: action.payload.data,
          hasNext: action.payload.hasNext,
          hasPrevious: action.payload.hasPrevious,
          count: action.payload.count,
        },
      };
    case actionTypes.GET_TEAMS_FAIL:
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
}

// export const myClubNegotiations = {
//   baseUrl: Endpoints.teams,

//   list: async function(teamId) {
//     try {

//       const { data } = await axios.get(`${this.baseUrl(teamId)}`);

//       return data;
//     } catch (err) {
//       return parseError(err);
//     }
//   },
// };

export const getNegotiations = id => async dispatch => {
  dispatch({ type: actionTypes.GET_TEAMS });
  const url = Endpoints.teams;

  try {
    // const queryString = stringifyFilters(filters);
    const { data } = await axios.get(`${url}${id}`);
    dispatch({
      type: actionTypes.GET_TEAMS_SUCCESS,
      payload: {
        data: data.results ?? data,
        hasNext: data.next ? true : false,
        hasPrevious: data.previous ? true : false,
        count: data.count,
      },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_TEAMS_FAIL,
        payload: { error: 'erros.text39' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_TEAMS_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else {
      dispatch({
        type: actionTypes.GET_TEAMS_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};
