export default class ErrorMessages {
  static serviceUnavailable = 'Serviço indisponível. Tente novamente mais tarde.';

  static defaultCredentialsError = 'Não pudemos validar suas credenciais. Tente novamente.';

  static excededPlanLimit = 'exceded_plan_limit';

  static resourceNotFound(resource) {
    return `${resource} não encontrado.`;
  }
}
