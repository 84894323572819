/*eslint-disable eqeqeq*/
import React, { useEffect } from 'react';
import styles from './StepThree.module.scss';
import Switch from 'react-switch';
import { FaSearch } from 'react-icons/fa';
import { Table, Input, Checkbox, Menu, Spinner } from 'components/template';
import TeamRow from 'components/TeamRow/TeamRow';
import { useTranslation } from 'react-i18next';
import { getTransferMarketTeams } from 'store/ducks/transferMarketTeams';
import { getCountries } from 'store/ducks/countries';
import { useDispatch, useSelector } from 'react-redux';
import { checkboxesAdvancedSearch } from '../../../../../shared/mocks';

export default function StepThree({ adsData, setAdsData, stepThreeConfig, setStepThreeConfig }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { countries } = useSelector(state => state.countries);
  const { teams } = useSelector(state => state.transferMarketTeams);

  const divisions = {
    1: t('home.text6'),
    2: t('home.text7'),
    3: t('home.text8'),
    4: t('home.text9'),
  };

  useEffect(() => {
    dispatch(getTransferMarketTeams(stepThreeConfig.filters));
  }, [dispatch, stepThreeConfig.filters]);

  useEffect(() => {
    if (stepThreeConfig.allTeamsChecked) {
      updateTeamsAllTeamsChecked();
    }
    // eslint-disable-next-line
  }, [teams.data]);

  useEffect(() => {
    dispatch(getCountries());
    // eslint-disable-next-line
  }, [dispatch]);

  const updateTeamsAllTeamsChecked = () => {
    setAdsData({
      ...adsData,
      teams: teams.data.map(team => team.id),
    });
  };

  const switchButtonAll = (
    <div className={styles.stepThreeSwitchContainer}>
      <Switch
        checked={stepThreeConfig.allTeamsChecked}
        onChange={checked => {
          setStepThreeConfig({
            ...stepThreeConfig,
            allTeamsChecked: checked,
          });
          if (checked) {
            updateTeamsAllTeamsChecked();
          } else {
            setAdsData({
              ...adsData,
              teams: [],
            });
          }
        }}
        className={styles.stepThreeSwitch}
        checkedIcon={false}
        uncheckedIcon={false}
        onColor="#5A00FF"
        offColor="#ffffff"
        offHandleColor="#5A00FF"
        onHandleColor="#ffffff"
      />
    </div>
  );

  const tableData = teams.data?.map(row => {
    const country = <img className={styles.stepThreeCountryFlag} src={row.country?.flag} alt="" />;
    const division = divisions[row.division];
    const club = <TeamRow img={row.emblem} name={row.nickname} />;
    const button = (
      <div className={styles.stepThreeSwitchContainer}>
        <Switch
          checked={adsData.teams?.includes(row.id)}
          onChange={checked => {
            if (!checked) {
              const filteredTeams = adsData.teams?.filter(item => {
                return item != row.id;
              });
              setAdsData({
                ...adsData,
                teams: filteredTeams,
              });
            } else {
              setAdsData({
                ...adsData,
                teams: adsData.teams.includes(row.id)
                  ? adsData.teams.filter(item => {
                      return !adsData.teams.includes(item.id);
                    })
                  : [...adsData.teams, row.id],
              });
            }
          }}
          className={styles.stepThreeSwitch}
          checkedIcon={false}
          uncheckedIcon={false}
          onColor="#5A00FF"
          offColor="#ffffff"
          offHandleColor="#5A00FF"
          onHandleColor="#ffffff"
        />
      </div>
    );

    return [country, division, club, '', button];
  });

  return (
    <div className={styles.stepThreeBody}>
      <div className={styles.stepThreeText}>
        <span>{t('transferMarket.step3title')}</span>
      </div>
      <div className={styles.stepThreeAuction}>
        <div className={styles.stepThreeAuctionSpan}>
          <span>{t('transferMarket.adAuciton')}</span>
        </div>
        <div className={styles.stepThreeAuctionSelect}>
          <Checkbox
            className={styles.stepThreeCheckbox}
            label={t('transferMarket.showAll')}
            checked={stepThreeConfig.checkedShowAll}
            onChange={() => {
              setAdsData({
                ...adsData,
                teams: [],
              });
              setStepThreeConfig({
                ...stepThreeConfig,
                checkedShowAll: true,
              });
            }}
          />
          <Checkbox
            className={styles.stepThreeCheckbox}
            label={t('transferMarket.selectShow')}
            checked={!stepThreeConfig.checkedShowAll}
            onChange={() => {
              setStepThreeConfig({
                ...stepThreeConfig,
                checkedShowAll: false,
              });
            }}
          />
        </div>
      </div>
      {!stepThreeConfig.checkedShowAll && (
        <>
          <div className={styles.stepThreeFilters}>
            <div className={styles.stepThreeInputContainer}>
              <Input
                className={styles.stepThreeInput}
                placeholder={t('navDrawer.name')}
                icon={<FaSearch />}
                iconPosition="right"
                onChange={e => {
                  setStepThreeConfig({
                    ...stepThreeConfig,
                    filters: {
                      ...stepThreeConfig.filters,
                      ids: '',
                      q: e.target.value,
                    },
                  });
                }}
                value={stepThreeConfig.filters.q}
              />
            </div>
            <Menu
              className={styles.stepThreeCountryMenu}
              rounded
              title={t('compe.text1')}
              placeholder={t('compe.text1')}
              options={countries?.data?.map(country => ({ label: country.name, value: country.code }))}
              setValue={value => {
                setStepThreeConfig({
                  ...stepThreeConfig,
                  filters: {
                    ...stepThreeConfig.filters,
                    ids: '',
                    country: value,
                  },
                });
              }}
              onClear={() =>
                setStepThreeConfig({
                  ...stepThreeConfig,
                  filters: {},
                })
              }
              value={stepThreeConfig.filters.country}
            />
            <Menu
              className={styles.stepThreeLeagueMenu}
              rounded
              title={t('organi.text12')}
              placeholder={t('organi.text12')}
              options={checkboxesAdvancedSearch.division.map(item => ({ label: item.label, value: item.value }))}
              setValue={value => {
                setStepThreeConfig({
                  ...stepThreeConfig,
                  filters: {
                    ...stepThreeConfig.filters,
                    ids: '',
                    division: value,
                  },
                });
              }}
              onClear={() =>
                setStepThreeConfig({
                  ...stepThreeConfig,
                  filters: {},
                })
              }
              value={stepThreeConfig.filters.division}
            />
            {adsData.teams?.length > 0 && (
              <div
                className={styles.stepThreeSelectedClubs}
                onClick={() => {
                  const currentIds = stepThreeConfig.filters.ids ?? '';
                  let ids = '';
                  if (currentIds === '') {
                    ids = adsData.teams.join(',');
                  }
                  setStepThreeConfig({
                    ...stepThreeConfig,
                    checkedShowAll: false,
                    filters: {
                      ...stepThreeConfig.filters,
                      q: '',
                      country: '',
                      division: '',
                      ids,
                    },
                  });
                }}
              >
                <span>{`${adsData.teams?.length} ${t('rest.box27')}`}</span>
              </div>
            )}
          </div>
          <div className={styles.stepThreeTableContainer}>
            {teams.loading ? (
              <Spinner height="40" width="40" className={styles.spinner} />
            ) : (
              <Table
                theads={[t('compe.text1'), t('organi.text12'), t('athlete_list.box2'), '', switchButtonAll]}
                className={styles.stepThreeTable}
                data={tableData}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
