import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

const BarChartByYear = (data) => {
    const { t } = useTranslation();
    const [options, setOptions] = useState({
        chart: {
        type: 'bar',
        toolbar: {
            show: false, // Oculta as opções do menu
        },
        },
        plotOptions: {
        bar: {
            horizontal: false, // Mantém as barras verticais
            columnWidth: '60%', // Largura da barra
            endingShape: 'rounded', // Deixa o topo das barras arredondado
        },
        },
        dataLabels: {
        enabled: false, // Ativa as labels nas barras
        },
        xaxis: {
        categories: [],
        title: {
            style: {
            fontSize: '12px',
            fontWeight: '400',
            color: '#4a4a4a',
            },
        },
        axisBorder: {
            color: '#d8d8d8',
        },
        axisTicks: {
            show: false
        },
        },
        yaxis: {
        title: {
            style: {
                fontSize: '12px',
                fontWeight: '400',
                color: '#4a4a4a',
            },        
        },
        axisBorder: {
            show: true,
            color: '#d8d8d8',
        },
        labels: {
            formatter: (val) => `${val}`,
            style: {
            fontSize: '12px',
            colors: ['#666'],
            },
        },
        },
        grid: {
        show: false
        },
        tooltip: {
        y: {
            formatter: (val) => `${val} jogadores`, // Formato do tooltip
        },
        },
        colors: ['#5a00ff'], // Cor das barras
        legend: {
        show: false, // Oculta a legenda, pois só há uma série
        },
    });

    const [series, setSeries] = useState([
    {
      data: [],
    },
  ]);

  useEffect(() => {
    if(data?.data){
      const dataWithoutConunter = Object.entries(data.data).filter(([key, _]) => key !== 'total_count_recommendation');
      const categories  = dataWithoutConunter.map(([key, _]) => key === 'Não informado' ? t('club.text9') : '\''+key.toString().slice(2,4));
      setOptions({...options, xaxis: {...options.xaxis, categories}});
      const series = dataWithoutConunter.map(([_, value]) => value);
      setSeries([{data: series}]);
    }
  }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Chart options={options} series={series} type="bar" height={400} />
    </div>
  );
};

export default BarChartByYear;
