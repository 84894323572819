/*eslint-disable eqeqeq*/
import React from 'react';
import Note from './Note';
import BadgeDate from 'components/BadgeDate/BadgeDate';
// import { Container } from './styles';

const NoteList = ({ notes, reverseBadge, checkAllNotes, noteListChecked }) => {
  /*
        O reverseBadge deve ser utilizado quando a listagem dos notes tbm estiver invertido, 
        dessa forma o comportamento ficará assim...

        reverseBadge true:    
            15 de Fevereiro de 2022
                mensagem 1
                mensagem 2
            16 de Fevereiro de 2022
                mensagem 3
                mensagem 4
                
        reverseBadge false:    
            16 de Fevereiro de 2022
                mensagem 4
                mensagem 3
            15 de Fevereiro de 2022
                mensagem 2
                mensagem 1

        lembrando que os próprios notes já vem do backend ordenado do recente para o mais antigo,
        por isso que a ordem padrão (reserve false) seria mensagem 4, mensagem 3, ... 2 e ... 1.
    */
  let date = reverseBadge ? notes[0]?.date : '';
  let noteList = notes.map((note, i) => {
    let content = (
      <Note
        note={note}
        provisory={note.provisory}
        key={i}
        checkAllNotes={checkAllNotes}
        onCheckClick={
          noteListChecked
            ? (note, checked) => {
                if (checked) {
                  noteListChecked.push(note);
                } else {
                  const index = noteListChecked.indexOf(note);
                  if (index > -1) {
                    noteListChecked.splice(index, 1);
                  }
                }
              }
            : undefined
        }
      />
    );
    if (note.date != date) {
      content = (
        <>
          {badge(reverseBadge ? date : note.date)}
          {content}
        </>
      );
      date = note.date;
    }
    return content;
  });
  if (noteList.length > 0 && reverseBadge) {
    noteList.push(badge(date));
  }
  return noteList;
};

function badge(date) {
  return <BadgeDate fontSize={'small'} date={date ?? new Date(Date.now())} />;
}

export default NoteList;
