import React, { useState } from 'react';
import styles from './ChatItem.module.scss';
import { Avatar } from 'components/template';
import { useTranslation } from 'react-i18next';
import WithoutClub from 'assets/ico/without-club.svg';
import RightToLeft from 'assets/ico/ico-right_to_left.svg';
import LeftToRight from 'assets/ico/ico-left_to_right.svg';
import moment from 'moment';
//import "../../translations/i18n"

export default function ChatItem({
  id,
  user,
  chat,
  date,
  alertCount,
  isSelected = false,
  onClick = () => { },
  ...rest
}) {
  const { t } = useTranslation();
  const athlete = chat.athlete;

  const [isAlertVisible, setAlertVisibility] = useState(true);

  const containerClasses = [styles.athleteContainer];
  if (isSelected) containerClasses.push(styles.athleteSelected);

  let transferTypeComponent;
  if (user?.organization?.teamId === chat.buyer_club?.id || chat.ceding_club == null)
    transferTypeComponent = (
      <div>
        {chat.buyer_club && (
          <Avatar img={chat.buyer_club.emblem} name={chat.buyer_club.nickname} className={styles.clubFrom} noBorder />
        )}
        <img draggable="false" className={styles['buy']} src={LeftToRight} alt="buy" />
        {chat.ceding_club ? (
          <Avatar img={chat.ceding_club.emblem} name={chat.ceding_club.nickname} className={styles.clubTo} noBorder />
        ) : (
          <img draggable="false" src={WithoutClub} className={styles.clubTo} alt="ceding agency" />
        )}
      </div>
    );
  if (user?.organization?.teamId === chat.ceding_club?.id && chat.ceding_club != null)
    transferTypeComponent = (
      <div>
        {chat.ceding_club ? (
          <Avatar img={chat.ceding_club.emblem} name={chat.ceding_club.nickname} className={styles.clubFrom} noBorder />
        ) : (
          <img draggable="false" src={WithoutClub} alt="ceding agency" className={styles.clubFrom} />
        )}
        <img draggable="false" className={styles['sell']} src={RightToLeft} alt="sell" />
        {chat.buyer_club && (
          <Avatar img={chat.buyer_club.emblem} name={chat.buyer_club.nickname} className={styles.clubTo} noBorder />
        )}
      </div>
    );

  return (
    <div className={containerClasses.join(' ')} onClick={() => setAlertVisibility(false)} {...rest}>
      <div className={styles.link} onClick={() => onClick(id)}>
        <div className={styles.left}>
          <div className={styles.clubContainer}>
            <div className={styles.athleteImg}>
              <Avatar img={athlete.photo} name={athlete.fullname} noBorder />
            </div>
            {transferTypeComponent}
          </div>
        </div>
        <div className={styles.center}>
          <span className={styles.name}>{athlete.nickname}</span>
          <span className={styles.completeName}>{athlete.fullname}</span>
          <div className={styles.info}>
            {athlete.nationalities?.length && (
              <img
                src={athlete.nationalities?.[0]?.flag}
                alt={athlete.nationalities?.[0]?.name ?? ''}
                draggable="false"
              />
            )}
            <span className={styles.age}>
              {athlete.year} {athlete.age} {t('athlete_card.idade')}
            </span>
            <span className={styles.position}>{t(`positionOptionsRaw.${athlete?.position}`)}</span>
          </div>
        </div>
        <div className={styles.right}>
          <span className={styles.date}>{moment(date ?? '2000-01-01T15:05:18.347034Z').format('DD/MM/YYYY')}</span>
          {!!alertCount && isAlertVisible && (
            <div className={styles.alert}>
              <span>{alertCount}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
