import React from 'react';
import PropTypes from 'prop-types';
import ReportEdition from './ReportEdition/ReportEdition.js';
import styles from './Main.module.scss';
import ShadowTeamReport from '../../ShadowTeamReport/index.js';

export default function Main({
  data,
  orderedAthletesId,
  configuration,
  setConfiguration,
  setAthletesInfoData,
  athletesInfoData,
  reportData,
}) {
  const reportContent = [];

  if (reportData.shadow_team && (reportData.shadow_team_athletes ?? []).length > 0) {
    reportContent.push(<ShadowTeamReport reportData={reportData} key={0} />);
  }

  if (orderedAthletesId) {
    orderedAthletesId.forEach(orderedAthleteId => {
      const item = data.find(athlete => athlete.id === orderedAthleteId);
      if (item) {
        reportContent.push(
          <div className={styles.content} key={item.id}>
            <ReportEdition
              data={data.find(athlete => athlete.id === item.id)}
              configuration={configuration}
              setConfiguration={setConfiguration}
              setAthletesInfoData={setAthletesInfoData}
              athletesInfoData={athletesInfoData}
              athleteInfoData={athletesInfoData.find(athlete => athlete.id === parseInt(item.id))}
            />
          </div>
        );
      }
    });
  }

  return <div className={styles.container}>{reportContent}</div>;
}

Main.propTypes = {
  data: PropTypes.array.isRequired,
  orderedAthletesId: PropTypes.array.isRequired,
};
