/*eslint-disable eqeqeq*/
import React from 'react'
import styles from './index.module.scss'
import moment from 'moment'
import { useTranslation } from "react-i18next";

import { Avatar } from 'components/template'

export default function Negotiations({ data }) {
    const { t } = useTranslation();
    const item = data.data

    const card = (<div className={styles.card} key={data.id}>
        <div className={styles.left}>
            <Avatar noBorder img={data.owner?.profile?.photo} name={data.owner?.name} />
        </div>
        <div className={styles.right}>
            <div className={styles.texto}>
                <span className={styles.monitor}>{data.owner?.name}</span> {item?.sender?.occupation ?? 'Usuário'} {t("organi.text29")} <span className={styles.monitor}>{item?.sender?.team?.name ?? 'N/D'}</span> {t("organi.text30")} <span className={styles.monitor}>{item?.negotiations?.athlete?.fullname}</span>
            </div>
            <div className={styles.date}>
                <span>{data.created_at ? t("organi.text20") + moment(data.created_at).format('DD/MM/YYYY') + t("organi.text21") + moment(data.created_at).format('HH:mm') : " - "}</span>
            </div>
        </div>
    </div>)

    return (
        <div className={styles.container}>
            {card}
        </div>
    );
}