import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMount } from 'shared/hooks';
import { Creators as userCreators } from 'store/ducks/user';

import Login from 'pages/Login/Login';
import ResetPassword from 'pages/ResetPassword/ResetPassword';
import PrimeiroAcesso from 'pages/PrimeiroAcesso/PrimeiroAcesso';
import styles from './App.module.scss';
import { Helmet } from 'react-helmet';
import AthleteReports from 'pages/Athlete/Reports/Reports';
import ReportLayout from './ReportLayout/ReportLayout';

import Routes from './Routes';

//firebase
import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import ReportExport from 'pages/Athlete/Reports/ReportExport';

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

let _firebaseIsLogged = false;
export const firebase = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebase);
export const signInFirebase = async () => {
  try {
    const auth = getAuth(firebase);
    const userCredential = await signInWithEmailAndPassword(auth, process.env.REACT_APP_FIREBASE_EMAIL, process.env.REACT_APP_FIREBASE_PASS);
    _firebaseIsLogged = true;
    return userCredential.user;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode, errorMessage);
    return null;
  }
};
export const firebaseIsLogged = () => _firebaseIsLogged;

function App() {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [mounting, setMounting] = useState(true);

  useMount(() => {
    const udata = JSON.parse(localStorage.getItem('footlink_udata'));
    if (udata && udata.data.plan) dispatch(userCreators.setSession(udata.accessToken, udata.refreshToken, udata.data));
    setMounting(false);
  });

  const content = (
    <Switch>
      <Route path="/primeiro-acesso/:token" exact render={() => <PrimeiroAcesso />} />
      <Route key="/relatorio-exportar/:token" path="/relatorio-exportar/:token" exact render={() => <ReportExport />} />,
      <Route
        key="/relatorios/:id"
        path="/relatorios/:id"
        render={() => (
          <ReportLayout>
            <AthleteReports />
          </ReportLayout>
        )}
      />,
      {user.accessToken ? (
        // usuário autenticado
        <Routes />
      ) : (
        // usuário não autenticado
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/recuperacao-senha/:uid/:token" exact component={ResetPassword} />
          <Redirect to="/login" />
        </Switch>
      )}
    </Switch>
  );

  return (
    <>
      {process.env.REACT_APP_ENABLE_TAG_MANAGER === 'true' && (
        <Helmet>
          <script src="/tagmanager.js" type="text/javascript" />
        </Helmet>
      )}
      <main className={styles.appContainer}>{!mounting && content}</main>
    </>
  );
}

export default App;
