import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCountries } from 'store/ducks/countries';

import styles from './FootlinkClubs.module.scss';

import ActionBar from './ActionBar/ActionBar';
import { brazilianStates } from 'shared/mocks';
import TopBar from '../NationalClubs/TopBar/TopBar';
import ClubsList from '../NationalClubs/ClubsList/ClubsList';
import { useTranslation } from "react-i18next";

function FootlinkClubs() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchName, setSearchName] = useState('');
  const [filterMenu, setFilterMenu] = useState({
    currentState: [],
    has_footlink_contract: true,
  });

  const countries = useSelector(state => state.countries.countries);
  const countriesData = (countries?.data ?? []).filter(c => c.code !== 'BR');
  useEffect(() => {
    /* Countries */
    dispatch(getCountries({ limit: ['teams'] }));
  }, [dispatch]);

  let content = null;
  content = (
    <>
      <TopBar />      
      <div className={styles.content}>
        <div>
          <h1 className={styles.title}>{t("home.footlinkClubs")}</h1>
          <span className={styles.subTitle}>{t("trello.box6")}</span>          
        </div>
        <div className={styles.actionBar}>          
          <div className={styles.actionBar}>
            <ActionBar
              stateOptions={brazilianStates}
              filterMenu={filterMenu}
              setFilterMenu={setFilterMenu}
              nameInputChangeHandler={setSearchName}
              nameInputValue={searchName}
              countries={countriesData}
            />
          </div>
        </div>
        <ClubsList filterMenu={filterMenu} searchName={searchName} footlink={true} />
      </div>
    </>
  );

  return <div className={styles.container}>{content}</div>;
}

export default withRouter(FootlinkClubs);
