import Endpoints from 'endpoints';
import axios from 'api';
import { stringifyFilters } from 'shared/utility';

export const actionTypes = {
  GET: 'dashboardMarketAnalysis/GET',
  GET_SUCCESS: 'dashboardMarketAnalysis/GET_SUCCESS',
  GET_ERROR: 'dashboardMarketAnalysis/GET_ERROR',
};

const initialState = {
  loading: false,
  error: null,
  data: [],
};

export default function reducer(state = initialState, action) {    
  switch (action.type) {
    case actionTypes.GET:
      return {
        ...initialState,
        loading: true,
      };
    case actionTypes.GET_SUCCESS:
      return {
        ...initialState,
        loading: false,
        data: action.payload.data,
      };
    case actionTypes.GET_ERROR:
      return {
        ...initialState,
        error: action.payload.error,
        loading: false,
      };
    default:
      return state;
  }
}

function mapPayloadToMarketAnalysis(payload) {
    const athleteMonitoring = {
        total: payload.total_count_athletes_monitored,
        tabs: [],
        bars: []
    };

    if (Object.keys(payload.total_count_athletes_monitored_per_org).length > 0) {
        athleteMonitoring.tabs.push('total_count_athletes_monitored_per_org');
        Object.entries(payload.total_count_athletes_monitored_per_org).forEach(([label, value]) => {
            athleteMonitoring.bars.push({
                tab: athleteMonitoring.tabs.length - 1,
                label,
                value
            });
        });
    }

    if (Object.keys(payload.total_count_evaluations_per_org).length > 0) {
        athleteMonitoring.tabs.push('total_count_evaluations_per_org');
        Object.entries(payload.total_count_evaluations_per_org).forEach(([label, value]) => {
            athleteMonitoring.bars.push({
                tab: athleteMonitoring.tabs.length - 1,
                label,
                value
            });
        });
    }

    if (Object.keys(payload.total_count_reports_per_org).length > 0) {
        athleteMonitoring.tabs.push('total_count_reports_per_org');
        Object.entries(payload.total_count_reports_per_org).forEach(([label, value]) => {
            athleteMonitoring.bars.push({
                tab: athleteMonitoring.tabs.length - 1,
                label,
                value
            });
        });
    }

    return athleteMonitoring;
}

let axisosGetDashboardController;
export const getDashboardMarketAnalysis = filters => async dispatch => {
  dispatch({ type: actionTypes.GET });
  const url = Endpoints.dashboardMarketAnalysis;

  try { 
    const queryString = stringifyFilters(filters);
    
    if (axisosGetDashboardController) {
        axisosGetDashboardController.abort();
    }
    axisosGetDashboardController = new AbortController();
    const { data } = await axios.get(`${url}`+queryString, { signal: axisosGetDashboardController.signal });
    axisosGetDashboardController = null;

    const transferMarket = mapPayloadToMarketAnalysis(data);    

    dispatch({
      type: actionTypes.GET_SUCCESS,
      payload: {
        data: transferMarket,
      },
    });
  } catch (err) {
    if (err?.name === 'CanceledError') {
      //requisição cancelada
    } else {
      dispatch({
        type: actionTypes.GET_ERROR,
        payload: { error: 'erros.text20' },
      });
    }
  }
};
