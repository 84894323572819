import React from 'react';
import { withRouter } from 'react-router-dom';
import styles from './TransferWindows.module.scss';
import List from './List/List';

function TransferWindows() {
  let content = null;
  content = (
    <>
      <div className={styles.content}>
        <List />
      </div>
    </>
  );

  return <div className={styles.container}>{content}</div>;
}

export default withRouter(TransferWindows);
