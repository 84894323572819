import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from 'store/ducks/club';
import { withRouter } from 'react-router-dom';
import Helpers from 'shared/helpers';
import { Card, Spinner, Table, Checkbox } from 'components/template';
import styles from './Loaned.module.scss';
import ActionBar from '../ActionBar/ActionBar';
import { getList } from 'store/ducks/monitoringV2';
import { useTranslation } from 'react-i18next';
// import Filters from './Filters/Filters';

function Loaned({ teamId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loaned = useSelector(state => state.club.loanedPage);
  const list = useSelector(state => state.monitoringV2);

  const [checkedAthletes, setCheckedAthletes] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  const today = new Date();

  const [selectedFilters, setSelectedFilters] = useState({
    name: '',
    position: [],
    list: [],
    birthYear: {
      min: 1975,
      max: Number(today.getFullYear()),
    },
  });

  const [athletes, setAthletes] = useState([]);

  const [athletesAsRows, setAthletesAsRows] = useState([]);

  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    setAthletes(applyFilters(loaned.data.athletes));
  }, [loaned]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAthletesAsRows(
      Helpers.athleteListToTable(athletes, 'emprestados', styles, 'monitoring', handleChange, checkedAthletes, true)
    );
  }, [athletes, checkedAthletes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getList());
  }, [dispatch]);

  useEffect(() => {
    setFilteredList(
      list.data.filter(item => item.name !== '_DEFAULT_').map(item => ({ label: item.name, value: String(item.id) }))
    );
  }, [list.data]);

  useEffect(() => {
    if (allChecked) {
      setCheckedAthletes(athletes.map(athlete => ({ id: athlete.id, name: athlete.fullname })));
    } else {
      setCheckedAthletes([]);
    }
  }, [allChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAthletes(applyFilters(loaned.data.athletes));
  }, [selectedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loaned.data.athletes.length === 0) {
      dispatch(Creators.getLoaned(teamId, { o: 'position_order' }));
    } else {
      setAthletes(loaned.data.athletes);
    }
  }, [dispatch, teamId]); // eslint-disable-line react-hooks/exhaustive-deps

  function applyFilters(athletes) {
    let filteredAthletes = athletes.filter(athlete => {
      if (
        selectedFilters.name === '' ||
        athlete.name?.toLowerCase().includes(selectedFilters.name) ||
        athlete.nickname?.toLowerCase().includes(selectedFilters.name)
      ) {
        if (selectedFilters.position.length === 0 || selectedFilters.position.some(pos => athlete.position === pos)) {
          if (
            athlete.birthYear >= selectedFilters.birthYear.min &&
            athlete.birthYear <= selectedFilters.birthYear.max
          ) {
            if (
              selectedFilters.list.length === 0 ||
              athlete.lists.some(list => selectedFilters.list.some(item => Number(item) === Number(list)))
            ) {
              return true;
            }
          }
        }
      }

      return false;
    });

    return filteredAthletes;
  }

  const handleChange = athlete => {
    if (checkedAthletes.some(item => item.id === athlete.id)) {
      setCheckedAthletes(checkedAthletes.filter(item => item.id !== athlete.id));
    } else {
      setCheckedAthletes([...checkedAthletes, athlete]);
    }
  };

  return (
    <>
      <Card softShadow className={styles.loaned}>
        <ActionBar
          checkedAthletes={checkedAthletes}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          list={filteredList}
        />
        {loaned.loading ? (
          <div className={styles.loader}>
            <Spinner />
          </div>
        ) : loaned.data.athletes.length === 0 ? (
          <div className={styles.nocontent}>
            <span>{t('club.noData')}</span>
          </div>
        ) : (
          <div className={styles.tableContainer}>
            <Table
              className={styles.loanedsTable}
              flat
              theads={[
                <Checkbox dark checked={allChecked} onChange={() => setAllChecked(!allChecked)} />,
                '',
                t('athlete_list.box1'),
                t('athlete_list.box3'),
                t('athlete_list.box4'),
                t('athlete_card.nation'),
                t('athlete_list.box6'),
                <div className={styles.loanedContract}>
                  {t('text.box1')} /<br /> {t('text.box2')}
                </div>,
                '',
              ]}
              data={athletesAsRows}
            />
          </div>
        )}
      </Card>
    </>
  );
}

Loaned.propTypes = {
  teamId: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(Loaned);
