import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './Avatar.module.scss';

export default function Avatar({ img, name, professionalHeader, connectionsAvatar, noBorder, noRadius, className, classNameOnError, ...props }) {
  const [error, setError] = useState(false);
  const avatarClasses = [styles.avatar];
  if (!img || error) {
    avatarClasses.push(styles.textAvatar);
    if (classNameOnError) {
      avatarClasses.push(classNameOnError);
    }
  };
  if (noBorder) avatarClasses.push(styles.noBorder);
  if (professionalHeader) avatarClasses.push(styles.professionalHeader);
  if (connectionsAvatar) avatarClasses.push(styles.connectionsAvatar);
  if (noRadius) avatarClasses.push(styles.noRadius);
  if (className) avatarClasses.push(className);

  function getInitials() {
    let tmpName = ''
    if (name === null) return '';
    tmpName = name;
    if (typeof name == "object")
      tmpName = name.innerText ?? '';
    const initials = tmpName
      .split(' ')
      .filter((initial, i) => i < 2)
      .map(initial => initial[0]?.toUpperCase());
    return initials.join('');
  }

  return (
    <>
      {img && !error ? (
        <img
          draggable="false"
          {...props}
          src={img}
          className={avatarClasses.join(' ')}
          alt="avatar"
          onError={() => setError(true)}
        />
      ) : (
        <div draggable="false" {...props} className={avatarClasses.join(' ')}>
          <span>{getInitials(name)}</span>
        </div>
      )}
    </>
  );
}

Avatar.defaultProps = {
  img: null,
  name: '',
  noBorder: false,
  noRadius: false,
  className: '',
};

Avatar.propTypes = {
  img: PropTypes.node,
  name: PropTypes.node,
  className: PropTypes.string,
  classNameOnError: PropTypes.string,
  noBorder: PropTypes.bool,
  noRadius: PropTypes.bool
};
