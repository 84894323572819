import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from 'store/ducks/club';
import { Card, Spinner } from 'components/template';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import { useTranslation } from 'react-i18next';
import styles from './MyClub.module.scss';
import TopBar from './TopBar/TopBar';
import General from './General/General';
import Contracts from './Contracts/Contracts';
import ProfessionalCast from './ProfessionalCast/ProfessionalCast';
import ProfileCast from './ProfileCast/ProfileCast';
import Negotiations from './Negotiations/Negotiations';
import Users from './Users/Users';

function MyClub({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loggedUser = useSelector(state => state.user.data);
  const club = useSelector(state => state.club.clubPage);
  const teamId = loggedUser?.organization?.teamId;

  useEffect(() => {
    dispatch(Creators.getClub(teamId));
    const body = document.querySelector('body');
    body.classList.add('lightBg');

    return () => {
      dispatch(Creators.reset());
      body.classList.remove('lightBg');
    };
  }, [dispatch, match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const baseURL = `/meu-clube`;

  const items = [
    {
      label: t('myClub.general.title'),
      link: `${baseURL}`,
      component: <General club={club.data} teamId={teamId} />,
    },
    {
      label: t('myClub.professionalCast.pageTitle'),
      link: `${baseURL}/elenco`,
      component: <ProfessionalCast teamId={teamId} />,
    },
    {
      label: t('myClub.profileCast.title'),
      link: `${baseURL}/perfil-elenco`,
      component: <ProfileCast teamId={teamId} />,
    },
    {
      label: t('myClub.contracts.pageTitle'),
      link: `${baseURL}/contratos`,
      component: <Contracts teamId={teamId} />,
    },
    {
      label: t('myClub.negotiations.negotiations'),
      link: `${baseURL}/negociacoes`,
      component: <Negotiations teamId={teamId} />,
    },
    {
      label: t('myClub.users.title'),
      link: `${baseURL}/usuarios`,
      component: <Users teamId={teamId} />,
    },
  ];

  const routes = items.map(item => <Route key={item.label} exact path={item.link} render={() => item.component} />);

  let content = '';
  if (club.loading) {
    content = (
      <div className={styles.loaderContainer}>
        <Spinner />
      </div>
    );
  } else if (club.error) {
    content = (
      <Card className={styles.errorContainer}>
        <h2>{t(club.error)}</h2>
      </Card>
    );
  } else if (club.data.id === teamId) {
    content = (
      <>
        <TopBar club={club.data} />
        <div className={styles.content}>
          <NavigationBar items={items} />
          <Switch>
            {routes}
            {/* elenco redirect until overview is completed */} <Redirect to={items[0].link} />
            <Redirect to={baseURL} />
          </Switch>
        </div>
      </>
    );
  }

  return <div className={styles.container}>{content}</div>;
}

MyClub.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(MyClub);
