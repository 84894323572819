import styled from 'styled-components';

export const Container = styled.div`
    display:flex;
    flex-direction: column;
`;

export const FormGroup = styled.div`
    display:flex;
    flex-wrap: wrap;
    align-items: flex-end;
`;