import Endpoints from 'endpoints';
import axios from 'api';
import { stringifyFiltersWithNestedObjects } from 'shared/utility';

export const actionTypes = {
  GET: 'evaluations/GET',
  GET_SUCCESS: 'evaluations/GET_SUCCESS',
  GET_ERROR: 'evaluations/GET_ERROR',
  DELETE: 'evaluations/DELETE',
  DELETE_SUCCESS: 'evaluations/DELETE_SUCCESS',
  DELETE_ERROR: 'evaluations/DELETE_ERROR',
};

const initialState = {
  loading: false,
  error: null,
  data: [],
  hasNext: false,
  hasPrevious: false,
  count: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return {
        ...initialState,
        loading: true,
      };
    case actionTypes.GET_SUCCESS:
      return {
        ...initialState,
        loading: false,
        data: action.payload.data,
        hasNext: action.payload.hasNext,
        hasPrevious: action.payload.hasPrevious,
        count: action.payload.count,
      };
    case actionTypes.GET_ERROR:
      return {
        ...initialState,
        error: action.payload.error,
        loading: false,
      };
    case actionTypes.DELETE:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_ERROR:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

// Action Creators

export const getAthleteEvaluation = async (athleteId, id) => {
  try {
    const { data } = await axios.get(`athletes/${athleteId}/evaluations/${id}/`);
    return data;
  } catch (err) {
    return { error: 'Não foi possível buscar as avaliações no momento. Por favor, tente novamente' };
  }
};

let axiosGetAthletesController;
export const getEvaluations = filters => async dispatch => {
  dispatch({ type: actionTypes.GET });
  const url = Endpoints.evaluations;

  try {
    const queryString = stringifyFiltersWithNestedObjects(filters);

    if (axiosGetAthletesController) {
      axiosGetAthletesController.abort();
    }
    axiosGetAthletesController = new AbortController();
    const { data } = await axios.get(`${url}${queryString}`, { signal: axiosGetAthletesController.signal });
    axiosGetAthletesController = null;

    dispatch({
      type: actionTypes.GET_SUCCESS,
      payload: {
        data: data.results,
        count: data.count,
        hasPrevious: !!data.previous,
        hasNext: !!data.next,
      },
    });
  } catch (err) {
    if (err?.name === 'CanceledError') {
      //requisição cancelada
    } else {
      dispatch({
        type: actionTypes.GET_ERROR,
        payload: { error: 'erros.text20' },
      });
    }
  }
};

export const deleteEvaluations = (evaluations, filters) => async dispatch => {
  dispatch({ type: actionTypes.DELETE });
  const url = Endpoints.evaluations;

  try {
    await Promise.all(evaluations.map(evaluation => axios.delete(`${url}/${evaluation}`)));

    await dispatch({
      type: actionTypes.DELETE_SUCCESS,
    });

    dispatch(getEvaluations(filters));
  } catch (err) {
    dispatch({
      type: actionTypes.DELETE_ERROR,
      payload: { error: 'erros.text21' },
    });
  }
};
