import React from 'react';
import PropTypes from 'prop-types';

import { Link, useHistory } from 'react-router-dom';
import { Avatar, Button } from 'components/template';
import styles from './TopBar.module.scss';
import moment from 'moment';
import { BiArrowBack } from 'react-icons/bi';
import MonitoringButton from 'components/MonitoringButton/MonitoringButton';
import { useTranslation } from 'react-i18next';
import { RiAccountPinBoxFill } from 'react-icons/ri';

export default function TopBar({ athlete, border }) {
  const { t } = useTranslation();
  const history = useHistory();
  const agency = athlete?.agency;
  const team = athlete?.current_team;
  const loanTeam = athlete?.src_team;

  const currentTeamContent = (
    <div className={styles.teamContainer}>
      <div className={styles.teamContent}>
        <div>
          <h5>{t('club.box21')}</h5>
        </div>
        {team?.id != null ? (
          <Link to={`/clube/${team?.id}/elenco`} className={styles.teamDetails}>
            <Avatar
              noBorder
              img={team?.emblem ?? ''}
              className={styles.shield}
              classNameOnError={styles.withoutEmblem}
              name={team?.longname}
              alt=""
              draggable="false"
            />
            <div>
              <div className={styles.clubShortName}>{team?.nickname}</div>
              <div className={styles.clubContractDate}>
                {athlete?.contract_info?.current_contract_end
                  ? t('club.box22') + moment(athlete?.contract_info?.current_contract_end).format('DD/MM/YYYY')
                  : '-'}
              </div>
            </div>
          </Link>
        ) : (
          <div className={styles.withoutClub}>{t('transferMarket.withoutClub')}</div>
        )}
      </div>
    </div>
  );
  const loanTeamContent = (
    <div className={styles.teamContainer}>
      <div className={styles.teamContent}>
        <div>
          <h5>{t('club.box23')}</h5>
        </div>
        <Link to={`/clube/${agency?.id}/elenco`} className={styles.teamDetails}>
          {loanTeam?.emblem && (
            <Avatar
              noBorder
              img={loanTeam?.emblem ?? ''}
              className={styles.shield}
              name={loanTeam?.longname}
              alt=""
              draggable="false"
            />
          )}
          <div>
            <div className={styles.clubShortName}>{loanTeam?.nickname}</div>
            <div className={styles.clubContractDate}>
              {athlete?.contract_info?.src_contract_end
                ? t('club.box22') + moment(athlete?.contract_info?.src_contract_end).format('DD/MM/YYYY')
                : '-'}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
  const agencyContent = (
    <div className={styles.teamContainer}>
      <div className={styles.teamContent}>
        <Link to={`/agencias/${agency?.id}/atletas`} className={`${styles.teamDetails} ${styles.agencyDetails}`}>
          <Avatar
            noBorder
            img={agency?.emblem ?? ''}
            className={styles.shield}
            classNameOnError={styles.withoutEmblem}
            name={agency?.name}
            alt=""
            draggable="false"
          />
          <div>
            <h5>{t('club.box12')}</h5>
            <div className={styles.clubShortName}>{agency?.name}</div>
          </div>
        </Link>
      </div>
    </div>
  );

  const nationalities = athlete?.nationalities?.length
    ? athlete.nationalities.map(item => (
      <img src={item.flag} alt={item.name} className={styles.country} draggable="false" />
    ))
    : '';

  return (
    <div className={styles.topBar} style={{ borderBottom: border && '1px solid #BDBDBD' }}>
      <div className={styles.left}>
        <div className={styles.pageBack}>
          <Button onClick={() => history.goBack()} noShadow>
            <BiArrowBack />
          </Button>
        </div>
        <Avatar noBorder img={athlete?.photo ?? ''} name={athlete?.fullname} className={styles.avatar} />
        <div className={styles.athleteInfo}>
          <div className={styles.athleteName}>
            <h1>{athlete?.nickname ?? ''}</h1>
            <span>{athlete?.fullname}</span>
          </div>
          <div className={styles.athleteDetails}>
            {nationalities}
            <span className={styles.span}>
              {athlete?.country?.name} | {athlete?.age?.toString().slice(5, 7)} {t('athlete_card.idade')} |{' '}
              {t(`positionOptionsRaw.${athlete?.position}`)}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.teamsContainer}>{currentTeamContent}</div>
        <div className={styles.teamsContainer}>{loanTeam?.id ? loanTeamContent : ''}</div>
        <div className={styles.teamsContainer}>{agency?.id ? agencyContent : ''}</div>
        {/* <div className={styles.marketValue}>
          <div className={styles.info}>
            <h5>VALUATION FOOTURE</h5>
          </div>
          <a
            draggable="false"
            href={`https://api.whatsapp.com/send?phone=${t('whatsapp.phone')}&text=${t(
              'whatsapp.valuation1'
            )}${athlete?.fullname ?? ''}${t('whatsapp.valuation2')}${team?.nickname ?? team?.longname ?? ''}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={styles.marketValueSpan}>{t('athlete_card.buyValuation')}</div>
          </a>
        </div> */}
        <div className={styles.buttonsContainer}>
          <Link to={{ pathname: `/atletas/${athlete.id}`, state: { previews: window.location.pathname } }}>
            <Button className={styles.buttonsPerfil} color="blue" rounded>
              <RiAccountPinBoxFill className={styles.icon} />
              {t('transferMarket.completeProfile')}
            </Button>
          </Link>
          <MonitoringButton filled={true} athlete={athlete} />
        </div>
      </div>
    </div>
  );
}

TopBar.propTypes = {
  athlete: PropTypes.instanceOf(Object).isRequired,
};
