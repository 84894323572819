import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Variables from 'variables';

import styles from './IconButtonText.module.scss';

export default function IconButtonText({ icon, text, className, color, disabled, tip, ...props }) {
    const iconClasses = [styles.button];
    if (className) iconClasses.push(className);
    if (color === 'green') iconClasses.push(styles.green);
    if (color === 'purple') iconClasses.push(styles.purple);
    if (color === 'white') iconClasses.push(styles.white);
    if (color === 'lightPurple1') iconClasses.push(styles.lightPurple1);
    if (color === 'lightBlue') iconClasses.push(styles.lightBlue);
    if (color === 'orange') iconClasses.push(styles.orange);
    if (color === 'dark') iconClasses.push(styles.dark);
    if (disabled) iconClasses.push(styles.disabled);

    const tooltipColor = Variables.lightPurple1;

    return (
        <>
            <button data-tip={tip} disabled={disabled} {...props} type="button" className={iconClasses.join(' ')}>
                {color === 'lightPurple1' && <span>{text}</span>}
                {icon}
            </button>
            {tip ? (
                <ReactTooltip className={styles.tooltip} effect="solid" place="bottom" backgroundColor={tooltipColor} />
            ) : null}
        </>
    );
}

IconButtonText.defaultProps = {
    className: '',
    color: '',
};

IconButtonText.propTypes = {
    icon: PropTypes.node.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    tip: PropTypes.string,
};
