import React, { useEffect, useState } from 'react';
import styles from './FootlinkOriginals.module.scss';
import logo from './../../assets/logo/logo_originals.svg';
import thumbnail from './../../assets/images/bg-foot-originals.jpeg';
import { IoMdThumbsUp } from "react-icons/io";
import { Button } from 'components/template';
import { Link } from 'react-router-dom';
import { getOriginals, likeVideo } from 'store/ducks/originals';
import { useDispatch, useSelector } from 'react-redux';
import { VscDebugRestart } from "react-icons/vsc";

const FootlinkOriginals = () => {
  const [isActive, setIsActive] = useState(false);
  const [id, setId] = useState(null);
  const dispatch = useDispatch();
  const originals = useSelector(state => state.originals.originals);
  const videoProgress = localStorage.getItem('videoProgress');

  const handleLike = () => {
    setIsActive(!isActive);
    if(originals && id !== null){
      dispatch(likeVideo(id, isActive));
    }
  };

  useEffect(() => {
    dispatch(getOriginals());
  }, [dispatch]);

  useEffect(() => {
    console.log('ao abrir',originals)
      setIsActive(originals?.like);
      setId(originals?.id)
  }, [originals])

  const resetPlayer = () => {
    localStorage.removeItem('videoProgress');
    window.location.href = '/originals/play/'+id;
  }

  return (
    <div className={styles.videoDescriptionPage}>
      <img className={styles.backgroundImage} src={thumbnail} alt="Footlink Originals Thumbnail"/>
      <div className={styles.colorOverlay}></div>
      <div className={styles.content}>
        <img src={logo} alt="Footlink Originals" className={styles.logo} />        
        <h1>O mercado francês de futebol</h1>
        <p className={styles.details}>2024 &bull; 32 minutos &bull; Footure</p>
        <div className={styles.actions}>
          {!videoProgress && <Link className={styles.watchButton} to={"/originals/play/"+id}>
            Assistir <span className={styles.playIcon}>▶</span>
          </Link> }
          {videoProgress && <>
            <Link className={styles.watchButton} to={"/originals/play/"+id}>
              Continuar <span className={styles.playIcon}>▶</span>
            </Link>
            <Button color="green" className={styles.restartButton} onClick={resetPlayer}>
              Reiniciar <VscDebugRestart/>
            </Button>
          </>}
          <Button 
            className={`${styles.likeButton} ${isActive ? styles.active : ''}`}
            onClick={handleLike}
          >
            <IoMdThumbsUp />
          </Button>
        </div>
        <p className={styles.description}>
        No primeiro episódio da série Footlink Originals, oferecemos um olhar profundo sobre o Mercado Francês de futebol. Através de dados, infográficos e entrevistas com diretores desportivos, agentes e jornalistas, exploramos detalhadamente o perfil do jogador que está sendo formado no país, bem como as origens e destinos das transferências.
        </p>
        <p className={styles.description}>
        Footlink Originals é uma ferramenta essencial para os profissionais do futebol que desejam compreender melhor o mercado francês. Com isso, é possível entender o perfil de atleta que está sendo formado no país, o tipo de jogador que os clubes franceses buscam, aqueles que têm melhor desempenho nas ligas locais e as fontes de receitas dos clubes.
        </p>
      </div>
    </div>
  );
};

export default FootlinkOriginals;