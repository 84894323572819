import Endpoints from 'endpoints';
import axios from 'api';

export const actionTypes = {
  GET: 'dashboardTransferMarket/GET',
  GET_SUCCESS: 'dashboardTransferMarket/GET_SUCCESS',
  GET_ERROR: 'dashboardTransferMarket/GET_ERROR',
};

const initialState = {
  loading: false,
  error: null,
  data: [],
};

export default function reducer(state = initialState, action) {    
  switch (action.type) {
    case actionTypes.GET:
      return {
        ...initialState,
        loading: true,
      };
    case actionTypes.GET_SUCCESS:
      return {
        ...initialState,
        loading: false,
        data: action.payload.data,
      };
    case actionTypes.GET_ERROR:
      return {
        ...initialState,
        error: action.payload.error,
        loading: false,
      };
    default:
      return state;
  }
}

function mapPayloadToTransferMarket(payload) {
    const announcementCount = {
      total: payload.data.total_announcement,
      tabs: [],
      bars: []
    };
  
    if (
      payload.data.total_announcement_athletes_team > 0 ||
      payload.data.total_announcement_athletes_agency > 0 ||
      payload.data.total_announcement_desired_athletes > 0
    ) {
      announcementCount.tabs.push('total_announcement_by_type');
      
      if (payload.data.total_announcement_athletes_team > 0) {
        announcementCount.bars.push({
          tab: announcementCount.tabs.length - 1,
          label: 'total_announcement_athletes_team',
          value: payload.data.total_announcement_athletes_team
        });
      }
  
      if (payload.data.total_announcement_athletes_agency > 0) {
        announcementCount.bars.push({
          tab: announcementCount.tabs.length - 1,
          label: 'total_announcement_athletes_agency',
          value: payload.data.total_announcement_athletes_agency
        });
      }
  
      if (payload.data.total_announcement_desired_athletes > 0) {
        announcementCount.bars.push({
          tab: announcementCount.tabs.length - 1,
          label: 'total_announcement_desired_athletes',
          value: payload.data.total_announcement_desired_athletes
        });
      }
    }
      
    if (Object.keys(payload.data.by_advertiser_count).length > 0) {
      announcementCount.tabs.push('by_advertiser_count');
      
      Object.entries(payload.data.by_advertiser_count).forEach(([label, value]) => {
        announcementCount.bars.push({
          tab: announcementCount.tabs.length - 1,
          label,
          value
        });
      });
    }
  
    if (Object.keys(payload.data.by_negotiation_count).length > 0) {
      announcementCount.tabs.push('by_negotiation_count');
      
      Object.entries(payload.data.by_negotiation_count).forEach(([label, value]) => {
        announcementCount.bars.push({
          tab: announcementCount.tabs.length - 1,
          label,
          value
        });
      });
    }
  
    return announcementCount;
  }
  

let axisosGetDashboardController;
export const getDashboardTransferMarket = filters => async dispatch => {
  dispatch({ type: actionTypes.GET });
  const url = Endpoints.dashboardTransferMarket;

  try { 
    let queryString = '?is_active=true';
    if(filters?.is_active === false){        
        queryString = '';
    }
    
    if (axisosGetDashboardController) {
        axisosGetDashboardController.abort();
    }
    axisosGetDashboardController = new AbortController();
    const { data } = await axios.get(`${url}`+queryString, { signal: axisosGetDashboardController.signal });
    axisosGetDashboardController = null;

    const transferMarket = mapPayloadToTransferMarket(data);    

    dispatch({
      type: actionTypes.GET_SUCCESS,
      payload: {
        data: transferMarket,
      },
    });
  } catch (err) {
    if (err?.name === 'CanceledError') {
      //requisição cancelada
    } else {
      dispatch({
        type: actionTypes.GET_ERROR,
        payload: { error: 'erros.text20' },
      });
    }
  }
};
