import React from 'react';
import { Menu, Checkbox, CalendarMenu, IconButton } from 'components/template';
import styles from './ActionBar.module.scss';
import { fileTypeOptions } from 'shared/mocks';
import { BiSolidPlusCircle } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

export default function ActionBar({ filters, setFilters, createReportPro, athlete, user, orgs }) {
  const userOrgId = user?.organization?.id?.id;
  const athleteAgencyId = athlete?.data?.agency?.id;
  const isOrgAgency =
    (userOrgId != null && athleteAgencyId != null && userOrgId === athleteAgencyId) || athlete?.data?.custom_athlete;
  const isOrgTeam = user?.organization?.teamId != null && user.organization.teamId === athlete?.data?.current_team?.id;
  const { t } = useTranslation();

  return (
    <div className={styles.actionBar}>
      <div className={styles.reports_pro_actionbar_menu_container}>
        <Menu
          title={t('report_pro.filter_type')}
          placeholder={t('report_pro.filter_type')}
          className={styles.menu}
          rounded
          largeMenu
          allowEmpty
          options={fileTypeOptions.map(value => {
            const label = t(`filters.${value}`);
            return { value, label };
          })}
          value={filters.type}
          onClear={() => setFilters({ ...filters, type: '' })}
          setValue={val =>
            setFilters({
              ...filters,
              type: val,
            })
          }
        />
        <CalendarMenu
          className={styles.menu}
          label={t('report_pro.filter_period')}
          minDatePlaceholder={filters.period.after}
          maxDatePlaceholder={filters.period.before}
          minDateValue={filters.period.after}
          maxDateValue={filters.period.before}
          setMinValue={val => setFilters({ ...filters, period: { ...filters.period, after: val } })}
          setMaxValue={val => setFilters({ ...filters, period: { ...filters.period, before: val } })}
          onClear={() => setFilters({ ...filters, period: { after: null, before: null } })}
        />
        <Menu
          title={t('report_pro.filter_origin')}
          placeholder={t('report_pro.filter_origin')}
          className={styles.menu}
          rounded
          largeMenu
          options={orgs.map(item => ({ label: item.org.fullname, value: item.org.id }))}
          value={filters.origin}
          onClear={() => setFilters({ ...filters, origin: null })}
          setValue={val => setFilters({ ...filters, origin: val })}
        />
      </div>
      <div className={styles.reports_pro_actionbar_checkbox_container}>
        {orgs.some(item => item['org']['id'] === userOrgId) && (
          <Checkbox
            label={t('report_pro.filter_myagency')}
            checked={filters.origin === userOrgId}
            onChange={() =>
              setFilters({
                ...filters,
                origin: filters.origin === userOrgId ? null : userOrgId,
              })
            }
          />
        )}
      </div>
      <div className={styles.reports_pro_actionbar_add_report_button_container}>
        {(isOrgAgency || userOrgId === 8 || isOrgTeam) && (
          <IconButton
            className={styles.reports_pro_actionbar_add_report_button}
            text={t('report_pro.button_add')}
            icon={<BiSolidPlusCircle />}
            onClick={createReportPro}
          />
        )}
      </div>
    </div>
  );
}
