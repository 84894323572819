import styled from 'styled-components';
import { Avatar as DefaultAvatar } from 'components/template';
import Variables from 'variables.js';
import { Link as DefaultLink } from 'react-router-dom';

export const Item = styled.div`
  background-color: ${Variables.white};
  margin: 20px 0px;
  border-radius: 8px;
  width: 100%;
  border: 1px solid ${Variables.lightBorder};
`;


export const Link = styled(DefaultLink)`
  text-decoration: none;
  z-index: 0;
`;

export const ImageSection = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
  width: 100%;
  position: relative;
  border-radius: 8px 8px 0 0;
`;

export const MembersList = styled.div`
  display: flex;
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const EditProject = styled.button`
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  border: none;
  background-color: ${Variables.lightPurple1};
  align-items: center;
  justify-content: center;
  color: ${Variables.white};
  z-index: 1;
  margin: 10px;
  cursor: pointer;

  svg {
    width: 22px;
    height: 22px;
  }
`;

export const Avatar = styled(DefaultAvatar)`
  border: 2px solid ${Variables.white};
  height: 40px;
  width: 40px;
  margin: 5px 5px 5px -15px;
  font-weight: 600;
  background-color: ${Variables.lightPurple3};
`;

export const AvatarNum = styled(DefaultAvatar)`
  border: 2px solid ${Variables.white};
  height: 40px;
  width: 40px;
  margin: 5px 5px 5px -15px;
  font-weight: 600;
  background-color: ${Variables.white};
`;

export const ProjectData = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const ProjectName = styled.span`
  color: ${Variables.lightPurple1};
  font-size: 18px;
  font-weight: 600;
`;

export const AthleteQuantity = styled.span`
  color: ${Variables.black};
  font-size: 16px;
  font-weight: 400px;
  height: 19px;
`;

export const LastModified = styled.span`
  color: ${Variables.gray2};
  font-size: 14px;
  font-weight: 400px;
`;
