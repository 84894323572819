import React from 'react';
import styles from './Graph.module.scss';
import { AiFillInfoCircle } from 'react-icons/ai';
import Variables from 'variables';
import { Link } from 'react-router-dom';
import { Spinner } from 'components/template';
import { useTranslation } from 'react-i18next';

const Graph = ({ loading, data }) => {
  const { t } = useTranslation();
  const minAge = 16;
  const maxAge = 42;
  const sumEachAge = 2;
  const staticAges = Array.from({ length: 14 }, (_, index) => minAge + index * sumEachAge);
  const ageLinesCount = staticAges.length - 1;
  const ageLinePercent = 100 / ageLinesCount;
  const ageLinesGap = Array.from({ length: ageLinesCount }, (_, index) => `${ageLinePercent * (index + 1)}%`);
  const ageLinesIndexOtherColor = [1, 3, 8];
  const titlesContainerWidth = [2, 2, 3.5, 1.5, 4];

  let minMinute = Number.MAX_SAFE_INTEGER;
  let maxMinute = 0;
  if (data != null && data.length > 0) {
    data.forEach(d => {
      const minute = d.minutes_played;
      if (minute < minMinute) {
        minMinute = minute;
      }
      if (minute > maxMinute) {
        maxMinute = minute;
      }
    });
    //arredonda para ficar de 100 em 100
    minMinute = Math.trunc(minMinute / 100) * 100;
    maxMinute = Math.ceil((maxMinute * 1.075) / 100) * 100; //1.1 de margem
  } else {
    minMinute = 0;
    maxMinute = 1600;
  }
  const sumEachMinute = Math.trunc((maxMinute - minMinute) / 8);
  const staticMinutes = Array.from({ length: 9 }, (_, index) => minMinute + index * sumEachMinute);
  const minutesLinesCount = staticMinutes.length - 1;
  const minutesLinesGap = Array.from(
    { length: minutesLinesCount },
    (_, index) => `${(100 / minutesLinesCount) * (index + 1)}%`
  );

  return (
    <div className={styles.graph_container}>
      {loading && <Spinner width="30" height="30" color="white" className={styles.spinner} />}
      <div className={styles.titles}>
        <div className={styles.development} style={{ width: `${ageLinePercent * titlesContainerWidth[0]}%` }}>
          <span>{t('myClub.profileCast.development')}</span>
          <AiFillInfoCircle />
        </div>
        <div className={styles.cutting} style={{ width: `${ageLinePercent * titlesContainerWidth[1]}%` }}>
          <span>{t('myClub.profileCast.cutting')}</span>
          <AiFillInfoCircle />
        </div>
        <div className={styles.performance_peak} style={{ width: `${ageLinePercent * titlesContainerWidth[2]}%` }}>
          <span>{t('myClub.profileCast.performance_peak')}</span>
          <AiFillInfoCircle />
        </div>
        <div className={styles.sustain} style={{ width: `${ageLinePercent * titlesContainerWidth[3]}%` }}>
          <span>{t('myClub.profileCast.sustain')}</span>
          <AiFillInfoCircle />
        </div>
        <div className={styles.tutor} style={{ width: `${ageLinePercent * titlesContainerWidth[4]}%` }}>
          <span>{t('myClub.profileCast.tutor')}</span>
          <AiFillInfoCircle />
        </div>
      </div>

      <div className={styles.graph_border}>
        <div className={styles.minutes_played_label}>{t('myClub.profileCast.minutes_played_label')}</div>
        <div className={styles.age_label}>{t('myClub.profileCast.age_label')}</div>
        <div className={styles.info_label}>{t('myClub.profileCast.info_label')}</div>

        {/* Draw grid lines */}
        {ageLinesGap.map((left, index) => {
          const style = { left };
          if (ageLinesIndexOtherColor.includes(index)) {
            style.borderLeftColor = Variables.green3;
            style.opacity = 1;
          }
          return <div className={styles.x_line} style={style} key={index}></div>;
        })}

        {minutesLinesGap.map((top, index) => {
          return <div className={styles.y_line} style={{ top }} key={index} />;
        })}

        {/* Draw extra line */}
        <div
          className={styles.x_line}
          style={{
            left: `${ageLinePercent * 7.5}%`,
            borderLeftColor: Variables.green3,
            opacity: 1,
          }}
        ></div>

        <div className={styles.titles_container}>
          {titlesContainerWidth.map(tcw => {
            const width = `${ageLinePercent * tcw}%`;
            return <div className={styles.title_container} style={{ width }}></div>;
          })}
        </div>

        {/* Draw data points */}
        <div className={styles.data_points}>
          {data != null &&
            data.length > 0 &&
            data.map((d, index) => {
              const age = d.age;
              const minute = d.minutes_played;

              let xPos;
              if (age < minAge) {
                xPos = 0;
              } else if (age > maxAge) {
                xPos = 100;
              } else {
                xPos = Math.round(((age - minAge) / (maxAge - minAge)) * 100);
              }

              const yPos = 100 - Math.round(((minute - minMinute) / (maxMinute - minMinute)) * 100);

              return (
                <Link key={index} to={{ pathname: `/atletas/${d.id}`, state: { previews: window.location.pathname } }}>
                  <div
                    className={styles.data_point}
                    style={{
                      left: `${xPos}%`,
                      top: `${yPos}%`,
                    }}
                  >
                    <span className={styles.player_name}>{d.nickname ?? d.fullname}</span>
                  </div>
                </Link>
              );
            })}
        </div>

        {/* Draw x-axis labels at the bottom */}
        <div className={styles.x_labels}>
          {staticAges.map((age, index) => (
            <span key={index}>{age}</span>
          ))}
        </div>

        {/* Draw y-axis labels */}
        <div className={styles.y_labels}>
          {staticMinutes.map((minute, index) => (
            <span key={index}>{minute}</span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Graph;
