import axios from 'api';
import Endpoints from 'endpoints';

export const actionTypes = {
    GET_CONNECTIONS: 'connections/GET',
    GET_CONNECTIONS_SUCCESS: 'connections/GET_SUCCESS',
    GET_CONNECTIONS_ERROR: 'connections/GET_ERROR',
    PATCH_CONNECTIONS: 'connections/PATCH',
    PATCH_CONNECTIONS_SUCCESS: 'connections/PATCH_SUCCESS',
    PATCH_CONNECTIONS_ERROR: 'connections/PATCH_ERROR',
    POST_CONNECTIONS: 'connections/POST',
    POST_CONNECTIONS_SUCCESS: 'connections/POST_SUCCESS',
    POST_CONNECTIONS_ERROR: 'connections/POST_ERROR',
    DELETE_CONNECTIONS: 'connections/DELETE',
    DELETE_CONNECTIONS_SUCCESS: 'connections/DELETE_SUCCESS',
    DELETE_CONNECTIONS_ERROR: 'connections/DELETE_ERROR',
};

const initialState = {
    loading: false,
    error: null,
    data: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_CONNECTIONS:
            return {
                ...state,
                loading: true

            }
        case actionTypes.GET_CONNECTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                error: null
            }
        case actionTypes.GET_CONNECTIONS_ERROR:
            return {
                ...state,
                error: action.payload.error
            }

        default:
            return state;
    }
};

export const getConnectionsRequests = () => async dispatch => {
    dispatch({ type: actionTypes.GET_CONNECTIONS });

    const url = Endpoints.networks;

    try {
        const { data } = await axios.get(`${url}`);

        dispatch({
            type: actionTypes.GET_CONNECTIONS_SUCCESS,
            payload: { data: data.results }
        })
    } catch (err) {
        dispatch({
            type: actionTypes.GET_CONNECTIONS_ERROR,
            payload: { error: "erros.text44" }
        })
    }
};

export const sendConnectionRequest = (body) => async dispatch => {
    dispatch({ type: actionTypes.POST_CONNECTIONS });

    const url = Endpoints.networks;

    try {
        const { data } = await axios.post(`${url}`, body);

        dispatch({ type: actionTypes.POST_CONNECTIONS_SUCCESS, payload: { data } })
    } catch (err) {
        dispatch({
            type: actionTypes.POST_CONNECTIONS_ERROR,
            payload: { error: "erros.text44" }
        })
    }
};

export const acceptConnectionRequest = (id, body) => async dispatch => {
    dispatch({ type: actionTypes.PATCH_CONNECTIONS });

    const url = Endpoints.networks;

    try {
        const { data } = await axios.patch(`${url}/${id}`, body);

        dispatch({ type: actionTypes.PATCH_CONNECTIONS_SUCCESS, payload: { data } })
    } catch (err) {
        dispatch({
            type: actionTypes.PATCH_CONNECTIONS_ERROR,
            payload: { error: "erros.text44" }
        })
    }
};

export const deleteConnectionRequest = (id) => async dispatch => {
    dispatch({ type: actionTypes.DELETE_CONNECTIONS });

    const url = Endpoints.networks;

    try {
        const { data } = await axios.delete(`${url}/${id}`);

        dispatch({ type: actionTypes.DELETE_CONNECTIONS_SUCCESS, payload: { data } })
    } catch (err) {
        dispatch({
            type: actionTypes.DELETE_CONNECTIONS_ERROR,
            payload: { error: "erros.text44" }
        })
    }
};
