import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'shared/hooks';
import styles from './MoreFilters.module.scss';
import { Button, Calendar, Menu } from 'components/template';
import { useTranslation } from 'react-i18next';
import { MinMaxDate } from 'pages/AdvancedSearch/Filter/CustomInputs/CustomInputs';
import CheckboxContainer from 'pages/AdvancedSearch/Filter/CheckboxContainer/CheckboxContainer';
import { defaultClubEvaluationFields, priorityOptionsCB } from 'shared/mocks';
import { Creators as clubActions } from 'store/ducks/club';
import CustomCollapse from './CustomCollapse/CustomCollapse';
import { getEvaluationClubs } from 'store/ducks/user';

const MoreFilters = ({ isVisible, closeModal, applyFilter, children, onFilterCountChange }) => {
    const initialFilters = {
        priority: [],
        mode: [],
        evaluation_club: null,
        actual_club: null,
        athlete_birthdate: {
          before: null,
          after: null,
        },
        period: {
          before: null,
          after: null,
        },
      };
    const dispatch = useDispatch();
    const [filters, setFilters] = useState(initialFilters);
    const { t } = useTranslation();
    const user = useSelector(state => state.user.data);
    const evaluationFields = useSelector(state => state.club.evaluationFields);
    const [filterCount, setFilterCount] = useState(0);
    const [evaluationTeams, setEvaluationTeams] = useState(null);
    const [actualTeams, setActualTeams] = useState(null);

    const countValidFilters = (filters) => {
        let count = 0;
    
        Object.keys(filters).forEach((key) => {
            const value = filters[key];
            
            if (Array.isArray(value)) {
                count += value.length; // Conta cada item no array
            } else if (typeof value === 'object' && value !== null) {
                // Verifica os subcampos 'before' e 'after' para objetos
                Object.values(value).forEach(subValue => {
                    if (subValue !== null && subValue !== '') {
                        count += 1;
                    }
                });
            } else if (value !== null && value !== false) {
                count += 1;
            }
        });
    
        return count;
    };

    useEffect(() => {
        if (evaluationTeams == null || actualTeams == null) {
          getEvaluationClubs().then(evaluationsApi => {            
            setEvaluationTeams(evaluationsApi?.data?.evaluation_teams);
            setActualTeams(evaluationsApi?.data?.actual_teams);
          }
          );
        }
    });
    

    useEffect(() => {
        onFilterCountChange(filterCount);
      }, [filterCount]);//eslint-disable-line

    useEffect(() => {        
        if (evaluationFields && (user.organization.id.id === 152)) {
            if(evaluationFields.data.filter(item => item?.mode === 'RELATORIO-DE-JOGO').length === 0){
                evaluationFields.data.push(defaultClubEvaluationFields[0]);
            }
        }
    }, [evaluationFields]);//eslint-disable-line

    useMount(() => {        
        dispatch(clubActions.getEvaluationFields());
    });

    const onSetFilters = newFilters => {        
        setFilters(newFilters);
    };

    const limpar = () => {
        setFilters(initialFilters);
    };

    if (!isVisible) return null;    

    return (
        <div 
        className={styles.modalOverlay}
        onClick={closeModal}
        >
        <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
        >
            <div className={styles.modalHeader}>
            <span>{t('filters.filter')}</span>
            <button className={styles.closeButton} onClick={closeModal}>
                &times;
            </button>
            </div>

            <div className={styles.modalBody}>
                <h3>{t('report_pro.filter_period')}</h3>
                <div className={styles.periodContainer}>         
                    <span>{t('filters.de')}</span>
                    <div className={styles.calendarContainer}>
                        <Calendar
                            value={filters.period.after}
                            setValue={value => {
                                onSetFilters({
                                ...filters,
                                period: {
                                    ...filters.period,
                                    after: value,
                                }
                                })
                            }}
                            minDate={1975}
                        />
                    </div>                    
                    <span>{t('filters.ate')}</span>
                    <div className={styles.calendarContainer}>
                        <Calendar
                            value={filters.period.before}
                            setValue={value => {onSetFilters({
                                    ...filters,
                                    period: {
                                        ...filters.period,
                                        before: value,
                                    }
                                })
                            }}
                            minDate={1975}
                        />
                    </div>
                </div>                     
                <h3>{t('filters.birt_year')}</h3>
                    <div className={styles.periodContainer}>
                        <span>{t('slider_menu.in')}</span>
                        <div className={styles.minMaxDateChildren}>
                            <MinMaxDate
                                to
                                noTitle={true}
                                title={t('slider_menu.in')}
                                titlePlaceholder={
                                    (filters?.athlete_birthdate?.after ?? '').toString().length > 0 
                                    ? filters.athlete_birthdate?.after.slice(0, 4) 
                                    : '---'
                                }
                                setFilter={values => {
                                    const afterDate = values.max ? new Date(values.max.slice(0,4), 0, 1) : null;
                                    const validDate = afterDate instanceof Date && !isNaN(afterDate);                                
                                    onSetFilters({
                                        ...filters,
                                        athlete_birthdate: {
                                            ...filters.athlete_birthdate,
                                            after: validDate ? afterDate.toISOString().slice(0, 10) : null,
                                        }
                                    });
                                }
                                }
                                onClear={() => {
                                    onSetFilters({ ...filters, athlete_birthdate: { ...filters.athlete_birthdate, after: null } })                                    
                                }}
                                valueMin={filters.athlete_birthdate.before}
                                valueMax={filters.athlete_birthdate.after}
                            />
                        </div>
                        <span>{t('slider_menu.e')}</span>
                        <div className={styles.minMaxDateChildren}>
                            <MinMaxDate
                                from
                                noTitle={true}
                                title={t('slider_menu.e')}
                                titlePlaceholder={
                                    (filters?.athlete_birthdate?.before ?? '').toString().length > 0 
                                    ? filters.athlete_birthdate.before.slice(0, 4) 
                                    : '---'
                                }
                                setFilter={values => {
                                    const beforeDate = values.min ? new Date(values.min.slice(0,4), 11, 31) : null;
                                    const validDate = beforeDate instanceof Date && !isNaN(beforeDate);                                
                                    onSetFilters({
                                        ...filters,
                                        athlete_birthdate: {
                                        ...filters.athlete_birthdate,
                                        before: validDate ? beforeDate.toISOString()?.slice(0, 10): null,
                                        }
                                    })
                                }}
                                onClear={() => {
                                    onSetFilters({ ...filters, athlete_birthdate: { ...filters.athlete_birthdate, before: null } })                                    
                                }}
                                valueMin={filters.athlete_birthdate.before}
                                valueMax={filters.athlete_birthdate.after}
                            />
                        </div>
                    </div>       
                <h3>{t('filters.byPriority')}</h3>
                <CheckboxContainer
                    className={styles.customCheckbox}
                    setFilter={value => {
                        onSetFilters({ ...filters, priority: value})
                    }}
                    value={filters.priority || null}
                    checkboxes={priorityOptionsCB.map(item => ({
                        label: t('config.'+item.value),
                        value: item.value,
                      }))}
                />
                {evaluationFields && <>
                    <CustomCollapse openedDefault title={t('table.model')}>
                        <CheckboxContainer
                            className={styles.customCheckbox}
                            setFilter={value => {
                                onSetFilters({ ...filters, mode: value})
                            }}
                            value={filters.mode || null}
                            checkboxes={evaluationFields?.data.map(item => ({
                                label: t('avaliationModel.'+item?.mode || ''),
                                value: item?.data?.mode || '',
                            }))}
                        />
                    </CustomCollapse>
                    </>
                }
                <h3>{t('charts.byMomentClub')}</h3>
                <div className={styles.periodContainer}>
                    <Menu
                        title={t('dashboard.clubs')}
                        rounded
                        allowEmpty
                        placeholder={t('charts.byMomentClub')}
                        className={styles.menu}
                        options={
                        evaluationTeams
                            ? evaluationTeams.map(item => ({
                                label: item.nickname,
                                value: item.id,
                            }))
                            : [
                                {
                                label: t('proj.text20'),
                                value: 0,
                                },
                            ]
                        }
                        value={filters['evaluation_club']}
                        onClear={() => {
                        setFilters({ ...filters, evaluation_club: null });
                        }}
                        setValue={val => {
                        setFilters({ ...filters, evaluation_club: val ? val : null });
                        }}
                    />
                </div>
                <h3>{t('charts.byActualClub')}</h3>
                <div className={styles.periodContainer}>
                    <Menu
                        title={t('dashboard.clubs')}
                        rounded
                        allowEmpty
                        placeholder={t('charts.byActualClub')}
                        className={styles.menu}
                        options={
                        actualTeams
                            ? actualTeams.map(item => ({
                                label: item.nickname,
                                value: item.id,
                            }))
                            : [
                                {
                                label: t('proj.text20'),
                                value: 0,
                                },
                            ]
                        }
                        value={filters['actual_club']}
                        onClear={() => {
                        setFilters({ ...filters, actual_club: null });
                        }}
                        setValue={val => {
                        setFilters({ ...filters, actual_club: val ? val : null });
                        }}
                    />
                </div>
                {children}
            </div>

            <div className={styles.modalFooter}>
            <Button rounded color="white" onClick={() => limpar()}>Limpar</Button>
            <Button rounded color="purple" onClick={() => {
                applyFilter(filters)
                setFilterCount(countValidFilters(filters));
            }}>Aplicar</Button>
            </div>
        </div>
        </div>
    );
};

export default MoreFilters;
