import React from 'react';
import PropTypes from 'prop-types';

import styles from './InfoBar.module.scss';
import moment from 'moment';
import { FaCalendarAlt, FaMapMarkerAlt } from 'react-icons/fa';
import { Avatar } from 'components/template';

export default function InfoBar({ competition }) {
  return (
    <div className={styles.competitionInfo}>
      <div className={styles.competitionName}>
        <Avatar
          className={styles.avatar}
          noBorder
          noRadius
          img={competition.emblem ?? ''}
          name={competition.nickname}
        />
        <span className={styles.title}>{competition.name}</span>
      </div>

      <div className={styles.detailsContainer}>
        <div className={styles.competitionOrganizer}>
          <Avatar
            className={styles.avatar}
            noBorder
            noRadius
            img={competition.organizer?.emblem ?? ''}
            name={competition.organizer?.name}
          />
          <span className={styles.title}>{competition.organizer?.name}</span>
        </div>
        <div className={styles.period}>
          <FaCalendarAlt size={18} />
          <span className={styles.title}>
            {moment(competition.start_date).format('DD/MM/YYYY')} - {moment(competition.end_date).format('DD/MM/YYYY')}
          </span>
        </div>
        <div className={styles.location}>
          <FaMapMarkerAlt size={18} />
          <span className={styles.title}>{competition.country?.name}</span>
          <img src={competition.country?.flag} alt={competition.country?.name ?? ''} />
        </div>
      </div>
    </div>
  );
}

InfoBar.propTypes = {
  competition: PropTypes.instanceOf(Object).isRequired,
};
