import React from 'react';
import styles from './Project.module.scss';
import { TopBar } from 'components/template';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import target from './icons/targetIcon.png';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import Variables from 'variables';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import Projects from './Projects/Projects';
import Reports from './Reports/Reports';
import Monitoring from './Monitoring/Monitoring';
import Evaluations from './Evaluations/Evaluations';
import TransferWindows from './TransferWindows/TransferWindows';

function Project() {
  const { t } = useTranslation();
  const user = useSelector(state => state.user.data);

  const baseURL = `/projetos`;
  const items = [];
  if (user.is_only_for_projects || user.plan.projects_limit !== 0) {
    items.push({
      label: t('proj.linkProjetos'),
      link: `/projetos`,
      component: <Projects />,
    });
  }
  const showOtherRoutes = !user.is_only_for_projects;
  if (showOtherRoutes) {
    items.push(
      {
        label: t('proj.linkRelatorios'),
        link: `${baseURL}/relatorios`,
        component: <Reports />,
      },
      {
        label: t('proj.linkMonitoramento'),
        link: `${baseURL}/monitoramento`,
        component: <Monitoring />,
      },
      {
        label: t('proj.linkJanelasTransferencias'),
        link: `${baseURL}/janelas-transferencias`,
        component: <TransferWindows />,
      }
    );

    if (user.organization != null && user.plan.evaluations_limit !== 0) {
      items.push({
        label: t('proj.linkAvaliacoes'),
        link: `${baseURL}/avaliacoes`,
        component: <Evaluations />,
      });
    }
  }
  const routes = items.map(item => <Route key={item.label} exact path={item.link} render={() => item.component} />);

  return (
    <div className={styles.container}>
      <TopBar
        title={t('home.text10')}
        subtitle={t('home.text11')}
        icon={<img draggable="false" className={styles.targetIcon} src={target} alt="target" />}
        iconBgColor={Variables.brown}
      />
      {showOtherRoutes && <NavigationBar items={items} />}
      <Switch>
        {routes}
        <Redirect to={baseURL} />
      </Switch>
    </div>
  );
}

export default withRouter(Project);
