import React, { useState } from 'react';
import styles from './FormPerfil.module.scss';
import { useTranslation } from 'react-i18next';
import { Input, /*Checkbox, */ Avatar, Menu } from 'components/template';
import { AiFillInfoCircle } from 'react-icons/ai';
import { getBase64 } from 'shared/utility';
import Variables from 'variables';

export default function FormPerfil({
  onChangeFormUser,
  userName,
  userEmail,
  errorMessage,
  countries,
  onUpdateFile,
  onChangeFormPassword,
}) {
  const { t } = useTranslation();
  const [values, setValues] = useState([]);
  const [imgBase64, setImgBase64] = useState(null);

  const handleUpdateFile = async e => {
    const file = e.target.files[0];
    getBase64(file, base64 => setImgBase64(base64));
    onUpdateFile(file);
  };

  const handleChangeFormUser = e => {
    onChangeFormUser(e);
  };

  return (
    <div className={styles.content}>
      <h2 className={styles.subTitleDadosPessoais}>{t('firstAccess.text22')}</h2>
      <div className={styles.fileInput}>
        <Avatar noBorder img={imgBase64} name={userName ?? ''} className={styles.avatar} />
        <div className={styles.fileButton}>
          <span>{t('organi.box2')}</span>
          <Input
            onChange={handleUpdateFile}
            rounded
            smaller
            type="file"
            inputClassName={styles.avatarInput}
            containerClassName={styles.avatarLabel}
            label={t('organi.box1')}
          />
        </div>
      </div>
      <div className={styles.nomeEmailInput}>
        <Input
          label={t('professionals.filtersInputName')}
          inputStyle={{ width: 340 }}
          name="name"
          onChange={e => handleChangeFormUser(e)}
          required
        />
        <Input label="E-mail" inputStyle={{ width: 340 }} value={userEmail} required disabled />
      </div>
      <Input
        label={t('contact_card.role')}
        inputStyle={{ width: 340 }}
        name="role"
        onChange={e => handleChangeFormUser(e)}
        required
      />
      <div className={styles.telephoneContainer}>
        <div>
          <div className={styles.telephone}>
            <div>
              <Menu
                rounded
                className={styles.ddi}
                labelClassName={styles.ddiLabel}
                options={countries.data.map(country => ({
                  label: (
                    <>
                      <img className={styles.flagImage} src={country.flag} alt="country flag" />
                      <span className={styles.spanFlag}>{country.code}</span>
                    </>
                  ),
                  value: country.ddi,
                }))}
                placeholder={
                  <>
                    <img
                      className={styles.flagImage}
                      alt="country flag"
                      src={
                        countries.data.find(country => country.ddi === values.phoneddi)?.flag ||
                        countries.data.find(country => country.code === 'BR')?.flag
                      }
                    />
                    <span className={styles.spanFlag}>
                      {countries.data.find(country => country.ddi === values.phoneddi)?.code ||
                        countries.data.find(country => country.code === 'BR')?.code}
                    </span>
                  </>
                }
                setValue={e => {
                  setValues({ ...values, phoneddi: e });
                  handleChangeFormUser({ name: 'phoneddi', value: e });
                }}
                name="phoneddi"
                label={t('contact_card.phone')}
                value={
                  <span className={styles.spanFlag}>
                    {countries.data.find(country => country.ddi === values.phoneddi)?.code ||
                      countries.data.find(country => country.code === 'BR')?.code}
                  </span>
                }
              />
            </div>
            <div className={styles.number}>
              <Input
                onChange={e => handleChangeFormUser(e)}
                type="number"
                name="phoneddd"
                placeholder="00"
                required
                rounded
                containerClassName={styles.ddd}
              />
              <Input
                onChange={e => handleChangeFormUser(e)}
                type="phonenumber"
                name="phonenumber"
                placeholder="000000000"
                rounded
                required
                containerClassName={styles.phone}
              />
            </div>
          </div>
          <p>
            <AiFillInfoCircle />
            {t('firstAccess.text23')}
          </p>
        </div>
        <div>
          <div className={styles.telephone}>
            <div>
              <Menu
                rounded
                className={styles.ddi}
                labelClassName={styles.ddiLabel}
                options={countries.data.map(country => ({
                  label: (
                    <>
                      <img className={styles.flagImage} src={country.flag} alt="country flag" />
                      <span className={styles.spanFlag}>{country.code}</span>
                    </>
                  ),
                  value: country.ddi,
                }))}
                placeholder={
                  <>
                    <img
                      className={styles.flagImage}
                      alt="country flag"
                      src={
                        countries.data.find(country => country.ddi === values.whatsappddi)?.flag ||
                        countries.data.find(country => country.code === 'BR')?.flag
                      }
                    />
                    <span className={styles.spanFlag}>
                      {countries.data.find(country => country.ddi === values.whatsappddi)?.code ||
                        countries.data.find(country => country.code === 'BR')?.code}
                    </span>
                  </>
                }
                setValue={e => {
                  setValues({ ...values, whatsappddi: e });
                  handleChangeFormUser({ name: 'whatsappddi', value: e });
                }}
                name="whatsappddi"
                label="WhatsApp"
                value={
                  <>
                    <span className={styles.spanFlag}>
                      {countries.data.find(country => country.ddi === values.whatsappddi)?.code ||
                        countries.data.find(country => country.code === 'BR')?.code}
                    </span>
                  </>
                }
              />
            </div>
            <div className={styles.number}>
              <Input
                onChange={e => handleChangeFormUser(e)}
                type="number"
                name="whatsappddd"
                placeholder="00"
                // required={values.profile.phones.phone1.number != null}
                rounded
                containerClassName={styles.ddd}
                // value={values.profile.phones.phone1.ddd}
              />
              <Input
                onChange={e => handleChangeFormUser(e)}
                type="number"
                name="whatsappnumber"
                placeholder="000000000"
                rounded
                containerClassName={styles.phone}
                // value={values.profile.phones.phone1.number}
              />
            </div>
          </div>
          <p>
            <AiFillInfoCircle />
            {t('firstAccess.text23')}
          </p>
        </div>
      </div>
      <div className={styles.newPasswordContainer}>
        <h1>{t('firstAccess.text24')}</h1>
        <p className={styles.newPassSubTitle}>{t('firstAccess.text25')}</p>
        <div className={styles.newPassword}>
          <Input
            label={t('home.text16')}
            inputStyle={{ width: 340 }}
            name="new_password"
            onChange={e => onChangeFormPassword(e)}
          />
          <Input
            label={t('home.text17')}
            inputStyle={{ width: 340 }}
            name="re_new_password"
            onChange={e => onChangeFormPassword(e)}
          />
        </div>
        <p
          style={
            errorMessage === 'firstAccess.errorPassword'
              ? {
                  color: Variables.red,
                  fontWeight: '600',
                }
              : {}
          }
        >
          <AiFillInfoCircle />
          {t('firstAccess.text26')}
        </p>
      </div>
      {/* <Checkbox
        // onChange={() => {
        //   handleCheck(item.id);
        // }}
        // checked={checkedLists.some(id => id === item.id)}
        className={styles.checkboxItem}
        // key={item.id}
        label={t('firstAccess.text27')}
      /> */}
      {errorMessage != null && <span className={styles.spanError}>{t(errorMessage)}</span>}
    </div>
  );
}
