import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from 'store/ducks/club';
import { Card, Spinner } from 'components/template';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import styles from './Club.module.scss';
import TopBar from './TopBar/TopBar';
import ProfessionalCast from './ProfessionalCast/ProfessionalCast';
import Loaned from './Loaned/Loaned';
import Amateur from './Amateur';
import Female from './Female';
import { useTranslation } from 'react-i18next';

function Club({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const club = useSelector(state => state.club.clubPage);

  useEffect(() => {
    dispatch(Creators.getClub(match.params.id));
    const body = document.querySelector('body');
    body.classList.add('lightBg');

    return () => {
      dispatch(Creators.reset());
      body.classList.remove('lightBg');
    };
  }, [dispatch, match.params.id]);

  const teamId = match ? match.params.id : null;
  const baseURL = `/clube/${teamId}`;

  const items = [
    // {
    //   notImplemented: true,
    //   label: 'Visão Geral',
    //   link: `${baseURL}`,
    //   component: <Overview club={club.data} baseURL={baseURL} />,
    // },
    // {
    //   label: 'Competições',
    //   link: `${baseURL}/competicoes`,
    //   amount: club.data.competitionsAmount,
    // },
    {
      label: t('text.box30'),
      link: `${baseURL}/elenco`,
      component: <ProfessionalCast teamId={teamId} />,
      amount: club.data.professionalCastAmount,
    },
    {
      label: t('text.box31'),
      link: `${baseURL}/emprestados`,
      component: <Loaned teamId={teamId} />,
      amount: club.data.loanedAmount,
    },
    {
      label: t('text.box32'),
      link: `${baseURL}/amadores`,
      component: <Amateur teamId={teamId} />,
      // amount: club.data.loanedAmount,
    },
    {
      label: t('text.box33'),
      link: `${baseURL}/feminino`,
      component: <Female teamId={teamId} />,
    },
    // {
    //   label: 'Avaliações',
    //   shouldHide: Number(user?.data?.organization?.teamId) !== Number(teamId),
    //   link: `${baseURL}/avaliacoes`,
    //   component: <Evaluations teamId={teamId} baseURL={baseURL} />,
    //   amount: club.data.observationsAmount,
    // },
    // {
    //   notImplemented: true,
    //   label: 'Contatos',
    //   link: `${baseURL}/contatos`,
    //   component: <Contacts teamId={teamId} />,
    //   amount: club.data.contactsAmount,
    // },
    // {
    //   label: 'Transferências',
    //   link: `${baseURL}/transferencias`,
    //   amount: club.data.transfersAmount,
    // },
    // {
    //   notImplemented: true,
    //   label: 'Vídeos',
    //   link: `${baseURL}/videos`,
    //   component: <Videos teamId={teamId} />,
    //   amount: club.data.videosAmount,
    // },
    // {
    //   notImplemented: true,
    //   label: 'Notícias',
    //   link: `${baseURL}/noticias`,
    //   component: <News teamId={teamId} />,
    //   amount: club.data.newsAmount,
    // },
  ];

  const routes = items.map(item => <Route key={item.label} exact path={item.link} render={() => item.component} />);

  let content = null;
  if (club.loading) {
    content = (
      <div className={styles.loaderContainer}>
        <Spinner />
      </div>
    );
  } else if (club.error) {
    content = (
      <Card className={styles.errorContainer}>
        <h2>{t(club.error)}</h2>
      </Card>
    );
  } else {
    content = (
      <>
        <TopBar club={club.data} />
        <div className={styles.content}>
          <NavigationBar items={items} />
          <div className={styles.contentMargin}>
            <Switch>
              {routes}
              {/* elenco redirect until overview is completed */} <Redirect to={items[0].link} />
              <Redirect to={baseURL} />
            </Switch>
          </div>
        </div>
      </>
    );
  }

  return <div className={styles.container}>{content}</div>;
}

Club.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(Club);
