/*eslint-disable eqeqeq*/
import React from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function Tabs({ filter, setFilter }) {
  const { t } = useTranslation();
  const itemClasses = [styles.badge];
  const loggedUser = useSelector(state => state.user.data);

  const items = [];

  if (loggedUser.plan.meu_feed) {
    items.push(
      {
        text: t('pagina_inicial.homepage_7'),
        value: '',
      },
      {
        text: t('pagina_inicial.homepage_8'),
        value: 'bids',
      }
    );
  }

  items.push(
    {
      text: t('pagina_inicial.homepage_9'),
      value: 'actions',
    },
    {
      text: 'FootNews',
      value: 'notices',
    }
  );

  const linksList = items.map(i => {
    if (i.value == filter) itemClasses.push(styles.active);
    else if (itemClasses.indexOf(styles.active) > 0) itemClasses.splice(itemClasses.indexOf(styles.active), 1);

    return (
      <span key={i.value} className={itemClasses.join(' ')} onClick={() => setFilter(i.value)}>
        {' '}
        {i.text}
      </span>
    );
  });

  return <div className={styles.container}>{linksList}</div>;
}
