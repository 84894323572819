import { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * hook which returns the last value of the parameter
 * @param {*} value
 */
export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function useMount(func) {
    useEffect(func, []);
}

export function usePagination(limit = 20, offset = 0) {
    const defaultPagination = {
        limit,
        offset,
    };

    const [pagination, setPagination] = useState(defaultPagination);

    return [pagination, setPagination, defaultPagination];
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}
