import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NegotiationRepository } from 'data/negotiations';
import { AthleteChatRepository } from 'data/athleteChat';
import { TeamChatRepository } from 'data/teamChat';
import { AgencyChatRepository } from 'data/agencyChat';
import { Creators as notificationsCreators } from 'store/ducks/notifications';

export default function useChats(filters, setSidebarLoading) {
  const history = useHistory();
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notifications);
  const updateNotificationsRef = useRef(false);
  const { repository, id } = useParams();

  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);

  const handleSelectChat = chat_id => {
    history.push(`/mensagens/${repository}/${chat_id}`);
    setSelectedChat(chats.find(i => i.id === chat_id));
  };

  const repositoryObject = useMemo(
    () => ({
      negociacoes: {
        list: async params => await NegotiationRepository.list(params),
      },
      atletas: {
        list: async params => await AthleteChatRepository.list(params),
      },
      clubes: {
        list: async params => await TeamChatRepository.list(params),
      },
      agentes: {
        list: async params => await AgencyChatRepository.list(params),
      },
    }),
    []
  );

  const loadChats = useCallback(
    async queryFilters => {
      setSidebarLoading(true);
      const response = await repositoryObject[repository].list(queryFilters);

      if (response && !!response.results) {
        setChats(response.results);

        if (id && response.results.length) {
          setSelectedChat(response.results.find(i => i.id === parseInt(id)));
        }
      }

      setSidebarLoading(false);
    },
    [repository] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (repository != null) {
      loadChats(filters);
    }
  }, [filters, repository, loadChats]);
  
  useEffect(() => {
    if (repository == null) {
      return;
    }

    const count = selectedChat?.new_messages_count ?? 0;
    if (count > 0) {
      if (repository === 'negociacoes') {
        dispatch(notificationsCreators.updateTransferMarketCount(-count));
      }
      if (repository === 'atletas') {
        dispatch(notificationsCreators.updateAthletechatCount(-count));
      }
      if (repository === 'clubes') {
        dispatch(notificationsCreators.updateTeamchatCount(-count));
      }
      if (repository === 'agentes') {
        dispatch(notificationsCreators.updateOrgchatCount(-count));
      }
      setSelectedChat({ ...selectedChat, new_messages_count: 0 });
    }
  }, [selectedChat, dispatch, repository]);
  
  useEffect(() => {
    if (!notifications.update || repository == null) {
      return;
    }

    if (!updateNotificationsRef.current) {
      //ignora o primeiro update, afinal já está na tela de mensagens
      updateNotificationsRef.current = true;
      return;
    }

    repositoryObject[repository].list({ ...filters, load_notifications: 'true' }).then(response => {
      if (response?.results) {
        dispatch(notificationsCreators.setNotification({
          newAthletechatCount: response.newAthletechatCount,
          newMessagesCount: response.newMessagesCount,
          newOrgchatCount: response.newOrgchatCount,
          newTeamchatCount: response.newTeamchatCount,
          newTransferMarketCount: response.newTransferMarketCount,
          newTransferMarketMessages: response.newTransferMarketMessages,
        }));
        setChats(response.results);
      }
    });
  }, [notifications]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    chats,
    selectedChat,
    handleSelectChat,
    repository,
  };
}
