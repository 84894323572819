import CenteredLoader from 'components/template/CenteredLoader';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'api';
import { useTranslation } from 'react-i18next';
import { Types, Creators } from 'store/ducks/user';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';

export default function ReportExport() {
    const { token } = useParams();
    const [access, setAccess] = useState(null);
    const { t } = useTranslation();
    const udata = JSON.parse(localStorage.getItem('footlink_udata'));
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const { data } = await axios.get(`/reports-access?token=${token}`);
                if ((data?.results ?? []).length === 0) {
                    setAccess({error: true});
                } else {
                    const reportAccess = data.results[0];
                    setAccess({
                        report: reportAccess.report,
                        jwt: reportAccess.jwt.split(' ')[1],
                        error: false,
                        redirect: false,
                    });
                }
            } catch(e) {
                setAccess({error: true});
            }
        };
        fetchToken();
    }, [token]);
    
    if (access == null) {
        return <CenteredLoader />;
    }

    if (access.error) {
        return t('errorMessage.defaultCredentialsError');
    }
    
    const loggedJwt = udata?.accessToken;
    if (loggedJwt != null) {
        const loggedUserId = jwtDecode(loggedJwt).user_id;
        const accessUserId = jwtDecode(access.jwt).user_id;
        if (loggedUserId !== accessUserId) {
            return t('report.appAccessAuthError');
        }
        history.push({ pathname: `/relatorios/${access.report}`, state: {export: true, type: 'preview'} });
    } else {
        Creators.loginWithBearer(access.jwt, '', false).then(login => {
            const user = login.user;
            dispatch({
              type: Types.LOGIN_SUCCESS,
              payload: {
                accessToken: access.jwt,
                refreshToken: '',
                data: { ...user },
              },
            });
            history.push({ pathname: `/relatorios/${access.report}`, state: {export: true, type: 'preview'} });
        });
    }
    

    return <CenteredLoader />;
}