import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_PT } from "./pt/translations";
import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_BR } from "./br/translations";
import { TRANSLATIONS_ESP } from "./esp/translations";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: TRANSLATIONS_EN
            },
            pt: {
                translation: TRANSLATIONS_PT
            },
            br: {
                translation: TRANSLATIONS_BR
            },
            esp: {
                translation: TRANSLATIONS_ESP
            }
        }
    });

const defaultLanguage = localStorage.getItem("language") ?? "br"
i18n.changeLanguage(defaultLanguage);

export default i18n;