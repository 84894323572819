import React, { useState, useEffect } from 'react';
import { RadarChart, StarRating, LineChart, Spinner } from 'components/template';
import styles from './athleteCharts.module.scss';
import { useTranslation } from 'react-i18next';

const plotOptions = {
    plotOptions: {
        radar: {
        size: 100,        
        },
    },
};

const AthleteCharts = ({evaluations, loading, filters, setFilters}) => {  
    const { t } = useTranslation();
    const [selectedSection, setSelectedSection] = useState(null);
    const [radarChartData, setRadarChartData] = useState({
        labels: [],
        series: [{ data: [] }],
    });    

    const mapToFields = (data) => {                    
        const subfieldsMapped = Object.values(data.aspects).map(subfield => ({
            title: subfield.title,
            rate: subfield.avg_rate
        }));
        
        const fields = [
            ...subfieldsMapped,            
        ];
    
        return fields;
    };

    const mapToRadarChart = (data) => {
        const fields = mapToFields(data);
        const radarChartLabels = fields.map(item => String(item.title));
        const radarChartSeries = fields.map(item => item.rate || 0);
        
        setRadarChartData({
            labels: radarChartLabels,
            series: [{ data: radarChartSeries }]
        });
    };

    const mapToFieldsMicroAspects = (data) => {        
        const subfieldsMapped = data.micro_aspect.map(subfield => ({
            title: subfield.title,
            rate: subfield.avg_rate
        }));
        const fields = [
            ...subfieldsMapped,            
        ];

        return fields;
    };

    const mapToRadarChartMicroAspects = (data) => {
        const fields = mapToFieldsMicroAspects(data);
        const radarChartLabels = fields.map(item => String(item.title));
        const radarChartSeries = fields.map(item => item.rate || 0);
        
        return {
            labels: radarChartLabels,
            series: [{ data: radarChartSeries }]
        };
    };

    useEffect(() => {          
        if (evaluations?.dashboard?.sections) {
            const selected = evaluations.dashboard.sections.map(section => {
                const sectionKey = Object.keys(section).find(
                  key => section[key].selected === true
                );
                return sectionKey ? { [sectionKey]: section[sectionKey] } : null;
              }).filter(Boolean);            

            if (selected.length > 0) {
                const sectionName = Object.keys(selected[0])[0];                
                setSelectedSection(selected[0][sectionName]);
                mapToRadarChart(selected[0][sectionName])
            }
        }
    }, [evaluations]); // eslint-disable-line react-hooks/exhaustive-deps

    return (            
        <>{!loading ? <>
            { evaluations?.dashboard?.sections.length > 0 && evaluations?.dashboard?.evolution.length > 0?
                <div className={styles.container}>
                    { evaluations?.dashboard?.sections &&
                        <div className={styles.menuMode}>
                            <span>{t('athleteDashboard.avaliationModels')}</span>
                            <div className={styles.modeList}>
                                { Object.values(evaluations?.dashboard?.sections[0]).map((section, index) => {                                    
                                    const sectionName = Object.keys(evaluations?.dashboard?.sections[0])[index];
                                    const isSelected = section?.selected;                                    
                                    return <div key={section.title} 
                                                className={`${styles.mode} ${isSelected ? styles.selectedMode : ''}`}
                                                onClick={() => {                                                
                                                    setFilters({
                                                        ...filters, 
                                                        mode: [sectionName],
                                                        selected: [sectionName]
                                                    })
                                                }}
                                            >
                                                    {t(`avaliationModel.${sectionName}`) + (section?.count ? ' ('+ section?.count +')' : '') }
                                            </div>
                                })}
                            </div>                                          
                        </div>
                    }
                    <div className={styles.cards}> 
                        <div className={styles['card-md']}>
                            <h3>{t('athleteDashboard.general')}</h3>
                            <div className={styles.visaoGeral}>          
                                <div className={styles.section}>
                                    <RadarChart 
                                        height={280} 
                                        data={radarChartData} 
                                        options={plotOptions}
                                    />
                                </div>
                                <div className={styles.section}>
                                    <h3>{t('athleteDashboard.generalAvaliation')}</h3>
                                    { selectedSection &&
                                        <>
                                            <div className={styles.average}>
                                                <StarRating
                                                    rate={selectedSection?.avg_rate}
                                                    size={18}              
                                                />
                                                <span>({selectedSection?.avg_rate})</span>
                                            </div>  
                                            <table>
                                                <tbody>
                                                    {Object.values(selectedSection?.aspects || {}).map(subfield => 
                                                        <tr key={'subfield-' + subfield?.title}>
                                                            <td>{subfield?.title}:</td>
                                                            <td className={styles.stars}>
                                                                <StarRating rate={subfield?.avg_rate || 0} size={16}/>
                                                                <span>({subfield?.avg_rate || 0})</span>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>                                      
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        {evaluations?.dashboard?.evolution && 
                            <div className={styles['card-md']}>
                                <h3>{t('athleteDashboard.evolution')}</h3>
                                <LineChart 
                                    height={280}
                                    data={evaluations?.dashboard?.evolution}
                                />            
                            </div>
                        }
                        </div>
                        <div className={styles['cards-sm']}>                              
                            { Object.values(selectedSection?.aspects || {}).map(aspect => (
                                <>{aspect?.micro_aspect &&
                                <div className={styles['card-sm']}>
                                    <h3>{aspect?.title}</h3>
                                    <div className={styles.averageAspect}>
                                        <StarRating rate={aspect?.avg_rate} size={18}/>
                                        <span>({aspect?.avg_rate})</span>
                                    </div>
                                    <div className={styles.aspecto}>
                                        {                                                                                        
                                            <RadarChart 
                                                height={280} 
                                                data={mapToRadarChartMicroAspects(aspect)} 
                                                options={plotOptions}
                                            />      
                                        }
                                        <ul>
                                            {aspect?.micro_aspect?.map(microAspect => (
                                                <li>{microAspect?.title}: 
                                                    <div className={styles.stars}>
                                                        <StarRating rate={microAspect?.avg_rate} size={16}/>
                                                        <span>({microAspect?.avg_rate})</span>
                                                    </div>
                                                </li>    
                                            ))}                                            
                                        </ul>                
                                    </div>
                                </div>
                                }</>))}
                        </div>
                    </div> 
                : <div style={{textAlign:'center'}}>{t('athleteDashboard.noEvaluations')}</div>}
            </> : <div style={{textAlign:'center'}}><Spinner /></div>}
        </>
    );
};

export default AthleteCharts;
