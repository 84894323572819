import React from 'react';
import PropTypes from 'prop-types';
import styles from './AthleteList.module.scss';
import { Table } from 'components/template';
import TeamRow from 'components/TeamRow/TeamRow';
import NationalitiesRow from 'components/NationalitiesRow/NationalitiesRow';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ActiveFilters from '../ActiveFilters/ActiveFilters';
import moment from 'moment';

export default function AthleteList({
  activeFilters,
  countries,
  setActiveFilters,
  athletes,
}) {
  const rawData = athletes.data;
  const { t } = useTranslation();
  
  // prettier-ignore
  const positionMap = {
    'Atacante': t('positionOptions.Atacante'),
    'Ataque': t('positionOptions.Ataque'),
    'Centroavante': t('positionOptions.Centroavante'),
    'Zagueiro': t('positionOptions.Zagueiro'),
    'Defesa': t('positionOptions.Defesa'),
    'Goleiro': t('positionOptions.Goleiro'),
    'Meia': t('positionOptions.Meia'),
    'Volante': t('positionOptions.Volante'),
    'Lateral Direito': t('positionOptions.LateralDireito'),
    'Lateral Esquerdo': t('positionOptions.LateralEsquerdo'),
    'Meia-atacante': t('positionOptions.MeiaAtacante'),
    'Meio-campo': t('positionOptions.MeioCampo'),
  };

  let data = rawData
    ? rawData.map(row => {
        const data = moment(row.initial_date).format('DD/MM/YYYY');
        const position = (<b className={styles.position}>{positionMap[row.position]}</b>);
        const age = `${t('slider_menu.in')} ${row.min_age} ${t('slider_menu.e')} ${row.max_age} ${t('athlete_card.idade')}`;
        const profile = t(row.amateur ? 'listTranslation.Amateur' : 'compe.text26');
        const gender = t(row.female ? 'radioAdvancedSearch.female' : 'radioAdvancedSearch.male');
        const team = <TeamRow name={row.owner_team?.team_name} id={row.owner_team?.id} img={row.owner_team?.emblem} />;
        const teamDivision = t(`newDivisionOptions.${row.owner_team?.division ?? 'division'}`);
        const nationalities = (
          <span className={styles.flag}>
            <NationalitiesRow nationalities={[row.owner_team?.country ?? {}]} athleteId={row.id} />
          </span>
        );
        const type = t(`desiredNegotiationOptions.${row.negotiation_type}`);
        const moreInfo = (
          <div className={styles.buttonContainer}>
            <Link to={`/desired-athletes/${row.id}`}>
              <button className={styles.moreInfoButton}>
                {t('negotiations.moreInfo')}
              </button>
            </Link>
          </div>
        );

        return [
          data,
          position,
          age,
          profile,
          gender,
          team,
          teamDivision,
          nationalities,
          type,
          moreInfo
        ];
      })
    : [];

  if (athletes.data) {
    return (
      <div className={styles.container}>
        <h3 style={{margin: 0}}>{t('transferMarket.advancedSearch.desiredAthletes')}</h3>
        <div>{t('transferMarket.advancedSearch.subtitle')}</div>
        <div className={styles.topBar}>
          <ActiveFilters
            setActiveFilters={setActiveFilters}
            activeFilters={activeFilters}
            countries={countries}
          />
        </div>
        <Table
          flat
          theads={[              
            t('transferMarket.advancedSearch.postedIn'),
            t('transferMarket.advancedSearch.desiredPosition'),
            t('transferMarket.advancedSearch.age'),
            t('transferMarket.advancedSearch.profile'),
            t('transferMarket.advancedSearch.sex'),
            t('transferMarket.advancedSearch.club'),
            t('transferMarket.advancedSearch.clubSeries'),
            t('transferMarket.advancedSearch.country'),
            t('transferMarket.advancedSearch.business'),
            ''
          ]}
          data={data}
          className={styles.athleteTable}
        />
      </div>
    );
  }

  return '';
}

AthleteList.defaultProps = {
  hasFilter: false,
};

AthleteList.propTypes = {
  hasFilter: PropTypes.bool,
};
