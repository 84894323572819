import axios from 'api';
import Endpoints from 'endpoints';

export const actionTypes = {
  GET_PROFESSIONALS: 'professionals/GET',
  GET_PROFESSIONALS_SUCCESS: 'professionals/GET_SUCCESS',
  GET_PROFESSIONALS_ERROR: 'professionals/GET_ERROR',
  PATCH_PROFESSIONALS: 'professionals/PATCH',
  PATCH_PROFESSIONALS_SUCCESS: 'professionals/PATCH_SUCCESS',
  PATCH_PROFESSIONALS_ERROR: 'professionals/PATCH_ERROR',
};

const initialState = {
  professionals: {
    loading: false,
    error: null,
    data: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PROFESSIONALS:
      return {
        ...state,
        professionals: {
          ...initialState.professionals,
          loading: true,
        },
      };
    case actionTypes.GET_PROFESSIONALS_SUCCESS:
      return {
        ...state,
        professionals: {
          ...initialState.professionals,
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case actionTypes.GET_PROFESSIONALS_ERROR:
      return {
        ...state,
        professionals: {
          ...initialState.professionals,
          error: action.payload.error,
        },
      };

    default:
      return state;
  }
}

export const getProfessionals = () => async dispatch => {
  dispatch({ type: actionTypes.GET_PROFESSIONALS });

  const url = Endpoints.users;

  try {
    const { data } = await axios.get(`${url}?limit=10`);

    dispatch({
      type: actionTypes.GET_PROFESSIONALS_SUCCESS,
      payload: { data: data.results },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_PROFESSIONALS_ERROR,
      payload: { error: 'erros.text22' },
    });
  }
};

export const followProfessional = (id, body) => async dispatch => {
  dispatch({ type: actionTypes.PATCH_PROFESSIONALS });

  const url = Endpoints.users;

  try {
    const { data } = await axios.patch(`${url}/${id}`, body);

    dispatch({ type: actionTypes.PATCH_PROFESSIONALS_SUCCESS, payload: { data } });
  } catch (err) {
    dispatch({
      type: actionTypes.PATCH_PROFESSIONALS_ERROR,
      payload: { error: 'erros.text44' },
    });
  }
};
