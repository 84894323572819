import { useState } from 'react';

export default function useFilters() {
  const [filters, setFilters] = useState({
    athlete: '',
    messages: '',
    negotiation_type: '',
  });

  const handleFilterChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  return {
    filters,
    handleFilterChange,
  };
}
