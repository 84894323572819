import axios from 'api';
import Endpoints from 'endpoints';

export const Types = {
  GET: 'notifications/GET',
  GET_SUCCESS: 'notifications/GET_SUCCESS',
  GET_FAILED: 'notifications/GET_FAILED',
  ERROR_HANDLED: 'notifications/ERROR_HANDLED',
  UPDATE: 'notifications/UPDATE',
};

const initialState = {
  loading: false,
  update: false,
  error: null,
  data: {},
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET:
      return { ...state, loading: true, update: false };
    case Types.GET_FAILED:
      return { ...state, loading: false, update: false, error: action.payload.error };
    case Types.GET_SUCCESS:
      return { ...state, loading: false, update: false, data: action.payload.data };
    case Types.ERROR_HANDLED:
      return { ...state, error: null, update: false };
    case Types.UPDATE:
      return { ...state, update: true };
    default:
      return state;
  }
}

export const Creators = {
  loadNotificationsFromStorage: () => async dispatch => {
    const data = JSON.parse(localStorage.getItem('rdf_notifications') ?? '{}');
    dispatch({
      type: Types.GET_SUCCESS,
      payload: { data },
    });
  },
  getNotifications: () => async dispatch => {
    dispatch({ type: Types.GET });
    try {
      const { data } = await axios.get(Endpoints.notifications);
      localStorage.setItem('rdf_notifications', JSON.stringify(data));
      dispatch({
        type: Types.GET_SUCCESS,
        payload: { data },
      });
    } catch (err) {
      dispatch({
        type: Types.GET_FAILED,
        payload: { error: 'erros.text1' },
      });
    }
  },
  setNotification: data => dispatch => {
    localStorage.setItem('rdf_notifications', JSON.stringify(data));
    dispatch({
      type: Types.GET_SUCCESS,
      payload: { data },
    });
  },
  notifyUpdate: () => dispatch => {
    dispatch({ type: Types.UPDATE });
  },
  updateTransferMarketCount: add => dispatch => {
    const data = JSON.parse(localStorage.getItem('rdf_notifications') ?? '{}');
    data.newTransferMarketCount = data.newTransferMarketCount + add;
    data.newMessagesCount = data.newMessagesCount + add;
    data.newTransferMarketMessages = data.newTransferMarketCount > 0;
    dispatch(Creators.setNotification({ ...data }));
  },
  updateTeamchatCount: add => dispatch => {
    const data = JSON.parse(localStorage.getItem('rdf_notifications') ?? '{}');
    data.newTeamchatCount = data.newTeamchatCount + add;
    data.newMessagesCount = data.newMessagesCount + add;
    dispatch(Creators.setNotification({ ...data }));
  },
  updateOrgchatCount: add => dispatch => {
    const data = JSON.parse(localStorage.getItem('rdf_notifications') ?? '{}');
    data.newOrgchatCount = data.newOrgchatCount + add;
    data.newMessagesCount = data.newMessagesCount + add;
    dispatch(Creators.setNotification({ ...data }));
  },
  updateAthletechatCount: add => dispatch => {
    const data = JSON.parse(localStorage.getItem('rdf_notifications') ?? '{}');
    data.newAthletechatCount = data.newAthletechatCount + add;
    data.newMessagesCount = data.newMessagesCount + add;
    dispatch(Creators.setNotification({ ...data }));
  },
};
