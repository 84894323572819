import React from 'react';
import moment from 'moment';
import Variables from 'variables';
import * as S from './styles';
import { useTranslation } from 'react-i18next';

const NotesGroup = ({ notes, viewOnlyDay, setViewOnlyDay }) => {
    const { t } = useTranslation()
    const getNotesList = (notes) => {

        const notesList = []
        const groupedNotes = groupByDate(notes)

        for (let dateNote in groupedNotes) {

            notesList.push({
                date: groupedNotes[dateNote][0].date,
                count: groupedNotes[dateNote].length
            })
        }

        return notesList

    }

    const groupByDate = (notes) => {
        return notes.reduce((accum, x) => {
            (accum[x.date] = accum[x.date] || []).push(x);
            return accum;
        }, {});
    };

    if(notes.length === 0){
        return(
           <S.NoteGroupText>{t("rest.box23")}</S.NoteGroupText>
        )
    }

    return <>
        {getNotesList(notes).map(group => {
            const selectedDay = viewOnlyDay === group.date;
            return <S.NoteGroupItem 
                key={group.date} 
                onClick={() => {
                    if (selectedDay) {
                        setViewOnlyDay(null);
                    } else {
                        setViewOnlyDay(group.date);
                    }
                }}
                style={{backgroundColor: selectedDay ? Variables.lightGrey6 : Variables.white}}
            >
                <S.NoteGroupDate>
                    {moment(new Date(`${group.date}T00:00:00`)).format('DD/MM/YYYY')}
                </S.NoteGroupDate>
                <S.NoteGroupCounter>
                    {group.count} {t("athlete_card.anotacoes")}
                </S.NoteGroupCounter>
            </S.NoteGroupItem>
        })}
    </>;
}

export default NotesGroup;