import React from 'react';
import { Table, Card } from 'components/template';
import TeamRow from 'components/TeamRow/TeamRow';
import { divisionOptions } from '../../../shared/mocks';
import { useTranslation } from 'react-i18next';

import styles from './ClubList.module.scss';

export default function ClubList({ teams, pagination, setPagination }) {
  const { t } = useTranslation();
  const data = teams.data.map(row => {
    const team = <TeamRow className={styles.teamName} name={row.nickname} id={row.id} img={row.emblem} />;
    const country = (
      <img className={styles.countryFlag} src={row.country?.flag} alt={`Bandeira ${row.country?.name}`} />
    );
    // const city = row.city;
    const division = divisionOptions.find(option => option.value === String(row.division))?.label;
    const professionalSquad = row.counts?.athletes?.all ?? '';
    const marketValue = row.market_value ?? '';
    const stadium = row.stadium ?? '';
    // const seeProfessionalCastButton = (
    //     <Button smaller={true}>
    //         <Link className={styles.link} to={`/clube/${row.id}/elenco`}>
    //             Ver elenco
    //         </Link>
    //     </Button>);

    return [team, country, division, professionalSquad, marketValue, stadium];
  });

  return (
    <>
      <Card softShadow className={styles.foreignClubs}>
        <div className={styles.tableContainer}>
          <Table
            flat
            theads={[
              t('athlete_list.box2'),
              t('compe.text1'),
              t('organi.text12'),
              t('organi.text13'),
              t('organi.text14'),
              t('text.box19'),
            ]}
            data={data}
          />
        </div>
      </Card>
    </>
  );
}
