import React, { useState, useEffect, useRef } from 'react';
import styles from './Card.module.scss';
import { Spinner } from 'components/template';
import DashboardLogo from 'assets/ico/menu/dashboard.svg';
import { Menu } from 'components/template';
import Switch from 'react-switch';
import { useTranslation } from 'react-i18next';

const Card = ({title, subtitle, icon, bgColor, filters, total, tabs, bars, loading, onFiltersChange}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [cardFilters, setCardFilters] = useState({});
  const [maxValue, setMaxValue] = useState(0);
  const prevCardFiltersRef = useRef();

  // Calcula o maxValue para a aba ativa
  useEffect(() => {
    if (bars) {
      const maxForActiveTab = bars
        .filter(bar => bar?.tab === activeTab)
        .reduce((max, bar) => Math.max(max, bar.value), 0);
      setMaxValue(maxForActiveTab);
    }
  }, [bars, activeTab]);

  useEffect(() => {
    if (filters?.days_ago) {
      setCardFilters({ days_ago: filters.days_ago[0].value });
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (onFiltersChange && JSON.stringify(cardFilters) !== JSON.stringify(prevCardFiltersRef.current)) {
      onFiltersChange(cardFilters);
      prevCardFiltersRef.current = cardFilters;
    }
  }, [cardFilters, onFiltersChange]);

  return (
    <div className={styles.card}>
      <h3>{title}</h3>
      <div className={styles.info}>
        <div className={`${styles.icon} ${bgColor ? styles[bgColor] : ''}`}>
          <img src={icon ? icon : DashboardLogo} alt="Club Logo" />
        </div>
        <div className={styles.stats}>
          <span className={styles.number}>{loading ? '- -' : total}</span>
          <span className={styles.subtitle}>{subtitle}</span>
        </div>
      </div>
      <div className={styles.tabs}>
        {tabs && tabs.map((tab, index) => (
          <div
            key={index}
            className={activeTab === index ? styles.activeTab : styles.tab}
            onClick={() => setActiveTab(index)}
          >
            {t('dashboard.' + tab)}
          </div>
        ))}
      </div>

      <div className={styles.tabContent}>
        {loading ? (
          <div className={styles.loader}>
            <Spinner />
          </div>
        ) : (
          <>
            {filters?.boolean && 
              <div className={styles.switchContainer}>
                <Switch
                  checked={cardFilters[filters?.boolean?.fieldName] || false}
                  className={styles.switch}
                  onChange={() => setCardFilters({ ...cardFilters, [filters.boolean.fieldName]: !cardFilters[filters.boolean.fieldName], isApplyButton: true })}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#5A00FF"
                  offColor="#ffffff"
                  offHandleColor="#5A00FF"
                  onHandleColor="#ffffff"
                  handleDiameter={16}
                  height={20}
                  width={42}
                />
                <span>{filters.boolean?.label}</span>
              </div>
            }
            {filters?.days_ago && 
              <Menu
                title={t('proj.filtrarPor')}
                className={styles.select}
                options={filters.days_ago}
                value={cardFilters.days_ago}
                setValue={value => setCardFilters({ ...cardFilters, days_ago: value, isApplyButton: true })}
                onClear={() => setCardFilters({ ...cardFilters, days_ago: '', isApplyButton: true })}
              />
            }
            {bars && bars.map((bar, index) => (bar?.tab === activeTab && (
              <div key={index} className={styles.barContent}>
                <div className={styles.label}>
                  {bar.label.includes('_') ? t('dashboard.' + bar.label) : bar.label}
                </div>
                <div className={styles.progressBarContainer}>
                  <div className={`${styles.progressBar} ${bar?.max ? styles.bgGrey : ''}`}>
                    {bar?.max ? (
                      <div
                        className={styles.progress}
                        style={{ width: `${(bar.value / (bar.max || 20)) * 100}%` }}
                      />
                    ) : (
                      <div
                        className={styles.progress}
                        style={{ width: `${(bar.value / maxValue) * 100}%` }}
                      />
                    )}
                  </div>
                  <span>{bar.value}{bar?.max && '/' + bar.max}</span>
                </div>
              </div>
            )))}
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
