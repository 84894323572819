import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './SideInfo.module.scss';
import { SocialButton } from 'components/template';
import { useTranslation } from 'react-i18next';
import SideInfoAgency from 'pages/Athlete/Overview/SideInfo/SideInfoAgency.js';
import SideBarReportsPro from './ReportsPro';
import { useSelector } from 'react-redux';

export default function SideInfo({ data }) {
  const { t } = useTranslation();
  const athleteId = data.athlete.id;
  const user = useSelector(state => state.user.data);

  // prettier-ignore
  const positionMap = {
    'Atacante': t('positionOptions.Atacante'),
    'Ataque': t('positionOptions.Ataque'),
    'Centroavante': t('positionOptions.Centroavante'),
    'Zagueiro': t('positionOptions.Zagueiro'),
    'Defesa': t('positionOptions.Defesa'),
    'Goleiro': t('positionOptions.Goleiro'),
    'Meia': t('positionOptions.Meia'),
    'Volante': t('positionOptions.Volante'),
    'Lateral Direito': t('positionOptions.LateralDireito'),
    'Lateral Esquerdo': t('positionOptions.LateralEsquerdo'),
    'Meia-atacante': t('positionOptions.MeiaAtacante'),
    'Meio-campo': t('positionOptions.MeioCampo'),
  };

  const athleteData = data.athlete;
  let avgEvaluation = null;
  if (data.evaluations.length > 0) {
    avgEvaluation = 0;
    data.evaluations.forEach(_eval => (avgEvaluation += _eval.general_evaluation));
    avgEvaluation /= data.evaluations.length;
  }

  const nationalities = athleteData?.nationalities?.length
    ? athleteData.nationalities.map(item => (
      <img src={item.flag} alt={item.name} className={styles.country} draggable="false" />
    ))
    : '';

  const emblem_current_team = athleteData?.current_team?.emblem ? (
    <img src={athleteData?.current_team?.emblem} alt={''} />
  ) : (
    ''
  );
  const emblem_src_team = athleteData?.src_team?.emblem ? <img src={athleteData?.src_team?.emblem} alt={''} /> : '';

  return (
    <div className={styles.container}>
      <h1>{t('transferMarket.athleteInfo')}</h1>
      <div className={styles.info}>
        <div className={styles.images_display}>
          <SideBarReportsPro athleteId={athleteId} />
        </div>
        <div className={styles.detailedInfo}>
          <tr cellspacing="10">
            <td>{t('athlete_card.fullname')} </td>
            <td>{athleteData?.fullname}</td>
          </tr>
          <tr>
            <td>{t('athlete_card.birth')} </td>
            <td>{athleteData.birthdate ? moment(athleteData.birthdate).format('DD/MM/YYYY') : t('club.text12')}</td>
          </tr>
          <tr>
            <td>{t('athlete_list.box3')}: </td>
            <td>{athleteData.age ? athleteData.age + t('athlete_card.idade') : t('club.text12')}</td>
          </tr>
          <tr>
            <td>{t('club.text13')}: </td>
            {athleteData.height ? <td>{athleteData.height} cm</td> : <td>{t('club.text12')}</td>}
          </tr>
          <tr>
            <td>{t('club.text14')}:</td>
            {athleteData.weight ? <td>{athleteData.weight} kg</td> : <td>{t('club.text12')}</td>}
          </tr>
          <tr>
            <td>{t('athlete_card.pos')}</td>
            <td>{positionMap[athleteData.position] ?? t('club.text12')}</td>
          </tr>
          <tr>
            <td>{t('athlete_card.foot')}</td>
            <td>{t(`preferentialFoot.${athleteData.foot}`) ?? t('club.text12')}</td>
          </tr>
          {/*
          <tr>
            <td>{t('overview.text27')}:</td>
            <td>{athleteData.career_stats?.total_goals ?? t('club.text12')}</td>
          </tr>
          <tr>
            <td>{t('overview.text28')}:</td>
            <td>{athleteData.career_stats?.total_matches ?? t('club.text12')}</td>
          </tr>
            */}
          <tr>
            <td>{t('athlete_card.nation')}:</td>
            <td>
              {nationalities} {athleteData?.nationalities[0]?.name ?? t('club.text9')}
            </td>
          </tr>
          <tr>
            <td>{t('agencies.text2')}:</td>
            <td>
              {emblem_current_team} {athleteData?.current_team?.nickname ?? t('transferMarket.withoutClub')}
            </td>
          </tr>
          {athleteData?.current_team != null && (
            <tr>
              <td>{t('myClub.professionalCast.contractUntil')}:</td>
              <td>
                {athleteData?.contract_info?.current_contract_end
                  ? moment(athleteData?.contract_info?.current_contract_end).format('DD/MM/YYYY')
                  : t('club.text12')}
              </td>
            </tr>
          )}
          {athleteData?.src_team != null && (
            <tr>
              <td>{t('agencies.text4')}:</td>
              <td>
                {emblem_src_team} {athleteData?.src_team?.nickname ?? t('club.text12')}
              </td>
            </tr>
          )}
          {athleteData?.contract_info?.src_contract_end != null && (
            <tr>
              <td>{t('filters.contract_until')}:</td>
              <td>{moment(athleteData.contract_info.src_contract_end).format('DD/MM/YYYY')}</td>
            </tr>
          )}
          {/*
          <tr>
            <td>{t('GBE')}:</td>
            <td>?????</td>
          </tr>
          <tr>
            <td>{t('organi.text14')}:</td>
            <td>{athleteData.market_value.current?.value} M €</td>
          </tr>
          {athleteData?.market_value?.greatest && (
            <tr>
              <td>{t('overview.text29')}: </td>
              <td>
                {athleteData?.market_value?.greatest.value} M € {t('overview.text30')}{' '}
                {moment(athleteData?.market_value?.greatest.date).format('DD/MM/YYYY')}
              </td>
            </tr>
          )}
          <tr>
            <td>Valuation Footure:</td>
            <td>
              <a
                draggable="false"
                href={`https://api.whatsapp.com/send?phone=${t('whatsapp.phone')}&text=${t(
                  'whatsapp.valuation1'
                )}${athleteData?.fullname ?? ''}${t('whatsapp.valuation2')}${athleteData?.current_team?.nickname ??
                athleteData?.current_team?.longname ??
                ''}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.marketValueSpan}>{t('athlete_card.buyValuation')}</div>
              </a>
            </td>
          </tr>
          */}
          <div className={styles.socialButtons}>
            {/* {athleteData.wikipedia ? <SocialButton large type='wikipedia' href={athleteData.wikipedia} /> : null} */}
            <SocialButton large type="wikipedia" href={athleteData.wikipedia} />
            {/* {athleteData.instagram ? <SocialButton large type='instagram' href={athleteData.instagram} /> : null} */}
            <SocialButton large type="instagram" href={athleteData.instagram} />
            {/* {athleteData.twitter ? <SocialButton large type='twitter' href={athleteData.twitter} /> : null} */}
            <SocialButton large type="twitter" href={athleteData.twitter} />
            {/* {athleteData.youtube ? <SocialButton large type='youtube' href={athleteData.youtube} /> : null} */}
            <SocialButton large type="youtube" href={athleteData.youtube} />
          </div>
          <SideInfoAgency data={athleteData} user={user} />
        </div>
      </div>
    </div >
  );
}

SideInfo.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};
