/*eslint-disable eqeqeq*/
import React, { useState, useEffect, useRef } from 'react';
import CustomCollapse from './CustomCollapse/CustomCollapse';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash/lang';
import styles from './Filter.module.scss';
import { Card, Spinner, Input } from 'components/template';
import { getCountries } from 'store/ducks/countries';
import { useLocation } from 'react-router-dom';
import {
  positionOptions,
  checkboxesAdvancedSearch,
  radioAdvancedSearch,
} from '../../../../shared/mocks';
import CheckboxContainer from './CheckboxContainer/CheckboxContainer';
import RadioContainer from './RadioContainer/RadioContainer';
import { MinMaxDate } from 'pages/AdvancedSearch/Filter/CustomInputs/CustomInputs';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import { Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { moneyStringToNumber, formatMoneyString, debounce } from 'shared/utility';

export default function Filter({
  desiredTransferMarket,
  setQueryFilters,
  onSetQueryFilters,
  activeFilters,
  overlay,
  autoSearch,
  className,
}) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const location = useLocation();
  const thisYear = new Date().getFullYear();

  const countries = useSelector(state => state.countries.countries);

  const [loadingState, setLoadingState] = useState(true);

  const initialFilters = {
    position: [],
    nationalities: [],
    foot: '',
    gender: 'male',
    negotiation_type: [],
    min_age: '',
    max_age: '',
    sale: {
      min: '',
      max: '',
    },
    salary: {
      min: '',
      max: '',
    },
  };

  const mounted = useRef(false);

  const [filters, setFilters] = useState(location?.state?.queryFilters || initialFilters);

  useEffect(() => {
    const filtersFromActive = { ...initialFilters, ...activeFilters };

    if (!isEqual(filtersFromActive, filters)) {
      setFilters(filtersFromActive);
    }
  }, [activeFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoadingState(countries.loading);
  }, [countries.loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (countries.data.length === 0 && !countries.loading) {
      dispatch(getCountries());
    }
  }, [dispatch, getCountries, countries.data.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const now = new Date();
    if (!mounted.current) {
      mounted.current = now;

      let parentQueryFilters = location?.state?.queryFilters;
      if (parentQueryFilters == null) {
        parentQueryFilters = initialFilters;
      }
      if (parentQueryFilters) {
        onSetFilters({ ...initialFilters, ...parentQueryFilters });
      }
    } else if (now.getTime() - mounted.current.getTime() > 500) {
      if (autoSearch || (filters.isApplyButton ?? false)) {
        setQueryFilters(queryFiltersParser(filters));
      }
    }
  }, [setQueryFilters, filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSetFilters = newFilters => {
    const isApplyButton = newFilters.isApplyButton ?? false;
    if (isApplyButton || !isEqual(newFilters, filters)) {
      setFilters(newFilters);
      if (autoSearch || isApplyButton) {
        onSetQueryFilters({ ...newFilters });
      }
    }
  };

  const negotiationFilterParser = arr => {
    if (arr.length === 1) {
      if (arr[0] === 'SALE') {
        return 'SALE';
      } else if (arr[0] === 'LOAN') {
        return 'LOAN';
      }
    }
    return null;
  };

  const queryFiltersParser = filters => {
    const params = {
      transfermarket: filters.transfer_market,
      position: filters.position,
      nationalities: filters.nationalities,
      foot: filters.foot,
      female: filters.gender == 'female',
      min_age: filters.max_age ? thisYear - filters.max_age : null,
      max_age: filters.min_age ? thisYear - filters.min_age : null,
      min_annual_salary: filters.salary.min,
      max_annual_salary: filters.salary.max,
      min_purchase_value: filters.sale.min,
      max_purchase_value: filters.sale.max,
      negotiation_type: negotiationFilterParser(filters.negotiation_type.map(item => item)),
    };

    return params;
  };

  const useStyles = makeStyles({
    root: {
      color: '#5A00FF',
      height: 35,
      '& .MuiSlider-rail': {
        height: 3,
      },
      '& .MuiSlider-track': {
        height: 3,
      },
      '& .MuiSlider-thumb': {
        color: '#ffffff',
        width: 20,
        height: 20,
        marginTop: -9,
        border: '4px solid #5A00FF',
      },
    },
  });

  const saleValueNullCheck = desiredTransferMarket?.max_purchase_value;
  const salaryValueNullCheck = desiredTransferMarket?.max_salary_value;

  const classes = useStyles();
  const [sliderSaleValue, setSliderSaleValue] = useState([0, saleValueNullCheck]);
  const [sliderSalaryValue, setSliderSalaryValue] = useState([0, salaryValueNullCheck]);

  const [inputSaleValueMin, setInputSaleValueMin] = useState('0');
  const [inputSaleValueMax, setInputSaleValueMax] = useState('0');
  const [inputSalaryValueMin, setInputSalaryValueMin] = useState('0');
  const [inputSalaryValueMax, setInputSalaryValueMax] = useState('0');
  const [defaultIsSet, setDefaultIsSet] = useState(false);

  useEffect(() => {
    if (saleValueNullCheck && !defaultIsSet) {
      setDefaultIsSet(true);
      setSliderSaleValue([0, saleValueNullCheck]);
      setSliderSalaryValue([0, salaryValueNullCheck]);
      setInputSaleValueMax(saleValueNullCheck.toString());
      setInputSalaryValueMax(salaryValueNullCheck.toString());
    }
  }, [saleValueNullCheck, salaryValueNullCheck, defaultIsSet]);

  const onSliderSellChange = (event, newValues) => {
    setSliderSaleValue(newValues);
    debounce(onSetFilters)({ ...filters, sale: { min: newValues[0], max: newValues[1] } });
  };

  function onSliderSalaryChange(event, newValues) {
    setSliderSalaryValue(newValues);
    debounce(onSetFilters)({ ...filters, salary: { min: newValues[0], max: newValues[1] } });
  }

  let content;
  content = (
    <div>
      {overlay && <div className={styles.overlay} />}
      <Card borderedGrey className={className}>
        <Scrollbars
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                width: '10px',
                height: '165px !important',
                borderRadius: '5px',
                backgroundColor: '#D7D6D2',
                right: '5px',
              }}
            />
          )}
          renderThumbHorizontal={({ style, ...props }) => (
            <div {...props} style={{ ...style, borderRadius: '5px', backgroundColor: '#D7D6D2' }} />
          )}
        >
          {loadingState ? (
            <div className={styles.loader}>
              <Spinner />
            </div>
          ) : (
            <>
              <CustomCollapse openedDefault title={t('search.typeNegotiation')}>
                <CheckboxContainer
                  setFilter={value => {
                    onSetFilters({ ...filters, negotiation_type: value, isApplyButton: false });
                  }}
                  value={filters.negotiation_type || null}
                  checkboxes={checkboxesAdvancedSearch.negotiation_type.map(item => ({
                    label: t(`desiredNegotiationOptions.${item.value}`),
                    value: item.value,
                  }))}
                />
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('filters.gender')}>
                <RadioContainer
                  setFilter={value => onSetFilters({ ...filters, gender: value, isApplyButton: false })}
                  value={filters.gender || 'male'}
                  options={radioAdvancedSearch.gender.map(item => ({
                    label: t(`radioAdvancedSearch.${item.value}`),
                    value: item.value,
                  }))}
                  name={t('filters.gen')}
                />
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('search.valueForPurchase')}>
                <div className={styles.slideBar}>
                  <div className={styles.spanValue}>
                    <span>
                      €{formatMoneyString(inputSaleValueMin)}- €{formatMoneyString(inputSaleValueMax)}
                    </span>
                  </div>
                  <div className={classes.root}>
                    <Slider
                      value={sliderSaleValue}
                      onChange={(event, newValues) => {
                        onSliderSellChange(event, newValues);
                        setInputSaleValueMin(newValues[0].toString());
                        setInputSaleValueMax(newValues[1].toString());
                      }}
                      valueLabelDisplay="off"
                      min={0}
                      max={desiredTransferMarket?.max_purchase_value}
                      className={classes.slider}
                    />
                  </div>
                  <div
                    className={styles.sliderInput}
                    style={{ display: 'flex', height: '50px', justifyContent: 'space-between' }}
                  >
                    <Input
                      inputClassName={styles.sellMin}
                      value={inputSaleValueMin}
                      prefix="€ "
                      type="currency"
                      onValueChange={(value, name) => {
                        setInputSaleValueMin(value);
                        onSliderSellChange(null, [moneyStringToNumber(value), sliderSaleValue[1]]);
                      }}
                    />
                    <Input
                      inputClassName={styles.sellMax}
                      value={inputSaleValueMax}
                      prefix="€ "
                      type="currency"
                      onValueChange={(value, name) => {
                        setInputSaleValueMax(value);
                        onSliderSellChange(null, [sliderSaleValue[0], moneyStringToNumber(value)]);
                      }}
                    />
                  </div>
                </div>
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('search.salary')}>
                <div className={styles.slideBar}>
                  <div className={styles.spanValue}>
                    <span>
                      €{formatMoneyString(inputSalaryValueMin)}- €{formatMoneyString(inputSalaryValueMax)}
                    </span>
                  </div>
                  <div className={classes.root}>
                    <Slider
                      value={sliderSalaryValue}
                      onChange={(event, newValues) => {
                        onSliderSalaryChange(event, newValues);
                        setInputSalaryValueMin(newValues[0].toString());
                        setInputSalaryValueMax(newValues[1].toString());
                      }}
                      valueLabelDisplay="off"
                      min={0}
                      max={desiredTransferMarket?.max_salary_value}
                      className={classes.slider}
                    />
                  </div>
                  <div
                    className={styles.sliderInput}
                    style={{ display: 'flex', height: '50px', justifyContent: 'space-between' }}
                  >
                    <Input
                      inputClassName={styles.sellMin}
                      value={inputSalaryValueMin}
                      prefix="€ "
                      type="currency"
                      onValueChange={(value, name) => {
                        setInputSalaryValueMin(value);
                        onSliderSalaryChange(null, [moneyStringToNumber(value), sliderSalaryValue[1]]);
                      }}
                    />
                    <Input
                      inputClassName={styles.sellMax}
                      value={inputSalaryValueMax}
                      prefix="€ "
                      type="currency"
                      onValueChange={(value, name) => {
                        setInputSalaryValueMax(value);
                        onSliderSalaryChange(null, [sliderSalaryValue[0], moneyStringToNumber(value)]);
                      }}
                    />
                  </div>
                </div>
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('filters.pos')}>
                <CheckboxContainer
                  setFilter={value => onSetFilters({ ...filters, position: value, isApplyButton: false })}
                  value={filters.position || null}
                  checkboxes={positionOptions.map(item => ({
                    label: t(`positionOptions.${item.value}`),
                    value: item.label,
                  }))}
                />
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('filters.nation')}>
                <CheckboxContainer
                  setFilter={value => onSetFilters({ ...filters, nationalities: value, isApplyButton: false })}
                  value={filters.nationalities || null}
                  checkboxes={countries.data.map(country => ({ label: country.name, value: country.code }))}
                />
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('filters.birt_year')}>
                <div className={styles.minMaxDateChildren}>
                  <MinMaxDate
                    from
                    title={t('home.box4')}
                    titlePlaceholder={
                      (filters?.min_age ?? '').toString().length > 0 ? filters.min_age : '---'
                    }
                    setFilter={values =>
                      onSetFilters({
                        ...filters,
                        min_age: values.min,
                        max_age: values.min,
                        isApplyButton: false,
                      })
                    }
                    onClear={() => onSetFilters({ ...filters, min_age: '', max_age: '' })}
                    valueMin={filters.min_age}
                    valueMax={filters.max_age}
                  />
                </div>
              </CustomCollapse>
            </>
          )}
        </Scrollbars>
      </Card>
    </div>
  );

  return <div className={styles.container}>{content}</div>;
}
