import { combineReducers } from 'redux';

import user from './user';
import organization from './organization';
import layout from './layout';
import searchBar from './searchBar';
import notifications from './notifications';
import chat from './chat';
import countries from './countries';
import monitoring from './monitoring';
import monitoringV2 from './monitoringV2';
import athlete from './athlete';
import athleteV2 from './athleteV2';
import teams from './teams';
import club from './club';
import athleteReport from './athleteReport';
import advancedSearch from './advancedSearch';
import nationalClubs from './nationalClubs';
import competition from './competition';
import reports from './reports';
import evaluations from './evaluations';
import evaluationsStats from './evaluationsStats';
import transferWindows from './transferWindows';
import agencies from './agencies';
import athleteNotes from './athleteNotes';
import home from './home';
import project from './projects';
import professionals from './professionals';
import connections from './connections';
import transferAthletes from './transferAthletes';
import transferMarketTeams from './transferMarketTeams';
import myClubNegotiations from './myClubNegotiations';
import teamsHistorical from './teamsHistorical';
import myClub from './myClub';
import originals from './originals';
import dashboardTeams from './dashboardTeams';
import dashboardTransferMarket  from './dashboardTransferMarket';
import dashboardMarketAnalysis from './dashboardMarketAnalysis';
import dashboardChat from './dashboardChat';

const reducers = combineReducers({
  originals,
  user,
  organization,
  layout,
  searchBar,
  notifications,
  chat,
  countries,
  monitoring,
  monitoringV2,
  athlete,
  athleteV2,
  teams,
  club,
  athleteReport,
  advancedSearch,
  nationalClubs,
  competition,
  reports,
  evaluations,
  evaluationsStats,
  transferWindows,
  agencies,
  athleteNotes,
  project,
  home,
  professionals,
  connections,
  transferAthletes,
  transferMarketTeams,
  myClubNegotiations,
  teamsHistorical,
  myClub,
  dashboardTeams,
  dashboardTransferMarket,
  dashboardMarketAnalysis,
  dashboardChat,
});

export default reducers;
