import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const DonutChart = (data) => {
  const [options, setOptions] = useState({
    labels: [],
    dataLabels: {
        enabled: false,
    },
    legend: {
        position: 'bottom',
    },
    tooltip: {
        enabled: true,
        theme: 'dark',
        y: {
        formatter: (value) => `${value}`,
        },
    },
    responsive: [
        {
            breakpoint: 600,
            options: {
            chart: {
                width: 300,
            },
            legend: {
                position: 'bottom',
            },
            },
        },
    ],      
  });
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if(data?.data){
      const dataWithoutConunter = Object.entries(data.data).filter(([key, _]) => key !== 'total_count_evaluations');
      const labels  = dataWithoutConunter.map(([key, _]) => key);
      setOptions({...options, labels});
      const series = dataWithoutConunter.map(([_, value]) => value);
      setSeries(series);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {data &&
        <Chart options={options} series={series} type="donut" height={350} />
      }
    </div>
  );
};

export default DonutChart;
