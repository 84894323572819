import React from 'react';
import PropTypes from 'prop-types';

import styles from './ImageList.module.scss';
import { FaRegTrashAlt } from 'react-icons/fa';
import { Input, IconButton } from 'components/template';

export default function ImageList({ images, setImagesFileInputValue, setModifiedAthlete }) {
  const setTitle = (id, val) => {
    let currentImage = images.find(el => el.id === id);
    let copy = { ...currentImage };
    let prevState = [...images];
    const index = prevState.indexOf(currentImage);

    copy.title = val;
    prevState[index] = copy;

    setImagesFileInputValue(prevState);
    setModifiedAthlete(f => ({ ...f, files: prevState }));
  };

  const removeItem = id => {
    let filteredArray = images.filter(el => el.id !== id);
    setImagesFileInputValue(filteredArray);
    setModifiedAthlete(f => ({ ...f, files: filteredArray }));
  };

  const imageList = images.map(item => (
    <div className={styles.imageItem} key={item.id}>
      <IconButton
        className={styles.removeImage}
        color="vividGreen"
        icon={<FaRegTrashAlt className={styles.icon} size={14} />}
        onClick={() => removeItem(item.id)}
      />
      <img className={styles.image} src={item.file} alt="" />
      <Input
        value={item.title}
        containerClassName={styles.input}
        placeholder="Título"
        onChange={e => setTitle(item.id, e.target.value)}
        rounded
      />
    </div>
  ));

  let content = <div className={styles.imageList}>{imageList}</div>;

  return <>{content}</>;
}

ImageList.propTypes = {
  images: PropTypes.instanceOf(Object).isRequired,
  setImagesFileInputValue: PropTypes.func.isRequired,
  setModifiedAthlete: PropTypes.func.isRequired,
};
