import React from 'react';
import moment from 'moment';
import { FaFile, FaPlayCircle } from 'react-icons/fa';
import styles from './Match.module.scss';
import { Avatar } from 'components/template';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export default function Match({ match, teamLink }) {
    const { t } = useTranslation();
    const home_team = match?.home_team ?? {};
    const visiting_team = match?.visiting_team ?? {};
    let home_team_scoreboard = '';
    let visiting_team_scoreboard = '';
    if (match?.scoreboard) {
        let scoreboard = (match?.scoreboard ?? '');
        if (scoreboard === '99 - 99') {
            //o 99-99 é um padrão utilizado para definir que a partida não ocorreu ainda, e portanto deve ser exibido só um -
            home_team_scoreboard = '-';
            visiting_team_scoreboard = '-';
        } else {
            if (scoreboard.includes('(')) {
                //caso de penalti, scoreboard vai vir "1-1(5-6)"
                scoreboard = scoreboard.replace(')', '').split('(');
                let penalti_scoreboard = scoreboard[1]; //pega somente o placar dos penaltis, "5-6" nesse caso
                scoreboard = scoreboard[0]; //pega somente o placar normal, "1-1" nesse caso
                penalti_scoreboard = penalti_scoreboard.split('-');
                home_team_scoreboard = ` (${penalti_scoreboard[0]})`;
                visiting_team_scoreboard = ` (${penalti_scoreboard[1]})`;
            }
            //após o if acima, podemos garantir que o scoreboard vai estar "normal", com o placar 1-1 por exemplo 
            scoreboard = scoreboard.split('-');
            home_team_scoreboard = scoreboard[0] + home_team_scoreboard;
            visiting_team_scoreboard = scoreboard[1] + visiting_team_scoreboard;
        }
    }

    const matchTitle = `
        ${match?.match_date ? moment(match?.match_date).format("DD/MM/YYYY") : ''}
        ${match?.match_hour ? ` - ${match?.match_hour}` : ''}
        ${match?.city ? ` - ${match?.city}` : ''}
    `.trim();

    return <div className={styles.matchItem}>
        <div className={styles.matchItemInfo}>
            {matchTitle.length > 0 && (
                <div className={styles.title}>{matchTitle}</div>
            )}
            <div className={styles.avatar}>
                <Avatar
                    noBorder
                    noRadius
                    img={home_team.emblem}
                    name={home_team.nickname}
                />
                <Link to={teamLink.replace('$id', home_team.id)}>
                    {home_team.nickname}
                </Link>
                <span className={styles.score}>{home_team_scoreboard}</span>
            </div>
            <div className={styles.avatar}>
                <Avatar
                    noBorder
                    noRadius
                    img={visiting_team.emblem}
                    name={visiting_team.nickname}
                />
                <Link to={teamLink.replace('$id', visiting_team.id)}>
                    {visiting_team.nickname}
                </Link>
                <span className={styles.score}>{visiting_team_scoreboard}</span>
            </div>
        </div>
        <div className={styles.bottomLinks}>
            {match?.match_url && <>
                <FaFile />
                <a href={match.match_url} target="_blank" rel="noopener noreferrer" className={styles.link}>{t("compe.text14")}</a>
            </>}
            {match?.match_video && <>
                <FaPlayCircle />
                <a href={match.match_video} target="_blank" rel="noopener noreferrer" className={styles.link}>{t("compe.text15")}</a>
            </>}
        </div>
    </div>
}