// hooks
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// scss
import styles from './SideInfoAgency.module.scss';

// icons
import { IoIosWarning } from 'react-icons/io';
import { MdVerified, MdAddCircle, MdPersonRemove } from 'react-icons/md';
import { BsChatLeftTextFill } from 'react-icons/bs';

// components
import AgencyDialog from './AgencyDialog';
import { Button, IconButton } from 'components/template';

// other
import PropTypes from 'prop-types';
import { AthleteChatRepository } from 'data/athleteChat';
import { Creators as layoutCreators } from 'store/ducks/layout';
import { useDispatch } from 'react-redux';

export default function SideInfoAgency({ data, user }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const userAgencyId = user.organization?.type === 'agency' ? user.organization.id.id : null;

  if (data.custom_athlete ?? false) {
    return '';
  }

  return (
    <div className={styles.agencyContent}>
      <div>
        <h5>{t('club.text11')}:</h5>
        <span style={{ position: 'relative', zIndex: '1', display: 'block', width: 'calc(100% - 130px)' }}>
          {data.agency ? (
            <>
              <span
                className={styles.agencyName}
                onClick={() => {
                  history.push(`/agencias/${data.agency.id}/atletas`);
                }}
              >
                {data.agency.name}
                {data.agency.verified && <MdVerified className={styles.agencyButtonIco} />}
              </span>
              {data.org_has_mensageria_access && user?.organization?.id?.id && data.agency.id !== user.organization.id.id && (
                <IconButton
                  className={styles.agent_message}
                  text={t('chat.talk_to_agent')}
                  icon={<BsChatLeftTextFill />}
                  onClick={async () => {
                    const response = await AthleteChatRepository.post({
                      sender_org: user.organization.id.id,
                      receiver_org: data.agency.id,
                      athlete: data.id,
                    });
                    if (response.error && response.status === 402) {
                      dispatch(layoutCreators.showExcededPlanLimit());
                    } else {
                      history.push(`/mensagens/atletas/${response.id}`);
                    }
                  }}
                />
              )}
            </>
          ) : (
            <span
              className={styles.withoutAgency}
              onClick={() => {
                if (userAgencyId != null) {
                  setIsOpen(true);
                }
              }}
            >
              {t('athlete_card.without_agency')}
              {userAgencyId != null && <MdAddCircle className={styles.agencyButtonIco} />}
            </span>
          )}
        </span>
        {data.agency && (
          <div className={styles.agencyButton}>
            <Button className={styles.button} rounded smaller color="green" onClick={() => setIsOpen(true)}>
              {userAgencyId === data.agency.id ? (
                <>
                  <MdPersonRemove className={styles.ico} />
                  {t('myClub.users.remove')}
                </>
              ) : (
                <>
                  <IoIosWarning className={styles.ico} />
                  {t('overview.text24')}
                </>
              )}
            </Button>
          </div>
        )}
      </div>
      <AgencyDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        userAgencyId={userAgencyId}
        athleteAgency={data.agency}
        athleteId={data.id}
      />
    </div>
  );
}

SideInfoAgency.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};
