import React from 'react';
import PropTypes from 'prop-types';
import styles from './InputNumber.module.scss';
import Input from '../Input/Input';
import { Button } from 'components/template';

const InputNumber = ({value, maxLength, showButton, onChange, ...props }) => {
  const handleKeyDown = (e) => {
    const { key, target } = e;
    const { value, selectionStart, selectionEnd } = target;

    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
    if (allowedKeys.includes(key)) {
        return;
    }

    if (!/^[0-9]$/.test(key)) {
        e.preventDefault();
        return;
    }
    
    const isTextSelected = selectionStart !== selectionEnd;

    if (value.length >= maxLength && !isTextSelected) {
        e.preventDefault();
    }  
  };

  const handleChange = (e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, '');
    console.log('teste', newValue);
    if(newValue.length > maxLength) {
      console.log('entrou aqui', newValue.toString().slice(0, maxLength))
      onChange(newValue.toString().slice(0, maxLength));
    }else{
      onChange(newValue);
    }
  };

  const increment = () => {
    const newValue = (parseInt(value || '0', 10) + 1).toString();
    if(newValue.toString().length <= maxLength) {
      onChange(newValue);
    }
  };

  const decrement = () => {
    if(value){
      if(value > 0){
        const newValue = (parseInt(value || '0', 10) - 1);        
        onChange(newValue);
      }      
    }else {
      onChange(0);
    }
  };

  return (
    <div className={styles.inputNumberContainer}>
      {showButton && <Button onClick={() => decrement()} className={styles.button} color="white" rounded>-</Button>}
      <Input
        type="text"
        value={value}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        className={showButton ? styles.withButtons : styles.input}
        containerStyle={{ width: 'auto' }}
        {...props}
      />
      {showButton && <Button onClick={() => increment()} className={styles.button} color="white" rounded>+</Button>}
    </div>
  );
};

InputNumber.propTypes = {
  maxLength: PropTypes.number.isRequired,
  showButton: PropTypes.bool,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
};

InputNumber.defaultProps = {
  showButton: false,
  value: '',
};

export default InputNumber;

