import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Aside.module.scss';
import { FaRegTrashAlt, FaSearch, FaRedoAlt } from 'react-icons/fa';
import { reportCheckboxes } from 'shared/mocks';
import { Checkbox, AthleteAvatar, Input, IconButton, Spinner, TextArea } from 'components/template';
import ResultCard from './ResultCard/ResultCard';
import { useTranslation } from 'react-i18next';
import { Creators } from 'store/ducks/searchBar';
import orderShadowTeamPositions from 'pages/Project/Workflow/ShadowTeam/ShadowTeamLineUp/helpers';

export default function Aside({
  data,
  orderedAthletesId,
  setConfiguration,
  configuration,
  reportData,
  setReportData,
  addAthlete,
  removeAthlete,
  updateAthlete,
}) {
  const mounted = useRef(false);
  const dispatch = useDispatch();
  const searchBar = useSelector(state => state.searchBar);
  const [searchValue, setSearchValue] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);
  const [resultIsVisible, setResultIsVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (mounted.current) {
      setResultIsVisible(true);
    } else {
      mounted.current = true;
    }
  }, [searchBar.data]);

  const handleSearchChange = value => {
    clearTimeout(timeoutId);
    setSearchValue(value);

    if (value !== '') {
      setTimeoutId(
        setTimeout(() => {
          if (value !== '' && value.length >= 3) {
            dispatch(Creators.search(value, false));
          }
        }, 800)
      );
    } else {
      setResultIsVisible(false);
    }
  };

  const removeAthleteHandle = id => {
    if (data.length > 1) {
      removeAthlete(id);
    }
  };

  const handleClickItem = id => {
    setSearchValue('');
    addAthlete(id);
    setResultIsVisible(false);
  };

  const searchIconClickHandler = useCallback(() => {
    if (searchValue.trim()) dispatch(Creators.search(searchValue, false));
  }, [dispatch, searchValue]);

  function setChecked(e) {
    e.persist();
    setConfiguration(f => ({ ...f, [e.target.value]: e.target.checked }));
  }

  const checkboxList = reportCheckboxes.map(item => {
    return (
      <li key={item.value} className={styles.checkboxItem}>
        <Checkbox
          checked={configuration[item.value]}
          onChange={setChecked}
          value={item.value}
          label={t(`reportCheckboxes.${item.value}`)}
        />
      </li>
    );
  });

  const positionsList = reportData.shadow_team_positions
    ? orderShadowTeamPositions(reportData.shadow_team_positions).map(position => {
        const index = reportData.shadow_team_positions_selected.indexOf(position.position_name);
        return (
          <li key={position.id} className={styles.checkboxItem}>
            <Checkbox
              checked={index >= 0}
              onChange={e => {
                const positionsSelected = [...reportData.shadow_team_positions_selected];
                if (index >= 0) {
                  positionsSelected.splice(index, 1);
                } else {
                  positionsSelected.push(position.position_name);
                }
                setReportData({
                  ...reportData,
                  shadow_team_positions_selected: positionsSelected,
                });
              }}
              value={position.position_name}
              label={`${t('report.position')} ${position.position_name}`}
            />
          </li>
        );
      })
    : null;

  const athletesCard =
    orderedAthletesId &&
    orderedAthletesId.map(orderedAthleteId => {
      const item = data.find(athlete => athlete.id === orderedAthleteId);
      return item ? (
        <div className={styles.athleteContainer} key={item.id}>
          <div className={styles.removeBtn}>
            {reportData.shadow_team == null && data.length >= 2 && (
              <IconButton
                className={styles.iconButton}
                color="vividGreen"
                icon={<FaRegTrashAlt className={styles.icon} size={15} />}
                onClick={() => removeAthleteHandle(item.id)}
                style={{
                  float: 'right',
                  marginLeft: '6px',
                }}
              />
            )}
            <IconButton
              className={styles.iconButton}
              color="lightBlue"
              icon={<FaRedoAlt className={styles.icon} size={15} />}
              onClick={() => updateAthlete(item.id)}
            />
          </div>
          <div className={styles.left}>
            <AthleteAvatar img={item.athlete.photo} clubImg={item.athlete.contract_info?.current_team?.emblem} />
          </div>
          <div className={styles.right}>
            <div className={styles.name}>
              <h3>{item.athlete.nickname}</h3>
            </div>
            <div className={styles.completeName}>{item.athlete.fullname}</div>
            <div className={styles.info}>
              <div>
                {item.athlete.nationalities &&
                  item.athlete.nationalities.length > 0 &&
                  item.athlete.nationalities[0] && (
                    <img
                      src={item.athlete.nationalities[0].flag}
                      alt={item.athlete.nationalities[0].name}
                      draggable="false"
                    />
                  )}
                <span className={styles.age}>
                  {item.athlete.age} {t('athlete_card.idade')}
                </span>
              </div>
              <span className={styles.position}>{t(`positionOptionsRaw.${item.athlete.position}`)}</span>
            </div>
          </div>
        </div>
      ) : (
        ''
      );
    });

  return (
    <div className={styles.container}>
      <div>
        <h4>{t('report.reportData')}</h4>
        <div>
          {reportData.project && (
            <div className={styles.formGroup}>
              <label>{t('club.box3')}</label>
              <br />
              <span className={styles.darkPurple9}>{reportData.project}</span>
            </div>
          )}

          <div className={styles.formGroup}>
            <label>{t('report.reportName')}</label>
            <Input
              rounded
              value={reportData.name}
              onChange={e => {
                setReportData({ ...reportData, name: e.target.value });
              }}
            />
          </div>

          <div className={styles.formGroup}>
            <label>{t('club.box4')}</label>
            <TextArea
              value={reportData.description}
              onChange={e => {
                let value = e.target.value;

                if (value.length > 250) {
                  setReportData({ ...reportData, description: value.substring(0, 250) });
                } else {
                  setReportData({ ...reportData, description: value });
                }
              }}
            />
            <div className={styles.maxchar}>{t('club.box5')}</div>
          </div>
        </div>
      </div>
      <div className={styles.checkboxDiv}>
        <h4>{t('club.box6')}</h4>
        <ul>{checkboxList}</ul>
      </div>
      {positionsList && positionsList.length > 0 && (
        <div className={styles.checkboxDiv}>
          <h4>{t('report.positions')}</h4>
          <ul>{positionsList}</ul>
        </div>
      )}
      <div className={styles.selectedAthletes}>
        <h4 className={styles.currentClub}>{t('club.box7')}</h4>
        {athletesCard}
        <div className={styles.addAthleteContainer}>
          <Input
            rounded
            placeholder={t('club.box8')}
            containerClassName={styles.inputContainer}
            iconPosition="right"
            icon={
              searchBar.loading ? (
                <Spinner type="TailSpin" />
              ) : (
                <FaSearch
                  className={styles.searchIcon}
                  onClick={searchValue.length >= 3 ? searchIconClickHandler : undefined}
                />
              )
            }
            value={searchValue}
            onKeyUp={e => {
              if (e.keyCode === 13 && searchValue.length >= 3) searchIconClickHandler();
            }}
            onChange={e => handleSearchChange(e.target.value)}
          />
          <ResultCard
            isVisible={resultIsVisible}
            loading={false}
            data={searchBar.data}
            hasHide={true}
            hide={() => {
              setResultIsVisible(false);
            }}
            onItemClick={handleClickItem}
            setSearchValue={setSearchValue}
          />
        </div>
      </div>
    </div>
  );
}

Aside.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  orderedAthletesId: PropTypes.array.isRequired,
  setConfiguration: PropTypes.func.isRequired,
  configuration: PropTypes.instanceOf(Object).isRequired,
};
