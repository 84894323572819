import React from 'react';
import { useTranslation } from 'react-i18next';

// scss
import styles from './Content.module.scss';

// components
import Chat from './Chat/Chat';
import SideBar from './Sidebar/SideBar';

export default function ChatContent({
  repository,
  chats,
  messages,
  messageGroups,
  selectedChat,
  submitMessage,
  deleteMessages,
  nextMessagesPage,
  handleFilterChange,
  handleSelectChat,
  filters,
  user,
  sidebarLoading,
  chatLoading,
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.panel}>
        <SideBar
          chats={chats}
          user={user}
          messages={messages}
          sidebarLoading={sidebarLoading}
          filters={filters}
          selectedChat={selectedChat}
          handleSelectChat={handleSelectChat}
          repository={repository}
          handleFilterChange={handleFilterChange}
        />
        {selectedChat != null ? (
          <Chat
            user={user}
            chatLoading={chatLoading}
            messages={messages}
            selectedChat={selectedChat}
            messageGroups={messageGroups}
            submitMessage={submitMessage}
            deleteMessages={deleteMessages}
            nextMessagesPage={nextMessagesPage}
            handleSelectChat={handleSelectChat}
            repository={repository}
            handleFilterChange={handleFilterChange}
          />
        ) : (
          <div className={styles.noChatSelected}>
            <span>{t('chat.select_message')}</span>
          </div>
        )}
      </div>
    </div>
  );
}
