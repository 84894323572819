import React from 'react';
import moment from 'moment';
import styles from './styles.module.scss';
import { Button } from 'components/template';
import { Avatar } from 'components/template';
import { useTranslation } from 'react-i18next';
import { MdOutlineArrowBack } from 'react-icons/md';

export default function TopBar({ project }) {
    const { t } = useTranslation();
    let users = '';
    if (project?.users) {
        if (project.users.length > 2) {
            users = (
                <>
                    <Avatar
                        img={project.users[0].user.photo}
                        name={project.users[0].user.name}
                        style={{ marginLeft: '-7px' }}
                    />
                    <Avatar
                        img={project.users[1].user.photo}
                        name={project.users[1].user.name}
                        style={{ marginLeft: '-7px' }}
                    />
                    <Avatar name={`+ ${project.users.length - 2}`} style={{ marginLeft: '-7px' }} />
                </>
            );
        } else {
            users = project.users.map(pu => (
                <Avatar img={pu.user.photo} name={pu.user.name} key={pu.user.id} style={{ marginLeft: '-7px' }} />
            ));
        }
    }

    const arrowLeft = <MdOutlineArrowBack size="26px" />;

    const currentUrl = window.location.pathname;
    const isNotAthleteOpened = isNaN(Number(currentUrl.split('/').pop()));
    let urlBack = '';
    if (isNotAthleteOpened) {
        urlBack = '/projetos';
    } else {
        urlBack = currentUrl.substring(0, currentUrl.lastIndexOf('/'));
    }
    return (
        <div className={styles.topBar}>
            <div className={styles.left}>
                <div className={styles.pageBack}>
                    <Button onClick={() => window.location.replace(urlBack)} noShadow>
                        {arrowLeft}
                    </Button>
                </div>
                <div>
                    <Avatar
                        img={project.photo}
                        className={styles.shield}
                        alt=""
                        draggable="false" />
                </div>
                <div className={styles.projectName}>
                    <h1>{project?.name}</h1>
                </div>
            </div>
            <div className={styles.right}>
                {project?.created_at && project?.lastmodified_at ? (
                    <div className={styles.info}>
                        <span>
                            {t('user.box16')} {moment(project.created_at).format('DD/MM/YYYY')} {t('user.box18')}{' '}
                            {project?.created_by_user?.name}
                        </span>
                        <span>
                            {t('user.box17')} {moment(project.lastmodified_at).format('DD/MM/YYYY')}{' '}
                            {t('organi.text21')} {moment(project.lastmodified_at).format('HH:mm')}
                        </span>
                    </div>
                ) : (
                    t('proj.text20')
                )}
                <div className={styles.members}>{users}</div>
            </div>
        </div>
    );
}
