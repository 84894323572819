import React from 'react';
import PropTypes from 'prop-types';

import styles from './Radio.module.scss';

export default function Radio({
  label, checked, className, dark, small, ...props
}) {
  const radioClasses = [styles.radio];
  if (dark) radioClasses.push(styles.dark);
  if (small) radioClasses.push(styles.small);
  if (checked) radioClasses.push(styles.checked);

  return (
    <label className={[styles.container, className].join(' ')}>
      {/* <div className={radioClasses.join(' ')} /> */}
      <input checked={checked} type='radio' {...props} /> {label}
    </label>
  );
}

Radio.defaultProps = {
  label: null,
  className: '',
  dark: false,
  small: false,
};

Radio.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  dark: PropTypes.bool,
  small: PropTypes.bool,
};
