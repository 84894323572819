import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Contracts.module.scss';
import { useTranslation } from 'react-i18next';
import { getAgencyAthletes } from 'store/ducks/agencies';
import CenteredLoader from 'components/template/CenteredLoader';
import ContractsGroup from './ContractsGroup/ContractsGroup';
import ContractDialog from './ContractDialog/ContractDialog';
import ActionBar from './ActionBar/ActionBar';

export default function Contracts({ agencyId }) {
  const { t } = useTranslation();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [athletes, setAthletes] = useState([]);
  const [groups, setGroups] = useState({ finished: [], danger: [], warning: [], ok: [] });
  const dispatch = useDispatch();
  const agency = useSelector(state => state.agencies.instance);
  const agencyAthletes = useSelector(state => state.agencies.athletes);
  const contractHighRisk = agency.data?.contract_high_risk ?? 9;
  const contractWarningRisk = agency.data?.contract_warning_risk ?? 18;

  const [selectedFilters, setSelectedFilters] = useState({
    female: false,
    professional: true,
    loaned: false,
  });

  const dangerRangeDivider = new Date(new Date().setMonth(new Date().getMonth() + contractHighRisk))
    .toISOString()
    .split('T')[0];
  const warningRangeDivider = new Date(new Date().setMonth(new Date().getMonth() + contractWarningRisk))
    .toISOString()
    .split('T')[0];

  useEffect(() => {
    dispatch(getAgencyAthletes(agencyId, selectedFilters));
  }, [dispatch, agencyId, selectedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAthletes(agencyAthletes.data);
  }, [agencyAthletes]);

  function getGroupsByAthletes(athletes) {
    const today = new Date();
    return athletes.reduce(
      (acc, at) => {
        if (at?.contract_info?.current_contract_end == null || at.contract_info.current_contract_end < today) acc['finished'].push(at)
        else if (at.contract_info.current_contract_end < dangerRangeDivider) acc['danger'].push(at)
        else if (at.contract_info.current_contract_end < warningRangeDivider) acc['warning'].push(at)
        else acc['ok'].push(at);
        return acc;
      },
      { finished: [], danger: [], warning: [], ok: [] }
    );
  }

  useEffect(() => {
    if (athletes) setGroups(getGroupsByAthletes(athletes));
  }, [athletes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        <div className={styles.panelTitle}>
          <h2 className={styles.title}>{t('myClub.contracts.pageTitle')}</h2>
          <p className={styles.subTitle}>{t('myClub.contracts.pageSubTitle')}</p>
        </div>
        <ActionBar setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} />
        {agencyAthletes.loading ? (
          <CenteredLoader size="calc(100vh - 250px)" />
        ) : (
          Object.keys(groups).map((group, index) => {
            return (
              <div key={index} className={styles.groupContainer}>
                <ContractsGroup
                  selectedFilters={selectedFilters}
                  type={group}
                  athletes={groups[group]}
                  onClick={group === 'finished' ? undefined : () => {
                    setIsOpenDialog(true);
                  }}
                />
              </div>
            );
          })
        )}
        <ContractDialog agencyId={agencyId} isOpen={isOpenDialog} setIsOpen={setIsOpenDialog} />
      </div>
    </div>
  );
}
