import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from 'store/ducks/club';
import { withRouter } from 'react-router-dom';
import Helpers from 'shared/helpers';
import { useTranslation } from 'react-i18next';
import { Card, Spinner, Table, Checkbox, SelectionButton } from 'components/template';
import styles from './ProfessionalCast.module.scss';
import ActionBar from './ActionBar/ActionBar';
import { getList } from 'store/ducks/monitoringV2';

function ProfessionalCast({ teamId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const professionalCast = useSelector(state => state.club.professionalCastPage);
  const list = useSelector(state => state.monitoringV2);

  const [checkedAthletes, setCheckedAthletes] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [maleCastSelected, setMaleCastSelected] = useState('professional');
  const [femaleCastSelected, setFemaleCastSelected] = useState(null);

  const [selectedFilters, setSelectedFilters] = useState({
    female: false,
    professional: true,
    loaned: false,
    name: '',
    position: '',
    min_birthyear: null,
    max_birthyear: null,
    o: 'position_order',
  });

  const [athletesAsRows, setAthletesAsRows] = useState([]);

  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    setAthletesAsRows(
      Helpers.athleteListToTable(
        professionalCast.data.athletes,
        'elenco',
        styles,
        'monitoring',
        athlete => {
          if (checkedAthletes.some(item => item.id === athlete.id)) {
            setCheckedAthletes(checkedAthletes.filter(item => item.id !== athlete.id));
          } else {
            setCheckedAthletes([...checkedAthletes, athlete]);
          }
        },
        checkedAthletes,
        selectedFilters.loaned
      )
    );
  }, [professionalCast.data.athletes, checkedAthletes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getList());
  }, [dispatch]);

  useEffect(() => {
    setFilteredList(
      list.data.filter(item => item.name !== '_DEFAULT_').map(item => ({ label: item.name, value: String(item.id) }))
    );
  }, [list.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(Creators.getProfessionalCast(teamId, selectedFilters));
  }, [dispatch, teamId, selectedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  let content = '';
  if (professionalCast.loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (professionalCast.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(professionalCast.error)}</p>
      </Card>
    );
  } else if (professionalCast.data.athletes.length) {
    content = (
      <Card softShadow className={styles.professionalCast}>
        <div className={styles.tableContainer}>
          <Table
            flat
            theads={[
              <Checkbox
                dark
                checked={allChecked}
                onChange={() => {
                  const newAllChecked = !allChecked;
                  setAllChecked(newAllChecked);
                  if (newAllChecked) {
                    setCheckedAthletes(
                      professionalCast.data.athletes.map(athlete => ({ id: athlete.id, name: athlete.fullname }))
                    );
                  } else {
                    setCheckedAthletes([]);
                  }
                }}
              />,
              t('table.atleta'),
              '',
              t('athlete_list.box3'),
              t('athlete_list.box4'),
              'Nac.',
              t('myClub.professionalCast.contractUntil'),
              <div className={styles.loanedContract}>
                {t(`myClub.professionalCast.${selectedFilters.loaned ? 'loanedClub' : 'cedingClub'}`)} /<br />
                {t(`myClub.professionalCast.${selectedFilters.loaned ? 'loanedContract' : 'cedingContract'}`)}
              </div>,
              '',
            ]}
            data={athletesAsRows}
          />
        </div>
      </Card>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.panelTitle}>
        <h2 className={styles.title}>{t('myClub.professionalCast.pageTitle')}</h2>
        <p className={styles.subTitle}>{t('myClub.professionalCast.pageSubTitle')}</p>
      </div>
      <div className={styles.buttonsTop}>
        <span className={styles.spanMale}>{t('listTranslation.Male')}: </span>
        <SelectionButton
          items={[
            { label: t('compe.text26'), value: 'professional' },
            { label: t('text.box31'), value: 'loaned' },
            { label: t('text.box32'), value: 'amateur' },
          ]}
          selected={maleCastSelected}
          onClick={val => {
            const loaned = val === 'loaned';
            setSelectedFilters({
              ...selectedFilters,
              female: false,
              professional: loaned ? null : val === 'professional',
              loaned,
            });
            setMaleCastSelected(val);
            setFemaleCastSelected(null);
          }}
        />
        <span className={styles.spanFemale}>{t('listTranslation.Female')}: </span>
        <SelectionButton
          items={[
            { label: t('compe.text26'), value: 'professional' },
            { label: t('text.box38'), value: 'loaned' },
            { label: t('text.box37'), value: 'amateur' },
          ]}
          selected={femaleCastSelected}
          onClick={val => {
            const loaned = val === 'loaned';
            setSelectedFilters({
              ...selectedFilters,
              female: true,
              professional: loaned ? null : val === 'professional',
              loaned,
            });
            setMaleCastSelected(null);
            setFemaleCastSelected(val);
          }}
        />
      </div>
      <ActionBar
        checkedAthletes={checkedAthletes}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        list={filteredList}
      />
      {content}
    </div>
  );
}

ProfessionalCast.propTypes = {
  teamId: PropTypes.number.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(ProfessionalCast);
