import styled from 'styled-components';
import Variables from 'variables.js';
import { FaStickyNote, FaPlay } from 'react-icons/fa';

export const Container = styled.div`
  background-color: white;
  display: flex;
  padding: 20px;
`;

export const Aside = styled.div`
  width: 400px;
  background-color: white;
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-top-left-radius: 15px;
  /* border-right: 0.5px solid lightgray; */
  /* display: block; */
  /* overflow: auto;  */
`;

export const AsideContent = styled.div`
  /* flex-grow: 1; */
`;

export const Content = styled.div`
  padding: 10px 0;
  width: 100%;
  background-color: #fff;
  height: ${props => (props.height ? props.height : 'auto')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top-right-radius: 15px;
  border: 1px solid lightgray;
`;

export const TextContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column-reverse;

  overflow-y: scroll;
  overflow-x: hidden;

  &:first-child {
    padding-bottom: 40px;
  }
`;

export const TextContainerEmpty = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  margin: auto;
  overflow: hidden;

  &:first-child {
    padding-bottom: 40px;
  }
`;

export const InputsContainer = styled.div`
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  padding: 15px 20px 0 20px;
  /* position: absolute;
    bottom: 0px; */
  /* height: 100%; */
`;

export const MessageContainer = styled.div`
  padding: 2px 10px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  border-radius: ${props => (props.backgroundColor ? '8px' : '0')};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'transparent')};
`;

export const UserName = styled.span`
  padding-bottom: 5px;
  display: block;
  color: #5a00ff;
`;

export const MediaContainer = styled.div``;

export const Video = styled.video``;

export const Image = styled.img`
  width: 200px;
  height: 200px;
`;

export const Audio = styled.audio`
  /* width: 200px;
    height: 200px; */
`;

export const Thumbnail = styled.div`
    width: 200px;
    height: 200px;

    background-image: url("${props => props.link}");

`;

export const ThumbnailIcon = styled(FaPlay)`
  display: block;
  color: ${Variables.light};
  opacity: 0.6;
  width: 50%;
  height: 50px;
  margin: auto;
  padding: 70px 0;
`;

export const Message = styled.div`
  max-width: 600px;
  background-color: #eee5ff;
  padding: 15px;
  border-radius: 8px 0px 8px 8px;
  font-size: small;
  font-weight: 600;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  opacity: ${props => (props.loading ? '0.4' : '1')};
`;

export const Text = styled.span`
  margin-top: 5px;
  font-weight: 400;
`;

export const EmptyContainer = styled.div`
  margin: auto;
  text-align: center;
  width: 50%;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
`;

export const BackgroundIconContainer = styled.div`
  padding: 20px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: ${Variables.light};
  margin: auto;
`;

export const EmptyIcon = styled(FaStickyNote)`
  color: white;
  width: 70px;
  height: 70px;
  margin: auto;
  padding: 15px 0;
`;

export const EmptyText = styled.span`
  margin-top: 10px;
  color: ${Variables.lightGrey};
`;

export const NoteGroupItem = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid lightgray;
    border-top-left-radius: 15px;
    padding: 20px;
    cursor: pointer;
}
    
    &:not(:first-child){
        border-top: none;
    }
`;

export const NoteGroupText = styled.div`
  color: #bdbdbd;
  text-align: center;
  padding: 20px;
`;

export const NoteGroupDate = styled.span`
  display: block;
`;

export const NoteGroupCounter = styled.span`
  display: block;
  color: #5a00ff;
  font-weight: 600;
  font-size: 14px;
`;
