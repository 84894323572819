/*eslint-disable eqeqeq*/
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBanners } from 'store/ducks/home';
import styles from './index.module.scss';
import { Carousel } from 'react-responsive-carousel';

export default function Banners() {
  const dispatch = useDispatch();
  const data = useSelector(state => state.home.banners);
  const [height, setHeight] = useState('0');
  const [showCarousel, setShowCarousel] = useState(false);

  useEffect(() => {
    dispatch(getBanners());
  }, [dispatch]);

  const bannersData = data?.data ?? [];
  if (bannersData.length > 0) {
    if (height === '0') {
      setTimeout(() => setHeight('340px'), 200); //for transition
      if (bannersData.length > 1) {
        setTimeout(() => setShowCarousel(true), 1400); //wait transition
      }
    }

    const imagesComponent = showCarousel ? (
      <Carousel
        showThumbs={false}
        showStatus={false}
        emulateTouch={true}
        autoPlay={true}
        infiniteLoop={true}
        interval={6000}
      >
        {data.data.map((banner, index) => {
          return <div
            key={`banner-${index}`}
            onClick={() => window.open(banner.url)}
            style={{ cursor: banner.url ? 'pointer' : 'default' }}
          >
            <img
              className={styles.image}
              alt={banner.alt ?? ''}
              src={banner.photo}
              style={{ height: height }}
            />
          </div>
        })}
      </Carousel>
    ) : (
      <img
        className={styles.image}
        alt={bannersData[0].alt ?? ''}
        src={bannersData[0].photo}
        style={{ height: height }}
      />
    );

    return (
      <div
        className={`${styles.content} ${styles.padding}`}
        style={{
          height: height,
          marginTop: height === '0' ? '20px' : '40px'
        }}
      >
        {imagesComponent}
      </div>
    );
  }

  return <div className={styles.padding}></div>;
}
