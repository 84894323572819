import React from 'react'
import * as S from './styles'

export default function ProfileInfo({ user, email }) {
    return (
        <S.Card softShadow>
            <S.Avatar noBorder img={user.photo} name={user.name} />
            <S.ProfileData>
                <S.UserName>{user.name}</S.UserName>
                {email && (
                    <S.UserEmail>{user.email}</S.UserEmail>
                )}
            </S.ProfileData>
        </S.Card>
    );
}