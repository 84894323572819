import React from 'react';
import PropTypes from 'prop-types';

import styles from './OrganizerRow.module.scss';
import { Avatar } from 'components/template';

export default function OrganizerRow(props) {
  const { img, name, id, ...rest } = props;

  return (
    <div className={styles.withImg} {...rest}>
      <Avatar className={styles.avatar} noBorder img={img ?? ''} name={name} />
      {name}
    </div>
  );
}

OrganizerRow.defaultProps = {
  countries: [],
};

OrganizerRow.propTypes = {
  id: PropTypes.number,
  img: PropTypes.string,
  name: PropTypes.string
};
