import styled from 'styled-components';
import Variables from 'variables';
import { Card as DefaultCard } from 'components/template';

export const Card = styled(DefaultCard)`
  background-color: #ffffff;
  padding: 0 30px;
  min-height: 75vh;
`;

export const ColumnsContainer = styled.div`
  display: flex;
  min-height: 70vh;
  min-width: 90vh;
  padding: 0 15px 10px 0;
  overflow-x: auto;

  & > div {
    background-color: ${Variables.white};
    border: 1px solid ${Variables.lightBorder};
    border-radius: 8px;
    height: fit-content;
    margin-right: 15px;
    min-width: 360px;
  }
`;

export const ColumnHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  padding-left: 30px;
  padding-right: 8px;
`;

export const Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
`;

export const ColumnBody = styled.div`
  padding: 0 8px;
  overflow-y: auto;
  max-height: 58vh;
`;

export const ColumnLink = styled.div`
  padding: 15px;
`;

export const UpdateWorkflow = styled.div`
  position: absolute;
  background-color: ${Variables.white};
  margin-top: 50px;
  border: solid 1px ${Variables.lightBorder};
  border-radius: 6px;
  z-index: 1;
  display: none;

  & > button {
    padding: 8px 5px;
    background-color: ${Variables.white};
    border: none;
    cursor: pointer;
    font-weight: 500;
    width: 95px;
    display: block;

    &:first-child {
      border-radius: 6px 6px 0 0;
    }

    &:last-child {
      border-radius: 0 0 6px 6px;
    }

    &:hover {
      background-color: ${Variables.lightPurple3};
    }
  }
`;
