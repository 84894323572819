import React from 'react';
import PropTypes from 'prop-types';
import styles from './AgencyInfo.module.scss';
import { SocialButton, IconButton } from 'components/template';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import { FaPen } from 'react-icons/fa';

export default function AgencyInfo({ agency, setIsOpen }) {
  const { t } = useTranslation();

  return (
    <>
      {agency && (
        <div className={styles.clubInfo}>
          <h2 className={styles.title}>{t('myAgency.agency_info')}</h2>
          <div className={styles.content}>
            <Carousel showThumbs={false} dynamicHeight={true} showStatus={false} emulateTouch={true}>
              {agency.photos.length > 0 ? (
                agency.photos?.map((photo, index) => {
                  return <img className={styles.image} alt={`agency${index}`} src={photo.photo} />;
                })
              ) : (
                <div className={styles.agency_info_no_image} />
              )}
            </Carousel>

            {/* GENERAL INFO */}
            <div className={styles.generalInfo}>
              <div className={styles.left_column}>
                <div>
                  <span>{t('text.box35')}: </span>
                </div>
                <div>
                  <span>{t('text.box34')}: </span>
                </div>
                <div>
                  <span>{t('text.box36')}: </span>
                </div>
                <div>
                  <span>{t('myAgency.athletes_in')}: </span>
                </div>
                <div>
                  <span>{t('myAgency.principal_market')}: </span>
                </div>
              </div>
              <div className={styles.right_column}>
                <div>
                  <span className={styles.bold}>{agency.address.city || '-'}</span>
                </div>
                <div>
                  <span className={styles.bold}>{agency.address.state || '-'}</span>
                </div>
                <div className={styles.agency_info_country_div}>
                  <img src={agency.country?.flag} alt="" />
                  <span className={styles.bold}>{agency.country?.name || '-'}</span>
                </div>
                <div>
                  <span className={styles.bold}>{agency.counts.athletes}</span>
                </div>
                <div>
                  <span className={styles.bold}>{agency.main_market.name}</span>
                </div>
              </div>
              <p>{agency.address.full_address}</p>
            </div>

            {/* SOCIAL BUTTONS */}
            <div className={styles.socialButtons}>
              {agency.social?.website && <SocialButton type="website" href={agency.social?.website} />}
              {agency.social?.wikipedia && <SocialButton type="wikipedia" href={agency.social?.wikipedia} />}
              {agency.social?.instagram && <SocialButton type="instagram" href={agency.social?.instagram} />}
              {agency.social?.twitter && <SocialButton type="twitter" href={agency.social?.twitter} />}
              {agency.social?.youtube && <SocialButton type="youtube" href={agency.social?.youtube} />}
              {agency.social?.tiktok && <SocialButton type="tiktok" href={agency.social?.tiktok} />}
            </div>
            {/* EDIT BUTTON */}
            <div className={styles.edit_button_container}>
              <IconButton
                className={styles.edit_button}
                text={t('buttons.edit')}
                icon={<FaPen />}
                onClick={() => setIsOpen(true)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

AgencyInfo.propTypes = {
  club: PropTypes.instanceOf(Object).isRequired,
};
