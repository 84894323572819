import React from 'react';
import PropTypes from 'prop-types';

import styles from './Select.module.scss';

export default function Select({
  options,
  value,
  onChange,
  label,
  isMultiple,
  labelClassName,
  selectClassName,
}) {
  const labelClasses = [styles.label];
  if (labelClassName) labelClasses.push(labelClassName);

  const selectClasses = [styles.select];
  if (selectClassName) selectClasses.push(selectClassName);

  const optionsList = options.map(option => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));

  return (
    <label className={[labelClasses].join(' ')}>
      {label}
      <select
        multiple={isMultiple}
        onChange={onChange}
        value={value}
        className={selectClasses.join(' ')}
      >
        {optionsList}
      </select>
    </label>
  );
}

Select.defaultProps = {
  label: null,
  isMultiple: false,
  labelClassName: null,
  selectClassName: null,
  options: [],
};

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  isMultiple: PropTypes.bool,
  labelClassName: PropTypes.string,
  selectClassName: PropTypes.string,
};
