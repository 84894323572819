import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export default function BarIdentification({ title, subtitle }) {
    return (
        <div className={styles.BarIdentification}>
            <h1>{title}</h1>
            <p>{subtitle}</p>
        </div>
    );
}

BarIdentification.propTypes = {
    title: PropTypes.string.isRequired,
    subitle: PropTypes.string,
};
