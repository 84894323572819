import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getLastSeenAthletes } from 'store/ducks/home';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
//import "../../../translations/i18n"

import { AthleteAvatar, Spinner, Card } from 'components/template';

export default function LastSeenAthletes() {
  const dispatch = useDispatch();
  const data = useSelector(state => state.home.lastSeenAthletes);
  const loggedUser = useSelector(state => state.user.data);
  const { t } = useTranslation();

  useEffect(() => {
    if (loggedUser.plan.meu_feed) {
      dispatch(getLastSeenAthletes());
    }
  }, [dispatch, loggedUser.plan.meu_feed]);

  const positionMap = {
    Atacante: t('positionOptions.Atacante'),
    Ataque: t('positionOptions.Ataque'),
    Centroavante: t('positionOptions.Centroavante'),
    Zagueiro: t('positionOptions.Zagueiro'),
    Defesa: t('positionOptions.Defesa'),
    Goleiro: t('positionOptions.Goleiro'),
    Meia: t('positionOptions.Meia'),
    Volante: t('positionOptions.Volante'),
    'Lateral Direito': t('positionOptions.LateralDireito'),
    'Lateral Esquerdo': t('positionOptions.LateralEsquerdo'),
    'Meia-atacante': t('positionOptions.MeiaAtacante'),
    'Meio-campo': t('positionOptions.MeioCampo'),
  };

  const athletesCard =
    data.data &&
    data.data.map(item => {
      const position = item.athlete?.position;
      const translatedPosition = positionMap[position] || '';

      return (
        <Link
          className={styles.athleteContainer}
          key={item.id}
          to={{ pathname: `/atletas/${item?.athlete?.id}`, state: { previews: window.location.pathname } }}
        >
          <div className={styles.left}>
            <AthleteAvatar
              img={item.athlete?.photo}
              clubImg={item.athlete?.current_team?.emblem}
              name={item.athlete?.fullname}
            />
          </div>
          <div className={styles.right}>
            <span className={styles.name}>{item?.athlete?.nickname}</span>
            <span className={styles.completeName}>{item.athlete?.fullname}</span>
            <div className={styles.info}>
              {item.athlete?.nationalities && item.athlete?.nationalities.length > 0 && item.athlete.nationalities[0] && (
                <>
                  <img
                    src={item.athlete?.nationalities[0].flag}
                    alt={item.athlete?.nationalities[0]?.name ?? ''}
                    draggable="false"
                  />
                </>
              )}
              <span className={styles.age}>
                {item.athlete?.age} {t('athlete_card.idade')}
              </span>
              {item.athlete?.position && <span className={styles.position}>| {translatedPosition}</span>}
            </div>
          </div>
        </Link>
      );
    });

  let content = '';
  if (data.loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (data.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(data.error)}</p>
      </Card>
    );
  } else {
    content = athletesCard;
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t('pagina_inicial.homepage_4')}</h2>
      {content}
    </div>
  );
}
