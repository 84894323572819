import styled from 'styled-components';
import Variables from 'variables';
import { Card as DefaultCard, Menu as DefaultMenu } from 'components/template';

export const Card = styled(DefaultCard)`
    background-color: #ffffff;
    padding: 0 30px;
    min-height: 75vh;

`;

export const MinMaxDateDiv = styled.div`
    background-color: ${Variables.white};
    font-size: 14px;
    font-weight: 500;
    color: ${Variables.black};
    display: flex;
    align-items: center;
    padding: 1px 15px;
    height: 37px;
    border-radius: 8px;
    border: 1px solid ${Variables.lightBorder};
`;

export const MinMaxDateDiv2 = styled.div`
    background-color: ${Variables.white};
    font-size: 14px;
    font-weight: 500;
    color: ${Variables.black};
    display: flex;
    align-items: center;
    padding: 1px 15px;
    height: 37px;
    border-radius: 8px;
    border: 1px solid ${Variables.lightBorder};
`;


export const FilterDiv = styled.div`
    display: flex;
    padding-bottom: 15px;

    & > * {
        margin-right: 0.5rem;
    }
`;

export const FilterInputContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

export const Menu = styled(DefaultMenu)`
    background-color: ${Variables.white};
    color: ${Variables.black};
    display: flex;
    border: 1px solid ${Variables.lightBorder};
    padding: 1px 20px;
    width: 141px;
    height: 37px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
`;

export const ColumnsContainer = styled.div`
    display: flex;
    min-height: 70vh;
    min-width: 90vh;
    padding: 0 15px 10px 0;
    overflow-x: auto;

    & > div {
        background-color: ${Variables.white};
        border: 1px solid ${Variables.lightBorder};
        border-radius: 8px;
        height: fit-content;
        margin-right: 15px;
        min-width: 360px;
    }
`;

export const ColumnHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
    padding-left: 30px;
    padding-right: 8px;
`;

export const Title = styled.h3`
    font-size: 18px;
    font-weight: 600;
`;

export const ColumnBody = styled.div`
    padding: 0 8px;
    overflow-y: auto;
    max-height: 58vh;
`;

export const ColumnLink = styled.div`
    padding: 15px;
`;

export const UpdateWorkflow = styled.div`
    position: absolute;
    background-color: ${Variables.white};
    margin-top: 50px;
    border: solid 1px ${Variables.lightBorder};
    border-radius: 6px;
    z-index: 1;
    display: none;

    & > button {
    padding: 8px 5px;
    background-color: ${Variables.white};
    border: none;
    cursor: pointer;
    font-weight: 500;
    width: 95px;
    display: block;

    &:first-child {
        border-radius: 6px 6px 0 0;
    }
    
    &:last-child {
        border-radius: 0 0 6px 6px;
    }

    &:hover {
        background-color: ${Variables.lightPurple3};
    }
    }
`;
