import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Input, Menu } from 'components/template';
import { FaSearch } from 'react-icons/fa';
import styles from './ActionBar.module.scss';
import { useTranslation } from "react-i18next";
import { divisionOptions } from '../../../shared/mocks';

export default function ActionBar({
  stateOptions,
  filterMenu,
  setFilterMenu,
  nameInputValue,
  nameInputChangeHandler,
  countries
}) {
  const { t } = useTranslation();
  const [timeoutId, setTimeoutId] = useState(null);
  const [clubNameFilter, setClubNameFilter] = useState('');

  useEffect(() => {
    setClubNameFilter(nameInputValue);
  }, [nameInputValue]);

  const handleInputChange = (value) => {

    setClubNameFilter(value);
    clearTimeout(timeoutId);

    setTimeoutId(
      setTimeout(() => {
        if ((clubNameFilter !== '' && value === '') || value !== '') {
          nameInputChangeHandler(value);
        }
      }, 800)
    );
  }

  return (
    <div className={styles.actionBar}>
      {/* estado */}
      {/* clube */}
      <Input
        rounded
        placeholder={t("navDrawer.name")}
        value={clubNameFilter}
        containerClassName={styles.inputContainer}
        onChange={e => handleInputChange(e.target.value)}
        icon={<FaSearch className={styles.icon} />}
        iconPosition="right"
      />
       <Menu
        title={t('compe.text1')}
        rounded
        allowEmpty
        placeholder={countries.find(country => country.code === filterMenu.country)?.name || t('compe.text1')}
        fixedPlaceholder
        className={styles.menu}
        options={countries.map(country => ({ value: country.code, label: country.name })) ?? []}
        setValue={val => setFilterMenu(f => ({ ...f, country: val }))}
        onClear={() => setFilterMenu(f => ({ ...f, country: '' }))}
        value={filterMenu.country}
      />
      <Menu
        title={t("athlete_card.text5")}
        largeMenu
        rounded
        allowEmpty
        multiple
        placeholder={t("home.text2")}
        fixedPlaceholder
        className={styles.menu}
        options={stateOptions ?? []}
        value={filterMenu.currentState}
        onClear={() => setFilterMenu(f => ({ ...f, currentState: [] }))}
        setValue={val => setFilterMenu(f => ({ ...f, currentState: val }))}
      />
      <Menu
        title={t('organi.text11')}
        rounded
        allowEmpty
        placeholder={t(
          `clubDivisionOptions.${divisionOptions.find(division => division.value === filterMenu.division)?.value}`
        )}
        fixedPlaceholder
        className={styles.menu}
        options={divisionOptions.map(item => ({
          label: t(`clubDivisionOptions.${item.value}`),
          value: item.value,
        }))}
        setValue={val => setFilterMenu(f => ({ ...f, division: val }))}
        onClear={() => setFilterMenu(f => ({ ...f, division: '' }))}
        value={filterMenu.division}
      />
    </div>
  );
}

ActionBar.defaultProps = {
  // trashLoading: false,
};

ActionBar.propTypes = {
  stateOptions: PropTypes.instanceOf(Array).isRequired,
  nameInputValue: PropTypes.string.isRequired,
  nameInputChangeHandler: PropTypes.func.isRequired,
  filterMenu: PropTypes.instanceOf(Object).isRequired,
  setFilterMenu: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
};
