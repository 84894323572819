import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import Variables from 'variables.js';
import ReactApexChart from 'react-apexcharts';
import {StarRating} from 'components/template';
import styles from './LineChart.module.scss';

export default function LineChart({ options, data, height }) {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#EB5757', '#27AE60', '#56CCF2', '#BB6BD9'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2,
      curve: 'straight',
    },    
    legend: {
      tooltipHoverFormatter: function(val, opts) {
        return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
      }
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6
      }
    },
    xaxis: {
      categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan'],
    },
    yaxis: {
      min: 0, 
      max: 5,
      tickAmount: 5,
      labels: {
        show: false,
      }
    },
    tooltip: {
      x: {
        formatter: function (val, { seriesIndex, dataPointIndex, w }) {          
          const date = w.globals.categoryLabels[dataPointIndex];
          const evaluator = w.config.series[seriesIndex].evaluator[dataPointIndex];
          return `${date} - Avaliado por: ${evaluator}`;
        }
      },
      y: {
        formatter: function (val) {
          return val;
        }
      }
    },
    grid: {
      borderColor: '#f1f1f1',
    },
    ...options,
  });

  const [series, setSeries] = useState([]);

  useEffect(()=>{
    if(data){      
      const categories = mapEvolutionDates(data);
      setChartOptions({
        ...chartOptions,
        xaxis: {
          categories: categories
        }
      })
      setSeries(mapEvolutionData(data));      
    }
  },[data]);// eslint-disable-line react-hooks/exhaustive-deps
  function mapEvolutionData(evolution) {
    const evaluationNames = evolution.reduce((acc, entry) => {
      entry.micro_aspects.forEach((aspect) => {
        if (!acc.includes(aspect.title)) {
          acc.push(aspect.title);
        }
      });
      return acc;
    }, []);

    const mappedData = evaluationNames.map((name) => {
      return {
        name: name,
        data: evolution.map((entry) => {
          const evaluation = entry.micro_aspects.find((aspect) => aspect.title === name);
          return evaluation ? evaluation.rate : 0;
        }),
        evaluator: evolution.map((entry) => entry.author)
      };
    });

    return mappedData;
  }

  function mapEvolutionDates(evolution) {    
    return evolution.map(entry => {
      const [day, month, year] = entry.date.split("-"); // Formato 'dd-MM-yyyy'
      return `${day}/${month}/${year}`; // Transformar para 'dd/MM/yyyy'
    })    
  }

  return <div className={styles.LineChart}>
    <div className={styles.chartStars}>
      {Array.from({ length: 6 }, (_, index) => (
          <StarRating key={index} rate={index} size={16} />
      )).reverse()}
    </div>
    <div className={styles.chart}>
      {series.length > 0 &&
        <ReactApexChart height={height} options={chartOptions} series={series} />
      }
    </div>
  </div>
}

LineChart.defaultProps = {
  options: {},
};

LineChart.propTypes = {
  options: PropTypes.instanceOf(Object),  
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
