import styled from 'styled-components';
import Variables from 'variables.js';
import { Card as DefaultCard, Menu as DefaultMenu, Button as DefaultButton } from 'components/template';

export const ShadowTeamPage = styled.div`
    background-color: ${Variables.white};
    padding: 0 30px;
`;

export const FilterContainer = styled.div`
    box-shadow: none !important;
    display: flex;
    justify-content: space-between;
    height: 40px;
    margin-bottom: 10px;
`;

export const MenuFilter = styled.div`
    display: flex;
`;

export const FilterButton = styled(DefaultButton)`
    width: fit-content !important;
`;

export const FilterInputContainer = styled.div`
    padding-left: 20px;
    display: flex;
    align-items: center;
`;

export const FilterInputText = styled.div`
    flex-shrink:0;
`;


export const Menu = styled(DefaultMenu)`
    background-color: ${Variables.white};
    border: 1px solid ${Variables.lightBorder};
    font-size: 12px;
    font-weight: 500;
    padding: 0.65rem 0.95rem;
`;

export const ContentContainer = styled(DefaultCard)`
    padding: 0 !important;
`;