/*eslint-disable eqeqeq*/
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSystemDetails } from 'store/ducks/home';
import styles from './index.module.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Spinner, Card } from 'components/template';

export default function SystemDetails() {
  const dispatch = useDispatch();
  const data = useSelector(state => state.home.systemDetails);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getSystemDetails());
  }, [dispatch]);

  const generateKey = pre => {
    return `${pre}_${new Date().getTime()}`;
  };

  const systemInfoList = ['transfers', /*'bid',*/ 'historical_data', 'transfer_windows'];

  function trBody(id, systemName, lastUpload) {
    return (
      <tr key={id}>
        <td className={styles.info}>{t(`SystemInfoName.${systemName}`)}</td>
        <td className={styles.date}>{lastUpload ? moment(lastUpload.finished_at).format('DD/MM/YYYY') : '-'}</td>
      </tr>
    );
  }

  const infoData =
    data.data &&
    data.data
      .map(function(item, index) {
        if (systemInfoList.indexOf(item.name) > -1) {
          if (item.name == 'historical_data') {
            return (
              <React.Fragment key={generateKey(index)}>
                {trBody('p' + item.id, 'historical_data_prof', item.last_upload)}
                {trBody('b' + item.id, 'historical_data_base', item.last_upload)}
              </React.Fragment>
            );
          } else return trBody(item.id, item.name, item.last_upload);
        }
        return false;
      })
      .filter(item => item);

  let content = '';
  if (data.loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (data.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(data.error)}</p>
      </Card>
    );
  } else {
    content = (
      <table>
        <tbody>
          <tr className={styles.header}>
            <td>{t('pagina_inicial.homepage_10')}</td>
            <td>{t('pagina_inicial.homepage_11')}</td>
          </tr>
          {infoData}
        </tbody>
      </table>
    );
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t('pagina_inicial.homepage_2')}</h2>
      {content}
    </div>
  );
}
