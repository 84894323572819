/*eslint-disable eqeqeq*/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as S from './styles';
import { FaSearch, FaEllipsisV } from 'react-icons/fa';
import { Input, Button, IconButton, Dialog } from 'components/template';
import AthleteCard from 'components/AthleteCard';
import Helpers from 'shared/helpers';
import { getPreferentialFootWithLabel, positionOptions } from 'shared/mocks';
import { getList } from 'store/ducks/monitoringV2';
import { MinMaxDate } from 'pages/AdvancedSearch/Filter/CustomInputs/CustomInputs';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Variables from 'variables';
import { Creators } from 'store/ducks/projects';
import { useTranslation } from 'react-i18next';
import BarIdentification from '../../../MyClub/Negotiations/BarIdentification';

let updateWorkflowPosition = 0;
function Kanban({
  openFilter,
  openEditFrame,
  confirmDelete,
  deleteFrame,
  setDeleteFrame,
  enableAddAthlete,
  updateAthletesWorkflow,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({ name: '', position: '', list: '', foot: '', birthyear: { max: '', min: '' } });
  const list = useSelector(state => state.monitoringV2.data);
  const project = useSelector(state => state.project);
  const athletesWorkflow = project.data.workflow;
  const [listIds, setListIds] = useState([]);
  const [athletesFrames, setAthletesFrames] = useState({});
  const [currentFrame, setCurrentFrame] = useState('');
  const [deleting, setDeleting] = useState(false);
  const [removeAthleteData, setRemoveAthleteData] = useState(null);


  useEffect(() => {
    dispatch(getList());
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (athletesWorkflow != null && Object.keys(athletesFrames).length === 0) {
      const newAthletesFrames = {};
      (athletesWorkflow ?? []).forEach(athleteWorkflow => {
        let newAthletesFrame = newAthletesFrames[athleteWorkflow.frame];
        if (newAthletesFrame === undefined) {
          newAthletesFrames[athleteWorkflow.frame] = [];
          newAthletesFrame = newAthletesFrames[athleteWorkflow.frame];
        }
        newAthletesFrame.push(athleteWorkflow.athlete);
      });
      setAthletesFrames(newAthletesFrames);
    }
  }, [athletesWorkflow]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newAthletesFrames = {};
    const currentYear = new Date().getFullYear();
    const birthyearMin = filter['birthyear'].min === '' ? 0 : parseInt(filter['birthyear'].min);
    const birthyearMax = filter['birthyear'].max === '' ? 9999 : parseInt(filter['birthyear'].max);
    const filterList = filter['list'].length > 0 ? filter['list'].split(',') : [];
    (athletesWorkflow ?? []).forEach(athleteWorkflow => {
      let birthyearAthlete = new Date(athleteWorkflow.athlete.birthdate).getFullYear() ?? currentYear;
      birthyearAthlete = isNaN(birthyearAthlete) ? currentYear : birthyearAthlete;
      const athleteName = `${Helpers.normalizedString(athleteWorkflow.athlete.nickname)} ${Helpers.normalizedString(
        athleteWorkflow.athlete.fullname
      )}`;
      if (
        (filter['name'] === '' || athleteName.includes(filter['name'])) &&
        (filter['foot'] === '' || athleteWorkflow.athlete.foot === filter['foot']) &&
        (filter['position'] === '' ||
          athleteWorkflow.athlete.position === filter['position'] ||
          athleteWorkflow.athlete.position1 === filter['position']) &&
        (filterList.length === 0 || filterList.includes(athleteWorkflow.athlete.id.toString())) &&
        birthyearAthlete >= birthyearMin &&
        birthyearAthlete <= birthyearMax
      ) {
        let newAthletesFrame = newAthletesFrames[athleteWorkflow.frame];
        if (newAthletesFrame === undefined) {
          newAthletesFrames[athleteWorkflow.frame] = [];
          newAthletesFrame = newAthletesFrames[athleteWorkflow.frame];
        }
        newAthletesFrame.push(athleteWorkflow.athlete);
      }
    });
    setAthletesFrames(newAthletesFrames);
  }, [filter, updateAthletesWorkflow]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const listsAthletesIds = {};
    if (list && athletesWorkflow) {
      const defaultListName = t('filters.lists_default');
      athletesWorkflow.forEach(athleteWorkflow => {
        athleteWorkflow.athlete.lists.forEach(listId => {
          const aList = list.find(l => l.id === listId);
          if (aList) {
            const listName = aList.name === '_DEFAULT_' ? defaultListName : aList.name;
            const athleteIdWithComma = `,${athleteWorkflow.athlete.id}`;
            if (listsAthletesIds[listName]) {
              listsAthletesIds[listName] += athleteIdWithComma;
            } else {
              listsAthletesIds[listName] = athleteWorkflow.athlete.id.toString();
            }     
            if (aList.name !== '_DEFAULT_' && listsAthletesIds[defaultListName] && !listsAthletesIds[defaultListName].includes(athleteIdWithComma)) {
              listsAthletesIds[defaultListName] += athleteIdWithComma;
            }        
          }
        });
      });
    }
    setListIds(Object.keys(listsAthletesIds).map(listName => (
      { label: listName, value: listsAthletesIds[listName] }
    )));
  }, [list]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (confirmDelete) {
      deleteFrame(currentFrame);
      toogleUpdateWorkflowPanel(-1, '');
      setDeleteFrame(false);
    }
  }, [confirmDelete]); // eslint-disable-line react-hooks/exhaustive-deps

  const toogleUpdateWorkflowPanel = (index, frameName) => {
    for (let i = 0; i < Object.keys(project.data.frames).length; i++) {
      if (i !== index) {
        document.getElementById(`updateWorkflow${i}`).style.backgroundColor = 'unset';
      }
    }
    setCurrentFrame(frameName);
    const currentBC = index >= 0 ? document.getElementById(`updateWorkflow${index}`).style.backgroundColor : undefined;
    const dragDropContext = document.querySelector('#DragDropContext').firstChild;
    if (currentBC === 'unset' || currentBC === '') {
      updateWorkflowPosition = index * 376 + 260;
      dragDropContext.onscroll = () => {
        const scrollLeft = document.querySelector('#DragDropContext').firstChild.scrollLeft;
        document.getElementById('updateWorkflow').style.marginLeft = `${updateWorkflowPosition - scrollLeft}px`;
      };
      document.getElementById('updateWorkflow').style.display = 'block';
      document.getElementById('updateWorkflow').style.marginLeft = `${updateWorkflowPosition -
        dragDropContext.scrollLeft}px`;
      document.getElementById(`updateWorkflow${index}`).style.backgroundColor = Variables.gray5;
    } else {
      dragDropContext.onscroll = undefined;
      document.getElementById('updateWorkflow').style.display = 'none';
      if (index >= 0) {
        document.getElementById(`updateWorkflow${index}`).style.backgroundColor = 'unset';
      }
    }
  };

  return (
    <S.Card softShadow bordered>
      <BarIdentification title={t('proj.text21')} subtitle={t('proj.text22')} />
      <S.FilterDiv>
        <S.FilterInputContainer>
          <Input
            rounded
            name="name"
            onChange={e => {
              setFilter({ ...filter, name: Helpers.normalizedString(e.target.value) });
            }}
            inputStyle={{ width: 300 }}
            placeholder={t('user.text31')}
            icon={
              <FaSearch
                style={{
                  color: Variables.lightPurple1,
                  transform: 'rotate(90deg)',
                }}
              />
            }
            iconPosition="right"
          />
        </S.FilterInputContainer>
        <S.Menu
          title={t('agencies.lists')}
          largeMenu
          allowEmpty
          placeholder={t('agencies.lists')}
          value={filter['list']}
          setValue={list => setFilter({ ...filter, list: list })}
          onClear={() => setFilter({ ...filter, list: [] })}
          options={listIds}
        />
        <S.Menu
          title={t('athlete_list.box4')}
          largeMenu
          allowEmpty
          value={filter['position']}
          setValue={position => setFilter({ ...filter, position: position })}
          onClear={() => setFilter({ ...filter, position: '' })}
          options={positionOptions.map(option => {
            const positionName = t(`positionOptions.${option.value}`);
            return {
              label: positionName,
              value: positionName,
            };
          })}
          placeholder={t('athlete_list.box4')}
        />
        <S.Menu
          title={t('athlete_card.foot')}
          largeMenu
          allowEmpty
          value={filter['foot']}
          setValue={foot => setFilter({ ...filter, foot: foot })}
          onClear={() => setFilter({ ...filter, foot: '' })}
          options={getPreferentialFootWithLabel(t)}
          placeholder={t('athlete_card.foot')}
        />
        <S.MinMaxDateDiv>
          <span style={{ marginRight: '4px' }}>{t('athlete_card.birth')}</span>
          <MinMaxDate
            setFilter={values => setFilter({ ...filter, birthyear: { max: values.max, min: values.min } })}
            onClear={() => setFilter({ ...filter, birthyear: { ...filter.birthyear, min: '' } })}
            valueMin={filter['birthyear'].min}
            valueMax={filter['birthyear'].max}
            from={true}
          />
        </S.MinMaxDateDiv>
        <S.MinMaxDateDiv2>
          <span style={{ marginRight: '4px' }}>{t('athlete_card.birth')}</span>
          <MinMaxDate
            setFilter={values => setFilter({ ...filter, birthyear: { max: values.max, min: values.min } })}
            onClear={() => setFilter({ ...filter, birthyear: { ...filter.birthyear, max: '' } })}
            valueMin={filter['birthyear'].min}
            valueMax={filter['birthyear'].max}
            to={true}
          />
        </S.MinMaxDateDiv2>
      </S.FilterDiv>
      <S.UpdateWorkflow id="updateWorkflow">
        <button
          onClick={() => {
            openEditFrame({
              isOpen: true,
              frame: currentFrame,
              description: project.data.frames[currentFrame],
              saving: false,
            });
            toogleUpdateWorkflowPanel(-1, '');
          }}
        >
          {t('buttons.edit')}
        </button>
        <button
          onClick={() => {
            setDeleteFrame(true);
          }}
        >
          {t('buttons.excluir')}
        </button>
      </S.UpdateWorkflow>
      <div id={'DragDropContext'}>
        <DragDropContext
          onDragEnd={result => {
            const sourceFrame = result.source.droppableId;
            const destinationFrame = result.destination?.droppableId;
            const athleteId = parseInt(result.draggableId);
            if (destinationFrame) {
              if (sourceFrame === destinationFrame) {
                const sourceAthletesArray = [...athletesFrames[sourceFrame]];
                const [removedAthlete] = sourceAthletesArray.splice(result.source.index, 1);
                sourceAthletesArray.splice(result.destination.index, 0, removedAthlete);

                const newAthletesFrame = { ...athletesFrames };
                newAthletesFrame[sourceFrame] = sourceAthletesArray;
                setAthletesFrames(newAthletesFrame);

                const aw = athletesWorkflow.find(aw => aw.athlete.id === athleteId);
                aw.frame = destinationFrame;
                Creators.updateFrameWorkflow(project.data.id, aw.id, destinationFrame, result.destination.index).then(
                  data => {
                    if (data.error) {
                    }
                  }
                );
              } else {
                const sourceAthletesArray = [...athletesFrames[sourceFrame]];
                const [removedAthlete] = sourceAthletesArray.splice(result.source.index, 1);

                const destAthletesArray = [...(athletesFrames[destinationFrame] ?? [])];
                destAthletesArray.splice(result.destination.index, 0, removedAthlete);

                const newAthletesFrame = { ...athletesFrames };
                newAthletesFrame[sourceFrame] = sourceAthletesArray;
                newAthletesFrame[destinationFrame] = destAthletesArray;
                setAthletesFrames(newAthletesFrame);

                const aw = athletesWorkflow.find(aw => aw.athlete.id === athleteId);
                aw.frame = destinationFrame;
                Creators.updateFrameWorkflow(project.data.id, aw.id, destinationFrame, result.destination.index).then(
                  data => {
                    if (data.error) {
                    }
                  }
                );
              }
            }
          }}
        >
          <S.ColumnsContainer>
            {Object.entries(project.data?.frames ?? {}).sort((a, b) => a[1][0].localeCompare(b[1][0])).map(([frameName, frameDescription], index) => {
              const atletas = athletesFrames[frameName] ?? [];
              return (
                <div key={`kanban${frameName}`}>
                  <S.ColumnHeader>
                    <S.Title>
                      {frameDescription}
                      {atletas.length == 1 ? (
                        <small style={{ paddingLeft: '20px', fontWeight: '500' }}>
                          ({atletas.length} {t('rest.box21')})
                        </small>
                      ) : (
                        <small style={{ paddingLeft: '20px', fontWeight: '500' }}>
                          ({atletas.length.toLocaleString('pt-BR')} {t('proj.box20')})
                        </small>
                      )}
                    </S.Title>
                    <IconButton
                      color="white"
                      onClick={() => toogleUpdateWorkflowPanel(index, frameName)}
                      icon={<FaEllipsisV />}
                      id={`updateWorkflow${index}`}
                      style={{ boxShadow: 'none' }}
                    />
                  </S.ColumnHeader>
                  <S.ColumnBody>
                    <Droppable droppableId={frameName}>
                      {provided => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {atletas.map((athlete, index) => (
                            <Draggable
                              key={athlete.id}
                              draggableId={athlete.id.toString()}
                              index={index}
                              isDragDisabled={deleting}
                            >
                              {provided => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <AthleteCard
                                    deleteAthlete={() => setRemoveAthleteData({ id: athlete.id, atletas, frameName })}
                                    item={athlete}
                                    style={{ marginTop: '10px', marginBottom: '0' }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                          <div style={{ height: '15px' }}></div>
                        </div>
                      )}
                    </Droppable>
                  </S.ColumnBody>
                  <S.ColumnLink>
                    <Button
                      color="link"
                      noShadow
                      onClick={enableAddAthlete ? () => openFilter(frameName) : undefined}
                      style={enableAddAthlete ? {} : { color: '#b6b6b6', cursor: 'default' }}
                    >
                      {t('proj.text1')}
                    </Button>
                  </S.ColumnLink>
                </div>
              );
            })}
            <div
              style={{
                minWidth: '200px',
                height: '53px',
                paddingTop: '30px',
              }}
              align="center"
            >
              <Button
                color="link"
                noShadow
                smaller
                onClick={() => {
                  openEditFrame({ isOpen: true, frame: '', description: '', saving: false });
                }}
                style={enableAddAthlete ? {} : { color: '#b6b6b6', cursor: 'default' }}
              >
                {t('proj.text2')}
              </Button>
            </div>
          </S.ColumnsContainer>
        </DragDropContext>
      </div>
      <Dialog
        isOpen={removeAthleteData != null}
        onClose={() => {
          setRemoveAthleteData(null);
        }}
        mainTitle={t('proj.removeAthlete')}
        btnLeft={t('dialog_content.cancel')}
        btnLeftClick={() => {
          setRemoveAthleteData(null);
        }}
        btnRight={t('buttons.excluir')}
        btnRightClick={async () => {
          setRemoveAthleteData(null);
          setDeleting(true);
          try {
            const aw = athletesWorkflow.find(aw => aw.athlete.id === removeAthleteData.id);
            await Creators.deleteWorkflow(project.data.id, aw.id);
            const newAthleteArray = { ...athletesFrames };
            newAthleteArray[removeAthleteData.frameName] = removeAthleteData.atletas.filter(player => player.id !== removeAthleteData.id);
            setAthletesFrames(newAthleteArray);
            dispatch(Creators.getProject(project.data.id));
          } finally {
            setDeleting(false);
          }
        }}
      >
        <span style={{ marginBottom: '10px', marginLeft: '5px' }}>
          {t('proj.removeAthleteMessage')}
        </span>
      </Dialog>
    </S.Card>
  );
}

export default withRouter(Kanban);
