import styled from 'styled-components';

export const Container = styled.div`
    width: 350px;
    /* padding: 15px; */
    border-right: 0.5px solid lightgray;
    border-bottom: 0.5px solid lightgray;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px 20px;

    label {
        margin-bottom: 30px;
    }
`;