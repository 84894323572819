import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Input, Menu } from 'components/template';
import { FaSearch } from 'react-icons/fa';
import styles from './ActionBar.module.scss';
import { useTranslation } from "react-i18next";
import { positionOptions } from 'shared/mocks';

export default function ActionBar({
  filterMenu,
  setFilterMenu,
  nameInputValue,
  nameInputChangeHandler,
  season,
  maxAge,
  teams,
}) {
  const { t } = useTranslation();
  const [timeoutId, setTimeoutId] = useState(null);
  const [clubNameFilter, setClubNameFilter] = useState('');
  const [byYearList, setByYearList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    setClubNameFilter(nameInputValue);
  }, [nameInputValue]);

  useEffect(() => {
    if(filterMenu?.max_birthyear === filterMenu?.min_birthyear) {
      setSelectedYear(filterMenu?.max_birthyear);
    }
  }, [filterMenu]);

  const handleInputChange = (value) => {
    setClubNameFilter(value);
    clearTimeout(timeoutId);

    setTimeoutId(
      setTimeout(() => {
        if ((clubNameFilter !== '' && value === '') || value !== '') {
          nameInputChangeHandler(value);
        }
      }, 800)
    );
  }

  useEffect(() => {
    setByYearList(getPastYears(maxAge-3, season - maxAge -3));
  },[maxAge, season])

  function getPastYears(maxAge, currentYear) {
    let years = [];
    for (let i = 3; i < maxAge + 6; i++) {
        years.push({label: currentYear + i, value: (currentYear + i).toString()});
    }
    return years;
  }

  return (
    <div className={styles.actionBar}>
      <Input
        rounded
        placeholder={t("proj.atletaFiltroNome")}
        value={clubNameFilter}
        containerClassName={styles.inputContainer}
        onChange={e => handleInputChange(e.target.value)}
        icon={<FaSearch className={styles.icon} />}
        iconPosition="right"
      />
      {filterMenu?.min_birthyear &&
        <>
        <Menu
          largeMenu
          rounded
          placeholder={selectedYear || t("criteriaOptions.birthyear")}
          fixedPlaceholder
          className={styles.menu}
          options={byYearList}
          onClear={() => {
            setSelectedYear(null);
            setFilterMenu(f => ({ ...f, max_birthyear: null, min_birthyear: season - maxAge }))
          }}
          setValue={val => { 
            setSelectedYear(val);
            setFilterMenu(f => ({ ...f, max_birthyear: val, min_birthyear: val}))
          }}
        /></>
      }
      <Menu
        largeMenu
        rounded
        allowEmpty
        multiple
        placeholder={t("criteriaOptions.position")}
        fixedPlaceholder
        className={styles.menu}
        options={positionOptions
          .sort((a, b) => a.value.localeCompare(b.value))
          .map(position => ({ label: position.label, value: position.label }))}
        value={filterMenu.position}
        onClear={() => setFilterMenu(f => ({ ...f, position: [] }))}
        setValue={val => setFilterMenu(f => ({ ...f, position: val }))}
      />
      {teams && 
        <Menu
          largeMenu
          rounded
          allowEmpty
          placeholder={t("criteriaOptions.teamInComp")}
          className={styles.menu}
          options={teams
            .sort((a, b) => a.nickname.localeCompare(b.nickname))
            .map(team => ({ label: team.nickname, value: team.id }))}
          value={filterMenu.team}
          onClear={() => setFilterMenu(f => ({ ...f, team: [] }))}
          setValue={val => setFilterMenu(f => ({ ...f, team: val }))}
        />
      }
      <div className={styles.orderBy}>
        {t("filters.order_by")}: 
        <Menu
          largeMenu
          rounded
          className={styles.menu}
          options={[
            {label: t('criteriaOptions.minutes_played'), value: '-minutes_played'}, 
            {label: t('criteriaOptions.athlete__birthdate'), value: '-athlete__birthdate'}]
          }
          value={filterMenu.order_by}
          setValue={val => setFilterMenu(f => ({ ...f, order_by: val }))}
        />
      </div>
    </div>
  );
}

ActionBar.defaultProps = {
  // trashLoading: false,
};

ActionBar.propTypes = {
  nameInputValue: PropTypes.string.isRequired,
  nameInputChangeHandler: PropTypes.func.isRequired,
  filterMenu: PropTypes.instanceOf(Object).isRequired,
  setFilterMenu: PropTypes.func.isRequired,
  season: PropTypes.number.isRequired,
  maxAge: PropTypes.number.isRequired,
  teams: PropTypes.instanceOf(Object).isRequired,
};
