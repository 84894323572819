import styled from 'styled-components';
import Variables from 'variables.js';

export const FilterDiv = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    margin: 0px 18px;
    background-color: ${Variables.white};
`;

export const FilterInputContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

export const FilterLabelOrder = styled.span`
    margin: 0 8px 0 32px;
`;

export const ProjectsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 15px;
    padding: 0 20px;
`;

export const NoResults = styled.div`
    height: calc(100vh - 280px);
    text-align: center;

    & span {
        position: relative;
        top: 40%;
    }
`;

