import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import styles from './ResultItem.module.scss';

import { useTranslation } from 'react-i18next';

import { AthleteAvatar } from 'components/template';

function ResultItem({ item, handleItemClick }) {
  const { t } = useTranslation();
  return (
    <div onClick={() => handleItemClick(item.id)} key={item.id} className={styles.resultItem}>
      <AthleteAvatar img={item.photo} clubImg={item.team_emblem} className={styles.avatarDiv} />
      <div className={styles.generalInfo}>
        <h5>{item.nickname}</h5>
        <span>{item.fullname}</span>
      </div>
      <div className={styles.rightInfo}>
        {item.birthyear && (
          <span>
            '{item.birthyear.toString().substr(-2)} ({item.age} {t('athlete_card.idade')})
          </span>
        )}
        {item.position && <span>{t(`positionOptionsRaw.${item.position}`)}</span>}
        {item.nationalities?.length > 0 && (
          <span className={styles.flags}>
            {item.nationalities.map(i => (
              <img width="25" key={i.name} src={i.flag} alt={i.name} />
            ))}
          </span>
        )}
      </div>
    </div>
  );
}

ResultItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  handleItemClick: PropTypes.func.isRequired,
};

export default withRouter(ResultItem);
