import React from 'react';
import PropTypes from 'prop-types';

import { Backdrop } from 'components/template';
import Scrollbar from 'react-scrollbars-custom';
import styles from './NavDrawer.module.scss';

export default function NavDrawer({ isOpen, hide, children }) {
  const navDrawerClasses = [styles.navDrawer];

  if (isOpen) navDrawerClasses.push(styles.open);

  const body = document.querySelector('body');
  if (isOpen) {
    body.classList.add('overflowHidden');
  } else {
    body.classList.remove('overflowHidden');
  }

  return (
    <>
      <Backdrop style={{ top: '60px' }} onClick={hide} isVisible={isOpen} />

      <div className={navDrawerClasses.join(' ')}>
        <Scrollbar style={{ height: 'calc(100vh - 60px)' }}>
          {children}
        </Scrollbar>
      </div>
    </>
  );
}

NavDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
};
