/*eslint-disable eqeqeq*/
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Creators as layoutCreators } from 'store/ducks/layout';
import { getReportPDF, updateReportConfiguration } from 'store/ducks/reports';
import { Dialog, Button, Spinner } from 'components/template';
import styles from './ReportDialog.module.scss';
import { toJpeg } from 'html-to-image';
import { useTranslation } from 'react-i18next';
//import "../../translations/i18n"
import { Creators as layoutActions } from 'store/ducks/layout';

const ReportDialog = () => {
  const dispatch = useDispatch();
  const layout = useSelector(state => state.layout);
  const reports = useSelector(state => state.reports);
  // eslint-disable-next-line
  const [exporting, setExporting] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (reports.pdfGeneration.generated) {
      setExporting(false);
      onClose();
    }
  }, [reports.pdfGeneration.generated]); // eslint-disable-line react-hooks/exhaustive-deps

  const generateReport = async opt => {
    if (opt === 'print') {
      onClose();
      setTimeout(() => {
        try {
          if(!document.execCommand('print', false, null)) {
            window.print()
          }
        } catch {
          window.print()
        }
      }, 600);
    } else if (opt === 'export') {
      setExporting(true);
      let error = false;
      const reportData = reports?.report?.data;
      const reportSchema = reportData?.shadow_team?.schema;
      if (
        reportSchema &&
        (reportData?.shadow_team_athletes?.length ?? 0) > 0 &&
        reportData?.configuration.lastShadowTeamSchema != reportSchema
      ) {
        const configuration = { ...reportData.configuration };
        configuration.lastShadowTeamSchema = reportSchema;
        const shadowTeamReport = document.getElementById('time-sombra-image').firstChild;
        try {
          const imageBase64 = await toJpeg(shadowTeamReport, { quality: 0.95 });
          configuration.lastShadowTeamSchemaImage = imageBase64;
          await updateReportConfiguration(reportData.id, configuration);
          reportData.configuration = configuration;
        } catch (err) {
          error = true;
          dispatch(
            layoutActions.showToast({
              content: <span style={{ padding: '20px 0' }}>{t('report.relat_error')}</span>,
              duration: 3000,
            })
          );
        }
      }
      if (error) {
        setExporting(false);
      } else {
        dispatch(getReportPDF(reports.report.data?.id));
      }
    }
  };

  const onClose = () => {
    dispatch(layoutCreators.hideDialog());
    dispatch({ type: 'report/RESET_PDF' });
  };

  return (
    <Dialog
      isOpen={layout.isDialogVisible}
      onClose={onClose}
      mainTitle={reports.pdfGeneration?.loading ? t('report.pdf') : t('report.ex_relat')}
      btnRight={t('dialog_content.cancel')}
      btnRightClick={onClose}
    >
      <div className={styles.generateReportDiv}>
        {reports.report?.loading || reports.pdfGeneration?.loading ? (
          <div className={styles.loader}>
            <>
              <Spinner />
            </>
          </div>
        ) : t(reports.report?.error) || t(reports.pdfGeneration.error) ? (
          <>
            <h4>Erro</h4>
            <span>{t(reports.report?.error) || t(reports.pdfGeneration.error)}</span>
          </>
        ) : (
          <>
            <h4>{t('report.success')}</h4>
            <span>{t('report.generate')}</span>
            {/*
                            <Button
                                onClick={() => {
                                    if (!exporting) {
                                        generateReport("export");
                                    }
                                }}
                                rounded
                                smaller
                                color="green"
                                noShadow
                                className={styles.reportButton}
                                style={
                                    exporting ? {
                                        backgroundColor: 'lightgrey',
                                        cursor: 'default',
                                    } : undefined
                                }
                            >
                                {t("report.export_pdf")}
                            </Button>
                            */}
            <Button
              rounded
              smaller
              color="green"
              noShadow
              className={styles.reportButton}
              onClick={() => generateReport('print')}
            >
              {t('report.print_out')}
            </Button>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default withRouter(ReportDialog);

ReportDialog.defaultProps = {
  athletes: [],
};
