import styled from 'styled-components';
import { Form as FormikForm } from 'formik';
import Variables from 'variables.js';

export const CountryFlag = styled.img`
  height: 20px;
  width: 20px;
`;

export const FormElement = styled(FormikForm)`
  display: flex;
  justify-content: center;
`;

export const AvatarContainer = styled.div`
  margin-bottom: 20px;
  display: inline-flex;
`;

export const AvatarUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  justify-content: center;
`;

export const AvatarButtonTitle = styled.span`
  text-transform: uppercase;
  color: ${Variables.dark};
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  margin-bottom: 5px;
`;

export const ContentContainer = styled.div`
  min-height: 50vh;
  text-align: -webkit-center;

  label {
    text-align: start;
  }
`;

export const ErrorMessage = styled.div`
  margin-top: 30px;
`;

export const ErrorText = styled.span`
  color: ${Variables.danger};
`;

export const ToastMessage = styled.span`
  padding: 20px 0;
`;
