import React from 'react';
import PropTypes from 'prop-types';

import styles from './ClickAwayListener.module.scss';

export default function ClickAwayListener({ onClick }) {
  return <div role='button' tabIndex='0' className={styles.clickAwayListener} onClick={onClick} />;
}

ClickAwayListener.propTypes = {
  onClick: PropTypes.func.isRequired,
};
