// hooks
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

//firebase
import { firebase, firestore } from 'App/App';
import { collection, query, where, orderBy, limit, doc, onSnapshot } from "firebase/firestore";

// other
import { NegotiationMessageRepository } from 'data/negotiationMessages';
import { AthleteChatMessagesRepository } from 'data/athleteChatMessages';
import { TeamChatMessagesRepository } from 'data/teamChatMessages';
import { AgencyChatMessagesRepository } from 'data/agencyChatMessages';
import { formatDate } from 'shared/utility';
import moment from 'moment';
import { decryptJson } from 'shared/crypto';

let _currentOffset = 0;
let _unsubscribe;
let _updateVisualizedTimeout;
export default function useMessages(selectedRepository, selectedChat, setChatLoading) {
  const [messages, setMessages] = useState([]);
  const otherOrgIdRef = useRef(null);
  const messagesRef = useRef([]);
  const [messageGroups, setMessageGroups] = useState([]);
  const [error, setError] = useState(null);

  const loggedUser = useSelector(state => state.user.data);

  const repositoryToPropName = {
    negociacoes: 'negotiations',
    atletas: 'athletechat',
    clubes: 'orgchat',
    agentes: 'orgchat',
  };

  const repositoryObject = {
    negociacoes: {
      list: async params => await NegotiationMessageRepository.list(params),
      create: async params => await NegotiationMessageRepository.create(otherOrgIdRef.current, selectedChat.secret, params),
      deleteMessages: async ids => await NegotiationMessageRepository.deleteMessages(selectedChat.id, ids),
    },
    atletas: {
      list: async params => await AthleteChatMessagesRepository.list(params),
      create: async params => await AthleteChatMessagesRepository.create(otherOrgIdRef.current, selectedChat.secret, params),
      deleteMessages: async ids => await AthleteChatMessagesRepository.deleteMessages(selectedChat.id, ids),
    },
    clubes: {
      list: async params => await TeamChatMessagesRepository.list(params),
      create: async params => await TeamChatMessagesRepository.create(otherOrgIdRef.current, selectedChat.secret, params),
      deleteMessages: async ids => await TeamChatMessagesRepository.deleteMessages(selectedChat.id, ids),
    },
    agentes: {
      list: async params => await AgencyChatMessagesRepository.list(params),
      create: async params => await AgencyChatMessagesRepository.create(otherOrgIdRef.current, selectedChat.secret, params),
      deleteMessages: async ids => await AgencyChatMessagesRepository.deleteMessages(selectedChat.id, ids),
    },
  };

  async function loadMessages(id, offset) {
    if (id != null && id > 0) {
      setChatLoading(true);
      const response = await repositoryObject[selectedRepository].list({
        [repositoryToPropName[selectedRepository]]: id,
        offset: offset,
        limit: 20,
      });

      if (response && response.results) {
        _currentOffset = offset;
        otherOrgIdRef.current = response.other_org_id;
        if (offset === 0) {
          setMessages(response.results);
        } else {
          setMessages([...messages, ...response.results]);
        }
        setChatLoading(false);
      } else {
        setChatLoading(false);
        setError('Não foi possível carregar as mensagens');
        return;
      }
    }
  }

  const submitMessage = async message => {
    if (message == null || (message.text === '' && message.file_type === '')) return;
    const newMessage = await repositoryObject[selectedRepository].create({
      ...message,
      sender: loggedUser.id,
      [repositoryToPropName[selectedRepository]]: selectedChat.id,
    });

    if (newMessage) setMessages([newMessage, ...messages]);
  };

  const nextMessagesPage = () => loadMessages(selectedChat?.id, _currentOffset + 20);

  const deleteMessages = async ids => {
    const data = await repositoryObject[selectedRepository].deleteMessages(ids);
    if (data?.error == null) {
      messages.filter(m => ids.includes(m.id)).forEach(message => {
        message.deleted_at = (new Date()).toString();
        message.message = 'Esta mensagem foi excluída';
      });
      setMessages([...messages]);
    }
    return data;
  };

  useEffect(() => {
    return () => {
      if (_unsubscribe != null) {
        _unsubscribe();
      }
    };
  }, [firebase]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedRepository != null) {
      loadMessages(selectedChat?.id, 0).then(() => {
        if (selectedChat != null) {
          if (_unsubscribe != null) {
            _unsubscribe();
          }
  
          const repository = `${repositoryToPropName[selectedRepository]}-${process.env.REACT_APP_FIREBASE_SUFFIX}`;
          const repositoryDataRef = collection(doc(firestore, repository, selectedChat.id.toString()), "data");
          const firebaseBatchLimit = 10;
          const q = query(repositoryDataRef, where("sender_id", "!=", loggedUser.id), orderBy("created_at", "desc"), limit(firebaseBatchLimit));
          _unsubscribe = onSnapshot(q, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
              const messagesLength = messagesRef.current.length;
              if (change.type === "added") {
                const newMessage = decryptJson(change.doc.data()['data'], selectedChat.secret);
                let addMessage = messagesLength === 0;
                if (messagesLength > 0) {
                  const lastLoadedMessageDate = moment(messagesRef.current[messagesLength-1].created_at ?? '2024-01-01');
                  const newMessageDate = moment(newMessage.created_at ?? '2024-01-01');
                  addMessage = !newMessageDate.isBefore(lastLoadedMessageDate) && !messagesRef.current.find(m => m.id === newMessage.id);
                }
                if (addMessage) {
                  if (_updateVisualizedTimeout == null) {
                    _updateVisualizedTimeout = setTimeout(() => {
                      //serve para que o backend atualize a data da ultima mensagem visualizada.
                      repositoryObject[selectedRepository].list({ limit: 1, [repositoryToPropName[selectedRepository]]: selectedChat.id, });
                      _updateVisualizedTimeout = null;
                    }, 2000);
                  }  
                  newMessage.sender = {};
                  for (const key of Object.keys(newMessage)) {
                    if (key.includes('sender_')) {
                      newMessage.sender[key.substring(7)] = newMessage[key];
                      delete newMessage[key];
                    }
                  }
                  setMessages(prevMessages => [ newMessage, ...prevMessages ]);
                  _currentOffset++;
                }
              }
              //se for o ultimo da lista, é só o firebase reajustando a lista.
              //  aqui há uma limitação pois se foi apagado justamente esse registro, 
              //  então o user conseguirá ler uma mensagem que ja foi apagada até dar um F5 ou navegar pelo site
              if (change.type === "removed" && messagesLength > 0 && change.oldIndex < firebaseBatchLimit-1) { 
                const removedMessage = decryptJson(change.doc.data()['data'], selectedChat.secret);
                const newMessages = [ ...messagesRef.current ];
                const messageDeleted = newMessages.find(m => m.id === removedMessage.id);
                messageDeleted.deleted_at = moment().toISOString();
                messageDeleted.message = 'Esta mensagem foi excluída';
                setMessages(newMessages);
              }
            });
          });     
        }
      });
    }
  }, [selectedRepository, selectedChat]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedChat) {
      setMessages(prevMessages => prevMessages.filter(message => message.negotiations === selectedChat.id));
    }
  }, [selectedChat]);

  useEffect(() => {
    messagesRef.current = messages;
    setMessageGroups(
      messages
        ?.reduce((groups, message) => {
          const date = formatDate(message.created_at);
          const existingGroup = groups.find(group => group.date === date);
          if (existingGroup) {
            existingGroup.messages.push(message);
          } else {
            groups.push({
              date: date,
              messages: [message],
            });
          }

          return groups;
        }, [])
        .reverse()
    );
  }, [messages]);

  return {
    submitMessage,
    deleteMessages,
    nextMessagesPage,
    messages,
    messageGroups,
    loggedUser,
    error,
  };
}
