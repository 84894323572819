import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Slider } from '@material-ui/core';
import Variables from 'variables';
import styles from './Slider.module.scss';

const useStyles = makeStyles(theme => ({
  root: {
    width: 300,
  },
  margin: {
    height: theme.spacing(3),
  },
  thumb: {
    background: Variables.white,
    border: `solid 4px ${Variables.lightPurple1}`,
    width: '20px',
    height: '20px',
    marginLeft: '-9px',
    marginTop: '-6px',
  },
  mark: {
    background: Variables.gray5,
    height: '14px',
    marginTop: '-3px',
    width: '2px',
  },
  rail: {
    background: `linear-gradient(to right, ${Variables.red}, ${Variables.red} 33.33%, ${Variables.green2} 33.33%, ${Variables.green2});`,
    opacity: 1,
    height: '8px',
  },
  track: {
    background: Variables.orange2,
    height: '8px',
  },
  valueLabel: {
    "&>*": {
      background: "black"
    }
  }
})
);

const ContractSlider = ({ value, setValue }) => {
  const classes = useStyles();

  const value0 = value[0];
  const value1 = value[1];
  const maxValue = 36;

  return <div className={styles.slider}>
    <Slider
      classes={{
          thumb: classes.thumb,
          rail: classes.rail,
          track: classes.track,
          valueLabel: classes.valueLabel,
          mark: classes.mark
      }}
      value={value}
      onChange={(_, newValue) => {
        if (newValue[0] < 1) {
          newValue[0] = 1;
        } else if (newValue[0] > 12) {
          newValue[0] = 12;
        }
        if (newValue[1] < 12) {
          newValue[1] = 12;
        } else if (newValue[1] >= maxValue) {
          newValue[1] = maxValue-1;
        }
        setValue(newValue);                  
      }}
      valueLabelDisplay="auto"
      min={0}
      max={maxValue}
      marks
    />
    <div className={styles.values}>
      <div>0</div>
      <div style={{left: `calc(${value0/maxValue*100}% - 10px)`}}>{value0}</div>
      <div style={{left: `calc(${value1/maxValue*100}% - 10px)`}}>{value1}</div>
      <div>{maxValue}</div>
    </div>
  </div>

}

export default ContractSlider;