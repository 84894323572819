/*eslint-disable eqeqeq*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from 'store/ducks/transferWindows';
import { getCountries } from 'store/ducks/countries';
import { Card, Spinner, Table, Pagination } from 'components/template';
import styles from './List.module.scss';
import moment from 'moment';
import ActionBar from '../ActionBar/ActionBar';
import { usePagination } from 'shared/hooks';
import CountryRow from 'components/CountryRow/CountryRow';
import { useTranslation } from 'react-i18next';
import MonitoringTransferWindowsSwitch from './MonitoringTransferWindowsSwitch';

export default function List() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const transferWindows = useSelector(state => state.transferWindows.transferWindowsPage);
  const countries = useSelector(state => state.countries.countries);

  const [checkboxFilter, setCheckboxFilter] = useState({
    is_open: '',
    monitoring: '',
  });

  const [countriesFilter, setCountriesFilter] = useState({
    currentState: [],
  });

  const [categoriesFilter, setCategoriesFilter] = useState({
    currentState: null,
  });

  const [statusesFilter, setStatusesFilter] = useState({
    currentState: null,
  });

  const [pagination, setPagination] = usePagination();

  useEffect(() => {
    if (countries.data.length == 0) {
      dispatch(getCountries());
    }
  }, [dispatch, countries.data]);

  const loadTransferWindows = () => {
    let filters = {
      ...pagination,
      ...checkboxFilter,
      order: 'country.name',
    };
    if (categoriesFilter.currentState?.length) filters.category = categoriesFilter.currentState;
    if (statusesFilter.currentState?.length) filters.is_open = statusesFilter.currentState;
    if (countriesFilter.currentState?.length) filters.country = countriesFilter.currentState;

    dispatch(Creators.getTransferWindows(filters, 'recent'));
  };

  useEffect(() => {
    loadTransferWindows();
    return () => {
      dispatch(Creators.resetTransferWindows());
    };
  }, [dispatch, countriesFilter, categoriesFilter, statusesFilter, pagination, checkboxFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  const rawData = transferWindows.data.data;

  const data =
    rawData &&
    rawData.map(row => {
      const monitoring = <MonitoringTransferWindowsSwitch row={row} />
      const country = (
        <div className={styles.countryRowContainer}>
          <CountryRow name={row.country?.name} id={row.country?.code} img={row.country?.flag} />
        </div>
      );
      const category = t(`listTranslation.${row.category}`);
      const season = row.season;
      const seasonStart = moment(row.season_start).format('DD/MM/YYYY');
      const seasonEnd = moment(row.season_end).format('DD/MM/YYYY');
      const window1Start = row.window1_start ? moment(row.window1_start).format('DD/MM/YYYY') : 'N/D';
      const window1End = row.window1_end ? moment(row.window1_end).format('DD/MM/YYYY') : 'N/D';
      const window1Text = window1Start + ' - ' + window1End;
      const window1Class =
        row.window1_start && row.window1_end
          ? moment()
            .startOf('day')
            .isAfter(moment(row.window1_end))
            ? styles.windowPast
            : moment()
              .startOf('day')
              .isBefore(moment(row.window1_start))
              ? styles.windowFuture
              : styles.windowCurrent
          : styles.windowNotDefined;
      const window1 = <span className={window1Class}>{window1Text}</span>;
      const window2Start = row.window2_start ? moment(row.window2_start).format('DD/MM/YYYY') : 'N/D';
      const window2End = row.window2_end ? moment(row.window2_end).format('DD/MM/YYYY') : 'N/D';
      const window2Text = window2Start + ' - ' + window2End;
      const window2Class =
        row.window2_start && row.window2_end
          ? moment()
            .startOf('day')
            .isAfter(moment(row.window2_end))
            ? styles.windowPast
            : moment()
              .startOf('day')
              .isBefore(moment(row.window2_start))
              ? styles.windowFuture
              : styles.windowCurrent
          : styles.windowNotDefined;
      const window2 = <span className={window2Class}>{window2Text}</span>;
      const window3Start = row.window3_start ? moment(row.window3_start).format('DD/MM/YYYY') : 'N/D';
      const window3End = row.window3_end ? moment(row.window3_end).format('DD/MM/YYYY') : 'N/D';
      const window3Text = window3Start + ' - ' + window3End;
      const window3Class =
        row.window3_start && row.window3_end
          ? moment()
            .startOf('day')
            .isAfter(moment(row.window3_end))
            ? styles.windowPast
            : moment()
              .startOf('day')
              .isBefore(moment(row.window3_start))
              ? styles.windowFuture
              : styles.windowCurrent
          : styles.windowNotDefined;
      const window3 = <span className={window3Class}>{window3Text}</span>;

      return [monitoring, country, category, season, seasonStart, seasonEnd, window1, window2, window3];
    });

  if (transferWindows.error) {
    return (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(transferWindows.error) || t(countries.error)}</p>
      </Card>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <Card softShadow className={styles.actionBar}>
          <ActionBar
            stateOptions={[]}
            categoriesOptions={[]}
            categoriesFilter={categoriesFilter}
            countriesFilter={countriesFilter}
            setCategoriesFilter={setCategoriesFilter}
            statusesFilter={statusesFilter}
            setStatusesFilter={setStatusesFilter}
            setCountriesFilter={setCountriesFilter}
            countries={countries.data}
            checkboxFilter={checkboxFilter}
            setCheckboxFilter={setCheckboxFilter}
          />
        </Card>
        {transferWindows.loading || countries.loading ? (
          <div className={styles.loaderContainer}>
            <Spinner />
          </div>
        ) : (
          <Card softShadow className={styles.transferWindows}>
            <div className={styles.tableContainer}>
              <Table
                flat
                theads={[
                  t('config.mon'),
                  t('compe.text1'),
                  t('user.text21'),
                  t('slider_menu.seas'),
                  t('user.text24'),
                  t('user.text25'),
                  t('user.text26'),
                  t('user.text27'),
                  t('user.text28'),
                ]}
                className={styles.listTable}
                data={data}
              />
            </div>
          </Card>
        )}
      </div>
      {rawData && transferWindows.data && transferWindows.data.count > rawData.length && (
        <Pagination
          length={rawData.length}
          count={transferWindows.data.count}
          pagination={pagination}
          setPagination={setPagination}
          hasNext={transferWindows.data.hasNext}
          hasPrevious={transferWindows.data.hasPrevious}
          resourceDisplayName={t('user.text29')}
        />
      )}
    </>
  );
}

List.defaultProps = {
  division: 0,
};

List.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  division: PropTypes.number,
};
