import axios from 'api';
import Endpoints from 'endpoints';
import logger from 'shared/Logger';
import ErrorMessages from 'shared/ErrorMessages';
import { parseOrganizationUpdate } from 'shared/utility';

export const Types = {
    GET_ORGANIZATION: 'organization/GET_ORGANIZATION',
    GET_ORGANIZATION_FAILED: 'organization/GET_ORGANIZATION_FAILED',
    GET_ORGANIZATION_SUCCESS: 'organization/GET_ORGANIZATION_SUCCESS',
    RESET_ORGANIZATION: 'organization/RESET_ORGANIZATION',

    UPDATE_ORGANIZATION: 'organization/UPDATE_ORGANIZATION',
    UPDATE_ORGANIZATION_FAILED: 'organization/UPDATE_ORGANIZATION_FAILED',
    UPDATE_ORGANIZATION_SUCCESS: 'organization/UPDATE_ORGANIZATION_SUCCESS',
};

const initialState = {
    loading: false,
    error: null,
    data: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.GET_ORGANIZATION:
            return { ...initialState, loading: true };
        case Types.GET_ORGANIZATION_FAILED:
            return { ...initialState, loading: false, error: action.payload.error };
        case Types.GET_ORGANIZATION_SUCCESS:
            return { ...state, data: action.payload.data, loading: false };
        case Types.RESET_ORGANIZATION:
            return { ...initialState };
        case Types.UPDATE_ORGANIZATION:
            return {
                ...state,
                loading: true
            };
        case Types.UPDATE_ORGANIZATION_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                error: null
            };
        case Types.UPDATE_ORGANIZATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        default:
            return state;
    }
};

export const getOrganization = (id) => async dispatch => {
    dispatch({ type: Types.GET_ORGANIZATION });
    try {
        const { data } = await axios.get(`${Endpoints.organizations}/${id}/`);
        dispatch({
            type: Types.GET_ORGANIZATION_SUCCESS,
            payload: { data }
        });

    } catch (err) {
        logger.error(err.message);
        dispatch({ type: Types.GET_ORGANIZATION_FAILED, payload: { error: ErrorMessages.serviceUnavailable } });
    }
};

export const reset = () => ({ type: Types.RESET_ORGANIZATION });

export const updateOrganization = (id, flatData, orgType) => async dispatch => {

    dispatch({ type: Types.UPDATE_ORGANIZATION });

    const url = Endpoints.organizations;

    try {

        // const config = {
        //     headers: {
        //         'content-type': 'multipart/form-data'
        //     }
        // }

        const updatedData = parseOrganizationUpdate(flatData, orgType);

        // const formData = new FormData();

        const { data } = await axios.patch(`${url}/${id}`, updatedData);



        dispatch({ type: Types.UPDATE_ORGANIZATION_SUCCESS, payload: { data } })
    } catch (err) {
        const { response } = err;

        if (response?.status === 400) {
            dispatch({
                type: Types.UPDATE_ORGANIZATION_FAILED,
                payload: { error: "erros.text30" }
            });
        } else {
            dispatch({
                type: Types.UPDATE_ORGANIZATION_FAILED,
                payload: { error: "erros.text30" }
            })
        }

    }
};

export const updateOrganizationImage = (id, file) => async dispatch => {

    dispatch({ type: Types.UPDATE_ORGANIZATION });

    const url = Endpoints.organizations;

    try {

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        // const updatedData = parseOrganizationUpdate(flatData);

        const formData = new FormData();

        formData.append('emblem', file)

        const { data } = await axios.patch(`${url}/${id}`, formData, config);



        dispatch({ type: Types.UPDATE_ORGANIZATION_SUCCESS, payload: { data } })
    } catch (err) {
        dispatch({
            type: Types.UPDATE_ORGANIZATION_FAILED,
            payload: { error: "erros.text50" }
        })
    }
};