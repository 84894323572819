import React from 'react';
import PropTypes from 'prop-types';

import styles from './Performance.module.scss';
import TimeIco from 'assets/ico/ico-tempo.png';
import GamesIco from 'assets/ico/ico-jogos.png';
import FirstStringIco from 'assets/ico/ico-titular.png';
import GoalsIco from 'assets/ico/ico-gols.png';
import BenchIco from 'assets/ico/ico-bench.png';
import YellowCardsIco from 'assets/ico/ico-amarelos.png';
import YellowRedCardsIco from 'assets/ico/ico-amarelo-vermelho.png';
import RedCardsIco from 'assets/ico/ico-vermelhos.png';
import { useTranslation } from 'react-i18next';
import { Table } from 'components/template';

export default function Performance({ data, athleteId, redirect }) {
  const { t } = useTranslation();
  const theads = [
    t('slider_menu.seas'),
    t('match.comp'),
    t('athlete_list.box2'),
    //t('overview.text26'),
    <img src={TimeIco} alt="" draggable={false} />,
    <img src={GamesIco} alt="" draggable={false} />,
    <img src={FirstStringIco} alt="" draggable={false} />,
    <img src={BenchIco} alt="" draggable={false} />,
    <img src={GoalsIco} alt="" draggable={false} />,
    <img src={YellowCardsIco} alt="" draggable={false} />,
    <img src={YellowRedCardsIco} alt="" draggable={false} />,
    <img src={RedCardsIco} alt="" draggable={false} />,
  ];

  const tdata = data
    ? data.slice(0, 5).map(d => {
        const club = (
          <div className={styles.withImage}>
            <img src={d.team?.emblem} alt="" draggable="false" />
            <span>{d.team?.nickname}</span>
          </div>
        );
        /*
        const availability = (
          <div className={styles.interestedVisualizedContainer}>
            <div className={styles.interestedVisualizedCounter}></div>
            <div className={styles.progressBarContainer}>
              <div className={styles.progressBar} style={{ width: `${20}%` }}></div>
            </div>
          </div>
        );
        */

        return [
          d.season || '-',
          d.competition_name || '-',
          club,
          //availability || '-',
          d.minutes_played || '-',
          d.appearances || '-',
          d.lineups || '-',
          d.on_bench || '-',
          d.goals || '-',
          d.yellow_cards || '-',
          d.second_yellow_cards || '-',
          d.red_cards || '-',
        ];
      })
    : [];

  const tfooter = (
    <div onClick={() => redirect.push(`/atletas/${athleteId}/desempenho`)} className={styles.link}>
      {t('overview.text19')}
    </div>
  );

  return (
    <div className={styles.performance}>
      <h3>{t('overview.text23')}</h3>
      <Table className={styles.competition} footer={tfooter} theads={theads} data={tdata} />
    </div>
  );
}

Performance.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  baseURL: PropTypes.string.isRequired,
};
