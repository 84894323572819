import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, useLocation, Link, useParams, useHistory } from 'react-router-dom';
import { useQuery } from 'shared/hooks';
import styles from './AppBar.module.scss';
import { BiArrowBack } from 'react-icons/bi';
import { Button } from 'components/template';
import { useTranslation } from 'react-i18next';

function AppBar({ showDialog }) {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const ids = query.get('ids')?.split(',');

  const location = useLocation();
  const { id } = useParams();

  const type = location.state ? location.state.type : 'edition';

  const pathname = id ? `/relatorios/${id}` : `/relatorios/criar`;
  const search = ids ? `?ids=${ids}` : '';

  return (
    <div className={styles.container}>
      <div className={styles.pageBack}>
        <Button
          onClick={() => {
            if (window.history.length === 1) {
              window.location.href = '/';
            } else {
              window.history.back();
            }
          }}
          smaller
          noShadow
        >
          <BiArrowBack />
        </Button>
        <h2>{t('app.box20')}</h2>
      </div>
      <div className={styles.tabs}>
        <Link
          to={{
            pathname,
            search,
            state: { type: 'edition' },
          }}
        >
          <Button className={type === 'edition' ? styles.selected : ''} smaller>
            {t('app.text1')}
          </Button>
        </Link>
        <Link
          to={{
            pathname,
            search,
            state: { type: 'preview' },
          }}
        >
          <Button className={type === 'preview' ? styles.selected : ''} smaller>
            {t('app.text2')}
          </Button>
        </Link>
      </div>
      <div className={styles.reportExport}>
        {/* {type === "preview" ? */}
        <Button
          onClick={() => {
            history.push({
              pathname,
              search,
              state: { type: 'preview' },
            });
            showDialog();
          }}
          color="green"
          rounded
          smaller
        >
          {t('report.ex_relat')}
        </Button>
        {/* : */}
        {/* <div style={{ width: "35px" }}></div> */}
        {/* } */}
      </div>
    </div>
  );
}

AppBar.defaultProps = {
  history: null,
};

AppBar.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  showDialog: PropTypes.func.isRequired,
};

export default withRouter(AppBar);
