import React from 'react';
import { useTranslation } from 'react-i18next';

// scss
import styles from './Items.module.scss';

// icons
import TransferMarketLogo from 'assets/ico/menu/transfermarket.svg';
import SearchLogo from 'assets/ico/menu/search.svg';
import AgentsLogo from 'assets/ico/menu/agents.svg';
import ClubsLogo from 'assets/ico/menu/clubs.svg';
// import NetworkLogo from 'assets/ico/menu/network.svg';

// components

function MyMessages(activeItem) {
  const { t } = useTranslation();
  const getItemContent = (repository, icon, label) => (
    <div className={styles[repository]}>
      <div className={styles.icon}>
        <img src={icon} alt={repository} />
      </div>
      <div className={styles.label_container}>
        <span>{label}</span>
      </div>
    </div>
  );

  const menuItems = [
    {
      repository: 'connections',
      content: getItemContent('connections', TransferMarketLogo, t('chat.connections')),
    },
  ];

  return menuItems.map(item => ({
    ...item,
    content: (
      <div className={activeItem === item.repository ? styles[`${item.repository}_active`] : styles[item.repository]}>
        {item.content}
      </div>
    ),
  }));
}

function OrganizationMessages(activeItem, notifications) {
  const { t } = useTranslation();
  const getItemContent = (repository, icon, label, notificationCount) => (
    <div className={styles[repository]}>
      <div className={styles.icon}>
        <img src={icon} alt={repository} />
      </div>
      <div className={styles.label_container}>
        <span>{label}</span>
        {!!notificationCount && (
          <div className={styles.counter}>{notificationCount}</div>
        )}
      </div>
    </div>
  );

  const menuItems = [
    {
      repository: 'negociacoes',
      content: getItemContent('negociacoes', TransferMarketLogo, t('chat.negotiations'), notifications?.data?.newTransferMarketCount),
    },
    {
      repository: 'atletas',
      content: getItemContent('atletas', SearchLogo, t('chat.athletes'), notifications?.data?.newAthletechatCount),
    },
    {
      repository: 'clubes',
      content: getItemContent('clubes', ClubsLogo, t('chat.clubs'), notifications?.data?.newTeamchatCount),
    },
    {
      repository: 'agentes',
      content: getItemContent('agentes', AgentsLogo, t('chat.agents'), notifications?.data?.newOrgchatCount),
    },
  ];

  return menuItems.map(item => ({
    ...item,
    content: (
      <div className={activeItem === item.repository ? styles[`${item.repository}_active`] : styles[item.repository]}>
        {item.content}
      </div>
    ),
  }));
}
export { MyMessages, OrganizationMessages };
