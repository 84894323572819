import React, { useEffect, useState } from 'react';
import styles from './Header.module.scss';
import { useTranslation } from 'react-i18next';
import { Input, Menu } from 'components/template';
import Helpers from 'shared/helpers';
import { FaArrowLeft, FaSearch } from 'react-icons/fa';

export default function Header({ onFilterChange = () => { }, chat, otherChatActive, otherChatClose }) {
  const { t } = useTranslation();
  const [filter, setFilter] = useState({ query: null, transferType: null });
  const handleInputChange = e => {
    e.persist();
    setFilter({ ...filter, [e.target.name]: e.target.value ? Helpers.normalizedString(e.target.value) : null });
  };

  useEffect(() => {
    onFilterChange(filter);
  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  const defaultComponent = (
    <div className={styles.container}>
      <div className={styles.FilterInputContainer}>
        <Input
          rounded
          name="query"
          onChange={e => handleInputChange(e)}
          inputStyle={{ backgroundColor: '#fff' }}
          placeholder={t('transferMarket.filterMessagesPlaceholder')}
          icon={<FaSearch className={styles.searchIcon} />}
          iconPosition="right"
        />
      </div>

      <div className={styles.FilterMenuContainer}>
        <Menu
          rounded
          title={t('transferMarket.headerTransferTypeTitle')}
          placeholder={t(`transferMarket.filterTransferTypePlaceholder`)}
          options={[
            {
              label: t(`transferMarket.filterTransferTypePlaceholder`),
              value: '',
            },
            {
              label: t(`transferMarket.filterTransferTypeSALE`),
              value: 'SALE',
            },
            {
              label: t(`transferMarket.filterTransferTypeLOAN`),
              value: 'LOAN',
            },
          ]}
          value={filter.transferType ?? ''}
          setValue={value => setFilter({ ...filter, transferType: value })}
          className={styles.FilterMenu}
        />
      </div>
    </div>
  );

  const otherChatComponent = (
    <div className={styles.container}>
      <span className={styles.otherChatClose} onClick={otherChatClose}>
        <FaArrowLeft />
      </span>

      <span className={styles.otherChatText}>
        {t('negotiations.headerOtherChatTitle')}
        <b>{chat?.athlete?.shortName}</b>
      </span>
    </div>
  );

  return otherChatActive ? otherChatComponent : defaultComponent;
}
