/*eslint-disable eqeqeq*/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAgency, getAgencyAthletes, resetAgency, resetAthletes } from 'store/ducks/agencies';
import { getList } from 'store/ducks/monitoringV2';
import Page from 'components/template/Page';
import CenteredLoader from 'components/template/CenteredLoader';
import { usePagination } from 'shared/hooks';
import ActionBar from './ActionBar/ActionBar';
import { Checkbox, Table } from 'components/template';
import AthleteRow from 'components/AthleteRow/AthleteRow';
import NationalitiesRow from 'components/NationalitiesRow/NationalitiesRow';
import MonitoringButton from 'components/MonitoringButton/MonitoringButton';
import moment from 'moment';
import styles from './styles.module.scss';
import TeamRow from 'components/TeamRow/TeamRow';
import { useTranslation } from 'react-i18next';
import CustomTopBar from './CustomTopBar';
import AgencyInfo from './AgencyInfo/AgencyInfo';
// import Users from './Users/Users';

const AgencyAthletes = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { id } = useParams();
  const [pagination, setPagination, defaultPagination] = usePagination();

  const [filteredList, setFilteredList] = useState([]);
  const [checkedAthletes, setCheckedAthletes] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [athletes, setAthletes] = useState([]);
  const [athletesAsRows, setAthletesAsRows] = useState([]);

  const today = new Date();

  const [selectedFilters, setSelectedFilters] = useState({
    name: '',
    position: [],
    lists: [],
    min_birthyear: 1975,
    max_birthyear: today.getFullYear(),
  });

  const { agency, agencyAthletes, list } = useSelector(state => ({
    agency: state.agencies.instance,
    agencyAthletes: state.agencies.athletes,
    list: state.monitoringV2,
  }));

  useEffect(() => {
    if (!agency.data) {
      dispatch(getAgency(id));
    }

    dispatch(getList());
    dispatch(getAgencyAthletes(id, { ...selectedFilters, ...defaultPagination }));

    return () => {
      dispatch(resetAgency());
      dispatch(resetAthletes());
    };
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (agencyAthletes.data != null) {
      setAthletes(agencyAthletes.data);
    }
  }, [agencyAthletes.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilteredList(
      list.data.filter(item => item.name !== '_DEFAULT_').map(item => ({ label: item.name, value: String(item.id) }))
    );
  }, [list.data]);

  useEffect(() => {
    if (allChecked) {
      setCheckedAthletes(athletes.map(athlete => ({ id: athlete.id, name: athlete.fullname })));
    } else {
      setCheckedAthletes([]);
    }
  }, [allChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAthletesAsRows(athletesToTable(athletes));
  }, [athletes, checkedAthletes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getAgencyAthletes(id, { ...selectedFilters, ...pagination }));
  }, [dispatch, selectedFilters, pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  const athletesToTable = data => {
    return data.map(row => {
      const checkbox = (
        <Checkbox
          dark
          checked={checkedAthletes.some(athlete => athlete.id === row.id)}
          onChange={() => {
            handleCheckAthlete({ name: row.fullname, id: row.id });
          }}
        />
      );
      // const athleteImg = <Avatar noBorder className={styles.athleteImg}  name={row.fullname} />
      const athlete = <AthleteRow img={row.img ?? row.photo} name={row.fullname} id={row.id} nickname={row.nickname} />;
      const nationalities = row?.nationalities ? (
        <NationalitiesRow className={styles.nationalityFlag} nationalities={row?.nationalities} athleteId={row.id} />
      ) : (
        '-'
      );
      const age = <span style={{ fontWeight: '500' }}>{row.age}</span>;
      const position = t(`positionOptionsRaw.${row.position}`) ?? '-';
      const monitoringButton = (
        <div className={styles.buttonContainer}>
          <MonitoringButton athlete={row} />
        </div>
      );

      let currentTeam = null;
      currentTeam = (
        <div className={styles.teamContainer}>
          <span>{row.current_team?.nickname ?? row.current_team?.longname ?? '-'}</span>
          <br />
          <span className={styles.contractDate}>
            {row.contract_info?.current_contract_end
              ? moment(row.contract_info.current_contract_end).format('DD/MM/YYYY')
              : '-'}
          </span>
        </div>
      );

      const currentTeamComponent = row?.current_team ? (
        <TeamRow name={currentTeam} id={row.current_team?.id} img={row.current_team?.emblem} />
      ) : (
        '-'
      );

      let srcTeam = null;
      srcTeam = (
        <div className={styles.teamContainer}>
          <span>{row.src_team?.nickname ?? row.src_team?.longname ?? '-'}</span>
          <br />
          <span className={styles.contractDate}>
            {row.contract_info?.src_contract_end
              ? moment(row.contract_info.src_contract_end).format('DD/MM/YYYY')
              : '-'}
          </span>
        </div>
      );

      const srcTeamComponent = row?.src_team ? (
        <TeamRow name={srcTeam} id={row.src_team?.id} img={row.src_team?.emblem} />
      ) : (
        '-'
      );

      return [
        checkbox,
        // athleteImg,
        athlete,
        currentTeamComponent,
        age,
        position,
        nationalities,
        srcTeamComponent,
        monitoringButton,
      ];
    });
  };

  const handleCheckAthlete = athlete => {
    if (checkedAthletes.some(item => item.id === athlete.id)) {
      setCheckedAthletes(checkedAthletes.filter(item => item.id !== athlete.id));
    } else {
      setCheckedAthletes([...checkedAthletes, athlete]);
    }
  };

  let content = null;

  if (agency.error) {
    content = <div className={styles.errorText}>{t(agency.error)}</div>;
  } else if (agency.data) {
    content = (
      <Page
        customTopBar={<CustomTopBar agency={agency.data} />}
        paginationObj={{
          pagination,
          setPagination,
          length: agencyAthletes.data?.length ?? 0,
          count: agencyAthletes.count,
          resourceDisplayName: t('filters.ath'),
          hasNext: agencyAthletes.hasNext,
          hasPrevious: agencyAthletes.hasPrevious,
        }}
      >
        <div className={styles.agency_athletes_left_container}>
          <div className={styles.agency_athletes_aside_container}>
            <AgencyInfo agency={agency.data} />
            {/* <Users agency={agency.data} /> */}
          </div>

          <div className={styles.agency_athletes_right_container}>
            <div className={styles.agency_athletes_right_container_header}>
              <h1>{t('agencyAthletes.title')}</h1>
              <p>
                {agencyAthletes.count === 1 ? t('athlete_list.exist') : t('athlete_list.existplural')}{' '}
                <b>{agencyAthletes.count}</b> {t('agencyAthletes.subTitle')}
              </p>
            </div>
            <ActionBar
              checkedAthletes={checkedAthletes}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              list={filteredList}
            />
            {agency.loading ? (
              <CenteredLoader />
            ) : (
              <Table
                flat
                theads={[
                  <Checkbox
                    dark
                    checked={allChecked}
                    onChange={() => setAllChecked(!allChecked)}
                    className={styles.checkbox}
                  />,
                  // '',
                  t('table.atleta'),
                  <div className={styles.clubColumn}>
                    {t('agencies.text2')} /<br /> {t('table.contrato')}
                  </div>,
                  t('athlete_list.box3'),
                  t('athlete_list.box4'),
                  'Nac.',
                  <div className={styles.clubColumn}>
                    {t('agencies.text4')} /<br /> {t('table.contrato')}
                  </div>,
                  '',
                ]}
                className={styles.tableAthletes}
                data={athletesAsRows}
              />
            )}
          </div>
        </div>
      </Page>
    );
  } else {
    return <CenteredLoader />;
  }

  return content;
};

export default AgencyAthletes;
