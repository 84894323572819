/*eslint-disable eqeqeq*/
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from '../Form';
import { Button, Input } from 'components/template';
import * as S from './styles';
import * as FComponent from '../Form/styles';
import styles from './styles.module.scss'
import { passwordUpdate, passwordUpdateReset } from 'store/ducks/user';
import { Creators as layoutActions } from 'store/ducks/layout';
import { useTranslation } from "react-i18next";

const PasswordChange = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch()

    const userPassword = useSelector(state => state.user.password)


    const passwordSchema = yup.object().shape({
        re_new_password: yup.string()
            .oneOf([yup.ref('new_password'), null], t("home.text14"))
    })

    useEffect(() => {
        if (userPassword.error) {
            dispatch(layoutActions.showToast({
                // onCloseHandler: () => { },
                content: <S.ToastMessage>{t(userPassword.error)}</S.ToastMessage>,
                duration: 4000
            }))
        } else if (userPassword.data) {
            dispatch(layoutActions.showToast({
                // onCloseHandler: () => { },
                content: <S.ToastMessage>{userPassword.data}</S.ToastMessage>,
                type: 'success',
                duration: 4000
            }))
        }
    }, [userPassword, dispatch, t])

    useEffect(() => {
        return () => {
            dispatch(passwordUpdateReset());
        };
    }, [dispatch]);

    return (
        <Formik
            initialValues={{
                current_password: '',
                new_password: '',
                re_new_password: ''
            }}
            validationSchema={passwordSchema}
            onSubmit={(values, { setSubmitting }) => {
                dispatch(passwordUpdate(values))
            }}
        >
            {({
                values,
                errors,
                handleChange,
                touched,
                dirty
            }) => (
                <S.FormElement>
                    <Form>
                        <S.ContentContainer>
                            <FComponent.FieldWrapper align-items='center' width='200px'>
                                <Input
                                    disabled={userPassword.loading}
                                    onChange={handleChange}
                                    iconPosition='right'
                                    profileInput
                                    type="password"
                                    name="current_password"
                                    placeholder=""
                                    label={t("home.text15")}
                                    rounded
                                    value={values.current_password}
                                />
                            </FComponent.FieldWrapper>
                            <FComponent.FieldWrapper width='200px'>
                                <Input
                                    disabled={userPassword.loading}
                                    onChange={handleChange}
                                    iconPosition='right'
                                    profileInput
                                    type="password"
                                    name="new_password"
                                    placeholder=""
                                    label={t("home.text16")}
                                    rounded
                                    value={values.new_password}
                                />
                            </FComponent.FieldWrapper>
                            {t(userPassword.error) ? (userPassword.error).map(item => <S.ErrorMessage><S.ErrorText>{t(item)}</S.ErrorText></S.ErrorMessage>) : null}
                            <FComponent.FieldWrapper width='200px' >
                                <Input
                                    disabled={userPassword.loading}
                                    onChange={handleChange}
                                    iconPosition='right'
                                    profileInput
                                    type="password"
                                    name="re_new_password"
                                    placeholder=""
                                    label={t("home.text17")}
                                    rounded
                                    value={values.re_new_password}
                                />
                                <Button
                                    className={styles.updateButton}
                                    rounded
                                    disabled={userPassword.loading || !(values.current_password !== '' && values.new_password !== '' && values.new_password == values.re_new_password)}
                                    type='submit'
                                >
                                    {t("organi.box11")}
                                </Button>
                            </FComponent.FieldWrapper>
                            {t(errors.re_new_password) ? <S.ErrorMessage><S.ErrorText>{t(errors.re_new_password)}</S.ErrorText></S.ErrorMessage> : null}
                        </S.ContentContainer>
                    </Form>
                </S.FormElement>
            )}
        </Formik>
    );
}

export default PasswordChange;