import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/';

// scss
import styles from './AthleteChatHeader.module.scss';

// components
import { Avatar, IconButton } from 'components/template';

// assets
import ProfileIcon from 'assets/ico/profile_box.svg';

// other
import moment from 'moment';

export default function AthleteChatHeader({
  selectedChat,
  handleFilterChange,
  headerOpen,
  handleAccordionClick,
  openNegotiations,
  otherChatClick,
  showOtherChatsAlert,
  ...rest
}) {
  const { t } = useTranslation();
  const history = useHistory();
  if (selectedChat?.athlete == null) {
    return '';
  }

  const isAgency = selectedChat.receiver_org?.id !== selectedChat.athlete.current_team?.id
  const athleteOrg = isAgency ? selectedChat.athlete.agency : selectedChat.athlete.current_team;
  return (
    <div className={styles.container} {...rest}>
      <div className={styles.container_row}>
        <div className={styles.left}>
          <div className={styles.avatar_container}>
            <div className={styles.athlete_image_container}>
              <Avatar
                className={styles.athlete}
                img={selectedChat.athlete?.photo}
                name={selectedChat.athlete?.fullname}
                noBorder
              />
            </div>
          </div>
          <div className={styles.info_container}>
            <span className={styles.name}>{selectedChat.athlete?.nickname}</span>
            <span className={styles.fullname}>{selectedChat.athlete?.fullname}</span>
            <div className={styles.general_info}>
              <div className={styles.country_info}>
                {!!selectedChat.athlete?.nationalities?.length && (
                  <img
                    src={selectedChat.athlete?.nationalities?.[0]?.flag}
                    alt={selectedChat.athlete?.nationalities?.[0]?.name ?? ''}
                    draggable="false"
                  />
                )}

                <span className={styles.country}>
                  {selectedChat.athlete?.nationalities?.[0]?.name ?? t('athlete_card.nacionalidade')}
                </span>
              </div>
              {selectedChat.athlete?.nationalities?.[0]?.name && selectedChat.athlete?.age && '|'}
              <span className={styles.age}>
                {selectedChat.athlete?.year} {selectedChat.athlete?.age} {t('athlete_card.idade')}
              </span>
              {selectedChat.athlete?.age && selectedChat.athlete?.position && '|'}
              <span className={styles.position}>{t(`positionOptionsRaw.${selectedChat.athlete?.position}`)}</span>
            </div>
          </div>
        </div>
        <div className={styles.center}>
          <span className={styles.club_container_header}>{t(isAgency ? 'chat.athlete_agency' : 'chat.athlete_club')}</span>
          <div className={styles.club_container}>
            <div className={styles.club_avatar_container}>
              <Avatar
                className={styles.club}
                img={athleteOrg?.emblem}
                name={athleteOrg?.name}
                noBorder
              />
            </div>
            <div className={styles.club_info_container}>
              <span className={styles.club_name}>{athleteOrg?.name}</span>
              {!isAgency && athleteOrg.contract_end && (
                <span className={styles.club_contract}>
                  {t('chat.contract_until')} {moment(athleteOrg.contract_end).format('DD/MM/YYYY')}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <IconButton
            className={styles.button}
            text={t('chat.view_athlete_page')}
            icon={<img src={ProfileIcon} alt="profile_icon" />}
            onClick={() => history.push(`/atletas/${selectedChat.athlete?.id}`)}
          />
        </div>
      </div>
    </div>
  );
}
