/*eslint-disable eqeqeq*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Dialog } from 'components/template';
import styles from './ContractDialog.module.scss';
import { useTranslation } from 'react-i18next';
import ContractSlider from './Slider';
import { Creators as clubActions } from 'store/ducks/club';

const ContractDialog = ({ teamId, isOpen, setIsOpen }) => {
  const club = useSelector(state => state.club.clubPage);
  const { t } = useTranslation();
  const contractHighRisk = club.data?.contract_high_risk ?? 9;
  const contractWarningRisk = club.data?.contract_warning_risk ?? 18;
  const [contractRiskValue, setContractRiskValue] = useState([contractHighRisk, contractWarningRisk]);
  const [saveButtonDisable, setSaveButtonDisable] = useState(false);

  useEffect(() => {
    setContractRiskValue([contractHighRisk, contractWarningRisk]);
  }, [contractHighRisk, contractWarningRisk]);

  const onClose = () => {
    setContractRiskValue([contractHighRisk, contractWarningRisk]);
    setIsOpen(false);
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      mainTitle={t('myClub.contracts.dialogTitle')}
      btnLeft={t('dialog_content.cancel')}
      btnLeftClick={onClose}
      btnRight={t('dialog_content.save')}
      btnRightClick={async () => {
        setSaveButtonDisable(true);
        const data = await clubActions.updateContractRisks(teamId, contractRiskValue[0], contractRiskValue[1]);
        if (data) {
          window.location.reload();
        }
        setSaveButtonDisable(false);
      }}
      btnRightDisabled={saveButtonDisable}
      className={{
        container: styles.dialogContainer,
      }}
    >
      <div className={styles.dialogContent}>
        {t('myClub.contracts.dialogDesc')}
        <ContractSlider value={contractRiskValue} setValue={setContractRiskValue} />
        <div className={styles.dotRisk}>
          <div className={styles.dotDanger} />
          <div className={styles.riskTitle}>{t('myClub.contracts.riskTitle_danger')}</div>
          <div>
            {t('myClub.contracts.riskDescLessThan')} <b>{contractRiskValue[0]}</b>{' '}
            {t('myClub.contracts.riskDescMonths')}
          </div>
        </div>
        <div className={styles.dotRisk}>
          <div className={styles.dotWarning} />
          <div className={styles.riskTitle}>{t('myClub.contracts.riskTitle_warning')}</div>
          <div>
            {t('myClub.contracts.riskDescGreatherThan')} <b>{contractRiskValue[0]}</b>{' '}
            {t('myClub.contracts.riskDescMonths')} {t('myClub.contracts.riskDescAnd')}{' '}
            {t('myClub.contracts.riskDescLessThan')} <b>{contractRiskValue[1]}</b>{' '}
            {t('myClub.contracts.riskDescMonths')}
          </div>
        </div>
        <div className={styles.dotRisk}>
          <div className={styles.dotOk} />
          <div className={styles.riskTitle}>{t('myClub.contracts.riskTitle_ok')}</div>
          <div>
            {t('myClub.contracts.riskDescGreatherThan')} <b>{contractRiskValue[1]}</b>{' '}
            {t('myClub.contracts.riskDescMonths')}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ContractDialog;

ContractDialog.defaultProps = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};
