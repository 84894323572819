import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './AthleteList.module.scss';
import { useTranslation } from 'react-i18next';
import { offerDesiredAthlete, updateOfferDesiredAthlete, deleteOfferDesiredAthlete } from 'store/ducks/transferAthletes';
import { Button } from 'components/template';
import { Link } from 'react-router-dom';
import { FaCheck } from "react-icons/fa";

export default function ButtonOffer({
  transferMarket, athlete, onOwnerUpdateStatus, selectedOfferStatus, dialogConfirmation, setDialogConfirmation, acceptOffer
}) {
  const { t } = useTranslation();
  const [saving, setSaving] = useState();
  const [offerStatus, setOfferStatus] = useState(athlete.offers ? athlete.offers[0] : {status: null});

  const offerId = offerStatus.id ?? offerStatus.offer_id;

  const createOffer = async () => {
    setSaving(true);
    const data = await offerDesiredAthlete({
      athlete: athlete.id,
      status: "offered",
      desired_transfermarket: transferMarket.id,
    });
    if (data.id) {
      setOfferStatus(data);
    }
    setSaving(false);
  }

  const discardDiscardOfferXD = async () => {
    setSaving(true);
    const data = await updateOfferDesiredAthlete(offerId, { status: "offered" });
    if (data.id) {
      setOfferStatus(data);
      onOwnerUpdateStatus(data);
    }
    setSaving(false);
  }
  
  const acceptOfferVerify = async () => {
    if(acceptOffer){
      acceptOfferSave();
    } else {
      setDialogConfirmation({ isOpen: true, save: false, offer_id: offerId });
    }  
  }

  const acceptOfferSave = useCallback(async () => {
    setSaving(true);
    const data = await updateOfferDesiredAthlete(offerId, { status: "accepted" });
    if (data.id) {
      const offerData = {
        ...data,
        negotiation: data.negotiation.id,
      };
      setOfferStatus(offerData);
      onOwnerUpdateStatus(offerData);
    }
    setSaving(false);
  }, [offerId, onOwnerUpdateStatus]);

  useEffect(() => {
    if (dialogConfirmation?.save && offerId === dialogConfirmation.offer_id) {
      acceptOfferSave();
      setDialogConfirmation({ isOpen: false, save: false, offer_id: null });
    }
  }, [dialogConfirmation]);  // eslint-disable-line react-hooks/exhaustive-deps
  
  const discardOffer = async () => {
    setSaving(true);
    const data = await updateOfferDesiredAthlete(offerId, { status: "discarded" });
    if (data.id) {
      setOfferStatus(data);
      onOwnerUpdateStatus(data);
    }
    setSaving(false);
  }

  const deleteOffer = async () => {
    setSaving(true);
    if (await deleteOfferDesiredAthlete(offerId)) {
      athlete.offers = [];
      setOfferStatus({status: null});
    }
    setSaving(false);
  }

  const isAdOwner = transferMarket.is_ad_owner;
  let statusChanged = isAdOwner && offerStatus.status !== selectedOfferStatus;
  let statusChangedText;
  let statusChangedButton;
  if (statusChanged) {
    if (offerStatus.status === 'discarded' && selectedOfferStatus === 'offered') {
      statusChangedText = t('transferMarket.discardedAthletes');
      statusChangedButton = (
        <Button className={styles.moreInfoButton} disabled={saving} onClick={discardDiscardOfferXD}>
          Desfazer
        </Button>
      );
    } else if (offerStatus.status === 'offered' && selectedOfferStatus === 'discarded') {
      statusChangedText = t('transferMarket.athletesOffered');
      statusChangedButton = (
        <Button className={styles.moreInfoButton} disabled={saving} onClick={discardOffer}>
          Refazer
        </Button>
      );
    } else if (offerStatus.status === 'accepted' && selectedOfferStatus === 'offered') {
      statusChangedText = t('transferMarket.athletesInConversation');
    } else {
      statusChanged = false;
    }
  }

  if (statusChanged) {
    return (
      <div className={styles.buttonContainer}>
        <span className={styles.messageTabChange}>
          {t('transferMarket.movedTo')}
          <b>{statusChangedText}</b>
        </span>
        {statusChangedButton}
      </div>
    );
  }

  return (<>
    <div className={styles.buttonContainer}>
      {offerStatus?.status === 'accepted' && (
        <Link to={`/mensagens/negociacoes/${offerStatus.negotiation}`}>
          <Button className={styles.purpleButton}>
            {t('transferMarket.athleteList.goToNegotiation')}
          </Button>
        </Link>
      )}
      {offerStatus?.status === 'offered' && isAdOwner && (
        <div className={styles.multipleButtons}>
          <Button className={styles.moreInfoButton} disabled={saving} onClick={discardOffer}>
            {t('transferMarket.discard')}
          </Button>
          <Button className={styles.moreInfoButton} disabled={saving} onClick={() => acceptOfferVerify()}>
            {t('transferMarket.showInterest')}
          </Button>
        </div>
      )}
      {offerStatus?.status === 'offered' && !isAdOwner && (
        <Button className={styles.moreInfoButton} disabled={saving} onClick={deleteOffer}>
          {t('transferMarket.athleteList.offered')}
          <FaCheck className={styles.iconCheck}/>
        </Button>
      )}
      {offerStatus.status == null && (
        <Button className={styles.moreInfoButton} disabled={saving} onClick={createOffer}>
          {t('transferMarket.athleteList.toOffer')}
        </Button>
      )}
      {offerStatus?.status === 'discarded' && isAdOwner && (
        <Button className={styles.moreInfoButton} disabled={saving} onClick={discardDiscardOfferXD}>
          Desfazer
        </Button>
      )}
      {offerStatus?.status === 'discarded' && !isAdOwner && (
        <Button className={styles.moreInfoButton} disabled={true}>
          Atleta negado
        </Button>
      )}      
    </div>
  </>
  );
}

ButtonOffer.defaultProps = {
  hasFilter: false,
};

ButtonOffer.propTypes = {
  hasFilter: PropTypes.bool,
};
