import React from 'react';

import PropTypes from 'prop-types';

import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import styles from './StarRating.module.scss';

const StarRating = ({ rate, editable, className, size, label, outerContainerClassName, setValue, margin }) => {
  const outerContainerClasses = [styles.outerContainer];
  if (outerContainerClassName) outerContainerClasses.push(outerContainerClassName);
  // if (rate === null) rate = 0;

  const starClasses = [styles.star];
  if (editable) starClasses.push(styles.hover);

  const emptyStarClasses = [styles.emptyStar];
  if (editable) emptyStarClasses.push(styles.hover);

  const adjustedRate = Math.round(rate * 2) / 2;
  const hasHalf = adjustedRate % 1;
  const stars = [];
  const style = size ? { height: `${size}px`, width: `${size}px` } : {};
  if (margin) style.marginRight = margin;
  const totalStars = 5;

  const starClick = (e, pos) => {
    if (editable) {
      const star = e.target;
      const starRect = star.getBoundingClientRect();
      const clickX = e.clientX;
      const center = starRect.right - starRect.width / 2;
      if (clickX > center) {
        setValue(Number(pos));
      } else if (hasHalf) {
        setValue(Number(pos) - 1);
      } else setValue(Number(pos) - 0.5);
    }
  };

  for (let i = 0; i < Math.floor(adjustedRate); i += 1) {
    const starsPos = stars.length + 1;
    stars.push(
      <FaStar
        onClick={e => starClick(e, starsPos, 1)}
        style={style}
        className={starClasses.join(' ')}
        key={`fullstar${starsPos}`}
      />,
    );
  }

  if (hasHalf) {
    const starsPos = stars.length + 1;
    stars.push(
      <FaStarHalfAlt
        onClick={e => starClick(e, starsPos, 0.5)}
        style={style}
        className={starClasses.join(' ')}
        key="halfStar"
      />,
    );
  }

  while (stars.length < totalStars) {
    const starsPos = stars.length + 1;
    stars.push(
      <FaRegStar
        onClick={e => starClick(e, starsPos)}
        style={style}
        className={emptyStarClasses.join(' ')}
        key={`emptyStar${starsPos}`}
      />,
    );
  }

  return (
    <div className={outerContainerClasses.join(' ')}>
      {label && <span>{label}</span>}
      <div className={[styles.container, className].join(' ')}>{stars}</div>
    </div>
  );
};

StarRating.defaultProps = {
  className: null,
  editable: false,
  outerContainerClassName: null,
  size: null,
  margin: null,
  label: null,
  rate: 0,
  setValue: () => null,
};

StarRating.propTypes = {
  rate: PropTypes.number,
  className: PropTypes.string,
  size: PropTypes.number,
  label: PropTypes.node,
  outerContainerClassName: PropTypes.string,
  editable: PropTypes.bool,
  setValue: PropTypes.func,
  margin: PropTypes.string,
};

export default StarRating;
