import React, { useState } from 'react';
import { SelectionButton } from 'components/template';
import styles from './ActionBar.module.scss';
import { useTranslation } from 'react-i18next';

export default function ActionBar({ selectedFilters, setSelectedFilters }) {
  const { t } = useTranslation();
  const [maleCastSelected, setMaleCastSelected] = useState('professional');
  const [femaleCastSelected, setFemaleCastSelected] = useState(null);

  return (
    <div className={styles.buttonsTop}>
      <span className={styles.spanMale}>{t('listTranslation.Male')}: </span>
      <SelectionButton
        items={[
          { label: t('compe.text26'), value: 'professional' },
          { label: t('text.box31'), value: 'loaned' },
          { label: t('text.box32'), value: 'amateur' },
        ]}
        selected={maleCastSelected}
        onClick={val => {
          const loaned = val === 'loaned';
          setSelectedFilters({
            ...selectedFilters,
            female: false,
            professional: loaned ? null : val === 'professional',
            loaned,
          });
          setMaleCastSelected(val);
          setFemaleCastSelected(null);
        }}
      />
      <span className={styles.spanFemale}>{t('listTranslation.Female')}: </span>
      <SelectionButton
        items={[
          { label: t('compe.text26'), value: 'professional' },
          { label: t('text.box38'), value: 'loaned' },
          { label: t('text.box37'), value: 'amateur' },
        ]}
        selected={femaleCastSelected}
        onClick={val => {
          const loaned = val === 'loaned';
          setSelectedFilters({
            ...selectedFilters,
            female: true,
            professional: loaned ? null : val === 'professional',
            loaned,
          });
          setMaleCastSelected(null);
          setFemaleCastSelected(val);
        }}
      />
    </div>
  );
}
