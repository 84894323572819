import styled from 'styled-components'
import Variables from 'variables.js'

export const Head = styled.div`  
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${Variables.lighterGrey};
  padding: 15px;
`;

export const Container = styled.div`  
  padding: 15px;
`;