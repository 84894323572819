import React from 'react';
import { MdClose } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import styles from './ActiveFilters.module.scss';
import { brazilianStates, checkboxesAdvancedSearch } from 'shared/mocks';

const ActiveFilters = ({ setActiveFilters, activeFilters, countries, overlay }) => {
  const { t } = useTranslation();

  // prettier-ignore
  const positionMap = {
    'Atacante': t('positionOptions.Atacante'),
    'Ataque': t('positionOptions.Ataque'),
    'Centroavante': t('positionOptions.Centroavante'),
    'Zagueiro': t('positionOptions.Zagueiro'),
    'Defesa': t('positionOptions.Defesa'),
    'Goleiro': t('positionOptions.Goleiro'),
    'Meia': t('positionOptions.Meia'),
    'Volante': t('positionOptions.Volante'),
    'Lateral Direito': t('positionOptions.LateralDireito'),
    'Lateral Esquerdo': t('positionOptions.LateralEsquerdo'),
    'Meia-atacante': t('positionOptions.MeiaAtacante'),
    'Meio-campo': t('positionOptions.MeioCampo'),
  };

  const removeFilter = filter => {
    let newActiveFilters = { ...activeFilters };

    const filterType = filter.filter;

    if (Array.isArray(newActiveFilters[filterType])) {
      let newArr = newActiveFilters[filterType].filter(item => item !== filter.value);

      if (newArr.length === 0) {
        delete newActiveFilters[filterType];
      } else {
        newActiveFilters[filterType] = newArr;
      }
    } else {
      delete newActiveFilters[filterType];
    }

    setActiveFilters(newActiveFilters);
  };

  const getFilterMessage = (filter, value) => {
    if (filter === 'lists' || filter === 'watched') {
      return [
        {
          message: t('filters.lists'),
          key: value,
          value: value,
          filter,
        },
      ];
    }

    if (typeof value === 'string') {
      if (filter === 'gender') {
        return [
          {
            message: value === 'male' ? t('user.text16') : t('text.box33'),
            key: value,
            value: value,
            filter,
          },
        ];
      }
    }

    if (Array.isArray(value)) {
      return value.map(item => {
        if (filter === 'nationalities') {
          return {
            message: ` ${t('athlete_card.nation')} ${countries?.find(country => country.code === item)?.name}`,
            key: item,
            value: item,
            filter,
          };
        } else if (filter === 'position') {
          return {
            message: positionMap[item],
            key: item,
            value: item,
            filter,
          };
        } else if (filter === 'division') {
          return {
            message: t(`divisionOptions.${item}`),
            key: item,
            value: item,
            filter,
          };
        } else if (filter === 'negotiation_type') {
          return {
            message: t(`desiredNegotiationOptions.${item}`),
            key: item,
            value: item,
            filter,
          };
        } else if (filter === 'profile') {
          return {
            message:
              item === 'atletas-profissionais'
                ? t('profileOptions.atletas-profissionais')
                : t('profileOptions.atletas-amadores'),
            key: item,
            value: item,
            filter,
          };
        } else if (filter === 'contract') {
          return {
            message: item === 'com-contrato' ? t('contractOptions.com-contrato') : t('contractOptions.sem-contrato'),
            key: item,
            value: item,
            filter,
          };
        } else {
          return {
            message: checkboxesAdvancedSearch[filter]?.find(val => t(`positionOptions${val.value === item}`))?.label,
            key: item,
            value: item,
            filter,
          };
        }
      });
    }

    if (typeof value === 'object') {
      switch (filter) {
        case 'seasonMatches': {
          return [
            {
              message: `${value.min ? `${t('filters.mini')} ${value.min}` : ` ${t('filters.max')} ${value.max}`}
                                  ${value.max && value.min && ` ${t('filters.and_max')} ${value.max}`} ${t(
                'filters.games_in'
              )} ${value.season}`,
              key: `${filter}${value.season}`,
              value,
              filter,
            },
          ];
        }
        case 'seasonGoals': {
          return [
            {
              message: `${value.min ? `${t('filters.mini')} ${value.min}` : `${t('filters.max')} ${value.max}`}
                                  ${value.max && value.min && `${t('filters.and_max')} ${value.max}`} ${value.season}`,
              key: `${filter}${value.season}`,
              value,
              filter,
            },
          ];
        }
        case 'age': {
          return [
            {
              message: `${value.min && value.max
                ? `${t('slider_menu.in')} ${value.min} ${t('slider_menu.e')} ${value.max}`
                : `${value.min ? `${t('filters.mini')} ${value.min}` : `${t('filters.ate')} ${value.max}`}`
                } ${t('athlete_card.idade')}`,
              key: `${filter}${value.season}`,
              value,
              filter,
            },
          ];
        }
        case 'birthyear': {
          return [
            {
              message: `${value.min && value.max
                ? `${t('filters.de')} ${value.min} ${t('filters.a')} ${value.max}`
                : `${value.min ? `${t('filters.mini')} ${value.min}` : `${t('filters.ate')} ${value.max}`}`
                }`,
              key: `${filter}${value.season}`,
              value,
              filter,
            },
          ];
        }
        default:
          return [];
      }
    }

    const dateParser = dateString => {
      var date = new Date(dateString);

      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    };

    const filterMessages = {
      keyword: `${t('filters.key')} ${value}`,
      country: countries?.find(country => country.code === value)?.name,
      state: brazilianStates?.find(state => state.value === value)?.label,
      foot: `${t('athlete_card.foot')}: ${t(`preferentialFoot.${value}`)}`,
      cbfCode: `${t('filters.cbf_num')} ${value}`,
      contractEnds: `${t('filters.contract_until')} ${dateParser(value)}`,
    };

    return [
      {
        message: filterMessages[filter],
        key: filter,
        value,
        filter,
      },
    ];
  };

  let filters = [];

  for (let key in activeFilters) {
    filters.push(...getFilterMessage(key, activeFilters[key]));
  }
  return (
    filters.length > 1 && (
      <div className={styles.activeFilters}>
        {overlay && <div className={styles.overlay} />}
        <span>{t('filters.active_filters')}</span>
        {filters.map(filter =>
          filter.key === 'isApplyButton' ? (
            ''
          ) : (
            <span key={filter.key} className={styles.badge}>
              <span>{filter.message}</span>
              {filter.filter !== 'gender' && (
                <MdClose className={styles.deleteIcon} onClick={() => removeFilter(filter)} />
              )}
            </span>
          )
        )}
        <span className={styles.removeFilters} onClick={() => setActiveFilters({ gender: 'male', isApplyButton: true })}>
          {t('rest.box22')}
        </span>
      </div>
    )
  );
};

export default ActiveFilters;
