// hooks
import React, { useState } from 'react';

// components
import AthleteChatHeader from './Athletes/AthleteChatHeader';
import NegotiationChatHeader from './Negotiation/NegotiationChatHeader';
import TeamChatHeader from './Teams/TeamChatHeader';
import AgentChatHeader from './Agents/AgentChatHeader';

// other

export default function Header({
  repository,
  selectedChat,
  handleFilterChange,
  user,
  otherChatClick,
  showOtherChatsAlert,
  openNegotiations,
  ...rest
}) {
  const [headerOpen, setHeaderOpen] = useState(false);

  const handleAccordionClick = () => {
    setHeaderOpen(!headerOpen);
  };

  return (
    <>
      {repository === 'negociacoes' && (
        <NegotiationChatHeader
          selectedChat={selectedChat}
          headerOpen={headerOpen}
          handleAccordionClick={handleAccordionClick}
          openNegotiations={openNegotiations}
          showOtherChatsAlert={showOtherChatsAlert}
          otherChatClick={otherChatClick}
          handleFilterChange={handleFilterChange}
          user={user}
          {...rest}
        />
      )}
      {repository === 'atletas' && (
        <AthleteChatHeader
          selectedChat={selectedChat}
          headerOpen={headerOpen}
          handleAccordionClick={handleAccordionClick}
          openNegotiations={openNegotiations}
          showOtherChatsAlert={showOtherChatsAlert}
          otherChatClick={otherChatClick}
          handleFilterChange={handleFilterChange}
          {...rest}
        />
      )}
      {repository === 'clubes' && !!selectedChat.team && (
        <TeamChatHeader
          selectedChat={selectedChat}
          showOtherChatsAlert={showOtherChatsAlert}
          otherChatClick={otherChatClick}
          handleFilterChange={handleFilterChange}
        />
      )}
      {repository === 'agentes' && (
        <AgentChatHeader
          selectedChat={selectedChat}
          user={user}
        />
      )}
    </>
  );
}
