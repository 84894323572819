import React, { useState, useEffect } from 'react';
import styles from './Header.module.scss';
import { Avatar, IconButton } from 'components/template';
import { useTranslation } from 'react-i18next';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { BsExclamationTriangleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import ProfileIcon from 'assets/ico/profile_box.svg';
//import "../../translations/i18n"
import { AiFillWarning } from 'react-icons/ai';
import { MdError } from 'react-icons/md';
import Variables from 'variables';

export default function Header({
  chat,
  user,
  otherChatClick,
  showOtherChatsAlert,
  openNegotiations,
  onAthleteFilterChange = () => { },
  ...rest
}) {
  const { t } = useTranslation();
  const athlete = chat.athlete;
  const transferType = chat.transfer_market?.negotiation_type;
  const [filter, setFilter] = useState({ athlete: null });

  useEffect(() => {
    onAthleteFilterChange(filter);
  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  const [headerOpen, setHeaderOpen] = useState(false);

  const containerClasses = [styles.container];

  const handleAccordionClick = () => {
    setHeaderOpen(!headerOpen);
  };

  const transferMarketIsActive = chat.transfer_market?.is_active ?? false;
  return (
    <div className={containerClasses.join(' ')} {...rest}>
      <div className={styles.containerRow}>
        <div className={styles.athleteCard}>
          <div className={styles.left}>
            <div className={styles.clubContainer}>
              <div className={styles.athleteImg}>
                <Avatar img={athlete.photo} name={athlete.fullname} noBorder />
              </div>
            </div>
          </div>
          <div className={styles.center}>
            <span className={styles.name}>{athlete.nickname}</span>
            <span className={styles.completeName}>{athlete.fullname}</span>
            <div className={styles.info}>
              {athlete.nationalities?.length && (
                <img
                  src={athlete.nationalities?.[0]?.flag}
                  alt={athlete.nationalities?.[0]?.name ?? ''}
                  draggable="false"
                />
              )}
              <span className={styles.age}>
                {athlete.year} {athlete.age} {t('athlete_card.idade')}
              </span>
              <span className={styles.position}>{t(`positionOptionsRaw.${athlete?.position}`)}</span>
            </div>
          </div>
        </div>

        <div className={[styles.headerPanel, styles.athleteClub].join(' ')}>
          <div className={styles.headerTitle}>{t('negotiations.headerClubTitle')}</div>
          {chat.ceding_club && (
            <div className={styles.athleteClubAvatar}>
              <Avatar
                img={chat.ceding_club.emblem}
                name={chat.ceding_club.nikename}
                className={styles.club}
                noBorder
              />
              <div>
                <p className={styles.clubName}>{chat.ceding_club.nikename}</p>
                {athlete.current_team?.id === chat.ceding_club.id ? (
                  <p>Até {athlete.clubContractExpire ?? 'N/D'}</p>
                ) : (
                  <p>Até N/D</p>
                )}
              </div>
            </div>
          )}
          {chat.ceding_agency && (
            <div className={styles.athleteClubAvatar}>
              <Avatar
                img={chat.ceding_agency.emblem}
                name={chat.ceding_agency.name}
                className={styles.club}
                noBorder
              />
              <div>
                <p className={styles.clubName}>{chat.ceding_agency.name}</p>
                <p>{t('agencies.agency')}</p>
              </div>
            </div>
          )}
        </div>
        <div className={[styles.headerPanel, styles.athletePrice].join(' ')}>
          <div className={styles.headerTitle}>{t('negotiations.headerPriceTitle')}</div>
          <p className={styles.priceText}>
            {chat.transfer_market?.sale_value
              ? chat.transfer_market?.sale_value.toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR',
              })
              : 'N/D'}
          </p>
        </div>
        <div className={[styles.headerPanel, styles.transferType].join(' ')}>
          <div className={styles.headerTitle}>{t('negotiations.headerTransferTypeTitle')}</div>
          {transferType && (
            <p>
              {t(
                `negotiations.header${transferType.charAt(0).toUpperCase() +
                transferType.slice(1).toLowerCase()}TransferTypeText`
              )}
            </p>
          )}
        </div>
        <div className={[styles.headerPanel, styles.salary].join(' ')}>
          <div className={styles.headerTitle}>{t('negotiations.headerSalaryTitle')}</div>
          <p>{chat.transfer_market?.annual_salary ? chat.transfer_market?.annual_salary + ' M €' : 'N/D'}</p>
        </div>
        <div className={styles.accordionTrigger}>
          {headerOpen ? (
            <BiChevronUp size={26} onClick={handleAccordionClick} />
          ) : (
            <BiChevronDown size={26} onClick={handleAccordionClick} />
          )}
        </div>
      </div>
      <div className={styles.accordionContent} hidden={!headerOpen}>
        <div>
          <Link
            to={{ pathname: `/atletas/${athlete.id}`, state: { previews: window.location.pathname } }}
            target="_self"
          >
            <IconButton
              className={styles.profile_button}
              text="Ver perfil completo"
              icon={<img src={ProfileIcon} alt="profile icon" />}
              type="button"
            />
          </Link>
        </div>
        <div>
          <p>
            <b>{t('negotiations.headerClubObs')}</b>
          </p>
          <p>{chat.transfer_market?.notes}</p>
        </div>
      </div>
      {!!showOtherChatsAlert && (
        <div className={styles.otherChatAlert}>
          <BsExclamationTriangleFill />
          <span className={styles.otherChatAlertText}>
            {/* <Trans i18nKey="userMessagesUnread" count={count}>
          Hello <strong title={t('nameTitle')}>{{name}}</strong>, you have {{count}} unread message. <Link to="/msgs">Go to messages</Link>.
          </Trans> */}
            {t('negotiations.headerOtherChatAlertTitle')}
          </span>
          <span className={styles.otherChatAlertLink} onClick={otherChatClick}>
            {t('negotiations.headerOtherChatAlertLink')}
          </span>
        </div>
      )}
      {openNegotiations >= 3 ? (
        <div className={[styles.topBarBanner, styles.topBarBannerMessages].join(' ')}>
          <AiFillWarning />
          <span>{t('transferMarket.headerOtherChatAlertTitle', { length: openNegotiations })}</span>
          <span className={styles.spanButton} onClick={() => setFilter({ athlete: athlete.id })}>
            {t('transferMarket.headerOtherChatAlertLink')}
          </span>
        </div>
      ) : (
        <></>
      )}
      {!transferMarketIsActive && (
        <div className={[styles.topBarBanner, styles.topBarBannerInactive].join(' ')}>
          <MdError style={{ color: Variables.red }} />
          <span>{t('transferMarket.headerChatInactive')}</span>
        </div>
      )}
    </div>
  );
}
