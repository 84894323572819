// hooks
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// scss
import styles from './SideInfoClub.module.scss';

// components
import { IconButton } from 'components/template';

// icons
import { BsChatLeftTextFill } from 'react-icons/bs';

// other
import moment from 'moment';
import { AthleteChatRepository } from 'data/athleteChat';
import { Creators as layoutCreators } from 'store/ducks/layout';
import { useDispatch } from 'react-redux';
import { MdVerified } from 'react-icons/md';

export default function SideInfoClub({ data, user }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className={styles.container}>
      {data.current_team && (
        <div className={styles.club_container}>
          <h1 className={styles.title}>{t('agencies.text2')}:</h1>
          <div className={styles.club_info_container}>
            <img src={data.current_team.emblem} alt={''} />
            <div className={styles.club_info_text}>
              <span className={styles.club_name}>
                {data.current_team.longname}
                {data.team_has_footlink_contract && (
                  <div footlink-title-right={t("home.footlinkClient")}><MdVerified /></div>
                )}
              </span>
              {!!data.contract_info.current_contract_end && (
                <span className={styles.club_contract}>{`${t('table.contrato')} ${moment(
                  data.contract_info.current_contract_end
                ).format('DD/MM/YYYY')}`}</span>
              )}
            </div>
          </div>
          {data.team_has_mensageria_access && user?.organization?.id?.id && data.current_team_org && data.current_team.id !== user?.organization?.id?.team && (
            <IconButton
              className={styles.club_message}
              text={t('chat.talk_to_club')}
              icon={<BsChatLeftTextFill />}
              onClick={async () => {
                const response = await AthleteChatRepository.post({
                  sender_org: user.organization.id.id,
                  receiver_org: data.current_team_org,
                  athlete: data.id,
                });
                if (response.error && response.status === 402) {
                  dispatch(layoutCreators.showExcededPlanLimit());
                } else {
                  history.push(`/mensagens/atletas/${response.id}`);
                }
              }}
            />
          )}
        </div>
      )}

      {false && (
        <div className={styles.club_container}>
          <h1>Clube Cedente:</h1>
        </div>
      )}
    </div>
  );
}
