import React, { useState } from 'react';
import * as S from './styles';
import styles from './index.module.scss';
import { FaChevronLeft, FaTrash, FaPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import CenteredLoader from 'components/template/CenteredLoader';
import { Button, Spinner } from 'components/template';
import AthleteCard from 'components/AthleteCard';
import { createNegotiation, deleteNegotiation } from 'store/ducks/myClub';
import { useTranslation } from 'react-i18next';

export default function AthleteFilterResult({
  back,
  openFilter,
  teamId,
  frame,
  athletesWorkflow,
  setUpdateAthletesWorkflow,
  queryFilters,
}) {
  const { t } = useTranslation();
  const athletes = useSelector(state => state.athleteV2.athletes);
  const [changindIds, setChangindIds] = useState([]);

  return athletes.loading ? (
    <CenteredLoader />
  ) : (
    <>
      <S.Head>
        <FaChevronLeft onClick={back} />
        <span>
          {athletes.count.toLocaleString('pt-BR')} {t('proj.box20')}
        </span>
        <Button rounded smaller outline color="green" onClick={() => openFilter(false)}>
          {t('field.con')}
        </Button>
      </S.Head>
      <S.Container>
        {athletes.data.map(item => {
          const aw = athletesWorkflow?.find(aw => aw.athlete.id === item.id);
          return (
            <AthleteCard
              item={item}
              iconImg={
                changindIds.includes(item.id) ? (
                  <Spinner height="15" width="15" color="white" className={styles.spinner} />
                ) : aw ? (
                  <FaTrash />
                ) : (
                  <FaPlus />
                )
              }
              iconColor={aw ? 'orange' : 'green'}
              iconClick={async () => {
                setChangindIds([item.id, ...changindIds]);
                if (aw) {
                  const result = await deleteNegotiation(teamId, aw.id);
                  if (result?.error === undefined) {
                    athletesWorkflow.splice(athletesWorkflow.indexOf(aw), 1);
                    changindIds.splice(changindIds.indexOf(item.id), 1);
                  }
                } else {
                  const result = await createNegotiation(teamId, frame, item.id, queryFilters.type);
                  if (result.data) {
                    result.data.athlete = Object.assign({}, item);
                    athletesWorkflow.push(result.data);
                    changindIds.splice(changindIds.indexOf(item.id), 1);
                  }
                }
                setUpdateAthletesWorkflow([...athletesWorkflow]);
                setChangindIds(changindIds);
              }}
            />
          );
        })}
      </S.Container>
    </>
  );
}
