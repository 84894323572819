import React from 'react';
import * as S from './styles';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/template';
import { MdGroup } from 'react-icons/md';
import ShadowTeamLeftHeader from './ShadowTeamLeftHeader';

export default function ShadowTeamPositions({
  editButtonClick,
  positions,
  handleSelectPosition,
  handleCreatePosition,
}) {
  const { t } = useTranslation();

  return (
    <>
      <ShadowTeamLeftHeader editButtonClick={editButtonClick}></ShadowTeamLeftHeader>

      {positions &&
        positions.map((p, i) => (
          <Button
            key={i}
            onClick={() => handleSelectPosition(p)}
            rounded
            outline
            color="green"
            style={{
              fontWeight: '700',
              margin: '5px 15px',
              // textOverflow: 'ellipsis',
              // overflow: 'hidden',
            }}
          >
            <S.PositionButtonText>
              {t('proj.editarPosicao')}
              {p.position_name}
              <MdGroup size="18px" style={{ marginLeft: '10px', marginBottom: '-3px' }} />
            </S.PositionButtonText>
          </Button>
        ))}
      <Button
        onClick={handleCreatePosition}
        rounded
        color="green"
        style={{
          fontWeight: '700',
          margin: '14px 15px 15px 15px',
        }}
      >
        {t('proj.adicionarPosicao')}
        <MdGroup size="18px" style={{ marginLeft: '10px', marginBottom: '-3px' }} />
      </Button>
    </>
  );
}
