import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getTransferWindows } from 'store/ducks/home';
import styles from './index.module.scss';
import moment from 'moment';
import { getWindowCategory } from 'shared/utility';
import { useTranslation } from 'react-i18next';
import { FaExchangeAlt } from 'react-icons/fa';

import { Spinner, Card, Button } from 'components/template';

export default function TransferWindows() {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector(state => state.home.transferWindows);
  const { t } = useTranslation();
  const [monitoring, setMonitoring] = useState(true);

  useEffect(() => {
    dispatch(getTransferWindows(monitoring, 'recent'));
  }, [dispatch, monitoring]);

  const infoData =
    data.data &&
    data.data.map(function(item, index) {
      return (
        <tr key={index}>
          <td className={styles.nationality}>
            <img src={item.country?.flag} alt={item.country?.name ?? ''} /> <span>{item.country?.name}</span>
          </td>
          <td className={styles.type}>{getWindowCategory(item.category)}</td>
          <td className={styles.date}>
            {item.open_end ? t('club.box22') + moment(item.open_end).format('DD MMM') : t('user.text19')}
          </td>
        </tr>
      );
    });

  let content = '';
  if (data.loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (data.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(data.error)}</p>
      </Card>
    );
  } else {
    content = (
      <>
        {data?.data?.length > 0 ? (
          <table>
            <tbody>{infoData}</tbody>
          </table>
        ) : (
          <div className={styles.card}>
            <div className={styles.iconContainer}>
              <FaExchangeAlt className={styles.icon}/>
            </div>
            <h1>{monitoring ? t('pagina_inicial.youDontMonitor') : t('pagina_inicial.homepage_18')}</h1>
            {monitoring && <a href="/projetos/janelas-transferencias">{t('pagina_inicial.selectToMonitor')}</a>}
          </div> 
        )}
        <Button
          onClick={() => history.push('/projetos/janelas-transferencias')}
          className={styles.button}
          rounded
          fluid
          outline
          color="green"
        >
          {t('pagina_inicial.homepage_6')}
        </Button>
      </>
    );
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t('proj.linkJanelasTransferencias')}</h2>
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => setMonitoring(!monitoring)}
          className={styles.button}
          rounded
          fluid
          outline
          color="green"
          disabled={monitoring}
        >
          {t('pagina_inicial.monitored')}
        </Button>
        <Button
          onClick={() => setMonitoring(!monitoring)}
          className={styles.button}
          rounded
          fluid
          outline
          color="green"
          disabled={!monitoring}
        >
          {t('pagina_inicial.open')}
        </Button>
      </div>
      {content}
    </div>
  );
}
