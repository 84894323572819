import React from 'react';
import PropTypes from 'prop-types';

import styles from './ModalErroPermission.module.scss';
import Modal from 'components/template/Modal/Modal';
import { Button } from 'components/template';
import ErroIconPermission from 'assets/erroPermissionIcon.svg';
import { useTranslation } from 'react-i18next';

export default function ModalErroPermission({ isOpen, link }) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen}>
      <div className={styles.container}>
        <img src={ErroIconPermission} alt="" />
        <div className={styles.text}>
          <h1>{t('modalErroPermissionProjeto.text1')}</h1>
          <p>{t('modalErroPermissionProjeto.text2')}</p>
        </div>
        <div className={styles.buttonContainer}>
          <Button color="green" rounded fluid outline smaller onClick={() => (window.location.href = `${link}`)}>
            {t('home.box17')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

ModalErroPermission.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
};
