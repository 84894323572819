import { withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './Profilecast.module.scss';
import { useTranslation } from 'react-i18next';
import { Menu } from 'components/template';
import CenteredLoader from 'components/template/CenteredLoader';
import Graph from './Graph/Graph';
import { getTeamsHistorical, getTeamsHistoricalFilterOptions } from 'store/ducks/teamsHistorical';
import { positionOptions } from 'shared/mocks';

function ProfileCast({ teamId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { teams } = useSelector(state => state.teamsHistorical);

  const initialFilters = {
    limit: 40,
    season: null,
    competition_name: null,
    female: false,
    position: null,
    athlete_id: null,
  };

  const [filterOptions, setFilterOptions] = useState({});
  const [filter, setFilter] = useState(initialFilters);

  const filterOptionsEmpty = Object.keys(filterOptions).length === 0;
  useEffect(() => {
    if (filterOptionsEmpty) {
      getTeamsHistoricalFilterOptions(teamId).then(d => {
        setFilterOptions(d);
      });
    }
  }, [dispatch, teamId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!filterOptionsEmpty) {
      dispatch(getTeamsHistorical(filter, teamId));
    }
  }, [dispatch, filter, teamId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filterOptions.seasons && filter.season == null) {
      const year = new Date().getFullYear().toString();
      if (filterOptions.seasons.includes(year)) {
        setFilter({
          ...filter,
          season: year,
        });
      } else {
        dispatch(getTeamsHistorical(filter, teamId));
      }
    }
  }, [filterOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  if (filterOptionsEmpty) {
    return <CenteredLoader size="calc(100vh - 240px)" />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.panelTitle}>
        <h2 className={styles.title}>{t('myClub.profileCast.pageTitle')}</h2>
        <p className={styles.subTitle}>{t('myClub.profileCast.pageSubTitle')}</p>
      </div>

      <div className={styles.filtros}>
        <div className={styles.button}>
          <div
            className={`${styles.leftDefault} ${!filter.female ? styles.selected : ''}`}
            onClick={() => setFilter({ ...filter, female: false })}
          >
            <span>{t('myClub.profileCast.male')}</span>
          </div>
          <div
            className={`${styles.rightDefault} ${filter.female ? styles.selected : ''}`}
            onClick={() => setFilter({ ...filter, female: true })}
          >
            <span>{t('myClub.profileCast.female')}</span>
          </div>
        </div>
        <div className={styles.menus}>
          <span>{t('myClub.profileCast.filterBy')}</span>
          <Menu
            title={t('slider_menu.seas')}
            rounded
            larger
            className={styles.menu}
            placeholder={t('slider_menu.season_all')}
            options={(filterOptions.seasons ?? []).map(season => {
              return {
                label: season,
                value: season,
              };
            })}
            allowEmpty
            value={filter.season}
            onClear={() => setFilter({ ...filter, season: '' })}
            setValue={val => setFilter({ ...filter, season: val })}
          />
          {/*
          <Menu
            rounded
            larger
            className={styles.menu}
            placeholder={t('slider_menu.competition_all')}
            options={(filterOptions.competitions ?? []).map(competition => {
              return {
                label: competition,
                value: competition,
              };
            })}
            allowEmpty
            value={filter.competition_name}
            setValue={val => setFilter({ ...filter, competition_name: val })}
          />
          */}
          <Menu
            title={t('athlete_card.pos')}
            rounded
            larger
            className={styles.menu}
            placeholder={t('proj.atletaFiltroPosicaoTodos')}
            options={positionOptions.map(item => ({ label: t(`positionOptions.${item.value}`), value: item.label }))}
            allowEmpty
            value={filter.position}
            onClear={() => setFilter({ ...filter, position: '' })}
            setValue={val => setFilter({ ...filter, position: val })}
          />
          <Menu
            title={t('table.atleta')}
            rounded
            larger
            className={styles.menu}
            placeholder={t('myClub.profileCast.athlete_all')}
            allowEmpty
            options={(filterOptions.athletes ?? []).sort((a, b) => a.label.localeCompare(b.label))}
            value={filter.athlete_id}
            onClear={() => setFilter({ ...filter, athlete_id: '' })}
            setValue={val => setFilter({ ...filter, athlete_id: val })}
          />
        </div>
      </div>
      <Graph loading={teams?.loading} data={teams?.data} />
    </div>
  );
}

ProfileCast.propTypes = {
  teamId: PropTypes.number.isRequired,
};

export default withRouter(ProfileCast);
