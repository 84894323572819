import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import { AthleteAvatar, Menu, StarRating } from 'components/template';
import { useTranslation } from 'react-i18next';
import { HiTrash } from 'react-icons/hi';
import TransferMarketLogo from 'assets/ico/menu/transfermarket.svg';

export default function AthleteCard({
  item,
  bordered,
  iconImg,
  iconColor,
  iconClick,
  iconStyle = {},
  cardColor,
  handleColorChange = () => { },
  hiddenColorPicker = true,
  deleteAthlete,
  hideIcon = false,
  styleClass = '',
  ...rest
}) {
  const history = useHistory();
  const iconClasses = [styles.icon];
  const containerClasses = [styles.athleteContainer, styleClass];
  const project = useSelector(state => state.project);
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState(false);
  const user = useSelector(state => state.user.data);

  if (bordered) containerClasses.push(styles.bordered);
  if (deleting) containerClasses.push(styles.deleting);

  switch (iconColor ?? '') {
    case 'green':
      iconClasses.push(styles.green);
      break;
    case 'orange':
      iconClasses.push(styles.orange);
      break;
    default:
  }

  switch (cardColor ?? '') {
    case 'yellow':
      containerClasses.push(styles.yellow);
      break;
    case 'green':
      containerClasses.push(styles.green);
      break;
    case 'blue':
      containerClasses.push(styles.blue);
      break;
    case 'red':
      containerClasses.push(styles.red);
      break;
    case 'gray':
    default:
  }

  let iconContent = '';
  if (iconImg) {
    iconContent = (
      <div className={iconClasses.join(' ')} style={iconStyle} onClick={iconClick}>
        {iconImg}
      </div>
    );
  } else {
    iconContent = (
      <div className={styles.buttons}>
        {user.plan.mercado_de_transferencias && item.transfer_market_negotiation_id && (
          <div
            className={styles.transferMarketButton}
            onClick={() => {
              history.push({
                pathname: '/transfer-market/my-negotiations',
                state: { negotiation_id: item.transfer_market_negotiation_id },
              });
            }}
          >
            <img
              draggable="false"
              src={TransferMarketLogo}
              alt={t('nav_drawer.mercadoTransferencias')}
              width="14"
              height="14"
            />
          </div>
        )}
        <HiTrash
          onClick={async () => {
            setDeleting(true);
            try {
              const func = deleteAthlete ?? iconClick;
              await func();
            } finally {
              setDeleting(false);
            }
          }}
        />
      </div>
    );
  }

  const url = project?.data?.id ? `/projetos/${project.data.id}/workflow/${item.id}` : `/atletas/${item.id}`;
  return (
    <div className={containerClasses.join(' ')} {...rest}>
      {!hiddenColorPicker && (
        <Menu
          allowEmpty={false}
          className={styles.colorButton}
          rounded
          options={[
            {
              label: t(`proj.atletaCorAmarelo`),
              value: 'yellow',
            },
            {
              label: t(`proj.atletaCorVerde`),
              value: 'green',
            },
            {
              label: t(`proj.atletaCorAzul`),
              value: 'blue',
            },
            {
              label: t(`proj.atletaCorVermelho`),
              value: 'red',
            },
            {
              label: t(`proj.atletaCorCinza`),
              value: 'gray',
            },
          ]}
          value={cardColor}
          setValue={handleColorChange}
        />
      )}
      <Link
        key={item.id}
        to={{ pathname: url, state: { previews: window.location.pathname } }}
        target={iconImg || iconClick ? '_blank' : '_self'}
      >
        <div className={styles.left}>
          <AthleteAvatar name={item.nickname ?? item.fullname} img={item.photo} clubImg={item.current_team?.emblem} />
        </div>
        <div className={styles.right}>
          <div className={styles.name} title={item.nickname}>
            {item.nickname}
          </div>
          <div className={styles.completeName} title={item.fullname}>
            {item.fullname}
          </div>
          <div className={styles.info}>
            {item.nationalities && item.nationalities.length > 0 && item.nationalities[0] && (
              <img src={item.nationalities[0].flag} alt={item.nationalities[0].name} draggable="false" />
            )}
            <span className={styles.age}>
              {item.year} {item.age} {t('athlete_card.idade')}
            </span>
            <span className={styles.position}>{t(`positionOptionsRaw.${item.position}`)}</span>
          </div>
          {item.last_evaluation != null && (
            <div className={styles.starRating}>
              <StarRating rate={item.last_evaluation} size={15} />
            </div>
          )}
          {item.projects_count != null && (
            <div className={styles.completeName}>
              {t('proj.athleteProjectCount', { count: item.projects_count })}{item.projects_count > 1 ? 's' : ''}.
            </div>
          )}
        </div>
      </Link>
      {!hideIcon && iconContent}
    </div>
  );
}
