import React from 'react';
import PropTypes from 'prop-types';
import Variables from 'variables.js';
import Chart from 'react-apexcharts';

export default function RadarChart({ options, data, height }) {
  const chartOptions = {
    labels: [...data.labels],
    plotOptions: {
      radar: {
        size: 105,
        polygons: {
          strokeColor: Variables.lighterGrey,
          fill: {
            colors: [Variables.light, '#fff'],
          },
        },
      },
    },
    colors: [Variables.warning],
    fill: {
      colors: ['rgba(255, 255, 255, 0.1)'],
    },
    markers: {
      size: 4,
      colors: ['#fff'],
      strokeColor: Variables.warning,
      strokeWidth: 2,
    },
    tooltip: {
      y: {
        formatter(val) {
          return val;
        },
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 769,
        options: {
          plotOptions: {
            radar: {
              size: 50,
            },
          },
        },
      },
      {
        breakpoint: 1025,
        options: {
          plotOptions: {
            radar: {
              size: 75,
            },
          },
        },
      },
    ],
    yaxis: {
      max: 5,
      tickAmount: 5,
      show: false,
      labels: {
        show: false,
      },
    },
    ...options,
  };
  const series = [...data.series];

  return <Chart height={height} type='radar' options={chartOptions} series={series} />;
}

RadarChart.defaultProps = {
  options: {},
};

RadarChart.propTypes = {
  options: PropTypes.instanceOf(Object),
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    series: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      }),
    ),
  }).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
