import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Dialog, Card, Table, Checkbox, Spinner, Pagination } from 'components/template';
import ActionBar from './ActionBar';
import { getReports, deleteReports } from 'store/ducks/reports';
import { usePagination } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

const Reports = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const initialFilters = {
    name: '',
    analyst: '',
  };

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [checkedReports, setCheckedReports] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [filters, setFilters] = useState(initialFilters);

  const [pagination, setPagination, defaultPagination] = usePagination();

  const loggedUser = useSelector(state => state.user.data);
  const reports = useSelector(state => state.reports.reports);
  const dispatch = useDispatch();

  // Fetch reports
  useEffect(() => {
    dispatch(getReports({ ...defaultPagination, ...filters }));
  }, [dispatch, filters]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handle all checked
  useEffect(() => {
    if (allChecked) {
      setCheckedReports(reports.data.map(report => report.id));
    } else {
      setCheckedReports([]);
    }
  }, [allChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCheckReport = reportId => {
    if (checkedReports.some(report => report === reportId)) {
      setCheckedReports(checkedReports.filter(report => report !== reportId));
    } else {
      setCheckedReports([...checkedReports, reportId]);
    }
  };

  const handlePagination = newPagination => {
    setPagination(newPagination);
    dispatch(getReports({ ...newPagination }));
  };

  const handleReports = reports => {
    const rows = reports.map(report => {
      const checkbox = (
        <Checkbox
          dark
          checked={checkedReports.some(ckdReport => ckdReport === report.id)}
          onChange={() => {
            handleCheckReport(report.id);
          }}
        />
      );
      const name = report.name || '-';
      const athletes = (
        <div className={styles.athletes_body}>
          {report.athletes?.names ? report.athletes?.names : t('user.text7')}
          {report.athletes.remaining > 0 && (
            <span className={styles.remainingAthletes}>
              {' '}
              + {report.athletes.remaining.toLocaleString('pt-BR')}{' '}
              {report.athletes.remaining === 1 ? t('rest.box21') : t('proj.box20')}
            </span>
          )}
        </div>
      );
      const generatedDate = moment(new Date(report.generated_date)).format('DD/MM/YYYY');
      const author = <span className={styles.darkPurple9}>{report.author.name}</span>;
      const button =
        (loggedUser.plan.mensageria || loggedUser.id === report.owner) ? (
          <div
            onClick={() => {
              history.push(`/relatorios/${report.id}`);
            }}
            className={styles.smallFont}
          >
            {t('user.text8')}
          </div>
        ) : (
          ''
        );

      return [checkbox, name, athletes, generatedDate, author, button];
    });

    return rows;
  };

  const handleReportsDelete = () => {
    setIsOpenDialog(false);
    dispatch(
      deleteReports(
        checkedReports.map(report => report),
        { ...pagination }
      )
    );
    setCheckedReports([]);
  };

  if (reports.error) {
    return (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(reports.error)}</p>
      </Card>
    );
  }

  return (
    <div>
      <div className={styles.content}>
        <Card softShadow className={styles.reports}>
          <ActionBar
            reports={reports.data}
            filters={filters}
            setFilters={setFilters}
            checkedReports={checkedReports}
            setIsOpenDialog={setIsOpenDialog}
          />
          {reports.loading ? (
            <div className={styles.loader}>
              <Spinner />
            </div>
          ) : (
            <div className={styles.tableContainer}>
              <Table
                flat
                theads={[
                  <Checkbox
                    dark
                    checked={allChecked}
                    onChange={() => {
                      setAllChecked(!allChecked);
                    }}
                  />,
                  t('agencies.report_name'),
                  <span className={styles.textLeft}>{t('user.text9')}</span>,
                  t('report.gene_in'),
                  t('table.analist'),
                  '',
                ]}
                data={handleReports(reports.data)}
                className={styles.reportTable}
              />
            </div>
          )}
        </Card>
      </div>
      <Dialog
        isOpen={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        mainTitle={t('user.text10')}
        btnLeft={t('dialog_content.cancel')}
        btnRight={t('user.text11')}
        btnLeftClick={() => setIsOpenDialog(false)}
        btnRightClick={() => handleReportsDelete()}
      >
        {t('user.text12')}
      </Dialog>
      <Pagination
        count={reports.count}
        setPagination={handlePagination}
        pagination={pagination}
        resourceDisplayName={t('proj.linkRelatorios')}
        length={reports.data.length}
        hasNext={reports.hasNext}
        hasPrevious={reports.hasPrevious}
      />
    </div>
  );
};

export default Reports;
