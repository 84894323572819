import React from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import { AthleteAvatar } from 'components/template';
import { useTranslation } from 'react-i18next';
//import "../../translations/i18n"

export default function AthleteMinCard({ item, bordered, cardColor, ...rest }) {
  const containerClasses = [styles.athleteContainer];
  const { t } = useTranslation();

  if (bordered) containerClasses.push(styles.bordered);

  switch (cardColor ?? '') {
    case 'yellow':
      containerClasses.push(styles.yellow);
      break;
    case 'green':
      containerClasses.push(styles.green);
      break;
    case 'blue':
      containerClasses.push(styles.blue);
      break;
    case 'red':
      containerClasses.push(styles.red);
      break;
    case 'gray':
    default:
  }

  return (
    <div className={containerClasses.join(' ')} {...rest}>
      <Link
        key={item.id}
        to={{ pathname: `/atletas/${item.id}`, state: { previews: window.location.pathname } }}
        target={'_blank'}
      >
        <div className={styles.left}>
          <AthleteAvatar img={item.photo} clubImg={item.current_team?.emblem} className={styles.athleteAvatar} />
        </div>
        <div className={styles.right}>
          <span
            className={styles.name}
            footlink-title={(item.nickname || item.fullname) + " '" + (item.birthdate?.slice(2, 4) || '??')}
          >
            {item.nickname || item.fullname}
            <span className={styles.age}>'{item.birthdate?.slice(2, 4) || '??'}</span>
          </span>
          <div className={styles.info}>
            {item.nationalities && item.nationalities.length > 0 && item.nationalities[0] && (
              <img src={item.nationalities[0].flag} alt={item.nationalities[0].name} draggable="false" />
            )}
            {item.current_team ? item.current_team.name : t('errorMessage.resourceNotFoundClub')}
          </div>
        </div>
      </Link>
    </div>
  );
}
