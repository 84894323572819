import React from 'react';
import PropTypes from 'prop-types';

import styles from './TopBar.module.scss';

export default function TopBar({ title, subtitle, icon, iconBgColor, className }) {
  return (
    <div className={className}>
      <div className={styles.left}>
        <span style={{ backgroundColor: iconBgColor }} className={styles.icon}>
          {icon}
        </span>
        <div className={styles.clubInfo}>
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
      </div>
    </div>
  );
}

TopBar.defaultProps = {
  subtitle: '',
  icon: null,
  className: styles.topBar,
};

TopBar.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  iconBgColor: PropTypes.string,
};
