import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLatestMoves } from 'store/ducks/home';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import thumb from './../../../assets/images/Frame 272.png';

import Tabs from './Tabs';
import Bids from './Bids';
import Activities from './Activities';
import Negotiations from './Negotiations';
import Notices from './Notices';
import { Spinner, Card, Button } from 'components/template';

export default function LatestMoves({ styleClass = '' }) {
  const newMoves = 6;

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const latestMoves = useSelector(state => state.home.latestMoves);
  const loggedUser = useSelector(state => state.user.data);

  const [filter, setFilter] = useState(loggedUser.plan.meu_feed ? '' : 'actions');
  const [showTabs, setShowTabs] = useState(false);
  const [visible, setVisible] = useState(newMoves);

  useEffect(() => {
    dispatch(getLatestMoves(filter, loggedUser.plan.competicoes_de_base));
    setVisible(newMoves);
  }, [dispatch, filter]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleShowMore = () => {
    setVisible(visible + newMoves);
  };

  const cards =
    latestMoves.data &&
    latestMoves.data.slice(0, visible).map(item => {
      switch (item.category) {
        case 'new_notice':
          return <Notices key={item.id} data={item} />;
        case 'new_negotiation':
          return <Negotiations key={item.id} data={item} />;
        case 'new_bid':
          return <Bids key={item.id} data={item} />;
        case 'new_custom_athlete':
        case 'new_monitoring':
        case 'new_reports_pro':
        case 'new_report':
          return <Activities key={item.id} data={item} />;
        default:
          return undefined;
      }
    });

  let content = '';
  let banner = '';
  if (latestMoves.loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (latestMoves.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(latestMoves.error)}</p>
      </Card>
    );
  } else if (latestMoves.data) {
    if (!showTabs) {
      setShowTabs(true);
    }
    if (latestMoves.bannerData) {
      banner = (
        <div className={styles.sizeDiv}>
          <Link to={`/base/competicoes/${latestMoves.bannerData.id}`}>
            <img className={styles.img} src={latestMoves.bannerData.highlight_banner} alt="banner" />
          </Link>
        </div>
      );
    }
    content = (
      <>
        {cards}
        {latestMoves.data && visible < latestMoves.data.length && (
          <Button
            onClick={handleShowMore}
            className={styles.button}
            rounded
            fluid
            outline
            disabled={latestMoves.data && latestMoves.data.length > 0 ? false : true}
            color="green"
          >
            + {t('pagina_inicial.homepage_5')}
          </Button>
        )}
      </>
    );
  }

  return (
    <div className={[styles.container, styleClass].join(' ')}>
      {banner}
      <h2 className={styles.title}>{t('pagina_inicial.homepage_1')}</h2>
      {showTabs && <Tabs filter={filter} setFilter={setFilter} />}
      {/* <Link to="/originals">
        <img src={thumb} alt="O mercado francês de futebol" className={styles.bannerOriginals}/>
      </Link> */}
      {content}
    </div>
  );
}
