import React from 'react';
import * as S from './styles';
import Variables from 'variables.js';

export default function CorredoresModel({ fieldColor }) {

  const getColorFromFieldColor = () => {
    switch(fieldColor) {
      case Variables.green3:
        return Variables.green1;
      case Variables.darkPurple2:
        return Variables.lightPurple2;
      default:
        return Variables.gray2;
    }
  }
  const color = getColorFromFieldColor();

  return (
    <>
      <S.LineHModelCorredor left="21.3%" color={color} />
      <S.LineHModelCorredor left="35.8%" color={color} />
      <S.LineHModelCorredor left="63.2%" color={color} />
      <S.LineHModelCorredor left="77.7%" color={color} />
    </>
  )
}