import React from 'react';
import PropTypes from 'prop-types';
import styles from './ClubInfo.module.scss';
import { SocialButton, IconButton } from 'components/template';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import { formatDate } from 'shared/utility';
import { FaPen } from 'react-icons/fa';

export default function ClubInfo({ club, setIsOpen }) {
  const { t } = useTranslation();

  return (
    <>
      {club && (
        <div className={styles.clubInfo}>
          <h2 className={styles.title}>{t('myClub.general.info')}</h2>
          <div className={styles.content}>
            <Carousel showThumbs={false} dynamicHeight={true} showStatus={false} emulateTouch={true}>
              {club.photos?.map((photo, index) => {
                return <img className={styles.image} alt={`club${index}`} src={photo} />;
              })}
            </Carousel>
            {/* GENERAL INFO */}
            <div className={styles.generalInfo}>
              <div className={styles.left_column}>
                <div>
                  <span>{t('text.box35')}: </span>
                </div>
                <div>
                  <span>{t('text.box34')}: </span>
                </div>
                <div>
                  <span>{t('text.box36')}: </span>
                </div>
                <div>
                  <span>{t('organi.box18')}: </span>
                </div>
                <div>
                  <span>{t('text.box19')}: </span>
                </div>
                <div>
                  <span>{t('text.box14')}: </span>
                </div>
              </div>
              <div className={styles.right_column}>
                <div>
                  <span className={styles.bold}>{club.city_team || '-'}</span>
                </div>
                <div>
                  <span className={styles.bold}>{club.state || '-'}</span>
                </div>
                <div>
                  <span className={styles.bold}>{club.country?.name || '-'}</span>
                </div>
                <div>
                  <span className={styles.bold}>{club.founding_date ? formatDate(club.founding_date) : '-'}</span>
                </div>
                <div>
                  <span className={styles.bold}>{club.profile?.stadium || '-'}</span>
                </div>
                <div>
                  <span className={styles.bold}>{club.partners || '-'}</span>
                </div>
              </div>
              <p>{club.address}</p>
            </div>

            {/* SOCIAL BUTTONS */}
            <div className={styles.socialButtons}>
              {club.social?.website && <SocialButton type="website" href={club.social?.website} />}
              {club.social?.wikipedia && <SocialButton type="wikipedia" href={club.social?.wikipedia} />}
              {club.social?.instagram && <SocialButton type="instagram" href={club.social?.instagram} />}
              {club.social?.twitter && <SocialButton type="twitter" href={club.social?.twitter} />}
              {club.social?.youtube && <SocialButton type="youtube" href={club.social?.youtube} />}
              {club.social?.tiktok && <SocialButton type="tiktok" href={club.social?.tiktok} />}
            </div>
            {/* EDIT BUTTON */}
            <div className={styles.edit_button_container}>
              <IconButton
                className={styles.edit_button}
                text={t('buttons.edit')}
                icon={<FaPen />}
                onClick={() => setIsOpen(true)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

ClubInfo.propTypes = {
  club: PropTypes.instanceOf(Object).isRequired,
};
