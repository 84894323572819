import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Table, Button, StarRating, Checkbox } from 'components/template';
import TeamRow from 'components/TeamRow/TeamRow';
import styles from './EvaluationsTable.module.scss';
import { useTranslation } from 'react-i18next';
//import "../../translations/i18n"

export default function EvaluationsTable({ evaluations, checkedEvaluations, setCheckedEvaluations }) {
  const history = useHistory();
  const { t } = useTranslation();

  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    if (allChecked) {
      setCheckedEvaluations(evaluations.map(evaluation => ({ id: evaluation.id, athlete: evaluation?.athlete?.id })));
    } else {
      setCheckedEvaluations([]);
    }
  }, [allChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEvaluationCheck = evaluation => {
    if (checkedEvaluations.some(item => item.id === evaluation.id)) {
      setCheckedEvaluations(checkedEvaluations.filter(item => item.id !== evaluation.id));
    } else {
      setCheckedEvaluations([...checkedEvaluations, evaluation]);
    }
  };

  const data = evaluations?.map(item => {
    const checkbox = (
      <Checkbox
        dark
        checked={checkedEvaluations.some(ckdEval => ckdEval.id === item.id)}
        onChange={() => {
          handleEvaluationCheck({ id: item.id, athlete: item.athlete?.id });
        }}
      />
    );

    const rate = (
      <div className={styles.ratingContainer} style={{ display: 'flex', gap: '5px' }}>
        <StarRating rate={item.generalEvaluation} size={15} />
        <p>({item?.generalEvaluation ? item.generalEvaluation.toFixed(2) : '0.00'})</p>
      </div>
    );

    const current_team = item?.current_team?.nickname ? (
      <TeamRow name={item?.current_team?.nickname} id={item?.current_team.id} img={item?.current_team.emblem} />
    ) : null;

    const team = <TeamRow name={item?.team?.name} id={item?.team?.id} img={item?.team?.emblem} />;

    const direc = item?.recommendation && (t('config.' + item?.recommendation) || '');

    const priority =
      (
        <div className={styles.priority}>
          {item?.priority === 'ALTA' && <div className={styles.highPriority} />}
          {item?.priority === 'MEDIA' && <div className={styles.mediumPriority} />}
          {item?.priority === 'BAIXA' && <div className={styles.lowPriority} />}
          {item?.priority && t('config.' + item?.priority)}
        </div>
      ) || '';
    return [
      checkbox,
      moment(item.date).format('DD/MM/YYYY'),
      item.author.name !== ' ' ? item.author.name : item.author.email,
      current_team,
      team,
      item.mode,
      rate,
      direc,
      priority,
      <Button
        evaluationB
        key={item.id}
        onClick={() => history.push(`/atletas/${item?.athlete?.id}/avaliacoes/${item?.id}`)}
        noShadow
        smaller
        rounded
        color="green"
      >
        <span>{t('table.text2')}</span>
      </Button>,
    ];
  });

  return (
    <div className={styles.evaluations}>
      {/* <div className={styles.menuList}></div> */}
      {/* <Table theads={['Jogador', 'Apelido', 'Clube', 'Data', 'Analista', 'Avaliação Geral', ' ']} data={data} /> */}
      <Table
        theads={[
          <Checkbox dark checked={allChecked} onChange={() => setAllChecked(!allChecked)} />,
          t('organi.text6'),
          t('table.analist'),
          t('agencies.text2'),
          <div className={styles.club_column}>
            {t('agencies.club_at_moment1')} <br /> {t('agencies.club_at_moment2')}
          </div>,
          t('table.model'),
          t('club.box15'),
          t('config.dire'),
          t('config.pri'),
          ' ',
        ]}
        data={data}
        className={styles.evaluationTable}
      />
    </div>
  );
}

EvaluationsTable.propTypes = {
  evaluations: PropTypes.instanceOf(Object).isRequired,
};
