import React from 'react';
import PropTypes from 'prop-types';
import styles from './TopBar.module.scss';
import { Avatar } from 'components/template';

export default function TopBar({ club }) {
  const addressArray = [];
  if (club?.city_team) addressArray.push(club.city_team);
  if (club?.state) addressArray.push(club.state);
  if (club?.country?.name) addressArray.push(club.country.name);

  return (
    <div className={styles.topBar}>
      <div className={styles.left}>
        <Avatar
          noBorder
          noRadius
          img={club.img ?? club.emblem ?? ''}
          name={club.shortName ?? club.nickname}
          className={styles.club_avatar}
        />
        <div className={styles.clubInfo}>
          <h1>{club.shortName ?? club.nickname}</h1>
          <span>{club.longname ?? ''}</span>
          {club?.country?.flag && (
            <div className={styles.address}>
              <img draggable="false" src={club.country.flag} alt="país do clube" className={styles.country_flag} />
              <span>{addressArray.join(', ')}</span>
            </div>
          )}
        </div>
        <span className={styles.separator}></span>
      </div>
    </div>
  );
}

TopBar.propTypes = {
  club: PropTypes.instanceOf(Object).isRequired,
};
