import Endpoints from 'endpoints';
import axios from 'api';
import ErrorMessages from 'shared/ErrorMessages';
import { updatedStringifyFilters } from 'shared/utility';

export const actionTypes = {
  GET_ATHLETES: 'transfer/athletes/GET',
  GET_ATHLETES_SUCCESS: 'transfer/athletes/GET_SUCCESS',
  GET_ATHLETES_FAIL: 'transfer/athletes/GET_FAIL',
  GET_ADS: 'ads/GET',
  GET_ADS_SUCCESS: 'ads/GET_SUCCESS',
  GET_ADS_FAIL: 'ads/GET_FAIL',
  GET_TRANSFER_MARKET_SUCCESS: 'ads/GET_TRANSFER_MARKET_SUCCESS',
  POST_ADS: 'ads/POST',
  POST_ADS_SUCCESS: 'ads/POST_SUCCESS',
  POST_ADS_FAIL: 'ads/POST_FAIL',
  PATCH_ADS: 'ads/PATCH',
  PATCH_ADS_SUCCESS: 'ads/PATCH_SUCCESS',
  PATCH_ADS_FAIL: 'ads/PATCH_FAIL',
  DELETE_ADS: 'ads/DELETE',
  DELETE_ADS_SUCCESS: 'ads/DELETE_SUCCESS',
  DELETE_ADS_FAIL: 'ads/DELETE_FAIL',
  RESET_VIEWED_TRANSFERMARKET: 'ads/RESET_VIEWED_TRANSFERMARKET',
};

const initialState = {
  loading: false,
  error: null,
  data: [],
  max_salary_value: null,
  max_sale_value: null,
  max_purchase_value: null,
  viewedTransferMarket: null,
  hasNext: true,
  hasPrevious: false,
  count: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ATHLETES:
      return {
        ...initialState,
        loading: true,
      };
    case actionTypes.GET_ATHLETES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        hasNext: action.payload.hasNext,
        hasPrevious: action.payload.hasPrevious,
        max_salary_value: action.payload.max_salary_value,
        max_sale_value: action.payload.max_sale_value,
        max_purchase_value: action.payload.max_purchase_value,
        count: action.payload.count,
        error: null,
      };
    case actionTypes.GET_ATHLETES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actionTypes.GET_ADS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ADS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        hasNext: action.payload.hasNext,
        hasPrevious: action.payload.hasPrevious,
        count: action.payload.count,
        viewedTransferMarket: null,
        error: null,
      };
    case actionTypes.GET_TRANSFER_MARKET_SUCCESS:
      return {
        ...state,
        loading: false,
        viewedTransferMarket: action.payload.data,
        error: null,
      };
    case actionTypes.GET_ADS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actionTypes.POST_ADS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.POST_ADS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.POST_ADS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actionTypes.RESET_VIEWED_TRANSFERMARKET:
      return {
        ...state,
        viewedTransferMarket: null,
      };
    default:
      return state;
  }
}

let axiosGetAdsAthletesController;
export const getFilteredAthletes = filters => async dispatch => {
  dispatch({ type: actionTypes.GET_ATHLETES });

  const url = Endpoints.athletes;

  try {
    if ((filters.lists != null && filters.lists.length > 0) || filters.watched !== true) {
      delete filters.watched;
    }
    const queryString = updatedStringifyFilters(filters);
    if (axiosGetAdsAthletesController) {
      axiosGetAdsAthletesController.abort();
    }
    axiosGetAdsAthletesController = new AbortController();
    const { data } = await axios.get(`${url}?transfermarket=true${queryString}`);
    axiosGetAdsAthletesController = null

    dispatch({
      type: actionTypes.GET_ATHLETES_SUCCESS,
      payload: {
        data: data.results,
        hasNext: data.next ? true : false,
        hasPrevious: data.previous ? true : false,
        max_salary_value: data.max_salary_value,
        max_sale_value: data.max_sale_value,
        count: data.count,
      },
    });
  } catch (err) {
    if (err?.name === 'CanceledError') return;
    const { response } = err;
    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_ATHLETES_FAIL,
        payload: { error: 'erros.text13' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_ATHLETES_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else {
      dispatch({
        type: actionTypes.GET_ATHLETES_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};

export const getDesiredAthletes = filters => async dispatch => {
  dispatch({ type: actionTypes.GET_ATHLETES });
  const url = Endpoints.desiredTransferMarket;
  try {
    if ((filters.lists != null && filters.lists.length > 0) || filters.watched !== true) {
      delete filters.watched;
    }
    const queryString = updatedStringifyFilters(filters);
    if (axiosGetAdsAthletesController) {
      axiosGetAdsAthletesController.abort();
    }
    axiosGetAdsAthletesController = new AbortController();
    const { data } = await axios.get(`${url}?${queryString}`);
    axiosGetAdsAthletesController = null

    dispatch({
      type: actionTypes.GET_ATHLETES_SUCCESS,
      payload: {
        data: data.results,
        hasNext: data.next ? true : false,
        hasPrevious: data.previous ? true : false,
        max_salary_value: data.max_salary_value,
        max_purchase_value: data.max_purchase_value,
        count: data.count,
      },
    });
  } catch (err) {
    if (err?.name === 'CanceledError') return;
    const { response } = err;
    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_ATHLETES_FAIL,
        payload: { error: 'erros.text13' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_ATHLETES_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else {
      dispatch({
        type: actionTypes.GET_ATHLETES_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};

let axiosGetAdsController;
export const getMyAds = () => async dispatch => {
  dispatch({ type: actionTypes.GET_ADS });
  try {
    if (axiosGetAdsController) {
      axiosGetAdsController.abort();
    }
    axiosGetAdsController = new AbortController();
    const { data } = await axios.get(`${Endpoints.transferMarket}/?my_ads=true`, { signal: axiosGetAdsController.signal });
    axiosGetAdsController = null;

    dispatch({
      type: actionTypes.GET_ADS_SUCCESS,
      payload: {
        data: data.results,
        hasNext: data.next ? true : false,
        hasPrevious: data.previous ? true : false,
        count: data.count,
      },
    });
  } catch (err) {
    if (err?.name === 'CanceledError') return;
    const { response } = err;
    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_ADS_FAIL,
        payload: { error: 'erros.text13' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_ADS_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else {
      dispatch({
        type: actionTypes.GET_ADS_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};

export const getMyDesiredAds = () => async dispatch => {
  dispatch({ type: actionTypes.GET_ADS });
  try {
    if (axiosGetAdsController) {
      axiosGetAdsController.abort();
    }
    axiosGetAdsController = new AbortController();
    const { data } = await axios.get(`${Endpoints.desiredTransferMarket}/?my_ads=true`, { signal: axiosGetAdsController.signal });
    axiosGetAdsController = null;

    dispatch({
      type: actionTypes.GET_ADS_SUCCESS,
      payload: {
        data: data.results,
        hasNext: data.next ? true : false,
        hasPrevious: data.previous ? true : false,
        count: data.count,
      },
    });
  } catch (err) {
    if (err?.name === 'CanceledError') return;
    const { response } = err;
    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_ADS_FAIL,
        payload: { error: 'erros.text13' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_ADS_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else {
      dispatch({
        type: actionTypes.GET_ADS_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};

export const getTransferMarket = id => async dispatch => {
  dispatch({ type: actionTypes.GET_ADS });

  const url = `${Endpoints.transferMarket}/${id}`;
  try {
    const { data } = await axios.get(url);
    dispatch({
      type: actionTypes.GET_TRANSFER_MARKET_SUCCESS,
      payload: { data },
    });
  } catch (err) {
    const { response } = err;
    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_ADS_FAIL,
        payload: { error: 'erros.text13' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_ADS_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else {
      dispatch({
        type: actionTypes.GET_ADS_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};

export const getDesiredTransferMarket = id => async dispatch => {
  dispatch({ type: actionTypes.GET_ADS });

  const url = `${Endpoints.desiredTransferMarket}/${id}`;
  try {
    const { data } = await axios.get(url);
    dispatch({
      type: actionTypes.GET_TRANSFER_MARKET_SUCCESS,
      payload: { data },
    });
  } catch (err) {
    const { response } = err;
    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_ADS_FAIL,
        payload: { error: 'erros.text13' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_ADS_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else {
      dispatch({
        type: actionTypes.GET_ADS_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};

export const resetViewedTransfermarket = () => ({ type: actionTypes.RESET_VIEWED_TRANSFERMARKET });

export const publishAd = async body => {
  const url = Endpoints.transferMarket;

  try {
    const { data } = await axios.post(`${url}`, body);
    return { data };
  } catch (err) {
    return err;
  }
};

export const updateAdAsync = async (id, body) => {
  const url = Endpoints.transferMarket;
  const { data } = await axios.patch(`${url}/${id}`, body);
  return data;
};

export const updateAd = (id, body) => async dispatch => {
  dispatch({ type: actionTypes.PATCH_ADS });
  try {
    const data = await updateAdAsync(id, body);

    dispatch({ type: actionTypes.PATCH_ADS_SUCCESS, payload: { data } });
  } catch (err) {
    dispatch({
      type: actionTypes.PATCH_ADS_FAIL,
      payload: { error: 'erros.text44' },
    });
  }
};

export const deleteAd = async id => {
  const url = Endpoints.transferMarket;
  try {
    await axios.delete(`${url}/${id}`);
    return true;
  } catch (err) {
    return false;
  }
};

export const publishDesiredAd = async body => {
  const url = Endpoints.desiredTransferMarket;

  try {
    if (body.nationalities === '') {
      body.nationalities = [];
    }
    const { data } = await axios.post(`${url}`, body);
    return { data };
  } catch (err) {
    return err;
  }
};

export const updateDesiredAdAsync = async (id, body) => {
  const url = Endpoints.desiredTransferMarket;
  if (body.nationalities === '') {
    body.nationalities = [];
  }
  const { data } = await axios.patch(`${url}/${id}`, body);
  return data;
};

export const updateDesiredAd = (id, body) => async dispatch => {
  dispatch({ type: actionTypes.PATCH_ADS });
  try {
    const data = await updateDesiredAdAsync(id, body);

    dispatch({ type: actionTypes.PATCH_ADS_SUCCESS, payload: { data } });
  } catch (err) {
    dispatch({
      type: actionTypes.PATCH_ADS_FAIL,
      payload: { error: 'erros.text44' },
    });
  }
};

export const deleteDesiredAd = async id => {
  const url = Endpoints.desiredTransferMarket;
  try {
    await axios.delete(`${url}/${id}`);
    return true;
  } catch (err) {
    return false;
  }
};

export const offerDesiredAthlete = async payload => {
  const url = Endpoints.desiredAthletesOffered;
  try {
    const { data } = await axios.post(url, payload);
    return data;
  } catch (err) {
    return false;
  }
};

export const updateOfferDesiredAthlete = async (id, payload) => {
  const url = Endpoints.desiredAthletesOffered;
  try {
    const { data } = await axios.patch(`${url}/${id}`, payload);
    return data;
  } catch (err) {
    return false;
  }
};

export const deleteOfferDesiredAthlete = async id => {
  const url = Endpoints.desiredAthletesOffered;
  try {
    await axios.delete(`${url}/${id}`);
    return true;
  } catch (err) {
    return false;
  }
};

