import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUser, Creators } from 'store/ducks/user';
import Page from 'components/template/Page';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import { FaUser } from 'react-icons/fa';
import * as S from './styles';
import UserData from './UserData';
import { Route } from 'react-router';
import PasswordChange from './PasswordChange';
import { useTranslation } from 'react-i18next';

let userLastPhoto = null;
function Profile() {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);

  const userProfilePhoto = user?.profile?.data?.profile?.photo;
  if (userProfilePhoto != null) {
    if (userLastPhoto != null && userLastPhoto !== userProfilePhoto) {
      window.location.reload();
    }
    userLastPhoto = user.profile.data.profile.photo;
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user.data.id) {
      Creators.logout();
    } else {
      dispatch(getUser(user.data.id));
    }
  }, [dispatch, user.data]);

  const hasAnyPermission = user.data.plan.has_any_permission;
  const tabs = [];
  if (hasAnyPermission) {
    tabs.push({
      label: t('home.text18'),
      link: '/perfil',
      component: <UserData />,
    });
  }
  tabs.push({
    label: t('home.text19'),
    link: '/perfil/alterar-senha',
    component: <PasswordChange user={user.profile.data} />,
  });

  const routes = tabs.map(item => <Route key={item.label} exact path={item.link} render={() => item.component} />);

  return (
    <>
      <Page title={t('home.text18')} subtitle={t('home.text20')} icon={<FaUser />} loading={user.profile.loading}>
        {hasAnyPermission && <NavigationBar items={tabs} />}
        <S.Card bordered>{routes}</S.Card>
      </Page>
    </>
  );
}

export default Profile;
