import React, { useState, useEffect, useCallback } from 'react';
import { FaMinus, FaAngleUp } from 'react-icons/fa';
import styles from './CustomCollapse.module.scss';
import { useTranslation } from 'react-i18next';

export default function CustomCollapse({ children, title, openedDefault }) {
  const { t } = useTranslation();
  const maxHeight = 180;

  const [icon, setIcon] = useState(<FaAngleUp />);
  const [isOpen, setIsOpen] = useState(openedDefault);

  const [realMaxHeight, setRealMaxHeight] = useState(0);

  const [contentMaxHeight, setContentMaxHeight] = useState(0);

  const heightHandler = useCallback(node => {
    if (node) {
      if (node.getBoundingClientRect().height > maxHeight) {
        setRealMaxHeight(node.getBoundingClientRect().height);

        setContentMaxHeight(maxHeight);
      } else {
        setContentMaxHeight(node.getBoundingClientRect().height);
      }
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      setIcon(<FaAngleUp />);
    } else {
      setIcon(<FaMinus />);
    }
  }, [isOpen]);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.container}>
      <div onClick={() => handleClick()} className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
        <span className={styles.icon}>{icon}</span>
      </div>
      <div style={{ maxHeight: isOpen ? contentMaxHeight : 0 }} className={styles.contentContainer}>
        <div ref={heightHandler}>{children}</div>
      </div>
      {realMaxHeight > maxHeight && isOpen && (
        <span
          className={styles.showMore}
          onClick={() => setContentMaxHeight(contentMaxHeight > maxHeight ? maxHeight : realMaxHeight)}
        >
          {contentMaxHeight > maxHeight
            ? '- ' + t('pagina_inicial.homepage_19')
            : '+ ' + t('pagina_inicial.homepage_5')}
        </span>
      )}
    </div>
  );
}

CustomCollapse.defailtProps = {
  openedDefault: true,
};
