import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';

export default function Button({ children, rounded, className, fluid, color, outline, smaller, noShadow, type, evaluationB, ...rest }) {
  const buttonClasses = [styles.button];
  if (rounded) buttonClasses.push(styles.rounded);
  if (className) buttonClasses.push(className);
  if (fluid) buttonClasses.push(styles.fluid);
  if (outline) buttonClasses.push(styles.outline);
  if (smaller) buttonClasses.push(styles.smaller);
  if (noShadow) buttonClasses.push(styles.noShadow);
  if (evaluationB) buttonClasses.push(styles.evaluationB);

  switch (color) {
    case 'green':
      buttonClasses.push(styles.green);
      break;
    case 'danger':
      buttonClasses.push(styles.danger);
      break;
    case 'orange':
      buttonClasses.push(styles.orange);
      break;
    case 'white':
      buttonClasses.push(styles.white);
      break;
    case 'transparent':
      buttonClasses.push(styles.transparent);
      break;
    case 'link':
      buttonClasses.push(styles.link);
      break;
    case 'purple':
      buttonClasses.push(styles.purple)
      break;
    default:
  }

  return (
    <button type={type} {...rest} className={buttonClasses.join(' ')}>
      {children}
    </button>
  );
}

Button.defaultProps = {
  rounded: false,
  className: null,
  fluid: false,
  color: '',
  outline: false,
  smaller: false,
  noShadow: false,
  type: 'button'
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  rounded: PropTypes.bool,
  className: PropTypes.string,
  fluid: PropTypes.bool,
  color: PropTypes.string,
  outline: PropTypes.bool,
  smaller: PropTypes.bool,
  noShadow: PropTypes.bool,
};
