import React from 'react';

// hooks
// import { useTranslation } from 'react-i18next';

// scss
import styles from './TeamChatItem.module.scss';

// components
import { Avatar } from 'components/template';

// other
import moment from 'moment';

export default function TeamChatItem({
  chat,
  user,
  last_message,
  isSelected,
  isAlertVisible,
  alertCount,
  setAlertVisibility,
  handleSelectChat,
}) {
  // const { t } = useTranslation();

  const containerClasses = [styles.athleteContainer];
  if (isSelected) containerClasses.push(styles.athleteSelected);

  return (
    <div key={chat.id} className={containerClasses.join(' ')} onClick={() => setAlertVisibility(false)}>
      <div className={styles.link} onClick={() => handleSelectChat(chat.id)}>
        <div className={styles.left}>
          <div className={styles.clubContainer}>
            <div className={styles.athleteImg}>
              <Avatar
                img={chat.team.id === user.organization.teamId ? chat.created_by?.photo : chat.team?.emblem}
                name={chat.team.id === user.organization.teamId ? chat.created_by?.name : chat.team?.name}
                noBorder
              />
            </div>
          </div>
        </div>
        <div className={styles.center}>
          <span className={styles.name}>
            {chat.team.id === user.organization.teamId ? chat.created_by?.name : chat.team?.name}
          </span>
          {last_message?.message != null && <span>{`${last_message.sender_name}: ${last_message.message}`}</span>}
        </div>
        <div className={styles.right}>
          <span className={styles.date}>{moment(last_message.created_at ?? '2000-01-01T15:05:18.347034Z').format('DD/MM/YYYY')}</span>
          {!!alertCount && isAlertVisible && (
            <div className={styles.alert}>
              <span>{alertCount}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
