import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getAgencyAthletes } from 'store/ducks/agencies';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Spinner, Table, Checkbox, SelectionButton } from 'components/template';
import NationalitiesRow from 'components/NationalitiesRow/NationalitiesRow';
import MonitoringButton from 'components/MonitoringButton/MonitoringButton';
import AthleteRow from 'components/AthleteRow/AthleteRow';
import TeamRow from 'components/TeamRow/TeamRow';
import styles from './MyAthletes.module.scss';
import ActionBar from './ActionBar/ActionBar';
import { getList } from 'store/ducks/monitoringV2';
import moment from 'moment';

function MyAthletes({ agencyId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const agencyAthletes = useSelector(state => state.agencies.athletes);
  const list = useSelector(state => state.monitoringV2);

  const [checkedAthletes, setCheckedAthletes] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [maleCastSelected, setMaleCastSelected] = useState('professional');
  const [femaleCastSelected, setFemaleCastSelected] = useState(null);

  const [selectedFilters, setSelectedFilters] = useState({
    female: false,
    professional: true,
    loaned: false,
    name: '',
    position: '',
    min_birthyear: null,
    max_birthyear: null,
    o: 'position_order',
  });

  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    dispatch(getList());
  }, [dispatch]);

  useEffect(() => {
    setFilteredList(
      list.data.filter(item => item.name !== '_DEFAULT_').map(item => ({ label: item.name, value: String(item.id) }))
    );
  }, [list.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getAgencyAthletes(agencyId, selectedFilters));
  }, [dispatch, agencyId, selectedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const finalAthleteData =
    agencyAthletes.data &&
    agencyAthletes.data.map(row => {
      const checkbox = (
        <Checkbox
          dark
          checked={checkedAthletes.some(athlete => athlete.id === row.id)}
          onChange={() => {
            handleCheckAthlete({ name: row.fullname, id: row.id });
          }}
        />
      );
      // const athleteImg = <Avatar noBorder className={styles.athleteImg}  name={row.fullname} />
      const athlete = <AthleteRow img={row.img ?? row.photo} name={row.fullname} id={row.id} nickname={row.nickname} />;
      const nationalities = row?.nationalities ? (
        <NationalitiesRow className={styles.nationalityFlag} nationalities={row?.nationalities} athleteId={row.id} />
      ) : (
        '-'
      );
      const age = <span style={{ fontWeight: '500' }}>{row.age}</span>;
      const position = t(`positionOptionsRaw.${row.position}`) ?? '-';
      const monitoringButton = (
        <div className={styles.buttonContainer}>
          <MonitoringButton athlete={row} />
        </div>
      );

      let currentTeam = null;
      currentTeam = (
        <div className={styles.teamContainer}>
          <span>{row.current_team?.nickname ?? row.current_team?.longname ?? '-'}</span>
          <br />
          <span className={styles.contractDate}>
            {row.contract_info?.current_contract_end
              ? moment(row.contract_info.current_contract_end).format('DD/MM/YYYY')
              : '-'}
          </span>
        </div>
      );

      const currentTeamComponent = row?.current_team ? (
        <TeamRow name={currentTeam} id={row.current_team?.id} img={row.current_team?.emblem} />
      ) : (
        '-'
      );

      let srcTeam = null;
      srcTeam = (
        <div className={styles.teamContainer}>
          <span>{row.src_team?.nickname ?? row.src_team?.longname ?? '-'}</span>
          <br />
          <span className={styles.contractDate}>
            {row.contract_info?.src_contract_end
              ? moment(row.contract_info.src_contract_end).format('DD/MM/YYYY')
              : '-'}
          </span>
        </div>
      );

      const srcTeamComponent = row?.src_team ? (
        <TeamRow name={srcTeam} id={row.src_team?.id} img={row.src_team?.emblem} />
      ) : (
        '-'
      );

      return [
        checkbox,
        // athleteImg,
        athlete,
        age,
        position,
        nationalities,
        currentTeamComponent,
        srcTeamComponent,
        monitoringButton,
      ];
    });

  const handleCheckAthlete = athlete => {
    if (checkedAthletes.some(item => item.id === athlete.id)) {
      setCheckedAthletes(checkedAthletes.filter(item => item.id !== athlete.id));
    } else {
      setCheckedAthletes([...checkedAthletes, athlete]);
    }
  };

  let content = '';
  if (agencyAthletes.loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (agencyAthletes.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(agencyAthletes.error)}</p>
      </Card>
    );
  } else if (agencyAthletes.data?.length) {
    content = (
      <Card softShadow className={styles.professionalCast}>
        <div className={styles.tableContainer}>
          <Table
            flat
            theads={[
              <Checkbox
                dark
                checked={allChecked}
                onChange={() => {
                  const newAllChecked = !allChecked;
                  setAllChecked(newAllChecked);
                  if (newAllChecked) {
                    setCheckedAthletes(
                      agencyAthletes.data.map(athlete => ({ id: athlete.id, name: athlete.fullname }))
                    );
                  } else {
                    setCheckedAthletes([]);
                  }
                }}
              />,
              t('table.atleta'),
              t('athlete_list.box3'),
              t('athlete_list.box4'),
              'Nac.',
              <div className={styles.clubColumn}>
                Contrato atual /<br /> Contrato
              </div>,
              <div className={styles.loanedContract}>
                {t(`myClub.professionalCast.${selectedFilters.loaned ? 'loanedClub' : 'cedingClub'}`)} /<br />
                {t(`myClub.professionalCast.${selectedFilters.loaned ? 'loanedContract' : 'cedingContract'}`)}
              </div>,
              '',
            ]}
            data={finalAthleteData}
          />
        </div>
      </Card>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.panelTitle}>
        <h2 className={styles.title}>{t('myAgency.my_athletes')}</h2>
        <p className={styles.subTitle}>{t('myAgency.my_athletes_desc')}</p>
      </div>
      <div className={styles.buttonsTop}>
        <span className={styles.spanMale}>{t('listTranslation.Male')}: </span>
        <SelectionButton
          items={[
            { label: t('compe.text26'), value: 'professional' },
            { label: t('text.box32'), value: 'amateur' },
          ]}
          selected={maleCastSelected}
          onClick={val => {
            const loaned = val === 'loaned';
            setSelectedFilters({
              ...selectedFilters,
              female: false,
              professional: loaned ? null : val === 'professional',
              loaned,
            });
            setMaleCastSelected(val);
            setFemaleCastSelected(null);
          }}
        />
        <span className={styles.spanFemale}>{t('listTranslation.Female')}: </span>
        <SelectionButton
          items={[
            { label: t('compe.text26'), value: 'professional' },
            { label: t('text.box37'), value: 'amateur' },
          ]}
          selected={femaleCastSelected}
          onClick={val => {
            const loaned = val === 'loaned';
            setSelectedFilters({
              ...selectedFilters,
              female: true,
              professional: loaned ? null : val === 'professional',
              loaned,
            });
            setMaleCastSelected(null);
            setFemaleCastSelected(val);
          }}
        />
      </div>
      <ActionBar
        checkedAthletes={checkedAthletes}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        list={filteredList}
      />
      {content}
    </div>
  );
}

MyAthletes.propTypes = {
  agencyId: PropTypes.number.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(MyAthletes);
