import React, { useState } from 'react';
import { FaYoutube, FaDownload, FaPlay } from 'react-icons/fa';
import moment from 'moment';
import styles from './Reports.module.scss';
import { Creators } from 'store/ducks/competition';
import { useTranslation } from "react-i18next";

export default function ReportItem({ reportData }) {
  const { t } = useTranslation();
  const [openingFile, setOpeningFile] = useState(false);
  const [selected, setSelected] = useState(false);

  let type = '';
  const buttonStyle = {};
  let buttonIcon = '';
  let buttonLink = '';
  if (reportData.pdf) {
    buttonLink = reportData.pdf;
    type = 'PDF';
    buttonStyle['backgroundColor'] = '#6FA537';
    buttonIcon = <FaDownload />;
  } else if (reportData.youtube) {
    buttonLink = reportData.youtube;
    type = <>
      <FaYoutube style={{ verticalAlign: 'middle' }} />
      {' '}
      YouTube
    </>;
    buttonStyle['backgroundColor'] = '#EB5757';
    buttonStyle['paddingTop'] = '4px';
    buttonStyle['paddingLeft'] = '7px';
    buttonIcon = <FaPlay />;
  }

  const itemLinkClick = async () => {
    if (!openingFile) {
      setOpeningFile(true);
      try {
        const data = await Creators.getReport(reportData.competition, reportData.id);
        window.open((data.pdf ?? (data.youtube ?? buttonLink)), "_blank");
      } finally {
        setOpeningFile(false);
      }
    }
  };

  return <div
    onClick={itemLinkClick}
    style={openingFile ? {
      cursor: 'default',
      opacity: '0.5',
    } : {
      cursor: 'pointer',
    }}
  >
    <div
      className={styles.itemContainer}
      onMouseOver={() => {
        setSelected(true);
      }}
      onMouseOut={() => {
        setSelected(false);
      }}
    >
      <div style={{ overflow: 'hidden' }}>
        <img src={reportData.thumbnail} alt={reportData.title} style={(selected || openingFile) ? { transform: 'scale(1.1)' } : {}} />
      </div>
      <button style={buttonStyle}>
        <span>{buttonIcon}</span>
      </button>
      <h3 style={(selected || openingFile) ? { color: buttonStyle['backgroundColor'] } : {}}>{reportData.title}</h3>
      <div>{t("compe.text7")}{moment(reportData.created_at).format('DD/MM/YYYY')} - {type}</div>
    </div>
  </div>
}