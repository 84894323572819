import Endpoints from 'endpoints';
import axios from 'api';

export const actionTypes = {
  GET: 'dashboardTeams/GET',
  GET_SUCCESS: 'dashboardTeams/GET_SUCCESS',
  GET_ERROR: 'dashboardTeams/GET_ERROR',
};

const initialState = {
  loading: false,
  error: null,
  data: [],
};

export default function reducer(state = initialState, action) {    
  switch (action.type) {
    case actionTypes.GET:
      return {
        ...initialState,
        loading: true,
      };
    case actionTypes.GET_SUCCESS:
      return {
        ...initialState,
        loading: false,
        data: action.payload.data,
      };
    case actionTypes.GET_ERROR:
      return {
        ...initialState,
        error: action.payload.error,
        loading: false,
      };
    default:
      return state;
  }
}

function mapPayloadToTeamsCount(payload) {
    const teamsCount = {
        total: payload.teams_count.total_count_teams,
        tabs: [],
        bars: []
    };

    const divisionKeys = Object.keys(payload.teams_count).filter(key => key.startsWith('total_count_division_'));
    if (divisionKeys.length > 0) {
        teamsCount.tabs.push('total_count_division');
        divisionKeys.forEach((key) => {
            const [currentValue, max] = payload.teams_count[key].split(' / ').map(Number);        
            teamsCount.bars.push({ tab: teamsCount.tabs.length - 1, label: key, value: currentValue, max });
        });
        if(payload?.teams_count?.total_count_foreign) {
            const [currentValue, max] = payload?.teams_count?.total_count_foreign.split(' / ').map(Number);
            teamsCount.bars.push({ tab: teamsCount.tabs.length -1, label: 'total_count_foreign', value: currentValue, max });
        }
    }    

    if (Object.keys(payload.teams_count.total_count_per_license).length > 0) {
        teamsCount.tabs.push('total_count_per_license');
        Object.entries(payload.teams_count.total_count_per_license).forEach(([label, value]) => {
        teamsCount.bars.push({ tab: teamsCount.tabs.length - 1, label, value });
        });
    }

    if (Object.keys(payload.teams_count.total_count_per_project).length > 0) {
        teamsCount.tabs.push('total_count_per_project');
        Object.entries(payload.teams_count.total_count_per_project).forEach(([label, value]) => {
        teamsCount.bars.push({ tab: teamsCount.tabs.length - 1, label, value });
        });
    }    

    return teamsCount;
}

function mapPayloadToAgencyCount(payload) {
    const agencyCount = {
        total: payload.agency_count.total_count_agency,
        tabs: [],
        bars: []
    };
    
    if (
        payload.agency_count.total_count_agency_global > 0 ||
        payload.agency_count.total_count_agency_latam > 0 ||
        payload.agency_count.total_count_agency_brazil > 0
    ) {
        agencyCount.tabs.push('total_count_agency');
        if (payload.agency_count.total_count_agency_global > 0) {
        agencyCount.bars.push({ tab: agencyCount.tabs.length - 1, label: 'total_count_agency_global', value: payload.agency_count.total_count_agency_global });
        }
        if (payload.agency_count.total_count_agency_latam > 0) {
        agencyCount.bars.push({ tab: agencyCount.tabs.length - 1, label: 'total_count_agency_latam', value: payload.agency_count.total_count_agency_latam });
        }
        if (payload.agency_count.total_count_agency_brazil > 0) {
        agencyCount.bars.push({ tab: agencyCount.tabs.length - 1, label: 'total_count_agency_brazil', value: payload.agency_count.total_count_agency_brazil });
        }
    }
    
    if (Object.keys(payload.agency_count.total_count_per_license).length > 0) {
        agencyCount.tabs.push('total_count_per_license');
        Object.entries(payload.agency_count.total_count_per_license).forEach(([label, value]) => {
        agencyCount.bars.push({ tab: agencyCount.tabs.length - 1, label, value });
        });
    }
    
    if (Object.keys(payload.agency_count.total_count_per_athlete).length > 0) {
        agencyCount.tabs.push('total_count_per_athlete');
        Object.entries(payload.agency_count.total_count_per_athlete).forEach(([label, value]) => {
        agencyCount.bars.push({ tab: agencyCount.tabs.length - 1, label, value });
        });
    }

    return agencyCount;
}

let axisosGetDashboardTeamsController;
export const getDashboardTeams = filters => async dispatch => {
  dispatch({ type: actionTypes.GET });
  const url = Endpoints.dashboardTeams;

  try { 
    if (axisosGetDashboardTeamsController) {
        axisosGetDashboardTeamsController.abort();
    }
    axisosGetDashboardTeamsController = new AbortController();
    const { data } = await axios.get(`${url}`, { signal: axisosGetDashboardTeamsController.signal });
    axisosGetDashboardTeamsController = null;

    const teamsCount = mapPayloadToTeamsCount(data);
    const agencyCount = mapPayloadToAgencyCount(data);        

    dispatch({
      type: actionTypes.GET_SUCCESS,
      payload: {
        data: {teamsCount, agencyCount},
      },
    });
  } catch (err) {
    if (err?.name === 'CanceledError') {
      //requisição cancelada
    } else {
      dispatch({
        type: actionTypes.GET_ERROR,
        payload: { error: 'erros.text20' },
      });
    }
  }
};
