// core
import React from 'react';

// hooks
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// scss
import styles from './styles.module.scss';

// components
import { Button, IconButton, Avatar } from 'components/template';

// icons
import { BsChatLeftTextFill } from 'react-icons/bs';
import { FiArrowLeft } from 'react-icons/fi';
import { MdVerified } from 'react-icons/md';

// other
import { AgencyChatRepository } from 'data/agencyChat';
import { Creators as layoutCreators } from 'store/ducks/layout';
import CategoryGlobal from 'assets/category-global.svg';
import CategoryBrasil from 'assets/category-brasil-default.svg';
import CategoryLatam from 'assets/category-latam.svg';
import AgencyFifa from 'assets/agency-FIFA.svg';

export default function CustomTopBar({ agency }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.user.data);

  return (
    <div className={styles.topBar}>
      <div className={styles.left}>
        <div className={styles.pageBack}>
          <Button onClick={() => history.push('/agencias')} noShadow>
            <FiArrowLeft />
          </Button>
        </div>
        <Avatar
          className={styles.agency_athletes_topbar_avatar}
          noBorder
          noRadius
          img={agency.emblem}
          name={agency.name ? agency.name : agency.fullname}
        />
        <div className={styles.clubInfo}>
          <div>
            <h1>{agency.name ? agency.name : agency.fullname}</h1>
            {agency.is_verified_agency && (
              <div className={styles.agency_verified}>
                <MdVerified />
              </div>
            )}
          </div>
          <div className={styles.categorias}>
            {(() => {
              switch (agency.agency_subtype) {
                case 'brazil':
                  return <img src={CategoryBrasil} className={styles.category_types_img} alt="" />;
                case 'latam':
                  return <img src={CategoryLatam} className={styles.category_types_img} alt="" />;
                case 'global':
                  return <img src={CategoryGlobal} className={styles.category_types_img} alt="" />;
                default:
                  return <img src={CategoryBrasil} className={styles.category_types_img} alt="" />;
              }
            })()}
            {agency.is_fifa_agency ? <img src={AgencyFifa} alt="" /> : null}
          </div>
        </div>
      </div>
      {/* club.marketValue && () */}
      <div className={styles.right}>
        {/* <div className={styles.addressContainer}>
          <FaMapMarkerAlt className={styles.markerIcon} />
          <div className={styles.addressSection}>
            Alameda Santos 787, cnj 42, Cerqueira César, São Paulo, SP, Brasil - CEP 01419-001
          </div>
        </div> */}
        {/* 
        {agency.phones.phone1.number || agency.phones.phone2.number ? (
          <div className={styles.phonesContainer}>
            <FaPhoneAlt className={styles.phoneIcon} />
            <div className={styles.phonesSection}>
              <span>{`${agency.phones.phone1.ddi} ${agency.phones.phone1.ddd} ${agency.phones.phone1.number}`}</span>
              <br />
              <span>{`${agency.phones.phone2.ddi} ${agency.phones.phone2.ddd} ${agency.phones.phone2.number}`}</span>
            </div>
          </div>
        ) : null} */}
        {/* {agency.address.city || agency.address.full_address || agency.address.state || agency.address.zip_code ? (
          <div className={styles.addressContainer}>
            <FaMapMarkerAlt className={styles.markerIcon} />
            <div className={styles.addressSection}>
              {agency.address.full_address}, {agency.address.city}, {agency.address.state}{' '}
              {agency.address.zip_code ? `- CEP ${agency.address.zip_code}` : ''}
            </div>
          </div>
        ) : null}
        <div className={styles.socialSection}>
          {agency.social.website && <SocialButton type="website" href={agency.social.website} />}
          {agency.social.instagram && <SocialButton type="instagram" href={agency.social.instagram} />}
          {agency.social.twitter && <SocialButton type="twitter" href={agency.social.twitter} />}
          {agency.social.youtube && <SocialButton type="youtube" href={agency.social.youtube} />}
          {agency.social.linkedin && <SocialButton type="linkedin" href={agency.social.linkedin} />}
          {agency.social.facebook && <SocialButton type="facebook" href={agency.social.facebook} />}
        </div>
        <div className={styles.marketValue}>
          <h5>VALOR DE MERCADO</h5>
          <span>{club.marketValue}</span>
        </div> */}
        {agency.has_mensageria_access && agency.id !== user.organization.id.id && (
          <IconButton
            className={styles.agent_message}
            text={t('chat.talk_to_agent')}
            icon={<BsChatLeftTextFill />}
            onClick={async () => {
              const response = await AgencyChatRepository.post({
                org: user.organization.id.id,
                agency: agency.id,
              });
              if (response.error && response.status === 402) {
                dispatch(layoutCreators.showExcededPlanLimit());
              } else {
                history.push(`/mensagens/agentes/${response.id}`);
              }
            }}
          />
        )}
      </div>
    </div>
  );
}
