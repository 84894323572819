import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from 'store/ducks/club';
import { withRouter } from 'react-router-dom';
import Helpers from 'shared/helpers';

import { useTranslation } from 'react-i18next';

import { Card, Spinner, Table, Checkbox } from 'components/template';
import styles from './ProfessionalCast.module.scss';

import ActionBar from '../ActionBar/ActionBar';
import { getList } from 'store/ducks/monitoringV2';

function ProfessionalCast({ teamId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const professionalCast = useSelector(state => state.club.professionalCastPage);
  const list = useSelector(state => state.monitoringV2);

  const [checkedAthletes, setCheckedAthletes] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  const today = new Date();

  const [selectedFilters, setSelectedFilters] = useState({
    name: '',
    position: [],
    list: [],
    birthYear: {
      min: 1975,
      max: Number(today.getFullYear()),
    },
  });

  const [athletes, setAthletes] = useState([]);

  const [athletesAsRows, setAthletesAsRows] = useState([]);

  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    setAthletes(applyFilters(professionalCast.data.athletes));
  }, [professionalCast]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAthletesAsRows(
      Helpers.athleteListToTable(athletes, 'elenco', styles, 'monitoring', handleChange, checkedAthletes)
    );
  }, [athletes, checkedAthletes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getList());
  }, [dispatch]);

  useEffect(() => {
    setFilteredList(
      list.data.filter(item => item.name !== '_DEFAULT_').map(item => ({ label: item.name, value: String(item.id) }))
    );
  }, [list.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (allChecked) {
      setCheckedAthletes(athletes.map(athlete => ({ id: athlete.id, name: athlete.fullname })));
    } else {
      setCheckedAthletes([]);
    }
  }, [allChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAthletes(applyFilters(professionalCast.data.athletes));
  }, [selectedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (professionalCast.data.athletes.length === 0) {
      dispatch(Creators.getProfessionalCast(teamId, { female: false, professional: true, o: 'position_order' }));
    } else {
      setAthletes(professionalCast.data.athletes);
    }
  }, [dispatch, teamId, professionalCast.data.athletes.length]); // eslint-disable-line react-hooks/exhaustive-deps

  function applyFilters(athletes) {
    let filteredAthletes = athletes.filter(athlete => {
      if (
        selectedFilters.name === '' ||
        athlete.name?.toLowerCase().includes(selectedFilters.name) ||
        athlete.nickname?.toLowerCase().includes(selectedFilters.name)
      ) {
        if (selectedFilters.position.length === 0 || selectedFilters.position.some(pos => athlete.position === pos)) {
          if (
            athlete.birthYear >= selectedFilters.birthYear.min &&
            athlete.birthYear <= selectedFilters.birthYear.max
          ) {
            if (
              selectedFilters.list.length === 0 ||
              athlete.lists.some(list => selectedFilters.list.some(item => Number(item) === Number(list)))
            ) {
              return true;
            }
          }
        }
      }

      return false;
    });

    return filteredAthletes;
  }

  const handleChange = athlete => {
    if (checkedAthletes.some(item => item.id === athlete.id)) {
      setCheckedAthletes(checkedAthletes.filter(item => item.id !== athlete.id));
    } else {
      setCheckedAthletes([...checkedAthletes, athlete]);
    }
  };

  let content = '';
  if (professionalCast.loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (professionalCast.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(professionalCast.error)}</p>
      </Card>
    );
  } else if (professionalCast.data.athletes.length > 0) {
    content = (
      <Card softShadow className={styles.professionalCast}>
        <ActionBar
          checkedAthletes={checkedAthletes}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          list={filteredList}
        />
        <div className={styles.tableContainer}>
          <Table
            flat
            className={styles.clubsTable}
            theads={[
              <Checkbox
                dark
                checked={allChecked}
                onChange={() => setAllChecked(!allChecked)}
                className={styles.tableCheckbox}
              />,
              '',
              t('athlete_list.box1'),
              t('athlete_list.box3'),
              t('athlete_list.box4'),
              t('filters.nation'),
              t('athlete_list.box6'),
              <div className={styles.loanedContract}>
                {t('agencies.text4')} /<br /> {t('text.box22')}
              </div>,
              '',
            ]}
            data={athletesAsRows}
          />
        </div>
      </Card>
    );
  } else {
    content = (
      <div className={styles.nocontent}>
        <span>{t('club.noData')}</span>
      </div>
    );
  }

  return <>{content}</>;
}

ProfessionalCast.propTypes = {
  teamId: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(ProfessionalCast);
