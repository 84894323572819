import React from 'react';
import PropTypes from 'prop-types';
import Topbar from '../TopBar/TopBar';
import Pagination from '../Pagination/Pagination';
import * as S from './styles';
import CenteredLoader from '../CenteredLoader';
import { useTranslation } from 'react-i18next';

const Page = ({ children, loading, error, title, subtitle, icon, iconBgColor, paginationObj, customTopBar }) => {
    const { t } = useTranslation();
    let content = null;

    if (loading) {
        content = <CenteredLoader />;
    } else if (error) {
        content = <div>{t(error)}</div>;
    } else {
        content = (
            <>
                <S.Container>
                    <S.Content>{children}</S.Content>
                </S.Container>
                {paginationObj && (
                    <Pagination
                        length={paginationObj.length}
                        count={paginationObj.count}
                        pagination={paginationObj.pagination}
                        setPagination={paginationObj.setPagination}
                        hasNext={paginationObj.hasNext}
                        hasPrevious={paginationObj.hasPrevious}
                        resourceDisplayName={paginationObj.resourceDisplayName}
                    />
                )}
            </>
        );
    }

    return (
        <>
            {customTopBar ? (
                customTopBar
            ) : (
                <Topbar title={title} subtitle={subtitle} icon={icon} iconBgColor={iconBgColor} />
            )}
            {content}
        </>
    );
};

Page.protoTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    icon: PropTypes.instanceOf(React.Component).isRequired,
    iconBgColor: PropTypes.string,
    paginationObj: {
        pagination: PropTypes.instanceOf(Object).isRequired, // The usePagination hook returned object
        setPagination: PropTypes.instanceOf(Function).isRequired, // The usePagination hook returned function
        length: PropTypes.number.isRequired, // Number of items in screen
        count: PropTypes.number.isRequired, // Total number of items
        resourceDisplayName: PropTypes.string, // Name of the displayed resource, e.g.: "Athleta"
        hasNext: PropTypes.bool,
        hasPrevious: PropTypes.bool,
    },
};

export default Page;
