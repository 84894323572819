/*eslint-disable eqeqeq*/
import React, { useState } from 'react';
import CenteredLoader from 'components/template/CenteredLoader';
import { Checkbox } from 'components/template';
import * as S from './styles';
import Variables from 'variables';
import { useSelector } from 'react-redux';
import { FiDownload } from 'react-icons/fi';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { MdBlock } from 'react-icons/md';

const Note = ({ note, provisory, checkAllNotes, onCheckClick }) => {
  const { t } = useTranslation();
  const [showVideo, setShowVideo] = useState(false);
  const [checkedState, setChecked] = useState(false);
  const checked = checkAllNotes || checkedState;
  const loggedUser = useSelector(state => state.user.data);
  const isMyNote = loggedUser?.id != null && note?.author?.id != null && note.author.id === loggedUser.id;  

  let fileContent = null;
  if (note.file_type == 'image') {
    if (provisory) {
      fileContent = <CenteredLoader size="50px" />;
    } else {
      fileContent = <S.Image src={note.photo ? note.photo : note.file} alt={note.file_type} />;
    }
  } else if (note.file_type == 'video') {
    if (provisory) {
      fileContent = <CenteredLoader size="50px" />;
    } else {
      fileContent = fileContent = !showVideo ? (
        <S.Thumbnail onClick={() => setShowVideo(true)} link={note.thumb}>
          <S.ThumbnailIcon />
        </S.Thumbnail>
      ) : (
        // <S.Image onClick={() => setShowVideo(true)} src={note.thumb} alt={note.file_type} />) :
        <S.Video width="320" height="240" controls>
          <source src={note.file}/>
        </S.Video>
      );
    }
  } else if (note.file_type == 'audio') {
    if (provisory) {
      fileContent = <CenteredLoader size="50px" />;
    } else {
      fileContent = (
        <S.Audio controls>
          <source src={note.file} />
        </S.Audio>
      );
    }
  } else if (note.file_type == 'other') {
    if (provisory) {
      fileContent = <CenteredLoader size="50px" />;
    } else {
      fileContent = (
        <div className={styles.downloadlink}>
          <a href={note.file} target="_blank" rel="noopener noreferrer">
            <div className={styles.download}>
              <FiDownload size="14" />
              {note.original_file_name}
            </div>
          </a>
        </div>
      );
    }
  }

  return (
    <>
      <S.MessageContainer backgroundColor={checked ? `${Variables.purple9}90` : null}>
        <S.Message loading={provisory} backgroundColor={loggedUser.id === note.author.id ? '#EAF4CD' : Variables.light}>
          <S.UserName>{note.author.name}</S.UserName>
          { !note.deleted_at ? <>
            {fileContent}
            <S.Text>{note.text}</S.Text>
          </> 
          : <div className={styles.message}><i><MdBlock />{t('chat.deletedNote')}</i></div>}
        </S.Message>
        {onCheckClick !== undefined &&
          (isMyNote ? (
            <Checkbox
              checked={checked}
              onChange={() => {
                if (!checkAllNotes) {
                  onCheckClick(note, !checked);
                  setChecked(!checked);
                }
              }}
            />
          ) : (
            <div style={{ width: '25px' }}></div>
          ))}
      </S.MessageContainer>
    </>
  );
};

export default Note;
