import React from 'react';
import PropTypes from 'prop-types';

import styles from './CompetitionInfo.module.scss';
import moment from 'moment';
import { FaCalendarAlt, FaMapMarkerAlt } from 'react-icons/fa';
import { Avatar } from 'components/template';

export default function CompetitionInfo({ competition }) {
  let organizerContent = '';
  if (competition.organizer) {
    organizerContent = (
      <div className={styles.competitionName}>
        <Avatar
          className={styles.avatar}
          noBorder
          noRadius
          img={competition.organizer.emblem ?? ''}
          name={competition.organizer.name}
        />
        <span className={styles.title}>{competition.organizer.name}</span>
      </div>
    );
  }
  return (
    <div className={styles.competitionInfo}>
      {organizerContent}
      <div className={styles.period}>
        <FaCalendarAlt size={18} />
        <span className={styles.title}>
          {moment(competition.start_date).format('DD/MM/YYYY')} - {moment(competition.end_date).format('DD/MM/YYYY')}
        </span>
      </div>
      <div className={styles.location}>
        <FaMapMarkerAlt size={18} />
        <span className={styles.title}>{competition.country?.name}</span>
        <img src={competition.country?.flag} alt={competition.country?.name ?? ''} />
      </div>
    </div>
  );
}

CompetitionInfo.propTypes = {
  competition: PropTypes.instanceOf(Object).isRequired,
};
