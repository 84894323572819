import React, { useState, useEffect } from 'react';
import { useQuery } from 'shared/hooks';
import { useSelector, useDispatch } from 'react-redux';
import { isEmail } from 'validator';
import { Creators } from 'store/ducks/user';
import { getI18n, useTranslation } from 'react-i18next';
import WarningIcon from 'assets/ico/ico-warning.svg';
import { Card, Button, Input, Toast, Checkbox, Spinner } from 'components/template';
import { FaEye, FaEyeSlash, FaCheck, FaCheckCircle } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { AiFillWarning } from 'react-icons/ai';
import styles from './Login.module.scss';
import LanguageButton from 'App/Layout/AppBar/LanguageButton/LanguageButton';
import Logo from 'assets/logo.svg';
import FlashState from 'shared/FlashState';

const UI = {
  LOGIN: 'LOGIN',
  RECOVERY: 'RECOVERY',
  RECOVERY_SEND: 'RECOVERY_SEND',
};

export default function Login() {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguage = getI18n().language;
  const queryLanguage = useQuery().get('language');
  if (queryLanguage === 'pt-br' && currentLanguage !== 'br') {
    i18n.changeLanguage('br');
    localStorage.setItem('language', 'br');
    window.location.reload();
  } else if (queryLanguage === 'en-us' && currentLanguage !== 'en') {
    i18n.changeLanguage('en');
    localStorage.setItem('language', 'en');
    window.location.reload();
  } else if (queryLanguage === 'es-es' && currentLanguage !== 'esp') {
    i18n.changeLanguage('esp');
    localStorage.setItem('language', 'esp');
    window.location.reload();
  }
  const user = useSelector(state => state.user);
  const [form, setForm] = useState({
    email: '',
    password: '',
    persistSession: true,
    recoveryEmail: '',
  });
  const [isPwdVisible, setPwdVisibility] = useState(false);
  const [isRecovery, setIsRecovery] = useState(false);
  const [toast, setToast] = useState({ isVisible: false, content: null, duration: 1500 });
  const [ui, setUi] = useState(UI.LOGIN);
  const alreadyLoggedAnotherDevice = user.login?.alreadyLoggedAnotherDevice ?? false;

  // toast erro no login
  useEffect(() => {
    if (user.login.error) {
      setToast({
        isVisible: true,
        content: (
          <p className={styles.toastContent}>
            <AiFillWarning />
            {t(user.login.error)}
          </p>
        ),
        type: 'danger',
      });
    }
  }, [t, user.login.error]);

  // toast sucesso no envio do código do e-mail
  useEffect(() => {
    if (user.recovery.codeSent) {
      setUi(UI.RECOVERY_SENDED);
    }
  }, [user.recovery.codeSent]);

  // toast sucesso no envio do código do e-mail
  useEffect(() => {
    if (user.reset.redirect) {
      setToast({
        isVisible: true,
        content: (
          <p className={styles.toastContent}>
            <FaCheck />
            {FlashState.get('success')}
          </p>
        ),
        duration: 10000,
        type: 'success',
      });
    }
  }, [user.reset.redirect]);

  function resetToast() {
    setToast({ ...toast, isVisible: false });
    if (user.login.error) dispatch(Creators.handleLoginError());
    if (user.recovery.error) dispatch(Creators.handleRecoveryError());
    if (user.recovery.codeSent) dispatch(Creators.resetRecovery());
  }

  function loginClickHandler(failIfAlreadyLogged) {
    if (user.login.error || alreadyLoggedAnotherDevice) dispatch(Creators.handleLoginError());
    dispatch(Creators.login(form.email, form.password, form.persistSession, failIfAlreadyLogged));
  }

  function recoveryClickHandler() {
    dispatch(Creators.recovery(form.recoveryEmail));
  }

  function onKeyUp(e) {
    if (e.keyCode === 13 && isEmail(form.email) && form.password) {
      loginClickHandler(true);
    }
  }

  function setPersist(e) {
    e.persist();
    setForm(f => ({ ...f, persistSession: e.target.checked }));
  }

  function inputChangeHandler(e, field) {
    e.persist();
    setForm(f => ({ ...f, [field]: e.target.value }));
  }

  const passwordIcon = isPwdVisible ? (
    <FaEye className={styles.icon} onClick={() => setPwdVisibility(false)} />
  ) : (
    <FaEyeSlash className={styles.icon} onClick={() => setPwdVisibility(true)} />
  );

  let loginContent = null;
  if (user.login.loading) {
    loginContent = <Spinner className={styles.loading} />;
  } else {
    loginContent = (
      <>
        <Input
          onChange={e => inputChangeHandler(e, 'email')}
          containerClassName={styles.inputContainer}
          value={form.email}
          label="E-mail"
          onKeyUp={onKeyUp}
          hasError={Boolean(user.login.error)}
          inputClassName={styles.emailInput}
        />
        <div className={styles.passwordContainer}>
          <Input
            label={
              <div className={styles.passwordLabel}>
                {t('home.box10')}
                <span
                  className={styles.link}
                  tabIndex="0"
                  role="button"
                  onClick={() => {
                    setIsRecovery(true);
                    setUi(UI.RECOVERY);
                  }}
                >
                  {t('home.box11')}
                </span>
              </div>
            }
            type={isPwdVisible ? 'text' : 'password'}
            containerClassName={styles.inputContainer}
            value={form.password}
            onKeyUp={onKeyUp}
            icon={passwordIcon}
            iconPosition="right"
            onChange={e => inputChangeHandler(e, 'password')}
            hasError={Boolean(user.login.error)}
            inputClassName={styles.passwordInput}
          />
        </div>
        <Checkbox
          className={styles.checkbox}
          checked={form.persistSession}
          onChange={setPersist}
          label={t('home.box12')}
        />
        <Button disabled={user.login.loading} onClick={() => loginClickHandler(true)} color="purple" fluid rounded>
          {user.login.loading ? t('home.box13') : t('home.box14')}
        </Button>
      </>
    );
  }

  let recoveryContent = null;
  if (user.recovery.loading) {
    recoveryContent = <Spinner className={styles.loading} />;
  } else if (isRecovery) {
    recoveryContent = (
      <>
        <Input
          label={t('home.box15')}
          containerClassName={styles.inputContainer}
          value={form.recoveryEmail}
          onChange={e => inputChangeHandler(e, 'recoveryEmail')}
        />
        <Button
          disabled={!isEmail(form.recoveryEmail)}
          className="mb-2"
          color="purple"
          fluid
          rounded
          onClick={recoveryClickHandler}
        >
          {user.recovery.loading ? t('home.box13') : t('home.box16')}
        </Button>
        <span role="button" tabIndex="0" onClick={() => setUi(UI.LOGIN)} className={styles.link}>
          {t('home.box17')}
        </span>
      </>
    );
  }

  let recoverySendedContent = null;
  if (user.recovery.codeSent) {
    recoverySendedContent = (
      <>
        <span className={styles.recoverySendedContent}>
          <FaCheckCircle className={styles.recoverySendedIcon} />
          {t('home.box18')}
        </span>
        <span role="button" tabIndex="0" onClick={() => setUi(UI.LOGIN)} className={styles.link}>
          {t('home.box17')}
        </span>
      </>
    );
  }

  const loginContainerClasses = [styles.loginContainer];
  if (ui === UI.LOGIN) loginContainerClasses.push(styles.active);

  const recoveryContainerClasses = [styles.recoveryContainer];
  if (ui === UI.RECOVERY) recoveryContainerClasses.push(styles.active);

  const recoverySendedContainerClasses = [styles.recoverySendedContainer];
  if (ui === UI.RECOVERY_SENDED) recoverySendedContainerClasses.push(styles.active);

  const closeContainerAlreadyLogged = () => {
    dispatch(Creators.handleLoginError());
  };

  let findOutMoreUrl = 'https://footlink.app/';
  if (currentLanguage === 'en') {
    findOutMoreUrl += 'us/';
  } else if (currentLanguage === 'esp') {
    findOutMoreUrl += 'es/';
  }

  return (
    <>
      <div className={styles.container}>
        <Toast type={toast.type} isVisible={toast.isVisible} onClose={resetToast} content={toast.content} />
        <div className={styles.languageButton}>
          <LanguageButton />
        </div>
        <div className={styles.innerContainer}>
          <img src={Logo} alt="Logotipo Footlink" width="199" />
          <div className={styles.textl}>
            <p className={styles.text}>{t('trello.box4')}</p>
          </div>
          <Card className={styles.cardContainer}>
            <div className={loginContainerClasses.join(' ')}>{loginContent}</div>
            <div className={recoveryContainerClasses.join(' ')}>{recoveryContent}</div>
            <div className={recoverySendedContainerClasses.join(' ')}>{recoverySendedContent}</div>
          </Card>
          <p className={styles.purshaseText}>{t('trello.box2')}</p>
          <a className={styles.purshaseLink} href={findOutMoreUrl} rel="noopener noreferrer">
            <Button outline color="purple" fluid rounded>
              {t('trello.box3')}
            </Button>
          </a>
        </div>
      </div>
      {alreadyLoggedAnotherDevice && (
        <div className={styles.containerAlreadyLogged}>
          <div className={styles.innerContainerAlreadyLogged}>
            <MdClose className={styles.closeButton} onClick={closeContainerAlreadyLogged} />
            <img draggable="false" src={WarningIcon} alt="A" />
            <div className={styles.textAlreadyLogged}>
              <h5>{t('login.alreadyLoggedText1')}</h5>
              {t('login.alreadyLoggedText2')}
              <br />
              {t('login.alreadyLoggedText3')}
            </div>
            <div className={styles.buttonsAlreadyLogged}>
              <Button outline color="white" fluid rounded onClick={closeContainerAlreadyLogged}>
                {t('yesNo.nao')}
              </Button>
              <Button onClick={() => loginClickHandler(false)} color="purple" fluid rounded>
                {t('login.forceLogin')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
