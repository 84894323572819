import moment from 'moment';

let timerId;
export const debounce = (fn, delay = 700) => {
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export const moneyStringToNumber = value => {
  if (value?.length > 0) {
    const valuesSplit = value.replace(',', '.').split('.');
    let cents = '00';
    if (valuesSplit[valuesSplit.length - 1].length === 2) {
      cents = valuesSplit[valuesSplit.length - 1];
      valuesSplit.splice(-1);
    }
    return Number(`${valuesSplit.join('')}.${cents}`);
  }
  return 0;
};

export const formatMoneyString = value => {
  let numberValue;
  if (typeof value === 'string' || value instanceof String) {
    numberValue = moneyStringToNumber(value);
  } else {
    numberValue = value;
  }
  return Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(numberValue);
};

export const formatDate = date => {
  if (date == null || date === '') {
    return '';
  }
  return moment(date).format('DD/MM/YYYY');
};

export const getCurrentDate = () => {
  const d = new Date();
  return `${d.getDate()}/${d.getMonth()}/${d.getFullYear()}`;
};

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== '' && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

export const stringifyFilters = filterObj => {
  let queryString = '';

  if (filterObj) {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in filterObj) {
      if (
        (Array.isArray(filterObj[prop]) && filterObj[prop].length !== 0) ||
        (!Array.isArray(filterObj[prop]) &&
          typeof filterObj[prop] !== 'undefined' &&
          filterObj[prop] !== null &&
          filterObj[prop] !== '')
      ) {
        queryString += queryString ? '&' : '?';
        queryString += `${prop}=${Array.isArray(filterObj[prop]) ? filterObj[prop].join(',') : filterObj[prop]}`;
      }
    }
  }

  return queryString;
};

export const updatedStringifyFilters = filterObj => {
  let queryString = '';

  if (filterObj) {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in filterObj) {
      if (
        (Array.isArray(filterObj[prop]) && filterObj[prop].length !== 0) ||
        (!Array.isArray(filterObj[prop]) &&
          typeof filterObj[prop] !== 'undefined' &&
          filterObj[prop] !== null &&
          filterObj[prop] !== '')
      ) {
        queryString += queryString ? '&' : '&';
        queryString += `${prop}=${Array.isArray(filterObj[prop]) ? filterObj[prop].join(',') : filterObj[prop]}`;
      }
    }
  }

  return queryString;
};

export const stringifyFiltersWithNestedObjects = filterObj => {
  const queryStringParts = [];

  const addQueryParam = (key, value) => {
    queryStringParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
  };

  const processObject = (obj, prefix = '') => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        const propName = prefix ? `${prefix}_${prop}` : prop;
        const propValue = obj[prop];

        if (Array.isArray(propValue)) {
          if (propValue.length > 0) {
            addQueryParam(propName, propValue.join(','));
          }
        } else if (typeof propValue === 'object' && propValue !== null) {
          processObject(propValue, propName);
        } else if (propValue !== undefined && propValue !== null && propValue !== '') {
          addQueryParam(propName, propValue);
        }
      }
    }
  };

  if (filterObj) {
    processObject(filterObj);
  }

  return queryStringParts.length > 0 ? `?${queryStringParts.join('&')}` : '';
};

export const getDivision = d => {
  let division = '';
  switch (d) {
    case 1:
      division = 'Série A';
      break;
    case 2:
      division = 'Série B';
      break;
    case 3:
      division = 'Série C';
      break;
    case 4:
      division = 'Série D';
      break;
    default:
      division = '';
  }

  return division;
};

export const createAttribute = (name, obj) => {
  const value = obj;
  // eslint-disable-next-line no-prototype-builtins
  if (!value.hasOwnProperty(name)) {
    value[name] = {};
  }

  return value;
};

export const parseOrganizationUpdate = (flatObj, orgType) => {
  let updateObj = {};

  if (flatObj.phone1ddi !== null || flatObj.phone1ddd !== null || flatObj.phone1number !== null) {
    updateObj = createAttribute('phones', updateObj);
    updateObj.phones = createAttribute('phone1', updateObj.phones);

    if (flatObj.phone1ddi !== null) {
      updateObj.phones.phone1.ddi = flatObj.phone1ddi;
    }

    if (flatObj.phone1ddd !== null) {
      updateObj.phones.phone1.ddd = flatObj.phone1ddd;
    }

    if (flatObj.phone1number !== null) {
      updateObj.phones.phone1.number = flatObj.phone1number;
    }
  }

  if (flatObj.phone2ddi !== null || flatObj.phone2ddd !== null || flatObj.phone2number !== null) {
    updateObj = createAttribute('phones', updateObj);
    updateObj.phones = createAttribute('phone2', updateObj.phones);

    if (flatObj.phone2ddi !== null) {
      updateObj.phones.phone2.ddi = flatObj.phone2ddi;
    }

    if (flatObj.phone2ddd !== null) {
      updateObj.phones.phone2.ddd = flatObj.phone2ddd;
    }

    if (flatObj.phone2number !== null) {
      updateObj.phones.phone2.number = flatObj.phone2number;
    }
  }

  if (flatObj.whatsappddi !== null || flatObj.whatsappddd !== null || flatObj.whatsappnumber !== null) {
    updateObj = createAttribute('phones', updateObj);
    updateObj.phones = createAttribute('whatsapp', updateObj.phones);

    if (flatObj.whatsappddi !== null) {
      updateObj.phones.whatsapp.ddi = flatObj.whatsappddi;
    }

    if (flatObj.whatsappddd !== null) {
      updateObj.phones.whatsapp.ddd = flatObj.whatsappddd;
    }

    if (flatObj.whatsappnumber !== null) {
      updateObj.phones.whatsapp.number = flatObj.whatsappnumber;
    }
  }

  if (
    flatObj.facebook !== null ||
    flatObj.instagram !== null ||
    flatObj.linkedin !== null ||
    flatObj.twitter !== null ||
    flatObj.website !== null ||
    flatObj.youtube !== null
  ) {
    updateObj = createAttribute('social', updateObj);

    if (flatObj.facebook) {
      updateObj.social.facebook = flatObj.facebook;
    }

    if (flatObj.instagram) {
      updateObj.social.instagram = flatObj.instagram;
    }

    if (flatObj.linkedin) {
      updateObj.social.linkedin = flatObj.linkedin;
    }

    if (flatObj.twitter) {
      updateObj.social.twitter = flatObj.twitter;
    }

    if (flatObj.website) {
      updateObj.social.website = flatObj.website;
    }

    if (flatObj.youtube) {
      updateObj.social.youtube = flatObj.youtube;
    }
  }

  if (orgType === 'team') {
    if (flatObj.stadium !== null || flatObj.foundation_date !== null || flatObj.member_number !== null) {
      updateObj = createAttribute('team_info', updateObj);

      if (flatObj.stadium !== null) {
        updateObj.team_info.stadium = flatObj.stadium;
      }

      if (flatObj.foundation_date !== null) {
        updateObj.team_info.foundation_date = flatObj.foundation_date;
      }

      if (flatObj.member_number !== null) {
        updateObj.team_info.member_number = flatObj.member_number;
      }
    }
  }

  if (flatObj.city !== null || flatObj.full_address !== null || flatObj.state !== null || flatObj.zip_code !== null) {
    updateObj = createAttribute('address', updateObj);

    if (flatObj.city !== null) {
      updateObj.address.city = flatObj.city;
    }

    if (flatObj.full_address !== null) {
      updateObj.address.full_address = flatObj.full_address;
    }

    if (flatObj.state !== null) {
      updateObj.address.state = flatObj.state;
    }

    if (flatObj.zip_code !== null) {
      updateObj.address.zip_code = flatObj.zip_code;
    }
  }

  if (flatObj.country !== null) {
    updateObj.country = flatObj.country;
  }

  if (flatObj.fullname !== null) {
    updateObj.fullname = flatObj.fullname;
  }

  if (flatObj.nickname !== null) {
    updateObj.nickname = flatObj.nickname;
  }

  return updateObj;
};

export function getUnique(arr, comp) {
  // store the comparison  values in array
  const unique = arr
    .map(e => e[comp])

    // store the indexes of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the false indexes & return unique objects
    .filter(e => arr[e])
    .map(e => arr[e]);

  return unique;
}

export const getWindowCategory = c => {
  let initial = '';
  switch (c) {
    case 'Amateur':
      initial = 'A';
      break;
    case 'Female':
      initial = 'F';
      break;
    case 'Male':
      initial = 'M';
      break;
    default:
      initial = '';
  }

  return initial;
};

export const getSystemInfoName = n => {
  let name = '';
  switch (n) {
    case 'teams':
      name = 'Ti';
      break;
    case 'athletes':
      name = 'F';
      break;
    case 'market_value':
      name = 'M';
      break;
    case 'competitions':
      name = 'M';
      break;
    case 'transfers':
      name = 'Transferências de atletas fora do Brasil';
      break;
    case 'bid':
      name = 'Contratos - BID da CBF';
      break;
    case 'historical_data_prof':
      name = 'Estatísticas de jogos (Profissional)';
      break;
    case 'historical_data_base':
      name = 'Estatísticas de jogos (Base)';
      break;
    case 'match_data':
      name = 'M';
      break;
    case 'transfer_windows':
      name = 'Janelas de transferências';
      break;

    default:
      name = '';
  }

  return name;
};

export const getCookie = key => {
  const b = document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
};

export const getBase64 = (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
  };
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
