export const TRANSLATIONS_PT = {
  pagina_inicial: {
    homepage_1: 'Últimas movimentações',
    homepage_2: 'Informações do sistema',
    homepage_3: 'Janelas Abertas',
    homepage_4: 'Últimos visualizados',
    homepage_5: 'Ver mais',
    homepage_6: 'Ver todas as janelas',
    homepage_7: 'Tudo',
    homepage_8: 'Registros no BID',
    homepage_9: 'Atividades',
    homepage_10: 'Informação',
    homepage_11: 'Atualizado em',
    homepage_15: 'Janelas de transferências',
    homepage_16: 'Meu Feed',
    homepage_17: 'Seu feed com tudo o que de mais importante aconteceu desde o seu último acesso.',
    homepage_18: 'Não há janelas abertas no momento.',
  },

  buttons: {
    excluir: 'Excluir',
    cancel: 'Cancelar',
    save: 'Salvar',
    edit: 'Editar',
  },

  nav_drawer: {
    meuPerfil: 'Meu Perfil',
    organizacao: 'Organização',
    verPerfilOrganizacao: 'Ver perfil da organização',
    feed: 'Meu Feed',
    buscaAtletas: 'Busca de Atletas',
    clubes: 'Clubes',
    competicoesBase: 'Competições',
    analiseMercado: 'Análise de Mercado',
    mercadoTransferencias: 'Mercado de Transferências',
    redeProfissionais: 'Rede de Profissionais',
    agencias: 'Agências',
  },

  athlete_card: {
    without_agency: 'Não informado',
    idade: ' anos',
    meses: 'meses e',
    days: 'dias',
    text1: 'Contratos na CBF',
    text2: 'Data do BID',
    text3: 'Início',
    text4: 'Término',
    text5: 'Estado',
    text6: 'Valor(M €)',
    text7: 'Total movimentado:',
    text8: 'Tipo de Negociação: Todos',
    text9: 'Status do Anúncio: Todos',
    edit_pic: 'Editar Foto',
    nickname: 'Apelido:',
    fullname: 'Nome Completo:',
    nation: 'Nacionalidade',
    birth: 'Nascimento:',
    height: 'Altura:',
    weight: 'Peso:',
    pos: 'Posição:',
    foot: 'Pé preferencial:',
    edit_athl: 'Editar Atleta',
    add_athl: 'Adicionar Atleta',
    add_athl_msg1: 'O atleta adicionado será visível apenas para a sua organização!',
    add_athl_msg2: 'Fique tranquilo para usá-lo no Análise de Mercado.',
    filter1: 'Clube: Todos',
    anotacoes: 'anotações',
    selectTeam: 'Selecionar Clube',
    error: {
      nationalities: 'Preencha pelo menos uma nacionalidade!',
      name: 'O nome do atleta não deve conter caracteres inválidos.',
      birthdate: 'O atleta deve possuir ao menos 7 anos para ser cadastrado.',
      is_retired: 'Este atleta não está mais ativo no Footlink.',
    },
  },

  athlete_list: {
    box1: 'Jogador',
    box2: 'Clube',
    box3: 'Idade',
    box4: 'Posição',
    box5: 'Nac.',
    box6: 'Fim do Contrato',
    exist: 'Existem ',
    founded: 'Encontramos ',
    tip: 'Gerar relatório',
    text1: ' atleta na nossa base de dados',
    text2: ' atleta na nossa base de dados com os critérios da sua pesquisa',
    ageInComp: 'Idade na competição', 
  },

  month: {
    // Janeiro,
    // Fevereiro,
    // Março,
    // Abril,
    // Maio,
    // Junho,
    // Julho: "",
    // Agosto: "",
    // Setembro: "",
    // Outubro: "",
    // Novembro: "",
    // Dezembro: "December",
  },

  contact_card: {
    type: 'Tipo',
    category: 'Categoria',
    role: 'Cargo',
    phone: 'Telefone',
  },

  table: {
    origin: 'Origem',
    destiny: 'Destino',
    age_moment: 'Idade no momento',
    price: 'Valor',
    text: 'Ver avaliação',
    data: 'Data',
    analist: 'Analista',
    clube: 'Clube no momento',
    model: 'Modelo de avaliação',
    general: 'Avaliação Geral',
    criadoEm: 'Criado Em',
    atualizadoEm: 'Atualizado Em',
    atleta: 'Atleta',
    idade: 'Idade',
    posicao: 'Posição',
    nasc: 'Nasc.',
    contrato: 'Contrato até',
  },

  myAgency: {
    recomendedDimensionLogo: 'Dimensão recomendada ao logo: 200x200 px',
    agency_info: 'Informações da Agência',
    athletes_in: 'Atletas no Footlink',
    principal_market: 'Mercado Principal',
    my_athletes: 'Meus Atletas',
    my_athletes_desc: 'Gerencie as informações dos atletas da sua agência',
    athletes_profile: 'Perfil dos Atletas',
    represent: 'Representação',
    transfer: 'Transferências',
    dialog_title: 'Dados da agência',
    edit_agency: 'Editar dados da Agência',
  },

  report_pro: {
    title: 'Relatórios PRO',
    description: 'Confira os relatórios gerados por profissionais sobre o atleta. Insira o seu relatório também.',
    no_reports: 'Não há relatórios para exibir',
    image: 'Imagem',
    add_report: 'Adicionar relatório',
    edit: 'Alterar',
    dialog_h1: 'Dados do relatório',
    dialog_title: 'Título',
    dialog_type: 'Tipo de arquivo',
    dialog_type_pdf: 'Arquivo em PDF',
    dialog_type_video: 'Link para vídeo',
    file: 'Arquivo',
    video: 'Link do vídeo',
    select_file: 'Selecionar arquivo',
    access: 'acesso{{sufix}}',
    public: 'Público',
    only_agency: 'Só minha organização vê',
    when_see: 'Quem pode ver?',
    when_see_agency: 'Somente minha organização',
    delete_report: 'Excluir Relatório',
    confirm_delete_report: 'Tem certeza que deseja excluir esse relatório?',
    filter_type: 'Por tipo',
    filter_period: 'Por período',
    filter_origin: 'Por origem',
    filter_myagency: 'Somente inseridas por mim',
    button_add: 'Adicionar relatório PRO',
  },

  dialog_content: {
    list: 'Nova Lista',
    warn: 'Já existe uma lista com o nome inserido',
    create: 'Criar Lista',
    cancel: 'Cancelar',
    noCancel: 'Não, cancelar',
    include: 'Inclua o atleta em listas',
    save: 'Salvar',
    proceed: 'Prosseguir',
    yesProceed: 'Sim, prosseguir',
    new_create: 'Criar nova lista',
    text1: 'Marcar como agenciado',
    text2: 'Sua agência representa este atleta? Marque-o como um de seus atletas.',
    text3: 'Marcar como meu agenciado',
    text4: 'Denunciar',
    text5: 'Este não é o agente deste atleta? Avise-nos para que possamos corrigir a informação.',
    text6: 'Enviar denúncia',
    text7: 'Desmarcar como agenciado',
    text8: 'Não é meu agenciado',
    text9: 'Sua agência ',
    text10: ' representa este atleta? Desmarque-o como um de seus atletas.',
    text11: 'NÃO',
    text12: 'Nome da nova lista',
  },

  report: {
    gene_in: 'Gerado em ',
    project: 'Projeto ',
    relat: 'Relatório de Atleta',
    gene_for: 'Gerado por ',
    pdf: 'Gerando PDF...',
    ex_relat: 'Exportar Relatório',
    relat_error: 'Ocorreu algum problema ao gerar o time-sombra para o relatório, favor tente novamente',
    success: 'Seu relatório foi configurado com sucesso!',
    generate: 'No botão abaixo você poderá tanto imprimir quanto gerar um PDF.',
    export_pdf: ' Exportar para PDF',
    print_out: 'Imprimir/Exportar relatório',
    reportData: 'Dados do Relatório',
    reportName: 'Nome do relatório',
    position: 'Posição',
    athlete: 'Atleta',
    positions: 'Posições',
    appAccessAuthError: 'Você está tentando exportar um relatório com um usuário diferente do logado na WEB, favor saia da sua conta na WEB e tente novamente.',
  },

  slider_menu: {
    in: 'Entre',
    de: '(De):',
    ate: '(Até):',
    clean: 'Limpar',
    e: 'e',
    seas: 'Temporada',
    todas: 'Todas',
    season_all: 'Temporada: Todas',
    age_all: 'Idade: Todos',
    value_all: 'Valor: Todos',
    team_all: 'Equipe: Todas',
    competition_all: 'Competição: Todas',
    group_by: 'Agrupar por: ',
    criterion: 'Nenhum critério',
    value_sale: 'Valor para Venda (em €)',
    value_salary: 'Salário (em €)',
  },

  filters: {
    filter: 'Filtrar',
    add_athlete: 'Adicionar atleta',
    active_filters: 'Filtros ativos:',
    apply: 'Aplicar',
    custom_athletes: 'Pesquisar por Atletas',
    custom_athletes_applied: 'Da minha organização',
    custom_athletes_unapplied: 'Do Footlink',
    key: 'Palavra-chave',
    profile: 'Perfil',
    mini: 'Mínimo',
    minima: 'Mínima',
    ate: 'Até',
    de: 'De',
    select_period: 'Selecionar período',
    pdf: 'PDF',
    video_link: 'Vídeo',
    a: 'à',
    max: 'Máximo',
    maxima: 'Máxima',
    games_in: 'jogos em',
    and_max: 'e máximo',
    contract: 'Contrato',
    pos: 'Posição',
    nation: 'Nacionalidade',
    gender: 'Sexo',
    games: 'Jogos na Temporada',
    gols: 'Gols na Temporada',
    cbf_num: 'Número CBF',
    age: 'Idade',
    birt_year: 'Ano de Nascimento',
    division_ope: 'Divisão em que atua',
    country_ope: 'País em que atua',
    state_ope: 'Estado em que atua',
    clean_filters: 'Limpar Filtros',
    contract_until: 'Contrato até ',
    athl_pro: 'atletas-profissionais',
    athl: 'atletas-amadores',
    with_contr: 'com-contrato',
    no_contr: 'sem-contrato',
    gen: 'gênero',
    search: 'Busca de Atletas',
    ath_base: 'Pesquisa atletas na base mais atualizada do Brasil',
    ath: 'Atletas',
    multiple: 'Vários',
    order_by: 'Ordenar por',
    byPriority: 'Por Prioridade'
  },

  agencies: {
    name: 'Nome',
    email: 'E-mail',
    agencies: 'Agências',
    subti: 'Confira a lista mais completa e atualizada de agentes do Brasil',
    origin_countr: 'País de Origem',
    phones: 'Telefones',
    lists: 'Listas',
    text1: 'atletas agenciados por essa organização',
    text2: 'Clube Atual',
    text3: 'Contrato Atual',
    text4: 'Clube Cedente',
    text5: 'Contrato Cedente',
    text6: 'Ainda não existem atletas adicionados nesta agência',
  },

  config: {
    dire: 'Direcionamento',
    cont: 'Contratar',
    contr: 'contratar',
    mon: 'Monitorar',
    moni: 'monitorar',
    pri: 'Prioridade',
    hi: 'Alta',
    hig: 'alta',
    dang: 'danger',
    mid: 'Média',
    mi: 'media',
    warn: 'warning',
    lo: 'Baixa',
    low: 'baixa',
    inf: 'info',
    model: 'Modelo',
    prior: 'Prior.',
  },

  field: {
    con: 'Concluir',
    edit: 'Editar',
    click: 'Clique aqui para inserir sua avaliação',
    click1: 'Clique aqui para inserir informações.',
    graf: 'Gráfico de Aspectos',
    pare: 'Parecer',
    conte: 'Contexto da equipe/liga em que atua',
    ava: 'Avaliação do Atleta',
    gameData: 'Dados de Jogo',
    selec: 'Selecione um modelo de avaliação.',
    asp: 'Aspectos',
    re_ava: 'Avaliações Realizadas',
    add_ava: 'Adicionar Avaliação',
    text1: 'Esse atleta foi avaliado em',
    opor: 'oportunidades',
    re_ava2:
      'Confira as avaliações já realizadas para este atleta pelos analistas da sua organização e pelo Footure Pro.',
    re_ava1: 'Avaliações',
    filter1: 'Analistas: Todas',
    filter2: 'Clube no momento: Todos',
    filter3: 'Período',
  },

  match: {
    text1: 'Próximos Jogos',
    text2: 'Ainda não temos registro dos próximos jogos do atleta',
    text3: 'Jogos',
    text4: 'Jogos disputados',
    text5: 'Ainda não temos registro dos jogos do atleta',
    comp: 'Nome da Competição',
    mand: 'Mandante',
    pla: 'Placar',
    guest: 'Visitante',
    obs: 'Observador',
    sum: 'Súmula da partida',
    videos: 'Vídeos',
    text6: '+ VER TODOS OS VÍDEOS',
    availability: 'Disponibilidade',
    text7:
      'Confira as estatísticas do atleta por partidas. Também programe-se para acompanhar o atleta em seus próximos jogos.',
    text8: 'Estatísticas do Atleta na partida',
    text9: 'Gols',
    text10: 'Cartao Amarelo',
    text11: 'Dois Amarelos',
    text12: 'Cartao Vermelho',
    text13: 'Minutos Jogados',
    text14: 'Iniciou como Titular',
    text15: 'Iniciou no banco',
    text16: 'Entrou',
    text17: 'Saiu',
  },

  overview: {
    text1: '+ Ver todas as transferências',
    text2: 'Carreira',
    text3: 'Total movimentado:',
    text4: 'Ver Atividade',
    text5: '+ VER TODAS AS AVALIAÇÕES',
    text6: '+ VER TODAS AS NEGOCIAÇÕES',
    text7: 'Negociações',
    text8: 'concluida',
    text9: 'cancelada',
    text10: 'pendente',
    text11: 'Início',
    text12: 'Responsável',
    text13: 'Último Status',
    text14: 'Contato',
    text15: 'Tipo',
    text16: 'Status',
    text17: 'Notícias',
    text18: '+ VER TODAS AS NOTÍCIAS',
    text19: '+ Ver desempenho completo',
    text20: 'Desempenho',
    text21: 'Fechar',
    text22: 'Tentar novamente',
    text23: 'Desempenho nas últimas 5 competições ',
    text24: 'Denunciar',
    text25: 'Últimas 3 transferências',
    text26: 'Disponibilidade',
    text27: 'Gols na carreira',
    text28: 'Jogos na carreira',
    text29: 'Maior Valor',
    text30: ' em ',
    text31: 'Informações do atleta',
  },

  myClub: {
    general: {
      contractTitle: 'Contratos a vencer',
      contractSeeMore: 'Ver todos os contratos',
      info: 'Informações do Clube',
    },
    professionalCast: {
      pageTitle: 'Meu Elenco',
      pageSubTitle: 'Gerencie as informações dos atletas do seu clube.',
    },
    contracts: {
      pageTitle: 'Contratos',
      pageSubTitle: 'Acompanhe o vencimento dos contratos dos atletas do seu elenco.',
      contractGroup_finished: 'Sem contrato',
      contractGroup_danger: 'Menos de 9 meses do vencimento',
      contractGroup_warning: 'Entre 9 e 18 meses do vencimento',
      contractGroup_ok: 'Mais de 18 meses do vencimento',
      contractGroupEmpty_finished: 'Nenhum contrato vencido',
      contractGroupEmpty_danger: 'Nenhum contrato a menos de 9 meses do vencimento',
      contractGroupEmpty_warning: 'Nenhum contrato entre 9 e 18 meses do vencimento',
      contractGroupEmpty_ok: 'Nenhum contrato a mais de 18 meses do vencimento',
      contractResumeCount_finished: '{{length}} atletas',
      contractResumeCount_danger: '{{length}} contratos',
      contractResumeCount_warning: '{{length}} contratos',
      contractResumeDesc_finished: 'sem contrato',
      contractResumeDesc_danger: 'com Risco Alto',
      contractResumeDesc_warning: 'com Risco Médio',
      tableColumn: {
        athlete: 'Atleta',
        age: 'Idade',
        position: 'Posição',
        nationality: 'Nacionalidade',
        sourceTeam: 'Clube Cedente / Contrato Clube Cedente',
        contractType: 'Tipo de contrato',
        contractNumber: 'Contrato',
        contractStart: 'Início do Contrato',
        contractEnd: 'Fim do Contrato',
      },
    },
  },

  club: {
    text1: 'Prazo do Contrato:',
    text2: 'Emprestado:',
    text3: 'Sim',
    text4: 'Não',
    text5: 'Nenhuma avaliação',
    text7: 'VALOR ATUAL DE MERCADO',
    text8: 'MAIOR VALOR DE MERCADO',
    text9: 'não informado',
    text10: 'Editar dados do atleta',
    text11: 'Agência',
    text12: 'Não informada',
    text13: 'Altura',
    text14: 'Peso',
    text15: 'Avaliação Técnica',
    text16: 'Desempenho',
    text17: 'Clube/Seleção',
    text18: 'Confira o histórico de desempenho do atleta por temporada, competição, clube e seleções.',
    box1: 'Não encontramos nada com esses termos.',
    box2: 'Dados do relatório:',
    box3: 'Relatório gerado para o projeto',
    box4: 'Descrição',
    box5: 'Máximo de 250 caracteres',
    box6: 'Conteúdo do Relatório',
    box7: 'Atletas',
    box8: 'Adicionar atleta',
    box9: 'Total Temporada ',
    box10: 'Características Gerais',
    box11: 'Negociação',
    box12: 'Agente',
    box13: 'Salário',
    box14: 'Investimento',
    box15: 'Avaliação',
    box16: 'Contexto em que atua',
    box17: 'Ainda não existem avaliações para este atleta',
    box18: 'Sem título',
    box19: 'Histórico da Carreira do Atleta',
    box20: 'Transferências',
    box21: 'CLUBE ATUAL',
    box22: 'Até ',
    box23: 'CLUBE CEDENTE',
    box24: 'Visão Geral',
    box25: 'Anotações',
    box26: 'Avaliações',
    box27: 'Meus anúncios',
    box28: 'Atletas disponíveis',
    box29: 'Minhas negociações',
    noData: 'Não há atletas para exibir',
  },

  text: {
    box1: 'Clube Emprést.',
    box2: 'Cont. Emprést.',
    box3: 'Contatos',
    box4: 'Esse clube foi avaliado em ',
    box5: 'Monitoramento',
    box6: 'Notícia',
    box7: 'Adicionar notícia',
    box8: 'Competições mais recentes',
    box9: 'Colocação',
    box10: '+ VER TODAS AS COMPETIÇÕES',
    box11: 'Fonte',
    box12: 'Fundação',
    box13: 'Site Oficial',
    box14: 'Sócios',
    box15: 'Endereço',
    box16: 'CEP',
    box17: '+ VER TODOS OS CONTATOS',
    box18: 'Dirigentes',
    box19: 'Estádio',
    box20: '+ VER TODAS AS TRANSFERÊNCIAS',
    box21: 'elenco',
    box22: 'Cont. Cedente',
    box23: 'VALOR DE MERCADO',
    box24: 'Opção 1',
    box25: 'Opção 2',
    box26: 'Opção 3',
    box27: 'Período',
    box28: 'Local',
    box29: 'Resultado',
    box30: 'Elenco',
    box31: 'Emprestados',
    box32: 'Amadores',
    box33: 'Feminino',
    box34: 'Estado',
    box35: 'Cidade',
    box36: 'País',
  },

  compe: {
    text1: 'País',
    text2: 'Âmbito',
    text3: 'Atual',
    text4: 'Clube competição',
    text5: 'Atletas Inscritos',
    text6: 'Nenhuma partida encontrada',
    text7: 'Publicado em ',
    text8: 'Acompanhe os destaques da ',
    text9: ' com os relatórios produzidos pelo Footure PRO.',
    text10: 'Nenhum relatório encontrado',
    text11: 'Relatórios',
    text12: 'Clubes',
    text13: 'Artilharia',
    text14: 'Ver súmula',
    text15: 'Ver vídeo',
    text16: 'Nenhum jogo encontrado',
    text17: 'Jogos na competição',
    text18: 'Cedente',
    text19: 'Inscritos',
    text20: 'Elenco na competição',
    text21: 'Organizador',
    text22: 'Região',
    text23: 'Fim',
    text24: 'Competições',
    text25: 'Somos a maior fonte de competições de base e de futebol feminino do Brasil.',
    text26: 'Profissional',
    context: {
      R: 'Regional',
      Regional: 'Regional',
      N: 'Nacional',
      Nacional: 'Nacional',
      SA: 'Sulamericano',
      Sulamericano: 'Sulamericano',
    },
    currentSeason: 'Temporada atual',
  },

  organi: {
    box1: 'Atualizar',
    box2: 'Avatar',
    box3: 'Nome da organização',
    box4: 'E-mail Geral',
    box5: 'Telefone 1',
    box6: 'Telefone 2',
    box7: 'Endereço Principal',
    box8: 'Endereço Completo',
    box9: 'Cidade',
    box10: 'Redes Sociais',
    box11: 'Atualizar configurações',
    box12: 'O Telefone 1 precisa ter DDI, DDD e número.',
    box13: 'O Telefone 2 precisa ter DDI, DDD e número.',
    box14: 'O Whatsapp precisa ter DDI, DDD e número.',
    box15: 'Avatar',
    box16: 'Nome do Estádio',
    box17: 'Nome Popular',
    box18: 'Data de Fundação',
    box19: 'Número de Sócios',
    box20: 'Selecione',
    text1: 'Configurações',
    text2: 'Gerencie a sua conta e as suas preferências',
    text3: 'Cobrança',
    text4: 'Organização',
    text5: 'Excluir avaliação',
    text6: 'Realizada em',
    text7: 'Monitore e tenha acesso rápido sempre que precisar.',
    text8: 'Exclur Avaliações',
    text9: 'Confirmar',
    text10: 'Tem certeza que deseja excluir as avaliações selecionadas?',
    text11: 'Divisão',
    text12: 'Série',
    text13: 'Atletas',
    text14: 'Valor de Mercado',
    text15: 'Clubes Estrangeiros',
    text16: 'Pesquise atletas e informações de clubes do Brasil e do Mundo',
    text17: 'começou a ',
    text18: 'o atleta',
    text19: 'gerou um novo relatório',
    text20: 'Em ',
    text21: ' às ',
    text22: 'Atleta anunciado',
    text23: 'Tipo de Negócio',
    text24: 'Valor',
    text25: 'Publicação',
    text26: 'Expiração',
    text27: 'Interessados/Visualizações',
    text28: 'Status',
    text31: 'inseriu',
    reportPro: 'o Relatório PRO "{{report_name}}" d',
    evaluationSaved: 'Avaliação salva com sucesso',
    desiredAthlete: 'Atleta Desejado',
    inNegotiationsOferreds: 'Em negociação / Ofertados',
  },

  home: {
    support: 'Entrar em contato com o suporte',
    box1: 'registrou',
    box2: 'do atleta',
    box3: 'no BID da CBF',
    box4: 'Em: ',
    box5: 'Até: ',
    box6: 'Liberar',
    box7: 'Registro para iniciação desportiva',
    box8: 'Rescisão',
    box9: 'Reversão',
    box10: 'Senha',
    box11: 'Esqueci minha senha',
    box12: 'Permanecer conectado',
    box13: 'Aguarde',
    box14: 'Entrar na minha conta',
    box15: 'Digite o seu e-mail',
    box16: 'Recuperar Senha',
    box17: 'Voltar',
    box18: 'Enviamos um e-mail para você gerar uma nova senha.',
    box19: 'Remover Atletas',
    box20: 'Remover',
    text1: 'Tem certeza que deseja remover todos os atletas selecionados das listas de monitoramento?',
    text2: 'Por estado',
    text3: ' - Nacional',
    text4: 'Clubes Brasileiros',
    text5: 'Todos',
    text6: 'Série A',
    text7: 'Série B',
    text8: 'Série C',
    text9: 'Série D',
    text10: 'Análise de Mercado',
    text11: 'Gerencie seus projetos de análise de mercado de forma colaborativa',
    text12: 'Crie o seu primeiro projeto',
    text13: 'Adicionar Projeto',
    text14: 'A nova senha e senha de confirmação devem ser iguais',
    text15: 'Senha atual',
    text16: 'Nova senha',
    text17: 'Confirmar nova senha',
    text18: 'Meu perfil',
    text19: 'Alterar senha',
    text20: 'Gerencie a seu perfil e as suas preferências',
  },

  proj: {
    campo: 'Campo',
    linkProjetos: 'Projetos',
    linkRelatorios: 'Relatórios',
    linkMonitoramento: 'Monitoramento',
    linkAvaliacoes: 'Avaliações',
    linkJanelasTransferencias: 'Janelas de Transferências',
    archive: 'Arquivar',
    unarchive: 'Desarquivar',
    archived: 'ARQUIVADO',
    box1: 'Ver Perfil',
    box2: 'Editar Relatório',
    box3: 'Histórico de Comentários',
    box4: 'Importar comentários selecionados',
    box5: 'Importar',
    box6: 'ADICIONADO POR',
    box7: 'INDICADO POR',
    box8: 'em 00/00/000',
    box9: 'Indicador por',
    box10: 'Teste',
    box11: 'Comentários',
    box12: 'Você já fez',
    box13: 'comentários dentro do perfil desse atleta.',
    box14: 'Ver comentários',
    box15: 'Proprietário',
    box16: 'criou este projeto',
    box17: 'Não há qualquer histórico para exibir',
    box18: 'Monitorando',
    box19: 'Ocorreu algum problema ao sincronizar o Workflow, por favor atualize sua página',
    box20: 'atletas',
    text1: '+ Adicionar atleta',
    text2: '+ Adicionar lista',
    text3: 'Crie o seu primeiro Time Sombra',
    text4: 'Adicionar time sombra',
    text5: 'Ver time sombra',
    text6: 'Time Sombra',
    text7: 'Esquema: Todos',
    text8: 'Nenhum atleta para exibir',
    text9: 'Digíte um título',
    text10: 'Selecione o esquema: ',
    text11: 'Ocorreu algum problema durante a comunicação com o servidor, favor tente novamente mais tarde',
    text12: 'Nenhum atleta definido para esta posição',
    text13: 'Favor, selecione a posição desejada para o relatório',
    text14: 'Selecionar Atleta',
    text15: 'Etapa',
    text16: 'Atletas nessa posição',
    text17: 'Teste 1',
    text18: 'Teste 2',
    text19: 'Teste 3',
    text20: '...Carregando',
    text21: 'Workflow',
    text22: 'Organize e personalize o seu processo de análise de mercado',
    text23: 'Esquema',
    text24: 'Nome do Atleta',
    pageTitleTimeSombra: 'Times Sombra',
    pageDescTimeSombra: 'Crie times sombra a partir dos atletas selecionados no seu projeto.',
    filtrarPor: 'Filtrar por: ',
    filtarPorPlaceholder: 'Nome do Esquema',
    ultimaAtualizacao: 'Última Atualização: ',
    montarTimeSombra: 'Montar Time Sombra',
    montarTimeSombraDesc: 'Escolha o seu modelo campo, esquema e cor do campo e monte o seu Time Sombra.',
    nomeTimeSombra: 'Nome do Time Sombra: ',
    corDoCampo: 'Cor do Campo: ',
    corDoCampoVerde: 'Verde',
    corDoCampoEscuro: 'Escuro',
    corDoCampoBranco: 'Branco',
    salvarContinuar: 'Salvar e continuar',
    esquema: 'Esquema: ',
    modeloDeCampo: 'Modelo de Campo: ',
    modeloStandard: 'Padrão',
    modeloArea: 'Áreas',
    modeloRunner: 'Corredores',
    editarTimeSombra: 'Editar Time Sombra',
    adicionarPosicao: 'Adicionar Posição',
    editarPosicao: 'Posição ',
    posicaoPanelTitle: 'Atletas da posição',
    posicaoInputNome: 'Nome da posição',
    adicionarAtleta: 'Adicionar Atleta',
    atletaPanelTitle: 'Selecionar Atleta',
    atletaFiltroNome: 'Nome do atleta',
    atletaFiltroEtapaTodos: 'Etapa: Todas',
    atletaFiltroPosicaoTodos: 'Posição: Todas',
    atletaCorAmarelo: 'Amarelo',
    atletaCorVerde: 'Verde',
    atletaCorAzul: 'Azul',
    atletaCorVermelho: 'Vermelho',
    atletaCorCinza: 'Cinza',
    erroSchema: 'Por favor, defina o Esquema desejado',
    erroNome: 'Por favor, defina o Nome desejado',
    historyTitle: 'Histórico',
    historySubTitle: 'Mantenha o controle sobre todas as atividades do seu projeto.',
    userTitle: 'Usuários',
    userSubTitle: 'Gerencie a equipe que trabalha no projeto.',
    deleteMessage:
      'A exclusão da coluna irá excluir todos os atletas desta e essa ação não poderá ser revertida. Tem certeza de que quer continuar com a exclusão?',
    applyFilters: 'Aplicar',
    linkMercadoTransferencia: 'Mercado de Transferências',
    subMercadoTransferencia: 'Negocie atletas de forma direta e com mais transparência.',
    orderCreatedAtAsc: 'Criação - Mais Antiga',
    orderCreatedAtDesc: 'Criação - Mais Recente',
    orderUpdatedAtAsc: 'Atualização - Mais Antiga',
    orderUpdatedAtDesc: 'Atualização - Mais Recente',
    orderNameAsc: 'Ordem alfabética - A > Z',
    orderNameDesc: 'Ordem alfabética - Z > A',
    removeAthlete: 'Remover atleta',
    removeAthleteMessage: 'Tem certeza que deseja remover este atleta?',
    athleteProjectCount: 'Está presente em {{count}} projeto',
  },

  user: {
    box1: 'E-mail enviado com sucesso!',
    box2: 'Informe um e-mail válido',
    box3: 'Procurar por nome',
    box4: 'Perfil',
    box5: 'Adicionar',
    box6: 'Não está listado acima? Convide por e-mail.',
    box7: 'Digite o e-mail da pessoa',
    box8: 'Editar Usuários',
    box9: 'Alguns minutos atrás',
    box10: 'hora(s) atrás',
    box11: 'dia(s) atrás',
    box12: 'Adicionado em',
    box13: 'Última atividade',
    box14: 'Colaboradores',
    box15: 'Gerenciar usuários',
    box16: 'Criado em ',
    box17: 'Última atualização em ',
    box18: 'por',
    box19: 'Visualizador',
    box20: 'Administrador',
    text1: 'Editor',
    text2: 'Histórico',
    text3: 'Usuários',
    text4: 'Nenhum resultado encontrado',
    text5: 'Nome do Projeto',
    text6: 'Adicionar Projeto',
    text7: 'Nenhum atleta adicionado',
    text8: 'Ver relatório',
    text9: 'Atletas no Relatório',
    text10: 'Excluir Relatórios',
    text11: 'Excluir',
    text12: 'Tem certeza que deseja excluir os relatórios selecionados?',
    text13: 'Sua senha foi alterada. Faça o login utilizando a nova senha.',
    text14: 'DIGITE A NOVA SENHA',
    text15: 'CONFIRME A NOVA SENHA',
    text16: 'Masculino',
    text17: 'Amador',
    text18: 'Aberto',
    text19: 'Fechado',
    text20: 'Por País',
    text21: 'Modalidade',
    text22: 'Por Modalidade',
    text23: 'Por Status',
    text24: 'Início Temporada',
    text25: 'Fim Temporada',
    text26: 'Janela 1 (Início/Fim)',
    text27: 'Janela 2 (Início/Fim)',
    text28: 'Janela 3 (Início/Fim)',
    text29: 'Janelas',
    text30: 'Fique por dentro das janelas de transferências de todos os países',
    text31: 'Nome do Atleta',
    text32: 'Meus anúncios',
    text32sub: 'Maximize suas oportunidades de negociação de atletas com nossa solução de gerenciamento de anúncios.',
    text33: 'Adicionar Anúncio',
  },

  app: {
    box1: 'Ver todas as atividades',
    box2: 'Notificações e Alertas ',
    box3: 'Mais notificações',
    box4: 'Ver clube',
    box5: 'Sair',
    box6: 'Ver todos',
    box7: 'Digite o nome de um atleta ou clube (pelo menos 3 caracteres)',
    box8: 'BUSCA AVANÇADA',
    box9: 'Janelas ativas',
    box10: 'Ver todas as janelas',
    box12: 'PRO',
    box13: 'Página Inicial',
    box14: 'Atletas e Clubes',
    box15: 'Base',
    box16: 'Competições',
    box17: 'Copinha 2023',
    box18: 'Análise de Mercado',
    box19: 'Listagem de Agências',
    box20: 'Configuração do Relatório',
    text1: 'Edição',
    text2: 'Preview',
    text3: 'Salvando alterações...',
    text4: 'Alterações salvas com sucesso!',
    text5: 'de',
    text6: 'por página',
    text7: 'Itens',
    text8: 'Conversas',
    text9: 'Criar anúncio',
  },

  preferentialFoot: {
    null: '',
    Ambos: 'Ambos',
    Direito: 'Direito',
    Esquerdo: 'Esquerdo',
    Indiferente: 'Indiferente',
    undefined: 'Não informado',
  },

  performance: {
    ABAIXO: 'Abaixo do nível da partida',
    MEDIO: 'Na média do nível da partida',
    ACIMA: 'Acima do nível da partida',
    MUITO_ACIMA: 'Muito acima do nível da partida',
    null: '',
    undefined: 'Não informado',
  },

  positionOptions: {
    null: '',
    Atacante: 'Atacante',
    Ataque: 'Ataque',
    Centroavante: 'Centroavante',
    Defesa: 'Defesa',
    Goleiro: 'Goleiro',
    LateralDireito: 'Lateral Direito',
    LateralEsquerdo: 'Lateral Esquerdo',
    Meia: 'Meia',
    MeiaAtacante: 'Meia-atacante',
    MeioCampo: 'Meio-campo',
    Volante: 'Volante',
    Zagueiro: 'Zagueiro',
  },

  newDivisionOptions: {
    1: '1ª Divisão',
    2: '2ª Divisão',
    3: '3ª Divisão',
    4: '4ª Divisão',
    division: 'Divisão',
    UmDivisao: '1ª Divisão',
    DoisDivisao: '2ª Divisão',
    TresDivisao: '3ª Divisão',
    QuatroDivisao: '4ª Divisão',
    SemDivisao: 'Sem Divisão',
  },

  errorMessage: {
    expiredAccount: 'Sua conta expirou, favor entre em contato para renovar seu acesso. Você pode encontrar nosso contato através do site footlink.app',
    serviceUnavailable: 'Serviço indisponível. Tente novamente mais tarde.',
    defaultCredentialsError: 'Não pudemos validar suas credenciais. Tente novamente.',
    resourceNotFoundAth: 'Atleta não encontrado.',
    resourceNotFoundClub: 'Clube não encontrado.',
    minMax: 'Máx deve ser maior que Mín',
  },

  SystemInfoName: {
    teams: 'Ti',
    athletes: 'F',
    market_value: 'M',
    competitions: 'M',
    transfers: 'Transferências de atletas fora do Brasil',
    bid: 'Contratos - BID da CBF',
    historical_data_prof: 'Estatísticas de jogos (Profissional)',
    historical_data_base: 'Estatísticas de jogos (Base)',
    match_data: 'M',
    transfer_windows: 'Janelas de transferências',
  },

  yesNo: {
    nao: 'Não',
    sim: 'Sim',
  },

  reportCheckboxes: {
    generalFeatures: 'Características Gerais',
    negotiation: 'Negociação',
    images: 'Imagens',
    evaluations: 'Avaliações',
    historic: 'Desempenho',
    career: 'Carreira',
  },

  targetingOptions: {
    Contratar: 'Contratar',
  },

  radioAdvancedSearch: {
    female: 'Feminino',
    male: 'Masculino',
  },

  radioAdvancedSearchCustomAthlete: {
    custom: 'Inseridos pela minha organização',
    normal: 'Do banco de atletas do Footlink',
  },

  contractOptions: {
    'com-contrato': 'Com Contrato',
    'sem-contrato': 'Sem Contrato',
    'indiferente': 'Indiferente',
  },

  profileOptions: {
    'atletas-profissionais': 'Atletas Profissionais',
    'atletas-amadores': 'Atletas Amadores',
  },

  negotiationOptions: {
    SALE: 'Venda',
    LOAN: 'Empréstimo',
  },

  desiredNegotiationOptions: {
    SALE: 'Compra',
    LOAN: 'Empréstimo',
  },

  monitoredAthlete: {
    monitorado: 'Sim',
    naomonitorado: 'Não',
  },

  europeanPassport: {
    'possui-passaporte': 'Sim',
    'nao-possui-passaporte': 'Não',
  },

  erros: {
    text1: 'Dados não encontrados.',
    text2: 'Não foi possível buscar as agências no momento.',
    text3: 'Não foi possível buscar os detalhes da agência no momento',
    text4: 'Não foi possível buscar os atletas desta agência no momento.',
    text5: 'Atleta marcado para sua agência com sucesso!',
    text6: 'Denúncia enviada com sucesso!',
    text7: 'Atleta desmarcado com sucesso!',
    text8: 'Não foi possível realizar a ação no momento',
    text9: 'Atleta',
    text10: 'Falha na carga da avaliação.',
    text11: 'Ocorreu um erro ao tentar buscar as notas. Por favor, tente novamente',
    text12: 'Ocorreu um erro ao tentar criar a nota. Por favor, tente novamente',
    text13: 'Não foi possível encontrar a lista de atletas.',
    text14: 'Não foi possível encontrar o histórico de desempenho do atleta.',
    text15: 'Não foi possível encontrar os jogos do atleta.',
    text16: 'Não foi possível encontrar o histórico de contratos na CBF do atleta.',
    text17: 'Não foi possível encontrar o histórico de transferências do atleta.',
    text18: 'Ocorreu um erro ao tentar atualizar o atleta. Por favor, tente novamente',
    text19: 'Não foi possível encontrar a lista de países.',
    text20: 'Não foi possível buscar as avaliações no momento. Por favor, tente novamente',
    text21: 'Não foi possível excluir as avaliações no momento. Por favor, tente novamente',
    text22: 'Ocorreu um erro ao tentar buscar o últimos atletas visualizados. Por favor, tente novamente',
    text23: 'Ocorreu um erro ao tentar buscar as últimas movimentações. Por favor, tente novamente',
    text24: 'Ocorreu um erro ao tentar buscar os detalhes do sistema. Por favor, tente novamente',
    text25: 'Ocorreu um erro ao tentar buscar as janelas de transferência. Por favor, tente novamente',
    text26: 'Não foi possível encontrar as listas de monitoramento.',
    text27: 'Não foi possível salvar a lista de monitoramento.',
    text28: 'Não foi possível alterar as listas de monitoramento.',
    text29: 'Não foi possível adicionar ao monitoramento',
    text30: 'Ocorreu um erro ao tentar atualizar a organização. Por favor, tente novamente',
    text31: 'Não foi possível buscar os projetos no momento',
    text32: 'Não foi possível buscar os detalhes do projeto no momento',
    text33: 'Ocorreu um erro ao tentar buscar os relatórios. Por favor, tente novamente',
    text34: 'Ocorreu um erro ao tentar buscar o relatório. Por favor, tente novamente',
    text35: 'Ocorreu um erro ao tentar gerar o PDF do relatório. Por favor, tente novamente',
    text36: 'Ocorreu um erro ao tentar gerar o relatório. Por favor, tente novamente',
    text37: 'Ocorreu um erro ao tentar atualizar o relatório. Por favor, tente novamente',
    text38: 'Não foi possível excluir os relatórios no momento. Por favor, tente novamente',
    text39: 'Não foi possível encontrar a lista de times.',
    text40: 'Os dados dos filtros não foram encontrados',
    text41: 'Usuário e/ou senha inválidos',
    text42: 'Dados inválidos',
    text43: 'Ocorreu um erro ao tentar buscar os dados do perfil. Por favor, tente novamente',
    text44: 'Ocorreu um erro ao tentar atualizar o perfil. Por favor, tente novamente',
    text45: 'Senha atualizada com sucesso!',
    text46: 'Ocorreu um erro ao tentar trocar a senha. Por favor, tente novamente',
    text47: 'Falha na carga do schema de avaliação.',
    text48: 'Dados de inscritos da competição não encontrados.',
    text49: 'Os dados estão incorretos. Tente novamente.',
    text50: 'Formato de imagem não suportado envie imagens no formato *.png ou *.jpg ou *.jpeg ou *.gif ou *.bmp ou *.webp.',
    link: 'As redes sociais/site devem ser preenchidos com um link válido',
    generalForm: 'Todos os campos com * devem ser preenchidos',
    ageMinMax: 'A idade mínima deve ser menor que a idade máxima',
  },

  rest: {
    box1: 'Recortar imagem para o upload',
    box2: 'Faça a primeira anotação para o atleta',
    box3: 'Digite sua observação',
    box4: 'neste projeto',
    box5: 'Nota',
    box6: 'Anexos',
    box7: 'Adicionar arquivo',
    box8: 'Incluir Parecer',
    box9: 'Incluir Contexto',
    box10: 'Incluir aspectos',
    box11: 'Incluir microaspectos',
    box12: 'Selecione alguma avaliação da lista',
    box13: 'Não existem dados disponíveis sobre a carreira do atleta',
    box14: 'Não existem dados disponíveis sobre as transferencias do atleta',
    box15: 'Não existem dados disponíveis sobre os contratos do atleta',
    box16: 'Editar Imagem',
    box17: 'Inserir Imagem',
    box18: 'atletas analisados',
    box19: 'Ocorreu algum problema ao remover o atleta, favor tente novamente',
    box20: 'Ocorreu algum problema ao atualizar os dados do atleta, favor tente novamente',
    box21: 'atleta',
    box22: 'Excluir todos',
    box23: 'Nenhuma anotação inserida.',
    box24: 'Passo 1: Selecione o atleta a ser anunciado',
    box24other: '* Você só pode anunciar atletas sem contrato',
    box25: 'Continuar',
    box26: 'Audiência',
    box27: 'clubes selecionados',
    box28: 'Período de publicação',
    box29: 'Tipo de negociação desejada',
    box30: 'Valor de venda',
    box31: 'Salário anual do atleta',
    box32: 'Observações',
    box33: 'Valor do empréstimo',
  },

  trello: {
    box1: 'Saiba mais sobre os maiores clubes do mundo e seus atletas.',
    box2: 'Ainda não tem acesso? Solicite uma demonstração.',
    box3: 'Quero conhecer o Footlink',
    box4: 'O futuro do seu jogo passa por aqui.',
    box5: 'Confira informações sobre os clubes brasileiros e seus elencos.',
  },

  languages: {
    br: 'Português (Brasileiro)',
    pt: 'Português',
    eng: 'English',
    esp: 'Español',
  },

  professionals: {
    topbarTitle: 'Rede de Profissionais',
    topbarSubtitle: 'Gerencie e desenvolva seu network com a maior rede de profissionais de futebol do Brasil.',

    filtersTopspan: 'Já somos uma rede de 1.450 profissionais.',
    filtersInputName: 'Nome',
    filtersSelectLevel: 'Nível: Todos',
    filtersSelectOrganization: 'Organização: Todos',

    connectButton: 'Conectar',
    connectedButton: 'Conectado',
    pendingButton: 'Pendente',
    sendMessageButton: 'Enviar mensagem',

    inviteTitle: 'Convites',
    inviteSpan: 'Convide seus contatos para fazer parte da\n maior rede de profissionais de futebol do Brasil.',
    inviteButton: 'Enviar convite',
    inviteAccept: 'Aceitar',
    inviteIgnore: 'Ignorar',
    invite: 'Não há convites para exibir',

    networkTitle: 'Minha Rede',
    networkSpan: 'Gerencie e amplie o seu network com o Footure Pro.',
    networkConnections: 'Conexões',
    networkFollow: 'Seguir',
    networkFollowing: 'Seguindo',
    networkFollowers: 'Seguidores',

    description: 'Descrição',
    socialMedia: 'Redes sociais',
    language: 'Idiomas',
    connections: 'Conexões próximas',
    commonConnections: '+ Ver todas as conexões comuns',
  },

  listTranslation: {
    Amateur: 'Amador',
    Female: 'Feminino',
    Male: 'Masculino',
  },

  navDrawer: {
    myperfil: 'Meu perfil',
    organization: 'Organização',
    module: 'Módulos',
    name: 'Nome do Clube',
    perfil: 'ver perfil da organização',
    feed: 'Meu Feed',
    search: 'Busca de Atletas',
    club: 'Clubes',
    competitions: 'Competições',
    analysis: 'Análises de Mercado',
    market: 'Mercado de Transferência',
    rede: 'Rede de Profissionais',
    agencies: 'Agências',
  },

  chat: {
    placeholder: 'Pesquisar Mensagens',
    from: 'Você',
    deleted: 'Mensagem apagada',
    deletedNote: 'Nota deletada',
  },

  transferMarket: {
    offeredBy: 'Oferecido Por',
    search_page_desc: 'anunciados no Mercado de Transferências com esses atributos no momento',
    search_page_button: 'Ver Mercado de Transferências',
    subtitle: 'Negocie com os maiores clubes do Brasil e do mundo e reforce a sua equipe.',
    available_negotiation: ' atletas disponíveis para negociação',
    filterMessagesPlaceholder: 'Pesquisar mensagens',
    filterTransferTypePlaceholder: 'Todos atletas',
    filterTransferTypeBuy: 'Chegadas',
    filterTransferTypeSell: 'Saídas',
    filterTransferTypeSALE: 'Venda',
    filterTransferTypeLOAN: 'Empréstimo',
    editAd: 'Editar Anúncio',
    headerClubTitle: 'Anunciante',
    headerPriceTitle: 'Preço Pedido',
    headerTransferTypeTitle: 'Tipo de Negociação',
    headerSalaryTitle: 'Salário',
    headerBuyTransferTypeText: 'Compra',
    headerSellTransferTypeText: 'Venda',
    headerClubObs: 'Observações do anunciante',
    linkProfile: 'Ver perfil completo',
    chatMessageInput: 'Digite sua mensagem',
    headerOtherChatTitle: '3 conversas sobre',
    headerOtherChatAlertTitle: 'Existem mais {{length}} conversas abertas sobre este jogador',
    headerOtherChatAlertLink: 'Ver conversas',
    headerChatInactive: 'Esse atleta não está mais no mercado de transferências',
    moreInfo: 'Mais Informações',
    announcedIn: 'Anunciado em',
    business: 'Negócio',
    amountRequested: 'Preço Pedido',
    sale: 'Venda',
    year: 'Ano',
    clubAnnouncement: 'Detalhes do Anúncio',
    active: 'Ativo',
    inactive: 'Inativo',
    adData: 'Dados do Anúncio',
    defineAudience: 'Definir Audiência',
    confirmAd: 'Confirmar Anúncio',
    publish: 'Publicar',
    publishSuccess: 'Anúncio publicado com sucesso!',
    step1title: 'Passo 1: Informe as características do atleta que você está procurando',
    step2title: 'Passo 2: Digite os dados do seu anúncio',
    step3title: 'Passo 3: Defina quem pode ver o anúncio',
    step4title: 'Passo 4: Confirme os dados do anúncio antes de publicar',
    select: 'Selecionar',
    selected: 'Selecionado',
    adAuciton: 'Audiência do Anúncio',
    showAll: 'Todos podem ver',
    selectShow: 'Definir quem pode ver',
    negotiationType: 'Tipo de Negociação Desejada',
    sale_value: 'Preço de Venda',
    sale_value_desired: 'Valor da Compra',
    loan_value: 'Valor do Empréstimo',
    annual_salary: 'Salário Anual do Atleta',
    gross_salary: 'Salário Bruto',
    perYear: 'por ano',
    perMonth: 'por mês',
    publishFail: 'Falha ao publicar o anúncio!',
    completeProfile: 'Ver perfil completo',
    evaluationFooture: 'AVALIAÇÂO FOOTURE',
    openChat: 'Abrir conversas',
    athleteInfo: 'Informações do Atleta',
    withoutClub: 'Sem clube',
    announceType: 'Qual o tipo de anúncio você quer criar?'
  },

  negotiations: {
    headerClubTitle: 'Anunciante',
    headerPriceTitle: 'Valor pedido',
    headerTransferTypeTitle: 'Tipo de negócio',
    headerSalaryTitle: 'Salário',
    headerBuyTransferTypeText: 'Compra',
    headerSellTransferTypeText: 'Venda',
    headerSaleTransferTypeText: 'Venda',
    headerLoanTransferTypeText: 'Empréstimo',
    headerClubObs: 'Observações do anunciante',
    linkProfile: 'Ver perfil completo',
    chatMessageInput: 'Digite sua mensagem',
    headerOtherChatTitle: '3 conversas sobre',
    headerOtherChatAlertTitle: 'Existem outras 3 conversas abertas sobre esse atleta',
    headerOtherChatAlertLink: 'Ver conversas',
    errorChatCreate: 'Não foi possível salvar sua mensagem, verifique a mensagem e tente novamente',
    moreInfo: 'Mais Informações',
    announcedIn: 'Anunciado Em',
    business: 'Negócios',
    amountRequested: 'Valor pedido',
    sale: 'Venda',
    year: 'Ano',
    clubAnnouncement: 'Detalhes do Anúncio',
    active: 'Ativo',
    inactive: 'Inativo',
    adData: 'Dados do Anúncio',
    defineAudience: 'Definir Audiência',
    confirmAd: 'Confirmar Publicação',
    publish: 'Publicar',
    publishSuccess: 'Anúncio publicado com sucesso!',
    confidentialAd: 'Anúncio Sigiloso?',
    step1title: 'Passo 1: Informe as características do atleta que você está procurando',
    step2title: 'Passo 2: Digite os dados do seu anúncio',
    step3title: 'Passo 3: Defina quem poder poderá ver o anúncio',
    step4title: 'Passo 4: Confirme os dados do anúncio antes de publicar',
    select: 'Selecionar',
    selected: 'Selecionado',
    adAuciton: 'Audiência do Anúncio',
    showAll: 'Todos podem ver',
    selectShow: 'Definir quem pode ver',
    negotiationType: 'Tipo de negociação desejada',
    sale_value: 'Valor da venda',
    loan_value: 'Valor do empréstimo',
    annual_salary: 'Salário anual do atleta',
    perYear: 'por ano',
    noMessages: 'Ainda não há mensagens nesta conversa.',
    selectChat: 'Selecione uma conversa para começar a negociar!',
  },

  search: {
    typeNegotiation: 'Tipo de Negociação',
    valueForSale: 'Valor para Venda (em €)',
    valueForPurchase: 'Valor para Compra (em €)',
    salary: 'Salário (em €)',
  },

  divisionOptions: {
    division: 'Série',
    1: 'Série A',
    2: 'Série B',
    3: 'Série C',
    4: 'Série D',
  },
  
  gameFields: {
    competition: 'Competicão',
    level: 'Nível da Competição',
    gameLevel: 'Nível do Jogo',
    location: 'Local do Jogo',
    observed: 'Jogo Observado',
    date: 'Data do Jogo',
    minutes: 'Minutos jogados',
    startingPlayer: 'Saiu como titular',
    category: 'Categoria',  
    position: 'Posição no Jogo',
    stature: 'Estatura',
    foot: 'Pé dominante',
    gols: 'Gols',
    assistances: 'Assistências',
    yellowCards: 'Cartões Amarelos',
    redCards: 'Cartões Vermelhos',
    performance: 'Desempenho'
  },
};


