import React, { useLayoutEffect, useRef, useState } from 'react';
import * as S from './styles';
import AreaModel from './areamodel';
import CorredoresModel from './corredoresmodel';
import AthletePosition from './athleteposition';
import ShadowTeamConsts from '../../consts';

export default function LineUp({
  color,
  model,
  positions,
  setPosition,
  selectedPosition,
  athletes,
  handleSelectPosition,
  handleUnselectPosition,
  disablePositions = false,
}) {
  const ref = useRef(null);
  const [background, setBackground] = useState(null);

  useLayoutEffect(() => {
    if (ref)
      setBackground({
        width: ref.current?.offsetWidth,
        height: ref.current?.offsetHeight,
        ref,
      });
  }, []);
  const borderColor = color?.toLowerCase() === '#ffffff' ? '#000000' : '#ffffff';

  return (
    <S.Background color={color} onClick={disablePositions ? () => {} : handleUnselectPosition}>
      <S.External borderColor={borderColor} ref={ref}>
        <S.AreaCircle top={true} borderColor={borderColor} />
        <S.Area color={color} top={true} borderColor={borderColor}>
          <S.InsideArea top={true} borderColor={borderColor} />
          <S.DotArea top={true} borderColor={borderColor} />
        </S.Area>
        <S.MiddleLine borderColor={borderColor} />
        <S.MiddleCircle borderColor={borderColor} />
        <S.MiddleDot borderColor={borderColor} />
        <S.AreaCircle top={false} borderColor={borderColor} />
        <S.Area color={color} top={false} borderColor={borderColor}>
          <S.DotArea top={false} borderColor={borderColor} />
          <S.InsideArea top={false} borderColor={borderColor} />
        </S.Area>
        {model === ShadowTeamConsts.EModels.AREA.description && <AreaModel fieldColor={color} />}
        {model === ShadowTeamConsts.EModels.RUNNER.description && <CorredoresModel fieldColor={color} />}
        {positions &&
          background &&
          positions.map((p, i) => (
            <AthletePosition
              key={p.id ?? 0 + '' + i}
              position={p}
              setPosition={setPosition}
              athletes={athletes.filter(i => i.position === p.id)}
              background={background}
              selected={selectedPosition === p}
              disabled={disablePositions}
              onClick={e => {
                e.stopPropagation();
                handleSelectPosition(p);
              }}
            />
          ))}
      </S.External>
    </S.Background>
  );
}
