/*eslint-disable eqeqeq*/
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Avatar } from 'components/template';

export default function Activities({ data }) {
  const { t } = useTranslation();
  const item = data.data;
  const athleteData = ['new_monitoring', 'new_reports_pro'].includes(data?.category) ? item?.athlete : item;

  const card = (
    <div className={styles.card} key={data.id}>
      <div className={styles.left}>
        <Avatar noBorder img={data.owner?.profile?.photo} name={data.owner?.name} />
      </div>
      <div className={styles.right}>
        {data.category === 'new_monitoring' ||
        data.category === 'new_custom_athlete' ||
        data.category === 'new_reports_pro' ? (
          <div className={styles.texto}>
            <span className={styles.monitor}>{`${data.owner?.name} `}</span>
            {data.category === 'new_monitoring' && t('organi.text17')}
            <strong>{data.category === 'new_monitoring' ? t('config.moni') : t('organi.text31')}</strong>{' '}
            {data.category === 'new_reports_pro' && t('organi.reportPro', { report_name: item.title })}
            {t('organi.text18')}{' '}
            <Link
              className={styles.athlete}
              to={{ pathname: `/atletas/${athleteData?.id}`, state: { previews: window.location.pathname } }}
            >
              {athleteData?.nickname || athleteData?.fullname}
            </Link>
          </div>
        ) : (
          <div className={styles.texto}>
            <span className={styles.monitor}>{data.owner?.name}</span> {t('organi.text19')}
          </div>
        )}
        <div className={styles.date}>
          <span>
            {data.created_at
              ? t('organi.text20') +
                moment(data.created_at).format('DD/MM/YYYY') +
                t('organi.text21') +
                moment(data.created_at).format('HH:mm')
              : ' - '}
          </span>
        </div>
      </div>
    </div>
  );

  return <div className={styles.container}>{card}</div>;
}
