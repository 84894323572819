import Endpoints from 'endpoints';
import axios from 'api';
import logger from 'shared/Logger';
import ErrorMessages from 'shared/ErrorMessages';

export const Types = {
    SEARCH: 'advancedSearch/SEARCH',
    SEARCH_SUCCESS: 'advancedSearch/SEARCH_SUCCESS',
    SEARCH_FAILED: 'advancedSearch/SEARCH_FAILED',
    SEARCH_ERROR_HANDLED: 'advancedSearch/SEARCH_ERROR_HANDLED',
    RESET: 'advancedSearch/RESET',
    HIDE: 'advancedSearch/HIDE',
    SHOW: 'advancedSearch/SHOW',
};

const initialState = {
    loading: false,
    error: null,
    data: [],
    isVisible: false,
};

export default function advancedSearchReducer(state = initialState, action) {
    switch (action.type) {
        case Types.SEARCH:
            return { ...state, loading: true };
        case Types.SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                isVisible: true,
                data: action.payload.data,
            };
        case Types.SEARCH_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case Types.SEARCH_ERROR_HANDLED:
            return { ...state, error: null };
        case Types.HIDE:
            return { ...state, isVisible: false };
        case Types.SHOW:
            return { ...state, isVisible: true };
        case Types.RESET:
            return initialState;
        default:
            return state;
    }
}

export const Creators = {
    search: query => async dispatch => {
        try {
            dispatch({ type: Types.SEARCH });

            const url = `${Endpoints.athletes}`;
            const { data } = await axios.get(url);

            dispatch({ type: Types.SEARCH_SUCCESS, payload: { data } });
        } catch (err) {
            logger.error(err.message);
            const { response } = err;
            if (response) {
                dispatch({
                    type: Types.SEARCH_FAILED,
                    payload: { error: "erros.text1" },
                });
            } else {
                dispatch({
                    type: Types.SEARCH_FAILED,
                    payload: { error: ErrorMessages.serviceUnavailable },
                });
            }
        }
    },
    reset: () => ({ type: Types.RESET }),
    hide: () => ({ type: Types.HIDE }),
    show: () => ({ type: Types.SHOW }),
};