import React, { useEffect, useState } from 'react';
import axios from 'api';
import LatestMoves from '../LatestMoves';
import styles from './General.module.scss';
import { Spinner, Avatar, Dialog, Input, Button, IconButton } from 'components/template';
import { ClubAthletesRepository } from 'data/clubAthletes';
import ContractsResume from './ContractsResume/ContractsResume';
import ClubInfo from './ClubInfo/ClubInfo';
import Users from './Users/Users';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import { HiTrash } from 'react-icons/hi';
import { updateTeamInfo } from 'store/ducks/myClub';

export default function General({ club, teamId }) {
  const { t } = useTranslation();
  const initialClubInfo = {
    emblem: club.emblem ?? '',
    photos: club.photos,
    city_team: club.city_team ?? '',
    founding_date: club.founding_date,
    stadium: club.profile?.stadium ?? '',
    partners: club.partners ?? 0,
    social: {
      website: club.social?.website ?? '',
      instagram: club.social?.instagram ?? '',
      twitter: club.social?.twitter ?? '',
      youtube: club.social?.youtube ?? '',
      tiktok: club.social?.tiktok ?? '',
    },
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [athletes, setAthletes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [clubInfo, setClubInfo] = useState(initialClubInfo);
  const [step, setStep] = useState(0);
  const [imgInfo, setImgInfo] = useState(null);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const data = await ClubAthletesRepository.list(teamId, { has_contract: 'true' });
      setError(data.error);
      setAthletes(data.results);
      setLoading(false);
    }
    if (teamId) fetchData();
  }, [teamId]);

  useEffect(() => {
    axios
      .get(`/teams/${teamId}/photos`)
      .then(response => {
        setImgInfo(response.data.results);
      })
      .catch(error => { });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function onClose() {
    setIsOpen(false);
    setClubInfo(initialClubInfo);
  }

  function handleImageUpload() {
    const imageInput = document.createElement('input');
    imageInput.type = 'file';
    imageInput.multiple = true;

    imageInput.addEventListener('change', event => {
      const files = event.target.files;

      const formData = new FormData();
      formData.append('photo', files[0]);

      axios
        .post(`/teams/${teamId}/photos`, formData)
        .then(response => {
          setClubInfo(prevClubInfo => ({
            ...prevClubInfo,
            photos: [...prevClubInfo.photos, response.data.photo],
          }));
        })
        .catch(error => { });
    });
    imageInput.click();
  }

  function handleImageDelete() {
    imgInfo.forEach((img, index) => {
      if (step === index) {
        axios.delete(`/teams/${teamId}/photos/${img.id}`);
        setClubInfo(prevClubInfo => ({
          ...prevClubInfo,
          photos: prevClubInfo.photos.filter(photo => photo !== img.photo),
        }));
      } else return;
    });
  }

  function handleLogoUpdate() {
    const imageInput = document.createElement('input');
    imageInput.type = 'file';
    imageInput.multiple = false;

    imageInput.addEventListener('change', event => {
      const files = event.target.files;

      const formData = new FormData();
      formData.append('emblem', files[0]);

      axios
        .patch(`/teams/${teamId}`, formData)
        .then(response => {
          setClubInfo({
            ...clubInfo,
            emblem: response.data.emblem,
          });
        })
        .catch(error => { });
    });
    imageInput.click();
  }

  const dotClasses = [styles.image_dot];
  if (step || 1) dotClasses.push(styles.selected);

  return (
    <div className={styles.containerWrapper}>
      {loading && (
        <div className={styles.loading}>
          <Spinner />
        </div>
      )}
      {error && (
        <div className={styles.error}>
          <span>{error}</span>
        </div>
      )}
      {!error && !loading && (
        <div className={styles.container}>
          <div className={styles.leftPanel}>
            <ClubInfo club={club} setIsOpen={setIsOpen} />
            <Users club={club} />
          </div>
          <div className={styles.middlePanel}>
            <LatestMoves styleClass={styles.latestNews} />
          </div>
          <div className={styles.rightPanel}>
            <ContractsResume teamId={teamId} athletes={athletes.slice(0, 50)} />
          </div>
        </div>
      )}
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        className={{
          container: styles.dialog,
          header: styles.dialogHeader,
          body: styles.dialogBody,
          footer: styles.dialogFooter,
        }}
        mainTitle={t('myClub.general.dialogTitle')}
      >
        <div className={styles.body_container}>
          <div className={styles.left_container}>
            <h1 className={styles.left_title}>{t('reportCheckboxes.images')}</h1>
            <div className={styles.avatar_container}>
              <Avatar noBorder noRadius img={clubInfo.emblem} name={club.nickname} className={styles.club_avatar} />
              <div className={styles.avatar_side}>
                <span>{t('myClub.general.emblem')}</span>
                <Button
                  className={styles.avatar_button}
                  onClick={() => {
                    handleLogoUpdate();
                  }}
                >
                  {t('organi.box1')}
                </Button>
              </div>
            </div>
            <div className={styles.recomendedDimension}>
              {t('myAgency.recomendedDimensionLogo')}
            </div>
            <div className={styles.images_container}>
              <h1>{t('reportCheckboxes.images')}</h1>
              <div className={styles.images_display}>
                <div className={styles.carousel_container}>
                  <Carousel
                    showThumbs={false}
                    dynamicHeight={true}
                    showStatus={false}
                    emulateTouch={true}
                    showArrows={true}
                    showIndicators={false}
                    onChange={index => setStep(index)}
                    className={styles.carousel}
                    selectedItem={step}
                  >
                    {clubInfo.photos?.map((photo, index) => {
                      return <img key={index} className={styles.image} alt={`club${index}`} src={photo} />;
                    })}
                  </Carousel>
                </div>
                {clubInfo.photos?.length > 0 && (
                  <IconButton
                    className={styles.delete_image_icon}
                    icon={<HiTrash />}
                    onClick={() => {
                      handleImageDelete();
                    }}
                  />
                )}
              </div>
              <div className={styles.images_dots}>
                {clubInfo.photos?.map((item, index) => {
                  return (
                    <div
                      className={index === step ? dotClasses.join(' ') : styles.image_dot}
                      onClick={() => setStep(index)}
                      key={item}
                    ></div>
                  );
                })}
              </div>
              <Button
                className={styles.add_image_button}
                onClick={() => {
                  handleImageUpload();
                }}
              >
                {t('myClub.general.addImage')}
              </Button>
            </div>
          </div>
          <div className={styles.right_container}>
            <h1 className={styles.right_title}>{t('myClub.general.clubData')}</h1>
            <div className={styles.input_city}>
              <span>{t('organi.box9')}</span>
              <Input
                value={clubInfo.city_team}
                onChange={e =>
                  setClubInfo({
                    ...clubInfo,
                    city_team: e.target.value,
                  })
                }
              />
            </div>
            <div className={styles.input_foundation}>
              <span>{t('organi.box18')}</span>
              <Input
                type="date"
                value={clubInfo.founding_date}
                onChange={e =>
                  setClubInfo({
                    ...clubInfo,
                    founding_date: e.target.value,
                  })
                }
              />
            </div>
            <div className={styles.input_stadium}>
              <span>{t('myClub.general.stadium')}</span>
              <Input
                value={clubInfo.stadium}
                onChange={e =>
                  setClubInfo({
                    ...clubInfo,
                    stadium: e.target.value,
                  })
                }
              />
            </div>
            <div className={styles.input_partners}>
              <span>{t('myClub.general.partnersNumber')}</span>
              <Input
                value={clubInfo.partners}
                onChange={e =>
                  setClubInfo({
                    ...clubInfo,
                    partners: e.target.value,
                  })
                }
              />
            </div>
            <div className={styles.input_site}>
              <span>Site</span>
              <Input
                value={clubInfo.social.website}
                onChange={e =>
                  setClubInfo({
                    ...clubInfo,
                    social: {
                      ...clubInfo.social,
                      website: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className={styles.input_instagram}>
              <span>Instagram</span>
              <Input
                value={clubInfo.social.instagram}
                onChange={e =>
                  setClubInfo({
                    ...clubInfo,
                    social: {
                      ...clubInfo.social,
                      instagram: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className={styles.input_twitter}>
              <span>X</span>
              <Input
                value={clubInfo.social.twitter}
                onChange={e =>
                  setClubInfo({
                    ...clubInfo,
                    social: {
                      ...clubInfo.social,
                      twitter: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className={styles.input_youtube}>
              <span>YouTube</span>
              <Input
                value={clubInfo.social.youtube}
                onChange={e =>
                  setClubInfo({
                    ...clubInfo,
                    social: {
                      ...clubInfo.social,
                      youtube: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className={styles.input_tiktok}>
              <span>Tik Tok</span>
              <Input
                value={clubInfo.social.tiktok}
                onChange={e =>
                  setClubInfo({
                    ...clubInfo,
                    social: {
                      ...clubInfo.social,
                      tiktok: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.dialog_footer}>
          <Button
            className={styles.dialog_footer_button_cancel}
            onClick={() => {
              onClose();
            }}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            className={styles.dialog_footer_button_save}
            onClick={async () => {
              const updateBody = { ...clubInfo };
              delete updateBody.emblem;
              updateTeamInfo(teamId, updateBody)
                .then(res => {
                  if (res) {
                    window.location.reload();
                  }
                })
                .catch(err => { });
            }}
          >
            {t('buttons.save')}
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
