import React from 'react';
import styles from './ChatMessage.module.scss';
import * as S from './styles';
import { Avatar, Checkbox } from 'components/template';
import { FiDownload } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import AthleteCard from 'components/AthleteCard';
import { MdBlock } from 'react-icons/md';

//import "../../translations/i18n"

export default function ChatMessage({ id, user, message, hour, checked, onCheckClick, ...rest }) {
  const { t } = useTranslation();
  const containerClasses = [styles.container];
  const isMyItem = user?.id === message.sender || user?.id === message.sender?.id;
  containerClasses.push(isMyItem ? styles.typeSent : styles.typeReceived);

  let senderName = '-';
  if (message.sender?.occupation) {
    senderName = `${message.sender.name} - ${message.sender.occupation}`;
  } else if (message.sender?.first_name || message.sender?.last_name) {
    senderName = `${message.sender.first_name} ${message.sender.last_name}`;
  } else if (message.sender?.name) {
    senderName = message.sender.name;
  }

  let messageContent = '';

  if (message.deleted_at) {
    messageContent = <div className={styles.message}><i><MdBlock />{t('chat.deleted')}</i></div>
  } else {
    switch (message.messages_type) {
      case 'text':
        const hasExtraInfo = message.extra_info != null;
        const extraInfoData = message.extra_info?.data;
        const addressArray = [];
        if (message.extra_info?.type === 'team') {
          if (extraInfoData.city) addressArray.push(extraInfoData.city);
          if (extraInfoData.state) addressArray.push(extraInfoData.state);
          if (extraInfoData.country?.name) addressArray.push(extraInfoData.country.name);
        }
        messageContent = <div className={styles.message}>
          {hasExtraInfo && message.extra_info.type === 'athlete' && (
            <AthleteCard
              item={extraInfoData}
              hideIcon={true}
              styleClass={styles.extraInfoAthlete}
            />
          )}
          {hasExtraInfo && message.extra_info.type === 'team' && (
            <div className={styles.extraInfoTeam}>
              <Avatar
                noBorder
                noRadius
                img={extraInfoData.img ?? extraInfoData.emblem ?? ''}
                name={extraInfoData.shortName ?? extraInfoData.nickname}
              />
              <div className={styles.clubInfo}>
                <h1>{extraInfoData.shortName ?? extraInfoData.nickname}</h1>
                {extraInfoData?.country?.flag && (
                  <>
                    <div><span>{addressArray.join(', ')}</span></div>
                    <img draggable="false" src={extraInfoData.country.flag} alt="país do clube" />
                  </>
                )}
              </div>
            </div>
          )}

          {hasExtraInfo ? <a href={message.message}>{message.message}</a> : message.message}
        </div>;
        break;
      case 'photo':
        messageContent = <div className={styles.photo_container}>
          <img className={styles.photo} src={message.photo ? message.photo : message.file} alt="message" />
          {!!message.message && <span className={styles.message}>{message.message}</span>}
        </div>
        break;
      case 'file':
        messageContent = <div className={styles.file_container}>
          <a href={message.file} target="_blank" rel="noopener noreferrer">
            <div className={styles.file}>
              <FiDownload size="14" />
              Baixar arquivo
            </div>
          </a>
          {!!message.message && <span className={styles.message}>{message.message}</span>}
        </div>
        break;
      case 'audio':
        messageContent = <div className={styles.file_container}>
          <S.Audio controls>
            <source src={message.file} />
          </S.Audio>
        </div>
        break;
      default:
        messageContent = '';
        break;
    }
  }

  return (
    <div className={containerClasses.join(' ')} {...rest}>
      {isMyItem && checked != null && onCheckClick != null && (
        <Checkbox
          className={styles.checkboxSelected}
          checked={checked}
          onChange={() => onCheckClick(!checked, id)}
        />
      )}
      <div className={styles.avatar}>
        <div className={styles.clubContainer}>
          <div className={styles.athleteImg}>
            <Avatar img={message.sender?.photo} name={senderName ?? ''} noBorder />
          </div>
          {(message.sender?.team || message.sender?.emblem) && (
            <Avatar
              img={message.sender?.team?.emblem || message.sender?.emblem}
              name={message.sender?.team?.name || senderName}
              className={styles.club}
              noBorder
            />
          )}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.name_container}>
          <span className={styles.name}>
            {senderName}
          </span>
        </div>
        {messageContent}
        <div className={styles.hour}>{hour}</div>
      </div>
    </div>
  );
}
