import React from 'react';
import styles from './StepFour.module.scss';
import { MdCalendarMonth } from 'react-icons/md';
import { InfoCard, Calendar } from 'components/template';
import { useTranslation } from 'react-i18next';
import { formatMoneyString } from 'shared/utility';

export default function StepFour({ adsData, setAdsData, athletes }) {
  const { t } = useTranslation();
  // const [inputData, setInputData] = useState("");

  const filteredAthlete = athletes?.filter(item => {
    return item.id === adsData.athlete;
  });

  const data = filteredAthlete && filteredAthlete[0];

  return (
    <div className={styles.dialogFour}>
      <div className={styles.text}>
        <span>{t('transferMarket.step4title')}</span>
      </div>
      <div className={styles.athlete}>
        <InfoCard data={data} isDesired={false} />
      </div>
      <div className={styles.cont}>
        <div className={styles.c1}>
          <div className={styles.text1}>
            <h5>{t('rest.box26')}</h5>
          </div>
          <div className={styles.text2}>
            {adsData.teams?.length === 0
              ? (<h5>{t('transferMarket.showAll')}</h5>)
              : (<h5>{`${adsData.teams?.length} ${t('rest.box27')}`}</h5>)
            }
          </div>
        </div>
        <div className={styles.c1}>
          <div className={styles.text1}>
            <h5>{t('rest.box28')}</h5>
          </div>
          <div className={styles.inputUm}>
            <p>{t('filters.de')}:</p>
            <div className={styles.inputContainer}>
              <Calendar
                value={adsData.initial_date}
                setValue={value =>
                  setAdsData({
                    ...adsData,
                    initial_date: value,
                  })
                }
                maxDate={adsData.expiration_date} // Set maxDate based on the expiration_date
              />
              <div className={styles.calendarIcon}>
                <MdCalendarMonth />
              </div>
            </div>
          </div>
          <div className={styles.inputDois}>
            <p>{t('filters.ate')}:</p>
            <div className={styles.inputContainer}>
              <Calendar
                value={adsData.expiration_date}
                setValue={value =>
                  setAdsData({
                    ...adsData,
                    expiration_date: value,
                  })
                }
                minDate={adsData.initial_date} // Set minDate based on the initial_date
              />
              <div className={styles.calendarIcon}>
                <MdCalendarMonth />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.textos}>
          <div className={styles.text3}>
            <h5>{t('rest.box29')}</h5>
          </div>
          <div className={styles.subtext}>
            <span>{t(`negotiationOptions.${adsData.negotiation_type}`)}</span>
          </div>
        </div>
        <div className={styles.textos}>
          <div className={styles.text3}>
            {adsData.negotiation_type === 'SALE' ? <h5>{t('rest.box30')}</h5> : <h5>{t('rest.box33')}</h5>}
          </div>
          <div className={styles.subtext}>
            <p>€ {formatMoneyString(adsData.sale_value)}</p>
          </div>
        </div>
        <div className={styles.textos}>
          <div className={styles.text3}>
            <h5>{t('rest.box31')}</h5>
          </div>
          <div className={styles.subtext}>
            <p>€ {formatMoneyString(adsData.annual_salary)}</p>
          </div>
        </div>
        <div className={styles.textos}>
          <div className={styles.text3}>
            <h5>{t('rest.box32')}</h5>
          </div>
          <div className={styles.subtextObs}>
            <p>{adsData.notes}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
