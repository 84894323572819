import React, { useState, useRef } from 'react';
import styles from './Footer.module.scss';
import { useTranslation } from 'react-i18next';
import { IconButton, Input } from 'components/template';
import { FaPaperclip } from 'react-icons/fa';

export default function Footer({ submitMessage = () => {} }) {
  const { t } = useTranslation();

  const initialMessage = {
    message: '',
    messages_type: 'text',
    file: null,
  };
  const [message, setMessage] = useState(initialMessage);
  const fileInput = useRef(null);

  const handleAnnexClick = () => {
    fileInput.current.click();
  };
  // const handleMediaClick = () => {
  //   alert('media button click');
  // };
  // const handleSpeakClick = () => {
  //   alert('speaker button click');
  // };
  const handleChange = event => {
    setMessage({
      ...message,
      message: event.target.value,
    });
  };
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      submitMessage(message);
      setMessage(initialMessage);
    }
  };

  const onUploadFile = file => {
    const messages_type = file.type.split('/')[0] === 'image' ? 'photo' : 'file';
    submitMessage({
      ...message,
      messages_type,
      file,
    });
    setMessage(initialMessage);
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerRow}>
        <div className={styles.leftButtons}>
          <IconButton color="green" icon={<FaPaperclip />} onClick={handleAnnexClick} />
          <input
            accept="audio/*,video/*,image/*,application/*"
            onChange={e => onUploadFile(e.target.files[0])}
            type="file"
            ref={fileInput}
            style={{ display: 'none' }}
          />
          {/* <IconButton color="green" icon={<FaCamera />} onClick={handleMediaClick} /> */}
        </div>
        <div className={styles.center}>
          <Input
            placeholder={t('negotiations.chatMessageInput')}
            inputClassName={styles.messageInput}
            value={message.message}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className={styles.rightButtons}>
          {/* <IconButton color="green" icon={<FaMicrophone />} onClick={handleSpeakClick} /> */}
        </div>
      </div>
    </div>
  );
}
