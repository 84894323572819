/*eslint-disable eqeqeq*/
import React from 'react';
import styles from './index.module.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Footure from 'assets/footure.jpg';

import { Avatar } from 'components/template';

export default function Negotiations({ data }) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Avatar img={Footure} />
      <div className={styles.content}>
        <div className={styles.ownerName}>Footure</div>
        <div className={styles.date}>
          {data.created_at
            ? t('organi.text20') +
              moment(data.created_at).format('DD/MM/YYYY') +
              t('organi.text21') +
              moment(data.created_at).format('HH:mm')
            : ' - '}
        </div>
        <div dangerouslySetInnerHTML={{ __html: data.data?.html ?? '' }} />
      </div>
    </div>
  );
}
