import React from 'react';
import PropTypes from 'prop-types';

import styles from './Backdrop.module.scss';

export default function Backdrop({ isVisible, onClick, ...props }) {
  const backdropClasses = [styles.backdrop];
  if (isVisible) backdropClasses.push(styles.visible);
  return <div {...props} onClick={onClick} className={backdropClasses.join(' ')} />;
}

Backdrop.defaultProps = {
  isVisible: false,
  onClick: () => null,
};

Backdrop.propTypes = {
  isVisible: PropTypes.bool,
  onClick: PropTypes.func,
};
