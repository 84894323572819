import React, { useState, useEffect, useRef } from 'react';
import { isEqual } from 'lodash/lang'
import { Checkbox } from 'components/template';
import styles from './CheckboxContainer.module.scss';

const CheckboxContainer = ({ checkboxes, setFilter, value, className }) => {

    const [checkedItems, setCheckedItems] = useState(value);
    const mounted = useRef(false);

    useEffect(() => {
        if (!isEqual(checkedItems, value)) {
            setCheckedItems(value);
        }

    }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            setFilter(checkedItems);
        }
    }, [checkedItems]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (event) => {
        if (checkedItems.some(checkedItem => checkedItem === event.target.value)) {
            setCheckedItems(checkedItems.filter(checkedItem => checkedItem !== event.target.value));
        } else {
            setCheckedItems([...checkedItems, event.target.value]);
        }
    };

    return (
        <>
            {checkboxes && checkboxes.map(item => (
                <Checkbox
                    key={item.value}
                    value={item.value}
                    onChange={handleChange}
                    label={item.label}
                    checked={checkedItems?.some(checkedItem => checkedItem === item.value)}
                    className={className ? className : styles.checkbox}
                    dark
                />
            ))}
        </>
    );
};

export default CheckboxContainer;
