import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './NavSection.module.scss';
import { IconButton } from 'components/template';
import { Creators as layoutCreators } from 'store/ducks/layout';
import { useTranslation } from 'react-i18next';

function NavSection({ title, icon, to, items, history, hideNavDrawer, iconColor, paymentRequired }) {
  let itemsContent = null;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  itemsContent = items.map(item => (
    <li key={`${item.title}`} onClick={() => linkClickHandler(item.to)}>
      {item.title}
    </li>
  ));

  const iconClasses = [styles.iconColor];
  if (iconColor === 'iconPurple') iconClasses.push(styles.iconPurple);
  if (iconColor === 'iconPink') iconClasses.push(styles.iconPink);
  if (iconColor === 'iconGreen') iconClasses.push(styles.iconGreen);
  if (iconColor === 'iconBlue') iconClasses.push(styles.iconBlue);
  if (iconColor === 'iconBrown') iconClasses.push(styles.iconBrown);
  if (iconColor === 'iconYellow') iconClasses.push(styles.iconYellow);
  if (iconColor === 'iconRed') iconClasses.push(styles.iconRed);
  if (iconColor === 'iconGray') iconClasses.push(styles.iconGray);

  if (paymentRequired) {
    iconClasses.push(styles.paymentRequiredBackground);
  }

  const containerClasses = [styles.container];
  if (paymentRequired) {
    containerClasses.push(styles.paymentRequiredContainer);
  }

  function linkClickHandler(location) {
    hideNavDrawer();
    if (paymentRequired) {
      dispatch(layoutCreators.showExcededPlanLimit());
    } else {
      history.push(location);
    }
  }

  return (
    <div className={containerClasses.join(' ')}>
      {title && (
        <div onClick={() => linkClickHandler(to)} className={styles.sectionTitle}>
          <IconButton
            className={iconClasses.join(' ')}
            color="green"
            icon={
              <>
                {icon}
                {paymentRequired && <div className={styles.paymentRequired}>{t('nav_drawer.comprar')}</div>}
              </>
            }
          />
          <span>{title}</span>
        </div>
      )}
      {itemsContent && <ul>{itemsContent}</ul>}
    </div>
  );
}

NavSection.defaultProps = {
  items: [],
  title: null,
  icon: null,
  to: null,
};

NavSection.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  to: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  history: PropTypes.instanceOf(Object).isRequired,
  hideNavDrawer: PropTypes.func.isRequired,
};

export default withRouter(NavSection);
