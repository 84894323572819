import styled from 'styled-components';
import Variables from 'variables.js';
import { Menu as DefaultMenu } from 'components/template';

export const Menu = styled(DefaultMenu)`
  background-color: #fff;
  border: solid 1px ${Variables.lightBorder};
  font-size: 14px;
  font-weight: 500;
  padding: 12px 8px;
  flex: 1;
  color: #000;
  margin-right: 10px;

  &:last-child {
    margin-right: 0px;
  }
`;

export const MenuCor = styled.div`
  position: relative;
  width: 80px;
`;

export const MenuCorOption = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: ${props => props.color};
  border: solid 1px ${Variables.lightBorder};
  position: absolute;
  left: 60px;
  top: 0;
`;

export const LeftHeader = styled.div`
  font-size: 14px;
  padding: 15px 15px 15px 15px;

  & > h3 {
    padding: 0;
    margin: 10px 0;
  }
`;

export const PositionTitle = styled.div`
  font-size: 14px;
  padding: 15px 15px 15px 15px;
  color: ${Variables.lightPurple1};
  display: flex;
  justify-content: start;
  align-items: center;
`;
export const PositionTitleText = styled.div`
  color: #000;
  font-weight: 600;
  display: flex;
  flex-grow: 0;
  justify-content: start;
`;
export const PositionTitleName = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 205px;
  justify-content: start;
  padding-left: 10px;
  color: ${Variables.lightPurple1};

  & span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const LeftInput = styled.div`
  border-top: solid 1px ${Variables.lightBorder};
  height: 55px;
  display: flex;
  align-items: center;
  padding: 8px 15px;

  &&& {
    border-color: ${props => (props.hasError ? 'red' : Variables.lightBorder)};
  }
  & > * {
    flex: 1;
  }
  & > label {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
  }
`;
export const PositionButtonText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const FilterInputContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterInputText = styled.div`
  flex-shrink: 0;
`;

export const FlexDiv = styled.div`
  display: flex;
`;