import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FaSearch, FaClipboardList } from 'react-icons/fa';
import { Input, Menu, SliderMenu, Button } from 'components/template';
import { Creators as layoutActions } from 'store/ducks/layout';
import styles from './ActionBar.module.scss';
import { positionOptions } from 'shared/mocks';
import { useTranslation } from 'react-i18next';

export default function ActionBar({ checkedAthletes, selectedFilters, setSelectedFilters, list }) {
  // function applyFilters(newFilters) {
  //   setSelectedFilters(newFilters);
  // };
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [timeoutId, setTimeoutId] = useState(null);
  const [name, setName] = useState('');

  const handleInputChange = value => {
    clearTimeout(timeoutId);
    setName(value);
    setTimeoutId(
      setTimeout(() => {
        setSelectedFilters({ ...selectedFilters, name: value });
      }, 200)
    );
  };

  return (
    <div className={styles.actionBar}>
      <div className={styles.actionButtons}>
        <Button
          onClick={() => {
            if (checkedAthletes.length === 0) {
              dispatch(
                layoutActions.showToast({
                  content: t('athlete_list.noAthletesReport'),
                  duration: 3000,
                })
              );
            } else {
              window.open(`/relatorios/criar?ids=${checkedAthletes.map(athlete => athlete.id).join(',')}`);
            }
          }}
          color="green"
          rounded
          className={checkedAthletes.length === 0 ? styles.disabled : ''}
        >
          <span>{t('athlete_list.tip')}</span>
          <FaClipboardList />
        </Button>
      </div>
      <Input
        rounded
        placeholder={t('agencies.name')}
        containerClassName={styles.inputContainer}
        onChange={e => {
          handleInputChange(e.target.value);
        }}
        icon={<FaSearch className={styles.icon} />}
        iconPosition="right"
        value={name}
      />
      <SliderMenu
        rounded
        title={t('athlete_card.birth')}
        className={styles.menu}
        max={Number((() => new Date())().getFullYear())}
        min={1975}
        setFilter={val => {
          const [min, max] = val;
          setSelectedFilters({
            ...selectedFilters,
            min_birthyear: min,
            max_birthyear: max,
          });
        }}
      />
      <Menu
        title={t('athlete_list.box4')}
        largeMenu
        rounded
        allowEmpty
        placeholder={t('athlete_list.box4')}
        className={styles.menu}
        options={positionOptions.map(item => ({ label: t(`positionOptions.${item.value}`), value: item.label }))}
        value={selectedFilters.position}
        onClear={() => {
          setSelectedFilters({ ...selectedFilters, position: '' });
        }}
        setValue={val => {
          setSelectedFilters({ ...selectedFilters, position: val });
        }}
      />
    </div>
  );
}
