import Endpoints from 'endpoints';
import axios from 'api';
import { stringifyFiltersWithNestedObjects } from 'shared/utility';

export const actionTypes = {
  GET: 'evaluationsStats/GET',
  GET_SUCCESS: 'evaluationsStats/GET_SUCCESS',
  GET_ERROR: 'evaluationsStats/GET_ERROR',
};

const initialState = {
  loading: false,
  error: null,
  data: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return {
        ...initialState,
        loading: true,
      };
    case actionTypes.GET_SUCCESS:
      return {
        ...initialState,
        loading: false,
        data: action.payload.data,
      };
    case actionTypes.GET_ERROR:
      return {
        ...initialState,
        error: action.payload.error,
        loading: false,
      };
    default:
      return state;
  }
}

let axiosGetAthletesController;
export const getEvaluationsStats = filters => async dispatch => {
  dispatch({ type: actionTypes.GET });
  const url = Endpoints.evaluationsStats;

  try {
    const queryString = stringifyFiltersWithNestedObjects(filters);

    if (axiosGetAthletesController) {
      axiosGetAthletesController.abort();
    }
    axiosGetAthletesController = new AbortController();
    const { data } = await axios.get(`${url}${queryString}`, { signal: axiosGetAthletesController.signal });
    axiosGetAthletesController = null;

    dispatch({
      type: actionTypes.GET_SUCCESS,
      payload: {
        data: data,
      },
    });
  } catch (err) {
    if (err?.name === 'CanceledError') {
      //requisição cancelada
    } else {
      dispatch({
        type: actionTypes.GET_ERROR,
        payload: { error: 'erros.text20' },
      });
    }
  }
};
