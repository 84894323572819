/*eslint-disable eqeqeq*/
import Endpoints from 'endpoints';
import axios from 'api';
import { stringifyFilters } from 'shared/utility';

export const actionTypes = {
  GET_AGENCIES: 'agencies/GET',
  GET_AGENCIES_SUCCESS: 'agencies/GET_SUCCESS',
  GET_AGENCIES_ERROR: 'agencies/GET_ERROR',
  GET_AGENCY: 'agency/GET',
  GET_AGENCY_SUCCESS: 'agency/GET_SUCCESS',
  GET_AGENCY_ERROR: 'agency/GET_ERROR',
  SET_AGENCY: 'agency/SET',
  RESET_AGENCY: 'agency/RESET',
  GET_AGENCY_ATHLETES: 'agencyAthletes/GET',
  GET_AGENCY_ATHLETES_SUCCESS: 'agencyAthletes/GET_SUCCESS',
  GET_AGENCY_ATHLETES_ERROR: 'agencyAthletes/GET_ERROR',
  RESET_AGENCY_ATHLETES: 'agencyAthletes/RESET',
  CREATE_AGENCY_REQUEST: 'agencyRequest/POST',
  CREATE_AGENCY_REQUEST_SUCCESS: 'agencyRequest/POST_SUCCESS',
  CREATE_AGENCY_REQUEST_ERROR: 'agencyRequest/POST_ERROR',
  RESET_AGENCY_REQUEST: 'agencyRequest/RESET',
  GET_AGENCY_FRAMES: 'agencies/agency/frames/GET',
  GET_AGENCY_FRAMES_SUCCESS: 'agencies/agency/frames/GET_SUCCESS',
  GET_AGENCY_FRAMES_FAIL: 'agencies/agency/frames/GET_FAIL',
  GET_AGENCY_USERS: 'agencies/agency/users/GET',
  GET_AGENCY_USERS_SUCCESS: 'agencies/agency/users/GET_SUCCESS',
  GET_AGENCY_USERS_FAIL: 'agencies/agency/users/GET_FAIL',
};

const initialState = {
  list: {
    loading: false,
    data: [],
    error: null,
    hasNext: true,
    hasPrevious: false,
    count: 0,
  },
  instance: {
    loading: true,
    data: null,
    error: null,
  },
  athletes: {
    loading: false,
    data: null,
    error: null,
    hasNext: true,
    hasPrevious: false,
    count: 0,
  },
  frames: {
    loading: false,
    error: null,
    data: [],
    hasNext: true,
    hasPrevious: false,
    count: 0,
  },
  users: {
    loading: false,
    error: null,
    data: [],
    hasNext: true,
    hasPrevious: false,
    count: 0,
  },
  request: {
    loading: false,
    error: null,
    data: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_AGENCIES:
      return {
        ...state,
        list: {
          ...initialState.list,
          loading: true,
        },
      };
    case actionTypes.GET_AGENCIES_SUCCESS:
      return {
        ...state,
        list: {
          ...initialState.list,
          data: action.payload.data.results,
          count: action.payload.data.count,
          hasNext: action.payload.data.hasNext,
          hasPrevious: action.payload.data.hasPrevious,
        },
      };
    case actionTypes.GET_AGENCIES_ERROR:
      return {
        ...state,
        list: {
          ...initialState.list,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_AGENCY:
      return {
        ...state,
        instance: {
          ...initialState.instance,
          loading: true,
        },
      };
    case actionTypes.GET_AGENCY_SUCCESS:
      return {
        ...state,
        instance: {
          ...initialState.instance,
          data: action.payload.data,
          loading: false,
        },
      };
    case actionTypes.GET_AGENCY_ERROR:
      return {
        ...state,
        instance: {
          ...initialState.instance,
          error: action.payload.error,
          loading: false,
        },
      };
    case actionTypes.SET_AGENCY:
      return {
        ...state,
        instance: {
          ...initialState.instance,
          data: action.payload.data,
        },
      };
    case actionTypes.RESET_AGENCY:
      return {
        ...state,
        instance: {
          ...initialState.instance,
        },
      };
    case actionTypes.GET_AGENCY_ATHLETES:
      return {
        ...state,
        athletes: {
          ...initialState.athletes,
          loading: true,
        },
      };
    case actionTypes.GET_AGENCY_ATHLETES_SUCCESS:
      return {
        ...state,
        athletes: {
          ...initialState.athletes,
          data: action.payload.data.results,
          count: action.payload.data.count,
          hasNext: action.payload.data.hasNext,
          hasPrevious: action.payload.data.hasPrevious,
        },
      };
    case actionTypes.GET_AGENCY_ATHLETES_ERROR:
      return {
        ...state,
        athletes: {
          ...initialState.athletes,
          error: action.payload.error,
        },
      };
    case actionTypes.RESET_AGENCY_ATHLETES:
      return {
        ...state,
        athletes: {
          ...initialState.athletes,
        },
      };
    case actionTypes.CREATE_AGENCY_REQUEST:
      return {
        ...state,
        request: {
          ...initialState.request,
          loading: true,
        },
      };
    case actionTypes.CREATE_AGENCY_REQUEST_SUCCESS:
      return {
        ...state,
        request: {
          ...initialState.request,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.CREATE_AGENCY_REQUEST_ERROR:
      return {
        ...state,
        request: {
          ...initialState.request,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.RESET_AGENCY_REQUEST:
      return {
        ...state,
        request: {
          ...initialState.request,
        },
      };
    case actionTypes.GET_AGENCY_FRAMES:
      return {
        ...state,
        frames: {
          ...state.frames,
          loading: true,
        },
      };
    case actionTypes.GET_AGENCY_FRAMES_SUCCESS:
      return {
        ...state,
        frames: {
          ...state.frames,
          loading: false,
          data: action.payload.data,
          hasNext: action.payload.hasNext,
          hasPrevious: action.payload.hasPrevious,
          count: action.payload.count,
        },
      };
    case actionTypes.GET_AGENCY_FRAMES_FAIL:
      return {
        ...state,
        frames: {
          ...state.frames,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_AGENCY_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        },
      };
    case actionTypes.GET_AGENCY_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          data: action.payload.data,
          hasNext: action.payload.hasNext,
          hasPrevious: action.payload.hasPrevious,
          count: action.payload.count,
        },
      };
    case actionTypes.GET_AGENCY_USERS_FAIL:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
}

export const getAgencies = filters => async dispatch => {
  dispatch({ type: actionTypes.GET_AGENCIES });

  const url = Endpoints.agencies;

  try {
    const queryString = stringifyFilters(filters);
    const { data } = await axios.get(`${url}${queryString}`);

    dispatch({
      type: actionTypes.GET_AGENCIES_SUCCESS,
      payload: {
        data: {
          count: data.count,
          results: data.results,
          hasNext: data.next ? true : false,
          hasPrevious: data.previous ? true : false,
        },
      },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_AGENCIES_ERROR,
        payload: { error: 'erros.text2' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_AGENCIES_ERROR,
        payload: { error: 'errorMessage.defaultCredentialsError' },
      });
    } else {
      dispatch({
        type: actionTypes.GET_AGENCIES_ERROR,
        payload: { error: 'errorMessage.serviceUnavailable' },
      });
    }
  }
};

export const setAgency = agency => async dispatch => {
  dispatch({
    type: actionTypes.SET_AGENCY,
    payload: {
      data: agency,
    },
  });
};

export const updateContractRisks = async (ageencyId, contract_high_risk, contract_warning_risk) => {
  const body = { contract_high_risk, contract_warning_risk };
  const { data } = await axios.patch(`${Endpoints.agencies}/${ageencyId}`, body);
  return data;
};

export const getAgency = id => async dispatch => {
  dispatch({ type: actionTypes.GET_AGENCY });

  const url = Endpoints.agencies;

  try {
    const { data } = await axios.get(`${url}/${id}`);
    const photos = await axios.get(`${url}/${id}/photos`);
    const final_data_object = { ...data, photos: photos.data.results };

    dispatch({
      type: actionTypes.GET_AGENCY_SUCCESS,
      payload: { data: final_data_object },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_AGENCY_ERROR,
        payload: { error: 'erros.text3' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_AGENCY_ERROR,
        payload: { error: 'errorMessage.defaultCredentialsError' },
      });
    } else {
      dispatch({
        type: actionTypes.GET_AGENCY_ERROR,
        payload: { error: 'errorMessage.serviceUnavailable' },
      });
    }
  }
};

export const updateAgencyInfo = async (id, body) => {
  const url = Endpoints.agencies;
  try {
    const { data } = await axios.patch(`${url}/${id}`, body);
    return data;
  } catch (err) {
    return err;
  }
};

export const getAgencyAthletes = (id, filters) => async dispatch => {
  dispatch({ type: actionTypes.GET_AGENCY_ATHLETES });

  const url = Endpoints.agencies;

  try {
    const queryString = stringifyFilters(filters);
    const { data } = await axios.get(`${url}/${id}/athletes${queryString}`);

    dispatch({
      type: actionTypes.GET_AGENCY_ATHLETES_SUCCESS,
      payload: {
        data: {
          count: data.count,
          results: data.results,
          hasNext: data.next ? true : false,
          hasPrevious: data.previous ? true : false,
        },
      },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_AGENCY_ATHLETES_ERROR,
        payload: { error: 'erros.text4' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_AGENCY_ATHLETES_ERROR,
        payload: { error: 'errorMessage.defaultCredentialsError' },
      });
    } else {
      dispatch({
        type: actionTypes.GET_AGENCY_ATHLETES_ERROR,
        payload: { error: 'errorMessage.serviceUnavailable' },
      });
    }
  }
};

export const getAgencyNegotiations = (id, filters) => async dispatch => {
  dispatch({ type: actionTypes.GET_AGENCY_FRAMES });
  const url = Endpoints.agencies;

  try {
    const queryString = stringifyFilters(filters);
    const { data } = await axios.get(`${url}/${id}/negotiations${queryString}`);

    dispatch({
      type: actionTypes.GET_AGENCY_FRAMES_SUCCESS,
      payload: {
        data: data.results ?? data,
        hasNext: data.next ? true : false,
        hasPrevious: data.previous ? true : false,
        count: data.count,
      },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_AGENCY_FRAMES_FAIL,
        payload: { error: 'erros.text39' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_AGENCY_FRAMES_FAIL,
        payload: { error: 'errorMessage.defaultCredentialsError' },
      });
    } else {
      dispatch({
        type: actionTypes.GET_AGENCY_FRAMES_FAIL,
        payload: { error: 'errorMessage.serviceUnavailable' },
      });
    }
  }
};

export const createNegotiation = async (id, frame, athlete, type) => {
  try {
    const { data } = await axios.post(`${Endpoints.agencies}/${id}/negotiations`, {
      frame: frame,
      athlete: athlete,
      type: type,
    });
    return { data };
  } catch (err) {
    return { error: 'Ocorreu um erro ao tentar adicionar o atleta no Workflow. Por favor, tente novamente' };
  }
};

export const deleteNegotiation = async (id, negotiationId) => {
  try {
    const { data } = await axios.delete(`${Endpoints.agencies}/${id}/negotiations/${negotiationId}`);
    return { data };
  } catch (err) {
    return { error: 'Ocorreu um erro ao tentar remover o atleta. Por favor, tente novamente' };
  }
};

export const updateAgencyNegotiations = async (id, negotiationId, frame, order, type) => {
  try {
    const { data } = await axios.patch(`${Endpoints.agencies}/${id}/negotiations/${negotiationId}`, {
      frame,
      order,
      type,
    });
    return { data };
  } catch (err) {
    return { error: 'Ocorreu um erro ao tentar atualizar o projeto. Por favor, tente novamente' };
  }
};

export const updateAgencyFrames = async (id, frames_field_name, frames) => {
  const url = Endpoints.agencies;
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const formData = new FormData();
    formData.append(frames_field_name, JSON.stringify(frames));
    const { data } = await axios.patch(`${url}/${id}`, formData, config);
    return data;
  } catch (err) {
    return err;
  }
};

export const getAgencyUsers = (id, filters) => async dispatch => {
  dispatch({ type: actionTypes.GET_AGENCY_USERS });
  const url = Endpoints.agencies;

  try {
    const queryString = stringifyFilters(filters);

    const { data } = await axios.get(`${url}/${id}/users${queryString}`);

    dispatch({
      type: actionTypes.GET_AGENCY_USERS_SUCCESS,
      payload: {
        data: data.results ?? data,
        hasNext: data.next ? true : false,
        hasPrevious: data.previous ? true : false,
        count: data.count,
      },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_AGENCY_USERS_FAIL,
        payload: { error: 'erros.text39' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_AGENCY_USERS_FAIL,
        payload: { error: 'errorMessage.defaultCredentialsError' },
      });
    } else {
      dispatch({
        type: actionTypes.GET_AGENCY_USERS_FAIL,
        payload: { error: 'errorMessage.serviceUnavailable' },
      });
    }
  }
};

export const createAgencyRequest = athleteId => async dispatch => {
  dispatch({ type: actionTypes.CREATE_AGENCY_REQUEST });
  const url = Endpoints.agencyRequests;

  try {
    const { data } = await axios.post(`${url}`, {
      athlete: athleteId,
    });

    let successMessage = '';

    if (data.approved && data.action == 'claim') {
      successMessage = 'erros.text5';
    } else if (!data.approved && data.action == 'claim') {
      successMessage = 'erros.text6';
    } else if (data.approved && data.action == 'remove') {
      successMessage = 'erros.text7';
    }

    dispatch({
      type: actionTypes.CREATE_AGENCY_REQUEST_SUCCESS,
      payload: {
        data: successMessage,
      },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.CREATE_AGENCY_REQUEST_ERROR,
        payload: { error: 'erros.text8' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.CREATE_AGENCY_REQUEST_ERROR,
        payload: { error: 'errorMessage.defaultCredentialsError' },
      });
    } else if (response?.status === 402) {
      dispatch({
        type: actionTypes.CREATE_AGENCY_REQUEST_ERROR,
        payload: { error: 'errorMessage.maximumAthleteReached' },
      });
    } else {
      dispatch({
        type: actionTypes.CREATE_AGENCY_REQUEST_ERROR,
        payload: { error: 'errorMessage.serviceUnavailable' },
      });
    }
  }
};

export const resetAthletes = () => async dispatch => {
  dispatch({ type: actionTypes.RESET_AGENCY_ATHLETES });
};

export const resetAgency = () => async dispatch => {
  dispatch({ type: actionTypes.RESET_AGENCY });
};

export const resetAgencyRequest = () => async dispatch => {
  dispatch({ type: actionTypes.RESET_AGENCY_REQUEST });
};
