import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import styles from './ResultCard.module.scss';

import { useTranslation } from 'react-i18next';

import { PopCard } from 'components/template';
import ResultItem from './ResultItem/ResultItem';

function ResultCard({ data, isVisible, hide, hasHide, onItemClick }) {
  const { t } = useTranslation();
  const dataArr = [...(data?.athletes?.results ?? []), ...(data?.custom_athletes?.results ?? [])];
  const content =
    dataArr.length > 0 ? (
      dataArr.map(item => {
        return <ResultItem key={item.id} item={item} handleItemClick={onItemClick} />;
      })
    ) : (
      <div className={styles.nothingFound}> {t('club.box1')}</div>
    );

  return (
    <>
      <PopCard isVisible={isVisible} hasHide={hasHide} hide={hide} className={styles.card}>
        {/* <div className={styles.}> */}
        {content}
        {/* </div> */}
      </PopCard>
    </>
  );
}

ResultCard.defaultProps = {
  data: {},
  hide: () => {},
  hasHide: true,
  onItemClick: () => {},
};

ResultCard.propTypes = {
  data: PropTypes.instanceOf(Object),
  isVisible: PropTypes.bool.isRequired,
  hide: PropTypes.func,
  hasHide: PropTypes.bool,
  onItemClick: PropTypes.func,
};

export default withRouter(ResultCard);
