import React from 'react';
import PropTypes from 'prop-types';
import styles from './TopBar.module.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components/template';
import { Avatar } from 'components/template';
import { useTranslation } from 'react-i18next';
import { BsChatLeftTextFill } from 'react-icons/bs';
import { IconButton } from 'components/template';
import ArrowBack from 'assets/ico/arrow_back.svg';
import { TeamChatRepository } from 'data/teamChat';
import { Creators as layoutCreators } from 'store/ducks/layout';
import { MdVerified } from 'react-icons/md';

export default function TopBar({ club }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.user.data);
  // const [leagueSelect, setLeagueSelect] = useState('profissional');

  // const leagueOptions = [
  //   {
  //     label: 'Profissional',
  //     value: 'profissional',
  //   },
  //   {
  //     label: 'Amador',
  //     value: 'amador',
  //   },
  // ];

  const addressArray = [];
  if (club?.city) addressArray.push(club.city);
  if (club?.state) addressArray.push(club.state);
  if (club?.country?.name) addressArray.push(club.country.name);

  const goBackToPreviousPage = () => {
    const currentPath = history.location.pathname;
    const pathParts = currentPath.split('/');
    const currentPartsCount = pathParts.length;

    let previousPath = '';
    for (let i = currentPartsCount - 1; i > 4; i--) {
      const previousParts = pathParts.slice(0, i).join('/');
      if (history.location.pathname.includes(previousParts)) {
        previousPath = previousParts;
      }
    }
    history.push(previousPath);
  };

  return (
    <div className={styles.topBar}>
      <div className={styles.left}>
        <div className={styles.pageBack}>
          <Button
            onClick={() => {
              if (history.location.pathname.includes('/inscritos')) {
                goBackToPreviousPage();
              } else {
                history.goBack();
              }
            }}
            noShadow
          >
            <img src={ArrowBack} alt="arrow back" />
          </Button>
        </div>
        <Avatar noBorder noRadius img={club.img ?? club.emblem ?? ''} name={club.shortName ?? club.nickname} />
        <div className={styles.clubInfo}>
          <h1>
            {club.shortName ?? club.nickname}
            {club.has_footlink_contract && (
              <div footlink-title-right={t("home.footlinkClient")}><MdVerified /></div>
            )}
          </h1>
          {club?.country?.flag && (
            <div>
              <span>{addressArray.join(', ')}</span>
              <img draggable="false" src={club.country.flag} alt="país do clube" />
            </div>
          )}
        </div>
        <span className={styles.separator}></span>
        {/* <Menu
          className={styles.select}
          value={leagueSelect}
          options={leagueOptions}
          setValue={setLeagueSelect}
        /> */}
      </div>
      <div className={styles.right}>
        {/* {club.market_value && (
          <div className={styles.marketValue}>
            <h5>{t('text.box23')}</h5>
            <span>{club.market_value}</span>
          </div>
        )} */}
        {club.has_mensageria_access && club.id !== user.organization.id.id && (
          <IconButton
            className={styles.club_message}
            text={t('chat.talk_to_club')}
            icon={<BsChatLeftTextFill />}
            onClick={async () => {
              const response = await TeamChatRepository.post({ team: club.id, org: user.organization.id.id });
              if (response.error && response.status === 402) {
                dispatch(layoutCreators.showExcededPlanLimit());
              } else {
                history.push(`/mensagens/clubes/${response.id}`);
              }
            }}
          />
        )}
      </div>
    </div>
  );
}

TopBar.propTypes = {
  club: PropTypes.instanceOf(Object).isRequired,
};
