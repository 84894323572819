import React, { useState } from 'react';
import styles from './StepTwo.module.scss';
import { Input, Checkbox, InfoCard, Radio } from 'components/template';
import { useTranslation } from 'react-i18next';

export default function StepTwo({ adsData, setAdsData }) {
  const { t } = useTranslation();
  const [checkedSale, setCheckedSale] = useState(true);
  const [checkedSalary, setCheckedSalary] = useState(false);

  const perYearSelected = adsData.annual_salary != null;

  return (
    <div className={styles.stepTwoBody}>
      <div className={styles.stepTwoText}>
        <span>{t('transferMarket.step2title')}</span>
      </div>
      <InfoCard className={styles.stepTwoInfoCard} data={adsData} isDesired={true} />
      <div className={styles.stepTwoNegotiation}>
        <div className={styles.stepTwoNegotiationSpan}>
          <span>{t('transferMarket.negotiationType')}</span>
        </div>
        <div className={styles.stepTwoNegotiationSelect}>
          <Checkbox
            className={styles.stepTwoCheckbox}
            label={t('desiredNegotiationOptions.SALE')}
            checked={adsData.negotiation_type === 'SALE'}
            onChange={() => {
              if (checkedSalary) {
                setCheckedSalary(false);
              }
              setCheckedSale(true);
              setAdsData({
                ...adsData,
                negotiation_type: 'SALE',
              });
            }}
          />
          <Checkbox
            className={styles.stepTwoCheckbox}
            label={t('desiredNegotiationOptions.LOAN')}
            checked={adsData.negotiation_type === 'LOAN'}
            onChange={() => {
              if (checkedSale) {
                setCheckedSale(false);
              }
              setCheckedSalary(true);
              setAdsData({
                ...adsData,
                negotiation_type: 'LOAN',
              });
            }}
          />
        </div>
      </div>
      <div className={styles.stepTwoSale}>
        {checkedSale ? (
          <div className={styles.stepTwoSaleSpan}>
            <span>{t('transferMarket.sale_value_desired')}</span>
          </div>
        ) : (
          <div className={styles.stepTwoSaleSpan}>
            <span>{t('transferMarket.loan_value')}</span>
          </div>
        )}
        <div>
          <Input
            prefix="€ "
            inputClassName={styles.stepTwoSaleInput}
            value={adsData.purchase_value}
            type="currency"
            onValueChange={(value, name) => {
              setAdsData({
                ...adsData,
                purchase_value: value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.stepTwoSalary}>
        <div className={styles.stepTwoSalarySpan}>
          <span>{t('transferMarket.gross_salary')}</span>
        </div>
        <div className={styles.stepTwoSalaryData}>
          <Input
            prefix="€ "
            inputClassName={styles.stepTwoSalaryInput}
            value={perYearSelected ? adsData.annual_salary : adsData.monthly_salary}
            type="currency"
            onValueChange={(value, name) => {
              setAdsData({
                ...adsData,
                annual_salary: perYearSelected ? value : null,
                monthly_salary: perYearSelected ? null : value,
              });
            }}
          />
          <Radio className={styles.stepOneCheckbox}
            label={t('transferMarket.perYear')}
            checked={perYearSelected}
            onChange={() => {
              setAdsData({
                ...adsData,
                annual_salary: adsData.monthly_salary ?? '0',
                monthly_salary: null,
              });
            }}/>
          <Radio
            className={styles.stepOneCheckbox}
            label={t('transferMarket.perMonth')}
            checked={!perYearSelected}
            onChange={() => {
              setAdsData({
                ...adsData,
                annual_salary: null,
                monthly_salary: adsData.annual_salary ?? '0',
              });
            }}
          />
        </div>
      </div>
      <div className={styles.stepTwoObservation}>
        <div className={styles.stepTwoObservationSpan}>
          <span>{t('rest.box32')}</span>
        </div>
        <div>
          <textarea
            className={styles.stepTwoObservationInput}
            value={adsData.comments}
            onChange={e =>
              setAdsData({
                ...adsData,
                comments: e.target.value,
              })
            }
          ></textarea>
        </div>
      </div>
    </div>
  );
}
