import Endpoints from 'endpoints';
import axios from 'api';
import logger from 'shared/Logger';
import ErrorMessages from 'shared/ErrorMessages';
import { stringifyFilters } from 'shared/utility';

export const actionTypes = {
  GET: 'competitions/GET',
  GET_SUCCESS: 'competitions/GET_SUCCESS',
  GET_FAILED: 'competitions/GET_FAILED',
  RESET: 'competitions/RESET',
  GET_COMPETITION: 'competitions/GET',
  GET_SUCCESS_COMPETITION: 'competitions/GET_SUCCESS',
  GET_FAILED_COMPETITION: 'competitions/GET_FAILED',
  GET_SUBSCRIBED: 'competitions/subscribed/GET',
  GET_SUCCESS_SUBSCRIBED: 'competitions/subscribed/GET_SUCCESS',
  GET_FAILED_SUBSCRIBED: 'competitions/subscribed/GET_FAILED',
  GET_REPORTS: 'competitions/reports/GET',
  GET_SUCCESS_REPORTS: 'competitions/reports/GET_SUCCESS',
  GET_FAILED_REPORTS: 'competitions/reports/GET_FAILED',
  GET_MATCHES: 'competitions/matches/GET',
  GET_SUCCESS_MATCHES: 'competitions/matches/GET_SUCCESS',
  GET_FAILED_MATCHES: 'competitions/matches/GET_FAILED',
  RESET_MATCHES: 'competitions/matches/RESET_MATCHES',
  GET_TEAMS: 'competitions/teams/GET',
  GET_SUCCESS_TEAMS: 'competitions/teams/GET_SUCCESS',
  GET_FAILED_TEAMS: 'competitions/teams/GET_FAILED',
  GET_ATHLETES: 'competitions/athletes/GET',
  GET_SUCCESS_ATHLETES: 'competitions/athletes/GET_SUCCESS',
  GET_FAILED_ATHLETES: 'competitions/athletes/GET_FAILED',
  GET_OPTIONS: 'competitions/athletes/GET',
  GET_SUCCESS_OPTIONS: 'competitions/options/GET_SUCCESS',
  GET_FAILED_OPTIONS: 'competitions/options/GET_FAILED',
};

const initialState = {
  competitionsPage: {
    loading: false,
    error: null,
    data: {
      count: 0,
      teams: [],
      hasNext: true,
      hasPrevious: false,
    },
  },
  competitionPage: {
    loading: false,
    error: null,
    data: {},
  },
  subscribed: {
    loading: false,
    error: null,
    count: 0,
    athletes: [],
    hasNext: false,
    hasPrevious: false,
  },
  reports: {
    loading: false,
    error: null,
    data: {
      count: 0,
      reports: [],
      hasNext: true,
      hasPrevious: false,
    },
  },
  matches: {
    loading: false,
    error: null,
    data: {
      count: 0,
      matches: [],
      hasNext: true,
      hasPrevious: false,
    },
  },
  teamsPage: {
    loading: false,
    error: null,
    data: {
      count: 0,
      temas: [],
      hasNext: true,
      hasPrevious: false,
    },
  },
  athletesPage: {
    loading: false,
    error: null,
    data: {
      count: 0,
      athletes: [],
      hasNext: true,
      hasPrevious: false,
    },
  },
  competitionOptions: {
    data: null,
    loading: false,
    error: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, competitionsPage: { ...state.competitionsPage, loading: true } };
    case actionTypes.GET_SUCCESS:
      return {
        ...state,
        competitionsPage: {
          ...state.competitionsPage,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_FAILED:
      return {
        ...state,
        competitionsPage: {
          ...state.competitionsPage,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.RESET:
      return { ...state, competitionsPage: initialState.competitionsPage };
    case actionTypes.GET_COMPETITION:
      return { ...state, competitionPage: { ...state.competitionPage, loading: true } };
    case actionTypes.GET_SUCCESS_COMPETITION:
      return {
        ...state,
        competitionPage: {
          ...state.competitionPage,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_FAILED_COMPETITION:
      return {
        ...state,
        competitionPage: {
          ...state.competitionPage,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_SUBSCRIBED:
      return { ...state, subscribed: { ...state.subscribed, error: null, loading: true } };
    case actionTypes.GET_SUCCESS_SUBSCRIBED:
      return {
        ...state,
        subscribed: {
          ...state.subscribed,
          loading: false,
          error: null,
          athletes: action.payload.data,
          count: action.payload.count,
          hasNext: action.payload.hasNext,
          hasPrevious: action.payload.hasPrevious,
        },
      };
    case actionTypes.GET_FAILED_SUBSCRIBED:
      return {
        ...state,
        subscribed: {
          ...state.competitionPage,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_REPORTS:
      return { ...state, reports: { ...state.reports, loading: true } };
    case actionTypes.GET_SUCCESS_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_FAILED_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_MATCHES:
      return { ...state, matches: { ...state.matches, loading: true } };
    case actionTypes.GET_SUCCESS_MATCHES:
      return {
        ...state,
        matches: {
          ...state.matches,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.RESET_MATCHES:
      return {
        ...state,
        matches: {
          ...initialState.matches,
        },
      };
    case actionTypes.GET_FAILED_MATCHES:
      return {
        ...state,
        matches: {
          ...state.matches,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_TEAMS:
      return { ...state, teamsPage: { ...state.teamsPage, loading: true } };
    case actionTypes.GET_SUCCESS_TEAMS:
      return {
        ...state,
        teamsPage: {
          ...state.teamsPage,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_FAILED_TEAMS:
      return {
        ...state,
        teamsPage: {
          ...state.teamsPage,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_ATHLETES:
      return { ...state, athletesPage: { ...state.athletesPage, loading: true } };
    case actionTypes.GET_SUCCESS_ATHLETES:
      return {
        ...state,
        athletesPage: {
          ...state.athletesPage,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_FAILED_ATHLETES:
      return {
        ...state,
        athletesPage: {
          ...state.athletesPage,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_OPTIONS:
      return { ...state, competitionOptions: { ...state.competitionOptions, loading: true } };
    case actionTypes.GET_SUCCESS_OPTIONS:
      return {
        ...state,
        competitionOptions: {
          ...state.competitionOptions,
          loading: false,
          error: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_FAILED_OPTIONS:
      return {
        ...state,
        competitionOptions: {
          ...state.competitionOptions,
          loading: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
}

let axiosGetAthletesController;
export const Creators = {
  getCompetitions: filters => async dispatch => {
    try {
      dispatch({ type: actionTypes.GET });

      const url = `${Endpoints.competitions}`;
      const queryString = stringifyFilters(filters);

      const { data } = await axios.get(`${url}${queryString}`);

      dispatch({
        type: actionTypes.GET_SUCCESS,
        payload: {
          data: {
            count: data.count,
            competitions: data.results,
            options: data.options,
            hasNext: data.next ? true : false,
            hasPrevious: data.previous ? true : false,
          },
        },
      });
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response) {
        dispatch({
          type: actionTypes.GET_FAILED,
          payload: { error: 'erros.text1' },
        });
      } else {
        dispatch({
          type: actionTypes.GET_FAILED,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  resetCompetitions: () => ({ type: actionTypes.RESET }),
  getCompetitionData: id => async dispatch => {
    try {
      dispatch({ type: actionTypes.GET_COMPETITION });
      const { data } = await axios.get(`${Endpoints.competitions}/${id}`);

      dispatch({
        type: actionTypes.GET_SUCCESS_COMPETITION,
        payload: { data: data },
      });
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response) {
        dispatch({
          type: actionTypes.GET_FAILED_COMPETITION,
          payload: { error: 'erros.text1' },
        });
      } else {
        dispatch({
          type: actionTypes.GET_FAILED_COMPETITION,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getReports: (id, filters) => async dispatch => {
    try {
      dispatch({ type: actionTypes.GET_REPORTS });

      const url = `${Endpoints.competitions}/${id}/reports`;
      const queryString = stringifyFilters(filters);
      const { data } = await axios.get(`${url}${queryString}`);

      dispatch({
        type: actionTypes.GET_SUCCESS_REPORTS,
        payload: {
          data: {
            count: data.count,
            reports: data.results,
            hasNext: data.next ? true : false,
            hasPrevious: data.previous ? true : false,
          },
        },
      });
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response) {
        dispatch({
          type: actionTypes.GET_FAILED_REPORTS,
          payload: { error: 'erros.text1' },
        });
      } else {
        dispatch({
          type: actionTypes.GET_FAILED_REPORTS,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getReport: async (idCompetition, idReport) => {
    try {
      const url = `${Endpoints.competitions}/${idCompetition}/reports/${idReport}`;
      const { data } = await axios.get(url);
      return data;
    } catch (err) {
      logger.error(err.message);
    }
  },
  getMatches: (id, filters) => async dispatch => {
    try {
      dispatch({ type: actionTypes.GET_MATCHES });

      const url = `${Endpoints.competitions}/${id}/matches`;
      const queryString = stringifyFilters(filters);
      const { data } = await axios.get(`${url}${queryString}`);

      dispatch({
        type: actionTypes.GET_SUCCESS_MATCHES,
        payload: {
          data: {
            count: data.count,
            matches: data.results,
            hasNext: data.next ? true : false,
            hasPrevious: data.previous ? true : false,
          },
        },
      });
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response) {
        dispatch({
          type: actionTypes.GET_FAILED_MATCHES,
          payload: { error: 'erros.text1' },
        });
      } else {
        dispatch({
          type: actionTypes.GET_FAILED_MATCHES,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  resetMatches: () => ({ type: actionTypes.RESET_MATCHES }),
  getTeams: (id, filters) => async dispatch => {
    try {
      dispatch({ type: actionTypes.GET_TEAMS });

      const url = `${Endpoints.competitions}/${id}/teams`;
      const queryString = stringifyFilters(filters);
      const { data } = await axios.get(`${url}${queryString}`);

      dispatch({
        type: actionTypes.GET_SUCCESS_TEAMS,
        payload: {
          data: {
            count: data.count,
            teams: data.results,
            hasNext: data.next ? true : false,
            hasPrevious: data.previous ? true : false,
          },
        },
      });
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response) {
        dispatch({
          type: actionTypes.GET_FAILED_TEAMS,
          payload: { error: 'erros.text1' },
        });
      } else {
        dispatch({
          type: actionTypes.GET_FAILED_TEAMS,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getAthletes: (id, filters) => async dispatch => {
    try {
      dispatch({ type: actionTypes.GET_ATHLETES });

      const url = `${Endpoints.competitions}/${id}/scorers`;
      const queryString = stringifyFilters(filters);
      const { data } = await axios.get(`${url}${queryString}`);
      //const { data } = await axios.get(`${Endpoints.competitions}/${id}/scorers`);
      dispatch({
        type: actionTypes.GET_SUCCESS_ATHLETES,
        payload: {
          data: {
            count: data.count,
            athletes: data.results,
            hasNext: data.next ? true : false,
            hasPrevious: data.previous ? true : false,
          },
        },
      });
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response) {
        dispatch({
          type: actionTypes.GET_FAILED_ATHLETES,
          payload: { error: 'erros.text1' },
        });
      } else {
        dispatch({
          type: actionTypes.GET_FAILED_ATHLETES,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }      
    }
  },
  getPrecociousAthletes : (id, filters) => async dispatch => {
    try {
      dispatch({ type: actionTypes.GET_ATHLETES });
  
      const url = `${Endpoints.competitions}/${id}/athletes`;
      const queryString = stringifyFilters(filters);

      if (axiosGetAthletesController) {
        axiosGetAthletesController.abort();
      }
      axiosGetAthletesController = new AbortController();
      const { data } = await axios.get(`${url}${queryString}`, { signal: axiosGetAthletesController.signal });
      axiosGetAthletesController = null;

      dispatch({
        type: actionTypes.GET_SUCCESS_ATHLETES,
        payload: {
          data: {
            count: data.count,
            athletes: data.results,
            hasNext: data.next ? true : false,
            hasPrevious: data.previous ? true : false,
          },
        },
      });
    } catch (err) {
      if (!(err?.name === 'CanceledError')) {
        logger.error(err.message);
        const { response } = err;
        if (response) {
          dispatch({
            type: actionTypes.GET_FAILED_ATHLETES,
            payload: { error: 'erros.text1' },
          });
        } else {
          dispatch({
            type: actionTypes.GET_FAILED_ATHLETES,
            payload: { error: ErrorMessages.serviceUnavailable },
          });
        }
      }
    }
  },
};

export const getSubscribed = (competitionId, teamId, filters) => async dispatch => {
  try {
    dispatch({ type: actionTypes.GET_SUBSCRIBED });

    const queryString = stringifyFilters(filters);
    const { data } = await axios.get(
      `${Endpoints.competitions}/${competitionId}/teams/${teamId}/athletes${queryString}`
    );

    dispatch({
      type: actionTypes.GET_SUCCESS_SUBSCRIBED,
      payload: {
        data: data.results,
        count: data.count,
        hasNext: data.next ? true : false,
        hasPrevious: data.previous ? true : false,
      },
    });
  } catch (err) {
    logger.error(err.message);
    const { response } = err;
    if (response) {
      dispatch({
        type: actionTypes.GET_FAILED_SUBSCRIBED,
        payload: { error: 'erros.text48' },
      });
    } else {
      dispatch({
        type: actionTypes.GET_FAILED_SUBSCRIBED,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};
