import styled from 'styled-components';
// import { Input as TemplatedInput } from 'components/template';

export const Container = styled.div`
  display: flex;
  padding: 0.8rem 20px;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`;

export const InputContainer = styled.div`
  input {
    background-color: white !important;
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
  }

  svg {
    top: 11px !important;
  }
`;

export const MenuContainer = styled.div`
  div {
    border: 1px solid #bdbdbd;
    background-color: #fff;
    font-size: 12px;
    font-weight: 500;
    padding: 0.65rem 0.95rem;
    margin-top: 1px !important;
  }
`;

export const Checkboxes = styled.div`
  display: flex;
  gap: 20px;
`;
