import styled from 'styled-components';
import Variables from 'variables.js';

import { Card as DefaultCard, Button as DefaultButton } from 'components/template';
import { FaFolder as FolderIcon } from 'react-icons/fa'

export const Card = styled(DefaultCard)`
  box-shadow: none !important;
  height: 75vh;
  padding: 0;
  align-items: center;
  justify-content: center;  
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Icon = styled.div`
  border-radius: 50%;
  padding: 9px 10px 5px;
  background-color: ${Variables.light};
  font-size: x-large;
  color: ${Variables.lightGrey};
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImportIcon = styled(FolderIcon)`  
  font-size: 100px;
`;

export const ImportSubTitle = styled.div`  
  color: ${Variables.lighterGrey};
  font-size: large;
  margin: 20px 0;
`;

export const Button = styled(DefaultButton)`
  width: 300px;
`;