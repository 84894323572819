import React from 'react';

import * as S from './styles';

const FormFroup = ({ children, title }) => {
    return <S.Container>
        <h3>{title}</h3>
        <S.FormGroup>
            {children}
        </S.FormGroup>
    </S.Container>;
};

export default FormFroup;