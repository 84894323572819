import React from 'react';
import styles from './TransferMarket.module.scss';
import { useTranslation } from 'react-i18next';
import { TopBar } from 'components/template';
import { FaExchangeAlt } from 'react-icons/fa';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import MyAds from './MyAds/MyAds';
import AvailableAthletes from './AvailableAthletes/AvailableAthletes';
import MyNegotiations from './MyNegotiations/MyNegotiations';
import { useSelector } from 'react-redux';
import DesiredAthletes from './DesiredAthletes/DesiredAthletes';

function TransferMarket() {
  const { t } = useTranslation();
  const baseURL = `/transfer-market`;
  const notifications = useSelector(state => state.notifications);
  const user = useSelector(state => state.user.data);
  const newTransferMarketMessages = notifications?.data?.newTransferMarketMessages ?? false;

  const items = [];

  if (user?.organization?.id?.type !== 'agency') {
    items.push({
      label: t('club.box28'),
      link: `${baseURL}`,
      component: <AvailableAthletes />,
    });
  }

  items.push(
    {
      label: t('transferMarket.advancedSearch.desiredAthletes'),
      link: `${baseURL}/desired-athletes`,
      component: <DesiredAthletes />,
    },)

  items.push(
    {
      label: t('club.box29'),
      link: `${baseURL}/my-negotiations`,
      component: <MyNegotiations />,
      className: newTransferMarketMessages ? styles.negotiationNotification : '',
    },
    {
      label: t('club.box27'),
      link: `${baseURL}/my-ads`,
      component: <MyAds />,
    }
  );  

  const routes = items.map(item => <Route key={item.label} exact path={item.link} render={() => item.component} />);

  return (
    <div className={styles.container}>
      <div className={styles.containerFlex}>
        <TopBar
          title={t('proj.linkMercadoTransferencia')}
          subtitle={t('proj.subMercadoTransferencia')}
          icon={<FaExchangeAlt />}
          className={styles.TopBar}
          iconBgColor={'#F5A623'}
        />
        <NavigationBar items={items} className={styles.TopNavigation} />
        <div className={styles.content}>
          <Switch>
            {routes}
            <Redirect to={baseURL} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default withRouter(TransferMarket);
