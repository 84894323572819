import React, { useState, useEffect, useRef } from 'react';
import { isEqual } from 'lodash/lang'

import { Radio } from 'components/template';
import styles from './RadioContainer.module.scss';

const RadioContainer = ({ options, setFilter, value, name }) => {

    const [checkedItem, setCheckedItem] = useState(value);
    const mounted = useRef(false);

    useEffect(() => {
        if (!isEqual(checkedItem, value)) {
            setCheckedItem(value);
        }

    }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            setFilter(checkedItem);
        }
    }, [checkedItem]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (event) => {
        setCheckedItem(event.target.value);
    };

    return (
        <>
            {options && options.map(item =>
            (
                <Radio
                    key={item.value}
                    value={item.value}
                    onChange={handleChange}
                    label={item.label}
                    name={name}
                    checked={checkedItem === item.value}
                    className={styles.radio}
                    dark
                />
            )
            )}
        </>
    );
};

export default RadioContainer;
