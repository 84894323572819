import React from 'react';
import * as S from './styles';
import Variables from 'variables.js';

export default function AreaModel({ fieldColor }) {
  const areas = Array.from({ length: 18 }, (_, i) => i + 1);

  const getColorFromFieldColor = () => {
    switch (fieldColor) {
      case Variables.green3:
        return Variables.green1;
      case Variables.darkPurple2:
        return Variables.lightPurple2;
      default:
        return Variables.gray2;
    }
  };
  const color = getColorFromFieldColor();

  return (
    <>
      <S.LineHModelArea left="33.33%" color={color} />
      <S.LineHModelArea left="66.66%" color={color} />
      <S.LineWModelArea top="16.67%" color={color} />
      <S.LineWModelArea top="33.33%" color={color} />
      <S.LineWModelArea top="66.67%" color={color} />
      <S.LineWModelArea top="83.33%" color={color} />
      {areas.map((area, i) => {
        //para entender a conta mirabolante aqui só no teste de mesa, mas pode confiar que da certo.
        let lineH = Math.ceil(area / 3);
        lineH = (lineH - 7) * -1;
        let lineW = area % 3;
        lineW = lineW === 0 ? 3 : lineW;
        const top = lineH * 16.66 - 8.33;
        const left = lineW * 33.33 - 16.67;
        return (
          <S.NumberModelArea top={`${top}%`} left={`${left}%`} color={color} key={i}>
            {area.toString()}
          </S.NumberModelArea>
        );
      })}
    </>
  );
}
