import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';

import styles from './TopBar2.module.scss';
import { Avatar } from 'components/template';
import ArrowBack from 'assets/ico/arrow_back.svg';

function TopBar2({ title, subtitle, img, pageBack, style, season }) {
  return (
    <div className={styles.topBar} style={style ?? {}}>
      {pageBack !== '' && (
        <span className={styles.pageBack}>
          <Link className={styles.link} to={pageBack}>
            <img src={ArrowBack} alt="arrow back" />
          </Link>
        </span>
      )}
      <div className={styles.titleDiv}>
        <Avatar className={styles.avatar} noBorder img={img ?? ''} name={title} />
        <div>
          <h1 className={styles.title}>{title} {season && `(${season})`}</h1>
          {subtitle && (
            <>
              <br />
              <span className={styles.subtitle}>{subtitle}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

TopBar2.defaultProps = {
  img: null,
  pageBack: '',
};

TopBar2.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  img: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  pageBack: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  season: PropTypes.string,
};

export default withRouter(TopBar2);
