import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/template';
import styles from './FieldList.module.scss';
import FieldItem from './FieldItem/FieldItem';
import { useTranslation } from 'react-i18next';

const FieldList = ({ fields, setFields, saveEvaluation, showFieldsFeedback, onEditFinish }) => {
  const { t } = useTranslation();
  const handleSetField = useCallback(
    data => {
      setFields(f => {
        const newData = [...f];

        newData.forEach((item, j) => {
          if (item.title === data.title) {
            newData[j] = data;
          }
        });

        return newData;
      });
    },
    [setFields]
  );

  const content = useMemo(() => {
    return fields
      ? fields.map((field, i) => {
          return (
            <FieldItem
              key={field.title}
              setField={handleSetField}
              showFieldsFeedback={showFieldsFeedback ?? true}
              enableFieldsFeedbackUpdate={setFields !== undefined}
              onEditFinish={onEditFinish}
              field={field}
              index={i + 1}
            />
          );
        })
      : '';
  }, [fields, handleSetField, showFieldsFeedback, onEditFinish, setFields]);

  const saveButton = saveEvaluation ? (
    <div className={styles.buttons}>
      <Button onClick={saveEvaluation} className={styles.saveButton} color="green" rounded smaller>
        {t('dialog_content.save')}
      </Button>
      {/* <IconButton icon={<FaEllipsisV />} /> */}
    </div>
  ) : (
    ''
  );

  return (
    <>
      {/* {saveButton} */}
      {content}
      {saveButton}
    </>
  );
};

FieldList.propTypes = {
  fields: PropTypes.instanceOf(Object).isRequired,
  //setFields: PropTypes.func.isRequired,
};

export default FieldList;
