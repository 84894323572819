import Endpoints from 'endpoints';
import axios from 'api';
import ErrorMessages from 'shared/ErrorMessages';
import { stringifyFilters } from 'shared/utility';

export const actionTypes = {
  UPDATE_ATHLETE_LISTS: 'athletes/UPDATE_ATHLETE_LISTS',
  GET_ATHLETES: 'athletes/GET',
  GET_ATHLETES_SUCCESS: 'athletes/GET_SUCCESS',
  GET_ATHLETES_FAIL: 'athletes/GET_FAIL',
  GET_ATHLETE_TRANSFERS: 'athlete/transfers/GET',
  GET_ATHLETE_TRANSFERS_SUCCESS: 'athlete/transfers/GET_SUCCESS',
  GET_ATHLETE_TRANSFERS_FAIL: 'athlete/transfers/GET_FAIL',
  GET_ATHLETE_CONTRACTS: 'athlete/contracts/GET',
  GET_ATHLETE_CONTRACTS_SUCCESS: 'athlete/contracts/GET_SUCCESS',
  GET_ATHLETE_CONTRACTS_FAIL: 'athlete/contracts/GET_FAIL',
  GET_ATHLETE_HISTORICAL: 'athlete/historical/GET',
  GET_ATHLETE_HISTORICAL_SUCCESS: 'athlete/historical/GET_SUCCESS',
  GET_ATHLETE_HISTORICAL_FAIL: 'athlete/historical/GET_FAIL',
  GET_ATHLETE_MATCHES: 'athlete/matches/GET',
  GET_ATHLETE_MATCHES_SUCCESS: 'athlete/matches/GET_SUCCESS',
  GET_ATHLETE_MATCHES_FAIL: 'athlete/matches/GET_FAIL',
  GET_ATHLETE_FUTURE_MATCHES: 'athlete/matches/future/GET',
  GET_ATHLETE_FUTURE_MATCHES_SUCCESS: 'athlete/matches/future/GET_SUCCESS',
  GET_ATHLETE_FUTURE_MATCHES_FAIL: 'athlete/matches/future/GET_FAIL',
  UPDATE_ATHLETE: 'athlete/UPDATE_ATHLETE',
  UPDATE_ATHLETE_SUCCESS: 'athlete/UPDATE_ATHLETE_SUCCESS',
  UPDATE_ATHLETE_FAIL: 'athlete/UPDATE_ATHLETE_FAIL',
};

const initialState = {
  athletes: {
    loading: false,
    error: null,
    data: [],
    hasNext: true,
    hasPrevious: false,
    count: 0,
  },
  athleteTransfers: {
    loading: false,
    error: null,
    data: [],
  },
  athleteContracts: {
    loading: false,
    error: null,
    data: [],
  },
  athleteHistorical: {
    loading: false,
    error: null,
    data: [],
  },
  athleteMatches: {
    loading: false,
    error: null,
    data: {
      count: 0,
      data: [],
      hasNext: true,
      hasPrevious: false,
    },
  },
  athleteFutureMatches: {
    loading: false,
    error: null,
    data: {
      count: 0,
      data: [],
      hasNext: true,
      hasPrevious: false,
    },
  },
  athleteUpdate: {
    loading: false,
    error: null,
    data: {},
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // athletes
    case actionTypes.GET_ATHLETES:
      return {
        ...state,
        athletes: {
          ...initialState.athletes,
          loading: true,
        },
      };
    case actionTypes.GET_ATHLETES_SUCCESS:
      return {
        ...state,
        athletes: {
          ...state.athletes,
          loading: false,
          data: action.payload.data,
          hasNext: action.payload.hasNext,
          hasPrevious: action.payload.hasPrevious,
          count: action.payload.count,
          error: null,
        },
      };
    case actionTypes.GET_ATHLETES_FAIL:
      return {
        ...state,
        athletes: {
          ...state.athletes,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_ATHLETE_TRANSFERS:
      return {
        ...state,
        athleteTransfers: {
          ...state.athleteTransfers,
          loading: true,
          error: null,
        },
      };
    case actionTypes.GET_ATHLETE_TRANSFERS_SUCCESS:
      return {
        ...state,
        athleteTransfers: {
          ...state.athleteTransfers,
          loading: false,
          error: null,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_ATHLETE_TRANSFERS_FAIL:
      return {
        ...state,
        athleteTransfers: {
          ...state.athleteTransfers,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_ATHLETE_HISTORICAL:
      return {
        ...state,
        athleteHistorical: {
          ...state.athleteHistorical,
          loading: true,
          error: null,
        },
      };
    case actionTypes.GET_ATHLETE_HISTORICAL_SUCCESS:
      return {
        ...state,
        athleteHistorical: {
          ...state.athleteHistorical,
          loading: false,
          error: null,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_ATHLETE_HISTORICAL_FAIL:
      return {
        ...state,
        athleteHistorical: {
          ...state.athleteHistorical,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_ATHLETE_MATCHES:
      return {
        ...state,
        athleteMatches: {
          ...state.athleteMatches,
          loading: true,
          error: null,
        },
      };
    case actionTypes.GET_ATHLETE_MATCHES_SUCCESS:
      return {
        ...state,
        athleteMatches: {
          ...state.athleteMatches,
          loading: false,
          error: null,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_ATHLETE_MATCHES_FAIL:
      return {
        ...state,
        athleteMatches: {
          ...state.athleteMatches,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_ATHLETE_FUTURE_MATCHES:
      return {
        ...state,
        athleteFutureMatches: {
          ...state.athleteFutureMatches,
          loading: true,
          error: null,
        },
      };
    case actionTypes.GET_ATHLETE_FUTURE_MATCHES_SUCCESS:
      return {
        ...state,
        athleteFutureMatches: {
          ...state.athleteFutureMatches,
          loading: false,
          error: null,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_ATHLETE_FUTURE_MATCHES_FAIL:
      return {
        ...state,
        athleteFutureMatches: {
          ...state.athleteFutureMatches,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_ATHLETE_CONTRACTS:
      return {
        ...state,
        athleteContracts: {
          ...state.athleteContracts,
          loading: true,
          error: null,
        },
      };
    case actionTypes.GET_ATHLETE_CONTRACTS_SUCCESS:
      return {
        ...state,
        athleteContracts: {
          ...state.athleteContracts,
          loading: false,
          error: null,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_ATHLETE_CONTRACTS_FAIL:
      return {
        ...state,
        athleteContracts: {
          ...state.athleteContracts,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.UPDATE_ATHLETE_LISTS:
      const vTest = state?.athletes?.data ?? [];
      return {
        ...state,
        athletes: {
          ...state.athletes,
          data: vTest.map(athlete => {
            if (athlete.id === action.payload.athleteId) {
              return {
                ...athlete,
                lists: action.payload.lists,
                counts: { ...athlete.counts, lists: action.payload.lists.length },
              };
            } else {
              return athlete;
            }
          }),
        },
      };
    case actionTypes.UPDATE_ATHLETE:
      return {
        ...state,
        athleteUpdate: {
          ...state.athleteUpdate,
          loading: true,
          error: null,
        },
      };
    case actionTypes.UPDATE_ATHLETE_SUCCESS:
      return {
        ...state,
        athleteUpdate: {
          ...state.athleteUpdate,
          loading: false,
          error: null,
          data: action.payload.data,
        },
      };
    case actionTypes.UPDATE_ATHLETE_FAIL:
      return {
        ...state,
        athleteUpdate: {
          ...state.athleteUpdate,
          loading: false,
          error: action.payload.error,
        },
      };

    default:
      return state;
  }
}

let axiosGetAthletesController;
export const getAthletes = filters => async dispatch => {
  dispatch({ type: actionTypes.GET_ATHLETES });
  const afilters = { ...filters };
  const url = afilters.custom_athletes === 'custom' ? Endpoints.customAthletes : Endpoints.athletes;
  delete afilters.custom_athletes;
  try {
    if ((afilters.lists != null && afilters.lists.length > 0) || afilters.watched !== true) {
      delete afilters.watched;
    }
    const queryString = stringifyFilters(afilters);

    if (axiosGetAthletesController) {
      axiosGetAthletesController.abort();
    }
    axiosGetAthletesController = new AbortController();
    const { data } = await axios.get(`${url}${queryString}`, { signal: axiosGetAthletesController.signal });
    axiosGetAthletesController = null;
    dispatch({
      type: actionTypes.GET_ATHLETES_SUCCESS,
      payload: {
        data: data.results,
        hasNext: data.next ? true : false,
        hasPrevious: data.previous ? true : false,
        count: data.count,
      },
    });
  } catch (err) {
    if (err?.name === 'CanceledError') {
      //requisição cancelada
    } else {
      const { response } = err;

      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_ATHLETES_FAIL,
          payload: { error: 'erros.text13' },
        });
      } else if (response?.status === 401) {
        dispatch({
          type: actionTypes.GET_ATHLETES_FAIL,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_ATHLETES_FAIL,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  }
};

export const countAthletesInTransferMarket = async filters => {
  const afilters = { ...filters };
  const url = afilters.custom_athletes === 'custom' ? Endpoints.customAthletes : Endpoints.athletes;
  delete afilters.custom_athletes;
  try {
    if ((afilters.lists != null && afilters.lists.length > 0) || afilters.watched !== true) {
      delete afilters.watched;
    }
    afilters.transfermarket = true;
    const queryString = stringifyFilters(afilters);

    let extra;
    if (axiosGetAthletesController) {
      extra = { signal: axiosGetAthletesController.signal };
    }
    const { data } = await axios.get(`${url}${queryString}`, extra);
    return data.count;
  } catch { }
  return 0;
};

export const getAthleteHistorical = (id, filters) => async dispatch => {
  dispatch({ type: actionTypes.GET_ATHLETE_HISTORICAL });
  const url = Endpoints.athletes;

  try {
    const queryString = stringifyFilters(filters);
    const { data } = await axios.get(`${url}/${id}/historical${queryString}`);

    dispatch({
      type: actionTypes.GET_ATHLETE_HISTORICAL_SUCCESS,
      payload: {
        data: data.results,
      },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_ATHLETE_HISTORICAL_FAIL,
        payload: { error: 'erros.text14' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_ATHLETE_HISTORICAL_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else {
      dispatch({
        type: actionTypes.GET_ATHLETE_HISTORICAL_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};

export const getAthleteMatches = (id, filters) => async dispatch => {
  dispatch({ type: actionTypes.GET_ATHLETE_MATCHES });
  const url = Endpoints.athletes;

  try {
    const queryString = stringifyFilters(filters);
    const { data } = await axios.get(`${url}/${id}/matches${queryString}`);

    dispatch({
      type: actionTypes.GET_ATHLETE_MATCHES_SUCCESS,
      payload: {
        data: {
          count: data.count,
          data: data.results,
          hasNext: data.next ? true : false,
          hasPrevious: data.previous ? true : false,
        },
      },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_ATHLETE_MATCHES_FAIL,
        payload: { error: 'erros.text15' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_ATHLETE_MATCHES_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else {
      dispatch({
        type: actionTypes.GET_ATHLETE_MATCHES_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};

export const getAthleteFutureMatches = (id, filters) => async dispatch => {
  dispatch({ type: actionTypes.GET_ATHLETE_FUTURE_MATCHES });
  const url = Endpoints.athletes;

  try {
    const queryString = stringifyFilters(filters);
    const { data } = await axios.get(`${url}/${id}/matches${queryString}`);

    dispatch({
      type: actionTypes.GET_ATHLETE_FUTURE_MATCHES_SUCCESS,
      payload: {
        data: {
          count: data.count,
          data: data.results,
          hasNext: data.next ? true : false,
          hasPrevious: data.previous ? true : false,
        },
      },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_ATHLETE_FUTURE_MATCHES_FAIL,
        payload: { error: 'erros.text15' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_ATHLETE_FUTURE_MATCHES_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else {
      dispatch({
        type: actionTypes.GET_ATHLETE_FUTURE_MATCHES_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};

export const getAthleteContracts = (id, filters) => async dispatch => {
  dispatch({ type: actionTypes.GET_ATHLETE_CONTRACTS });
  const url = Endpoints.athletes;

  try {
    const queryString = stringifyFilters(filters);

    const { data } = await axios.get(`${url}/${id}/contracts${queryString}`);

    dispatch({
      type: actionTypes.GET_ATHLETE_CONTRACTS_SUCCESS,
      payload: {
        data: data.results,
      },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_ATHLETE_CONTRACTS_FAIL,
        payload: { error: 'erros.text16' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_ATHLETE_CONTRACTS_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else {
      dispatch({
        type: actionTypes.GET_ATHLETE_CONTRACTS_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};

export const getAthleteTransfers = (id, filters) => async dispatch => {
  dispatch({ type: actionTypes.GET_ATHLETE_TRANSFERS });
  const url = Endpoints.athletes;

  try {
    const queryString = stringifyFilters(filters);

    const { data } = await axios.get(`${url}/${id}/transfers${queryString}`);

    dispatch({
      type: actionTypes.GET_ATHLETE_TRANSFERS_SUCCESS,
      payload: {
        data: data.results,
      },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_ATHLETE_TRANSFERS_FAIL,
        payload: { error: 'erros.text17' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_ATHLETE_TRANSFERS_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else {
      dispatch({
        type: actionTypes.GET_ATHLETE_TRANSFERS_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};

export const updateLists = (lists, athleteId) => async dispatch => {
  dispatch({
    type: actionTypes.UPDATE_ATHLETE_LISTS,
    payload: {
      lists,
      athleteId,
    },
  });
};

export const editAthlete = athlete => async dispatch => {
  dispatch({ type: actionTypes.UPDATE_ATHLETE });

  const url = Endpoints.athletes;

  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const formData = new FormData();
    Object.keys(athlete).forEach(key => {
      if (athlete[key] !== null) {
        formData.append(key, athlete[key]);
      }
    });

    const data = await axios.patch(`${url}/${athlete.id}`, formData, config);

    dispatch({
      type: actionTypes.UPDATE_ATHLETE_SUCCESS,
      payload: {
        data: data,
      },
    });

    return data;
  } catch (err) {
    dispatch({
      type: actionTypes.UPDATE_ATHLETE_FAIL,
      payload: { error: 'erros.text18' },
    });
  }
};

export const addAthlete = athlete => async dispatch => {
  dispatch({ type: actionTypes.UPDATE_ATHLETE });
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const formData = new FormData();
    Object.keys(athlete).forEach(key => {
      if (athlete[key] !== null) {
        formData.append(key, athlete[key]);
      }
    });

    const data = await axios.post(Endpoints.customAthletes, formData, config);

    dispatch({
      type: actionTypes.UPDATE_ATHLETE_SUCCESS,
      payload: {
        data: data,
      },
    });

    return data;
  } catch (err) {
    dispatch({
      type: actionTypes.UPDATE_ATHLETE_FAIL,
      payload: { error: 'erros.text18' },
    });
  }
};
