import React from 'react';
import { Card } from 'components/template';
import HorizontalBarChart from './HorizontalBarChart/HorizontalBarChart';
import BarChartByYear from './BarCharByYear/BarCharByYear';
import DonutChart from './DonutChart/DonutChart';
import styles from './Charts.module.scss';
import { useTranslation } from 'react-i18next';
const Charts = (data) => {
    const { t } = useTranslation();
    return <div className={styles.chartContainer}>
        {data?.data[0] ? <><Card className={styles.card}>
            <div className={styles.header}>
                <div className={styles.number}>{data?.data[0]?.total_count_evaluations}</div>
                <div className={styles.text}>{t('charts.evaluations')}</div>
            </div>
            <div className={styles.content}>
                <h1>{t('charts.byAnalist')}</h1>
                <DonutChart data={data?.data[0]} />
            </div>
        </Card> 
        <Card className={styles.card}>
            <div className={styles.header}>
                <div className={styles.number}>{data?.data[1]?.total_count_athletes}</div>
                <div className={styles.text}>{t('charts.athletes')}</div>                
            </div>
            <div className={styles.content}>
                <h1>{t('charts.byPosition')}</h1>
                <HorizontalBarChart data={data?.data[1]}/>
            </div>
        </Card>
        <Card className={styles.card}>
            <div className={styles.header}>
                <div className={styles.number}>{data?.data[2]?.total_count_recommendation}</div>
                <div className={styles.text}>{t('charts.forContract')}</div>
            </div>
            <div className={styles.content}>
                <h1>{t('charts.byYear')}</h1>
                <BarChartByYear data={data?.data[2]} />
            </div>
        </Card></> : <h3>{t('charts.noData')}</h3>}
    </div>;
}

export default Charts;