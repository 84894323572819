import Endpoints from 'endpoints';
import axios from 'api';
import ErrorMessages from 'shared/ErrorMessages';
import { stringifyFilters } from 'shared/utility';

export const actionTypes = {
  GET_TRANSFER_TEAMS: 'transfer_teams/GET',
  GET_TRANSFER_TEAMS_SUCCESS: 'transfer_teams/GET_SUCCESS',
  GET_TRANSFER_TEAMS_FAIL: 'transfer_teams/GET_FAIL',
};

const initialState = {
  teams: {
    loading: false,
    error: null,
    data: [],
    hasNext: true,
    hasPrevious: false,
    count: 0,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // teams
    case actionTypes.GET_TRANSFER_TEAMS:
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: true,
        },
      };
    case actionTypes.GET_TRANSFER_TEAMS_SUCCESS:
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: false,
          data: action.payload.data,
          hasNext: action.payload.hasNext,
          hasPrevious: action.payload.hasPrevious,
          count: action.payload.count,
        },
      };
    case actionTypes.GET_TRANSFER_TEAMS_FAIL:
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
}

export const getTransferMarketTeams = filters => async dispatch => {
  dispatch({ type: actionTypes.GET_TRANSFER_TEAMS });
  const url = Endpoints.teams;

  try {
    filters['with_active_organization'] = true;
    const queryString = stringifyFilters(filters);

    const { data } = await axios.get(`${url}${queryString}`);
    dispatch({
      type: actionTypes.GET_TRANSFER_TEAMS_SUCCESS,
      payload: {
        data: data.results ?? data,
        hasNext: data.next ? true : false,
        hasPrevious: data.previous ? true : false,
        count: data.count,
      },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_TRANSFER_TEAMS_FAIL,
        payload: { error: 'erros.text39' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_TRANSFER_TEAMS_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else {
      dispatch({
        type: actionTypes.GET_TRANSFER_TEAMS_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};
