/*eslint-disable eqeqeq*/
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import * as S from './styles';
import { MdOutlineArrowBack } from 'react-icons/md';
import { Button, Spinner } from 'components/template';
import CenteredLoader from 'components/template/CenteredLoader';
import { useTranslation } from 'react-i18next';
import { Creators } from 'store/ducks/projects';
import { Creators as layoutActions } from 'store/ducks/layout';
import ShadowTeamConsts from './consts';
import Variables from 'variables';
import LineUp from './components/lineup';
import GenerateReportButton from './components/buttons/generateReport';
import ShadowTeamInitialInfo from './components/leftPanel/ShadowTeamInitialInfo';
import ShadowTeamPositions from './components/leftPanel/ShadowTeamPositions';
import ShadowTeamSchemas from './schemas';
import ShadowTeamPositionInfo from './components/leftPanel/ShadowTeamPositionInfo';
import ShadowTeamAddAthletes from './components/leftPanel/ShadowTeamAddAthletes';
import orderShadowTeamPositions from './helpers';

function ShadowTeamLineUp({ history }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { project_id, id: shadow_team_id } = useParams();
  const loggedUser = useSelector(state => state.user.data);
  const project = useSelector(state => state.project);
  const projectData = project.data;
  const shadowTeamsData = project.shadowTeamsData;

  const [isEditing, setIsEditing] = useState(project_id && shadow_team_id !== '0');
  const [newShadowTeamData, setNewShadowTeamData] = useState({});
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [athletesWorkflow, setAthletesWorkflow] = useState(projectData?.workflow ?? []);
  const [newAthletes, setNewAthletes] = useState((isEditing ? shadowTeamsData?.athletes : []) ?? []);
  const [positions, setPositions] = useState((isEditing ? shadowTeamsData?.positions : []) ?? []);
  const [newPositions, setNewPositions] = useState((isEditing ? shadowTeamsData?.positions : []) ?? []);
  const [saveButtonDisable, setSaveButtonDisable] = useState(false);
  const [schema, setSchema] = useState(shadowTeamsData?.schema ?? ShadowTeamConsts.initialSchema);
  const [colorfield, setColorfield] = useState(shadowTeamsData?.color_field ?? ShadowTeamConsts.initialColorField);
  const [modelfield, setModelfield] = useState(shadowTeamsData?.modelfield ?? ShadowTeamConsts.initialModelField);

  const [leftMenuShowing, setLeftMenuShowing] = useState(
    shadow_team_id === '0' ? 'ShadowTeamInitialInfo' : 'ShadowTeamPositionsInfo'
  );

  const loggedUserPermission =
    (projectData?.users ?? []).find(projectUser => projectUser?.user?.id == loggedUser.id)?.permission ??
    'Visualizador';
  const projectAthletes = [];
  if (projectData?.workflow) {
    projectData.workflow.forEach(item => {
      projectAthletes.push({
        ...item.athlete,
        frame: item.frame,
      });
    });
  }

  const getShadowTeamBasePathname = () => {
    return {
      pathname: `/projetos/${project_id}/workflow/time-sombra`,
      state: { id: project_id },
    };
  };

  const getShadowTeamPathname = id => {
    return {
      pathname: `/projetos/${project_id}/time-sombra/${id}`,
      state: { project_id, id },
    };
  };

  const processNewPositions = positions => {
    setNewPositions(orderShadowTeamPositions(positions));
  };

  useEffect(() => {
    if (isEditing) {
      dispatch(Creators.getShadowTeam(project_id, shadow_team_id));
    } else {
      setNewShadowTeamData({
        color_field: ShadowTeamConsts.initialColorField,
        modelfield: ShadowTeamConsts.initialModelField,
        project: project_id,
        name: '',
        schema: ShadowTeamConsts.initialSchema,
        athletes: [],
      });
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!shadow_team_id || shadow_team_id === '0') {
      dispatch(Creators.resetShadowTeam());
    }
  }, [shadow_team_id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if (Object.keys(newShadowTeamData).length === 0) {
    setNewShadowTeamData({
      ...shadowTeamsData,
    });
    // }

    setPositions(shadowTeamsData?.positions ?? []);
    setNewAthletes(shadowTeamsData?.athletes ?? []);
  }, [shadowTeamsData]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (projectData.workflow) setAthletesWorkflow(projectData.workflow);
  }, [projectData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (shadowTeamsData.id) {
      processNewPositions(positions);
    }
  }, [positions]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSchema(newShadowTeamData.schema ?? ShadowTeamConsts.initialSchema);
  }, [newShadowTeamData.schema]);

  useEffect(() => {
    setColorfield(newShadowTeamData.color_field ?? ShadowTeamConsts.initialColorField);
  }, [newShadowTeamData.color_field]);

  useEffect(() => {
    setModelfield(newShadowTeamData.modelfield ?? ShadowTeamConsts.initialModelField);
  }, [newShadowTeamData.modelfield]);
  useEffect(() => {
    if (selectedPosition) setSelectedPosition(newPositions.find(i => i.id === selectedPosition.id));
  }, [newPositions]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectAthlete = athlete => {
    setNewAthletes([
      ...newAthletes,
      {
        position: selectedPosition.id,
        positionOrder: newAthletes.length,
        color: null,
        athlete: athlete.athlete,
      },
    ]);
  };
  const handleUnselectAthlete = athlete => {
    // setNewAthletes(newAthletes.filter(i => i.athlete.id !== athlete?.athlete?.id && i.position !== selectedPosition.id));
    const index = newAthletes.indexOf(athlete);
    if (index >= 0) setNewAthletes([...newAthletes.slice(0, index), ...newAthletes.slice(index + 1)]);
  };
  const handleAtheleteReorder = athletes => {
    setNewAthletes([
      ...newAthletes.filter(i => i.position !== selectedPosition.id),
      ...athletes.map((athlete, index) => ({
        ...athlete,
        positionOrder: index,
      })),
    ]);
  };
  const handleAtheleteUpdate = (athlete, change) => {
    setNewAthletes([
      ...newAthletes.map(i =>
        i.position === athlete.position && i.athlete.id === athlete?.athlete?.id
          ? {
              ...i,
              ...change,
            }
          : i
      ),
    ]);
  };

  const handleSelectPosition = position => {
    setSelectedPosition(position);
    setLeftMenuShowing('ShadowTeamPositionInfo');
  };

  const handleUnselectPosition = () => {
    setSelectedPosition(null);
    setLeftMenuShowing('ShadowTeamPositionsInfo');
  };

  const handleChangeSchema = schema => {
    setNewShadowTeamData({
      ...newShadowTeamData,
      schema,
    });
    const newOnes = ShadowTeamSchemas.setPositions(schema, positions);
    processNewPositions(newOnes);
  };

  const handleDeletePosition = position => {
    processNewPositions(newPositions.filter(item => item.position_name !== position.position_name));
    setLeftMenuShowing('ShadowTeamPositionsInfo');
  };

  const setPosition = (position_name, data) => {
    const newOnes = newPositions.map(item =>
      item.position_name === position_name
        ? {
            ...item,
            position_name,
            ...data,
          }
        : item
    );
    processNewPositions(newOnes);
  };

  const handleUpdatePosition = (id, data) => {
    const newOnes = newPositions.map(item =>
      item.id === id
        ? {
            ...item,
            ...data,
          }
        : item
    );
    processNewPositions(newOnes);
    if (data.position_name && positions.filter(p => p.position_name === data.position_name && p.id !== id).length > 0)
      return false;
    return true;
  };

  const handleCreatePosition = () => {
    let positionName,
      counter = newPositions.length + 1;
    const currentPositions = newPositions.map(i => i.position_name);
    do {
      positionName = counter.toString();
      counter++;
      if (counter > 50)
        positionName = Math.random()
          .toString(16)
          .slice(2);
    } while (currentPositions.includes(positionName));
    const newPos = {
      position_name: positionName,
      x: 50,
      y: 50,
    };

    Creators.createShadowTeamPosition(project_id, shadow_team_id, newPos).then(result => {
      if (result.error !== undefined) {
        dispatch(
          layoutActions.showToast({
            content: <S.ToastMessage>{t(result.error)}</S.ToastMessage>,
            duration: 3000,
          })
        );
      }
      if (result.data) {
        setPositions([...newPositions, result.data]);
        handleSelectPosition(result.data);
      }
    });
  };

  const saveShadowTeam = async () => {
    const name = newShadowTeamData.name ?? '';
    if (name.length === 0) {
      dispatch(
        layoutActions.showToast({
          content: <S.ToastMessage>{t('proj.erroNome')}</S.ToastMessage>,
          duration: 3000,
        })
      );
      return false;
    }

    const schema = newShadowTeamData.schema ?? ShadowTeamConsts.initialSchema;
    if (schema.length === 0) {
      dispatch(
        layoutActions.showToast({
          content: <S.ToastMessage>{t('proj.erroSchema')}</S.ToastMessage>,
          duration: 3000,
        })
      );
      return false;
    }

    let saved = false;
    if (isEditing) {
      await Creators.updateShadowTeam(
        project_id,
        shadow_team_id,
        newShadowTeamData.color_field,
        newShadowTeamData.modelfield,
        newShadowTeamData.name,
        newShadowTeamData.schema
      ).then(result => {
        if (result.error !== undefined) {
          dispatch(
            layoutActions.showToast({
              content: <S.ToastMessage>{t(result.error)}</S.ToastMessage>,
              duration: 3000,
            })
          );
        } else {
          saved = true;
        }
      });
    } else {
      await Creators.createShadowTeam(
        project_id,
        newShadowTeamData.color_field ?? ShadowTeamConsts.initialColorField,
        newShadowTeamData.modelfield ?? ShadowTeamConsts.initialModelField,
        name,
        schema
      ).then(result => {
        if (result?.data?.id) {
          saved = result.data;
        } else if (result.error !== undefined) {
          dispatch(
            layoutActions.showToast({
              content: <S.ToastMessage>{t(result.error)}</S.ToastMessage>,
              duration: 3000,
            })
          );
        }
      });
    }
    if (saved) {
      await savePositions({ shadow_team_id: saved?.id });
      await saveAthletes({ shadow_team_id: saved?.id });
      setIsEditing(true);
    }
    return saved;
  };

  const savePositions = async data => {
    await Creators.syncShadowTeamPositions(
      project_id,
      data?.shadow_team_id ?? shadow_team_id,
      newPositions,
      positions
    ).then(async result => {
      if (result.error !== undefined) {
        dispatch(
          layoutActions.showToast({
            content: <S.ToastMessage>{t(result.error)}</S.ToastMessage>,
            duration: 3000,
          })
        );
      }
      if (result.data) {
        setPositions(result.data);
      }
    });
  };

  const saveAthletes = async data => {
    await Creators.syncShadowTeamAthletes(
      project_id,
      data?.shadow_team_id ?? shadow_team_id,
      newAthletes,
      shadowTeamsData.athletes
    ).then(async result => {
      if (result.error !== undefined) {
        dispatch(
          layoutActions.showToast({
            content: <S.ToastMessage>{t(result.error)}</S.ToastMessage>,
            duration: 3000,
          })
        );
      }
      if (result.data) {
        setNewAthletes(result.data);
        const positions = newPositions.map(i => i.id);
        await newAthletes.forEach(async a => {
          if (!positions.includes(a.position))
            await Creators.removeAthleteShadowTeam(project_id, data?.shadow_team_id ?? shadow_team_id, a.id, a.id);
        });
      }
    });
  };

  const saveButtonOnClick = async () => {
    const data = await saveShadowTeam();
    if (data) {
      const shadowTeamUrl = getShadowTeamPathname(data?.id ?? shadow_team_id);
      history.push(shadowTeamUrl);
      setLeftMenuShowing('ShadowTeamPositionsInfo');
      if (data?.id) dispatch(Creators.getShadowTeam(project_id, data?.id));
    }
  };

  const getAthletesIdsForReport = async () => {
    const athletesIds = [];
    newAthletes.forEach(sta => {
      const athleteId = sta.athlete?.id;
      if (
        athleteId &&
        athletesIds.indexOf(athleteId) === -1 /* && (selectedPosition == null || sta.position == selectedPosition.id)*/
      ) {
        athletesIds.push(athleteId);
      }
    });
    if (athletesIds.length > 0) {
      setSaveButtonDisable(true);
      try {
        if (
          shadowTeamsData.schema != newShadowTeamData.schema ||
          shadowTeamsData.color_field != newShadowTeamData.color_field ||
          shadowTeamsData.modelfield != newShadowTeamData.modelfield ||
          shadowTeamsData.name != newShadowTeamData.name ||
          newShadowTeamData.athletes.length != newAthletes.length
        ) {
          if (await saveShadowTeam()) {
            return athletesIds;
          }
        } else {
          return athletesIds;
        }
      } finally {
        setSaveButtonDisable(false);
      }
    } else {
      dispatch(
        layoutActions.showToast({
          content: <S.ToastMessage>{t('proj.text12')}</S.ToastMessage>,
          duration: 3000,
        })
      );
    }
    return [];
  };

  const closeShadowTeam = () => {
    Creators.resetShadowTeam();
    history.push(getShadowTeamBasePathname());
  };

  if (project.loading) {
    return <CenteredLoader />;
  }

  return (
    <S.GlobalContainer>
      <S.FilterContainer softShadow bordered>
        <S.MenuFilter>
          <S.PageBackButton onClick={closeShadowTeam} noShadow>
            <MdOutlineArrowBack color={Variables.lightPurple1} />
          </S.PageBackButton>
          <S.SpanTimeSombra>{t('proj.text6')}</S.SpanTimeSombra>
        </S.MenuFilter>
        {shadow_team_id !== '0' && (
          <GenerateReportButton
            disabled={saveButtonDisable}
            positionsName={newPositions.map(position => position.position_name).join(',')}
            getAthletesIds={getAthletesIdsForReport}
          />
        )}
        {shadow_team_id !== '0' && loggedUserPermission != 'Visualizador' && (
          <Button
            onClick={async () => {
              setSaveButtonDisable(true);
              try {
                await saveButtonOnClick();
              } finally {
                setSaveButtonDisable(false);
              }
            }}
            rounded
            smaller
            color={saveButtonDisable ? 'transparent' : 'green'}
            disabled={saveButtonDisable}
            style={{
              width: '83px',
              fontSize: '14px',
              height: '35px',
              marginTop: '3px',
              fontWeight: 700,
            }}
          >
            {t('dialog_content.save')}
          </Button>
        )}
        {saveButtonDisable ? <Spinner width="60" height="40" /> : null}
      </S.FilterContainer>
      {
        <S.ContentContainer>
          <S.LeftPanelConteinar>
            <S.LeftPanel>
              {leftMenuShowing === 'ShadowTeamInitialInfo' && (
                <ShadowTeamInitialInfo
                  newShadowTeamData={newShadowTeamData}
                  setNewShadowTeamData={setNewShadowTeamData}
                  buttonSaveClick={saveButtonOnClick}
                  handleChangeSchema={handleChangeSchema}
                />
              )}
              {leftMenuShowing === 'ShadowTeamPositionsInfo' && (
                <ShadowTeamPositions
                  newShadowTeamData={newShadowTeamData}
                  setNewShadowTeamData={setNewShadowTeamData}
                  editButtonClick={() => setLeftMenuShowing('ShadowTeamInitialInfo')}
                  positions={newPositions}
                  handleSelectPosition={handleSelectPosition}
                  handleCreatePosition={handleCreatePosition}
                />
              )}
              {leftMenuShowing === 'ShadowTeamPositionInfo' && (
                <ShadowTeamPositionInfo
                  editButtonClick={() => setLeftMenuShowing('ShadowTeamInitialInfo')}
                  position={selectedPosition}
                  handleBack={handleUnselectPosition}
                  handleDeletePosition={handleDeletePosition}
                  handleUpdatePosition={handleUpdatePosition}
                  handleNewAthleteClick={() => setLeftMenuShowing('ShadowTeamPositionAthletes')}
                  handleUnselectAthlete={handleUnselectAthlete}
                  handleAtheleteReorder={handleAtheleteReorder}
                  athletes={newAthletes.filter(i => i.position === selectedPosition.id)}
                  handleAtheleteUpdate={handleAtheleteUpdate}
                />
              )}
              {leftMenuShowing === 'ShadowTeamPositionAthletes' && (
                <ShadowTeamAddAthletes
                  editButtonClick={() => setLeftMenuShowing('ShadowTeamInitialInfo')}
                  handleBack={() => setLeftMenuShowing('ShadowTeamPositionInfo')}
                  position={selectedPosition}
                  athletes={athletesWorkflow}
                  athletesSelected={newAthletes.filter(i => i.position === selectedPosition.id)}
                  handleSelectAthlete={handleSelectAthlete}
                />
              )}
            </S.LeftPanel>
          </S.LeftPanelConteinar>
          <S.LineUp>
            {colorfield && (
              <LineUp
                schema={schema}
                color={colorfield}
                model={modelfield}
                positions={newPositions}
                athletes={newAthletes}
                setPosition={setPosition}
                selectedPosition={selectedPosition}
                handleSelectPosition={handleSelectPosition}
                handleUnselectPosition={handleUnselectPosition}
                disablePositions={!isEditing}
              />
            )}
          </S.LineUp>
        </S.ContentContainer>
      }
    </S.GlobalContainer>
  );
}

export default withRouter(ShadowTeamLineUp);
