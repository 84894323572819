import React, { useState } from 'react';
import styles from './ReportsProCard.module.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { IconButton } from 'components/template';
import { FiDownload } from 'react-icons/fi';
import { IoMdSettings, IoMdLock, IoMdPlay } from 'react-icons/io';
import { FaEye } from 'react-icons/fa';
import Variables from 'variables';
import { Creators } from 'store/ducks/athlete';
import { Creators as layoutActions } from 'store/ducks/layout';

export default function ReportsProCard({ report, editReportPro, athlete, user }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(false);
  const [gettingLink, setGettingLink] = useState(false);
  const userOrgId = user?.organization?.id?.id;
  const athleteAgencyId = athlete?.data?.agency?.id;
  const isOrgAgency =
    (userOrgId != null && athleteAgencyId != null && userOrgId === athleteAgencyId) || athlete?.data?.custom_athlete;
  const isOrgTeam = user?.organization?.teamId != null && user.organization.teamId === athlete?.data?.current_team?.id;

  return (
    <div className={styles.reports_pro_card_main_container}>
      {(isOrgAgency || userOrgId === 8 || isOrgTeam) && (
        <div className={styles.reports_pro_card_top_buttons} style={selected ? { zIndex: '1' } : {}}>
          <div className={styles.reports_pro_card_settings_button}>
            <IoMdSettings onClick={editReportPro} />
          </div>
          <div
            className={styles.reports_pro_card_lock_button}
            style={{ color: report.is_public ? Variables.green1 : Variables.lightPurple1 }}
          >
            {report.is_public ? (
              <>
                <FaEye />
                {t('report_pro.public')}
              </>
            ) : (
              <>
                <IoMdLock />
                {t('report_pro.only_agency')}
              </>
            )}
          </div>
        </div>
      )}
      <div
        className={styles.reports_pro_card_image_section}
        onMouseOver={() => {
          setSelected(true);
        }}
        onMouseOut={() => {
          setSelected(false);
        }}
      >
        {report.thumbnail && (
          <img src={report.thumbnail} alt="thumbnail" style={selected ? { transform: 'scale(1.1)' } : {}} />
        )}
      </div>
      <div
        className={styles.reports_pro_card_button_container}
        onClick={async () => {
          if (!gettingLink) {
            setGettingLink(true);
            try {
              const reportPro = await Creators.getReportPro(athlete.data.id, report.id);
              if (reportPro.error) {
                dispatch(
                  layoutActions.showToast({
                    content: t('erros.text8'),
                    duration: 3000,
                  })
                );
              } else {
                if (reportPro.file_type === 'pdf') {
                  window.open(reportPro.file, '_blank');
                } else {
                  window.open(reportPro.video_link, '_blank');
                }
              }
            } finally {
              setGettingLink(false);
            }
          }
        }}
      >
        {report.file_type === 'video_link' && (
          <IconButton
            disabled={gettingLink}
            className={styles.reports_pro_card_button}
            style={selected ? { zIndex: '1' } : {}}
            icon={<IoMdPlay />}
          />
        )}
        {report.file_type === 'pdf' && (
          <IconButton
            disabled={gettingLink}
            className={styles.reports_pro_card_button}
            style={selected ? { zIndex: '1' } : {}}
            icon={<FiDownload />}
          />
        )}
      </div>
      <div className={styles.reports_pro_card_info}>
        <div className={styles.reports_pro_card_title}>{report.title}</div>
        <div className={styles.reports_pro_card_group}>
          <Link to={`/agencias/${report.org.id}/atletas`}>{report.org.fullname}</Link>
        </div>
        <div className={styles.reports_pro_card_published_at}>
          {t('compe.text7')}
          {moment(report.created_at).format('DD/MM/YYYY')} - {report.views}{' '}
          {t('report_pro.access', { sufix: report.views !== 1 ? 's' : '' })}
        </div>
      </div>
    </div>
  );
}
