import React from 'react';
import Spinner from '../Spinner/Spinner';

import * as S from './styles';

const CenteredLoader = ({ size }) => {
    return (
        <S.LoaderContainer size={size}>
            <Spinner />
        </S.LoaderContainer>
    );
}

export default CenteredLoader;