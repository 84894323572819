import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getList } from 'store/ducks/monitoringV2';
import CheckboxContainer from '../CheckboxContainer/CheckboxContainer';
import CustomCollapse from '../CustomCollapse/CustomCollapse';
import { Spinner } from 'components/template';
import styles from './CheckboxContainerLists.module.scss';
import { useTranslation } from 'react-i18next';

export default function CheckboxContainerLists({ setFilter, listsIds, watched }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const list = useSelector(state => state.monitoringV2);
  const watchedListItem = list.data.find(list => list.name === '_DEFAULT_');
  const value = watched && watchedListItem ? [watchedListItem.id.toString()] : listsIds.toString().split(',');

  useEffect(() => {
    dispatch(getList());
  }, [dispatch]);

  if (list.loading) {
    return (
      <CustomCollapse openedDefault title={t('filters.lists')} key="CheckboxContainerListsLoading">
        <Spinner width="30" height="30" className={styles.spinner} />
      </CustomCollapse>
    );
  }

  if (list.data.length > 0) {
    return (
      <CustomCollapse openedDefault title={t('filters.lists')} key="CheckboxContainerListsLoaded">
        <CheckboxContainer
          setFilter={ids => {
            const watchedListItemIndex = watchedListItem ? ids.indexOf(watchedListItem.id.toString()) : -1;
            let newWatched = watchedListItemIndex >= 0 && (!watched || ids.length === 1);
            const listsIds = newWatched ? [] : ids;
            if (!newWatched && watchedListItemIndex >= 0) {
              ids.splice(watchedListItemIndex, 1);
              newWatched = null;
            }
            let listsIdsStr = listsIds.join(',');
            if (listsIdsStr[0] === ',') {
              listsIdsStr = listsIdsStr.substring(1);
            }
            setFilter(listsIdsStr, newWatched);
          }}
          value={value.filter(v => v.length > 0)}
          checkboxes={list.data.map(item => ({
            label: item.name === '_DEFAULT_' ? t('filters.lists_default') : item.name,
            value: item.id.toString(),
          }))}
        />
      </CustomCollapse>
    );
  }

  return <></>;
}
