import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { FiDownload } from 'react-icons/fi';
import { IoMdPlay } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from 'store/ducks/athlete';
import { IconButton } from 'components/template';
import styles from './ReportsPro.module.scss';


export default function SideInfoReportsPro({ athleteId }) {

    const dispatch = useDispatch();
    const reports = useSelector(state => state.athlete.reports_pro);
    const [step, setStep] = useState(0);
    const [height, setHeight] = useState('0');
    const [showCarousel, setShowCarousel] = useState(false);

    useEffect(() => {
        if (athleteId) {
            dispatch(Creators.getReportsPro(athleteId));
        }
        return () => {
            dispatch(Creators.resetReportPro());
        };
    }, [dispatch, athleteId]);

    const reportsData = [];
    if (reports?.results != null) {
        reports.results.forEach(item => {
            if (item.is_public && item.thumbnail != null) {
                reportsData.push({ id: item.id, type: item.file_type, thumbnail: item.thumbnail });
            }
        });
    }

    if (reportsData.length === 0) {
        return '';
    } else {
        if (height === '0') {
            setTimeout(() => setHeight('200px'), 200); //for transition
            if (reportsData.length > 1) {
                setTimeout(() => setShowCarousel(true), 700); //wait transition
            }
        }
    }
    const dotClasses = [styles.image_dot];
    if (step || 1) dotClasses.push(styles.selected);

    return (
        <>
            <div className={styles.carousel_container}>
                {showCarousel ? (
                    <>
                        <Carousel
                            showThumbs={false}
                            dynamicHeight={false}
                            showStatus={false}
                            emulateTouch={true}
                            autoPlay={true}
                            infiniteLoop={true}
                            interval={4000}
                            showArrows={true}
                            showIndicators={false}
                            onChange={index => setStep(index)}
                            className={styles.carousel}
                            selectedItem={step}
                        >
                            {
                                reportsData.map((item, index) => {
                                    return <img key={index} className={styles.image} alt={`club${index}`} src={item.thumbnail} />;
                                })
                            }
                        </Carousel>
                        {reportsData.map((item, index) => {
                            return (
                                <div
                                    className={styles.btnUpload}
                                    style={{ opacity: showCarousel ? '1' : '0' }}
                                    onClick={async () => {
                                        const reportPro = await Creators.getReportPro(athleteId, item.id);
                                        if (reportPro.file_type === 'pdf') {
                                            window.open(reportPro.file, '_blank');
                                        } else {
                                            window.open(reportPro.video_link, '_blank');
                                        }
                                    }}
                                >
                                    {step === index ? (
                                        <IconButton
                                            className={styles.reports_pro_card_button}
                                            icon={item.type === 'pdf' ? <FiDownload /> : <IoMdPlay />}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            );
                        })}
                    </>
                ) : (
                    <>
                        <img
                            className={styles.image}
                            alt={reportsData[0].alt ?? ''}
                            src={reportsData[0].thumbnail}
                            style={{ height: height }}
                        />
                        <div
                            className={styles.btnUpload}
                            style={{ opacity: '1' }}
                            onClick={async () => {
                                const reportPro = await Creators.getReportPro(athleteId, reportsData[0].id);
                                if (reportPro.file_type === 'pdf') {
                                    window.open(reportPro.file, '_blank');
                                } else {
                                    window.open(reportPro.video_link, '_blank');
                                }
                            }}
                        >
                            <IconButton
                                className={styles.reports_pro_card_button}
                                icon={reportsData.type === 'pdf' ? <FiDownload /> : <IoMdPlay />}
                            />
                        </div>                        
                    </>
                )}
            </div>
            <div className={styles.images_dots} style={{ opacity: showCarousel ? '1' : '0' }}>
                {showCarousel && reportsData.map((item, index) => {
                    return (
                        <div
                            className={index === step ? dotClasses.join(' ') : styles.image_dot}
                            onClick={() => setStep(index)}
                            key={item.thumbnail}
                        ></div>
                    );
                })}
            </div>
        </>
    )
}