import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './ContractsResume.module.scss';
import AthleteCard from 'components/AthleteCard';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'components/template';
import { formatDate } from 'shared/utility';

export default function ContractsResume({ athletes, linkURL = null }) {
  const { t } = useTranslation();
  const [groups, setGroups] = useState([]);
  const club = useSelector(state => state.club.clubPage);
  const contractHighRisk = club.data?.contract_high_risk ?? 9;
  const limit = new Date(new Date().setMonth(new Date().getMonth() + 7)).toISOString().split('T')[0];
  const warningRangeDivider = new Date(new Date().setMonth(new Date().getMonth() + contractHighRisk))
    .toISOString()
    .split('T')[0];
  const url = linkURL || `/meu-clube/contratos`;

  function getGroupsByAthletes(athletes) {
    return Object.values(
      athletes.reduce((acc, at) => {
        if (!at?.bid?.contract_expire) return acc;
        if (at?.bid?.contract_expire > limit) return acc;
        acc[at?.bid?.contract_expire] = at?.bid?.contract_expire;
        return acc;
      }, {})
    ).sort((a, b) => a > b);
  }

  useEffect(() => {
    if (athletes?.length) setGroups(getGroupsByAthletes(athletes));
  }, [athletes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <h3 className={styles.panelTitle}>{t('myClub.general.contractTitle')}</h3>
      {groups.map((group, index) => {
        return (
          <>
            <div key={index} className={styles.group}>
              <span>
                {t('myClub.general.expireIn')} {formatDate(group)}
              </span>
            </div>
            {athletes
              .filter(a => group === a?.bid?.contract_expire)
              .slice(0, 2)
              .map((athlete, index) => {
                return (
                  <AthleteCard
                    key={index}
                    item={athlete}
                    styleClass={[
                      styles.athleteCard,
                      group > warningRangeDivider ? styles.groupWarning : styles.groupDanger,
                    ].join(' ')}
                    hideIcon={true}
                  />
                );
              })}
            {athletes.filter(a => group === a?.bid?.contract_expire).length > 3 && (
              <div className={styles.moreAthletes}>
                + {athletes.filter(a => group === a?.bid?.contract_expire).length - 3} {t('organi.text13')}
              </div>
            )}
          </>
        );
      })}
      <Link draggable="false" to={url} className={styles.link}>
        <Button className={styles.button} rounded fluid outline color="green">
          {t('myClub.general.contractSeeMore')}
        </Button>
      </Link>
    </div>
  );
}
