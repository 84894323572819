import React, { useState } from 'react';

import { FaSearch, FaClipboardList } from 'react-icons/fa';
import { Input, Menu, SliderMenu, Button } from 'components/template';

import styles from './ActionBar.module.scss';
import { positionOptions } from 'shared/mocks';
import { useTranslation } from 'react-i18next';

export default function ActionBar({ checkedAthletes, selectedFilters, setSelectedFilters, list }) {
  const { t } = useTranslation();

  const [timeoutId, setTimeoutId] = useState(null);

  const [name, setName] = useState('');

  const handleInputChange = value => {
    clearTimeout(timeoutId);
    setName(value);
    setTimeoutId(
      setTimeout(() => {
        setSelectedFilters({ ...selectedFilters, name: value });
      }, 200)
    );
  };

  return (
    <div className={styles.actionBar}>
      <div className={styles.actionButtons}>
        <Button
          className={styles.iconButton}
          onClick={() => window.open(`/relatorios/criar?ids=${checkedAthletes.map(athlete => athlete.id).join(',')}`)}
          color="vividGreen"
          disabled={checkedAthletes.length === 0}
        >
          <div className={styles.text}>
            {t('athlete_list.tip')}
            <FaClipboardList className={styles.icon} />
          </div>
        </Button>
      </div>
      <Input
        rounded
        placeholder={t('agencies.name')}
        containerClassName={styles.inputContainer}
        onChange={e => {
          handleInputChange(e.target.value);
        }}
        icon={<FaSearch className={styles.icon} />}
        iconPosition="right"
        value={name}
      />
      <Menu
        title={t('agencies.lists')}
        largeMenu
        multiple
        rounded
        allowEmpty
        placeholder={t('agencies.lists')}
        fixedPlaceholder
        className={styles.menu}
        options={list ?? []}
        value={selectedFilters.list}
        onClear={() => {
          setSelectedFilters({ ...selectedFilters, list: [] });
        }}
        setValue={val => {
          setSelectedFilters({ ...selectedFilters, list: val });
        }}
      />
      <SliderMenu
        rounded
        title={t('athlete_card.birth')}
        className={styles.menu}
        max={Number((() => new Date())().getFullYear())}
        min={1975}
        setFilter={val => {
          const [min, max] = val;
          setSelectedFilters({ ...selectedFilters, birthYear: { min, max } });
        }}
      />
      <Menu
        title={t('athlete_list.box4')}
        largeMenu
        multiple
        rounded
        allowEmpty
        placeholder={t('athlete_list.box4')}
        fixedPlaceholder
        className={styles.menu}
        options={positionOptions.map(item => ({ label: t(`positionOptions.${item.value}`), value: item.label }))}
        value={selectedFilters.position}
        onClear={() => {
          setSelectedFilters({ ...selectedFilters, position: [] });
        }}
        setValue={val => {
          setSelectedFilters({ ...selectedFilters, position: val });
        }}
      />
      {/* <Menu
        title="Monitoramento"
        largeMenu
        multiple
        rounded
        allowEmpty
        placeholder="Monitoramento"
        fixedPlaceholder
        className={styles.menu}
        options={[]}
        value={''}
        onClear={() => { }}
        setValue={val => { }}
      />
      <Menu
        title="Avaliação"
        largeMenu
        multiple
        rounded
        allowEmpty
        placeholder="Avaliação"
        fixedPlaceholder
        className={styles.menu}
        options={[]}
        value={''}
        onClear={() => { }}
        setValue={val => { }}
      /> */}
    </div>
  );
}
