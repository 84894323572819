import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAgencies, getAgencyAthletes, getAgencyNegotiations, updateAgencyFrames } from 'store/ducks/agencies';
import { useDispatch } from 'react-redux';
import { Dialog, Input } from 'components/template';
import { Creators as layoutActions } from 'store/ducks/layout';
import { useTranslation } from 'react-i18next';
import Helpers from 'shared/helpers';
import styles from './Negotiations.module.scss';
import Kanban from './Kanban';
import AthleteFilter from './Kanban/Filter';
import AthleteFilterResult from './Kanban/FilterResult';
import NavDrawer from 'components/NavDrawer/NavDrawer';

export default function Negotiations({ agencyId }) {
  const { t } = useTranslation();

  const [frameFilter, setFrameFilter] = useState('');
  const [queryFilters, setQueryFilters] = useState({ name: '', foot: '', position: [], membership: 'transfer' });
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenFilterResult, setIsOpenFilterResult] = useState(false);
  const [deleteFrame, setDeleteFrame] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [updateAthletesWorkflow, setUpdateAthletesWorkflow] = useState(undefined);
  const [optionsEditFrame, setOptionsEditFrame] = useState({
    isOpen: false,
    frame: '',
    description: '',
    saving: false,
  });

  const dispatch = useDispatch();
  const agencies = useSelector(state => state.agencies.instance);
  const frames = useSelector(state => state.agencies.frames);
  const loading = { agencies: agencies.loading, frames: frames.loading };

  useEffect(() => {
    dispatch(getAgencies(agencyId));
    dispatch(getAgencyAthletes(agencyId));
  }, [dispatch, agencyId]);

  useEffect(() => {
    dispatch(getAgencyNegotiations(agencyId, queryFilters));
  }, [dispatch, agencyId, queryFilters]);

  useEffect(() => {
    setUpdateAthletesWorkflow(frames.data);
  }, [frames]);

  let agencyFrameObject;
  let agencyFrameFieldName;
  if (queryFilters.membership === 'transfer') {
    agencyFrameFieldName = 'transfer_frames';
    agencyFrameObject = agencies?.data?.transfer_frames ?? {};
  } else {
    agencyFrameFieldName = 'frames';
    agencyFrameObject = agencies?.data?.frames ?? {};
  }

  return (
    <div className={styles.containerNegotiations}>
      <NavDrawer isOpen={isOpenFilter} hide={() => setIsOpenFilter(!isOpenFilter)}>
        <AthleteFilter
          visible={isOpenFilter}
          onApply={() => {
            setIsOpenFilter(false);
            setIsOpenFilterResult(true);
          }}
        />
      </NavDrawer>
      <NavDrawer isOpen={isOpenFilterResult} hide={() => setIsOpenFilterResult(!isOpenFilterResult)}>
        <AthleteFilterResult
          back={() => {
            setIsOpenFilterResult(false);
            setIsOpenFilter(true);
          }}
          openFilter={setIsOpenFilterResult}
          athletesWorkflow={frames.data}
          frame={frameFilter}
          agencyId={agencyId}
          setUpdateAthletesWorkflow={setUpdateAthletesWorkflow}
          queryFilters={queryFilters}
        />
      </NavDrawer>
      <div className={styles.cards}>
        <Kanban
          openFilter={frame => {
            setFrameFilter(frame);
            setIsOpenFilter(true);
          }}
          queryFilters={queryFilters}
          setQueryFilters={setQueryFilters}
          openEditFrame={setOptionsEditFrame}
          setDeleteFrame={setDeleteFrame}
          confirmDelete={confirmDelete}
          deleteFrame={frame => {
            const oldValue = agencyFrameObject[frame];
            delete agencyFrameObject[frame];
            setOptionsEditFrame({ ...optionsEditFrame }); // update screen
            updateAgencyFrames(agencyId, agencyFrameFieldName, agencyFrameObject).then(result => {
              if (result?.error) {
                dispatch(
                  layoutActions.showToast({
                    content: <span style={{ padding: '20px 0' }}>{t(result.error)}</span>,
                    duration: 3000,
                  })
                );
                agencyFrameObject[frame] = oldValue;
                setOptionsEditFrame({ ...optionsEditFrame }); // update screen
              } else {
                window.location.reload();
              }
              setConfirmDelete(false);
              setDeleteFrame(false);
            });
          }}
          enableAddAthlete={true}
          updateAthletesWorkflow={updateAthletesWorkflow}
          agencies={agencies}
          agencyId={agencyId}
          loading={loading}
          agencyFrameObject={agencyFrameObject}
        />
      </div>
      <Dialog
        isOpen={optionsEditFrame.isOpen}
        onClose={() => setOptionsEditFrame({ isOpen: false, frame: '', description: '', saving: false })}
        mainTitle={`${optionsEditFrame.frame.length === 0 ? t('field.insert') : t('field.edit')} ${t(
          'myClub.negotiations.list'
        )}`}
        btnLeft={t('buttons.cancel')}
        btnLeftClick={() => setOptionsEditFrame({ isOpen: false, frame: '', description: '', saving: false })}
        btnRight={t('buttons.save')}
        btnRightDisabled={optionsEditFrame.saving}
        btnRightClick={() => {
          setOptionsEditFrame({ ...optionsEditFrame, saving: true });
          let frame = optionsEditFrame.frame;
          if (frame.length === 0) {
            frame = Helpers.normalizedString(optionsEditFrame.description);
          }
          const oldValue = agencyFrameObject[frame];
          agencyFrameObject[frame] = optionsEditFrame.description;
          updateAgencyFrames(agencyId, agencyFrameFieldName, agencyFrameObject).then(result => {
            if (result?.error === undefined) {
              agencies.data.transfer_frames = result.transfer_frames;
              agencies.data.frames = result.frames;
              setOptionsEditFrame({ isOpen: false, frame: '', description: '', saving: false });
            } else {
              dispatch(
                layoutActions.showToast({
                  content: <span style={{ padding: '20px 0' }}>{t(result.error)}</span>,
                  duration: 3000,
                })
              );
              agencyFrameObject[frame] = oldValue;
              setOptionsEditFrame({ ...optionsEditFrame, saving: false });
            }
          });
        }}
      >
        <span style={{ marginBottom: '10px', marginLeft: '5px' }}>{t('agencies.name')}:</span>
        <Input
          value={optionsEditFrame.description}
          placeholder={t('agencies.name')}
          onChange={e => {
            e.persist();
            setOptionsEditFrame({ ...optionsEditFrame, description: e.target.value });
          }}
          rounded
          style={{ marginTop: '10px' }}
        />
      </Dialog>
      <Dialog
        isOpen={deleteFrame}
        onClose={() => {
          setDeleteFrame(false);
        }}
        mainTitle={t('proj.deleteTitle')}
        btnLeft={t('buttons.cancel')}
        btnLeftClick={() => {
          setDeleteFrame(false);
        }}
        btnRight={t('buttons.excluir')}
        btnRightClick={() => {
          setConfirmDelete(true);
        }}
        btnRightDisabled={confirmDelete}
      >
        <span className={styles.deleteMessage}>{t('proj.deleteMessage')}</span>
      </Dialog>
    </div>
  );
}
