import React, { useState, useEffect } from 'react';
import axios from 'api';
import styles from './Annoucements.module.scss';
import { IconButton } from 'components/template';
import { MdForum } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import Chat from '../../../MyNegotiations/Chat/Chat.js';

export default function Annoucements({ data, athleteId, history }) {
  const { t } = useTranslation();

  const loggedUser = useSelector(state => state.user.data);

  const initialData = {
    athlete: null,
    ceding_club: null,
    buyer_club: null,
    transfer_market: null,
  };

  const [negotiationData, setNegotiationData] = useState(initialData);
  const [loadingChat, setLoadingChat] = useState(false);

  useEffect(() => {
    const container = document.getElementById('dynamicContainter');
    const textElement = document.getElementById('dynamicText');
    
    function adjustFontSize() {
      const containerWidth = container.offsetWidth;
      const textWidth = textElement.scrollWidth;

      if (textWidth > containerWidth) {
        let fontSize = window.getComputedStyle(textElement).fontSize.replace('px', '');
        while (textElement.scrollWidth > containerWidth && fontSize > 0) {
          fontSize--;
          textElement.style.fontSize = fontSize + 'px';
          console.log('fontSize', textElement.style.fontSize)
        }
      }
    }

    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);

    return () => window.removeEventListener('resize', adjustFontSize); // Cleanup
  }, [data]);

  useEffect(() => {
    setNegotiationData({
      athlete: athleteId,
      ceding_club: data?.athlete?.current_team?.id,
      buyer_club: loggedUser.organization?.teamId,
      transfer_market: data?.athlete?.transfermarket[0]?.id,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <h1>{t('transferMarket.clubAnnouncement')}</h1>
      <div className={styles.card}>
        <div className={styles.rowInfo}>
          <div className={styles.info}>
            <div id="dynamicContainter">
              <span>{t('transferMarket.amountRequested')}</span>
              <span id="dynamicText">
                {data?.athlete?.transfermarket[0]?.sale_value.toLocaleString('en-GB', {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
            <div>
              <span>{t('transferMarket.headerTransferTypeTitle')}</span>
              <span>{t(`negotiationOptions.${data?.athlete?.transfermarket[0]?.negotiation_type}`)}</span>
            </div>
            <div>
              <span>{t('transferMarket.headerSalaryTitle')}</span>
              <span>
                {data?.athlete.transfermarket[0]?.annual_salary} M €/{t('transferMarket.year')}
              </span>
            </div>
          </div>
          <div className={styles.divButton}>
            {loggedUser.organization == null ||
              loggedUser.organization?.teamId == null ||
              loggedUser.organization?.teamId === data?.athlete?.current_team?.id ||
              loggedUser.organization?.id?.type === 'agency' ? (
              ''
            ) : (
              <IconButton
                text={t('transferMarket.openChat')}
                className={styles.button}
                icon={<MdForum />}
                disabled={loadingChat}
                onClick={async () => {
                  const url = '/negotiations';
                  try {
                    setLoadingChat(true);
                    const { data } = await axios.post(`${url}`, negotiationData);
                    history.push({
                      pathname: '/transfer-market/my-negotiations',
                      state: { negotiation_id: data.id },
                    });
                  } catch (err) {
                    return err;
                  }
                  setLoadingChat(false);
                }}
              />
            )}
          </div>
        </div>
        <div className={styles.lowCard}>
          <div>
            <span>{t('negotiations.headerClubObs')}</span>
          </div>
          <div>
            <span>{data?.athlete.transfermarket[0]?.notes}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
