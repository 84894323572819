import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Reports.module.scss';
import ReportItem from './ReportItem';
import { Creators } from 'store/ducks/competition';
import { usePagination } from 'shared/hooks';
import { Card, Spinner, Pagination } from 'components/template';
import FooturePro from 'assets/footure-pro.svg';
import { useTranslation } from 'react-i18next';

export default function Reports({ id, baseURL }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const competition = useSelector(state => state.competition);
  const competitionPage = competition.competitionsPage;
  const reports = competition.reports;
  const rawData = reports.data.reports;
  const rawDataIsNotEmpty = (rawData?.length ?? 0) > 0;

  const [pagination, setPagination] = usePagination();

  useEffect(() => {
    if (Object.keys(competitionPage?.data ?? {}).length) {
      dispatch(Creators.getReports(id, pagination));
    }
  }, [dispatch, pagination, id, competitionPage.data]);

  let content = '';
  if (reports.loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (reports.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(reports.error)}</p>
      </Card>
    );
  } else {
    content = (
      <>
        <div className={styles.competition}>
          <div className={styles.title}>
            <img src={FooturePro} alt="footure" height="70px" />
            <div>
              {t('compe.text8')}
              {competitionPage?.data?.name ?? 'competição'}
              {t('compe.text9')}
            </div>
          </div>
          <div className={styles.reportContainer}>
            {rawDataIsNotEmpty ? (
              rawData.map((reportData, index) => {
                return <ReportItem key={index} reportData={reportData} />;
              })
            ) : (
              <p style={{ textAlign: 'center', margin: '40px' }}>
                <i>{t('compe.text10')}</i>
              </p>
            )}
            <div className={styles.itemContainer} style={{ height: 0 }}></div>
            <div className={styles.itemContainer} style={{ height: 0 }}></div>
            <div className={styles.itemContainer} style={{ height: 0 }}></div>
            <div className={styles.itemContainer} style={{ height: 0 }}></div>
          </div>
        </div>
        {rawDataIsNotEmpty && reports.data && (
          <Pagination
            length={rawData.length}
            count={reports.data.count}
            pagination={pagination}
            setPagination={setPagination}
            hasNext={reports.data.hasNext}
            hasPrevious={reports.data.hasPrevious}
          />
        )}
      </>
    );
  }

  return <>{content}</>;
}

Reports.propTypes = {
  id: PropTypes.string.isRequired,
};
