import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MdEdit } from 'react-icons/md';
import styles from './ContractsGroup.module.scss';
import { useTranslation } from 'react-i18next';
import { Card, Table } from 'components/template';
import Helpers from 'shared/helpers';

export default function ContractsGroup({ athletes, type, onClick, selectedFilters }) {
  const { t } = useTranslation();
  const [athletesAsRows, setAthletesAsRows] = useState([]);
  const agency = useSelector(state => state.agencies.instance);
  const contractHighRisk = agency.data?.contract_high_risk ?? 9;
  const contractWarningRisk = agency.data?.contract_warning_risk ?? 18;

  const loaned = selectedFilters.loaned ? 'emprestados' : 'elenco';

  const parseAthletesData = athletes => {
    return Helpers.athleteListToTable(athletes, loaned, styles);
  };

  useEffect(() => {
    return setAthletesAsRows(parseAthletesData(athletes));
  }, [athletes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <div className={[styles.panelTitle, styles[`panelGroup-${type}`]].join(' ')}>
        <p onClick={onClick}>
          {t(`myClub.contracts.contractGroup_${type}`, { contractHighRisk, contractWarningRisk })}
          {onClick != null && (<MdEdit />)}
        </p>
      </div>

      {athletesAsRows.length ? (
        <Card softShadow className={styles.content}>
          <div className={styles.tableContainer}>
            <Table
              flat
              className={styles.clubsTable}
              theads={[
                t('myClub.contracts.tableColumn.athlete'),
                '',
                t('myClub.contracts.tableColumn.age'),
                t('myClub.contracts.tableColumn.position'),
                t('myClub.contracts.tableColumn.nationality'),
                t('myClub.contracts.tableColumn.contractEnd'),
                t('myClub.contracts.tableColumn.sourceTeam'),
                // t('myClub.contracts.tableColumn.contractType'),
                // t('myClub.contracts.tableColumn.contractNumber'),
                // t('myClub.contracts.tableColumn.contractStart'),
              ]}
              data={athletesAsRows}
            />
          </div>
        </Card>
      ) : (
        <div className={styles.tableEmpty}>
          <p>{t(`myClub.contracts.contractGroupEmpty_${type}`, { contractHighRisk, contractWarningRisk })}</p>
        </div>
      )}
    </div>
  );
}
